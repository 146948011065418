<template>
  <section class="tw-w-full">
    <div class="tw-flex tw-flex-col tw-gap-2 tw-pb-4 xl:tw-flex-row">
      <div
        v-for="(tab, index) in tabs"
        :key="tab.componentInstance.title"
        class="tw-block xl:tw-w-2/6"
        @click="selectTab(index)"
      >
        <a
          class="big-tab tw-flex tw-items-center tw-h-full"
          :class="{
            selected: (index === selectedTabIndex) ,
            disabled: tab.componentInstance.disabled,
          }"
        >
          <div class="columns is-align-items-center">
            <div
              v-if="tab.componentInstance.icon"
              class="column is-1 is-hidden-mobile"
              style="margin-right: 10px;"
            >
              <b-icon
                :icon="tab.componentInstance.icon"
                size="is-medium"
                :class="index === selectedTabIndex ? 'tw-text-color-tertiary' : 'tw-text-grey-dark'"
              />
            </div>
            <div class="column">
              <div
                v-if="tab.componentInstance.tooltip"
                class="tooltip-content"
              >
                <b-tooltip
                  position="is-top"
                  size="is-small"
                  class="pull-right reference-tooltip"
                  :class="{'tw-bg-tertiary-light' : index === selectedTabIndex}"
                  multilined
                >
                  <template v-slot:content>
                    <div v-html="tab.componentInstance.tooltip" />
                  </template>
                  <b-icon
                    icon="info-circle"
                    size="is-small"
                    class="has-text-info"
                  />
                </b-tooltip>
              </div>
              <p>{{ tab.componentInstance.title }}</p>
              <p
                class="tw-mt-1 tw-font-normal tw-text-sm"
                v-html="tab.componentInstance.description"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
    <slot />
  </section>
</template>

<script>

export default {
  name: 'BigTabs',

  data() {
    return {
      selectedTabIndex: 0,
      tabs: [],
    };
  },
  mounted() {
    this.tabs = this.$slots.default;
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      if (this.tabs[i].componentInstance.disabled) {
        return;
      }

      this.selectedTabIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.componentInstance.isActive = (index === i);
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.big-tab {
  border: 2px solid #dbdbdb;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  color: #363636;
  min-height: 70px;
  line-height: 1.2;
}

.big-tab.selected {
  color: #338dbc;
  border: 2px solid theme('colors.tertiary');
  background-color: theme('colors.tertiary-light');
}

.big-tab.disabled {
  font-weight: 300 !important;
  color: #7e7e7e !important;
  cursor: not-allowed !important;
}


.tooltip-content {
  float: right;
  background-color: white !important;
}

::v-deep .reference-tooltip {
  .tooltip-content{
    background: theme('colors.black');
    color: theme('colors.white');
  }
}
</style>
