<template>
  <aside class="tw-bg-white tw-w-min tw-m-auto tw-p-5 tw-rounded-xs" style="width: 440px;">
    <header class="tw-flex tw-items-center tw-justify-between tw-mb-4">
      <h1 class="tw-text-xl tw-font-bold tw-grow">
        Part Marking and Screws
      </h1>
      <button
        class="twdelete tw-text-black tw-text-xxl"
        @click="$emit('close')"
      >&times</button>
    </header>

    <section>
      <h2 class="tw-mb-2 tw-font-bold tw-text-md">
        Include additions to your parts
      </h2>

      <div v-if="Object.keys(configurations).length > 1 && !isSamePartMakingAndScrews" class="msgbody tw-text-md tw-flex tw-items-center tw-bg-tertiary tw-bg-opacity-20 tw-py-2 tw-px-2 tw-mb-2">
        <font-awesome-icon icon="info-circle" class="tw-text-tertiary" />
        <span class="tw-pl-2">The parts you have selected have <b>mixed</b>  part marking and screws. Selecting new part marking and screws will replace the existing ones.</span>
      </div>

      <div class="tw-flex tw-flex-col tw-justify-start tw-space-y-3">
        <div
          v-for="(addon, i) in addons"
          :key="addon.id"
          :class="['tw-flex tw-flex-col tw-p-3 tw-px-3 tw-text-primary tw-border tw-rounded tw-text-md tw-rounded-xs tw-cursor-pointer',
            selectedAddons.includes(addon.id) ? 'tw-border-tertiary' : 'tw-border-grey-light ']"
          @click="handleSelectedAddon(addon)"
        >
          <div class="flex">
            <label class="b-checkbox checkbox checkbox">
              <input
                disabled
                type="checkbox"
                v-model="selectedAddons"
                :value="addon.id"
                class="checkbox" />
              <span class="check"></span>
              <span class="control-label"></span>
            </label>
            <span>{{ addon.string_value }}</span>
          </div>

          <div>
            <div v-if="addon.metadata.description"
                 class="tw-text-grey-dark tw-text-sm tw-font-bold tw-ml-8 tw-py-1 tw-cursor-pointer">
              <span class="tw-pr-2">What's included</span>
            </div>
            <div
              class="markdown-html tw-text-sm"
              v-html="$options.markdownConverter.makeHtml(addon.metadata.description)"
            />
          </div>
      </div>
      </div>
    </section>


    <footer class="tw-pt-5 tw-flex tw-justify-between">
      <GmButton
        outlined
        type="info"
        class="tw-font-bold"
        @click="$emit('close')"
      >
        Cancel
      </GmButton>
      <GmButton
        type="g-primary"
        class="tw-font-bold"
        @click="setupUpdate"
      >
        Add Selected
      </GmButton>
    </footer>
  </aside>
</template>

<script>
import {
  ADDONS,
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { PART_MARKING, SCREWS } from '@/shared/consts/slugs';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import { mapState } from 'vuex';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import showdown from 'showdown';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'OtherExtrasModal',
  components: { GmButton, FontAwesomeIcon },
  mixins: [safeMultipleUpdate],
  markdownConverter: new showdown.Converter(),
  props: {
    leadHash: {
      type: String,
      default: null,
    },
    leadConfig: {
      type: Object,
      default: () => ({}),
    },
    isSamePartMakingAndScrews: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedAddons: [],
      selectedAddonsInitial: [],
    };
  },
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    addons() {
      const filteredAddons = this[ADDONS]?.map((a) => {
        const children = this[REFERENCE_DATA]?.filter((p) => p.parent_ids.includes(a.id));
        return {
          ...a,
          children: children.filter((c) => this.checkParents(c.id, this.leadConfig)),
        };
      });

      const partMarkings = filteredAddons?.find((e) => e.slug === PART_MARKING)?.children || [];
      const screws = filteredAddons?.find((e) => e.slug === SCREWS)?.children || [];

      return [...partMarkings, ...screws]
    },
  },
  watch: {
    leadConfig: {
      handler(val) {
        const addonIds = this.addons.map((e) => e.id);
        this.selectedAddonsInitial = Object.values(val).filter((v) => addonIds.includes(v));
        this.selectedAddons = [...this.selectedAddonsInitial];
      },
      immediate: true,
    },
  },
  methods: {
    setupUpdate() {
      this.selectedAddonsInitial.filter((id) => !this.selectedAddons.includes(id)).forEach((id) => {
        const entitySlug = this.addons.find((e) => e.id === id).entity_slug;
        this.startUpdate(null, { entitySlug, values: [null] }, true);
      });
      this.selectedAddons.forEach((id) => {
        const entitySlug = this.addons.find((e) => e.id === id).entity_slug;
        const values = Array.from(new Set(
          Object.values(this.configurations).map((v) => v[entitySlug]).filter(Boolean),
        ));
        this.startUpdate(id, { entitySlug, values }, true);
      });
      this.$emit('close');
    },
    handleSelectedAddon({id, entity_slug}) {
      if (this.selectedAddons.includes(id)) {
        this.selectedAddons = this.selectedAddons.filter((e) => e !== id);
      } else {
        this.selectedAddons.push(id);
      }
    },
  },
}
</script>



<style
  scoped
  lang="scss"
>
.markdown-html {
  ul {
    list-style: disc;
    padding-inline-start: 40px;
  }
}
</style>
