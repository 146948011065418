export default  {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check if the clicked element is not the element itself and not contained within the element
      if (!(el === event.target || el.contains(event.target))) {
        // Call the method provided in the binding value
        vnode.context[binding.expression](event);
      }
    };
    // Add the event listener
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    // Remove the event listener when the directive is unbound
    document.removeEventListener('click', el.clickOutsideEvent);
  }
};
