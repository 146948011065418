import axios from 'axios';

export const programmaticUpload = {
  methods: {
    /**
     * An upload trigger for when input[type="file"] is not available
     * or not working (e.g. modals, notifications)
     *
     * notes:
     * Can get expensive if overused
     *
     * @param {function} uploadCallback     The function to be executed on the change event
     * @param {string} accept               Comma separated list of file types
     * @param {boolean} multiple            Enables multiple file selection
     * @param {boolean} batched             If set to true executes the uploadCallback function
     *                                      on the Filelist, otherwise the function is ran on
     *                                      all Files separately
     * @private
     */
    _triggerUpload(uploadCallback, accept, multiple = false, batched = false) {
      const input = document.createElement('INPUT');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', accept);
      input.setAttribute('multiple', multiple);
      input.setAttribute('data-testid', 'upload-input-field');
      input.onchange = (event) => {
        if (uploadCallback) {
          if (batched) {
            uploadCallback(event.target.files);
          } else {
            for (let i = 0; i < event.target.files.length; i++) {
              uploadCallback(event.target.files[i]);
            }
          }
        }
        document.body.removeChild(input);
      };
      input.style.display = 'none';
      document.body.appendChild(input);
      input.click();
    },
  },
};

function pad(number, length) {
  let str = `${number}`;
  while (str.length < length) {
    str = `0${str}`;
  }

  return str;
}

export function getDBTimeStampFN() {
  const dt = new Date();
  return `${dt.getFullYear()
  }-${pad(dt.getMonth() + 1, 2)
  }-${pad(dt.getDate(), 2)
  } ${pad(dt.getHours(), 2)
  }:${pad(dt.getMinutes(), 2)
  }:${pad(dt.getSeconds(), 2)}`;
}

export const getDBTimeStamp = {
  methods: {
    _getDBTimeStamp: getDBTimeStampFN,
  },
};

export const gmThemeProps = {
  props: {
    type: {
      type: String,
      default: () => '',
    },
    outlined: {
      type: Boolean,
      default: () => false,
    },
    fullwidth: {
      type: Boolean,
      default: () => false,
    },
    rounded: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => null,
      validator(value) {
        return ['xsmall', 'small', 'medium', 'large'].indexOf(value) !== -1;
      },
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    hasShadow: {
      type: Boolean,
      default: () => false,
    },
    is3d: {
      type: Boolean,
      default: () => false,
    },
  },
};

export const downloadItem = {
  methods: {
    downloadItem({ url, label }) {
      axios.get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
  },
};

export const queryParamHandler = {
  data() {
    return {
      queryParams: {},
    };
  },
  methods: {
    updateQueryParams() {
      this.$router.replace(
        {
          path: this.$router.currentRoute.path,
          query: this.queryParams,
        },
      ).catch(() => {});
    },
    parseQueryParams() {
      Object.keys(this.queryParams).forEach((key) => {
        const value = this.$route.query?.[key];
        if (value) {
          this.queryParams[key] = value;
        }
      });
    },
  },
};

export const isComponentVisible = {
  data() {
    return {
      canCheckComponentVisibility: !!window.IntersectionObserver,
      isComponentVisible: !window.IntersectionObserver,
    };
  },
  mounted() {
    if (!this.canCheckComponentVisibility) return;

    const callback = (entries) => {
      const [element] = entries;
      this.isComponentVisible = element?.isIntersecting;
    };

    const observer = new IntersectionObserver(callback);
    observer.observe(this.$el);

    this.$once('hook:beforeDestroy', () => {
      observer.disconnect();
    });
  },
};
