<template>
  <div class="tw-inline-flex">
    <g-button
      :label="defaultCountry?.string"
      iconRight="edit"
      type="underline"
      color="tertiary"
      fontWeight="bold"
      @click="handleOpenCountryPickerModal"
    />
    <g-modal
      :is-active.sync="isOpen"
      :can-cancel="['escape']"
      modalWidth="100%"
      modalHeight="100%"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs tw-w-9/12 xl:tw-w-4/12">
        <div class="tw-flex tw-items-center tw-justify-between tw-pb-3">
          <p class="tw-font-bold tw-text-xl">Edit country of delivery</p>
          <button
            class="tw-text-black tw-text-lg"
            @click="isOpen = false"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>
        <p class="tw-font-bold tw-text-sm tw-mb-1">Country of delivery</p>
        <g-select
          :hasFixedLabel="false"
          :activeOption="localCountrySelection || [defaultCountry]"
          :options='countries'
          :isFullWidth="true"
          :isAdminStyle="true"
          iconRight="chevron-down"
          label="Sort"
          type="primary"
          size="sm"
          fontWeight="normal"
          color="grey-dark"
          @selected="handleCountrySelected"
        />
        <div class="tw-flex tw-items-center tw-justify-between tw-mt-6">
          <g-button
            isInverted
            label="Cancel"
            color="tertiary"
            @click="isOpen = false"
          />
          <g-button
            :isDisabled="!localCountrySelection"
            label="Save"
            @click="handleSaveCountry"
          />
        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { USER_COUNTRY, USER_MODULE, SET_USER_COUNTRY } from '@/app-buyer/store/modules/user/types';
import COUNTRIES from '@/app-buyer/consts/countries';
import GModal from '@/app-buyer/components/GModal';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GSelect from '@common/components/storied/molecules/GSelect.vue';

export default {
  name: 'CountryPickerModal',

  components: {
    GModal,
    GButton,
    GSelect,
  },

  data() {
    return {
      isOpen: false,
      countrySearch: null,
      deliveryCountry: COUNTRIES[0],
      localCountrySelection: null,
    }
  },

  computed: {
    ...mapState(USER_MODULE, {
      USER_COUNTRY,
    }),

    defaultCountry() {
      const userCountry = this[USER_COUNTRY] || 'United Kingdom';
      return this.cleanCountries.find((c) => c.slug === userCountry);
    },

    cleanCountries() {
      return COUNTRIES.map((c) => {
        return { string: c, slug: c, value: c };
      });
    },

    countries() {
      if (this.countrySearch) {
        return this.cleanCountries?.filter((e) => e.toLowerCase().includes(this.countrySearch.toLowerCase()));
      }
      return this.cleanCountries
    },
  },

  methods: {
    ...mapMutations(USER_MODULE, {
      SET_USER_COUNTRY,
    }),

    handleOpenCountryPickerModal() {
      this.isOpen = !this.isOpen;
    },

    handleCountrySelected(country) {
      this.localCountrySelection = country;
    },

    handleSaveCountry() {
      if (this.localCountrySelection) {
        this.SET_USER_COUNTRY(this.localCountrySelection?.[0]?.value);
        localStorage.setItem('userCountrySelect', this.localCountrySelection?.[0]?.value);
      }
      this.isOpen = false;
    }
  },
}
</script>


<style
  scoped
  lang="scss"
>
</style>
