/* eslint-disable */
import {
  ADD_UPLOAD_PROGRESS,
  APPEND_FILE,
  BATCH_CREATE_DRAFT_RFQ,
  DRAFT_RFQS,
  GET_PRESETS,
  PRESETS,
  RFQ_MODULE,
  RFQS, SAVE_DRAFT_FILE_UPDATE,
  UPDATE_DRAFT,
  UPDATE_UPLOAD_PROGRESS,
} from '../store/modules/rfq/types';
import notificationInjection
  from '../../shared/components/notification/notification-injection-mixin';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import presetHandler from './preset-handler';
import {
  REFERENCE_DATA,
  REFERENCE_MODULE,
} from '../store/modules/reference-data/types';
import { GET } from '../store/modules/types';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_SUPPORTING_FILE,
  DWG,
  DXF,
  IGES,
  IGS,
  partOrder,
  PDF,
  STEP,
  STL,
  STP,
} from '@/shared/consts/slugs';
import {
  ACTIVE_PROJECT,
  PROJECT_MODULE,
} from '../store/modules/projects/types';
import {
  AUTH_MODULE,
  LOGGED_IN,
  UPLOAD_BEFORE_AUTH,
} from '../store/modules/auth/types'
import { GET_USER_ID, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { DETAIL } from '@/app-buyer/store/modules/types';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import { partSelectMixin } from '../components/project/mixins';

const MAX_FILE_SIZE = 100000000;

export const getNameAndExtension = (filename) => {
  const nameArray = filename.split('.');
  return {
    extension: nameArray.pop().toLowerCase(),
    name: nameArray.join('.'),
  };
};

/**
 * List of CAD mime types - http://isp.vsi.ru/library/Perl/CGI/ch15.htm
 *
 * @param {string} extension
 * @returns {string}
 */
export const getMimeType = (extension) => {
  const ext = extension.toLowerCase();
  if (ext === 'pdf') return 'application/pdf';
  if (ext === 'dwg') return 'application/acad';
  if (ext === 'dxf') return 'application/dxf';
  if (ext === 'stl') return 'application/sla';
  if (['stp', 'step'].includes(ext)) return 'application/STEP';
  if (['igs', 'iges'].includes(ext)) return 'application/iges';
  return 'application/octet-stream';
};

const baseUpload = {
  mixins: [notificationInjection, presetHandler, partSelectMixin],
  computed: {
    ...mapState(RFQ_MODULE, {
      PRESETS,
      RFQS,
      DRAFT_RFQS,
    }),
    ...mapGetters(USER_MODULE, {
      GET_USER_ID,
    }),
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),
  },
  data() {
    return {
      _successCallback: null,
      _failCallback: null,
    };
  },
  methods: {
    ...mapActions(REFERENCE_MODULE, {
      getRefData: GET,
    }),
    ...mapActions(RFQ_MODULE, {
      GET_PRESETS,
    }),
    ...mapMutations(RFQ_MODULE, {
      ADD_UPLOAD_PROGRESS,
      UPDATE_UPLOAD_PROGRESS,
    }),
    ...mapMutations(AUTH_MODULE, {
      UPLOAD_BEFORE_AUTH,
    }),
    uploadCallback() {
      return null;
    },

    async createQuotes(files, isDragged = false) {
      const prerequisites = [];

      if (!this[REFERENCE_DATA]?.length) {
        prerequisites.push(this.getRefData());
      }

      if (!this[PRESETS]?.length) {
        prerequisites.push(this[GET_PRESETS]());
      }

      await Promise.all(prerequisites);

      let project_hash = this[ACTIVE_PROJECT]?.hash;
      const firstModel = null;
      // files that pass the size check
      const acceptable = [];
      // files no passing size check
      const sizeError = [];
      // files passing file matchin matching
      const uploadables = [];
      // files not passing file matching
      const matchRejects = [];
      // Filter files that are larger than the MAX_FILE_SIZE
      [].slice.call(files).forEach((e) => {
        if (e.size < MAX_FILE_SIZE) {
          acceptable.push(e);
        } else {
          sizeError.push(e);
        }
      });

      // Notify user if file size is too large
      if (sizeError.length) {
        const message = `<p><b>File size exceeds upload limit.</b><br /><br />${sizeError.reduce((result, current) => `${result + current.name}<br />`, '')}`;
        this._addNotification({
          message,
          type: 'is-danger',
        });
      }

      // Map the acceptable parts so we have the name and extension easily accessible
      // add a supporting file list to the object to store pdfs
      const withExtensionAndName = acceptable.map((e) => {
        const { extension, name } = getNameAndExtension(e.name);
        return {
          file: e,
          name,
          extension,
          supportingFiles: [],
        };
      });

      const noMatching = [];

      /**
       * Create an object to collect parts with same names together, if name and extension are same then a new
       * upload is added.
       *
       * Key: name
       * Properties:
       *  - files: an array containing all the uploaded files with same names
       *  - draft: if a draft is already present in the active project the it's stored here
       * */
      const matchingNames = withExtensionAndName.reduce((result, current) => {
        // STL files don't match with anything.
        if (current.extension.toLowerCase() === STL) {
          noMatching.push(current);
          return result;
        }
        const hasUploadedMatch = this[DRAFT_RFQS].find((draft) => {
          const stringArr = draft.name.split('.');
          stringArr.pop();
          return draft.name !== current.file.name && stringArr.join('.') === current.name;
        });
        if (result[current.name]) {
          result[current.name].files.push(current);
        } else {
          result[current.name] = { files: [current] };
        }
        if (hasUploadedMatch && !result[current.name].draft) {
          result[current.name].draft = hasUploadedMatch;
        }
        return result;
      }, {});

      // We go through the names and preform the matching logic
      for (const key in matchingNames) {
        if (matchingNames.hasOwnProperty(key)) {
          const obj = matchingNames[key];
          /**
           * If draft is already present we either add all parts as supporting files or
           * swap out the current model file
           */
          if (obj.draft) {
            // Get uploaded model file
            const uploadedModelFile = findDraftModelFile(obj.draft);
            const hasNonPdfUploadedIndex = obj.files.findIndex((e) => e.extension.toLowerCase() !== PDF);
            const hasStepFileIndex = obj.files.findIndex((e) => [STEP, STP].includes(e.extension.toLowerCase()));
            let swapModelFile = null;
            /**
             * If there's a pdf already uploaded in drafts and the uploaded files contain
             * a non pdf we make the non pdf the model file and move the pdf as supporting
             */
            if (uploadedModelFile && uploadedModelFile.extension.toLowerCase() === PDF && hasNonPdfUploadedIndex > -1) {
              // Get the file that's non pdf
              swapModelFile = obj.files.splice(hasNonPdfUploadedIndex, 1)[0];
            }
            /**
             * If a dwg/dxf file is uploaded already and we match a step/stp file the
             * model file will be replaced by the step/stp file and the dxf/dwg will become
             * the supporting file
             */
            else if (uploadedModelFile && [DWG, DXF].includes(uploadedModelFile.extension.toLowerCase()) && hasStepFileIndex > -1) {
              swapModelFile = obj.files.splice(hasStepFileIndex, 1)[0];
            }
            /**
             * If a new model file is found we update the current one to be a supporting file and add the
             * new model file
             */
            // TODO use the UPDATE_DRAFT_RFQ endpoint to swap the upload_intents
            if (swapModelFile) {
              // Api.put(ENDPOINTS.UPLOADS.DETAIL, { type: DRAFT_RFQ_SUPPORTING_FILE }, { __pathParams: { id: uploadedModelFile.id } });
              const fileTypeId = this[REFERENCE_DATA]?.find((refData) => refData.slug === swapModelFile.extension)?.id;
              const service = this._preSelectServiceByFileType(swapModelFile.extension, uploadedModelFile.extension.toLowerCase());

              // Need to check for preset in case the new model file has different service options
              const preset = this._findPreset({ service }, {});

              // Add the new model file to the draft
              obj.draft._blockForm = true;
              this.$store.dispatch(`${RFQ_MODULE}/${UPDATE_DRAFT}`, {
                draft: obj.draft,
                properties: {
                  ...preset,
                  'file-type': fileTypeId,
                },
                files: {
                  modelFile: swapModelFile.file,
                },
                immediate: true,
              });
            }
            // TODO use the UPDATE_DRAFT_RFQ endpoint to swap the upload_intents
            // The files list is added as supporting files
            for (const fileObj of obj.files) {
              this.$store.dispatch(`${RFQ_MODULE}/${APPEND_FILE}`, {
                draft: obj.draft,
                file: fileObj.file,
              });
            }
          } else {
            /**
             * IGS/IGES files are rejected if STEP/STP files are present with the same name
             */
            const igesFiles = obj.files.filter((e) => [IGES, IGS].includes(e.extension.toLowerCase()));
            const stepFiles = obj.files.filter((e) => [STEP, STP].includes(e.extension.toLowerCase()));

            if (igesFiles.length && stepFiles.length) {
              obj.files = obj.files.filter((e) => ![IGES, IGS].includes(e.extension.toLowerCase()));
              igesFiles.forEach((e) => matchRejects.push(e));
            }
            /**
             * Model files and supporting files are selected by the following priority order:
             * STEP -> STP -> IGES -> IGS -> DXF -> DWG -> PDF
             */
            for (const type of [STEP, STP, IGES, IGS, DXF, DWG, PDF]) {
              const index = obj.files.findIndex((e) => e.extension.toLowerCase() === type);

              if (index > -1) {
                const found = obj.files.splice(index, 1)[0];
                const uploadable = {
                  ...found,
                  supportingFiles: obj.files,
                };
                uploadables.push(uploadable);
                break;
              }
            }
          }
        }
      }

      // Sort by size smallest to largest
      const sortedFiles = uploadables.concat(noMatching).sort((a, b) => a?.file?.size - b?.file?.size);

      // stop here if file list is empty
      if (!sortedFiles?.length) return;

      if (this[DRAFT_RFQS]?.length > 8 && sortedFiles.length) {
        this._addNotification({
          message: `Uploading file${sortedFiles?.length > 1 ? 's' : ''}`,
          type: 'is-info',
        });
      }

      const draftArray = [];
      sortedFiles.forEach((obj) => {
        // Preset loading logic
        const hasDxfOrDwgSupportingFile = obj.supportingFiles?.find((e) => [DWG, DXF].includes(e.extension.toLowerCase()))?.extension || null;
        const fileTypeId = this[REFERENCE_DATA]?.find((e) => e.slug === obj.extension)?.id;
        const service = this._preSelectServiceByFileType(obj.extension, hasDxfOrDwgSupportingFile);
        const preset = this._findPreset({ service }, {});

        let upload_intents = [{
          client_original_name: obj.file.name,
          bytes: obj.file.size,
          mime_type: getMimeType(obj.extension),
          extension: obj.extension,
          type: DRAFT_RFQ_MODEL,
        }];

        // if (obj.supportingFiles?.length) {
        //   obj.supportingFiles.forEach((supporting) => {
        //     const supporting_intents = {
        //       client_original_name: supporting.name,
        //       bytes: supporting.file.size,
        //       mime_type: getMimeType(supporting.extension),
        //       extension: supporting.extension,
        //       type: DRAFT_RFQ_SUPPORTING_FILE,
        //     }
        //     upload_intents = [...upload_intents, supporting_intents]
        //   })
        // }

        draftArray.push({
          ...preset,
          ...{
            'file-type': fileTypeId,
            project_hash,
            name: obj.file.name,
          },
          upload_intents,
        });
      });

      if (!this[LOGGED_IN]) this[UPLOAD_BEFORE_AUTH](true);

      const created = await this.$store.dispatch(
        `${RFQ_MODULE}/${BATCH_CREATE_DRAFT_RFQ}`,
        {
          draftRfqs: draftArray, blockAutoSelect: !!firstModel, isDragged,
        });
      created.forEach((model, index) => {
        const obj = sortedFiles[index];
        const time = new Date().getTime();
        this[ADD_UPLOAD_PROGRESS]({
          name: obj.file.name + time,
          status: 0,
        });

        for (const file of obj.supportingFiles.map((e) => e.file)) {
          this.$store.dispatch(`${RFQ_MODULE}/${APPEND_FILE}`, {
            draft: model,
            file,
          });
        }

        this.$store.dispatch(`${RFQ_MODULE}/${UPDATE_DRAFT}`, {
          draft: model,
          properties: { 'file-type': model.configuration['file-type'] },
          files: { modelFile: obj.file },
          immediate: true,
        })
          .finally(() => {
            this[UPDATE_UPLOAD_PROGRESS]({
              name: obj.file.name + time,
              status: 1,
            });
          });
      });

      if (this[ACTIVE_PROJECT] || this[DRAFT_RFQS]) {
        this.selectParts(this[DRAFT_RFQS][this[DRAFT_RFQS].length - 1]?.hash);
        this.$store.dispatch(`${PROJECT_MODULE}/${DETAIL}`, this[ACTIVE_PROJECT]);
      }

      if (created.length) {
        this.uploadCallback(created);
      }

      if (matchRejects.length) {
        const message = `<p><b>File matching error, IGES and STEP files can not be matched, IGES files rejected:</b><br /><br />${matchRejects.reduce((result, current) => `${result + current.name}<br />`, '')}`;
        this._addNotification({
          message,
          type: 'is-danger',
        });
      }

      // GTM Event for file upload
      if (this.$gtm) {
        files.forEach(() => {
          this.$gtm.trackEvent({
            event: 'user-action',
            'user-action-type': 'file-upload',
            user_id: this[GET_USER_ID],
            gmSource: sessionStorage.getItem('gm_src'),
          });
        })
      }
    },
  },
};

export default baseUpload;
