const thicknessMaterialData = [
  {
    category: 'aluminium',
    slug: 'aluminium-1050',
    string_name: 'Aluminium 1050',
    string_name_other: '3.0255 / Al99.5',
    thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '2.5', '3', '4'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-1050-tread',
    string_name: 'Aluminium 1050',
    string_name_other: '3.0255 / Al99.5 Tread Plate',
    thicknesses: ['0.5', '0.7', '1.5', '2', '3'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5052',
    string_name: 'Aluminium 5052',
    string_name_other: '3.3523 / AlMg2.5',
    thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5083',
    string_name: 'Aluminium 5083',
    string_name_other: '3.3547 / AlMg4.5Mn',
    thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8', '10', '12', '15', '18', '20'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5250',
    string_name: 'Aluminium 5250',
    string_name_other: '3.2530 / AlMg2.5',
    thicknesses: ['1', '1.2', '2', '2.5', '3', '4', '5', '6'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5251',
    string_name: 'Aluminium 5251',
    string_name_other: '3.3525 / AlMg2',
    thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8', '10', '12', '15', '18', '20'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5754',
    string_name: 'Aluminium 5754',
    string_name_other: '3.3535 / Al-Mg3',
    thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8', '10', '12', '15', '18', '20'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-5754-tread-plate',
    string_name: 'Aluminium 5754 Tread Plate',
    string_name_other: '3.3535 / Al-Mg3',
    thicknesses: ['1.5', '2', '3'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-6061',
    string_name: 'Aluminium 6061',
    string_name_other: '3.3206 / Al-Mg1SiCu',
    thicknesses: ['1', '2', '3', '4', '5', '6'],
  },
  {
    category: 'aluminium',
    slug: 'aluminium-6082-t6',
    string_name: 'Aluminium 6082 T6',
    string_name_other: '3.2315 / Al-Si1Mg',
    thicknesses: ['2', '3', '4', '5', '6', '8', '10'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-101',
    string_name: 'Stainless Steel 101',
    string_name_other: '1.4003 / X2CrNi12',
    thicknesses: ['0.8', '1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-260',
    string_name: 'Stainless Steel 260',
    string_name_other: '1.4016 / X6Cr17',
    thicknesses: ['0.5', '0.7', '0.8', '1', '1.2', '1.5', '2', '3', '4', '5'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-301',
    string_name: 'Stainless Steel 301',
    string_name_other: '1.4310 / X10CrNi18-8',
    thicknesses: ['0.7', '0.8', '1', '1.2', '1.5', '2', '2.5'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304',
    string_name: 'Stainless Steel 304',
    string_name_other: '1.4301 / X5CrNi18-10',
    thicknesses: ['0.7', '0.8', '1', '1.2', '1.5', '2', '2.5'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-1d',
    string_name: 'Stainless Steel 304 1D',
    string_name_other: '1.4301 / X5CrNi18-10 (mill finish, hot rolled)',
    thicknesses: ['3', '4', '5', '6', '8', '10', '12'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-2b',
    string_name: 'Stainless Steel 304 2B',
    string_name_other: '1.4301 / X5CrNi18-10 (mill finish, cold rolled)',
    thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8', '10', '12'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-4n',
    string_name: 'Stainless Steel 304 4N',
    string_name_other: '1.4301 / X5CrNi18-10 (brushed 1 side)',
    thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '2.5', '3'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-dp1',
    string_name: 'Stainless Steel 304 DP1',
    string_name_other: '1.4301 / X5CrNi18-10',
    thicknesses: ['0.5', '0.7', '0.8', '1', '1.2', '1.5', '2', '3'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-tread',
    string_name: 'Stainless Steel 304',
    string_name_other: '1.4301 / X5CrNi18-10 (tread plate)',
    thicknesses: ['2', '2.5', '3', '4'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-304-ba',
    string_name: 'Stainless Steel 304 BA',
    string_name_other: '1.4301 / X5CrNi18-10 (cold rolled, bright annealed and skinpassed)',
    thicknesses: ['0.5', '0.7', '1', '1.5', '2'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-316-2b',
    string_name: 'Stainless Steel 316 2B',
    string_name_other: '1.4401 / X5CrNiMo17-12-2 (mill finish, cold rolled)',
    thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '3', '4'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-316-1d',
    string_name: 'Stainless Steel 316 1D',
    string_name_other: '1.4401 / X5CrNiMo17-12-2',
    thicknesses: ['4', '5', '6', '8', '10', '12'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-316-dp1',
    string_name: 'Stainless Steel 316 DP1',
    string_name_other: '1.4401 / X5CrNiMo17-12-2',
    thicknesses: ['1', '1.2', '1.5', '2', '3'],
  },
  {
    category: 'steel',
    slug: 'stainless-steel-316-4n',
    string_name: 'Stainless Steel 316 4N',
    string_name_other: '1.4401 / X5CrNiMo17-12-2 (brushed 1 side)',
    thicknesses: ['0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '3'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s235',
    string_name: 'Carbon Steel S235',
    string_name_other: '1.0038 / St37-2',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s275',
    string_name: 'Carbon Steel S275',
    string_name_other: '1.0044 / St44-2',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s275-j2',
    string_name: 'Carbon Steel S275J2',
    string_name_other: '1.0145 / S275J2G4',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s275-jr',
    string_name: 'Carbon Steel S275JR',
    string_name_other: '1.0044 / S275JR',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s355',
    string_name: 'Carbon Steel S355',
    string_name_other: '1.0577 / St52-3',
    thicknesses: ['3', '4', '5', '6', '8', '10', '12', '14', '15', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s355-jr',
    string_name: 'Carbon Steel S355JR',
    string_name_other: '1.0045 / S355JR',
    thicknesses: ['3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'durbar-s355-jr-tread-plate',
    string_name: 'Durbar S355JR Tread Plate',
    string_name_other: '1.0045 / S355JR',
    thicknesses: ['2', '2.5', '3', '4', '4.5', '5', '6', '8', '10'],
  },
  {
    category: 'steel',
    slug: 'durbar-s355-j2',
    string_name: 'Durbar S355J2',
    string_name_other: '1.0577 / S355J2+N',
    thicknesses: ['3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-s355-mc',
    string_name: 'Carbon Steel S355MC',
    string_name_other: '1.0976 / S355MC',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12'],
  },
  {
    category: 'steel',
    slug: 'carbon-steel-dc01-cr4',
    string_name: 'Carbon Steel DC01/CR4',
    string_name_other: '1.0330 / St2',
    thicknesses: ['0.5', '0.6', '0.7', '0.75', '0.8', '0.9', '1.2', '1.25', '1.5', '2', '2.5', '3'],
  },
  {
    category: 'steel',
    slug: 'hardox-450',
    string_name: 'Hardox 450',
    string_name_other: '1.8702',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'hardox-500',
    string_name: 'Hardox 500',
    string_name_other: '1.8744',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '14', '15', '18', '20'],
  },
  {
    category: 'steel',
    slug: 'strenx-650-mc',
    string_name: 'Strenx 650MC',
    string_name_other: '1.8976 / S650MC',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10'],
  },
  {
    category: 'steel',
    slug: 'strenx-700-mc',
    string_name: 'Strenx 700MC',
    string_name_other: '1.8974 / S700MC',
    thicknesses: ['2', '2.5', '3', '4', '5', '6', '8', '10', '12', '15'],
  },
  {
    category: 'steel',
    slug: 'dx51d-274-mac',
    string_name: 'DX51D+274 MAC',
    string_name_other: '',
    thicknesses: ['0.5', '0.6', '0.7', '0.75', '0.9', '1', '1.2', '1.25', '1.5', '2', '2.5', '3', '4'],
  },
  {
    category: 'steel',
    slug: 'zintec',
    string_name: 'Zintec',
    string_name_other: 'DC01ZE / 1.0330 / St2Z',
    thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '2.5', '3'],
  },
  {
    category: 'copper',
    slug: 'copper-101',
    string_name: 'C101',
    string_name_other: 'CW004A / Cu-ETP',
    thicknesses: ['0.9', '1.2', '1.5', '2', '3', '4'],
  },
  {
    category: 'copper',
    slug: 'copper-110',
    string_name: 'C110',
    string_name_other: '2.0040 / Cu-ETP',
    thicknesses: ['0.9', '1.2', '1.5', '2', '3', '4'],
  },

];

const thicknessMaterialCategories = [
  { slug: 'aluminium', string_name: 'Aluminium' },
  { slug: 'steel', string_name: 'Steel' },
  { slug: 'copper', string_name: 'Copper' },
]

export { thicknessMaterialData, thicknessMaterialCategories };


// const thicknessMaterialData = [
//   {
//     slug: 'aluminum',
//     string_name: 'Aluminium',
//     data: [
//       {
//         category: 'aluminium',
//         slug: 'aluminium-1050',
//         string_name: 'Aluminium 1050 / 3.0255 / Al99.5',
//         thicknesses: ['0.5', '0.7', '0.8', '0.9', '1', '1.2', '1.5', '2', '2.5', '3', '4'],
//       },
//       {
//         category: 'aluminium',
//         slug: 'aluminium-1050-tread',
//         string_name: 'Aluminium 1050 Tread Plate / 3.0255 / Al99.5',
//         thicknesses: ['0.5', '0.7', '1.5', '2', '3'],
//       },
//       {
//         category: 'aluminium',
//         slug: 'aluminium-5052',
//         string_name: 'Aluminium 5052 / 3.3523 / AlMg2.5',
//         thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6'],
//       },
//       {
//         category: 'aluminium',
//         slug: 'aluminium-5083',
//         string_name: 'Aluminium 5083 / 3.3547 / AlMg4.5Mn',
//         thicknesses: ['1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8', '10', '12', '15', '18', '20'],
//       },
//     ]
//   },
//   {
//     slug: 'stainless-steel',
//     string_name: 'Stainless Steel',
//     data: [
//       {
//         category: 'steel',
//         slug: 'stainless-steel-101',
//         string_name: 'Stainless Steel 101 / 1.4003 / X2CrNi12',
//         thicknesses: ['0.8', '1', '1.2', '1.5', '2', '2.5', '3', '4', '5', '6', '8'],
//       },
//     ]
//   },
// ];
//
// export default thicknessMaterialData;
