<template>
  <div class="">
    <BInput
      v-model="searchTerm"
      icon="search"
      placeholder="Search material"
      size="is-small"
      custom-class="tw-text-md"
    />
    <div class="tw-flex tw-justify-between tw-my-3 tw-flex-col xl:tw-items-center xl:tw-flex-row">
      <div class="tw-flex tw-items-center">
        <g-button
          label="All"
          color="admin"
          size="sm"
          fontWeight="normal"
          class="tw-mr-2"
          :class="{'active-filter-bg' : !activeFilter }"
          @click="handleActiveFilter()"
        />
        <g-button
          v-for="category in thicknessMaterialCategories"
          :label="category.string_name"
          color="admin"
          size="sm"
          fontWeight="normal"
          class="tw-mr-2"
          :class="{'active-filter-bg' : activeFilter === category.slug }"
          @click="handleActiveFilter(category.slug)"
        />
      </div>

      <p class="tw-text-sm tw-mt-2 xl:tw-mt-0">
        <font-awesome-icon
          icon="badge-check"
          class="tw-text-success"
        />
        All thicknesses shown are instantly quotable
      </p>
    </div>
    <thickness-material-table :material-data="cleanMaterialData" />
  </div>
</template>

<script>
import {
  thicknessMaterialCategories,
  thicknessMaterialData,
} from '@/shared/misc/thickness-material-data';
import ThicknessMaterialTable from '@/app-buyer/components/project/ThicknessMaterialTable.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'ThicknessMaterialContent',

  components: {
    ThicknessMaterialTable,
    GButton,
  },

  data() {
    return {
      thicknessMaterialData,
      thicknessMaterialCategories,
      activeFilter: null,
      searchTerm: '',
    }
  },

  computed: {
    cleanMaterialData() {
      if (this.searchTerm) return thicknessMaterialData.filter((d) => {
        return (d.string_name.toLowerCase().includes(this.searchTerm.toLowerCase()) || d.string_name_other.toLowerCase().includes(this.searchTerm.toLowerCase()))
      });

      if (!this.activeFilter) return thicknessMaterialData;

      return thicknessMaterialData.filter((d) => d.category === this.activeFilter);
    },
  },

  methods: {
    handleActiveFilter(slug) {
      this.activeFilter = slug || null;
    },
  },
}
</script>


<style
  scoped
  lang="scss"
>
tbody {
  td {
    min-width: theme('spacing.8');
  }
}

.active-filter-bg {
  background-color: theme('colors.tertiary-light') !important;
  border-color: theme('colors.tertiary') !important;
}

</style>
