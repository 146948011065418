<template>
  <Portal to="uploadProgress">
    <div class="mb-2">
      <BProgress
        show-value
        size="is-small"
        type="is-dark"
        :value="UPLOAD_PERCENT"
      />
    </div>
  </Portal>
</template>

<script>
import { mapGetters } from 'vuex';
import { RFQ_MODULE, UPLOAD_PERCENT } from '@/app-buyer/store/modules/rfq/types';
import notificationInjection from '@/shared/components/notification/notification-injection-mixin';

export default {
  name: 'UploadIndicator',
  mixins: [notificationInjection],
  data() {
    return {
      notificationId: null,
    };
  },
  computed: {
    ...mapGetters(RFQ_MODULE, {
      UPLOAD_PERCENT,
    }),
  },
  watch: {
    [UPLOAD_PERCENT]() {
      this.checkAndFireNotification();
    },
    $route() {
      this.checkAndFireNotification();
    },
  },
  methods: {
    checkAndFireNotification() {
      const noNotificationRoute = ['quote-page', 'rfq-form-no-selected']
        .includes(this.$route.name);
      if (!this.notificationId
        && this[UPLOAD_PERCENT] > 0
        && !noNotificationRoute) {
        const { id } = this._addNotification({
          indefinite: true,
          icon: 'info-circle',
          portalName: 'uploadProgress',
          message: 'Your files are uploading!',
        });
        this.notificationId = id;
      } else if ((this.notificationId && noNotificationRoute)
        || !this[UPLOAD_PERCENT]
        || this[UPLOAD_PERCENT] > 99) {
        this._removeNotification(this.notificationId);
        this.notificationId = null;
      }
    },
  },
};
</script>

<style scoped>
.upload-indicator {
  height: 1rem;
  position: fixed;
  bottom: 0;
  right: 2rem;
  left: 6rem;
}
</style>
