/* eslint-disable no-mixed-operators,
camelcase,
no-underscore-dangle,
no-param-reassign */
import Vue from 'vue';
import { debounce, memoize, wrap } from 'lodash';
import { DialogProgrammatic } from 'buefy';
import axios from 'axios';
import {
  _ADD_NOTIFICATION,
  _NOTIFICATION_MODULE,
} from '@/shared/components/notification/vuex-setup';
import {
  ADD_TO_DRAFTS,
  APPEND_FILE,
  ATTACH_RFQS,
  BATCH_CREATE_DRAFT_RFQ,
  BATCH_DELETE,
  CREATE_DRAFT_RFQ,
  CREATE_RFQS,
  DEBOUNCE_DRAFT_UPDATE,
  DRAFT_CLONE,
  DRAFT_DETAIL,
  DRAFT_RFQS,
  GET_ALL_RFQS,
  GET_ALL_RFQS_BY_PROJECT,
  GET_INSTANT_QUOTE,
  GET_PRESETS,
  MOVE_RFQ,
  REMOVE_SUPPORTING_FILE,
  REVISE_RFQS,
  RFQS,
  RFQ_DETAIL,
  RFQ_UPDATE_HASHES,
  SAVE_DRAFT_FILE_UPDATE,
  SAVE_DRAFT_UPDATE,
  SET_FORM_ERRORS,
  SET_INSTANT_QUOTE,
  SET_PRESETS,
  SET_PROGRESS,
  SET_PROPERTY,
  SET_RFQ_UPDATE_HASHES,
  SET_UPDATING_PROPERTIES,
  SET_UPLOADED_DRAFTS,
  SWAP_SUPPORTING_FILES,
  SWAP_UPLOADING,
  UPDATE_CALLS,
  UPDATE_DRAFT,
  UPDATE_RFQ,
  UPDATING_PROPERTIES,
  GET_PREVIOUS_QUOTES,
  SET_PREVIOUS_QUOTES,
  SET_PREVIOUS_QUOTE,
  UPLOADED_DRAFTS, RFQ_MODULE,
} from '@/app-buyer/store/modules/rfq/types';

import {
  ACTIVE_PROJECT_HASH,
  ADD_DRAFT,
  GET_UPDATED_PROJECTS_MRFQ,
  LISTEN_PROJECT_CHANNEL,
  PROJECTS,
  PROJECT_MODULE,
  REMOVE_FROM_PROJECT,
  SET_ACTIVE_PROJECT,
} from '@/app-buyer/store/modules/projects/types';

import {
  UPLOAD,
  UPLOAD_MODULE,
} from '@/app-buyer/store/modules/upload/types';


import { findModelFile } from '@/app-buyer/components/project/helpers';
import Api from '../../../api/api';
import ENDPOINTS from '../../../api/endpoints';
import paramsSerializer from '../../../../shared/misc/paramsSerializer';
import {
  DELETE, SET, SET_ALL, UPDATE,
} from '../types';

import router from '../../../router';
import {
  AUTH_MODULE,
  FILES_BEFORE_AUTH,
  FILES_UPLOADED_BEFORE_AUTH,
  FORCE_AUTH,
  LOGGED_IN,
  MASQUERADING,
  SET_FILES_UPLOADED_BEFORE_AUTH,
} from '../auth/types';
import {
  METADATA, SET_METADATA, USER_DATA, USER_MODULE,
} from '../user/types';
import { LOGIN_MODAL_VISIBLE, NAVIGATION_MODULE } from '../navigation/types';
import { REFERENCE_DATA, REFERENCE_MODULE } from '../reference-data/types';
import Project from '@/app-buyer/models/Project';
import getEnvironmentVariable, { config } from '@/shared/misc/env-variable';
import { getNameAndExtension } from '@/app-buyer/mixins/base-upload';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_SUPPORTING_FILE,
} from '@/shared/consts/slugs';
import {
  QUOTES,
  QUOTES_MODULE,
  SET_PENDING_REVISIONS,
  SET_QUOTES,
  SET_REQUESTED_QUOTE_INFO,
} from '@/app-buyer/store/modules/quotes/types';
import { getMimeType } from '@/app-buyer/mixins/base-upload';
import { queueHandlerFactory } from '@/common/helpers/index';

let currentControllers = [];

/**
 * Sets up the formData for rfq creation and update
 * @param {Object} payload
 * @param {Object} payload.properties          Appends the properties set here to the formData
 * @param {Object} payload.files
 * @param {File} payload.files.modelFile       Appends the model file to the formData
 * @param {File} payload.files.supportingFiles  Appends the supporting file to the formData
 * */
const setUpFormData = ({ properties = {}, files = {} }) => {
  const formData = new FormData();
  if (files.modelFile) {
    formData.append('model_file', files.modelFile);
  }
  if (files.supportingFiles) {
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files.supportingFiles) {
      formData.append('supporting_files[]', file);
    }
  }
  Object.keys(properties).forEach((key) => {
    if (typeof properties[key] === 'object' && properties[key] !== null) {
      Object.entries(properties[key]).forEach((entry) => {
        const [entryKey, entryValue] = entry;

        const embeddedKey = `${key}[${entryKey}]`;

        formData.append(embeddedKey, entryValue || '');
      });
    } else {
      formData.append(key, properties[key] || '');
    }
  });
  return formData;
};

const cleanRfqRequestData = ({ draftOrRfq, isReviseRequote = false }) => {
  let requestData = [];

  draftOrRfq.forEach((rfq) => {
    // Destructure draft or rfq to flatten out configuration object
    let rfqObj = {};
    const {
      name,
      quantity_initial,
      quantity_production,
      revision,
      project_hash,
      configuration,
      delivery_country,
      notes,
      production_requirements,
      files,
      lead_time,
      lead_time_speed,
    } = rfq;

    rfqObj = {
      ...rfqObj,
      ...configuration,
      name,
      quantity_initial,
      quantity_production,
      revision,
      project_hash,
      delivery_country,
      files,
      notes,
      lead_time,
      lead_time_speed,
    };

    if (production_requirements) {
      rfqObj.production_requirements = (({
        development_stage,
        t1_sample_deliver_by,
        forecast_units,
        forecast_period,
        tool_life,
        tooling_config_desc,
        additional_notes,
      }) => ({
        development_stage,
        t1_sample_deliver_by,
        forecast_units,
        forecast_period,
        tool_life,
        tooling_config_desc,
        additional_notes,
      }))(production_requirements);
    }

    // Apply the id / hash the new rfq is created from
    if (isReviseRequote) {
      rfqObj.origin_rfq_id = rfq.id;
      rfqObj.draft_rfq_hash = rfq.draft_hash;
      rfqObj.revised_from_id = rfq.id;
      rfqObj.listing_hash = rfq.listing_hash;
    } else {
      rfqObj.draft_rfq_hash = rfq.hash;
    }

    requestData = [...requestData, rfqObj];
  });

  return requestData;
};

/**
 * Generates a dummy draft-rfq with an id to show the user
 * @param {Object} files
 * @param {Object} properties
 * @param {File} files.modelFile    The uploaded file of which the name we show in the dummy element
 * */
const generateUploading = (files, properties) => {
  const hashed = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  return {
    name: files?.modelFile?.name || properties?.name || 'new part',
    uploadPercent: files?.modelFile ? 1 : 0,
    uploading_hash: hashed,
    hash: hashed,
    __extension: files?.modelFile?.name?.split('.').pop().toUpperCase() || null,
    uploading: true,
    ctSource: null,
    pending: true,
    updatingFile: true,
  };
};

const queueHandler = queueHandlerFactory({ maxExecuting: 4, checkTimeout: 1000 });
const supportingQueueHandler = queueHandlerFactory({ maxExecuting: 4, checkTimeout: 1000 });

// This block of code is a backup just in case
// the websockets decide to not work *cough*
// [45s, 1min, 2min, 3min, 5min, 10min]
const backoffInSeconds = [45, 60, 120, 180, 300, 600];
const pdfBackoffInSeconds = [2, 3, 5, 10, 20, 30];
const manuallyCheckInstantQuotesAndUploadProperties = ({
  state,
  commit,
  dispatch,
  draft,
  loop = 0,
}) => {
  const drOut = state[DRAFT_RFQS].find((d) => d.hash === draft.hash);
  const uploadOut = drOut?.uploads.find((u) => u?.type?.slug === 'draft-rfq-model');

  let backoff = backoffInSeconds;
  if (uploadOut?.extension.toLowerCase() === 'pdf') backoff = pdfBackoffInSeconds;

  setTimeout(async () => {
    const dr = state[DRAFT_RFQS].find((d) => d.hash === draft.hash);
    const upload = dr?.uploads.find((u) => u?.type?.slug === 'draft-rfq-model');
    const img = dr?.uploads.find((u) => u?.type?.slug === 'draft-rfq-model-preview');
    // Exit loop before calling API if all conditions are met
    if ((upload?.extension.toLowerCase() === 'pdf' && img) || (upload?.parser_metadata?.failed_at || upload?.parser_metadata?.completed_at) &&
      (dr.quote_status === 'Manual quote needed' || dr.quote_status === 'Awaiting Quote' || dr.quote_id)) return;

    const { data: { data: updatedDr } } = await dispatch(DRAFT_DETAIL, dr);
    const updatedDrUpload = updatedDr?.uploads?.find(
      (u) => u?.type?.slug === 'draft-rfq-model',
    );
    const updatedImg = updatedDr?.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model-preview');

    // To achieve fast thumbnails we make a fake upload object where the
    // `url` property is an object URL created from a blob. Replacing
    // this would be redundant, so we can skip if we detect a fake.
    const hasObjectURL = !!(img && img.hasOwnProperty('id') && img.id > 0);
    if (
      hasObjectURL
      && (
        updatedDrUpload?.parser_metadata?.failed_at
        || updatedDrUpload?.parser_metadata?.completed_at
        || updatedDrUpload?.extension.toLowerCase() !== 'pdf'
      )
    ) {
      manuallySetUploadProperties({ commit, draft: updatedDr, model: updatedDr });
    }

    // for PDFs
    if (!img && updatedDrUpload?.extension.toLowerCase() === 'pdf' && updatedImg) manuallySetUploadProperties({ commit, draft: updatedDr, model: updatedDr });

    if (updatedDr.quote_status === 'Quoted' && updatedDr.quote_id) manuallySetQuoteProperties({ commit, draft: updatedDr, model: updatedDr });

    // Exit loop if all conditions are met
    if ((upload?.extension.toLowerCase() === 'pdf' && updatedImg) || (updatedDrUpload?.parser_metadata?.failed_at || updatedDrUpload?.parser_metadata?.completed_at) &&
      (updatedDr.quote_status === 'Manual quote needed' || updatedDr.quote_status === 'Awaiting Quote' || updatedDr.quote_id)) return;

    if (loop === backoff.length - 1) return;

    const newLoop = loop + 1;
    manuallyCheckInstantQuotesAndUploadProperties({
      state,
      commit,
      dispatch,
      draft,
      loop: newLoop,
    });
  }, backoff[loop] * 1000);
};

const manuallySetProperties = ({ commit, draft, model, isFullUpdate = true }) => {
  commit(SET_PROPERTY, {
    model: draft,
    property: 'config_string',
    value: model.config_string,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'manual_rfqs_count',
    value: model.manual_rfqs_count,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'version',
    value: model.version,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'lead_time',
    value: model.lead_time,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'estimated_buyer_delivery_date',
    value: model.estimated_buyer_delivery_date,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'lead_time_dates',
    value: model.lead_time_dates,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'lead_time_days',
    value: model.lead_time_days,
  });
  commit(SET_PROPERTY, {
    model: draft,
    property: 'lead_time_speed',
    value: model.lead_time_speed || 'standard',
  });
};

const manuallySetUploadProperties = ({ commit, draft, model }) => {
  if (model?.uploads) {
    commit(SET_PROPERTY, {
      model: draft,
      property: 'uploads',
      value: model.uploads,
    });
  }
};

const manuallySetQuoteProperties = ({ commit, draft, model }) => {
  const toUpdate = [
    { property: 'quote_id', value: model.quote_id },
    { property: 'quote_price', value: model.quote_price },
    { property: 'quote_rfq_type', value: model.quote_rfq_type },
    { property: 'quote_status', value: model.quote_status },
    { property: 'quote_expires_at', value: model.quote_expires_at },
    { property: 'quote_unit_price', value: model.quote_unit_price },
    { property: 'quote_tool_cost', value: model.quote_tool_cost },
    { property: 'quote_delay', value: model.quote_delay },
    { property: 'quote_notes', value: model.quote_notes },
    { property: 'status', value: model.status },
    // These properties are related to the quote
    // as they are only filled once gserve has made a bid.
    { property: 'dimension_x', value: model.dimension_x },
    { property: 'dimension_y', value: model.dimension_y },
    { property: 'dimension_z', value: model.dimension_z },
  ];

  // Don't update QNumber if the part is being priced
  if (model.status !== 'quoting') toUpdate.push({ property: 'quote_rfq_ref', value: model.quote_rfq_ref });

  toUpdate.forEach((u) => {
    commit(SET_PROPERTY, {
      model: draft,
      property: u.property,
      value: u.value,
    });
  });
};

export default {
  /**
   * Gets all rfqs for the user
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.params      Params that will be applied to the call
   * */
  async [GET_ALL_RFQS]({ commit, rootState }, payload = {}) {
    const { params } = payload;
    commit(SET_ALL, {
      data: [],
      clear: true,
    });
    if (!params.page) {
      params.page = 1;
    }
    const config = {
      params,
      paramsSerializer,
    };
    const {
      data: { data },
      meta,
    } = await Api.get(ENDPOINTS.RFQS.INDEX, config);
    commit(SET_ALL, {
      data,
      clear: true,
    });
    if (meta?.total) {
      commit(`${USER_MODULE}/${SET_METADATA}`, {
        ...rootState[USER_MODULE][METADATA],
        quotes_without_orders_count: meta.total,
      }, { root: true });
    }
    return data;
  },

  /**
   * Gets all rfqs and draft-rfqs for a project
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.hash         The project hash that we are getting the rfqs for
   * @param {boolean} payload.clear       Clears the stored rfqs and draft-rfqs from the state
   * @param {Object} payload.selected     If this is set it will be the set as
   *                                      the selected rfq/draft otherwise
   *                                      the first draft-rfq/rfq in the array
   * */
  async [GET_ALL_RFQS_BY_PROJECT]({ commit, state }, {
    hash,
    clear,
    selected,
  }) {
    const draftConfig = {
      params: {
        filter: { project_hash: hash },
        include: ['configurationProperties', 'uploads', 'uploads.parserMetadata', 'project'],
        // TODO Needs to be made optional
        page: 1,
        limit: 200,
      },
      paramsSerializer,
    };
    const rfqConfig = {
      params: {
        filter: {
          project_hash: hash,
        },
        include: [
          'configurationProperties',
          'uploads',
          'uploads.parserMetadata',
          'order',
          'sentQuotes.cartItem',
          'project',
        ],
        // TODO Needs to be made optional
        page: 1,
        limit: 200,
      },
      paramsSerializer,
    };

    const { data: { data: draftRfqs } } = await Api.get(ENDPOINTS.DRAFT_RFQS.INDEX, draftConfig);
    const { data: { data: rfqs } } = await Api.get(ENDPOINTS.RFQS.INDEX, rfqConfig);

    commit(SET, {
      rfqs,
      draftRfqs,
      clear,
      hasSelected: true,
    });
    const all = [...state[DRAFT_RFQS], ...state[RFQS]];
    if (selected) {
      all.forEach((e) => {
        if (selected.includes(e.hash)) {
          Vue.set(e, 'configuring', true);
        } else {
          Vue.set(e, 'configuring', false);
        }
      });
    } else if (all.length && !all.some((e) => e.configuring)) {
      all.some((e) => {
        if (!e.uploading) {
          Vue.set(e, 'configuring', true);
        }
        return !e.uploading;
      });
    }
  },

  /**
   * Creates an rfq from a draft or creates a duplicate rfq
   * note: Draft rfqs can not be duplicated
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.draftOrRfq
   * @param {boolean} payload.isDuplicate
   * @returns {Promise<Object>}
   */
  async [CREATE_RFQS]({ commit, dispatch, rootState }, {
    draftOrRfq,
    isReviseRequote,
  }) {
    const requestData = cleanRfqRequestData({ draftOrRfq, isReviseRequote });

    const { data, status } = await Api.post(
      ENDPOINTS.RFQS.INDEX,
      { rfqs: requestData },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).catch(({ response }) => {
      commit(SET_FORM_ERRORS, response.data.errors);
      const error = {
        data: response.data,
        status: response.status,
      };
      throw error;
    });

    if (status < 300) {
      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Rfq created', {
          rfqsRequested: `${draftOrRfq.length}`,
          rfqs: draftOrRfq.map((rfq) => ({
            category: rfq?.configuration_object?.service?.slug,
            category2: rfq?.configuration_object?.material?.slug,
            name: rfq?.name,
            // This information is not returned anymore with the batch endpoint.
            // We would need to rework the return data
            // or this event to get this data back.

            // qNumber: rfq?.data.ref,
            // id: rfq?.hash,
            deliveryCountry: rfq?.delivery_country,
          })),
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
        });
      }

      // Clear existing form errors
      commit(SET_FORM_ERRORS, []);
      // dispatch(`${PROJECT_MODULE}/${DETAIL}`, { hash: data[0].project_hash }, { root: true });
      // commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, data.job_listings, { root: true });
      // commit(`${USER_MODULE}/${SET_METADATA}`, {
      //   ...rootState[USER_MODULE][METADATA],
      //   quotes_without_orders_count: rootState[USER_MODULE][METADATA].quotes_without_orders_count + 1,
      // }, { root: true });
      return { data, status };
    }
    return data;
  },

  // This is the where all DRAFT RFQS are generated
  // TODO connect the response once its being returned
  async [BATCH_CREATE_DRAFT_RFQ]({
    commit, state, rootState, dispatch,
  }, { draftRfqs, blockAutoSelect, isDragged }) {
    const placeHolders = [];
    draftRfqs.forEach((draftConfig) => {
      const uploading = generateUploading(null, draftConfig);
      placeHolders.push(uploading);
      commit(ADD_TO_DRAFTS, uploading);
    });

    const { data: { data } } = await Api
      .post(ENDPOINTS.DRAFT_RFQS.INDEX, { 'draft-rfqs': draftRfqs })
      .catch(() => {
        placeHolders.forEach((e) => commit(DELETE, e));
      });

    // SEGMENT TRACKING
    if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
      window.analytics.track('Draft rfq created', {
        draftRfqsCreated: data.length,
        isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
        projectHash: draftRfqs[0]?.project_hash,
        isDragged,
        draftRfqs: data.map((draftRfq) => ({
          name: draftRfq.name,
          fileType: draftRfq.configuration_object?.['file-type']?.slug,
          draftHash: draftRfq.hash,
        })),
      });
    }

    data.forEach((draft, index) => {
      if (!blockAutoSelect
        && ![...state[RFQS], ...state[DRAFT_RFQS]].find((e) => e.configuring)
        && index === 0) {
        draft.selected = true;
        draft.configuring = true;
      }
      commit(SWAP_UPLOADING, {
        uploading: placeHolders.shift(),
        model: draft,
      });
      commit(SET_PROGRESS, {
        hash: draft.hash,
        progress: draft.progress,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: true,
      });
    });
    if (!rootState[AUTH_MODULE][LOGGED_IN]) {
      const hashids = JSON.parse(router.currentRoute.query.drafts || '[]');
      const appendable = data.map((e) => e.hash);
      const newHashids = hashids.concat(appendable);
      router.push({
        name: 'quote-page',
        query: { drafts: JSON.stringify(newHashids) },
      });
      setTimeout(() => {
        if (!rootState[NAVIGATION_MODULE][LOGIN_MODAL_VISIBLE]) {
          dispatch(`${PROJECT_MODULE}/${LISTEN_PROJECT_CHANNEL}`, data[0]?.project_hash, { root: true });
          const hasAccount = localStorage.getItem('gm_has_account');
          commit(`${AUTH_MODULE}/${FORCE_AUTH}`, hasAccount ? 'login' : 'register', { root: true });
        }
      });
    } else if (!rootState[PROJECT_MODULE][PROJECTS].find((e) => e.hash === data[0].project_hash)) {
      commit(`${PROJECT_MODULE}/${SET}`, {
        data: [{
          hash: data[0].project_hash,
          members: data[0].project_members,
          name: data[0].project_name,
        }],
      }, { root: true });
      dispatch(`${PROJECT_MODULE}/${SET_ACTIVE_PROJECT}`, {
        project: {
          hash: data[0].project_hash,
          members: data[0].project_members,
        },
        noRedirect: true,
      }, { root: true });
    }
    return data;
  },

  /**
   * Uploads a file and creates an dummy draft-rfq while it's uploading then
   * swaps it out with the created draft-rfq
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.properties            These properties will be
   *                                               applied to the created draft-rfq
   * @param {Object} payload.files                 This contains the main and
   *                                               supporting files
   * @param {File} payload.files.modelFile         This will be attached as
   *                                               the model file for the draft-rfq
   * @param {File} payload.files.supportingFile    This will be attached as
   *                                               the supporting file for
   *                                               the draft-rfq (optional)
   * */

  async [CREATE_DRAFT_RFQ]({
    commit, rootState, state, dispatch,
  },{ properties, blockAutoSelect = false, isDragged = false }) {
    const uploading = generateUploading(null, properties);
    commit(ADD_TO_DRAFTS, uploading);
    const response = await Api.post(ENDPOINTS.DRAFT_RFQS.INDEX, { 'draft-rfqs': [properties] }, {}).catch((error) => {
      commit(DELETE, uploading);
      return error.response;
    });
    if (response.status > 300) {
      return response;
    }
    const { data: { data }, status } = response;
    if (!data) {
      return {};
    }
    const model = data[0];
    if (!blockAutoSelect && ![...state[RFQS], ...state[DRAFT_RFQS]].find((e) => e.configuring)) {
      model.selected = true;
      model.configuring = true;
    }
    commit(SWAP_UPLOADING, {
      uploading,
      model,
    });
    commit(SET_PROGRESS, {
      hash: model.hash,
      progress: model?.progress,
    });

    if (!rootState[AUTH_MODULE][LOGGED_IN]) {
      const hashids = JSON.parse(router.currentRoute.query.drafts || '[]');
      hashids.push(model.hash);
      router.push({
        name: 'quote-page',
        query: { drafts: JSON.stringify(hashids) },
      });
      setTimeout(() => {
        if (!rootState[NAVIGATION_MODULE][LOGIN_MODAL_VISIBLE]) {
          const hasAccount = localStorage.getItem('gm_has_account');
          DialogProgrammatic.alert({
            message: `Please ${hasAccount ? 'log in to your account' : 'create an account'} to continue.`,
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
              if (hasAccount) {
                router.push('login');
              } else {
                router.push('/register');
              }
            },
          });
        }
      });
    } else if (!rootState[PROJECT_MODULE][PROJECTS].find((e) => e.hash === model.project_hash)) {
      commit(`${PROJECT_MODULE}/${SET}`, {
        data: [{
          hash: model.project_hash,
          members: model.project_members,
          name: '',
        }],
      }, { root: true });
      dispatch(`${PROJECT_MODULE}/${SET_ACTIVE_PROJECT}`, {
        project: {
          hash: model.project_hash,
          members: model.project_members,
        },
        noRedirect: true,
      }, { root: true });
    }
    return {
      model,
      status,
    };
  },


  /**
   * Returns the details of a rfq
   * @param {Object} context
   * @param {Object} rfq    The rfq that we want to get the details for
   * */
  async [RFQ_DETAIL](context, { id }) {
    const response = await Api.get(ENDPOINTS.RFQS.CONFIGURATION, {
      __pathParams: { id },
      params: {
        append: 'name',
        include: 'entity',
      },
    });
    return response;
  },

  /**
   * Returns the details of a draft-rfq
   * @param {Object} context
   * @param {Object} draft    The draft-rfq that we want to get the details for
   * */
  async [DRAFT_DETAIL](context, draft) {
    const { hash } = draft;
    const response = await Api.get(ENDPOINTS.DRAFT_RFQS.DETAIL, {
      __pathParams: { hash },
    });
    return response;
  },

  /**
   * Updates a draft-rfq, properties provided will
   * overwrite the existing draft properties even if null,
   * same applies to modelFile and supportingFile.
   *
   * (a new supporting file list replaces the existing one so if you want to append a file to the
   * list of supporting files use the ADD_SUPPORTING_FILE_TO_DRAFT action).
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.draft                The draft-rfq to be updated
   * @param {Object} payload.properties           The properties that will be
   *                                              added/updated on the draft-rfq
   * @param {Object} payload.files                If set adds/updates the uploaded files
   * @param {File} payload.files.modelFile        If set adds/updates the model
   *                                              file of the draft-rfq
   * @param {Array} payload.files.supportingFiles If set adds/updates the supporting
   *                                              file of the draft-rfq
   * */
  async [UPDATE_DRAFT]({
    commit, dispatch, rootState, state,
  }, {
    draft,
    properties,
    files,
    immediate = false,
    reviseRFQ = false,
  }) {
    const {
      project_hash = draft.project_hash,
      name = draft.name,
      notes = draft.notes,
      revision = draft.revision,
      quantity_initial = draft.quantity_initial,
      quantity_production = draft.quantity_production,
      lead_time = draft.lead_time,
      lead_time_speed = properties.lead_time_speed ?? draft.lead_time_speed,
    } = properties;

    const copy = {
      ...draft,
      ...{
        project_hash,
        name,
        notes,
        revision,
        quantity_initial,
        quantity_production,
        lead_time,
        lead_time_speed,
      },
    };

    if (files && files.modelFile && findModelFile(draft.uploads)) {
      const extension = files.modelFile.name.split('.').pop();
      copy.configuration['file-type'] = rootState[REFERENCE_MODULE][REFERENCE_DATA]?.find((e) => e.slug === extension?.toLowerCase())?.id;
    }

    copy.configuration_object = Object.keys(copy.configuration).reduce((res, e) => {
      res[e] = rootState[REFERENCE_MODULE][REFERENCE_DATA]?.find((d) => d.id === copy.configuration[e]);
      return res;
    }, {});
    commit(UPDATE_DRAFT, copy);

    if (rootState[PROJECT_MODULE][PROJECTS].find((p) => p.hash === project_hash)) {
      const currentProject = rootState[PROJECT_MODULE][PROJECTS].find(
        (p) => p.hash === project_hash,
      );

      const draftRfqs = state[DRAFT_RFQS];
      commit(`${PROJECT_MODULE}/${UPDATE}`, {
        hash: project_hash,
        data: { ...currentProject, draft_rfqs: [...draftRfqs] },
      }, { root: true });
    }

    setTimeout(() => {
      draft._blockForm = false;
    });
    commit(SET_PROPERTY, {
      model: draft,
      property: 'awaitingDispatch',
      value: true,
    });
    const startUpdate = () => {
      if (immediate && files) {
        commit(SET_PROPERTY, {
          model: draft,
          property: 'awaitingDispatch',
          value: false,
        });
        return dispatch(SAVE_DRAFT_UPDATE, {
          draft,
          properties,
          files,
        });
      }

      // This code block is to improve the debounce logic.
      // If there are no files attached (so it is just updating the configuration), we add the properties
      // to the store, adding to them whilst the update is debounced.
      // state[UPDATING_PROPERTIES] is read and removed within the SAVE_DRAFT_UPDATE action.
      if (!files && !draft.upload_intents) {
        if (state[UPDATING_PROPERTIES]?.[draft.hash]) {
          commit(SET_UPDATING_PROPERTIES, {
            ...state[UPDATING_PROPERTIES],
            [draft.hash]: {
              ...state[UPDATING_PROPERTIES]?.[draft.hash],
              ...properties,
            }
          })
        } else {
          commit(SET_UPDATING_PROPERTIES, {
            ...state[UPDATING_PROPERTIES],
            [draft.hash]: properties,
          })
        }
      }

      // const propertiesThatChangeQuote = ['quantity_initial', 'quantity_production', 'material', 'tolerance', 'primary-finish', 'notes', 'lead_time_speed', 'laser-marking', 'engraving', 'screen-printing', 'screws'];
      // const shouldShowLoading = Object.keys(properties).some((p) => propertiesThatChangeQuote.includes(p));
      const shouldShowLoading = true;

      if (!draft.upload_intents && shouldShowLoading) {
        commit(SET_RFQ_UPDATE_HASHES, [...state[RFQ_UPDATE_HASHES], draft.hash]);
      }

      return dispatch(DEBOUNCE_DRAFT_UPDATE, {
        draft,
        properties,
        files,
      });
    };
    if (files && (files.modelFile || files.supportingFiles?.length)) {
      return queueHandler.add(startUpdate);
    }
    return startUpdate();
  },

  [DEBOUNCE_DRAFT_UPDATE]: wrap(
    memoize(
      () => debounce(({ dispatch }, {
        draft,
        properties,
        files,
      }) => dispatch(SAVE_DRAFT_UPDATE, {
        draft,
        properties,
        files,
      }), 100), (...args) => args[1].draft.hash,
    ), (fn, ...payload) => fn(...payload)(...payload),
  ),

  // TODO this endpoint needs to be fixed to accept upload_intents
  async [SAVE_DRAFT_UPDATE]({
    state, commit, dispatch, rootState,
  }, {
    properties,
    draft,
    files,
  }) {
    const { hash } = draft;

    if (state[UPDATE_CALLS][hash]) {
      state[UPDATE_CALLS][hash] += 1;
    } else {
      state[UPDATE_CALLS][hash] = 1;
    }

    commit(SET_PROPERTY, {
      model: draft,
      property: 'updating',
      value: true,
    });
    commit(SET_PROPERTY, {
      model: draft,
      property: 'status',
      value: 'uploading',
    });

    if (files) {
      // Only update the file with signedUrls if the draft has an upload_intent present
      if (draft.upload_intents) {
        try {
          const { extension } = getNameAndExtension(files?.modelFile?.name);

          dispatch(SAVE_DRAFT_FILE_UPDATE, {
            file: files.modelFile,
            mime_type: getMimeType(extension),
            signedUrl: draft.upload_intents.filter((u) => u.file_type === 'draft-rfq-model')?.[0]?.signed_url,
            file_type: {
              id: 1,
              slug: "draft-rfq-model",
              name: "Draft Rfq Model"
            },
            draft,
          });

        } catch (err) {
          console.log(err);
        } finally {
          const currentDraft = state[DRAFT_RFQS].find((e) => e.hash === draft?.hash);
          if (currentDraft) {
            Object.keys(draft).forEach((key) => {
              Vue.delete(currentDraft, 'upload_intents')
            });
          }
          commit(SET_PROPERTY, {
            model: draft,
            property: 'version',
            value: !draft.version ? 1 : draft.version + 1,
          });
          commit(SET_PROPERTY, {
            model: draft,
            property: 'pending',
            value: false,
          });
          commit(SET_PROPERTY, {
            model: draft,
            property: 'updating',
            value: false,
          });
          setTimeout(() => {
            commit(SET_PROPERTY, {
              model: draft,
              property: 'uploadPercent',
              value: 0,
            });
          });
        }
      }
    }

    // If the draft has upload_intents then we want to include all files & properties, and send it in a POST request.
    // Else we want to perform a get request
    if (!draft.upload_intents) {
      // Increment the version number to prevent
      // the websocket events from overwriting
      // the configuration with previous config
      commit(SET_PROPERTY, {
        model: draft,
        property: 'version',
        value: !draft.version ? 1 : draft.version + 1,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'status',
        value: 'quoting',
      });

      const shouldShowLoading = true;

      const formData = setUpFormData({
        properties: state[UPDATING_PROPERTIES]?.[draft.hash],
        files,
      });
      formData.append('_method', 'PUT');

      delete state[UPDATING_PROPERTIES]?.[draft.hash];

      // Abort previous request if a new one is made
      if (currentControllers?.some((c) => c.hash === draft.hash)) {
        const controller = currentControllers.find((c) => c.hash === draft.hash)
        controller.c.abort();
      }

      // Create a new AbortController instance
      const currentController = new AbortController();
      const { signal } = currentController;
      let error = null;

      // Add the AbortController with the draft hash so we can prevent
      // cancelling the DRAFT_UPDATE of a different draft.
      if (currentControllers?.some((c) => c.hash === draft.hash)) {
        let index = currentControllers.findIndex((c) => c.hash === draft.hash);
        if (index !== -1) currentControllers[index].c = currentController;
      } else currentControllers.push({ c: currentController, hash: draft.hash });

      try {
        const { data: { data: model } } = await Api.post(ENDPOINTS.DRAFT_RFQS.DETAIL, formData, {
          __pathParams: { hash },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (files && files.modelFile) {
              const percent = Math.round(+progressEvent.loaded / +progressEvent.total * 100);
              commit(SET_PROPERTY, {
                model: draft,
                property: 'uploadPercent',
                value: percent,
              });
            }
          },
          signal, // Attach the signal to the request
        });

        if (model) {
          manuallySetProperties({ commit, draft, model, isFullUpdate: shouldShowLoading });
          manuallySetUploadProperties({ commit, draft, model })
          manuallySetQuoteProperties({ commit, draft, model })
        }

        // remove the AbortController from the array
        currentControllers.filter((c) => c.hash !== draft.hash);

        return model;
      } catch (err) {
        if (err.name === 'CanceledError') {
          console.log('Request canceled');
          return error = err;
        } else {
          commit(SET_FORM_ERRORS, err.response.data.errors);
          commit(SET_PROGRESS, {
            hash,
            progress: {
              is_complete: false,
              required: [],
            },
          });
          return err.response;
        }
      } finally {
        if (error?.name !== 'CanceledError') {
          state[UPDATE_CALLS][hash] -= 1;

          if (shouldShowLoading) {
            const rfqHashes = state[RFQ_UPDATE_HASHES];
            const filteredHashes = rfqHashes.filter((h) => h !== hash);

            commit(SET_RFQ_UPDATE_HASHES, filteredHashes);
          }
        }
      }
    }

    // This is here to keep track of the number of attached
    // drafts have that have attempted to be uploaded.
    // This is used with DRAFT_COUNT, which is the
    // total number of drafts to be uploaded.
    commit(SET_UPLOADED_DRAFTS, state[UPLOADED_DRAFTS] + 1);

    // if (!rootState[PROJECT_MODULE][PROJECTS].length) {
    //   // The draft update API endpoint will also add the given user as the
    //   // owner of the drafts project if no owner exists. This is required
    //   // due to the project potentially being created prior to the user
    //   // registering and being subsequently logged in.
    //
    //   // The need for this body of code is due to the LOGIN_SUCCESS handler not
    //   // ensuring the projects fetch call only happens after the update draft.
    //   // As a result, the projects fetch call will come back empty due to
    //   // the user not belonging to any projects until after the update.
    //
    //   // TODO: Ensure projects GET call comes after ATTACH_RFQS in LOGIN_SUCCESS
    //   commit(`${PROJECT_MODULE}/${SET}`, {
    //     data: [{
    //       hash: model.project_hash,
    //       name: model.project_name,
    //       members: model.project_members,
    //     }],
    //   }, { root: true });
    // }

    // return {
    //   model,
    //   progress: model?.progress,
    //   updating: draft.updating,
    // };
  },

  async [SAVE_DRAFT_FILE_UPDATE] ({ commit, dispatch, rootState, state, }, { draft, file, mime_type, signedUrl, file_type }) {
    const { hash } = draft;

    try {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: true,
      });

      await dispatch(`${UPLOAD_MODULE}/${UPLOAD}`,
        {
          type: mime_type,
          file,
          signedUrl,
          hash,
        },
        { root: true });

      file = {
        ...file,
        type: file_type,
      };

      if (rootState[AUTH_MODULE][FILES_BEFORE_AUTH]?.length) {
        commit(`${AUTH_MODULE}/${SET_FILES_UPLOADED_BEFORE_AUTH}`, rootState[AUTH_MODULE][FILES_UPLOADED_BEFORE_AUTH] + 1, { root: true });
      }

      manuallyCheckInstantQuotesAndUploadProperties({
        state,
        commit,
        dispatch,
        draft,
      });
    } catch (err) {
      console.log(err);
    } finally {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updatingFile',
        value: false,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'pending',
        value: false,
      });
      commit(SET_PROPERTY, {
        model: draft,
        property: 'updating',
        value: false,
      });
    }


  },


  async [GET_INSTANT_QUOTE]({ state, commit }, { draft = null }) {
    // stop pdfs from going through logic
    // stop service lines that are not 3d-printing
    if (
      draft.uploads?.filter((u) => u.type?.slug === 'draft-rfq-model')?.[0]?.extension === 'pdf' ||
      draft.configuration_object.service.slug !== '3d-printing'
    ) return;

    let instantQuoteData = {};
    const uuid = draft.uploads?.filter((u) => u.type?.slug === 'draft-rfq-model')?.[0]?.parser_metadata?.uuid;
    const parser_uuid = draft.uploads?.filter((u) => u.type?.slug === 'draft-rfq-model')?.[0]?.parser_metadata?.parser_uuid;
    const clean_uuid = parser_uuid || uuid;

    if (draft) {
      instantQuoteData = {
        ...instantQuoteData,
        uuid: clean_uuid,
        quantity: draft.quantity_initial,
        secondary_quantity: draft.quantity_production,
        lead_time_speed: draft.lead_time_speed,
        service: draft.configuration_object?.service?.slug,
        material: draft.configuration_object?.material?.slug,
        secondary_finish: draft.configuration_object?.['secondary-finish']?.slug ,
      }

      // 3d printing specific configuration
      if (draft.configuration_object?.service?.slug === '3d-printing') {
        instantQuoteData = {
          ...instantQuoteData,
          technology: draft.configuration_object?.technology?.slug,
        };

        // SLA specific configuration
        if (draft.configuration_object?.technology?.slug === 'sla-stereolithography') {
          let layerHeight = '0.1';

          if (draft.configuration_object?.layer_height?.slug === 'sla-zero-point-zero-five-mm') layerHeight = '0.05';
          if (draft.configuration_object?.layer_height?.slug === 'sla-zero-point-zero-two-five-mm') layerHeight = '0.025';

          instantQuoteData = {
            ...instantQuoteData,
            layer_height: layerHeight,
          };
        }
      }
    }

    // TODO ensure the isFetchingQuote works
    // if the draft doesn't have an instant quote in state add an Object with fetchingQuote: true
    if (!draft.hasOwnProperty('instant_quote')) {
      const instantQuoteFetching = { hash: draft.hash, instantQuote: { isFetchingQuote: true } };

      commit(SET_INSTANT_QUOTE, { instantQuote: instantQuoteFetching });
    }

    try {
      const { data } = await axios.get(`${config('GSERVE_URL')}/api/v2/model-parse/${clean_uuid}/price`,{ params: instantQuoteData});

      commit(SET_INSTANT_QUOTE, { instantQuote: data, hash: draft.hash });
    } catch (e) {
      console.log(e);

      commit(SET_INSTANT_QUOTE, { add: false, hash: draft.hash });
    }
  },

  /**
   * Update rfq details
   * @param context
   * @param {Object} payload
   * @param {Object} payload.rfq
   * @param {Object} payload.properties
   * @returns {Promise<Object>}
   */

  async [UPDATE_RFQ]({ commit }, { rfq, properties, files }) {
    const { hash } = rfq;
    Vue.set(rfq, 'updating', true);
    const formData = setUpFormData({
      properties,
      files,
    });
    formData.append('_method', 'PUT');
    const { data } = await Api.post(ENDPOINTS.RFQS.UPDATE, formData, {
      __pathParams: { hash },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data) {
      commit(UPDATE_RFQ, data);
    }
    Vue.set(rfq, 'updating', true);
    return data;
  },

  async [REVISE_RFQS]({ rootState, commit }, { revisedRfqs, jobListings }) {
    const requestData = cleanRfqRequestData({ draftOrRfq: revisedRfqs });

    const resetRfqCreatedEventCount = rootState[QUOTES_MODULE]?.[QUOTES]?.map((q) => ({
      ...q,
      rfqCreatedEventCount: 0,
    }));

    commit(`${QUOTES_MODULE}/${SET_QUOTES}`, resetRfqCreatedEventCount, { root: true });

    try {
      const res = await Api.post(
        ENDPOINTS.RFQS.REVISE,
        { rfqs: requestData },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, [jobListings], { root: true });
      commit(`${QUOTES_MODULE}/${SET_PENDING_REVISIONS}`, revisedRfqs.map((r) => r.hash), { root: true });
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  /**
   * Move rfq/draft-rfq between projects
   * @param {Object} context
   * @param {Object} payload
   * @param {Number} payload.hash
   * @param {Number} payload.to
   * @param {boolean} payload.isDraft
   */
  async [MOVE_RFQ]({
    dispatch, commit, state,
  }, {
    hash, to, isDraft,
  }) {
    const rfq = [...state[DRAFT_RFQS], ...state[RFQS]].find((e) => e.hash === hash);
    if (rfq) {
      /* in this case the rfq is removed before the update so there's
      a more seamless user experience and to prevent the user from
      dragging again before the response arrives */
      commit(DELETE, rfq);
      if (rfq.__draft || isDraft) {
        await dispatch(UPDATE_DRAFT, {
          draft: rfq,
          properties: { project_hash: to },
        });
      } else {
        await dispatch(UPDATE_RFQ, {
          rfq,
          properties: { project_hash: to },
        });
      }
      return true;
    }
    return false;
  },

  /**
   * Update from/to projects in sidebar [PROJECTS]
   * @param to hash
   */
  // [PROJECTS] in sidebar are preloaded. We need to preload from/to projects again
  // to show proper rfqs when clicking on them
  // Only when from/to projects are inside sidebar [PROJECTS]
  async [GET_UPDATED_PROJECTS_MRFQ]({ rootState }, { to }) {
    if (rootState[PROJECT_MODULE][PROJECTS]?.find((project) => project.hash === to)) {
      const toQuery = new Project().include([
        'draftRfqs',
        'members.user',
        'members.role',
        'unorderedRfqsCount',
      ]).where('search_hash', to);

      const { data: toProject } = await toQuery.get().catch((e) => e.response);

      const toIndex = rootState[PROJECT_MODULE][PROJECTS]?.findIndex((project) => project.hash === to);
      rootState[PROJECT_MODULE][PROJECTS].splice(toIndex, 1, toProject[0]);
    }
    const from = rootState[PROJECT_MODULE][ACTIVE_PROJECT_HASH];

    const fromQuery = new Project().include([
      'draftRfqs',
      'members.user',
      'members.role',
      'unorderedRfqsCount',
    ]).where('search_hash', from);

    const { data: fromProject } = await fromQuery.get().catch((e) => e.response);

    const fromIndex = rootState[PROJECT_MODULE][PROJECTS]?.findIndex((project) => project.hash === from);
    rootState[PROJECT_MODULE][PROJECTS].splice(fromIndex, 1, fromProject[0]);
  },

  /**
   * Soft deletes a draft-rfq or rfq
   * @param {Object} context
   * @param {Object} model    The draft-rfq/rfq that needs to be removed
   * */
  async [DELETE]({ commit, rootState }, model) {
    const token = localStorage.getItem('gm_access_token');
    const { hash } = model;
    const path = model.__draft ? ENDPOINTS.DRAFT_RFQS.DETAIL : ENDPOINTS.RFQS.DETAIL;
    commit(DELETE, model);
    commit(`${PROJECT_MODULE}/${REMOVE_FROM_PROJECT}`, model, { root: true });

    const stateProject = rootState[PROJECT_MODULE][PROJECTS]?.find((p) => p.hash === model?.project_hash);
    const updatedStateProject = {
      ...stateProject,
      draft_rfqs_count: stateProject.draft_rfqs_count - 1,
    }

    commit(`${PROJECT_MODULE}/${UPDATE}`, { hash: model.project_hash, data: updatedStateProject }, { root: true });

    const response = await Api.delete(path, {
      __pathParams: { hash },
      params: { token },
    }).catch((e) => e.response);
    return response;
  },

  /**
   * Soft deletes a draft-rfq
   * @param {Object} context
   * @param {Array} models    The draft-rfqs that need to be removed
   * */
  async [BATCH_DELETE]({ commit, rootState }, models) {
    try {
      await Api.delete(ENDPOINTS.DRAFT_RFQS.INDEX, {
        params: { hashids: models.map((model) => model.hash) },
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Draft rfq deleted', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: models[0]?.project_hash,
          draftRfqsDeleted: models?.length,
          draftRfqs: models.map((draftRfq) => ({
            name: draftRfq.name,
            fileType: draftRfq.configuration_object?.['file-type']?.slug,
            draftHash: draftRfq.hash,
          })),
        });
      }

      models.forEach((model) => {
        commit(DELETE, model);
        commit(`${PROJECT_MODULE}/${REMOVE_FROM_PROJECT}`, model, { root: true });
      });

      const stateProject = rootState[PROJECT_MODULE][PROJECTS]?.find((p) => p.hash === models?.[0]?.project_hash);
      const updatedStateProject = {
        ...stateProject,
        draft_rfqs_count: stateProject.draft_rfqs_count - models?.length,
      }

      commit(`${PROJECT_MODULE}/${UPDATE}`, { hash: models[0].project_hash, data: updatedStateProject }, { root: true });
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * Appends files to the draft
   * @param context
   * @param {Object} payload
   * @param {string} payload
   * @returns {Promise<Object>}
   */
  // TODO remove this action (it will use the SAVE_DRAFT_UPDATE)
  async [APPEND_FILE]({ commit, rootState }, { draft, file, type }) {
    // const { extension } = getNameAndExtension(file.name);
    // const { hash } = draft;
    //
    // let cleanFile = {
    //   client_original_name: file.name,
    //   bytes: file.size,
    //   mime_type: getMimeType(extension),
    //   extension,
    // };
    //
    // await supportingQueueHandler
    // .add(async () => {
    //   try {
    //     const res = await dispatch(`${UPLOAD_MODULE}/${GET_SIGNED_URL_SUPPORTING}`, {
    //       file: cleanFile,
    //       hash,
    //     }, { root: true }).catch((err) => console.log(err));
    //
    //     await dispatch(`${UPLOAD_MODULE}/${UPLOAD}`,
    //       {
    //         signedUrl: res.signed_url,
    //         type: res.mime_type,
    //         hashName: res.hash_name,
    //         file,
    //         hash,
    //         shouldParse: false,
    //       },
    //       { root: true }).catch((err) => console.log(err));
    //
    //     cleanFile = {
    //       ...cleanFile,
    //       type: res.type,
    //       id: res.id,
    //     };
    //
    //     await commit(SET_PROPERTY, {
    //       model: draft,
    //       property: 'uploads',
    //       value: [...draft.uploads, cleanFile],
    //     });
    //
    //     await dispatch(DRAFT_DETAIL, draft);
    //   } catch (e) {
    //     const message = 'We couldn\'t upload your file!';
    //     commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
    //       message,
    //       type: 'is-danger',
    //       icon: 'exclamation-circle',
    //     }, { root: true });
    //   }
    // });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type || DRAFT_RFQ_SUPPORTING_FILE);
    const { data, status } = await supportingQueueHandler.add(() => Api.post(
      ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
      formData,
      {
        __pathParams: { hash: draft.hash },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )).catch((e) => e.response);
    if (status < 300) {
      commit(SET_PROPERTY, {
        model: draft,
        property: 'uploads',
        value: [...draft.uploads, data],
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Supporting file created', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: draft?.project_hash,
          draftHash: draft.hash,
          supportingFile: {
            name: data.client_original_name,
            fileType: data.extension,
          },
        });
      }
    } else {
      const message = data?.message || 'We couldn\'t upload your file!';
      commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
        message,
        type: 'is-danger',
        icon: 'exclamation-circle',
      }, { root: true });
    }
    return data;
  },

  /**
   * Load and set presets for configurator
   * @param {Object} context
   * @returns {Promise<Object>}
   */
  async [GET_PRESETS]({ commit }) {
    const { data } = await Api.get(ENDPOINTS.PRESETS.INDEX, { params: { include: 'configurationProperties' } });
    commit(SET_PRESETS, data);
    return data;
  },

  /**
   * Attach files that were uploaded while the user was not signed in
   * @param {Object} context
   * @returns {Promise<Object>}
   * @param user_id
   */
  async [ATTACH_RFQS]({ state, dispatch, rootState }, { user_id }) {
    if (state[DRAFT_RFQS].length && user_id) {
      for (const draft of state[DRAFT_RFQS]) {
        await dispatch(UPDATE_DRAFT, {
          draft,
          properties: { user_id },
          immediate: true,
        });
      }
    }
    return null;
  },

  async [REMOVE_SUPPORTING_FILE]({ state, commit, rootState }, { part, file }) {
    try {
      const response = await Api.delete(ENDPOINTS.DRAFT_RFQS.DELETE_UPLOADED, {
        __pathParams: {
          hash: part.hash,
          id: file.id,
        },
      });
      const draft = state[DRAFT_RFQS].find((d) => d.hash === part.hash);
      commit(UPDATE_DRAFT, {
        ...draft,
        uploads: draft.uploads.filter((u) => u.id !== file.id),
      });

      // SEGMENT TRACKING
      if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
        window.analytics.track('Supporting file deleted', {
          isMasquerading: !!rootState[AUTH_MODULE][MASQUERADING],
          projectHash: part?.project_hash,
          draftHash: part.hash,
          supportingFile: {
            name: file.client_original_name,
            fileType: file.extension,
          },
        });
      }

      return response;
    } catch (e) {
      return e.response;
    }
  },

  async [SWAP_SUPPORTING_FILES]({ state, commit }, {
    file,
    originalOwner,
    newOwner,
  }) {
    const oldPart = state[DRAFT_RFQS].find((e) => e.hash === originalOwner);
    const newPart = state[DRAFT_RFQS].find((e) => e.hash === newOwner);

    let oldUploads;
    let newUploads;

    if (oldPart) {
      oldUploads = [...oldPart.uploads];
      commit(UPDATE_DRAFT, {
        ...oldPart,
        uploads: oldPart.uploads.filter((e) => e.id !== file.id),
      });
    }

    if (newPart) {
      newUploads = [...newPart.uploads];
      commit(UPDATE_DRAFT, {
        ...newPart,
        uploads: [...(newPart.uploads || []), file],
      });
    }

    try {
      await Api.put(
        ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
        { detach: file.id },
        {
          __pathParams: {
            hash: originalOwner,
          },
        },
      );
      await Api.put(
        ENDPOINTS.DRAFT_RFQS.MANAGE_UPLOADED,
        { attach: file.id },
        {
          __pathParams: {
            hash: newOwner,
          },
        },
      );
    } catch (e) {
      if (oldPart) {
        oldUploads = [...oldPart.uploads];
        commit(UPDATE_DRAFT, {
          ...oldPart,
          uploads: oldUploads,
        });
      }

      if (newPart) {
        newUploads = [...newPart.uploads];
        commit(UPDATE_DRAFT, {
          ...newPart,
          uploads: newUploads,
        });
      }
    }
  },

  async [DRAFT_CLONE]({ state, commit }, draft) {
    let newDraft = null;
    try {
      const { data: { data } } = await Api.post(
        ENDPOINTS.DRAFT_RFQS.CLONE,
        {},
        {
          __pathParams: { hash: draft.hash },
        },
      );
      // For better performance we can skimp on what we retrieve from
      // the b/e if we simply clone the origin and update the hash.
      const deepCopy = {
        ...structuredClone(draft),
        hash: data.hash,
        quote_id: data.quote_id,
        configuring: false,
        selected: false,
        is_in_current_user_cart: false,
        cart_item_id: null,
        status: data.status,
        quote_status: data.quote_status,
        version: data.version,
      };
      newDraft = deepCopy;
      const i = state[DRAFT_RFQS].indexOf(draft);

      state[DRAFT_RFQS].splice(i + 1, 0, deepCopy);
      commit(`${PROJECT_MODULE}/${ADD_DRAFT}`, deepCopy, { root: true })
    } catch (err) {
      console.log(err);
    }

    return newDraft;
  },

  async [GET_PREVIOUS_QUOTES]({ state, commit }, { hash }) {
    try {
      const { data: { data } } = await Api.get(ENDPOINTS.RFQS.INDEX, {
        params: {
          filter: {
            draft_hash: hash,
            type: 'manual',
            not_ordered: 'true',
            not_cancelled: 'true',
            created_after: '2024-01-01+00:00:00',
          },
          previous_quotes: true,
          sort: '-quote_proposals.sent_at',
          include: 'configurationProperties,sentQuotes,uploads',
        },
      });

      commit(SET_PREVIOUS_QUOTES, data);

      return data;
    } catch (err) {
      throw err;
    }
  },

  async [SET_PREVIOUS_QUOTE]({ state, commit }, { hash, rfq_id }) {
    try {
      const { data: { data } } = await Api.put(ENDPOINTS.DRAFT_RFQS.SET_QUOTE, {}, {
        __pathParams: { hash, rfq_id },
      });

      return data;
    } catch (err) {
      throw err;
    }
  },
}
