<template>
  <section class="main-aside tw-bg-primary tw-flex tw-h-full tw-flex-col tw-relative">
    <section class="main-aside-body">
      <project-sidebar />
      <div class="tw-flex-grow hidden lg:tw-flex" />
      <LoginRegistration />
    </section>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { AUTH_MODULE, LOGGED_IN } from '../../store/modules/auth/types';
import { METADATA, USER_DATA, USER_MODULE } from '../../store/modules/user/types';
import LoginRegistration from '../login-registration/login-registration.vue';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '../../store/modules/projects/types';
import ProjectSidebar from '@/app-buyer/components/sidebar/ProjectSidebar.vue';
import NavbarNotifications from '@/app-buyer/components/navbar/navbar-notifications.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'SideNavbar',

  components: {
    LoginRegistration,
    FontAwesomeIcon,
    NavbarNotifications,
    ProjectSidebar,
  },

  data() {
    return {
      chatReady: false,
      chatLoading: false,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapState(USER_MODULE, {
      METADATA,
      userData: USER_DATA,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
  },

  created() {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    if (window.HubSpotConversations) {
      this.chatReady = true;
      if (window.HubSpotConversations.widget) {
        window.HubSpotConversations.widget.remove();
      }
    } else {
      window.hsConversationsOnReady = [
        () => {
          this.chatReady = true;
        },
      ];
    }
  },
};
</script>

<style
  lang="scss"
  scoped
>

.main-aside {
  box-shadow: inset 0 0 0 1px #02182b;
  z-index: 40;

  @media all and (max-width: variables.$tablet) {
    flex-direction: row;
    height: auto;
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media all and (max-width: variables.$tablet) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 0;

      ul {
        display: flex;
      }
    }

    .sidebar-item {
      color: white;
      opacity: 0.8;
      border-radius: theme('borderRadius.xs');

      @media all and (max-width: variables.$tablet) {
        padding: 0.5rem;
      }

      span.count {
        min-width: theme('spacing.4');
        background-color: theme('colors.white');
      }

      &:hover {
        background-color: theme('colors.primary-light');
        color: theme('colors.secondary-hover');
        opacity: 1;

        span.count {
          background-color: theme('colors.secondary-hover');
          color: theme('colors.primary-light');
        }
      }
    }

    .router-link-active .sidebar-item {
      background-color: theme('colors.primary-light-pressed');
      color: theme('colors.white');
      opacity: 1;

      span.count {
        background-color: theme('colors.white');
        color: theme('colors.primary-light-pressed');
      }
    }
  }

  &-footer {
    flex-grow: 0;
  }
}

.side-nav-bottom {
  padding-bottom: 45px;

  @media all and (max-width: variables.$tablet) {
    flex-grow: 0;
    padding-bottom: 0;
  }
}

.main-aside-element-hover-child {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
  z-index: 1;
  max-width: 0;
  overflow: hidden;
  transform: translateX(100%);
  transition: max-width .5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.active {
    max-width: 1000px;
  }
}
</style>
