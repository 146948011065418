<template>
  <div>
    <g-button
      isInverted
      type="icon-circle"
      color="tertiary"
      size="sm"
      fontWeight="normal"
      iconLeft="user-plus"
      @click="handleInviteModal(true)"
    />
    <BModal
      has-modal-card
      :active.sync="showInviteModal"
    >
      <ProjectMemberInviteModal
        :project="project"
        @update:project="$emit('update:project', $event)"
        @close="handleInviteModal(false)"
      />
    </BModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ProjectMemberInviteModal from '@/app-buyer/components/project/ProjectMemberInviteModal.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'ProjectMemberInvite',
  components: {
    ProjectMemberInviteModal,
    GButton,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInviteModal: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
  },
  methods: {
    handleInviteModal(e) {
      this.showInviteModal = e;
      if (!e) return;

      // SEGMENT TRACKING
      if (!getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) return;
      window.analytics.track('Members opened', {
        isMasquerading: !!this[MASQUERADING],
        projectHash: this.project?.hash,
        memberLength: this.project?.members?.length,
      });
    },
  },
};
</script>
