<template>
  <div
    class="project-wrapper tw-grid tw-min-h-full"
    :class="{ 'logged-in' : LOGGED_IN }"
  >
    <b-loading
      :is-full-page="true"
      v-model="loading"
    />
    <div
      :class="{ 'tw-pb-16 xl:tw-pb-20' : LOGGED_IN }"
      class="project-content-wrapper tw-bg-grey-lighter tw-relative"
    >
      <ProjectContentHeader
        v-if="!!ACTIVE_PROJECT"
        :active-project="ACTIVE_PROJECT"
      />
      <ProjectContent
        ref="projectContent"
        @request-quotes="requestQuotes($event)"
        @add-to-cart="attemptAddToCart($event)"
        @remove-from-cart="handleRemoveFromCart($event)"
      />
    </div>
    <div
      v-if="LOGGED_IN && IS_DESKTOP"
      class="part-form-wrapper is-hidden-touch tw-flex tw-py-3 tw-px-4"
    >
      <PartForm
        @request-quotes="requestQuotes($event)"
        @remove-from-cart="handleRemoveFromCart($event)"
      />
      <g-modal
        :is-active.sync="quoteNoteConfirmationActive"
        :can-cancel="['escape']"
        width="1000"
        modalWidth="tw-w-full"
        modalHeight="tw-h-full"
      >
        <confirm-notes-modal
          v-if="quoteNoteConfirmation"
          :quotes="quoteNoteConfirmation"
          @add-to-cart="attemptAddToCart($event)"
          @close="handleCloseNotesModal"
        />
      </g-modal>
    </div>
    <PartFormFooter
      v-if="LOGGED_IN"
      @request-quotes="requestQuotes($event)"
      @add-to-cart="attemptAddToCart($event)"
      @remove-from-cart="handleRemoveFromCart($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import {
  ACTIVE_PROJECT, ACTIVE_PROJECT_HASH,
  LISTEN_PROJECT_CHANNEL,
  PROJECT_MODULE,
  PROJECTS,
  SET_ACTIVE_PROJECT,
} from '@/app-buyer/store/modules/projects/types';
import { CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import PartForm from '@/app-buyer/components/project/PartForm.vue';
import ProjectContentHeader from '@/app-buyer/components/project/ProjectContentHeader.vue';
import ProjectContent from '@/app-buyer/components/project/ProjectContent.vue';
import {
  CREATE_RFQS,
  DRAFT_COUNT,
  DRAFT_DETAIL,
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  INSTANT_QUOTE_RFQ_IDS,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
  SET_INSTANT_QUOTE_RFQ_IDS,
  SET_PROPERTY,
  SET_SELECTED_PART_HASHES,
  UPLOADED_DRAFTS,
} from '@/app-buyer/store/modules/rfq/types';
import { QUOTES_MODULE, SET_QUOTES } from '@/app-buyer/store/modules/quotes/types';
import PartFormFooter from '@/app-buyer/components/project/PartFormFooter.vue';
import confirmConfigurationPrompt from '@/app-buyer/mixins/confirm-configuration';
import {
  AUTH_MODULE,
  FORCE_AUTH,
  LOGGED_IN,
  UPLOAD_BEFORE_AUTH,
} from '@/app-buyer/store/modules/auth/types';
import { ADD, DELETE, GET, SET } from '@/app-buyer/store/modules/types';
import emailVerification from '@/app-buyer/mixins/email-verification';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { GET_USER_ID, USER_COUNTRY, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import RfqSuccessModalContent from '@/app-buyer/components/project/RfqSuccessModalContent.vue';
import RfqPendingModalContent from '@/app-buyer/components/project/RfqPendingModalContent.vue';
import notificationInjection from '@/shared/components/notification/notification-injection-mixin';
import RfqUnsuccessfulModalContent
  from '@/app-buyer/components/project/RfqUnsuccessfulModalContent.vue';
import leadTimeCalculator from '../../mixins/lead-time-calculator';
import addRemoveQuoteCart from '@/app-buyer/mixins/add-remove-quote-cart';
import ConfirmNotesModal from '@/app-buyer/components/grouped-quotes/ConfirmNotesModal.vue';
import GModal from '@/app-buyer/components/GModal.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';

const getCustomNotes = (draft) => {
  const customNotes = localStorage.getItem(`notes_${draft.hash}`);
  const { notes } = draft;
  if (customNotes) {
    const customNotesObject = JSON.parse(customNotes);
    const notePairs = Object.entries(customNotesObject).map(([entity, note]) => `${entity}: ${note}`);

    return `${notes ?? ''}${notePairs.join(', ')}`;
  }
  return notes;
};

export default {
  name: 'Project',

  components: {
    GModal,
    ConfirmNotesModal,
    PartFormFooter,
    ProjectContent,
    ProjectContentHeader,
    PartForm,
  },

  mixins: [
    confirmConfigurationPrompt,
    emailVerification,
    partSelectMixin,
    notificationInjection,
    leadTimeCalculator,
    addRemoveQuoteCart,
  ],

  provide() {
    return {
      projectContext: this.projectContext,
      setHighlightedParts: this.setHighlightedParts,
    };
  },

  props: {
    [URL_PARAMS.PROJECT_HASH]: {
      type: [Number, String],
      default: null,
    },

    [URL_PARAMS.DRAFT_HASH]: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      projectContext: {
        highlightedParts: [],
        submitting: false,
      },
      buefyModal: null,
      quoteNoteConfirmation: null,
      quoteNoteConfirmationActive: false,
      loading: false,
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
      UPLOAD_BEFORE_AUTH,
    }),

    ...mapState(USER_MODULE, {
      USER_COUNTRY,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapState(PROJECT_MODULE, {
      PROJECTS,
      ACTIVE_PROJECT_HASH,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      UPLOADED_DRAFTS,
      DRAFT_COUNT,
      SELECTED_PART_HASHES,
      INSTANT_QUOTE_RFQ_IDS,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),

    submittableDrafts() {
      return this[DRAFT_RFQS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];
        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    submittableSelectedDrafts() {
      return this[GET_ALL_SELECTED_PARTS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];

        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    allManualQuotes() {
      return this.submittableDrafts?.filter((s) => {
        const modelFileType = s.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model')?.extension?.toLowerCase();
        if ((MANUAL_QUOTE_EXTENSIONS.includes(modelFileType) && !s.status) || s.status === 'manual') return s;
      });
    },

    selectedManualQuotes() {
      return this.submittableSelectedDrafts?.filter((s) => {
        const modelFileType = s.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model')?.extension?.toLowerCase();
        if ((MANUAL_QUOTE_EXTENSIONS.includes(modelFileType) && !s.status) || s.status === 'manual') return s;
      });
    },

    allQuotes() {
      return this.submittableDrafts.filter((d) => d.quote_id);
    },

    selectedQuotes() {
      return this.submittableSelectedDrafts.filter((d) => d.quote_id);
    },
  },

  watch: {
    [URL_PARAMS.PROJECT_HASH]: {
      async handler(hash) {
        const project = this[PROJECTS]?.find((p) => p.hash === hash);
        if (!project || this[ACTIVE_PROJECT_HASH] === hash) return;
        await this[SET_ACTIVE_PROJECT]({ project, noRedirect: true });
        if (this[DRAFT_RFQS][0]?.hash && !this[SELECTED_PART_HASHES]?.length) this.selectParts(this[DRAFT_RFQS][0]?.hash);
      },
      immediate: true,
    },

    [URL_PARAMS.DRAFT_HASH]: {
      handler(hash) {
        this[SET_SELECTED_PART_HASHES](hash ? hash.split(',') : []);
      },
      immediate: true,
    },

    // This watcher is here to catch when a draft_rfq has been uploaded before
    // a user has been authenticated. This will trigger only after all
    // the draft_rfq(s) have been updated with the users id, then
    // it will call the project GET and set the active project.
    async [UPLOADED_DRAFTS](nv) {
      if (this[UPLOAD_BEFORE_AUTH] && this[DRAFT_COUNT] === nv && this[LOGGED_IN]) {
        await this[GET]({ clear: true });
        const project = this[ACTIVE_PROJECT] || this[PROJECTS][0];
        this[SET_ACTIVE_PROJECT]({ project, force: true });
        this[SET_SELECTED_PART_HASHES]([project.draft_rfqs[0]?.hash]);
      }
    },
  },

  created() {
    this.emailVerificationToast();
    this.extractUserlessDrafts();
  },

  mounted() {
    const element = document.getElementById('router-view-container');
    if (element) element.style.overflowY = 'hidden';
  },

  beforeDestroy() {
    const element = document.getElementById('router-view-container');
    if (element) element.style.overflowY = 'auto';
  },

  methods: {
    ...mapActions(PROJECT_MODULE, {
      SET_ACTIVE_PROJECT,
      LISTEN_PROJECT_CHANNEL,
      GET,
    }),

    ...mapMutations(RFQ_MODULE, {
      SET_SELECTED_PART_HASHES,
      SET_INSTANT_QUOTE_RFQ_IDS,
      SET_PROPERTY,
      SET,
    }),

    ...mapActions(RFQ_MODULE, {
      CREATE_RFQS,
      DRAFT_DETAIL,
    }),

    ...mapActions(CART_MODULE, {
      ADD,
      DELETE,
    }),

    ...mapMutations(QUOTES_MODULE, {
      SET_QUOTES,
    }),

    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),

    setHighlightedParts(ids) {
      this.projectContext.highlightedParts = ids;
    },

    requestQuotes(selected = false) {
      if ((!selected && !this.submittableDrafts.length) || (selected && !this.submittableSelectedDrafts.length)) return;
      this.confirmConfiguration(selected).then(this.finaliseRequests);
    },

    async finaliseRequests({ selected = false }) {
      this.projectContext.submitting = true;
      const manualQuotes = selected ? this.selectedManualQuotes : this.allManualQuotes;

      const rfqsToRequest = manualQuotes.map((draft) => ({
        ...draft,
        delivery_country: this[USER_COUNTRY],
        notes: getCustomNotes(draft),
      }));

      try {
        this.handleProgressModal({ propData: rfqsToRequest.length, status: 'pending' });
        const { data } = await this[CREATE_RFQS]({ draftOrRfq: rfqsToRequest });
        this.handleProgressModal({ status: 'successful' });

        // update the rfq count when a draft is submitted
        const updatedRfqsCount = rfqsToRequest.map((rfq) => {
          return {
            ...rfq,
            pending_rfqs_count: rfq.pending_rfqs_count + 1,
            quote_status: 'Awaiting quote',
            status: 'awaiting',
          };
        });

        // Update data in RFQ_MODULE
        this[SET]({ draftRfqs: updatedRfqsCount });
        // Update data in Project
        const projectToUpdate = this.$store.state[PROJECT_MODULE]?.[PROJECTS]?.find((p) => p.hash === rfqsToRequest?.[0]?.project_hash);
        const draftRfqToUpdate = projectToUpdate?.draft_rfqs?.find((rfq) => rfq.hash === rfqsToRequest?.[0]?.hash);
        draftRfqToUpdate.quote_status = 'Awaiting quote';
        draftRfqToUpdate.status = 'awaiting';

        // GTM event for successful rfq submit
        if (this.$gtm && data.length) {
          data.forEach(() => {
            this.$gtm.trackEvent({
              event: 'user-action',
              'user-action-type': 'submit-quote',
              user_id: this[GET_USER_ID],
            });
          });
        }
        // SEGMENT TRACKING TO REMOVE
      } catch (error) {
        console.log(error);
        this.handleProgressModal({ propData: error.data, status: 'unsuccessful' });
      } finally {
        this.submitting = false;
        this.projectContext.submitting = false;
      }
    },

    handleCloseNotesModal() {
      this.quoteNoteConfirmationActive = false;
      this.quoteNoteConfirmation = null;
    },

    attemptAddToCart({ addAllQuoted = false }) {
      const quotes = addAllQuoted ? this.allQuotes : this.selectedQuotes;
      const quotesWithNotes = quotes.filter((d) => d.quote_notes);

      if (!quotesWithNotes?.length) return this.handleAddToCart({ drafts: quotes });

      const quotesNeedingNoteApproval = [];

      quotesWithNotes.forEach((q) => {
        const hasBeenApproved = localStorage.getItem(`notes_${q.hash}_quote_${q.quote_id}`);
        if (!hasBeenApproved) quotesNeedingNoteApproval.push(q);
      });

      if (quotesNeedingNoteApproval?.length) {
        this.quoteNoteConfirmation = quotesNeedingNoteApproval;
        this.quoteNoteConfirmationActive = true;
      } else {
        this.$refs.projectContent.forceClearNoteNotifications();
        this.handleAddToCart({ drafts: quotes });
      }
    },

    async handleAddToCart({ drafts }) {
      this.loading = true;

      try {
        await this.addMultipleQuotesToCart({
          quote_ids: drafts.map((d) => d.quote_id),
          parts: drafts,
        });
        this.selectParts(...drafts.map((d) => d.hash));
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveFromCart({ removeAllInCart = false }) {
      const draftsInCart = removeAllInCart ? this[DRAFT_RFQS].filter((d) => d.cart_item_id) : this[GET_ALL_SELECTED_PARTS].filter((s) => s.cart_item_id);
      this.loading = true;

      try {
        await this.removeQuotesFromCart({
          ids: draftsInCart.map((d) => d.cart_item_id),
          parts: draftsInCart,
        });
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },

    handleProgressModal({ propData, status }) {
      let returnedComponent = RfqPendingModalContent;
      if (status === 'successful') returnedComponent = RfqSuccessModalContent;
      if (status === 'unsuccessful') returnedComponent = RfqUnsuccessfulModalContent;

      if (this.buefyModal) this.buefyModal.close();

      this.buefyModal = this.$buefy.modal.open({
        parent: this,
        component: returnedComponent,
        props: {
          ...propData,
        },
        canCancel: status === 'successful' || status === 'unsuccessful',
        width: '600px',
        hasModalCard: true,
        trapFocus: true,
      });
    },

    async extractUserlessDrafts() {
      const hashIds = this.$route.query.drafts ? JSON.parse(this.$route.query.drafts) : [];
      const calls = hashIds.map((hash) => this[DRAFT_DETAIL]({ hash }));
      const responses = await Promise.all(calls);

      const drafts = responses.map((r) => {
        try {
          const { data: { data } } = r;
          return data;
        } catch {
          return null;
        }
      });

      if (!drafts.length) return;

      this.selectParts(drafts[0].hash);

      this[SET]({ draftRfqs: drafts, clear: true });

      this[LISTEN_PROJECT_CHANNEL](drafts[0]?.project_hash);

      if (!this[LOGGED_IN]) {
        const hasAccount = localStorage.getItem('gm_has_account');
        this[FORCE_AUTH](hasAccount ? 'login' : 'register');
      }
    },


  },
};
</script>

<style
  lang="scss"
  scoped
>
.project-wrapper {
  &.logged-in {
    grid-template-columns: 1fr;

    @media (min-width: 1280px) {
      grid-template-columns: 4fr 2.28fr;
    }
  }
}

.part-form-wrapper {
  @media all and (max-width: 1500px) {
    padding: 1rem;
  }
}

.project-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.project-content-wrapper,
.part-form-wrapper {
  height: calc(100vh - var(--navbar-height));
  min-height: 0;
  overflow: auto;
}
</style>
