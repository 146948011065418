export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL';
export const SET_FORM_MODE = 'SET_FORM_MODE';
export const FORM_MODE = 'FORM_MODE';
export const LOGIN_MODAL_VISIBLE = 'LOGIN_MODAL_VISIBLE';
export const PROFILE_MODAL_VISIBLE = 'PROFILE_MODAL_VISIBLE';
export const SET_PROFILE_MODAL = 'SET_PROFILE_MODAL';
export const IS_LOGIN_MODAL_VISIBLE = 'IS_LOGIN_MODAL_VISIBLE';
export const GET_LOGIN_MODAL_MODE = 'GET_LOGIN_MODAL_MODE';
export const LOGIN_MODAL_MODE = 'LOGIN_MODAL_MODE';
export const RFQ_DETAIL_MODE = 'RFQ_DETAIL_MODE';
export const SET_RFQ_DETAIL_MODE = 'SET_RFQ_DETAIL_MODE';
export const FORCE = 'FORCE';
export const RFQ_ENGINEER_VIEW = 'RFQ_ENGINEER_VIEW';
export const SET_RFQ_ENGINEER_MODE = 'SET_RFQ_ENGINEER_MODE';
export const SHOW_FILE_SERVICE_TOOLTIP = 'SHOW_FILE_SERVICE_TOOLTIP';
export const SET_SHOW_FILE_SERVICE_TOOLTIP = 'SET_SHOW_FILE_SERVICE_TOOLTIP';
export const ON_LOGIN_REDIRECT = 'ON_LOGIN_REDIRECT';
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP';
export const IS_DESKTOP = 'IS_DESKTOP';
export const NAVIGATION_MODULE = 'NAVIGATION_MODULE';
export const SET_IS_MOBILE_SIDEBAR_OPEN = 'SET_IS_MOBILE_SIDEBAR_OPEN';
export const IS_MOBILE_SIDEBAR_OPEN = 'IS_MOBILE_SIDEBAR_OPEN';
