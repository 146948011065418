<template>
  <div
    class="tw-inline-flex tw-aspect-square tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer"
    :class="[elSize, elColor]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
    />
    <p
      v-else
      class="tw-m-0 tw-font-bold tw-uppercase"
    >{{ initials }}</p>
  </div>
</template>

<script>
import { getInitials } from '@common/helpers/index';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GInitialsTag',

  components: {
    FontAwesomeIcon,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'secondary',
      validator: (value) => [
        'primary',
        'primary-light-pressed',
        'secondary',
        'tertiary',
        'tertiary-light',
        'tertiary-muted',
        'error',
        'error-light',
        'error-muted',
        'red-muted',
        'success',
        'success-light',
        'success-muted',
        'purple',
        'purple-light',
        'purple-muted',
        'pink-muted',
        'pending',
        'pending-light',
        'grey-dark',
        'grey-light',
        'white',
        'transparent',
      ].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
  },

  computed: {
    initials() {
      return getInitials({ name: this.name });
    },
    elSize() {
      if (this.size === 'sm') return 'tw-p-1 tw-text-sm tw-w-7 tw-h-7';
      if (this.size === 'lg') return 'tw-p-4 tw-text-xl tw-w-12 tw-h-12';

      return 'tw-p-2 tw-text-lg tw-w-10 tw-h-10';
    },

    elColor() {
      if (this.color === 'primary') return `tw-text-white tw-bg-primary`;
      if (this.color === 'primary-light-pressed') return `tw-text-white tw-bg-primary-light-pressed`;
      if (this.color === 'tertiary') return `tw-text-white tw-bg-tertiary`;
      if (this.color === 'tertiary-light') return `tw-text-primary tw-bg-tertiary-light`;
      if (this.color === 'tertiary-muted') return `tw-text-white tw-bg-tertiary-muted`;
      if (this.color === 'error') return `tw-text-white tw-bg-error`;
      if (this.color === 'error-light') return `tw-text-primary tw-bg-error-light`;
      if (this.color === 'error-muted') return `tw-text-white tw-bg-error-muted`;
      if (this.color === 'red-muted') return `tw-text-white tw-bg-red-muted`;
      if (this.color === 'purple') return `tw-text-white tw-bg-purple`;
      if (this.color === 'purple-light') return `tw-text-primary tw-bg-purple-light`;
      if (this.color === 'purple-muted') return `tw-text-white tw-bg-purple-muted`;
      if (this.color === 'pink-muted') return `tw-text-white tw-bg-pink-muted`;
      if (this.color === 'success') return `tw-text-white tw-bg-success`;
      if (this.color === 'success-light') return `tw-text-primary tw-bg-success-light`;
      if (this.color === 'success-muted') return `tw-text-white tw-bg-success-muted`;
      if (this.color === 'pending') return `tw-text-white tw-bg-pending`;
      if (this.color === 'pending-light') return `tw-text-primary tw-bg-pending-light`;
      if (this.color === 'grey-dark') return `tw-text-primary tw-bg-grey-dark`;
      if (this.color === 'grey-light') return `tw-text-grey-darkest tw-bg-grey-light`;
      if (this.color === 'white') return `tw-text-primary tw-bg-white tw-inset tw-ring-1 tw-ring-grey-lightest`;
      if (this.color === 'transparent') return `tw-text-primary tw-bg-transparent tw-shadow-none`;
      // secondary is default
      return `tw-text-primary tw-bg-secondary`;
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>

</style>
