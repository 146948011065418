<template>
  <div>
    <g-button
      :label="btnText"
      isInverted
      size="sm"
      color="tertiary"
      type="primary-icon"
      class="tw-justify-center tw-whitespace-nowrap"
      @click="handleClick"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { RFQ_MODULE, SET_PRODUCTION_REQUIREMENTS_MODAL } from '@/app-buyer/store/modules/rfq/types';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'ProductionRequirementsButton',

  components: {
    GButton,
  },

  mixins: [partSelectMixin],
  props: {
    part: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_PRODUCTION_REQUIREMENTS_MODAL,
    }),
    async handleClick() {
      await this.selectParts(this.part.hash);
      this[SET_PRODUCTION_REQUIREMENTS_MODAL](true);
      setTimeout(() => {
        this[SET_PRODUCTION_REQUIREMENTS_MODAL](false);
      }, 300);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

</style>
