<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
  >
    <BField
      v-bind="$attrs"
      :type="fieldType
        || ((errors[0]
          || (customErrors && customErrors[0]))
          && showError
          && 'is-danger')
        || (valid
          &&
          showValid
          && 'is-success')
        || '' "
      :label-position="labelPosition"
      :custom-class="customClass"
      :message="[
        message,
        ...(showError
          ? [...errors, ...(customErrors || [])]
          : [])
      ]"
    >
      <template
        v-if="$slots.label"
        #label
      >
        <slot name="label" />
      </template>
      <BCheckbox
        v-if="inputType === 'checkbox'"
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot />
      </BCheckbox>
      <BRadio
        v-else-if="inputType === 'radio'"
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot />
      </BRadio>
      <BDropdown
        v-else-if="inputType === 'dropdown'"
        v-model="innerValue"
      >
        <template #trigger="{ active }">
          <slot
            name="trigger"
            :active="active"
          />
        </template>
        <slot />
      </BDropdown>
      <BInput
        v-else-if="trim"
        v-model.trim="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <BInput
        v-else
        v-model="innerValue"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </BField>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'BInputWithValidator',
  components: {
    ValidationProvider,
  },
  inheritAttrs: false,
  props: {
    vid: {
      type: String,
      default: () => '',
    },
    showValid: {
      type: Boolean,
      default: () => false,
    },
    showError: {
      type: Boolean,
      default: () => true,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: () => '',
    },
    inputType: {
      type: String,
      default: () => null,
    },
    fieldType: {
      type: String,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    labelPosition: {
      type: String,
      default: () => null,
    },
    customClass: {
      type: String,
      default: () => 'mb-0',
    },
    customErrors: {
      type: Array,
      default: () => null,
    },
    trim: Boolean,
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>

<style scoped>
::v-deep .help.is-danger {
  font-size: theme('fontSize.sm');
  padding-top: 0.5rem;
}
</style>
