<template>
  <div
    v-if="userData && userData.user && !userData.user.email_verified_at"
    class="container"
  >
    <div class="card box">
      <b-message
        has-icon
        size="is-small"
        type="is-warning"
      >
        <p class="title is-7">
          Email address not verified! Please verify your email address
          by clicking on the link we have sent to your email address.
        </p>
        <b-button
          type="is-warning"
          @click.prevent="submit"
        >
          Resend email
        </b-button>
      </b-message>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { USER_DATA, USER_MODULE } from '../../store/modules/user/types';
import notificationInjection from '../../../shared/components/notification/notification-injection-mixin';
import Api from '../../api/api';
import ENDPOINTS from '../../api/endpoints';

const successMessage = 'Verification email sent, please check your mailbox';
const errorMessage = 'An error occurred. Please try again later';

export default {
  name: 'UserProfileEmailVerification',
  mixins: [notificationInjection],
  computed: {
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
  },
  methods: {
    async submit() {
      const { data, status } = await Api.post(ENDPOINTS.AUTH.RESEND_VERIFICATION_EMAIL)
        .catch((e) => e.response);
      if (status < 300) {
        this._addNotification({
          message: successMessage,
          type: 'is-success',
        });
      } else {
        this._addNotification({
          message: data?.error || errorMessage,
          type: 'is-danger',
        });
      }
    },
  },
};
</script>
