<template>
  <div class="">
    <g-button
      :isFullWidth="isActionsDropdown"
      :isInverted="isActionsDropdown"
      :isDisabled="isAnalysing"
      :hasIconSpinning="isAnalysing"
      :label="isAnalysing ? 'Analysing DFM' : 'DFM Passed'"
      :iconLeft="isAnalysing ? 'sync' : 'check-circle'"
      type="primary-icon"
      :color="isActionsDropdown ? 'tertiary' : 'transparent'"
      size="sm"
      fontWeight="medium"
      :class="{ 'chin-btn' : !isActionsDropdown }"
      :style="isActionsDropdown ?'justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;' : ''"
      @click="isActive = true"
    />

    <b-modal
      :active.sync="isActive"
      has-modal-card
      :height="modalHeight"
      :width="modalWidth"
    >
      <div class="modal-card tw-mx-auto">
        <header class="modal-card-head tw-bg-white tw-border-grey-light tw-border-solid tw-border-b tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-flex-col tw-items-start">
            <p class="tw-font-bold tw-text-lg">DFM Analysis</p>
            <p class="tw-text-md">{{ part.name }}</p>
          </div>
          <button @click="isActive = false" class="tw-p-2 tw-cursor-pointer">
            <font-awesome-icon icon="times" class="tw-text-lg" />
          </button>
        </header>
        <div class="modal-card-body tw-flex tw-rounded-b-xs xl:tw-grid">
          <div class="tw-w-full tw-p-5 tw-overflow-auto">
            <div
              v-for="info in cleanDfmInfo"
              class="dfm-row tw-grid tw-py-3 tw-px-4 tw-text-md tw-items-center"
              :class="info.isHighlighted ? 'tw-bg-success tw-bg-opacity-20 tw-rounded-xs' : 'tw-border-grey-light tw-border-b'"
            >
              <font-awesome-icon :icon="info.icon" class="tw-mr-2 tw-text-success" :class="{ 'tw-text-lg' : info.isHighlighted }" />
              <p class="tw-font-bold">{{ info.title }}</p>
              <div class=""></div>
              <p v-if="info.body">{{ info.body }}</p>
            </div>
          </div>
          <div v-if="IS_DESKTOP" class="">
            <g-serve-editor
              v-if="parser === 'g-serve'"
              :part="part"
              :is-part-only="true"
            />
            <autodesk-viewer
              v-else-if="parser === 'autodesk'"
              :urn="uuid"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import GServeEditor from '@/app-buyer/components/project/GServeEditor.vue';
import AutodeskViewer from '@/app-buyer/components/viewer/viewers/autodesk-viewer.old.vue';
import { part3DconfigMixin } from '@/app-buyer/components/project/mixins';
import { dfmInfo } from '@/app-buyer/components/project/Dfm-info';
import { mapState } from 'vuex';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'DfmModal',

  components: {
    AutodeskViewer,
    GServeEditor,
    GButton,
  },

  mixins: [part3DconfigMixin],

  props: {
    part: {
      type: Object,
      required: true,
    },
    isAnalysing: {
      type: Boolean,
      default: false,
    },
    isActionsDropdown: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isActive: false,
    }
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    cleanDfmInfo() {
      return dfmInfo[this.part.configuration_object?.service?.slug];
    },

    modalWidth() {
      if (!this[IS_DESKTOP]) return '80vw';
      return '1000px';
    },

    modalHeight() {
      if (!this[IS_DESKTOP]) return '80vh';
      return '600px';
    },
  },

  methods: {},
}
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  button {
    &.chin-btn {
      color: theme('colors.tertiary') !important;
    }
  }
}

.modal-card {
  width: 80vw;
  max-height: 80vh;

  @media (min-width: 1280px) {
    width: 1000px;
    height: 600px;
  }

  .modal-card-body {
    padding: 0;

    @media (min-width: 1280px) {
      grid-template-columns: 45% 55%;
    }

    .dfm-row {
      min-height: theme('spacing.16');
      grid-template-columns: 8% 92%;

      &:last-child {
        border-bottom: none;
      }

      @media (min-width: 768px) {
        grid-template-columns: 5% 95%;
      }
    }
  }
}
</style>
