import {
  GET_SIGNED_URL_SUPPORTING,
  PARTS_PARSING,
  SET_PARTS_PARSING,
  UPLOAD,
} from './types';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import { config } from '@/shared/misc/env-variable';
import { DRAFT_RFQS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import { queueHandlerFactory } from '@/common/helpers/index';

const queueHandler = queueHandlerFactory({ maxExecuting: 4, checkTimeout: 5000 });

const sendSignedUrl = async ({
  signedUrl,
  file,
  type,
}) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedUrl, true);
  // xhr.onload = () => {
  //   if (xhr.status === 200) {
  //     resolve('success');
  //   }
  //   reject({
  //     status: xhr.status,
  //     statusText: xhr.responseText,
  //   });
  // };

  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        // Handle successful response
        resolve('success')
      }
      reject({
        status: xhr.status,
        statusText: xhr.responseText,
      });
    }
  };

  xhr.onerror = () => {
    reject({
      status: xhr.status,
      statusText: xhr.responseText,
    });
  };
  xhr.setRequestHeader('Content-Type', type);
  xhr.send(file);
});

export default {
  // eslint-disable-next-line no-unused-vars
  async [GET_SIGNED_URL_SUPPORTING]({ commit }, { file, hash }) {
    const { data } = await Api.post(ENDPOINTS.DRAFT_RFQS.SIGNED_URL_SUPPORTING,
      file,
      { __pathParams: { hash } }).catch((err) => console.log(err));
    return data;
  },

  // eslint-disable-next-line no-unused-vars
  async [UPLOAD]({ rootState, state, commit }, {
    signedUrl, file, type, hash, shouldParse = true,
  }) {
    try {
      await sendSignedUrl({
        signedUrl,
        file,
        type,
      });

      if (shouldParse) {
        let url = new URL(signedUrl);
        const ext = url.pathname.split('.')[1];
        if (['stl', 'stp', 'step'].includes(ext.toLowerCase())) {
          const affiliatePath = url.pathname.split('/').splice(-2).join('/');
          const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];
          const draft = draftRfqs.find((d) => d.hash === hash);

          await createThumbnailForDraft(affiliatePath, draft);
        }

        await queueHandler.add(async () => {
          await Api.post(ENDPOINTS.DRAFT_RFQS.PARSING, null, { __pathParams: { hash } });

          commit(SET_PARTS_PARSING, state[PARTS_PARSING] + 1);
        });
      }

      return 'success';
    } catch (err) {
      throw err;
    }
  },
};

async function createThumbnailForDraft(affiliatePath, draft) {
  try {
    const url = `${config('GSERVE_URL')}/api/v2/thumbnail`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ affiliate_path: affiliatePath }),
    });

    if (! response.ok) {
      throw new Error(`Request to ${url} returned status ${response.status}`);
    }

    const blob = await response.blob();

    draft?.uploads.push({
      'url': URL.createObjectURL(blob),
      'type_slug': 'draft-rfq-model-preview',
      'type': {
        'slug': 'draft-rfq-model-preview',
      },
    });
  } catch (error) {
    console.log(error)
  }
}
