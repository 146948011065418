<template>
  <div
    id="inspection-modal"
    class="modal-card"
    style="width: 761px;"
  >
    <div class="modal-card-head has-background-white py-5 px-6">
      <p class="modal-card-title has-text-weight-bold">
        Inspections
      </p>
      <a
        class="delete"
        @click="$emit('close')"
      />
    </div>
    <div class="modal-card-body py-5 px-6">
      <BTable
        :data="inspections"
        :opened-detailed="openedDetailed"
        :show-detail-icon="false"
        detail-key="id"
        detailed
      >
        <BTableColumn
          v-slot="{ row }"
          width="3rem"
        >
          <GmButton
            class="p-1"
            size="small"
            type="g-light-1"
            @click="updateDetailed(row.id, !openedDetailed.includes(row.id))"
          >
            <BIcon :icon="openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'" />
          </GmButton>
        </BTableColumn>
        <BTableColumn
          v-slot="{ row }"
          header-class="is-size-7 py-5 pl-0 pr-2"
          label="Name"
        >
          <span class="is-size-6 has-text-weight-bold pr-2">
            {{ row.string_value }}
          </span>
        </BTableColumn>
        <BTableColumn
          v-slot="{ row }"
          header-class="is-size-7 py-5 pl-0 pr-2"
          label="Typical cost"
        >
          <template v-if="row.slug === 'custom-inspection'">
            Variable
          </template>
          <template v-else-if="row.quote_addon_label_price">
            {{ row.quote_addon_label_price | formatSimpleCost }}
          </template>
          <template v-else>
            No cost
          </template>
        </BTableColumn>
        <BTableColumn
          v-slot="{ row }"
          numeric
        >
          <BButton
            :disabled="isLocked(row)"
            :icon-left="isSelected(row) ? 'check' : null"
            :outlined="isSelected(row)"
            :style="{
              'font-size': (isSelected(row) ? '0.85rem' : '1rem'),
              'min-width': '165px',
              'min-height': '40px',
            }"
            class="is-toggle"
            icon-pack="fas"
            type="is-info"
            @click="handleSelected(row)"
          >
            {{
              isSelected(row) ? 'Inspection selected' : 'Select inspection'
            }}
          </BButton>
        </BTableColumn>
        <template #detail="{ row }">
          <div>
            <p class="title is-7">
              What's included
            </p>
            <div
              class="markdown-html"
              v-html="$options.markdownConverter.makeHtml(row.metadata.description)"
            />
          </div>
        </template>
      </BTable>
      <div class="mt-4">
        <p class="is-size-7">
          * Lot size sampling as Geomiq Inspection Sampling Table
          (based on ISO 2859-1:1999, AQL 2.5%, with zero defects)
        </p>
        <p class="is-size-7">
          + A customer-supplied dimensional print is required for this inspection
        </p>
      </div>
    </div>
    <div class="modal-card-foot has-background-white is-justify-space-between py-5 px-6">
      <GmButton
        is-link
        @click="$emit('close')"
      >
        Cancel
      </GmButton>
      <GmButton
        type="g-primary"
        @click="handleConfirm"
      >
        Confirm Inspection
      </GmButton>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import showdown from 'showdown';
import { REFERENCE_DATA, REFERENCE_MODULE } from '@/app-buyer/store/modules/reference-data/types';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import InspectionTypeCard from '@/app-buyer/components/InspectionTypeCard.vue';

export default {
  name: 'InspectionModal',
  components: { GmButton, InspectionTypeCard },
  mixins: [safeMultipleUpdate],
  markdownConverter: new showdown.Converter(),
  props: {
    leadHash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openedDetailed: [],
      initialSelected: [],
      selected: null,
      videoSelected: null,
    };
  },
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    inspections() {
      const insp = this[REFERENCE_DATA]?.filter((r) => r.entity_slug === 'inspection' || r.entity_slug === 'video-inspection');

      return [
        insp.find((i) => i.slug === 'standard-inspection'),
        ...insp.filter((i) => i.slug !== 'standard-inspection' && i.slug !== '360-video-inspection'),
        insp.find((i) => i.slug === '360-video-inspection'),
      ].filter(Boolean);
    },
    values() {
      // Get all values from configurations
      const allValues = Object.values(this.configurations).map((conf) => conf.inspection);
      // Remove duplicate values
      return Array.from(new Set(allValues));
    },
  },
  watch: {
    configurations: {
      handler(val) {
        this.selected = val?.[this.leadHash]?.inspection ?? null;
        this.videoSelected = val?.[this.leadHash]?.['video-inspection'] ?? null;
      },
      immediate: true,
    },
  },
  methods: {
    isSelected({ id }) {
      return id === this.selected || this.videoSelected === id;
    },
    handleConfirm() {
      if (this.videoSelected) {
        this.startUpdate(this.videoSelected, { entitySlug: 'video-inspection' });
      } else if (!this.videoSelected && this.configurations[this.leadHash]?.['video-inspection']) {
        this.startUpdate(null, { entitySlug: 'video-inspection', values: [null] });
      }
      this.startUpdate(this.selected, { entitySlug: 'inspection' });
    },
    handleSelected({ slug, id }) {
      if (slug === '360-video-inspection' && this.videoSelected) this.videoSelected = null;
      else if (slug === '360-video-inspection') this.videoSelected = id;
      else this.selected = id;
    },
    updateDetailed(id, add) {
      if (add) this.openedDetailed.push(id);
      else this.openedDetailed = this.openedDetailed.filter((e) => e !== id);
    },
    onUpdateFinish() {
      this.$emit('close');
    },
    isLocked({ id }) {
      return this.leadLockedConfig?.inspection?.properties
        && (
          Array.isArray(this.leadLockedConfig?.inspection?.properties)
            ? this.leadLockedConfig?.inspection?.properties.includes(id)
            : true
        );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.markdown-html {
  font-size: 13px;
}

::v-deep {
  td {
    padding: 1rem 0 !important;
    vertical-align: middle !important;
  }

  .table-wrapper {
    max-height: inherit;
  }

  .button.is-info.is-outlined.is-toggle:hover,
  .button.is-info.is-outlined.is-toggle.is-hovered,
  .button.is-info.is-outlined.is-toggle:focus,
  .button.is-info.is-outlined.is-toggle.is-focused {
    background-color: transparent;
    border-color: #338dbc;
    color: #338dbc;
  }
}
</style>
