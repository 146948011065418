<template>
  <div @click.stop>
    <BField v-if="reorderInitialised">
      <g-button
        :isDisabled="!selectedCount"
        :isLoading="loading"
        :label="sameVendor ? 'Order from same manufacturer' : 'Order from new manufacturer'"
        type="primary-icon"
        iconLeft="cart-plus"
        @click="$emit('confirm-reorder')"
        data-testid="confirm-reorder-btn"
      />
      <BDropdown
        class="ml-1"
        :position="dropDownPosition"
      >
        <template #trigger>
          <g-button
            type="icon"
            iconRight="chevron-down"
          />
        </template>
        <BDropdownItem @click="$emit('cancel-reorder')">
          Cancel
        </BDropdownItem>
      </BDropdown>
    </BField>
    <BDropdown
      v-else
      arial-role="list"
      :mobile-modal="false"
      :position="dropDownPosition"
    >
      <template #trigger="{ active }">
        <g-button
          :iconRight="active ? 'chevron-up' : 'chevron-down'"
          type="primary-icon"
          label="Create reorder request"
        />
      </template>

      <BDropdownItem
        aria-role="listitem"
        data-testid="reorder-same-supplier"
        @click="startReorder(true)"
      >
        Use same supplier
      </BDropdownItem>
      <BDropdownItem
        aria-role="listitem"
        @click="startReorder(false)"
      >
        Use a new supplier
      </BDropdownItem>
    </BDropdown>
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'InvoiceTableReorderButton',

  components: {
    GButton,
  },

  props: {
    loading: Boolean,
    reorderInitialised: Boolean,
    selectedCount: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      sameVendor: false,
    };
  },

  computed: {
    dropDownPosition() {
      return this.screenSize < 600
        ? 'is-bottom-right'
        : 'is-bottom-left';
    },
  },
  mounted() {
    window.addEventListener('resize', this.setScreenSize);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.setScreenSize);
    });
    this.setScreenSize();
  },
  methods: {
    startReorder(sameVendor) {
      this.sameVendor = sameVendor;
      this.$emit('start-reorder', { sameVendor });
    },
    setScreenSize() {
      this.screenSize = window.innerWidth;
    },
  },
};
</script>
