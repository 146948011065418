<template>
  <Tippy
    v-if="show"
    :visible="show"
    :enabled="show"
    trigger="manual"
    interactive
    :hide-on-click="false"
    arrow
    class="tw-absolute"
  >
    <div
      v-if="show"
      id="buyer-app"
    >
      <div
        class="tw-p-2 tw-text-left"
      >
        Are you sure you would like to apply
        <b class="tw-whitespace-nowrap">{{ confirm.property }}</b>
        to
        <a
          class="tw-text-white tw-whitespace-nowrap tw-underline"
          @mouseenter="highlight(confirm.ids)"
          @mouseleave="highlight(null)"
        >{{ confirm.count }} parts</a>
        ?
        <div class="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <g-button
            isInverted
            label="Cancel"
            type="pill"
            color="tertiary"
            fontWeight="normal"
            class="tw-bg-white"
            @click="handleClick('cancel')"
          />
          <g-button
            :label="`Apply to selected to parts (${ confirm.count })`"
            type="pill"
            color="tertiary"
            fontWeight="normal"
            @click="handleClick('confirm')"
          />
        </div>
      </div>
    </div>
  </Tippy>
</template>
<script>
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'ConfirmApplicableTooltip',

  components: {
    GButton,
  },

  inject: {
    setHighlightedParts: {
      default: null,
    },
  },

  props: {
    confirmApplicable: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      canOpen: false,
      confirm: null,
    };
  },

  computed: {
    show() {
      return this.canOpen;
    },
  },

  watch: {
    confirmApplicable(nv) {
      if (nv) {
        this.canOpen = true;
        this.confirm = this.confirmApplicable;
      }
    },
  },

  methods: {
    highlight(ids) {
      if (this.setHighlightedParts) {
        this.setHighlightedParts(ids);
      }
    },
    handleClick(emit) {
      this.$emit(emit, { canApply: this.confirm.canApply });
      this.canOpen = false;
      this.confirm = null;
    },
  },
};
</script>
