<template>
  <div class="quote-row-simple-wrapper tw-flex tw-flex-col tw-py-4 tw-w-full">
    <div class="tw-flex">
      <div class="left">
        <PartThumbnail
          :part="quote"
          :size="53"
        />
        <div class="ref-wrapper tw-text-center tw-text-md tw-p-1">
          {{ quote.quote_id }}
        </div>
      </div>
      <div class="right tw-pl-6 tw-text-md">
        <div class="label-column tw-font-bold">
          <p>Part name</p>
          <p>Service</p>
          <p>Material</p>
          <p>Quantity</p>
          <p v-if="quote.quantity_production">Another quantity</p>
          <p>Manufacturing time</p>
        </div>
        <div class="body-column">
          <p>{{ quote.name }}</p>
          <p>{{ quote.service }}</p>
          <p>{{ quote.material }}</p>
          <p>{{ quote.quantity_initial }}</p>
          <p v-if="quote.quantity_production">{{ quote.quantity_production }}</p>
          <p>{{ quote.manufacturing_time }} days</p>
        </div>
      </div>
    </div>
    <div class="tw-pt-3">
      <p class="tw-font-bold tw-text-md">
        Geomiq note:
      </p>
      <div class="tw-w-full tw-rounded-xs tw-bg-grey-lighter tw-italic tw-text-md tw-p-4 tw-mt-1">
        <p>{{ notes }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';

export default {
  components: {
    PartThumbnail,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notes() {
      return this.quote?.quote_notes?.map((e) => e.content).join(' | ');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.quote-row-simple-wrapper {
  border-top: solid 1px #dbdbdb;

  .ref-wrapper {
    width: 53px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
  }

  .right {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1.4rem;
  }
}
</style>
