<template>
  <div
    v-click-outside="handleClose"
    class="g-dropdown tw-inline-block tw-relative"
    :class="{'tw-w-full': isFullWidth}"
  >
    <div class="selected">
      <g-button
        :label="label"
        :iconLeft="iconLeft"
        :iconRight="iconRight"
        :color="color"
        :size="size"
        :fontWeight="fontWeight"
        :isInverted="isInverted"
        :isFullWidth="isFullWidth"
        :hasBorder="hasBorder"
        :type="type"
        @click="handleOpenSelect"
      />
    </div>
    <div
      class="options tw-bg-white tw-absolute tw-z-30 tw-rounded-xs"
      :class="[
        dropdownDistance,
        isOpen ? 'tw-block': 'tw-hidden',
        isFullWidth ? 'tw-inset-x-0 tw-w-full' : 'tw-w-max',
        { 'tw-right-0' : alignRight }
        ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GDropdown',

  components: {
    GButton,
    FontAwesomeIcon,
  },

  props: {
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'secondary',
      validator: (value) => [
        'primary',
        'secondary',
        'tertiary',
        'error',
        'success',
        'grey-darkest',
        'grey-dark',
        'grey-light',
        'white',
        'transparent',
      ].includes(value),
    },
    type: {
      type: String,
      default: 'primary-icon',
      validator: (value) => [
        'icon',
        'primary-icon',
      ].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
    fontWeight: {
      type: String,
      default: 'bold',
      validator: (value) => [
        'light',
        'normal',
        'medium',
        'bold',
      ].includes(value),
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    // dropdownColor() {
    //   if (this.color === 'primary') return `tw-inset tw-ring-1 tw-ring-primary`;
    //   if (this.color === 'tertiary') return `tw-inset tw-ring-1 tw-ring-tertiary`;
    //   if (this.color === 'error') return `tw-inset tw-ring-1 tw-ring-error`;
    //   if (this.color === 'success') return `tw-inset tw-ring-1 tw-ring-success`;
    //   if (this.color === 'grey-dark') return `tw-inset tw-ring-1 tw-ring-grey-dark`;
    //   if (this.color === 'grey-light') return `tw-inset tw-ring-1 tw-ring-grey-light`;
    //   if (this.color === 'white') return `tw-inset tw-ring-1 tw-ring-white`;
    //   if (this.color === 'transparent') return ``;
    //   // secondary is default
    //   return `tw-inset tw-ring-1 tw-ring-secondary`;
    // },

    dropdownDistance() {
      if (this.size === 'sm') return 'tw-top-8';
      if (this.size === 'lg') return 'tw-top-12';
      // md is default
      return 'tw-top-10';

    },
  },

  methods: {
    handleOpenSelect() {
      this.isOpen = !this.isOpen;
    },
    handleClose() {
      this.isOpen = false;
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.option {
  min-width: 10rem;
}
</style>
