<script>
import PartStatusTag from '@/app-buyer/components/project/PartStatusTag.vue';
import { ERROR, WARNING, PENDING } from '@/shared/consts/slugs';

export default {
  name: 'PartTableGroupRowStatus',
  components: { PartStatusTag },
  props: {
    issues: {
      type: [Boolean, Array],
      default: () => null,
    },
    customStatus: {
      type: Object,
      default: null,
    },
  },
  computed: {
    status() {
      if (this.customStatus) return this.customStatus.type;
      if (this.issues == null) return null;
      if (this.issues === false) return 'success';
      const highestSeverity = this.issues.reduce((res, issue) => {
        if (issue?.type === ERROR) return issue.type;
        if (issue?.type === PENDING) return issue.type;
        if (issue?.type === WARNING && res !== ERROR) return issue.type;
        return res;
      }, 'success');

      switch (highestSeverity) {
        case WARNING:
          return 'warning';
        case ERROR:
          return 'danger';
        case PENDING:
          return 'pending';
        default:
          return 'success';
      }
    },

    message() {
      if (this.customStatus) return this.customStatus.message;
      if (!this.issues) return null;
      return this.issues?.filter(Boolean).map((i) => i.message).join('<br />');
    },

    components() {
      if (this.customStatus) return this.customStatus.componentDefs;
      if (!this.issues) return null;
      return this.issues?.filter(Boolean).map((i) => {
        if (!i?.component) return null;
        return {
          component: i?.component,
          props: i?.props,
          on: i?.on,
        };
      });
    },
  },

  render(createElement) {
    return createElement(
      PartStatusTag, {
        props: {
          status: this.status,
          message: this.message,
          componentDefs: this.components,
        },
      },
    );
  },
};
</script>
