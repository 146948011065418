export default function getEnvironmentVariable(key) {
  // eslint-disable-next-line no-prototype-builtins
  const value = window._env_?.hasOwnProperty(key)
    ? window._env_[key]
    : process.env[key];
  switch (value) {
    case 'false':
      return false;
    case 'true':
      return true;
    case 'null':
    case '':
      return null;
    default:
      return value;
  }
}

/**
 * Shorthand helper function for the above.
 *
 * @param {string} key
 * @returns {boolean|*|string}
 */
export function config(key) {
  const prefix = 'VUE_APP_';
  const k = key.startsWith(prefix) ? key : prefix + key;
  return getEnvironmentVariable(k);
}
