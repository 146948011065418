<template>
  <section
    data-testid="user-profile-wrapper"
  >
    <breadcrumbs :crumbs="{profile: {name: 'Profile data', to: '/profile', disabled: true}}" />
    <user-profile-email-verification v-show="!isModal" />
    <ul
      class="container"
      style="overflow: hidden; padding: 3rem 2rem;"
    >
      <transition-group
        appear
        class="columns is-multiline"
        name="float-in-vertical"
      >
        <li
          key="0"
          class="column is-12 is-relative"
        >
          <div :style="`position: relative; transition: left 0.5s; left: ${profileStatus}%`">
            <span class="progress-indicator">Profile completion: {{ profileStatus }}%</span>
          </div>
          <b-progress
            :value="profileStatus"
            size="is-small"
            type="is-info"
          />
        </li>
        <li
          key="1"
          class="column is-6"
          style="transition-delay: 0.1s"
        >
          <div class="card-wrapper">
            <p class="title is-7">
              Personal information
            </p>
            <user-profile-personal-information />
          </div>
        </li>
        <li
          key="2"
          class="column is-6"
          style="transition-delay: 0.2s"
        >
          <div class="card-wrapper">
            <p class="title is-7">
              Company information
            </p>
            <user-profile-company-information />
          </div>
        </li>
        <li
          key="4"
          class="column is-8"
        >
          <div class="card-wrapper">
            <user-address-wrapper>
              <template v-slot:shipping-address>
                <span class="is-size-7 has-text-weight-bold">
                  Shipping address
                </span>
              </template>
              <template v-slot:billing-address>
                <span class="is-size-7 has-text-weight-bold">
                  Billing address
                </span>
              </template>
            </user-address-wrapper>
          </div>
        </li>
        <li
          key="5"
          class="column is-4"
          style="transition-delay: 0.6s"
        >
          <div class="card-wrapper">
            <p class="title is-7">
              Subscriptions
            </p>
            <b-field
              custom-class="is-small"
              label="Marketing emails"
            >
              <div
                class="wrapper"
                data-testid="user-marketing-radio"
              >
                <b-radio
                  v-model="subscribed"
                  :native-value="true"
                  size="is-small"
                >
                  Yes
                </b-radio>
                <b-radio
                  v-model="subscribed"
                  :native-value="false"
                  size="is-small"
                >
                  No
                </b-radio>
              </div>
            </b-field>
          </div>
        </li>
        <li
          key="6"
          class="column is-4"
          style="transition-delay: 0.7s"
        >
          <UserProfileRegistrationDetails />
        </li>
      </transition-group>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import UserAddressWrapper from '@/app-buyer/components/user-profile/v2/user-address-wrapper.vue';
import UserProfileRegistrationDetails
  from '@/app-buyer/components/user-profile/UserProfileRegistrationDetails';
import UserProfilePersonalInformation from './user-profile-personal-information.vue';
import UserProfileCompanyInformation from './user-profile-company-information.vue';
import UserProfileEmailVerification from './user-profile-email-verification.vue';
import {
  GET_BILLING_DATA,
  GET_CONTACT_DATA,
  GET_DELIVERY_DATA,
  GET_PROFILE_COMPLETION,
  USER_DATA,
  USER_MODULE,
} from '../../store/modules/user/types';
import { UPDATE } from '../../store/modules/types';

export default {
  name: 'UserProfileWrapper',
  components: {
    UserProfileRegistrationDetails,
    UserAddressWrapper,
    UserProfileEmailVerification,
    UserProfileCompanyInformation,
    UserProfilePersonalInformation,
  },
  props: {
    isModal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      customBilling: false,
    };
  },
  computed: {
    ...mapGetters(USER_MODULE, {
      deliveryData: GET_DELIVERY_DATA,
      billingData: GET_BILLING_DATA,
      contactData: GET_CONTACT_DATA,
      profileStatus: GET_PROFILE_COMPLETION,
    }),
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
    subscribed: {
      get() {
        return !!(this.userData && this.userData.profile && this.userData.profile.subscribed_at);
      },
      set() {
        this.updateSubscription();
      },
    },
  },
  watch: {
    billingData: {
      handler(newVal) {
        if (newVal) {
          const keys = ['street_address', 'post_code', 'town', 'county', 'country'];
          // eslint-disable-next-line no-restricted-syntax
          for (const key of keys) {
            if (newVal[key] !== this.deliveryData[key]) {
              this.customBilling = true;
              return;
            }
          }
          this.customBilling = false;
        } else {
          this.customBilling = !!newVal;
        }
      },
      immediate: true,
      deep: true,
    },
    deliveryData: {
      handler(newVal) {
        if (newVal) {
          const keys = ['street_address', 'post_code', 'town', 'county', 'country'];
          // eslint-disable-next-line no-restricted-syntax
          for (const key of keys) {
            if (newVal[key] !== this.billingData[key]) {
              this.customBilling = true;
              return;
            }
          }
        } else {
          this.customBilling = !!this.billingData;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(USER_MODULE, {
      updateUserData: UPDATE,
    }),
    async updateSubscription() {
      const copy = JSON.parse(JSON.stringify(this.userData));
      copy.subscribe = !this.subscribed;
      this.updateUserData(copy)
        .catch((e) => console.error(e));
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.container {
  background: rgba(255, 255, 255, 0.3);
  border-radius: var(--border-radius);
}

.card-wrapper {
  background-color: white;
  border: 1px solid #eeefff;
  border-radius: var(--border-radius);
  min-height: 10rem;
  padding: 1.5rem;
}

.progress-indicator {
  background-color: var(--geomiq-blue);
  border-radius: 6px 6px 0 6px;
  color: white;
  font-size: 0.625rem;
  font-weight: bold;
  padding: 0.3rem;
  position: absolute;
  transform: translate(-100%, calc(-100% - 0.3rem));
  white-space: nowrap;

  &::after {
    border-left: 5px solid transparent;
    border-top: 5px solid var(--geomiq-blue);
    bottom: -5px;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
  }
}

.modal label.b-radio {
  font-weight: normal;
  margin-left: 0;
  margin-top: 0.2rem;
  text-transform: none;
}
</style>
