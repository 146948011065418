var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"animated-geomiq-text",class:{'appeared' : _vm.appeared},style:(`
       width: ${246.25 * _vm.scale}px;
       height: ${32 * _vm.scale}px;
       --scale: ${_vm.scale};
       --stroke: ${_vm.stroke};
       --stroke-width: ${_vm.strokeWidth};
       --fill: ${_vm.fill};
       --duration: ${_vm.duration}s`)},[_c('title',[_vm._v("geomiq")]),_c('path',{attrs:{"d":_vm.path}})])
}
var staticRenderFns = []

export { render, staticRenderFns }