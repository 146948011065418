<template>
  <div class="">
    <g-button
      isInverted
      :isFullWidth="!IS_DESKTOP"
      :isLoading="isLoading"
      label="View previous quotes"
      iconLeft="history"
      type="primary-icon"
      color="grey-dark"
      fontWeight="normal"
      :size="IS_DESKTOP ? 'sm' : 'md'"
      class="admin-style tw-whitespace-nowrap"
      @click="handlePreviousQuotes"
    />

    <g-modal
      v-if="isActive"
      :is-active.sync="isActive"
      has-modal-card
      :modal-height="modalHeight"
      :modal-width="modalWidth"
      :can-cancel="['escape', 'outside', 'button']"
    >
      <div class="previous-quotes-modal tw-bg-white tw-rounded-xs tw-w-full tw-h-full tw-overflow-auto">
        <div class="tw-px-6 tw-py-3 tw-flex tw-justify-between tw-items-center tw-sticky tw-z-10 tw-top-0 tw-bg-white">
          <p class="tw-text-lg tw-font-bold">View previous quotes ({{
              previousQuotes?.length || ''
            }})</p>
          <button @click="isActive = false">
            <font-awesome-icon
              icon="times"
              class="tw-text-xl"
            />
          </button>
        </div>

        <div
          v-if="IS_DESKTOP"
          class="previous-quotes-table-header tw-bg-grey-lighter tw-py-1 tw-border-t tw-border-b tw-border-grey-light tw-sticky tw-z-10 tw-top-12"
        >
          <div class="previous-quotes-grid tw-px-6 tw-grid">
            <p class="tw-text-md tw-font-bold">Ref</p>
            <p class="tw-text-md tw-font-bold">Part</p>
            <p class="tw-text-md tw-font-bold">Qty</p>
            <p class="tw-text-md tw-font-bold">Lead time</p>
            <p class="tw-text-md tw-font-bold">Price</p>
            <p class="tw-text-md tw-font-bold tw-text-right">Action</p>
          </div>
        </div>

        <div class="previous-quotes-table-body">
          <previous-quote
            v-for="quote in previousQuotes"
            :key="quote.id"
            :part="quote"
            :isPartInCart="isPartInCart"
            @closeModal="isActive = false"
            @removeFromCart="() => $emit('removeFromCart')"
          />
        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GModal from '@/app-buyer/components/GModal.vue';
import { GET_PREVIOUS_QUOTES, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import PreviousQuote from '@/app-buyer/components/project/PreviousQuote.vue';

export default {
  name: 'PreviousQuotesModal',

  components: {
    PreviousQuote,
    GButton,
    GModal,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isPartInCart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      isLoading: false,
      previousQuotes: null,
    }
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    modalWidth() {
      if (!this[IS_DESKTOP]) return '90vw';
      return '1000px';
    },

    modalHeight() {
      if (!this[IS_DESKTOP]) return '80vh';
      return '600px';
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      GET_PREVIOUS_QUOTES,
    }),

    async handlePreviousQuotes() {
      try {
        this.isLoading = true;
        this.previousQuotes = await this[GET_PREVIOUS_QUOTES]({ hash: this.part.hash });
        this.isActive = true;
      } catch (err) {
        console.log(err)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Could not get previous quotes',
        });
      } finally {
        this.isLoading = false;
      }

    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  button {
    &.admin-style {
      border-color: theme('colors.grey-light') !important;
      background-color: theme('colors.white');
    }

    &.admin-style {
      span {
        color: theme('colors.primary');
      }
    }
  }
}

.previous-quotes-modal {
  .previous-quotes-grid {
    grid-template-columns: 9% 35% 10% 19% 17% 10%;
  }
}
</style>
