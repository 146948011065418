<template>
  <div class="button-group tw-w-40 tw-mt-1">
    <GmButton
      outlined
      :loading="loading"
      :disabled="loading"
      size="xsmall"
      class="upload-button tw-flex tw-items-center tw-justify-center tw-border-r-0"
      type="light"
      @click="SET_VIEWED(part)"
    >
      <font-awesome-icon
        icon="cube"
        class="tw-mr-2 tw-text-grey-dark tw-text-sm"
      />
      <span class="tw-text-primary tw-text-sm tw-overflow-hidden tw-text-ellipsis ">
        3D Tool Configuration
      </span>
      <span
        class="is-absolute is-flex is-align-items-center is-justify-content-center
              is-inset-0 is-block"
      >
        <BIcon
          icon="eye"
          style="color: white;"
        />
      </span>
    </GmButton>
    <GmButton
      outlined
      :loading="loading"
      :disabled="loading"
      size="xsmall"
      type="light"
      class="tw-px-05 tw-flex tw-items-center"
      @click="$emit('remove')"
    >
      <BIcon
        icon="times"
        size="is-small"
        class="tw-text-grey-dark"
      />
    </GmButton>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { RFQ_MODULE, SET_VIEWED } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'PartSupportingConfiguration',
  props: {
    part: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),
  },
};
</script>
