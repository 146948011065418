import axios from 'axios';
import { Model } from 'vue-api-query';
import { config as env } from '@/shared/misc/env-variable';
import ENDPOINTS from '@/app-buyer/api/endpoints';

const Api = axios.create({
  baseURL: env('API_URL'),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

const noAuthUrls = ['login', 'register', 'password', 'email', 'complete-registration', '403'];

export const responseErrorHandler = async (error) => {
  if (error.name === 'CanceledError') return Promise.reject(error);

  // Any HTTP status codes > 2xx cause this function to trigger
  const { status } = error.response;
  if (status === 419) {
    await axios.get(`${env('API_URL')}${ENDPOINTS.AUTH.CSRF}`);
    return axios(error.config);
  }
  if (status === 401) {
    // This will redirect the user to the login page unless the url includes the noAuthUrls array
    // In mobile we also want to redirect if the user hits the /project url as they can't upload
    let isNoAuth;

    if (window.innerWidth < 1280) isNoAuth = noAuthUrls.some((noAuthUrl) => window.location.pathname.includes(noAuthUrl));
    else isNoAuth = [...noAuthUrls, 'project'].some((noAuthUrl) => !window.location.pathname.includes(noAuthUrl));

    if (!isNoAuth) window.location.href = `${env('BASE_URL')}/login`;
  }
  const originalRequest = error.config;
  if (status >= 500 && originalRequest.__forceRetry) {
    originalRequest.__forceRetry = false;
    return new Promise((innerResolve) => {
      setTimeout(() => innerResolve(axios(originalRequest)), 2000);
    });
  }
  // Else reject the call
  return Promise.reject(error);
};

export function resolvePathParams(path, params) {
  let mutablePath = path;
  Object.keys(params).forEach((key) => {
    const wrapped = `{${key}}`;
    if (mutablePath.includes(wrapped)) {
      mutablePath = mutablePath.replace(wrapped, params[key]);
    }
  });
  return mutablePath;
}

Api.interceptors.response.use((response) => response, responseErrorHandler);

/* eslint-disable no-param-reassign */
Api.interceptors.request.use((config) => {
  if (config.__pathParams) {
    config.url = resolvePathParams(config.url, config.__pathParams);
    delete config.__pathParams;
  }
  return config;
});
/* eslint-enable no-param-reassign */

export default Api;

Model.$http = Api;
