import {
  EXAMPLE,
  EXAMPLE_MUTATION,
  PARTS_PARSING,
  SET_PARTS_PARSING,
} from './types';

export default {
  /**
   * Sets data in the state
   *
   * @param state
   * @param data
   * */
  [EXAMPLE_MUTATION](state, data) {
    state[EXAMPLE] = data;
  },

  [SET_PARTS_PARSING](state, data) {
    state[PARTS_PARSING] = data;
  },
};
