<template>
  <div class="mesh-background">
    <article v-if="false" class="notification is-warning banner" role="alert">
      <div class="media">
        <div class="media-content">
          We are experiencing some technical issues where some partners are unable to log in.
          Please <a
          target="_blank"
          href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
        >delete any geomiq.com cookies</a> or open an <a
          target="_blank"
          href="https://support.google.com/chrome/answer/95464?hl=en-GB&co=GENIE.Platform%3DDesktop"
        >incognito</a> session if you are currently
          experiencing this issue until it is resolved. Thank you for your patience.
        </div>
      </div>
    </article>
    <div
      class="container is-flex is-flex-align-centered"
      style="height: 100%;"
    >
      <div
        class="card box is-flex is-flex-direction-column is-justify-center"
        style="width: 600px; max-width: 100vw; margin: auto;"
      >
        <div class="is-flex is-justify-center is-flex-align-centered p-md">
          <icon-font
            icon="geomiq-text"
            style="font-size: 1.2rem;"
          />
        </div>
        <h1 class="tw-text-lg tw-font-bold tw-w-full tw-text-center tw-mb-4">
          Login
        </h1>
        <login-form />
        <p class="tw-mt-5 tw-text-lg">
          Not registered yet?
          <g-button
            htmlType="router-link"
            to="/register"
            label="Sign up as a Customer"
            type="underline"
            color="tertiary"
            fontWeight="normal"
            class="tw-inline-flex tw-border-none"
            size="lg"
          />
          or
          <a
            :href="partnerForm"
            data-testid="register-link"
            class="tw-text-tertiary tw-underline tw-text-lg"
          >
            sign up as a Manufacturing Partner
          </a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../components/login-registration/login-form.vue';
import getEnvironmentVariable from '../../../shared/misc/env-variable';
import EmailVerification from '../../mixins/email-verification';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    GButton,
  },
  mixins: [
    EmailVerification,
  ],
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    partnerForm() {
      return `${getEnvironmentVariable('VUE_APP_VENDOR_URL')}/register`;
    },
  },
  mounted() {
    this.emailVerificationToast();
  },
};
</script>

<style scoped>

  .notification.banner {
    top: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    position: absolute;
    border-radius: 0;
    padding: 1rem;
    margin-bottom: 0 !important;
    z-index: 9999;
  }

</style>
