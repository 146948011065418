<template>
  <div
    v-click-outside="handleClickOutside"
    class="gm-dropdown search-dropdown is-relative"
    style="z-index: 2"
  >
    <b-collapse
      id="material-dropdown"
      ref="dropdown"
      :open.sync="isOpen"
      animation="slide"
      expanded
      @active-change="focusInput"
    >
      <template v-slot:trigger="{ open }">
        <button
          ref="trigger-button"
          slot="trigger"
          class="button is-small tw-pl-2 tw-w-full is-result"
        >
          <font-awesome-icon v-if="!isMaterialSelectable" icon="exclamation-circle" class="tw-text-sm tw-text-pending tw-mr-1" />
          <p
            :class="{ 'tw-opacity-50' : !selected }"
            class="tw-m-0 tw-text-sm tw-flex tw-justify-between tw-items-center tw-w-full"
            data-testid="configurator-preselected"
          >
            <span>{{ buttonLabel }} <span v-if="!isMaterialSelectable">unavailable</span></span>
            <font-awesome-icon :icon="open ? 'chevron-up' : 'chevron-down'" />
          </p>
        </button>
      </template>
      <div class="outside-wrapper">
        <div
          style="padding: 0.5rem; position: sticky; top: 0; background-color: white; z-index: 1"
        >
          <b-input
            ref="search"
            v-model="searchInput"
            :placeholder="placeholder"
            custom-class="is-small"
            expanded
            icon="search"
            rounded
            @input="resetNavigation"
            @click.native.stop
            @keydown.stop.prevent
            @keyup.native.stop.prevent="navigate"
          />
        </div>
        <material-collapse
          v-for="(data, key) in filteredOptions"
          :key="key"
          :data="data"
          :open="!!searchInput"
          :title="key"
          :values="values"
          @input="update"
          @set-note="$emit('set-note', $event)"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import * as Fuse from 'fuse.js';
import { mapGetters, mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import MaterialCollapse
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/MaterialCollapse.vue';
import customNotes from '@/app-buyer/mixins/custom_notes';
import clickOutside from '@/shared/directives/clickOutside';

function setProperty(data, keys, value) {
  if (keys.length > 1) {
    // eslint-disable-next-line no-param-reassign
    if (!data[keys[0]]) data[keys[0]] = { options: [] };
    setProperty(data[keys[0]], keys.slice(1), value);
    return;
  }
  if (data[keys[0]]) {
    data[keys[0]].options.push(value);
  } else {
    // eslint-disable-next-line no-param-reassign
    data[keys[0]] = { options: [value] };
  }
}

export default {
  components: {
    MaterialCollapse,
    FontAwesomeIcon,
  },

  directives: {
    clickOutside,
  },

  mixins: [
    customNotes,
  ],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    configurations: {
      type: Object,
      default: () => ({}),
    },
    leadConfiguration: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      searchInput: '',
      highlightedIndex: 0,
      group: null,
      fuse: null,
      isOpen: false,
    };
  },

  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),

    currentThicknesses() {
      const thicknessIds = [];

      for (const key in this.configurations) {
        if (this.configurations[key]?.thickness) {
          thicknessIds.push(this.configurations[key]?.thickness);
        }
      }

      const thicknesses = [];

      thicknessIds?.forEach((t) => {
        thicknesses.push(this[REFERENCE_DATA_OBJECT_BY_ID]?.[t]);
      });

      return thicknesses;
    },

    currentThicknessMaterialIds() {
      const materialIdsForTheSelectedThicknesses = [];

      this.currentThicknesses?.forEach((t) => {
        t.parents?.forEach((p) => {
          if (p.entity_slug === 'material') {
            materialIdsForTheSelectedThicknesses.push(p.id)
          }
        });
      });

      return materialIdsForTheSelectedThicknesses;
    },

    cleanMaterialOptions() {
      if (
        this.leadConfiguration.service !== this[REFERENCE_DATA_OBJECT_BY_SLUG]['sheet-metal'].id
        || (this.leadConfiguration.service === this[REFERENCE_DATA_OBJECT_BY_SLUG]['sheet-metal'].id && this[REFERENCE_DATA_OBJECT_BY_ID][this.leadConfiguration.thickness].entity_slug === 'other-thickness')
      ) return this.options;

      const materialsForThickness = [];

      this.options.forEach((o) => {
        if (this.currentThicknessMaterialIds.some((materialId) => materialId === o.id)) materialsForThickness.push(o);
      });

      return materialsForThickness;
    },

    filteredOptions() {
      const fuseSearchResult = this.fuse.search(this.searchInput);
      const materialsToMap = fuseSearchResult.length ? fuseSearchResult : this.cleanMaterialOptions.filter((e) => e.entity_slug === 'material');
      if (materialsToMap) {
        return materialsToMap.reduce((mappedMaterials, material) => {
          const keys = [];
          const type = material.parents.find((e) => e.entity_slug === 'material-type');

          if (type) keys.push(type.string_value);
          else keys.push('Other');

          setProperty(mappedMaterials, keys, material);
          return mappedMaterials;
        }, {});
      }
      return {};
    },

    selected() {
      if (this.values.length > 1) {
        return `Multiple selected (${this.values.length})`;
      }
      return this.options.find((e) => e.id === this.values[0]);
    },

    isMaterialSelectable() {
      return !!this.cleanMaterialOptions.find((e) => e.id === this.values[0]);
    },

    buttonLabel() {
      if (typeof this.selected === 'string') return this.selected;
      if (this.selected?.metadata?.requires_notes && this._singleViewedModel
        && (this._singleViewedModel.hash || this._singleViewedModel.draft_hash)) {
        return this.getNote('material');
      }
      if (this.selected) {
        return this.selected.string_value;
      }
      return this.placeholder;
    },
  },

  watch: {
    cleanMaterialOptions: {
      handler(newVal) {
        const searchOptions = {
          shouldSort: false,
          threshold: 0.4,
          minMatchCharLength: 2,
          keys: ['string_value'],
        };
        this.fuse = new Fuse(newVal.filter((e) => e.entity_slug === 'material'), searchOptions);
      },
      immediate: true,
    },
  },

  methods: {
    select(selected) {
      this.searchInput = '';
      this.highlightedIndex = -1;
      this.$emit('input', selected[this.valueKey]);
      this.$nextTick(() => {
        if (this.$refs.dropdown.isActive) {
          this.$refs.dropdown.toggle();
        }
      });
    },

    navigate(event) {
      const groups = Object.keys(this.filteredOptions);
      if (event.key === 'ArrowDown') {
        if (!this.group) {
          // eslint-disable-next-line prefer-destructuring
          this.group = groups[0];
        }
        if (this.highlightedIndex < this.filteredOptions[this.group].length) {
          this.highlightedIndex++;
        } else {
          const index = groups.indexOf(this.group);
          if (index < groups.length - 1) {
            this.highlightedIndex = 0;
            this.group = groups[index + 1];
          }
        }
        this.scrollToHighlighted();
      } else if (event.key === 'ArrowUp') {
        if (!this.group) {
          // eslint-disable-next-line prefer-destructuring
          this.group = groups[0];
        }
        if (this.highlightedIndex > 0) {
          this.highlightedIndex--;
        } else {
          const index = groups.indexOf(this.group);
          if (index > 0) {
            this.group = groups[index - 1];
            this.highlightedIndex = this.filteredOptions[this.group].length - 1;
          }
        }
        this.scrollToHighlighted();
      } else if (event.key === 'Enter') {
        this.select(this.filteredOptions[this.group][this.highlightedIndex]);
      }
    },

    scrollToHighlighted() {
      this.$nextTick(() => {
        const highlighted = this.$el.getElementsByClassName('is-highlighted')[0];
        if (highlighted) {
          highlighted.scrollIntoView({
            block: 'nearest',
          });
        }
      });
    },

    resetNavigation() {
      this.$nextTick(() => {
        // eslint-disable-next-line prefer-destructuring
        this.group = Object.keys(this.filteredOptions)[0];
        this.highlightedIndex = 0;
      });
    },

    focusInput(isActive) {
      setTimeout(() => {
        if (isActive) {
          this.$refs.search.focus();
          this.$refs['trigger-button'].addEventListener('keyup', this.preventEnter);
        } else {
          this.$refs['trigger-button'].removeEventListener('keyup', this.preventEnter);
        }
      }, 500);
    },

    preventEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },

    update(id) {
      this.isOpen = false;
      this.$emit('input', id);
    },

    handleClickOutside() {
      this.isOpen = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .outside-wrapper {
    background-color: white;
    bottom: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    left: 0;
    max-height: 20rem;
    overflow-y: auto;
    position: absolute;
    right: 0;
    transform: translateY(100%);
    z-index: 1;
  }

  .title,
  .subtitle {
    margin: 0;
  }

  .title {
    padding: 0.5rem;
  }

  .is-result:active,
  .is-result:focus {
    box-shadow: none !important;
  }

  .is-result p {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .is-highlighted {
    background-color: var(--bg-gray3);
  }

  .is-dropdown-trigger {
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-radius: 0 !important;
    border-right-color: transparent !important;
    font-weight: bold;
  }
}
</style>
