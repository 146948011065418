<template>
  <div>
    <p>
      <span class="has-text-weight-bold">Screws</span>
      <small>
        <i> (you can select multiple)</i>
      </small>
    </p>
    <BTable
      :data="screws"
      detailed
      :show-detail-icon="false"
      :opened-detailed="openedDetailed"
      detail-key="id"
    >
      <BTableColumn
        #default="{ row }"
        width="2rem"
      >
        <GmButton
          v-show="row.metadata.description"
          type="g-light-1"
          size="small"
          class="p-1"
          @click="$emit(
            'update-detailed',
            {
              id: row.id,
              isOpen: !openedDetailed.includes(row.id)
            }
          )"
        >
          <BIcon :icon="openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'" />
        </GmButton>
      </BTableColumn>
      <BTableColumn
        #default="{ row }"
      >
        <span class="is-size-6 has-text-weight-bold">
          {{ row.string_value }}
        </span>
      </BTableColumn>
      <BTableColumn
        #default="{ row }"
        width="10rem"
      >
        <template v-if="row.quote_addon_label_price">
          + {{ row.quote_addon_label_price | formatWithCurrency }}
        </template>
      </BTableColumn>
      <BTableColumn
        #default="{ row }"
        numeric
        width="10rem"
      >
        <GmButton
          type="info"
          :outlined="selected.includes(row.id)"
          :icon-left="selected.includes(row.id) ? 'check' : null"
          icon-pack="fas"
          @click="$emit('add-or-remove', row.id)"
        >
          {{ selected.includes(row.id) ? 'Added' : 'Add' }}
        </GmButton>
      </BTableColumn>
      <template #detail="{ row }">
        <div>
          <p class="title is-7">
            What's included
          </p>
          <div
            class="markdown-html"
            v-html="$options.markdownConverter.makeHtml(row.metadata.description)"
          />
        </div>
      </template>
    </BTable>
  </div>
</template>
<script>
import showdown from 'showdown';

export default {
  name: 'ScrewsTable',
  markdownConverter: new showdown.Converter(),
  props: {
    openedDetailed: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    screws: { type: Array, default: () => [] },
  },
  methods: {
    delayText(row) {
      const days = row.metadata?.additional_days || 0;
      if (!days) {
        return '';
      }
      return `+ ${days} day${days !== 1 ? 's' : ''}`;
    },
  },
};
</script>
