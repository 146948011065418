import {
  FORCE,
  FORM_MODE,
  IS_DESKTOP,
  IS_MOBILE_SIDEBAR_OPEN,
  LOGIN_MODAL_MODE,
  LOGIN_MODAL_VISIBLE,
  ON_LOGIN_REDIRECT,
  PROFILE_MODAL_VISIBLE,
  RFQ_DETAIL_MODE,
  RFQ_ENGINEER_VIEW,
  SET_FORM_MODE,
  SET_IS_DESKTOP,
  SET_IS_MOBILE_SIDEBAR_OPEN,
  SET_LOGIN_MODAL,
  SET_LOGIN_REDIRECT,
  SET_PROFILE_MODAL,
  SET_RFQ_DETAIL_MODE,
  SET_RFQ_ENGINEER_MODE,
  SET_SHOW_FILE_SERVICE_TOOLTIP,
  SHOW_FILE_SERVICE_TOOLTIP,
} from './types';
import { RESET_STATE } from '../types';
import getState from './state';

export default {
  /**
   * Sets visibility of login modal
   *
   * @param state
   *
   * @param {Object} payload
   * @param {boolean} payload.visible     Set's the visibility of the modal
   * @param {string} payload.mode         Set's the mode of the modal
   * @param {boolean} payload.force       If set to yes the modal can't be closed
   */
  [SET_LOGIN_MODAL](state, {
    visible = true, mode = 'login', force = false, redirect = null,
  }) {
    state[LOGIN_MODAL_VISIBLE] = visible;
    state[LOGIN_MODAL_MODE] = mode;
    state[FORCE] = force;
    state[ON_LOGIN_REDIRECT] = redirect;
  },
  [SET_LOGIN_REDIRECT](state, url) {
    state[ON_LOGIN_REDIRECT] = url;
  },
  /**
   * Sets the rfq-form's mode
   *
   * @param state
   * @param {string} formMode     The name of the mode to set
   * to (FORM_MODES.CONFIGURATOR || FORM_MODES.G_SERVE)
   * */
  [SET_FORM_MODE](state, formMode) {
    state[FORM_MODE] = formMode;
  },
  /**
   * Set's the profile modal visibility
   *
   * @param state
   * @param visible
   */
  [SET_PROFILE_MODAL](state, visible) {
    state[PROFILE_MODAL_VISIBLE] = visible;
  },
  /**
   * Set the form's detailed mode
   * @param state
   * @param bool
   */
  [SET_RFQ_DETAIL_MODE](state, bool) {
    state[RFQ_DETAIL_MODE] = bool;
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
  [SET_RFQ_ENGINEER_MODE](state, bool) {
    state[RFQ_ENGINEER_VIEW] = bool;
  },
  [SET_SHOW_FILE_SERVICE_TOOLTIP](state, bool) {
    state[SHOW_FILE_SERVICE_TOOLTIP] = bool;
  },
  [SET_IS_DESKTOP](state, bool) {
    state[IS_DESKTOP] = bool;
  },
  [SET_IS_MOBILE_SIDEBAR_OPEN](state, bool) {
    state[IS_MOBILE_SIDEBAR_OPEN] = bool;
  },
};
