import * as THREE from 'three';
import axios from 'axios';
import TrackBallControls from './TrackballControls';
import OrthographicTrackballControls from './OrthographicTrackballControls';
import OrbitControls from './OrbitControls';

import LineSegmentsGeometry from './Lines/LineSegmentsGeometry';
import LineGeometry from './Lines/LineGeometry';
import LineMaterial from './Lines/LineMaterial';
import LineSegments2 from './Lines/LineSegments2';
import Line2 from './Lines/Line2';

import SpriteText from 'three-spritetext';

window.THREE = THREE;

THREE.TrackballControls = TrackBallControls;
THREE.OrthographicTrackballControls = OrthographicTrackballControls;
THREE.OrbitControls = OrbitControls;
THREE.LineSegmentsGeometry = LineSegmentsGeometry;
THREE.LineGeometry = LineGeometry;
THREE.LineMaterial = LineMaterial;
THREE.LineSegments2 = LineSegments2;
THREE.Line2 = Line2;

export default function GViewer() {
  let serverAddr = '';
  let url_base = 'api/v2/model-parse/';
  let jsonData = null;
  let uid = '';

  let renderElement = 'placeholder';
  let ThreeRenderer = null;
  let scene = null;
  let controls = null;
  let raycaster = null;
  let object = null;
  let wireframe = null;
  let adjoining_edges = null;

  // interface
  const mouse = new THREE.Vector2();
  let mouseDrag = false;

  // let object = null;
  let objectBox = null;
  const objectBounds = {
    x: 0,
    y: 0,
    z: 0,
    average: 0,
    lambda: 0,
    center: [],
  };

  // feature view switch
  const featureObjects = [];
  let modeFeaturesVisible = false;

  // DFM feedback
  const dfmFeedback = {
    sharpCorners: {
      n: 0,
      message: '',
    },
    thinWalls: { message: '' },
    undercuts: { message: '' },
  };

  // Sharp Corners
  const matLine = [];
  const sharpCornersObjects = [];
  let modeSharpCornersVisible = false;

  // Undercuts
  const undercutsObjects = [];
  let modeUndercutsVisible = false;

  // Thin Walls
  const thinWallsFaces = [];
  let modeThinWallsVisible = false;

  // tolerance
  let modeTolerance = false;
  let tolsSelected = [];
  const tolerancesDisplayLines = [];

  // threads
  const threadsDisplayLines = [];

  let camera = null;
  let radius = 150;
  let sceneCenter = new THREE.Vector3();

  let onReadyFunction = function () {
  };
  let onStateToleranceModeFunction = function () {
  };
  let onStateNewToleranceFunction = function () {
  };
  let onStateSavedFunction = function () {
  };

  this.getCamera = function () {
    // console.log(camera);
    return {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };
  };

  this.setCamera = function (positionX, positionY, positionZ) {
    // console.log(positionX, positionY, positionZ);
    camera.position.x = positionX;
    camera.position.y = positionY;
    camera.position.z = positionZ;
    camera.lookAt(sceneCenter);
    camera.updateProjectionMatrix();
  };

  this.setServer = function (serverURL) {
    serverAddr = serverURL;
    url_base = `${serverAddr}/${url_base}`;
  };

  this.loadUID = function (uidIN, render = true) {
    return new Promise(
      (resolve) => {
        uid = uidIN;
        const xhr = new XMLHttpRequest();
        const url = `${url_base + uid}/features`;
        xhr.open('GET', url);
        xhr.onload = function () {
          let json = {
            features: {
              bosses: [],
              holes: [],
              planes: [],
              thread_inputs: [],
            }
          };
          try {
            const parsed = JSON.parse(xhr.responseText);
            if (! parsed.error) {
              json = parsed;
            }
          } catch (err) {
            // Do nothing as we still want the 3D tool to load the preview.
            console.error(err);
          }

          resolve(parseJSON(json, render));
        };
        xhr.send();
      },
    );
  };

  // parse Json - get all holes and thread options,feature.threads
  function parseJSON(jsonObj, render = true) {
    // console.log('parsing JSON data');

    jsonObj.features.thread_inputs = [];

    for (const key in jsonObj) {
      switch (key) {
        case 'features':

          // Run through each feature
          for (const prop in jsonObj[key]) {
            // prop is an id

            const feat_len = jsonObj[key][prop].length;

            if ((feat_len !== 0)
                            && (prop !== 'planes' && prop !== 'lines' && prop !== 'min_radius'
                                && prop !== 'thin_walls' && prop !== 'round_slots' && prop
                                !== 'square_slots' && prop !== 'pockets')) {
              for (const f in jsonObj[key][prop]) {
                switch (prop) {
                  case 'holes':
                    const found_threads = jsonObj[key][prop][f].threads;
                    const num_of_threads = found_threads.thread_size.length;

                    if (num_of_threads !== 0) {
                      for (let th = 0; th < num_of_threads; th++) {
                        if (jsonObj[key][prop][f].type === 'step hole') {
                          const value = `${found_threads.thread_size[th]
                          } (Max depth: ${
                            found_threads.max_depth[th]})`;
                        } else if (jsonObj[key][prop][f].type
                                                    === 'through hole') {
                          const value = found_threads.thread_size[th];
                        }
                      }
                    }
                    break;
                  case 'bosses':
                    break;
                }
              }
            }
          }
      }
    }

    jsonData = jsonObj;
    jsonData.features.tolerances = [];

    if (render) {
      const loader = new THREE.BufferGeometryLoader();
      // load a resource
      loader.load(
        // resource URL
        `${url_base + uid}/preview`,

        // onLoad callback
        (bufferGeometry) => {
          let geom = new THREE.Geometry().fromBufferGeometry(bufferGeometry);
          // geom.center();
          geom.mergeVertices();
          geom.computeBoundingBox();
          sceneCenter = geom.boundingBox.getCenter();

          geom = generateMaterialIndex(geom);
          const material = new THREE.MeshPhongMaterial({
            color: 0xbdbdbd,
            specular: 0x111111,
            shininess: 2,
            emissive: 0x0,
            transparent: true,
            vertexColors: 1,
            polygonOffset: true,
            polygonOffsetFactor: 1,
            polygonOffsetUnits: 1,
          });

          if (object) scene.remove(object);
          if (wireframe) scene.remove(wireframe);

          object = new THREE.Mesh(geom, material);

          scene.add(object);

          // wireframe
          const edges = new THREE.EdgesGeometry(geom, 25);
          wireframe = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
            color: (0x000000),
            linewidth: 1,
            transparent: true,
          }));
          wireframe.visible = true;
          scene.add(wireframe);

          // create bounding box
          objectBox = new THREE.Box3().setFromObject(object);

          objectBounds.x = Math.abs(objectBox.max.x - objectBox.min.x);
          objectBounds.y = Math.abs(objectBox.max.y - objectBox.min.y);
          objectBounds.z = Math.abs(objectBox.max.z - objectBox.min.z);
          objectBounds.average = (objectBounds.x + objectBounds.y + objectBounds.z) / 3;
          objectBounds.lambda = 0.5 * objectBounds.average;
          objectBounds.center = objectBox.getCenter(new THREE.Vector3());

          radius = objectBounds.average * 1.5;

          resetView();

          // see if there is a newer specification on the server
          getSpecification();
        },

        // onProgress callback
        (xhr) => {

        },

        // onError callback
        (err) => {
          console.error(err);
        },
      );
    }

    return jsonObj;
  }

  this.renderParent = function (elementID) {
    document.getElementById(elementID).innerHTML = `<div id="${elementID}-child" class="render-container" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0;"></div>`;
    renderElement = document.getElementById(`${elementID}-child`);

    // create default scene under parent div
    scene = new THREE.Scene();
    camera = new THREE.OrthographicCamera(-960, 960, 540, -540, -10000, 10000);
    raycaster = new THREE.Raycaster();

    ThreeRenderer = new THREE.WebGLRenderer({ antialias: true });
    ThreeRenderer.setClearColor(0xffffff);
    ThreeRenderer.setSize(renderElement.offsetWidth, renderElement.offsetHeight);

    autoSizeRenderer();
    // resetView();

    renderElement.appendChild(ThreeRenderer.domElement);

    window.addEventListener('resize', () => {
      autoSizeRenderer();
    });

    controls = new THREE.OrbitControls(camera, ThreeRenderer.domElement);

    scene.background = new THREE.Color(0xF3F3F3);

    const ambient = new THREE.AmbientLight(0x0);
    scene.add(ambient);

    const light1 = new THREE.PointLight(0xc8c8c8);
    light1.position.set(600, 450, 450);
    scene.add(light1);

    const light2 = new THREE.PointLight(0xc8c8c8);
    light2.position.set(-600, -450, -450);
    scene.add(light2);

    const animate = function () {
      setTimeout(() => {
        if (ThreeRenderer != null) {
          controls.update();
          requestAnimationFrame(animate);
          camera.updateProjectionMatrix();

          const canvas = ThreeRenderer.domElement;
          const width = canvas.clientWidth;
          const height = canvas.clientHeight;
          for (let i = 0; i < matLine.length; i++) {
            matLine[i].resolution.set(width, height);
          }

          ThreeRenderer.render(scene, camera);
        }
      }, 1000 / 60);
    };

    animate();
  };

  // actions

  this.actionTestGetSpecification = function () {
    getSpecification();
  };

  function getSpecification() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${url_base + uid}/specification`, true);
    // xhr.responseType = 'json';
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        // console.log(xhr.responseText);
      }
    };

    // send the collected data as JSON
    xhr.send();

    xhr.onloadend = function () {
      if (xhr.status !== 404 && xhr.responseText.indexOf('Error code: 404') === -1) {
        jsonData = JSON.parse(xhr.responseText);
        drawAllTolerances();
        drawAllThreads();
      }

      fireReady();
    };
  }

  this.actionSaveSpecification = function () {
    return saveSpecification();
  };

  function getCsrfToken() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${serverAddr}/sanctum/csrf-cookie`, true);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      xhr.send();
      xhr.onloadend = function () {
        if (xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.response);
        }
      }
    });
  }

  function saveSpecification() {
    return axios.post(`${url_base + uid}/specification`, jsonData, {
      withCredentials: true,
      withXSRFToken: true,
    });
  }

  this.actionResetView = function () {
    resetView();
  };

  function resetView() {
    // console.log(radius);
    camera.position.x = sceneCenter.x + radius * 0.5;
    camera.position.y = sceneCenter.y + radius * 0.5;
    camera.position.z = sceneCenter.z + radius * 0.5;
    camera.lookAt(sceneCenter);
    switch (true) {
      case radius < 50:
        camera.zoom = 8;
        break;
      case radius < 100:
        camera.zoom = 5;
        break;
      case radius < 200:
        camera.zoom = 3;
        break;
      default:
        camera.zoom = 1;
    }

    camera.updateProjectionMatrix();
    controls.target = sceneCenter;
    controls.update();
  }

  this.actionGetToleranceMode = function () {
    return modeTolerance;
  };

  this.actionSetToleranceMode = function (ToleranceState) {
    setToleranceMode(ToleranceState);
  };

  function setToleranceMode(ToleranceState) {
    modeTolerance = ToleranceState;
    if (modeTolerance) {
      renderElement.addEventListener('mousemove', onDocumentMouseMove, false);
      renderElement.addEventListener('touchmove', onDocumentMouseMove, false);
      renderElement.addEventListener('mousedown', () => mouseDrag = false);
      renderElement.addEventListener('touchstart', () => mouseDrag = false);
      renderElement.addEventListener('mousemove', () => mouseDrag = true);
      renderElement.addEventListener('touchmove', () => mouseDrag = true, true);
      renderElement.addEventListener('mouseup', rendererToleranceHandler);
      renderElement.addEventListener('touchend', rendererToleranceHandler);
    } else {
      for (let i = 0; i < object.geometry.faces.length; i++) {
        object.geometry.faces[i].color.setHex(0xbdbdbd);
      }
      object.geometry.colorsNeedUpdate = true;

      tolsSelected = [];
      renderElement.removeEventListener('mouseup', rendererToleranceHandler, false);
      renderElement.removeEventListener('touchend', rendererToleranceHandler, false);
    }

    onStateToleranceModeFunction(modeTolerance);
  }

  this.actionToleranceMode = function () {
    toleranceMode();
  };

  function toleranceMode() {
    setToleranceMode(!modeTolerance);
  }

  function onDocumentMouseMove(event) {
    event.preventDefault();
    mouse.x = (event.offsetX / ThreeRenderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.offsetY / ThreeRenderer.domElement.clientHeight) * 2 + 1;
  }

  function rendererToleranceHandler(event) {
    if (!mouseDrag) {
      if (event.type === 'touchend') {
        const rect = event.target.getBoundingClientRect();
        mouse.x = ((event.changedTouches[0].pageX - rect.left) / ThreeRenderer.domElement.clientWidth) * 2 - 1;
        mouse.y = -((event.changedTouches[0].pageY - rect.top) / ThreeRenderer.domElement.clientHeight) * 2 + 1;
      }

      raycaster.setFromCamera(mouse, camera);

      // 3. compute intersections
      const intersects = raycaster.intersectObject(object);

      if (intersects.length > 0) {
        const currentIntersect = intersects[0];
        currentIntersect.face.normal.normalize();
        // colour the selected surface
        switch (tolsSelected.length) {
          case 0:
            tolsSelected.push(currentIntersect);
            setFaceColour(currentIntersect, 0x88888);
            break;
          case 1:
            tolsSelected.push(currentIntersect);

            // add temp let handlers for clarity
            const tol1 = tolsSelected[0];
            const tol2 = tolsSelected[1];

            const diffVector = new THREE.Vector3(tol2.point.x - tol1.point.x, tol2.point.y - tol1.point.y, tol2.point.z - tol1.point.z);
            const tolDistance = Math.abs(diffVector.x * tol2.face.normal.x + diffVector.y * tol2.face.normal.y + diffVector.z * tol2.face.normal.z);

            // check for zero thickness
            if (tolDistance >= 0.001) {
              // check if selected surfaces are perpendicular
              if (tol2.face.normal.dot(tol1.face.normal) !== 0) {
                setFaceColour(currentIntersect, 0x88888);

                const vec_prev2curr_pnt = new THREE.Vector3(tol2.point.x - tol1.point.x, tol2.point.y - tol1.point.y, tol2.point.z - tol1.point.z);

                const wanted_dir = tol1.face.normal.multiplyScalar(vec_prev2curr_pnt.dot(tol1.face.normal));
                wanted_dir.normalize();

                const oppositePoint = new THREE.Vector3(tol1.point.x + tolDistance * wanted_dir.x, tol1.point.y + tolDistance * wanted_dir.y,
                  tol1.point.z + tolDistance * wanted_dir.z);
                const direction = find_direction(tol1.point, oppositePoint);
                if (direction !== null) {
                  const vec = new THREE.Vector3(tol2.point.x - oppositePoint.x, tol2.point.y - oppositePoint.y, tol2.point.z - oppositePoint.z);

                  const projectConstant = vec.dot(direction);

                  const toleranceLinePoints = [
                    // line1
                    [tol1.point.x, tol1.point.y, tol1.point.z],
                    [
                      tol1.point.x + objectBounds.lambda * direction.x,
                      tol1.point.y + objectBounds.lambda * direction.y,
                      tol1.point.z + objectBounds.lambda * direction.z],
                    // line2
                    [
                      oppositePoint.x + projectConstant * direction.x,
                      oppositePoint.y + projectConstant * direction.y,
                      oppositePoint.z + projectConstant * direction.z],
                    [
                      oppositePoint.x + objectBounds.lambda * direction.x,
                      oppositePoint.y + objectBounds.lambda * direction.y,
                      oppositePoint.z + objectBounds.lambda * direction.z],
                  ];

                  const currentTolerance = {
                    distance: Math.abs(tolDistance)
                      .toFixed(3),
                    tolerance: 'default',
                    line_points: toleranceLinePoints,
                  };
                  jsonData.features.tolerances.push(currentTolerance);
                  drawAllTolerances();
                  onStateNewToleranceFunction(currentTolerance);
                }
                toleranceMode();
              } else {
              }
            }

            break;
          default:

            toleranceMode();
            console.log('SHOULD NOT HAPPEN :O');
        }
      }
    }
  }

  this.actionSetToleranceByID = function (ID, toleranceText) {
    SetToleranceByID(ID, toleranceText);
  };

  function SetToleranceByID(ID, toleranceText) {
    for (let i = 0; i < jsonData.features.tolerances.length; i++) {
      if (i === ID) {
        jsonData.features.tolerances[i].tolerance = toleranceText;
      }
    }
    drawAllTolerances();
  }

  this.actionRemoveToleranceByID = function (ID) {
    removeToleranceByID(ID);
  };

  function removeToleranceByID(ID, updateScreen) {
    for (let i = 0; i < jsonData.features.tolerances.length; i++) {
      if (i === ID) {
        jsonData.features.tolerances.splice(i, 1);
      }
    }
    if (updateScreen) {
      drawAllTolerances();
    }
  }

  function drawAllTolerances() {
    // remove all tolerances
    for (let i = 0; i < tolerancesDisplayLines.length; i++) {
      scene.remove(tolerancesDisplayLines[i]);
    }

    // loop through all and draw individually
    for (let i = 0; i < jsonData.features.tolerances.length; i++) {
      const obj = jsonData.features.tolerances[i];
      drawTolerance(obj);
    }
  }

  function drawTolerance(tolerance) {
    const lines_group = new THREE.Group();

    // Draw arrows for the dimensions
    const points = tolerance.line_points;
    const origin1 = new THREE.Vector3(points[1][0], points[1][1], points[1][2]);
    const length = tolerance.distance;
    const hex = 0x000000;

    const headLength = 0.05 * length;
    const headWidth = 0.6 * headLength;

    const direction = new THREE.Vector3(points[3][0] - points[1][0], points[3][1] - points[1][1], points[3][2] - points[1][2]);
    direction.normalize();

    const arrow1 = new THREE.ArrowHelper(direction, origin1, length, hex, headLength, headWidth);

    const origin2 = new THREE.Vector3(points[3][0], points[3][1], points[3][2]);
    const arrow2 = new THREE.ArrowHelper(direction.negate(), origin2, length, hex, headLength, headWidth);

    lines_group.add(arrow1);
    lines_group.add(arrow2);

    // Draw lines for the dimensions
    let geometry = new THREE.BufferGeometry();
    let positions = new Float32Array([
      points[0][0], points[0][1], points[0][2],
      points[1][0], points[1][1], points[1][2],
    ]);

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    let line = new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    lines_group.add(line);

    geometry = new THREE.BufferGeometry();
    positions = new Float32Array([
      points[2][0], points[2][1], points[2][2],
      points[3][0], points[3][1], points[3][2],
    ]);

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    line = new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    lines_group.add(line);

    // Draw text
    const middlePoint = new THREE.Vector3((points[1][0] + points[3][0]) / 2, (points[1][1] + points[3][1]) / 2, (points[1][2] + points[3][2]) / 2);

    // let message = Math.abs(tolerance.distance).toFixed(3) + ' \xb1 ' + tolerance.tolerance + ' mm';
    const message = `${Math.abs(tolerance.distance)
      .toFixed(3)} \xb1 ${tolerance.tolerance} mm`;
    const myText = new SpriteText(message);
    myText.color = 'black';
    const height = ((objectBounds.x + objectBounds.y + objectBounds.z) / 4) / 10;
    myText.textHeight = height;

    const lineDirection = new THREE.Vector3(points[1][0] - points[0][0], points[1][1] - points[0][1], points[1][2] - points[0][2]);
    lineDirection.normalize();
    myText.position.set(middlePoint.x + height * lineDirection.x, middlePoint.y + height * lineDirection.y, middlePoint.z + height * lineDirection.z);

    lines_group.add(myText);

    scene.add(lines_group);
    tolerancesDisplayLines.push(lines_group);
  }

  this.actionRemoveThreadByID = function (ID) {
    removeThreadByID(ID);
  };

  function removeThreadByID(ID, updateScreen = true) {
    for (let i = 0; i < jsonData.features.thread_inputs.length; i++) {
      if (jsonData.features.thread_inputs[i].ID === ID) {
        jsonData.features.thread_inputs.splice(i, 1);
      }
    }
    if (updateScreen) {
      drawAllThreads();
    }
  }

  this.actionSetThreadByID = function (ID, thread_name) {
    setThreadByID(ID, thread_name);
  };

  function getFeatureForThreading(ID) {
    const { holes, bosses } = jsonData.features;
    return [...(holes || []), ...(bosses || [])].find((el) => el.ID === ID);
  }

  function setThreadByID(ID, thread = '') {
    removeThreadByID(ID, false);

    const current_feature = getFeatureForThreading(ID);

    const point = current_feature.position;
    const dir = current_feature.direction;
    const xdir = current_feature.xdirection;
    const { radius } = current_feature;
    const { depth } = current_feature;

    let lambda = 0;
    if (depth < 5) {
      lambda = 3 * depth;
    } else if (depth < 15) {
      lambda = 2 * depth;
    } else {
      lambda = depth;
    }

    const start_pnt = [point[0] + radius * xdir[0], point[1] + radius * xdir[1], point[2] + radius * xdir[2]];

    const geometryTemp = new THREE.BufferGeometry();
    const positionsTemp = new Float32Array([
      start_pnt[0], start_pnt[1], start_pnt[2],
      start_pnt[0] + lambda * dir[0], start_pnt[1] + lambda * dir[1], start_pnt[2] + lambda * dir[2],
    ]);

    geometryTemp.addAttribute('position', new THREE.BufferAttribute(positionsTemp, 3));

    const message = thread.split('(Max')[0];
    const thread_txt = new SpriteText(message);
    thread_txt.color = 'black';
    thread_txt.textHeight = ((objectBounds.x + objectBounds.y + objectBounds.z) / 4) / 10;
    const d = lambda + ((objectBounds.x + objectBounds.y + objectBounds.z) / 4) / 10;
    thread_txt.position.set(start_pnt[0] + d * dir[0], start_pnt[1] + d * dir[1], start_pnt[2] + d * dir[2]);

    const line_pnts = [
      [start_pnt[0], start_pnt[1], start_pnt[2]],
      [start_pnt[0] + lambda * dir[0], start_pnt[1] + lambda * dir[1], start_pnt[2] + lambda * dir[2]],
    ];
    const currentThread = {
      ID,
      thread_size: message,
      line_points: line_pnts,
      thread_dir: dir,
      text_position: [start_pnt[0] + d * dir[0], start_pnt[1] + d * dir[1], start_pnt[2] + d * dir[2]],
    };
    jsonData.features.thread_inputs.push(currentThread);

    drawAllThreads();
  }

  function drawAllThreads() {
    // remove all visible threads
    for (let i = 0; i < threadsDisplayLines.length; i++) {
      scene.remove(threadsDisplayLines[i]);
    }

    // loop through all and draw individually
    for (let i = 0; i < jsonData.features.thread_inputs.length; i++) {
      // let obj = jsonData['features']['holes'][i];
      // setThreadByID(i + 1);
      drawThread(jsonData.features.thread_inputs[i]);
    }
  }

  function drawThread(thread) {
    const lines_group = new THREE.Group();
    const points = thread.line_points;
    // Draw line
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array([
      points[0][0], points[0][1], points[0][2],
      points[1][0], points[1][1], points[1][2],
    ]);

    geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
    const line = new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0x000000 }));
    lines_group.add(line);

    const message = thread.thread_size;
    const thread_txt = new SpriteText(message);
    thread_txt.color = 'black';
    const height = ((objectBounds.x + objectBounds.y + objectBounds.z) / 4) / 10;
    thread_txt.textHeight = height;
    thread_txt.position.set(thread.text_position[0], thread.text_position[1], thread.text_position[2]);
    lines_group.add(thread_txt);

    scene.add(lines_group);
    threadsDisplayLines.push(lines_group);
  }

  function setFaceColour(intersect, color) {
    if (intersect.face.materialIndex !== 0) {
      for (let i = 0; i < object.geometry.faces.length; i++) {
        if (object.geometry.faces[i].materialIndex === intersect.face.materialIndex) {
          object.geometry.faces[i].color.setHex(color);
        }
      }
      object.geometry.colorsNeedUpdate = true;
    }
  }

  function generateMaterialIndex(geometry) {
    const { faces } = geometry;
    const faces_num = faces.length;
    const { vertices } = geometry;
    for (let i = 0; i < jsonData.features.planes.length; i++) {
      const plane = jsonData.features.planes[i];
      const { normal } = plane;
      const constant = plane.plane_constant;
      for (let f = 0; f < faces_num; f++) {
        const parallel = dotProduct(normal, faces[f].normal);
        if (Math.abs(1 - parallel) <= 1e-2) {
          const d1 = dotProduct(normal, vertices[faces[f].a]);
          const d2 = dotProduct(normal, vertices[faces[f].b]);
          const d3 = dotProduct(normal, vertices[faces[f].c]);
          if ((Math.abs(d1 - constant) <= 1e-2) && (Math.abs(d2 - constant) <= 1e-2) && (Math.abs(d3 - constant) <= 1e-2)) {
            if (faces[f].materialIndex === 0) {
              faces[f].materialIndex = i + 1;
            }
          }
        }
      }
    }

    return geometry;
  }

  function dotProduct(a, b) {
    return a[0] * b.x + a[1] * b.y + a[2] * b.z;
  }

  // todo tidy this up
  function find_direction(point, opp_point) {
    // find the 8 points of the object's boundary box
    // 4 points above center point
    const p1 = [objectBounds.center.x + objectBounds.x / 2, objectBounds.center.y + objectBounds.y / 2, objectBounds.center.z + objectBounds.z / 2];
    const p2 = [objectBounds.center.x + objectBounds.x / 2, objectBounds.center.y + objectBounds.y / 2, objectBounds.center.z - objectBounds.z / 2];
    const p3 = [objectBounds.center.x - objectBounds.x / 2, objectBounds.center.y + objectBounds.y / 2, objectBounds.center.z - objectBounds.z / 2];
    const p4 = [objectBounds.center.x - objectBounds.x / 2, objectBounds.center.y + objectBounds.y / 2, objectBounds.center.z + objectBounds.z / 2];
    // 4 points below objectBounds.center point
    const p5 = [objectBounds.center.x + objectBounds.x / 2, objectBounds.center.y - objectBounds.y / 2, objectBounds.center.z + objectBounds.z / 2];
    const p6 = [objectBounds.center.x + objectBounds.x / 2, objectBounds.center.y - objectBounds.y / 2, objectBounds.center.z - objectBounds.z / 2];
    const p7 = [objectBounds.center.x - objectBounds.x / 2, objectBounds.center.y - objectBounds.y / 2, objectBounds.center.z - objectBounds.z / 2];
    const p8 = [objectBounds.center.x - objectBounds.x / 2, objectBounds.center.y - objectBounds.y / 2, objectBounds.center.z + objectBounds.z / 2];

    // define the normals of the 6 planes of the bbox
    // x direction
    const Nx_pos = new THREE.Vector3(1, 0, 0);
    const Nx_neg = new THREE.Vector3(-1, 0, 0);
    // y direction
    const Ny_pos = new THREE.Vector3(0, 1, 0);
    const Ny_neg = new THREE.Vector3(0, -1, 0);
    // z direction
    const Nz_pos = new THREE.Vector3(0, 0, 1);
    const Nz_neg = new THREE.Vector3(0, 0, -1);

    // define the plane constant of the 6 planes of the bbox
    // x direction
    const const_xpos = dotProduct(p1, Nx_pos);
    const const_xneg = dotProduct(p3, Nx_neg);
    // y direction
    const const_ypos = dotProduct(p3, Ny_pos);
    const const_yneg = dotProduct(p5, Ny_neg);
    // z direction
    const const_zpos = dotProduct(p1, Nz_pos);
    const const_zneg = dotProduct(p3, Nz_neg);

    const p_center = [objectBounds.center.x, objectBounds.center.y, objectBounds.center.z];

    const boxes_min_values = [];
    const boxes_max_values = [];
    const boxes_normals = [];
    const boxes_constants = [];

    // box1 (x_pos, y_pos, z_pos)
    // b1_points = [p1, p12, p12_34, p41, p15, p12_56, p_center, p41_58];
    const b1_normals = [Nx_pos, Ny_pos, Nz_pos];
    const b1_max = [p1[0], p1[1], p1[2]];
    const b1_min = [p_center[0], p_center[1], p_center[2]];
    boxes_min_values.push(b1_min);
    boxes_max_values.push(b1_max);
    boxes_normals.push(b1_normals);
    boxes_constants.push([const_xpos, const_ypos, const_zpos]);

    // box2 (x_pos, y_pos, z_neg)
    // b2_points = [p12, p2, p23, p12_34, p12_56, p62, p23_67, p_center];
    const b2_normals = [Nx_pos, Ny_pos, Nz_neg];
    const b2_max = [p2[0], p2[1], p_center[2]];
    const b2_min = [p_center[0], p_center[1], p2[2]];
    boxes_min_values.push(b2_min);
    boxes_max_values.push(b2_max);
    boxes_normals.push(b2_normals);
    boxes_constants.push([const_xpos, const_ypos, const_zneg]);

    // box3 (x_neg, y_pos, z_neg)
    // b3_points = [p12_34, p23, p3, p34, p_center, p23_67, p73, p78_34];
    const b3_normals = [Nx_neg, Ny_pos, Nz_neg];
    const b3_max = [p_center[0], p3[1], p_center[2]];
    const b3_min = [p3[0], p_center[1], p3[2]];
    boxes_min_values.push(b3_min);
    boxes_max_values.push(b3_max);
    boxes_normals.push(b3_normals);
    boxes_constants.push([const_xneg, const_ypos, const_zneg]);

    // box4 (x_neg, y_pos, z_pos)
    // b4_points = [p41, p12_34, p34, p4, p41_58, p_center, p78_34, p84];
    const b4_normals = [Nx_neg, Ny_pos, Nz_pos];
    const b4_max = [p_center[0], p4[1], p4[2]];
    const b4_min = [p4[0], p_center[1], p_center[2]];
    boxes_min_values.push(b4_min);
    boxes_max_values.push(b4_max);
    boxes_normals.push(b4_normals);
    boxes_constants.push([const_xneg, const_ypos, const_zpos]);

    // box5 (x_pos, y_neg, z_pos)
    // b5_points = [p15, p12_56, p_center, p41_58, p5, p56, p56_78, p58];
    const b5_normals = [Nx_pos, Ny_neg, Nz_pos];
    const b5_max = [p5[0], p_center[1], p5[2]];
    const b5_min = [p_center[0], p5[1], p_center[2]];
    boxes_min_values.push(b5_min);
    boxes_max_values.push(b5_max);
    boxes_normals.push(b5_normals);
    boxes_constants.push([const_xpos, const_yneg, const_zpos]);

    // box6 (x_pos, y_neg, z_neg)
    // b6_points = [p12_56, p62, p23_67, p_center, p56, p6, p67, p56_78];
    const b6_normals = [Nx_pos, Ny_neg, Nz_neg];
    const b6_max = [p6[0], p_center[1], p_center[2]];
    const b6_min = [p_center[0], p6[1], p6[2]];
    boxes_min_values.push(b6_min);
    boxes_max_values.push(b6_max);
    boxes_normals.push(b6_normals);
    boxes_constants.push([const_xpos, const_yneg, const_zneg]);

    // box7 (x_neg, y_neg, z_neg)
    // b7_points = [p_center, p23_67, p73, p78_34, p56_78, p67, p7, p78];
    const b7_normals = [Nx_neg, Ny_neg, Nz_neg];
    const b7_max = [p_center[0], p_center[1], p_center[2]];
    const b7_min = [p7[0], p7[1], p7[2]];
    boxes_min_values.push(b7_min);
    boxes_max_values.push(b7_max);
    boxes_normals.push(b7_normals);
    boxes_constants.push([const_xneg, const_yneg, const_zneg]);

    // box8 (x_neg, y_neg, z_pos)
    // b8_points = [p41_58, p_center, p78_34, p84, p58, p56_78, p78, p8];
    const b8_normals = [Nx_neg, Ny_neg, Nz_pos];
    const b8_max = [p_center[0], p_center[1], p8[2]];
    const b8_min = [p8[0], p8[1], p_center[2]];
    boxes_min_values.push(b8_min);
    boxes_max_values.push(b8_max);
    boxes_normals.push(b8_normals);
    boxes_constants.push([const_xneg, const_yneg, const_zpos]);

    let selected_box;
    // Find in which bbox the point lies
    for (let i = 0; i < boxes_max_values.length; i++) {
      if ((point.x >= boxes_min_values[i][0]) && (point.x <= boxes_max_values[i][0])) {
        if ((point.y >= boxes_min_values[i][1]) && (point.y <= boxes_max_values[i][1])) {
          if ((point.z >= boxes_min_values[i][2]) && (point.z <= boxes_max_values[i][2])) {
            selected_box = i;
          }
        }
      }
    }

    const direction = new THREE.Vector3(opp_point.x - point.x, opp_point.y - point.y, opp_point.z - point.z);
    direction.normalize();

    // Find the possible directions
    const selected_normals = boxes_normals[selected_box];
    const selected_constants = boxes_constants[selected_box];
    if (selected_normals !== undefined) {
      const possible_directions = [];
      for (let i = 0; i < selected_normals.length; i++) {
        if (Math.round(direction.dot(selected_normals[i])) === 0) {
          possible_directions.push(i);
        }
      }

      const dist_planes_pnt = [];
      for (let i = 0; i < possible_directions.length; i++) {
        const result = selected_normals[possible_directions[i]].dot(point);
        dist_planes_pnt.push(Math.abs(result - selected_constants[possible_directions[i]]));
      }

      const min_ind = dist_planes_pnt.indexOf(Math.min(...dist_planes_pnt));

      return selected_normals[possible_directions[min_ind]];
    }
    return null;
  }

  function createAllFeatures() {
    // iterate through features and create objects for them
    if (featureObjects.length < 1) {
      const { holes } = jsonData.features;
      for (let i = 0; i < holes.length; i++) {
        const { position } = holes[i];
        const featureRadius = holes[i].radius;
        const { depth } = holes[i];
        const { direction } = holes[i];
        const featureMaterial = new THREE.MeshPhongMaterial({
          color: 0x0000ff,
          side: THREE.DoubleSide,
        });
        const featureGeometry = new THREE.CylinderGeometry(featureRadius - 0.2, featureRadius - 0.2, depth, 50, 50, true);
        featureGeometry.applyMatrix(new THREE.Matrix4().makeRotationX(THREE.Math.degToRad(90)));
        const mesh = new THREE.Mesh(featureGeometry, featureMaterial);
        mesh.position.copy(new THREE.Vector3(position[0], position[1], position[2]));
        mesh.lookAt(new THREE.Vector3(position[0], position[1], position[2]).add(new THREE.Vector3(direction[0], direction[1], direction[2])));

        mesh.name = `${jsonData.features.holes[i].type} ${jsonData.features.holes[i].ID.toString()}`;
        featureObjects.push(mesh);
        mesh.visible = false;
        scene.add(mesh);
      }

      const { bosses } = jsonData.features;
      for (let i = 0; i < bosses.length; i++) {
        const { position } = bosses[i];
        const featureRadius = bosses[i].radius;
        const { depth } = bosses[i];
        const { direction } = bosses[i];
        const featureMaterial = new THREE.MeshPhongMaterial({
          color: 0x0000ff,
          side: THREE.DoubleSide,
        });
        const featureGeometry = new THREE.CylinderGeometry(featureRadius + 0.2, featureRadius + 0.2, depth, 50, 50, true);
        featureGeometry.applyMatrix(new THREE.Matrix4().makeRotationX(THREE.Math.degToRad(90)));
        const mesh = new THREE.Mesh(featureGeometry, featureMaterial);
        mesh.position.copy(new THREE.Vector3(position[0], position[1], position[2]));
        mesh.lookAt(new THREE.Vector3(position[0], position[1], position[2]).add(new THREE.Vector3(direction[0], direction[1], direction[2])));

        mesh.name = `${jsonData.features.bosses[i].type} ${jsonData.features.bosses[i].ID.toString()}`;
        featureObjects.push(mesh);
        mesh.visible = false;
        scene.add(mesh);
      }
    }
  }

  // actions
  this.actionViewHoleByID = function (id) {
    viewHoleByID(id);
  };

  function viewHoleByID(id) {
    for (let i = 0; i < jsonData.features.holes.length; i++) {
      if (jsonData.features.holes[i].ID === id) {
        viewHole(jsonData.features.holes[i]);
      }
    }
  }

  function viewHole(holeJson) {
    const holeDir = holeJson.direction;
    const holePos = holeJson.position;
    const holeName = `${holeJson.type} ${holeJson.ID.toString()}`;

    camera.position.x = holePos[0] + 100 * holeDir[0];
    camera.position.y = holePos[1] + 100 * holeDir[1];
    camera.position.z = holePos[2] + 100 * holeDir[2];

    camera.zoom = 5;
    camera.updateProjectionMatrix();

    featuresViewIndividual(holeName);
  }

  this.actionViewBossByID = function (id) {
    viewBossByID(id);
  };

  function viewBossByID(id) {
    for (let i = 0; i < jsonData.features.bosses.length; i++) {
      if (jsonData.features.bosses[i].ID === id) {
        viewBoss(jsonData.features.bosses[i]);
      }
    }
  }

  function viewBoss(bossJson) {
    const bossDir = bossJson.direction;
    const bossPos = bossJson.position;
    const bossName = `${bossJson.type} ${bossJson.ID.toString()}`;

    camera.position.x = bossPos[0] + 100 * bossDir[0];
    camera.position.y = bossPos[1] + 100 * bossDir[1];
    camera.position.z = bossPos[2] + 100 * bossDir[2];

    camera.zoom = 5;
    camera.updateProjectionMatrix();

    featuresViewIndividual(bossName);
  }

  this.actionOpacitySwitch = function () {
    opacitySwitch();
  };

  function opacitySwitch() {
    if (object.material.opacity !== 0.5) {
      object.material.opacity = 0.5;
    } else {
      object.material.opacity = 1;
    }
  }

  this.actionFeaturesView = function () {
    featuresView();
  };

  function featuresView() {
    // create the feature only the first time
    createAllFeatures();

    // features visibility switch
    if (modeFeaturesVisible) {
      for (let i = 0; i < featureObjects.length; i++) {
        featureObjects[i].visible = false;
      }
      modeFeaturesVisible = false;
    } else {
      for (let i = 0; i < featureObjects.length; i++) {
        featureObjects[i].visible = true;
      }
      modeFeaturesVisible = true;
    }
  }

  function featuresViewIndividual(featureName) {
    // create the feature only the first time
    createAllFeatures();

    const obj = scene.getObjectByName(featureName);

    for (let i = 0; i < featureObjects.length; i++) {
      if (featureObjects[i].name != featureName) {
        featureObjects[i].visible = false;
      }
    }

    obj.visible = true;
  }

  this.actionAdjoiningEdges = function () {
    edgeAdjoiningFaces();
  };

  function edgeAdjoiningFaces() {
    const edge = [0, 0];
    const edges = {};
    let edge1;
    let edge2;
    let key;
    const keys = ['a', 'b', 'c'];

    const { faces } = object.geometry;
    // create a data structure where each entry represents an edge with its adjoining faces
    for (let i = 0, l = faces.length; i < l; i++) {
      const face = faces[i];

      for (let j = 0; j < 3; j++) {
        edge1 = face[keys[j]];
        edge2 = face[keys[(j + 1) % 3]];

        edge[0] = Math.min(edge1, edge2);
        edge[1] = Math.max(edge1, edge2);

        key = `${edge[0]},${edge[1]}`;

        if (edges[key] === undefined) {
          edges[key] = {
            index1: edge[0],
            index2: edge[1],
            face1: i,
            face2: undefined,
          };
        } else {
          edges[key].face2 = i;
        }
      }
    }

    adjoining_edges = edges;
  }

  // ========================= DFM ===============================
  this.actionCreateSharpCorners = function () {
    createSharpCorners();
  };

  function createSharpCorners() {
    const sharp_corners = jsonData.features.min_radius;
    let cornerCounter = 0;
    let line = null;
    // loop over all g-serve detected possible sharp corners
    for (let i = 0; i < sharp_corners.length; i++) {
      // check if there is a radius less tahn 0.5 but not zero
      if (sharp_corners[i].radius != 0) {
        const pnts = sharp_corners[i].points;

        const p1 = new THREE.Vector3(pnts[0][0], pnts[0][1], pnts[0][2]);
        const p2 = new THREE.Vector3(pnts[1][0], pnts[1][1], pnts[1][2]);
        const p3 = new THREE.Vector3(pnts[2][0], pnts[2][1], pnts[2][2]);
        const p4 = new THREE.Vector3(pnts[3][0], pnts[3][1], pnts[3][2]);

        // first line
        line = drawCornerLine(p1, p3);
        sharpCornersObjects.push(line);

        // second line
        line = drawCornerLine(p2, p4);
        sharpCornersObjects.push(line);

        cornerCounter += 1;
      }
      // check if there is a radius equal to zero
      else if (sharp_corners[i].radius == 0) {
        const pnts = sharp_corners[i].points;
        const p1 = new THREE.Vector3(pnts[0][0], pnts[0][1], pnts[0][2]);
        const p2 = new THREE.Vector3(pnts[1][0], pnts[1][1], pnts[1][2]);
        const p3 = new THREE.Vector3(pnts[3][0], pnts[3][1], pnts[3][2]);
        const flag = pnts[2];

        object.geometry.computeFaceNormals();

        const { faces } = object.geometry;

        const sourceVertices = object.geometry.vertices;

        if (flag == 'circle') {
          const normal = new THREE.Vector3(pnts[3][0], pnts[3][1], pnts[3][2]);
          const pnt = new THREE.Vector3(pnts[4][0], pnts[4][1], pnts[4][2]);

          for (const key in adjoining_edges) {
            const e = adjoining_edges[key];

            const vertex1 = sourceVertices[e.index1];
            const vertex2 = sourceVertices[e.index2];

            const dist1 = p1.distanceTo(vertex1);
            const dist2 = p2.distanceTo(vertex2);
            const dist3 = p1.distanceTo(vertex2);
            const dist4 = p2.distanceTo(vertex1);

            if ((dist1 <= 0.01 && dist2 <= 0.01) || (dist3 <= 0.01 && dist4 <= 0.01)) {
              const N1 = new THREE.Vector3(pnt.x - p1.x, pnt.y - p1.y, pnt.z - p1.z);
              N1.normalize();

              if (faces[e.face1].normal.dot(normal) >= 0.9) {
                const N2 = faces[e.face2].normal;
              } else {
                const N2 = faces[e.face1].normal;
              }

              if (N1.dot(N2) > 0) {
                if (Math.round(N1.dot(N2)) == 1) {
                  line = drawCornerLine(vertex1, vertex2);
                  sharpCornersObjects.push(line);

                  cornerCounter += 1;
                }
              }
            }
          }
        } else if (flag == 'line') {
          const EPS = 0.1;

          const raycaster1 = new THREE.Raycaster();
          const raycaster2 = new THREE.Raycaster();

          let intersects1 = [];
          let intersects2 = [];

          for (const key in adjoining_edges) {
            const e = adjoining_edges[key];

            const vertex1 = sourceVertices[e.index1];
            const vertex2 = sourceVertices[e.index2];

            const dist1 = p1.distanceTo(vertex1);
            const dist2 = p2.distanceTo(vertex2);
            const dist3 = p1.distanceTo(vertex2);
            const dist4 = p2.distanceTo(vertex1);

            if ((dist1 <= 0.01 && dist2 <= 0.01) || (dist3 <= 0.01 && dist4 <= 0.01)) {
              const N1 = faces[e.face1].normal;
              const N2 = faces[e.face2].normal;

              const N = new THREE.Vector3(p2.x - p1.x, p2.y - p1.y, p2.z - p1.z);
              const p_a = new THREE.Vector3(p1.x + EPS * (N.x + N2.x), p1.y + EPS * (N.y + N2.y), p1.z + EPS * (N.z + N2.z));
              const p_b = new THREE.Vector3(p1.x + EPS * (N.x + N1.x), p1.y + EPS * (N.y + N1.y), p1.z + EPS * (N.z + N1.z));

              raycaster1.set(p_a, N1);
              intersects1 = raycaster1.intersectObject(object);

              raycaster2.set(p_b, N2);
              intersects2 = raycaster2.intersectObject(object);

              if (intersects1.length > 0 && intersects2.length > 0) {
                const start_vector = new THREE.Vector3(p3.x - p1.x, p3.y - p1.y, p3.z - p1.z);
                start_vector.normalize();

                const d_list = [];
                d_list.push(start_vector.dot(N1));
                d_list.push(start_vector.dot(N2));

                const result = d_list.filter((d) => Math.round(d) != 0);

                if (result > 0) {
                  line = drawCornerLine(p1, p2);
                  sharpCornersObjects.push(line);

                  cornerCounter += 1;
                }
              }
            }
          }
        }
      }
    }
    dfmFeedback.sharpCorners = cornerCounter;
  }

  function drawCornerLine(point1, point2) {
    const positions = [];
    const colors = [];
    const color = new THREE.Color();
    positions.push(point1.x, point1.y, point1.z);
    positions.push(point2.x, point2.y, point2.z);

    color.setRGB(255, 0, 0);
    colors.push(color.r, color.g, color.b);
    colors.push(color.r, color.g, color.b);

    const geometry = new THREE.LineGeometry();
    geometry.setPositions(positions);
    geometry.setColors(colors);

    const current_matLine = new THREE.LineMaterial({

      color: 0xffffff,
      linewidth: 7,
      vertexColors: THREE.VertexColors,

    });

    matLine.push(current_matLine);

    const mesh = new THREE.Line2(geometry, current_matLine);
    mesh.computeLineDistances();
    mesh.scale.set(1, 1, 1);
    scene.add(mesh);
    mesh.visible = false;

    return mesh;
  }

  this.actionSharpCornersView = function () {
    sharpCornersView();
  };

  function sharpCornersView() {
    // sharp corners visibility switch
    if (modeSharpCornersVisible) {
      for (let i = 0; i < sharpCornersObjects.length; i++) {
        sharpCornersObjects[i].visible = false;
        object.material.opacity = 1.0;
      }
      modeSharpCornersVisible = false;
    } else {
      for (let i = 0; i < sharpCornersObjects.length; i++) {
        sharpCornersObjects[i].visible = true;
        object.material.opacity = 0.6;
      }
      modeSharpCornersVisible = true;
    }
  }

  this.actionCreateUndercuts = function () {
    createUndercuts();
  };

  function createUndercuts() {
    let undercutsCounter = 0;
    const { geometry } = object;
    const material = new THREE.MeshNormalMaterial({ side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);

    const raycaster = new THREE.Raycaster();

    let intersects = [];

    const sides_directions = [
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(-1, 0, 0),
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, -1, 0),
      new THREE.Vector3(0, 0, 1),
      new THREE.Vector3(0, 0, -1),
    ];

    const EPS = 0.35;
    let vecs = [];

    const { faces } = mesh.geometry;
    const { vertices } = mesh.geometry;

    const faces_num = faces.length;

    const midPoint = new THREE.Vector3();
    const tri = new THREE.Triangle();

    for (let i = 0; i < faces_num; i++) {
      const no = faces[i].normal;
      const no_s = new THREE.Vector3(no.x * EPS, no.y * EPS, no.z * EPS);

      vecs = [vertices[faces[i].a], vertices[faces[i].b], vertices[faces[i].c]];

      tri.set(vecs[0], vecs[1], vecs[2]);
      tri.getMidpoint(midPoint);

      const p_a = new THREE.Vector3(midPoint.x + no_s.x, midPoint.y + no_s.y, midPoint.z + no_s.z);

      let counter = 0;

      for (let r = 0; r < 6; r++) {
        raycaster.set(p_a, sides_directions[r]);
        intersects = raycaster.intersectObject(mesh);

        if (intersects.length > 0) {
          counter++;
        }
      }

      if (counter == 6) {
        const materialUC = new THREE.MeshBasicMaterial({
          color: 0xff0000,
          transparent: true,
          vertexColors: THREE.FaceColors,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
          side: THREE.DoubleSide,
        });

        const geometryUC = new THREE.Geometry();
        geometryUC.vertices.push(
          vecs[0],
          vecs[1],
          vecs[2],
        );

        geometryUC.faces.push(
          new THREE.Face3(0, 1, 2),
        );

        const meshUC = new THREE.Mesh(geometryUC, materialUC);

        meshUC.visible = false;
        scene.add(meshUC);
        undercutsObjects.push(meshUC);

        undercutsCounter += 1;
      }
    }
    dfmFeedback.undercuts = undercutsCounter > 0;
  }

  this.actionUndercutsView = function () {
    undercutsView();
  };

  function undercutsView() {
    // undercuts visibility switch
    if (modeUndercutsVisible) {
      for (let i = 0; i < undercutsObjects.length; i++) {
        undercutsObjects[i].visible = false;
        object.material.opacity = 1.0;
        object.material.depthWrite = true;
      }
      modeUndercutsVisible = false;
    } else {
      for (let i = 0; i < undercutsObjects.length; i++) {
        undercutsObjects[i].visible = true;
        object.material.opacity = 0.6;
        object.material.depthWrite = false;
      }
      modeUndercutsVisible = true;
    }
  }

  this.actionCreateThinWalls = function (material) {
    createThinWalls(material);
  };

  function createThinWalls(selectedMaterial) {
    let thickness;

    if (selectedMaterial === 'metal') {
      thickness = 0.8;
    } else if (selectedMaterial === 'plastic') {
      thickness = 1.2;
    }

    let thinWallsCounter = 0;
    const { geometry } = object;
    const material = new THREE.MeshNormalMaterial({ side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);

    const raycaster = new THREE.Raycaster();
    let intersects = [];
    const EPS = 0.0001;

    const { faces } = mesh.geometry;
    const { vertices } = mesh.geometry;

    const faces_num = faces.length;

    let vecs = [];

    for (let i = 0; i < faces_num; i++) {
      const no = faces[i].normal;
      const no_s = new THREE.Vector3(no.x * EPS, no.y * EPS, no.z * EPS);
      const no_e = new THREE.Vector3(no.x * thickness, no.y * thickness, no.z * thickness);

      vecs = [vertices[faces[i].a], vertices[faces[i].b], vertices[faces[i].c]];

      const rnd_min = 0.05;
      const rnd_max = 0.95;

      const num_points = 6;
      for (let j = 0; j < num_points; j++) {
        let u1 = Math.random() * (rnd_max - rnd_min) + rnd_min;
        let u2 = Math.random() * (rnd_max - rnd_min) + rnd_min;
        const u_tot = u1 + u2;

        if (u_tot > 1.0) {
          u1 = 1.0 - u1;
          u2 = 1.0 - u2;
        }

        const side1 = new THREE.Vector3(vecs[1].x - vecs[0].x, vecs[1].y - vecs[0].y, vecs[1].z - vecs[0].z);
        const side2 = new THREE.Vector3(vecs[2].x - vecs[0].x, vecs[2].y - vecs[0].y, vecs[2].z - vecs[0].z);

        const p = new THREE.Vector3(
          vecs[0].x + u1 * side1.x + u2 * side2.x,
          vecs[0].y + u1 * side1.y + u2 * side2.y,
          vecs[0].z + u1 * side1.z + u2 * side2.z,
        );

        const p_a = new THREE.Vector3(p.x - no_s.x, p.y - no_s.y, p.z - no_s.z);
        const p_b = new THREE.Vector3(p.x - no_e.x, p.y - no_e.y, p.z - no_e.z);
        const p_dir = new THREE.Vector3(p_b.x - p_a.x, p_b.y - p_a.y, p_b.z - p_a.z);

        raycaster.set(p_a, p_dir, 0, p_dir.length());

        intersects = raycaster.intersectObject(mesh);
        if (intersects.length > 0) {
          const temp_dist = intersects[intersects.length > 1 ? 1 : 0].distance;
          if (temp_dist < thickness) {
            const inters_face = intersects[0].faceIndex;
            if ((1 + no.dot(object.geometry.faces[inters_face].normal)) <= 0.013) {
              thinWallsFaces.push(i);
              thinWallsFaces.push(inters_face);
              thinWallsCounter += 1;
            }
          }
        }
      }
    }
    dfmFeedback.thinWalls = thinWallsCounter > 0;
  }

  this.actionThinWallsView = function () {
    thinWallsView();
  };

  function thinWallsView() {
    // thin walls visibility switch
    if (modeThinWallsVisible) {
      const { faces } = object.geometry;
      const faces_num = faces.length;

      for (let f = 0; f < faces_num; f++) {
        faces[f].color.setHex(0xffffff);
      }
      object.geometry.colorsNeedUpdate = true;
      modeThinWallsVisible = false;
    } else {
      const { faces } = object.geometry;
      const faces_num = faces.length;

      for (let f = 0; f < faces_num; f++) {
        if (thinWallsFaces.includes(f)) {
          faces[f].color.setHex(0xff0000);
        } else {
          faces[f].color.setHex(0x00ff00);
        }
      }
      object.geometry.colorsNeedUpdate = true;
      modeThinWallsVisible = true;
    }
  }

  this.getDFMfeedback = function (customMaterial) {
    this.actionAdjoiningEdges();
    this.actionCreateSharpCorners();
    this.actionCreateUndercuts();
    this.actionCreateThinWalls(customMaterial);
    return dfmFeedback;
  };

  // ========================= DFM ===============================

  this.getBoundingBox = function () {
    return {
      x: objectBounds.x.toFixed(2),
      y: objectBounds.y.toFixed(2),
      z: objectBounds.z.toFixed(2),
    };
  };

  this.getFeatures = function () {
    return jsonData.features;
  };

  this.getTolerances = function () {
    return jsonData.features.tolerances;
  };

  this.getFeatureHoles = function () {
    return this.getFeatures().holes;
  };

  this.getFeatureBosses = function () {
    return this.getFeatures().bosses;
  };

  this.getThreadInputs = function () {
    return jsonData && jsonData.features && jsonData.features.thread_inputs;
  };

  // event triggers
  this.onReady = function (executable) {
    onReadyFunction = executable;
  };

  function fireReady() {
    onReadyFunction();
  }

  this.onStateToleranceMode = function (executable) {
    onStateToleranceModeFunction = executable;
  };

  this.onStateNewTolerance = function (executable) {
    onStateNewToleranceFunction = executable;
  };

  this.onStateSaved = function (executable) {
    onStateSavedFunction = executable;
  };

  this.actionAutoSizeRender = function () {
    autoSizeRenderer();
  };

  this.actionClearTolerances = function () {
    jsonData.features.tolerances.splice(0, jsonData.features.tolerances.length);
    drawAllTolerances();
  };

  function autoSizeRenderer() {
    camera.aspect = renderElement.offsetWidth / renderElement.offsetHeight;
    camera.left = renderElement.offsetWidth / -2;
    camera.right = renderElement.offsetWidth / 2;
    camera.top = renderElement.offsetHeight / 2;
    camera.bottom = renderElement.offsetHeight / -2;
    camera.updateProjectionMatrix();
    ThreeRenderer.setSize(renderElement.offsetWidth, renderElement.offsetHeight);
  }
}
