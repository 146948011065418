import { render, staticRenderFns } from "./configurator-element-select.vue?vue&type=template&id=36eacc30&scoped=true"
import script from "./configurator-element-select.vue?vue&type=script&lang=js"
export * from "./configurator-element-select.vue?vue&type=script&lang=js"
import style0 from "./configurator-element-select.vue?vue&type=style&index=0&id=36eacc30&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36eacc30",
  null
  
)

export default component.exports