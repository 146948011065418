<template>
  <aside class="inspection-modal-wrapper tw-bg-white tw-m-auto tw-p-5 tw-rounded-xs xl:tw-w-min">
    <header class="tw-flex tw-items-center tw-justify-between tw-mb-4">
      <h1 class="tw-text-xl tw-font-bold tw-grow">
        Inspection and certificates
      </h1>
      <button
        class="twdelete tw-text-black tw-text-xxl"
        @click="closeModal"
      >&times</button>
    </header>

    <section>
<!-- Inspections Start-->
      <div class="tw-mb-3">
        <h2 class="tw-mb-2 tw-font-bold tw-text-md">
          Select inspection type
        </h2>


        <div v-if="Object.keys(configurations).length > 1 && !isSameInspection" class="msgbody tw-text-md tw-flex tw-items-center tw-bg-tertiary tw-bg-opacity-20 tw-py-2 tw-px-2 tw-mb-2">
          <font-awesome-icon icon="info-circle" class="tw-text-tertiary" />
          <span class="tw-pl-2">The parts you have selected have <b>mixed</b> inspection types. Selecting a new type will replace the existing ones.</span>
        </div>

        <div class="relative tw-grid tw-grid-cols-2 tw-gap-2 xl:tw-space-x-3 xl:tw-flex xl:tw-justify-start">
          <inspection-type-card
            v-for="(item, i) in inspections" :key="item.id"
            :item="item"
            :is-disabled="isLocked(item)"
            :is-tag-inverted="i === 0"
            :is-selected="selectedInspectionId === item.id"
            :is-details-open="openedDetails === item.id"
            @selectCard="selectedInspectionId = item.id"
            @toggleDetails="handleDetailToggled"
          />
        </div>
      </div>

      <div v-if="openedDetails"
            class="tw-text-md tw-bg-grey-lighter tw-p-5 tw-my-4">
          <h2 class="tw-font-bold tw-pb-2">What’s included in {{ this.inspections.find(item => item.id === this.selectedInspectionId).string_value }}</h2>
          <div class="markdown-html" v-html="$options.markdownConverter.makeHtml(selectedDetails)" />
      </div>

      <!-- Add-ons Start -->
      <div>
        <h2 class="tw-mb-2 tw-text-md">
          <span class="tw-font-bold">Include add-ons </span><span class="tw-italic">(optional)</span>
        </h2>

        <div v-if="Object.keys(configurations).length > 1 && !isSameCertificates" class="msgbody tw-text-md tw-flex tw-items-center tw-bg-tertiary tw-bg-opacity-20 tw-py-2 tw-px-2 tw-mb-2">
          <font-awesome-icon icon="info-circle" class="tw-text-tertiary" />
          <span class="tw-pl-2">The parts you have selected have <b>mixed</b> add-ons. Selecting new add-ons will replace the existing ones.</span>
        </div>

        <div class="tw-grid tw-grid-flow-row tw-grid-cols-1 tw-gap-y-2 xl:tw-grid-cols-3 xl:tw-gap-4">
          <div
            v-for="certificate in certificates"
            :key="certificate.id"
            :class="['tw-flex tw-p-3 tw-px-3 tw-text-primary tw-border tw-rounded tw-text-md tw-rounded-xs tw-cursor-pointer',
            selectedCertificatesIds.includes(certificate.id) ? 'tw-border-tertiary' : 'tw-border-grey-light ']"
            @click="handleCertificateClick(certificate)">
            <label class="b-checkbox checkbox"
          >
              <input
                disabled
                type="checkbox"
                v-model="selectedCertificatesIds"
                :value="certificate.id"
                class="checkbox"
              />
              <span class="check"></span>
              <span class="control-label"></span>
            </label>

            <span class="tw-flex tw-items-center tw-min-w-max tw-font-bold">{{ certificate.string_value }}</span>
            <span
              v-if="! certificate.entity_slug.includes('iso-')"
              class="tw-flex tw-items-center tw-min-w-max tw-pl-2"
            >
              {{ costText(certificate.quote_addon_label_price) }}
            </span>
          </div>
        </div>
      </div>
      <!-- Add-ons End -->
    </section>

    <footer class="tw-pt-5 tw-flex tw-justify-between">
      <GmButton
        outlined
        type="info"
        class="tw-font-bold"
        @click="closeModal"
      >
        Cancel
      </GmButton>
      <GmButton
        type="g-primary"
        class="tw-font-bold"
        @click="handleConfirm"
      >
        Confirm Inspection
      </GmButton>
    </footer>
  </aside>

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  ADDONS,
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_DATA_PDF_REQUIRED,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import InspectionTypeCard from '@/app-buyer/components/InspectionTypeCard';
import GTag from '@common/components/storied/atoms/GTag.vue';
import showdown from 'showdown';
import GmButton from '@/shared/components/gm-button/gm-button.vue';
import PartErrorMessage from '@/app-buyer/components/project/PartErrorMessage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'InspectionModal',
  components: { PartErrorMessage, GmButton, InspectionTypeCard, GTag, FontAwesomeIcon },
  markdownConverter: new showdown.Converter(),
  props: {
    leadHash: {
      type: String,
      required: true,
    },
    leadConfig: {
      type: Object,
      default: () => ({}),
    },
    inspections: {
      type: Array,
      default: () => [],
    },
    selectedInspection: {
      type: Object,
      default: () => ({}),
    },
    certificates: {
      type: Array,
      default: () => [],
    },
    selectedCertificatesInitialIds: {
      type: Array,
      default: () => [],
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    isSameInspection: {
      type: Boolean,
      default: false,
    },
    isSameCertificates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalLockedConfig: JSON.parse(JSON.stringify(this.lockedConfigurations?.[this.leadHash] ?? {})),
      openedDetails: null,
      selectedCertificatesIds: [],
      selectedInspectionId: this.selectedInspection.id || 375,
    };
  },
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
      REFERENCE_DATA_OBJECT_BY_SLUG,
      REFERENCE_DATA_PDF_REQUIRED,
      ADDONS,
    }),
    selectedDetails() {
      return this.inspections.find(item => item.id === this.selectedInspectionId)?.metadata?.description;
    },
  },
  methods: {
    costText(price) {
      if (!price) return 'FREE';
      if (price === 1) return 'Variable';
      if (price < 15000) return '£';
      if (price < 25000) return '££';
      if (price < 100000) return '£££';
      return '££££';
    },
    handleConfirm() {
      // Modal becomes unresponsive if I don't emit all these updates next tick after closing.
      this.$nextTick(() => {
        this.$emit('start-update', this.selectedInspectionId, { entitySlug: 'inspection' }, true);

        this.selectedCertificatesInitialIds
          .filter((id) => !this.selectedCertificatesIds.includes(id))
          .forEach((id) => {
            const entitySlug = this.certificates.find((e) => e.id === id).entity_slug;
            this.$emit('start-update', null, { entitySlug, values: [null] }, true);
          });

        this.selectedCertificatesIds.forEach((id) => {
          const entitySlug = this.certificates.find((e) => e.id === id).entity_slug;
          const values = Array.from(new Set(
            Object.values(this.configurations)
              .map((v) => v[entitySlug])
              .filter(Boolean),
          ));
          this.$emit('start-update', id, { entitySlug, values }, true);
        });

        this.closeModal();
      });
    },
    handleDetailToggled(itemId) {
      if (this.openedDetails === itemId) {
        this.openedDetails = null;
      } else {
        this.openedDetails = itemId;
      }
    },
    ppapPackageCheck(status) {
      const currentLockedBy =  this.modalLockedConfig?.inspection?.lockedBy || [];

      if (status === 'add') {
        this.modalLockedConfig = {
          inspection: {
            lockedBy: [...currentLockedBy, 'ppap-package'],
            properties: this.lockedConfigurations?.[this.leadHash]?.inspection?.lockedBy.includes('tolerance') ? [375] : true,
          },
        };
      } else{
        this.modalLockedConfig.inspection.lockedBy = currentLockedBy.filter(item => item !== 'ppap-package');
        this.modalLockedConfig.inspection.properties = this.lockedConfigurations?.[this.leadHash]?.inspection?.lockedBy.includes('tolerance') ? [375] : undefined;
      }

 },
    handleCertificateClick({id, entity_slug}) {
      if (this.selectedCertificatesIds.includes(id)) {
        this.selectedCertificatesIds = this.selectedCertificatesIds.filter((e) => e !== id);

        if (entity_slug === 'ppap-package') {
          this.ppapPackageCheck('remove');
          this.selectedInspectionId = this.modalLockedConfig?.inspection?.lockedBy.includes('tolerance') ? 376 : 375;
        }
      } else {
        this.selectedCertificatesIds.push(id);
        if (entity_slug === 'ppap-package') {
          this.selectedInspectionId = 379;
          this.ppapPackageCheck('add');
        }
      }
    },
    isLocked({ id }) {
      return this.modalLockedConfig?.inspection?.lockedBy?.includes('ppap-package')
        || (
          Array.isArray(this.modalLockedConfig?.inspection?.properties)
            ? this.modalLockedConfig?.inspection?.properties.includes(id)
            : false
        );
    },
    closeModal() {
      this.$emit('close');
    },
  },
  mounted() {
    this.selectedCertificatesIds = [...this.selectedCertificatesInitialIds];
  },
}
</script>



<style
  scoped
  lang="scss"
>
.inspection-modal-wrapper {
  width: 80vw;
}

.markdown-html {
  ul {
    list-style: disc;
    padding-inline-start: 40px;
  }
}
.msgbody {
  border-color: #338dbc;
  border-radius: 5px;
  border-style: solid;
  border-width: 0 0 0 4px;
}
</style>
