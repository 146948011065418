import {
  DANGER, DRAFT_RFQ_SUPPORTING_FILE, ERROR, PDF, RFQ_SUPPORTING_FILE, STEP,
  STP,
} from '@/shared/consts/slugs';
import {
  REFERENCE_DATA_NOTE_REQUIRED, REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_PDF_REQUIRED, REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import PartSupporting from '@/app-buyer/components/project/PartSupporting.vue';
import PartFileUpload from '@/app-buyer/components/project/PartFileUpload.vue';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import { getParserErrorMessage } from '@/app-buyer/mixins/check-uploads';
import ProductionRequirementsButton
from '@/app-buyer/components/project/ProductionRequirementsButton.vue';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { RFQ_MODULE, REVISE_REQUOTE_CONFIG } from '@/app-buyer/store/modules/rfq/types';
import { G_SERVE_MODULE, DATA } from '@/app-buyer/store/modules/g-serve/types';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';
import ThicknessMaterialModal
  from '@/app-buyer/components/project/ThicknessMaterialModal.vue';

export const hasBeenXMins = (createdAt, minutes) => {
  let createdAtTimeStamp = createdAt;
  let timeoutMinutes = minutes;
  if (typeof createdAt === 'number') createdAtTimeStamp *= 1000;
  const XMins = timeoutMinutes * 60 * 1000; // X minutes in milliseconds
  return (new Date() - new Date(createdAtTimeStamp)) > XMins;
};

export const hasBeen15Mins = (createdAt) => {
  let createdAtTimeStamp = createdAt;
  return hasBeenXMins(createdAtTimeStamp, 15);
};

const getModelFileIssues = (draft) => {
  const modelFile = findDraftModelFile(draft);
  if (
    modelFile?.parser_metadata?.failed_at ||
    (!modelFile && !draft.uploadPercent && !draft.updatingFile && !draft.awaitingDispatch) ||
    (modelFile && modelFile?.extension?.toLowerCase() !== PDF && (!modelFile?.parser_metadata || !modelFile?.parser_metadata?.completed_at) && hasBeen15Mins(modelFile?.created_at))
  ) {
    return {
      ...getParserErrorMessage(modelFile?.parser_metadata?.status),
      type: ERROR,
      component: PartFileUpload,
    };
  }

  return false;
};

const getNoteIssues = (draft, requiring) => {
  if (!requiring?.length) return false;
  const customNotes = localStorage.getItem(`notes_${draft.hash}`);
  const hasNotes = !!draft?.notes || customNotes;
  if (!hasNotes && requiring.length) {
    return {
      messageTitle: 'Notes are required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(', ')} you need to add notes to clarify your requirements`,
      type: ERROR,
    };
  }
  return false;
};

const getSupportingFileIssues = ({ rootState, draft, requiring }) => {
  if (!requiring?.length || draft.configuration_object.service.slug === '3d-printing') return false;
  const modelFile = findDraftModelFile(draft);
  if (!modelFile) return false;
  const supportingFiles = draft?.uploads?.filter(
    (u) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(u?.type?.slug),
  );
  const inspection = draft?.configuration_object?.inspection && draft?.configuration_object?.inspection?.slug !== 'standard-inspection';
  const pdfSupportingFileExists = supportingFiles?.find((f) => f.extension.toLowerCase() === PDF);

  // if buyer selects any inspection for a part, they must upload a PDF format supporting file
  if (modelFile.extension.toLowerCase() !== PDF && inspection && !pdfSupportingFileExists) {
    return {
      messageTitle: 'A PDF supporting file is required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(' & ')} you need to add a PDF supporting file to clarify your requirements.`,
      btnText: 'Add PDF supporting file',
      btnType: ERROR,
      type: ERROR,
      service: draft.configuration_object?.service?.name,
      showEditor: false,
      component: PartSupporting,
    };
  }
  if (modelFile.extension.toLowerCase() !== PDF && !supportingFiles?.length && rootState[G_SERVE_MODULE]?.[DATA]?.[draft.hash] && requiring.length) {
    return {
      messageTitle: 'A Supporting file is required',
      message: `When selecting ${requiring.map((p) => `${p.string_value}`).join(' & ')} you need to add a supporting file to clarify your requirements. Please remove your 3D Configuration and replace it with a supporting file.`,
      btnText: 'Add supporting file',
      btnType: ERROR,
      type: ERROR,
      service: draft.configuration_object?.service?.name,
      showEditor: false,
      component: PartSupporting,
    };
  }
  if (modelFile.extension.toLowerCase() !== PDF && !supportingFiles?.length && requiring.length) {
    return {
      messageTitle: 'A Supporting file is required',
      message: `When selecting ${requiring.map((p) => `${p.string_value.replace('Has', '')}`).join(' & ')} you need to add a supporting file to clarify your requirements.`,
      btnText: 'Add supporting file',
      btnType: ERROR,
      type: ERROR,
      service: draft.configuration_object?.service?.name,
      showEditor: false,
      component: PartSupporting,
    };
  }

  return false;
};

const getMaterialIssue = ({ rootGetters, draft }) => {
  if (draft.configuration_object.service?.slug !== 'sheet-metal' || !rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`]) return false;

  const thickness = rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`][draft?.configuration_object?.thickness?.id];

  if (!thickness || thickness?.parent_ids?.includes(draft.configuration_object?.material?.id) || thickness?.entity_slug === 'other-thickness') return false;

  return {
    messageTitle: `${draft.configuration_object?.material?.string_value} is unavailable for selected part thickness`,
    message: `Please select a different material, or see our available thicknesses and materials chart`,
    btnText: 'here',
    btnType: 'pending',
    errorType: 'material-error',
    type: 'pending',
    iconLeft: 'exclamation-circle',
    iconColor: 'pending',
    inlineComponent: ThicknessMaterialModal,
  };
};

const getProductionRequirementIssues = (draft) => {
  if (draft.configuration_object.service?.slug !== 'injection-moulding') {
    return false;
  }

  if (new Date(draft?.production_requirements?.t1_sample_deliver_by) < new Date()) {
    return {
      messageTitle: 'Please update your T1 Sample Date',
      message: 'Your T1 Sample Date is out of date, please update it to request a quote.',
      btnText: 'Update T1 sample date',
      component: ProductionRequirementsButton,
      type: ERROR,
    };
  }

  const required = [
    't1_sample_deliver_by',
    'development_stage',
  ];

  if (required.every((k) => draft.production_requirements?.[k])) {
    return false;
  }

  return {
    messageTitle: `You're almost there! Please add your production requirements`,
    message: `Add your production requirements to this part to request a quote`,
    btnText: 'Add production requirements',
    component: ProductionRequirementsButton,
    type: 'grey-lighter',
    hasLeftBorder: true,
    borderColor: 'tertiary',
    iconLeft: 'exclamation-circle',
    iconColor: 'tertiary',
  };
};

const getIsParsing = (draft) => {
  const modelFile = findDraftModelFile(draft);

  if (!modelFile) return false;
  if (MANUAL_QUOTE_EXTENSIONS.includes(modelFile?.extension.toLowerCase())) return false;
  if (modelFile?.parser_metadata?.completed_at) return false;
  if (modelFile?.parser_metadata?.failed_at) return false;

  return {
    isParsing: true,
    type: 'parsing',
  };
};

const getDraftIssues = (draft, rootGetters, rootState) => {
  const modelFile = findDraftModelFile(draft);
  const fullProperties = Object.values(draft.configuration).map((propertyId) => {
    if (!rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`]) return {};
    return rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_OBJECT_BY_ID}`][propertyId];
  }).filter(Boolean);
  const propertiesRequiringPdf = rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_PDF_REQUIRED}`];
  const propertiesRequiringNotes = rootGetters[`${REFERENCE_MODULE}/${REFERENCE_DATA_NOTE_REQUIRED}`];

  const hasInspections = draft.configuration_object?.inspection && draft.configuration_object?.inspection?.slug !== 'standard-inspection';
  const hasBendingNotAutoDetect = draft.configuration_object?.bending?.slug === 'has-bending' && !draft.configuration?.['auto-detects'].bending;
  const hasThreadingNotAutoDetect = draft.configuration_object?.['hole-threading']?.slug === 'has-hole-threading' && !draft.configuration?.['auto-detects']?.['hole-threading'] && !rootState[G_SERVE_MODULE]?.[DATA]?.[draft.hash]
  const hasCountersinkingNotAutoDetect = draft.configuration_object?.['hole-countersinking']?.slug === 'has-countersunk-holes' && !draft.configuration?.['auto-detects']?.['hole-countersinking'];
  let requiresSupportingFile = false;

  // Has Revise & Requote has attached a new file?
  const reviseRequoteConfigFiles = rootState[RFQ_MODULE][REVISE_REQUOTE_CONFIG]?.find((c) => c.hash === draft.hash)?.files;

  if ((propertiesRequiringPdf || hasInspections || hasBendingNotAutoDetect || hasThreadingNotAutoDetect || hasCountersinkingNotAutoDetect) && !reviseRequoteConfigFiles?.hasOwnProperty('supportingFiles')) {
    let requiring = [];
    if (propertiesRequiringPdf) requiring = fullProperties.filter((p) => propertiesRequiringPdf[p.slug]);
    if (hasInspections) requiring = [...requiring, draft.configuration_object.inspection];
    if (hasBendingNotAutoDetect && ![STP, STEP].includes(modelFile?.extension)) requiring = [...requiring, draft.configuration_object?.bending];
    if (hasThreadingNotAutoDetect) requiring = [...requiring, draft.configuration_object?.['hole-threading']];
    if (hasCountersinkingNotAutoDetect) requiring = [...requiring, draft.configuration_object?.['hole-countersinking']];

    requiresSupportingFile = getSupportingFileIssues({ rootState, draft, requiring });
  }

  let requiresNotes = false;
  if (propertiesRequiringNotes) {
    requiresNotes = getNoteIssues(
      draft,
      fullProperties.filter((p) => propertiesRequiringNotes[p.slug]),
    );
  }

  const requiresMaterial = getMaterialIssue({ rootGetters, draft });
  const requiresModelFile = getModelFileIssues(draft);
  const requiresProdRequirements = getProductionRequirementIssues(draft);
  const isParsing = getIsParsing(draft);

  if (
    requiresSupportingFile
    || requiresNotes
    || requiresMaterial
    || requiresModelFile
    || requiresProdRequirements
    || isParsing
  ) {
    return [
      requiresSupportingFile,
      requiresNotes,
      requiresMaterial,
      requiresModelFile,
      requiresProdRequirements,
      isParsing,
    ].filter((error) => error);
  }

  return false;
};

export default getDraftIssues;
