<template>
  <nav
    v-click-outside="() => menuOpen = false"
    aria-label="main navigation"
    class="navbar tw-bg-primary tw-relative tw-z-40"
    role="navigation"
  >
    <div class="navbar-brand tw-mr-1">
      <a
        :class="{'is-active' : menuOpen}"
        aria-expanded="false"
        aria-label="menu"
        class="navbar-burger burger"
        role="button"
        @click="menuOpen = !menuOpen"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
      <span class="tw-flex tw-flex-1 tw-items-center tw-justify-center">
        <span class="tw-flex tw-flex-1 tw-items-center">
          <icon-font
            icon="geomiq-text"
            style="font-size: 1rem; color: white;"
          />
        </span>
        <span
          v-if="loggedIn"
          class="tw-flex tw-items-center tw-flex-grow-0 tw-mr-2"
        >
          <navbar-notifications class="tw-mr-1" />

          <navbar-cart
            :metadata-cart-count="metadata?.['cart_count']"
            :metadata-cart-total="metadata?.['cart_total']"
          />
        </span>
      </span>
    </div>

    <div
      :class="{'is-active' : menuOpen}"
      class="navbar-menu"
    >
      <div
        v-if="loggedIn"
        class="navbar-item has-dropdown tw-cursor-pointer"
        data-testid="menu-dropdown"
      >
        <a class="navbar-link">
          <font-awesome-icon
            v-if="MASQUERADING"
            icon="user-secret"
            class="tw-text-lg"
          />
          <font-awesome-icon
            v-else
            icon="user-circle"
            class="tw-text-lg"
          />
          <p class="tw-ml-2">{{ username || '' }}</p>
        </a>
        <div
          class="navbar-dropdown"
          data-testid="navbar-dropdown"
          @click="handleCloseMenu"
        >
          <b-navbar-item
            v-if="isSubMenuOpen || isProjectSubMenuOpen"
            class="no-close-menu no-box-shadow"
            @click="isSubMenuOpen = false; isProjectSubMenuOpen = false"
          >
            <div class="tw-pointer-events-none">
              <font-awesome-icon
                icon="chevron-left"
                class="tw-text-pending tw-mr-1"
              />
              Go back
            </div>
          </b-navbar-item>
          <div v-if="isProjectSubMenuOpen" class="tw-mt-2">
            <p class="tw-text-lg tw-font-bold tw-text-left">Project List</p>
            <div class="tw-border-b tw-border-grey-darkest tw-w-full tw-mt-2 tw-mb-1"></div>
          </div>
          <b-navbar-item
            v-if="!isSubMenuOpen && !isProjectSubMenuOpen"
            class="no-close-menu no-box-shadow tw-flex tw-justify-between tw-items-center"
            @click="handleOpenProjectSubMenu"
          >
            <div class="tw-pointer-events-none">
              Quotes
            </div>
            <font-awesome-icon
              icon="chevron-right"
              class="tw-text-pending tw-pointer-events-none"
            />
          </b-navbar-item>
          <div
            v-if="isProjectSubMenuOpen && !PAGINATION_PAGES?.includes(1) && PROJECTS?.length"
            class="tw-mb-2 no-close-menu"
          >
            <g-button
              :isLoading="isLoadingMoreProjects"
              isFullWidth
              isInverted
              color="white"
              label="Load previous"
              class="no-close-menu"
              @click="handleChangePage(false)"
            />
          </div>
          <component
            :is="item.tag || 'a'"
            v-for="item in activeMenuItems"
            :key="item.name"
            :data-testid="`${item.name}-nav-dropdown-link`"
            :href="item.link"
            :target="(!item.tag || item.tag === 'a') && '_blank'"
            :to="item.tag === 'router-link' && item.link"
            class="navbar-item no-box-shadow"
          >
            <font-awesome-icon
              v-if="item.icon"
              :icon="item.icon"
              :class="item.hasCount && metadata?.[item.hasCount] ? 'tw-text-white' : 'tw-text-grey-light'"
            />
            <div v-else>
              <p class="tw-text-md">{{ item.name }}</p>
              <p v-if="item.subText" class="tw-text-md tw-font-normal">{{ item.subText }}</p>
            </div>
          </component>

          <div
            v-if="!isSubMenuOpen && !isProjectSubMenuOpen"
            class="tw-border-b tw-border-grey-darkest tw-w-full tw-my-2"
          ></div>
          <b-navbar-item
            v-if="!isSubMenuOpen && !isProjectSubMenuOpen"
            class="no-close-menu no-box-shadow tw-flex tw-justify-between tw-items-center"
            @click="handleOpenSubMenu"
          >
            <div class="tw-pointer-events-none">
              <font-awesome-icon
                icon="user"
                class="tw-text-white tw-mr-1"
              />
              {{ username }}
            </div>
            <font-awesome-icon
              icon="chevron-right"
              class="tw-text-pending tw-pointer-events-none"
            />
          </b-navbar-item>

          <b-navbar-item
            v-if="isSubMenuOpen"
            class="no-box-shadow"
            @click="logOut"
          >
            {{ MASQUERADING ? 'Back to admin' : 'Logout' }}
            <b-icon
              v-show="MASQUERADING"
              icon="user-secret"
            />
          </b-navbar-item>

          <div
            v-if="isProjectSubMenuOpen && !PAGINATION_PAGES?.includes(PAGINATION?.last_page) && PROJECTS?.length"
            class="tw-mt-2 no-close-menu"
          >
            <g-button
              :isLoading="isLoadingMoreProjects"
              isFullWidth
              isInverted
              color="white"
              label="Load more"
              class="no-close-menu"
              @click="handleChangePage"
            />
          </div>

        </div>
      </div>

      <div
        v-if="!loggedIn"
        class="navbar-item"
      >
        <div class="buttons">
          <RouterLink
            class="gm-theme gm-button small link"
            data-testid="register-button"
            to="/register"
          >
            Sign up
          </RouterLink>
        </div>
      </div>
      <div
        v-if="!loggedIn"
        class="navbar-item"
      >
        <div class="buttons">
          <RouterLink
            class="gm-theme gm-button small link"
            data-testid="login-button"
            to="/login"
          >
            Log in
          </RouterLink>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import clickOutside from 'buefy/src/directives/clickOutside';
import {
  IS_DESKTOP,
  NAVIGATION_MODULE,
  RFQ_ENGINEER_VIEW,
} from '../../store/modules/navigation/types';
import { AUTH_MODULE, LOGGED_IN, LOGOUT, MASQUERADING } from '../../store/modules/auth/types';
import {
  GET_METADATA,
  GET_USER_NAME,
  GET_USER_ROLES,
  METADATA,
  USER_MODULE,
} from '../../store/modules/user/types';
import ProfileDataWidget from '../user-profile/profile-data-widget.vue';
import StepIndicator from '../step-indicator/step-indicator.vue';
import NavbarNotifications from './navbar-notifications.vue';
import {
  ACTIVE_PROJECT,
  PAGINATION_PAGES,
  PROJECT_MODULE,
  PROJECTS,
} from '../../store/modules/projects/types';
import getEnvironmentVariable from '../../../shared/misc/env-variable';
import { GET, PAGINATION } from '@/app-buyer/store/modules/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import NavbarCart from '@/app-buyer/components/navbar/NavbarCart.vue';

const navbarItems = {
  end: [
    {
      name: 'cart',
      link: '/cart',
      icon: 'shopping-cart',
      tag: 'router-link',
      hasCount: 'cart_count',
      requiresAuth: true,
    },
  ],
  topMenu: [
    {
      name: 'Orders',
      link: '/orders',
      tag: 'router-link',
    },
  ],
  subMenu: [
    {
      name: 'invoices',
      link: '/invoices',
      tag: 'router-link',
      requiresAuth: true,
    },
    {
      name: 'cart transfer',
      link: '/cart-transfer',
      tag: 'router-link',
      hasCount: 'cart_transfer_count',
      requiresAuth: true,
    },
    {
      name: 'resources',
      link: 'https://geomiq.com/knowledgebase/',
      tag: 'a',
    },
    {
      name: 'contact',
      link: 'https://geomiq.com/contact-us/',
      tag: 'a',
    },
    {
      name: 'profile',
      link: '/profile',
      tag: 'router-link',
    },
  ],
};

export default {
  name: 'NavbarMobile',

  components: {
    NavbarNotifications,
    StepIndicator,
    ProfileDataWidget,
    GButton,
    NavbarCart,
  },

  directives: {
    clickOutside,
  },

  data() {
    return {
      navbarItems,
      menuOpen: false,
      isSubMenuOpen: false,
      isProjectSubMenuOpen: false,
      isLoadingMoreProjects: false,
      noClose: false,
      apiUrl: getEnvironmentVariable('VUE_APP_VENDOR_URL'),
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      loggedIn: LOGGED_IN,
      MASQUERADING,
    }),
    ...mapState(PROJECT_MODULE, {
      PROJECTS,
      PAGINATION,
      PAGINATION_PAGES,
    }),
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    ...mapState(NAVIGATION_MODULE, {
      RFQ_ENGINEER_VIEW,
      IS_DESKTOP,
    }),
    ...mapState(USER_MODULE, {
      metadata: METADATA,
    }),
    ...mapGetters(USER_MODULE, {
      username: GET_USER_NAME,
      roles: GET_USER_ROLES,
    }),
    activeMenuItems() {
      if (this.isSubMenuOpen) return this.navbarItems['subMenu'];
      if (this.isProjectSubMenuOpen) return this.cleanProjectNavItems;

      return this.navbarItems['topMenu'];
    },

    cleanProjectNavItems() {
      let projectNavItems = [];

      if (!this[PROJECTS]?.length) return projectNavItems;

      this[PROJECTS].forEach((p) => {
        projectNavItems = [...projectNavItems, {
          name: p.name,
          subText: `${p.draft_rfqs?.length} part${p.draft_rfqs?.length > 1 ? 's' : ''}`,
          link: `/quotes/${p.hash}`,
          tag: 'router-link',
        }];
      });

      return projectNavItems;
    },
  },

  methods: {
    ...mapActions(AUTH_MODULE, {
      logOut: LOGOUT,
    }),

    ...mapActions(USER_MODULE, {
      getMetaData: GET_METADATA,
    }),

    ...mapActions(PROJECT_MODULE, {
      GET,
    }),

    handleOpenSubMenu() {
      this.noClose = true
      this.isSubMenuOpen = true;
    },

    handleOpenProjectSubMenu() {
      this.noClose = true
      this.isProjectSubMenuOpen = true;
    },

    handleCloseMenu(e) {
      if (this.noClose || [...e.srcElement?.classList]?.includes('no-close-menu')) {
        this.noClose = false;
        return;
      }

      this.menuOpen = false;
      this.isSubMenuOpen = false;
      this.isProjectSubMenuOpen = false;
      this.noClose = false;
    },

    async handleChangePage(nextPage = true) {
      this.isLoadingMoreProjects = true;

      const pageChangeTo = nextPage ? Number(Math.max(...this[PAGINATION_PAGES]) + 1) : Number(Math.min(...this[PAGINATION_PAGES]) - 1);

      await this.GET({ clear: false, page: pageChangeTo, toFront: !nextPage });
      this.isLoadingMoreProjects = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

.large-brand-holder {
  align-items: center;
  display: flex;
  margin: -0.75rem;
}

.navbar {
  .navbar-dropdown {
    max-height: 55vh;
    overflow: auto;

    @media (min-width: 1280px) {
      max-height: initial;
    }
  }
}
</style>
