<template>
  <div >
    <div
      v-if="!hasUploadingParts"
      v-for="part in UPLOADING_PARTS"
      :key="part.hash"
    >
      <PartElementPlaceholder />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  RFQ_MODULE,
  UPLOADING_PARTS,
} from '@/app-buyer/store/modules/rfq/types';
import PartElementPlaceholder from '@/app-buyer/components/placeholders/part-element-placeholder.vue';

export default {
  name: 'PartTableUploadingParts',
  components: { PartElementPlaceholder },
  computed: {
    ...mapGetters(RFQ_MODULE, {
      UPLOADING_PARTS,
    }),
    hasUploadingParts() {
      return this[UPLOADING_PARTS]?.length;
    },
  },
};
</script>
