import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import {
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS, GET_FIRST_SELECTED_PART,
  RFQ_MODULE, RFQS, SELECTED, SELECTED_PART_HASHES,
  SET_SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import { PROJECT_MODULE, ACTIVE_PROJECT_HASH } from '@/app-buyer/store/modules/projects/types';
import {
  CNC,
  CUSTOM_INSPECTION,
  DRAFT_RFQ_SUPPORTING_FILE,
  DWG,
  DXF,
  FORMAL_INSPECTION,
  HAS_BENDING,
  HAS_COUNTERSINK_HOLES,
  HAS_HOLE_THREADING,
  INJECTION_MOULDING,
  INSPECTION, PDF,
  PPAP_PACKAGE,
  RFQ_SUPPORTING_FILE,
  SHEET_METAL, STANDARD_INSPECTION,
  STEP,
  STP,
  TOLERANCE,
  CMM_INSPECTION,
  FIRST_ARTICLE_INSPECTION,
} from '@/shared/consts/slugs';
import {
  DATA,
  G_SERVE_MODULE,
  GET_SPECIFICATION,
} from '@/app-buyer/store/modules/g-serve/types';
import PDFView from '@/app-buyer/components/project/PDFView.vue';
import { SET } from '@/app-buyer/store/modules/types';
import {
  ADDONS,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_DATA_PDF_REQUIRED,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { INPUT_TYPES } from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-types';
import GViewer from '@/shared/g-serve/g-view';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { findModelFile } from '@/app-buyer/components/project/helpers';

/*
  Use this mixin when selecting part, the router takes care of the prop
  delegation to the project page
 */
export const partSelectMixin = {
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_SELECTED_PART_HASHES,
    }),

    selectParts(...partHashes) {
      // If the first part is the same as the current part, select the second part (because it means we unselected the first leading part)
      let newFirstHash;
      if (partHashes.length > 1 && partHashes[0] === this.part?.hash) newFirstHash = partHashes[1];
      else newFirstHash = partHashes[0];

      // the safest way to find the first part
      const newFirstPart = partHashes.reduce((found, current) => {
        if (found) return found;
        return this[DRAFT_RFQS].find((draft) => draft.hash === current)
          || this[RFQS].find((rfq) => rfq.hash === current);
      }, null);

      if (this.$route.name === 'quote-page' && this[ACTIVE_PROJECT_HASH] && this.$route.path !== `/quotes/${this[ACTIVE_PROJECT_HASH]}/${partHashes.join(',')}`)  {
        this.$router.push(`/quotes/${this[ACTIVE_PROJECT_HASH]}/${partHashes.join(',')}`)
      } else {
        this[SET_SELECTED_PART_HASHES](partHashes);
      }
    },
  },
  computed: {
    ...mapGetters(RFQ_MODULE, {
      GET_FIRST_SELECTED_PART,
      GET_ALL_SELECTED_PARTS
    }),
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      RFQS,
    }),
    ...mapState(PROJECT_MODULE, {
      ACTIVE_PROJECT_HASH,
    })
  },
};

const getEditableThreads = (bossOrHole) => bossOrHole.threads?.thread_size?.length;
const path = getEnvironmentVariable('VUE_APP_API_URL');

/*
  This mixin is used for every component that deals with the configurator and
  supporting file, can be moved to separate file
 */
export const part3DconfigMixin = {
  data() {
    return {
      removingSpecs: false,
    };
  },
  computed: {
    ...mapState(G_SERVE_MODULE, {
      DATA,
    }),
    supportingFiles() {
      return this.part?.uploads
        ?.filter((u) => [DRAFT_RFQ_SUPPORTING_FILE, RFQ_SUPPORTING_FILE].includes(u?.type?.slug));
    },
    hasSupportingFiles() {
      return !!this.supportingFiles?.length;
    },
    modelFile() {
      return findModelFile(this.part);
    },
    parserData() {
      return this.modelFile?.parser_metadata;
    },
    parser() {
      return this.parserData?.parser_slug;
    },
    uuid() {
      return this.parserData?.parser_uuid || this.parserData?.uuid;
    },
    configuration3D() {
      return this[DATA]?.[this.part?.hash];
    },
    hasConfiguration3D() {
      if (!this.configuration3D) return false;
      // eslint-disable-next-line camelcase
      const { threaded_holes, threadInputs, tolerances } = this.configuration3D;
      return !!threaded_holes?.length || !!threadInputs?.length || !!tolerances?.length;
    },
    holes() {
      return this.configuration3D?.holes;
    },
    bosses() {
      return this.configuration3D?.bosses;
    },
    tolerances() {
      return this.configuration3D?.tolerances;
    },
    editableHoles() {
      return this.holes?.filter(getEditableThreads);
    },
    editableBosses() {
      return this.bosses?.filter(getEditableThreads);
    },
    canUploadSupporting() {
      return !this.holes?.length && !this.bosses?.length && !this.tolerances?.length;
    },
    canBeConfigured3D() {
      const isCNC = this.part?.configuration_object?.service?.slug === CNC;
      const isStep = [STEP, STP].includes(this.modelFile?.extension?.toLowerCase());
      const isGServe = this.parser === 'g-serve';
      return isCNC && isStep && isGServe && this.part?.__draft;
    },
  },
  methods: {
    ...mapMutations(G_SERVE_MODULE, {
      SET,
    }),
    ...mapActions(G_SERVE_MODULE, {
      GET_SPECIFICATION,
    }),
    startRemoveSpecifications() {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to <b>remove</b> the configurations you added with the 3D Tool?',
        type: 'is-danger',
        onConfirm: () => this.removeSpecifications(),
        hasIcon: true,
        confirmText: 'Remove',
      });
    },
    async removeSpecifications() {
      if (!this.uuid) return;
      this.removingSpecs = true;
      const gView = new GViewer();
      gView.setServer(path);
      await gView.loadUID(this.uuid, false);
      this.$nextTick(() => {
        const tolerancesLength = this.tolerances.length;
        for (let i = 0; i < tolerancesLength; i++) {
          gView.actionRemoveToleranceByID(i, false);
        }
        const holesAndBosses = [...(this.editableBosses || []), ...(this.editableHoles || [])];
        holesAndBosses.forEach((e) => gView.actionRemoveThreadByID(e.ID, false));
      });
      await gView.actionSaveSpecification();
      const holes = gView.getFeatureHoles();
      const tolerances = gView.getTolerances();
      const bosses = gView.getFeatureBosses();
      const threadInputs = gView.getThreadInputs();
      this[SET]({
        model: this.part,
        holes,
        tolerances,
        bosses,
        threadInputs,
      });
      this.removingSpecs = false;
    },
  },
};

/*
  Mixin for adding a method to any component that accepts a pdf upload
  and opens a viewer

  pdf upload here means a BE upload object
 */
export const viewPDF = {
  methods: {
    viewPDF(pdf) {
      if (!pdf) return;
      this.$buefy.modal.open({
        component: PDFView,
        hasModalCard: true,
        width: '90vw',
        props: {
          pdf,
        },
      });
    },
  },
};

let lockChecked;

/*
  Massive mixin to help handle property updates even when multiple parts
  are selected with different configurations

  In most cases the decisions are based on the first selected part,
  however the user is always prompted before a change is finalised
 */
export const safeMultipleUpdate = {
  props: {
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    leadHash: {
      type: String,
      default: null,
    },
    def: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      confirmApplicable: null,
      excludedEntities: [
        'material-type-3',
        'material-type-2',
        'material-type',
        'secondary-finish-type',
        'add-on',
      ],
      wasManualChange: false,
    };
  },
  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
      REFERENCE_DATA_OBJECT_BY_SLUG,
      REFERENCE_DATA_PDF_REQUIRED,
      ADDONS,
    }),
    leadLockedConfig() {
      return this.lockedConfigurations?.[this.leadHash] || {};
    },
  },
  watch: {
    leadHash: {
      handler(val) {
        if (!val || lockChecked === val) return;
        lockChecked = val;
        this.doLockConfigChecks(this.configurations[val] || {});
      },
      immediate: true,
    },
  },
  methods: {
    onUpdateFinish() {},

    getDetailedProperty(id, entitySlug) {
      if (entitySlug === 'notes') {
        return { string_value: 'notes' };
      }
      // use object getter to make search n(1)
      return this[REFERENCE_DATA_OBJECT_BY_ID][id];
    },

    getAvailableParents(id, configuration) {
      // Get property from reference data to always have parents
      const property = this.getDetailedProperty(id);
      /*
       If a definition is provided from 'configurator-elements.js' we only need
       to check the required parents
      */
      const definedDependencies = this.def?.dependencies && (
        this.def.dependencies.get
          ? this.def.dependencies.get(configuration)
          : this.def.dependencies
      );
      /*
       Create an array of the dependencies and acceptable values,
       this needs to leave out "middle" entities that are used for visual separation
      */
      return property?.parents
        ?.filter((parent) => !this.excludedEntities.includes(parent.entity_slug))
        ?.filter((parent) => (
          definedDependencies
            ? definedDependencies.includes(parent.entity_slug)
            : true))
        ?.reduce((res, parent) => {
          const idx = res.findIndex((e) => e.entity_slug === parent.entity_slug);
          if (idx > -1) {
            res[idx].acceptable.push(parent.id);
          } else {
            res.push({ entity_slug: parent.entity_slug, acceptable: [parent.id] });
          }
          return res;
        }, []);
    },

    checkParents(id, configuration) {
      const parents = this.getAvailableParents(id, configuration);
      if (!parents) return false;
      /*
       After collecting all parents check if their acceptable values include
       the configuration's value
      */
      return parents
        .every((p) => p.acceptable?.includes(configuration[p?.entity_slug]));
    },

    startUpdate(value, { entitySlug, values } = {}, hasConfirmed) {
      // Null branch
      if (value === null) {
        if (entitySlug) {
          const nulled = Object.entries(this.configurations).reduce((res, [hash]) => ({
            ...res,
            [hash]: null,
          }), {});
          this.finaliseUpdate(nulled, entitySlug);
        } else {
          console.warn('To null out a value the entity slug needs to be provided in the update options.');
        }
        return;
      }
      // Check if each part has only the acceptable values and add only the ones that do
      const canApply = Object.entries(this.configurations).reduce((res, [hash, configuration]) => {
        const hasRightParents = this.def?.type === INPUT_TYPES.NUMBER
          || this.def?.type === INPUT_TYPES.MIN_MAX
          || entitySlug === 'notes'
          || this.checkParents(value, configuration);
        if (hasRightParents) {
          return {
            ...res,
            [hash]: value,
          };
        }
        return res;
      }, {});
      this.checkForPrompt(
        canApply,
        this.getDetailedProperty(value, entitySlug),
        { entitySlug, values },
        hasConfirmed,
      );
    },

    /*
      This function prompts the user that there were multiple configuration's
      present and we are going to override that, only do the action once the
      user confirmed
     */
    checkForPrompt(canApply, detailedProperty, { entitySlug, values }, hasConfirmed) {
      const applicableIds = Object.keys(canApply);
      const applicableCount = applicableIds.length;
      const partCount = Object.keys(this.configurations).length;

      if (!applicableCount) return;

      if (!hasConfirmed && ((values || this.values?.length) > 1 || partCount > applicableCount)
        && this.def?.type !== INPUT_TYPES.NUMBER) {
        this.confirmApplicable = {
          canApply,
          count: applicableCount,
          property: detailedProperty.string_value,
          ids: applicableIds,
        };
      } else {
        this.finaliseUpdate(canApply, entitySlug);
      }
    },

    finaliseUpdate(canApply, entitySlug = null) {
      this.confirmApplicable = null;
      this.$emit('update-config', entitySlug ? { entitySlug, canApply } : canApply);
      Object.entries(canApply)
        .forEach(([hash, value]) => this.checkEffects(hash, value, entitySlug));
      this.onUpdateFinish();
    },

    doLockConfigChecks(config) {
      Object.entries(config).forEach(([entitySlug, value]) => {
        this.checkEffects(this.leadHash, value, entitySlug);
      });
    },

    /*
      This function is called after a value is set for any property to check
      if any other follow up side effect is needed.

      Eg. for some tolerances the inspection type needs to be locked
     */
    checkEffects(hash, value, entitySlug = null) {
      const resolvedEntitySlug = entitySlug || this.def?.entity;
      switch (resolvedEntitySlug) {
        case TOLERANCE:
          this.checkToleranceEffect(hash, value);
          break;
        case PPAP_PACKAGE:
          this.checkPpapPackageEffect(hash, value);
          break;
        default:
          break;
      }

      this.wasManualChange = false;
    },

    /*
      This function takes care of handling the side effects in case multiple are
      present.

      Future todos:
      - prioritize the side effects so if they affect the same properties
      it can be decided which should take effect
      - create a separate file that would handle all the side effects to reduce
      file size
     */
    getUpdatedLockArray(hash, entitySlug, lockingEntitySlug, add) {
      const lockArray = this.lockedConfigurations?.[hash]?.[entitySlug]?.lockedBy || [];
      if (add) {
        return [...lockArray, lockingEntitySlug];
      }
      const filtered = lockArray.filter((e) => e !== lockingEntitySlug);
      return filtered.length ? filtered : false;
    },

    checkToleranceEffect(hash, value) {
      const slug = this[REFERENCE_DATA_OBJECT_BY_ID]?.[value]?.slug;
      const formalInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[FORMAL_INSPECTION];
      const customInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[CUSTOM_INSPECTION];
      const firstArticleInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[FIRST_ARTICLE_INSPECTION];
      const cmmInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[CMM_INSPECTION];
      const standardInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION];
      const doesToleranceRequirePdf = this[REFERENCE_DATA_OBJECT_BY_ID]?.[value]?.metadata?.requires_pdf;

      // if the user has customInspection || firstArticleInspection || cmmInspection already selected
      // no need to add/remove any inspection
      if (
        [customInspection?.slug, firstArticleInspection?.slug, cmmInspection?.slug].includes(this[REFERENCE_DATA_OBJECT_BY_ID]?.[this.configurations?.[hash]?.inspection]?.slug) ||
        doesToleranceRequirePdf && [customInspection?.slug, firstArticleInspection?.slug, cmmInspection?.slug, formalInspection.slug].includes(this[REFERENCE_DATA_OBJECT_BY_ID]?.[this.configurations?.[hash]?.inspection]?.slug)
      ) {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, TOLERANCE, doesToleranceRequirePdf),
          properties: [this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION]?.id],
        });
        return;
      }

      if (!this[REFERENCE_DATA_PDF_REQUIRED]?.[slug] && [formalInspection?.slug].includes(this[REFERENCE_DATA_OBJECT_BY_ID]?.[this.configurations?.[hash]?.inspection]?.slug) && this.wasManualChange) {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          value: this.leadLockedConfig?.inspection?.lockedBy?.includes('ppap-package') ? customInspection.id : standardInspection.id,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, TOLERANCE, !!this.leadLockedConfig?.inspection?.lockedBy?.includes('ppap-package')),
          properties: [this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION]?.id]
        });
        this.$buefy.snackbar.open({
          message: '<div class="message-wrapper"><p class="message-title">Standard Inspection selected</p> <p class="message-content">As Formal Inspection is no longer required for your selected tolerance, Standard Inspection has been selected instead</p></div>',
          type: 'is-white',
          size: 'is-small',
          position: 'is-top-right',
          duration: 6000,
          pauseOnHover: true,
        });
      } else if (this[REFERENCE_DATA_PDF_REQUIRED]?.[slug] && ![formalInspection?.slug].includes(this[REFERENCE_DATA_OBJECT_BY_ID]?.[this.configurations?.[hash]?.inspection]?.slug)) {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          value: this.leadLockedConfig?.inspection?.lockedBy?.includes('ppap-package') ? customInspection.id : formalInspection.id,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, TOLERANCE, true),
          properties: [this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION]?.id]
        });
        this.$buefy.snackbar.open({
          message: '<div class="message-wrapper"><p class="message-title">Formal Inspection selected</p> <p class="message-content">As Standard Inspection is unavailable for your selected tolerance, Formal Inspection has been selected instead</p></div>',
          type: 'is-white',
          size: 'is-small',
          position: 'is-top-right',
          duration: 6000,
          pauseOnHover: true,
        });
      } else {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, TOLERANCE, false),
        });
      }
    },

    checkPpapPackageEffect(hash, value) {
      const slug = this[REFERENCE_DATA_OBJECT_BY_ID]?.[value]?.slug;
      const formalInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[FORMAL_INSPECTION];
      const customInspection = this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[CUSTOM_INSPECTION];
      if (slug === PPAP_PACKAGE && customInspection) {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, PPAP_PACKAGE, true),
          properties: this.leadLockedConfig?.inspection?.lockedBy?.includes('tolerance') ? [this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION]?.id] : true,
        });
      } else {
        this.$emit('effect', {
          hash,
          entitySlug: INSPECTION,
          value: this.leadLockedConfig?.inspection?.lockedBy?.includes('tolerance') ? formalInspection.id : customInspection.id,
          lockedBy: this.getUpdatedLockArray(hash, INSPECTION, PPAP_PACKAGE, false),
          properties: this.leadLockedConfig?.inspection?.lockedBy?.includes('tolerance') ? [this[REFERENCE_DATA_OBJECT_BY_SLUG]?.[STANDARD_INSPECTION]?.id] : undefined,
        });
      }
    },
  },
};

/*
  Mixin for deciding the the acceptable supporting files for a part
 */
export const getAcceptableUploads = {
  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    acceptableSupportingFiles() {
      const uploadExtension = findModelFile(this.part)?.extension?.toLowerCase();
      const configurationExtension = this[REFERENCE_DATA_OBJECT_BY_ID]?.[this.part?.configuration?.['file-type']]?.slug;
      const fileType = configurationExtension || uploadExtension;
      const service = this.part?.configuration_object?.service?.slug;
      const inspection = this.part?.configuration_object?.inspection && this.part?.configuration_object?.inspection?.slug !== 'standard-inspection';

      if (inspection) {
        return '.pdf';
      }

      if ([INJECTION_MOULDING, CNC].includes(service)) {
        return fileType === PDF ? '.step,.stp,.iges,.igs,.pdf,.dwg' : '.pdf,.dwg';
      }

      return '.pdf,.dwg,.dxf';
    },

    acceptableModelFiles() {
      const serviceId = this.part?.configuration?.service;
      const serviceObj = this[REFERENCE_DATA_OBJECT_BY_ID][serviceId];

      if (serviceObj.parents?.length) return serviceObj.parents.map((p) => `.${p.slug}`).toString();
      return '.iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl';
    },
  },
};
