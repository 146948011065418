<template functional>
  <div class="shiny-effect tw-w-full">
    <div
      v-for="n in props.range"
      :key="n"
      class="placeholder-row tw-flex tw-items-center tw-w-full tw-h-24"
    >
      <span
        class="shiny-effect-child tw-mx-1 tw-h-10"
        style="width: 3rem;"
      />
      <span
        class="shiny-effect-child tw-mx-1"
        style="width: 80px; height: 80px;"
      />
      <span class="shiny-effect-child tw-flex-grow tw-mx-1 tw-h-10" />
      <span class="shiny-effect-child tw-flex-grow tw-mx-1 tw-h-10" />
      <span class="shiny-effect-child tw-flex-grow tw-mx-1 tw-h-10" />
      <span class="shiny-effect-child tw-flex-grow tw-mx-1 tw-h-10" />
      <span
        class="shiny-effect-child tw-mx-1 tw-h-10"
        style="width: 10rem;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartElementPlaceholder',
  functional: true,
  props: {
    range: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style scoped>
.shiny-effect-child {
  border-radius: theme('borderRadius.xs');
}
</style>
