<template>
  <label :style="labelStyle">
    <template v-if="!dragDrop">
      <slot />
    </template>
    <div
      v-else
      class="drop-area tw-rounded-sm tw-h-full"
      :class="[{
        'is-disabled': disabled,
        'is-hovered': dragover
      }]"
      @dragenter.prevent="dragover = !loading && !disabled && true"
      @dragleave.prevent="dragover = !loading && !disabled && false"
      @dragover.prevent="dragover = !loading && !disabled && true"
      @drop.prevent="onFileChange"
    >
      <slot />
    </div>

    <input
      ref="input"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      type="file"
      v-bind="$attrs"
      style="display: none;"
      :data-testid="dataTestid"
      class="hidden-input"
      @change="onFileChange"
    >
  </label>
</template>

<script>
import checkFileType from '../../mixins/check-type';

export default {
  name: 'UploadInput',
  mixins: [checkFileType],
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    accept: {
      type: String,
      default: () => '',
    },
    dragDrop: {
      type: Boolean,
      default: () => false,
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [Object, Function, Array],
      default: () => null,
    },
    dataTestid: {
      type: String,
      default: () => null,
    },
    labelStyle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dragover: false,
    };
  },
  methods: {
    onFileChange(event) {
      const rejected = [];
      if (this.disabled || this.loading) return;
      if (this.dragDrop) {
        this.dragover = false;
      }
      const value = event.target.files || event.dataTransfer.files;
      if (value.length === 0) {
        if (!this.newValue) {
          return;
        }
        if (this.native) {
          this.newValue = null;
        }
      } else if (!this.multiple) {
        if (this.dragDrop && value.length !== 1) {
          return;
        }
        const file = value[0];
        if (this.checkType(file)) {
          this.newValue = file;
        } else if (this.newValue) {
          this.newValue = null;
        } else {
          return;
        }
      } else {
        let newValues = false;
        if (this.native || !this.newValue) {
          this.newValue = [];
          newValues = true;
        }
        for (let i = 0; i < value.length; i++) {
          const file = value[i];
          if (this.checkType(file)) {
            this.newValue.push(file);
            newValues = true;
          } else {
            rejected.push(file);
          }
        }
        if (rejected.length) {
          this.$emit('file-error', rejected);
        }
        if (!newValues) {
          return;
        }
      }
      this.$emit('input', this.newValue);
    },

    manualClick() {
      this.$refs.input.click();
    },
  },
};
</script>

<style lang="scss" scoped>
  .drop-area {
    cursor: pointer;
    padding: 0.25em;
    border: 1px dashed theme('colors.grey-darkest');

    &:hover,
    &.dragover {
      background-image: linear-gradient(rgba(#888,0.1), rgba(#888,0.1));
    }
  }
</style>
