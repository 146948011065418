<template>
  <g-message
    isFullWidth
    iconLeft="exclamation-circle"
    color="grey-lighter"
    borderColor="tertiary"
    iconColor="tertiary"
  >
    <div class="tw-flex tw-flex-col tw-justify-between tw-text-sm tw-w-full xl:tw-flex-row xl:tw-items-center">
      <div class="tw-flex tw-flex-col">
        <p class="tw-font-medium">
          Instant quote is unavailable for the detected part thickness
        </p>
        <p>Please select a different thickness.</p>
      </div>
      <g-button
        isInverted
        label="Select a different thickness"
        color="tertiary"
        size="sm"
        @click="handleClickOtherThickness"
      />
    </div>
  </g-message>
</template>

<script>
import GMessage from '@common/components/storied/molecules/GMessage.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { mapState } from 'vuex';
import {
  THICKNESS_COMPONENT_REF,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';

export default {
  name: 'PartRowOtherThickness',

  components: {
    GMessage,
    GButton,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(REFERENCE_MODULE, {
      THICKNESS_COMPONENT_REF,
    }),
  },

  methods: {
    handleClickOtherThickness() {
      this[THICKNESS_COMPONENT_REF]?.$parent?.handleOpenFromAfar();
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
