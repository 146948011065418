<template>
  <div
    class="tw-bg-white tw-max-w-full tw-relative tw-h-full tw-p-4 tw-shadow-none tw-border tw-border-grey-light tw-rounded-sm tw-text-md
           sm:tw-block md:tw-flex">
    <table class="table">
      <thead>
        <tr>
          <th colspan="2">
            {{ isProduction ? 'Additional' : 'Initial' }} quantity
          </th>
        </tr>
      </thead>
      <tbody v-if="quote !== null">
        <template v-for="data in quoteData">
          <QuoteRow
            :key="`${data.title}-top-row`"
            :data="data"
            :is-penny="data.isPenny"
          />
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td>
            Awaiting quotes
          </td>
        </tr>
      </tbody>
      <tfoot v-if="quote !== null">
        <tr>
          <hr>
        </tr>
        <QuoteRow
          :data="subtotalData"
          :is-penny="!isProduction"
        />

        <QuoteRow
          :data="deliveryCostData"
        />
        <tr>
          <td colspan="2" class="tw-pt-0 tw-text-sm">(Combined shipping discounts available in cart)</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>

import QuoteRow from '@/app-buyer/components/rfq-list/QuoteRow.vue';
import leadTimeCalculator from '@/app-buyer/mixins/lead-time-calculator';
import { INJECTION_MOULDING } from '@/shared/consts/slugs';

const entityTypeIds = {
  'part': 1,
  'certificate': 3,
  'delivery': 5,
  'tooling': 8,
  'addon': 9,
};

export default {
  name: 'QuoteDetails',
  components: { QuoteRow },
  mixins: [
    leadTimeCalculator,
  ],
  props: {
    rfq: {
      required: true,
      type: Object,
    },
    quote: {
      type: Object,
      default: () => null,
    },
    isProduction: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    quoteData() {
      const partPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'part' || e.type_id === entityTypeIds['part']);
      const toolingPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'tooling' || e.type_id === entityTypeIds['tooling']);
      const deliveryData = this.quote?.delivery;
      const addonsData = this.quote?.entries?.filter((e) => e.type?.slug === 'addon' || e.type_id === entityTypeIds['addon'])
      const certificateData = this.quote?.entries?.find((e) => e.type?.slug === 'certificate' || e.type_id === entityTypeIds['certificate']);
      const quantity = this.isProduction ? this.rfq.quantity_production : this.rfq.quantity_initial;

      const toolingCost = {
        title: 'Tooling cost',
        value: toolingPriceData?.unit_cost,
        applyFilter: true,
      };
      const certificateCost = {
        title: certificateData?.description || 'Certificate',
        value: certificateData?.unit_cost,
        applyFilter: true,
      };

      const addonsCosts = addonsData?.map((addon, i) => ({
        title: addon?.description || "Addon",
        value: addon?.unit_cost,
        applyFilter: true,
      })) || [];

      const result = [
        {
          title: this.isProduction ? 'Additional quantity' : 'Initial quantity',
          value: quantity,
        },
        {
          title: 'Unit cost',
          value: this.isProduction ? this.quote?.unit_cost : partPriceData?.unit_cost,
          applyFilter: true,
          isPenny: !this.isProduction,
        },
      ];

      if (this.rfq.configuration_object.service?.slug === INJECTION_MOULDING && !this.isProduction) { result.push(toolingCost) }
      if (addonsCosts) { result.push(...addonsCosts) }
      if (certificateCost.value) { result.push(certificateCost) }



      // no delivery in production
      if (!this.isProduction) {
        let requestedManufacturingTime =  this.rfq?.configuration_object['manufacturing-time']?.string_value;

        if (this.rfq.lead_time !== 0) {
          requestedManufacturingTime = this.leadTimeDate({ date: this.rfq.estimated_buyer_delivery_date })
        }

        const requested = {
          title: 'Delivery by',
          value: `${deliveryData?.requested_lead_time || requestedManufacturingTime}`,
        };

        if (! deliveryData?.proposed_lead_time) {
          result.push(requested);
          return result;
        }

        result.push({
          title: 'Shipping in (Requested)',
          value: deliveryData ? `${deliveryData.requested_lead_time} business days` : null
        });

        result.push({
          title: 'Shipping in (Proposed)',
          value: deliveryData ? `${deliveryData.proposed_lead_time} business days` : null,
        });
      }

      return result;
    },
    subtotalData() {
      const partPriceData = this.quote?.entries?.find((e) => e.type?.slug === 'part');
      return {
        title: 'Subtotal',
        value: partPriceData?.subtotal || this.quote?.subtotal,
        applyFilter: true,
        bold: true,
      };
    },
    deliveryCostData() {
      return {
        title: 'Shipping cost',
        value: this.quote?.shipping || this.quote?.entries?.find((e) => e.type_id === 5)?.shipping,
        applyFilter: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
