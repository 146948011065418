<template>
  <b-dropdown
    expanded
    multiple
    :value="values"
    :triggers="selectTriggers"
    v-bind="$attrs"
    ref="dropdown"
    class="gm-dropdown"
    @active-change="setListener"
  >
    <template v-slot:trigger="{ active }">
      <button
        ref="trigger-button"
        v-bind="$attrs"
        :entity="entity"
        class="button is-small tw-pl-2 tw-w-full is-result select-target"
      >
        <p
          :class="{ 'tw-opacity-50' : !selected }"
          class="subtitle select-target tw-m-0 tw-text-sm tw-flex tw-justify-between tw-items-center tw-w-full tw-overflow-hidden tw-overflow-ellipsis"
          data-testid="configurator-preselected"
        >
          <span>
            {{ (customNote && `${selected}: ${customNote}`) || selected || placeholder }}

            <tippy
              v-if="hasAutoDetectedThickness"
              :trigger="isThicknessLocked ? 'mouseenter focus' : 'manual'"
              tag="span"
              arrow
            >
              <template #trigger>
               <span
                 class="tw-ml-05 tw-p-1"
                 @click.stop="handleThicknessLock"
               >
                  <font-awesome-icon
                    :icon="isThicknessLocked ? 'lock' : 'lock-open'"
                    class="tw-text-grey-dark"
                  />
                </span>
              </template>
              <div id="buyer-app">
                <div class="tw-text-left tw-p-1 tw-text-sm">
                  <p>Unlock to change thickness</p>
                </div>
              </div>
            </tippy>
          </span>
          <font-awesome-icon
            :icon="active ? 'chevron-up' : 'chevron-down'"
            :class="{ 'tw-text-grey-light' : isThicknessLocked && hasAutoDetectedThickness }"
          />
        </p>
      </button>
    </template>
    <b-dropdown-item
      v-for="(property, index) in cleanProperties"
      :key="property.id"
      :value="property.id"
      :class="{'is-highlighted' : index === highlightedIndex}"
      :custom="property.metadata && property.metadata.requires_notes"
      :data-testid="`configurator-${formatString(property.string_value)}`"
      @click="updateWithNote(property.id)"
    >
      <b-field
        v-if="property.metadata && property.metadata.requires_notes"
        custom-class="tw-text-sm"
        :label="property.string_value"
        @click.stop
      >
        <b-input
          v-model="customNote"
          expanded
          size="is-small"
          :placeholder="`Please specify the ${property.entity_name}`"
          @keyup.native.stop
        />
        <b-button
          :disabled="!customNote"
          size="is-small"
          @click="updateWithNote(property.id, true)"
        >
          Save
        </b-button>
      </b-field>
      <p
        v-else
        class="tw-text-sm"
      >
        {{ property[valueType] }}
        <span
          v-if="isThickness && property.id === REFERENCE_DATA_OBJECT_BY_SLUG?.['other-thickness']?.id"
          class="tw-inline-flex tw-ml-2 tw-py-05 tw-px-1 tw-rounded-xs"
          :class="values.includes(REFERENCE_DATA_OBJECT_BY_SLUG?.['other-thickness']?.id) ? 'tw-bg-white tw-text-tertiary' : 'tw-bg-success-light tw-text-success'"
        >
          Detected thickness
        </span>
      </p>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { formInput } from '@/app-buyer/mixins/form-input';
import customNotes from '@/app-buyer/mixins/custom_notes';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
  SET_THICKNESS_COMPONENT_REF,
} from '@/app-buyer/store/modules/reference-data/types';

export default {
  name: 'ConfiguratorElementSelect',
  components: {
    FontAwesomeIcon,
  },
  mixins: [formInput, customNotes],
  props: {
    placeholder: {
      type: String,
      default: () => '',
    },
    entity: {
      type: String,
      default: () => null,
    },
    isThickness: {
      type: Boolean,
      default: false,
    },
    hasAutoDetectedThickness: {
      type: Boolean,
      default: false,
    },
    otherThickness: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      highlightedIndex: -1,
      isThicknessLocked: true,
      prioritySlugs: ['primary-as-machined-toolmarks-visible', 'primary-standard', 'primary-raw-sheet'],
    };
  },
  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),

    selected() {
      if (this.values.length > 1) {
        return `Multiple selected (${this.values.length})`;
      }
      const selectedProperty = this.cleanProperties?.find((e) => e.id === this.values[0]);
      return selectedProperty?.[this.valueType] || null;
    },

    selectTriggers() {
      return this.hasAutoDetectedThickness && this.isThicknessLocked ? [''] : ['click'];
    },

    cleanProperties() {
      if (this.isThickness && this.otherThickness) {
        return [
          {
            id: this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['other-thickness']?.id,
            string_value: `Other Thickness: ${this.otherThickness}mm`,
            valueType: `${this.otherThickness}mm`,
          },
          ...this.properties,
        ];
      }

      const priorityProperties = this.properties.filter((prop) => this.prioritySlugs.includes(prop.slug));
      const orderedProperties = this.properties.filter((prop) =>  !this.prioritySlugs.includes(prop.slug))
      .sort((a, b) => {
        if (a.entity_slug === 'thickness' || a.entity_slug === 'tolerance') return a.string_value - b.string_value;
        return a.string_value.localeCompare(b.string_value)
      });

      return [...priorityProperties, ...orderedProperties];
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isThickness) {
        this[SET_THICKNESS_COMPONENT_REF](this.$refs.dropdown);
      }
    })
  },
  watch: {
    leadValue: {
      handler(val) {
        if (val && this.entity && this._singleViewedModel && this._singleViewedModel.hash) {
          this.getNote(this.entity);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(REFERENCE_MODULE, {
      SET_THICKNESS_COMPONENT_REF,
    }),
    select(value) {
      this.searchInput = '';
      this.highlightedIndex = -1;
      this.$emit('input', value);
      this.$nextTick(() => {
        if (this.$refs.dropdown?.isActive) {
          this.$refs.dropdown.toggle();
        }
      });
    },
    setListener(activated) {
      if (activated) {
        setTimeout(() => {
          this.$el.addEventListener('keyup', this.navigate);
          this.$refs['trigger-button'].addEventListener('keydown', this.preventEnter);
        }, 500);
      } else {
        this.$nextTick(() => {
          this.$el.removeEventListener('keyup', this.navigate);
          this.$refs['trigger-button'].removeEventListener('keydown', this.preventEnter);
        });
      }
    },
    navigate(event) {
      if (event.key === 'ArrowDown' && this.highlightedIndex < this.properties.length) {
        event.stopPropagation();
        this.highlightedIndex++;
        this.scrollToHighlighted();
      } else if (event.key === 'ArrowUp' && this.highlightedIndex > 0) {
        event.stopPropagation();
        this.highlightedIndex--;
        this.scrollToHighlighted();
      } else if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
        event.stopPropagation();
        this.select(this.properties?.[this.highlightedIndex]?.id || null);
      }
    },
    scrollToHighlighted() {
      this.$nextTick(() => {
        const highlighted = this.$el.getElementsByClassName('is-highlighted')[0];
        if (highlighted) {
          highlighted.scrollIntoView({
            block: 'nearest',
          });
        }
      });
    },
    preventEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
    updateWithNote(id, withNote) {
      this.update(id);
      this.$emit('set-note', withNote ? this.customNote : null);
      if (this.$refs.dropdown?.isActive) {
        this.$refs.dropdown.toggle();
      }
    },
    formatString(str) {
      return str.replace(/\s+/g, '-').toLowerCase();
    },

    handleThicknessLock() {
      this.isThicknessLocked = !this.isThicknessLocked;
    },

    handleOpenFromAfar() {
      if (![...this.$refs.dropdown?.$el?.getElementsByClassName('dropdown-menu')]?.[0]?.style?.display) return;

      this.$refs['trigger-button'].focus();
      this.isThicknessLocked = false;
      this.$refs.dropdown.toggle();
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.is-highlighted {
  background-color: theme('colors.grey-lighter');
}

::v-deep {
  .dropdown-menu {
    background-color: theme('colors.white');

    .dropdown-content {
      box-shadow: none;
    }
  }
}
</style>
