import { MODEL } from '../store/modules/viewer/types';
import { findModelFile } from '@/app-buyer/components/project/helpers';

export const getParserErrorMessage = (status) => {
  switch (status) {
    case 'broken-file':
      return {
        messageTitle: `We’ve detected an issue with your part file`,
        message: `The file might be empty or incomplete. Please double-check that your CAD software exported the file correctly.`,
        btnText: 'Upload another file',
        isHardFail: true,
      };

    case 'multi-body':
      return {
        messageTitle: `We've detected multiple bodies in this part file`,
        message: `Please use your CAD software to merge all objects into a single body or export each object as its own file and upload them individually.`,
        btnText: 'Upload another file',
        isHardFail: true,
      };
    default:
      return {
        messageTitle: `Looks like your upload didn't go through smoothly`,
        message: `Please ensure you have a stable internet connection and do not leave the page while parts are being uploaded`,
        btnText: 'Upload file',
        isHardFail: true,
      };
  }
};

// eslint-disable-next-line import/prefer-default-export
export const checkUploads = {
  computed: {
    _part() {
      return this.model || this.rfq || this.draftRfq || this[MODEL];
    },
    _messageInParserUUIDGServe() {
      try {
        // TODO makes this less hacky
        return JSON.parse(
          findModelFile(this._part)
            .parser_metadata.parser_uuid,
        ).message;
      } catch {
        return false;
      }
    },
    _missingModelFile() {
      try {
        return !this._part.uploadPercent && !findModelFile(this._part) && 'Missing model file, please upload a new one!';
      } catch {
        return false;
      }
    },
    _parserIssue() {
      const parserMetadata = findModelFile(this._part)?.parser_metadata;
      const hasIssue = parserMetadata?.failed_at != null;
      return hasIssue && getParserErrorMessage(parserMetadata?.status);
    },
    _hasInvalidModelFile() {
      const hasAnyIssue = this._missingModelFile
        || this._messageInParserUUIDGServe
        || this._parserIssue;
      return !!hasAnyIssue && `There is an issue with this file: ${hasAnyIssue}`;
    },
  },
  watch: {
    _hasInvalidModelFile: {
      handler(newVal) {
        if (this._part) {
          this.$set(this._part, '__fileError', newVal);
        }
      },
      immediate: true,
    },
  },
};
