<template>
  <div
    class="tw-m-4 tw-rounded-xs tw-bg-white tw-p-8"
    style="max-width: 600px"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-4">
      <p class="tw-text-2xl tw-font-bold tw-leading-8 tw-text-primary">
        Manage cookie preferences
      </p>
      <button @click="goBack">
        <font-awesome-icon icon="times" />
      </button>
    </div>
    <p class="tw-text-primary tw-text-md tw-pb-2">At Geomiq, we use a range of cookies to give you
      the best possible experience when you use our products and services.
      You will find detailed information about all cookies under each consent category below.</p>
    <p
      class="tw-text-primary tw-text-md tw-pb-2"
    >
      You can learn about how we use cookies by visiting our
      <a
        class="tw-text-primary tw-underline"
        href="https://geomiq.com/privacy-policy/#:~:text=support%40Geomiq.com-,COOKIES,-Below%20is%20a"
        target="_blank"
      >
        Cookie Policy
      </a>
    </p>
    <div
      class="tw-text-primary tw-flex tw-flex-col tw-py-2 tw-text-lg tw-items-start"
    >
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-3 tw-w-full">
        <div class="tw-flex tw-flex-col tw-w-9/12">
          <p class="tw-font-bold tw-text-lg">Essential</p>
          <p class="tw-whitespace-normal tw-text-md">Strictly necessary for the Geomiq website to
            properly function</p>
        </div>
        <p class="tw-text-success tw-font-bold tw-text-md">Always on</p>
      </div>

      <g-toggle
        :isFullWidth="true"
        :isChecked="analytics"
        class="tw-mb-3"
        @change="handleCookieToggle($event, 'analytics')"
      >
        <div class="tw-flex tw-flex-col tw-w-9/12">
          <p class="tw-font-bold tw-text-lg">Analytics and customization</p>
          <p class="tw-whitespace-normal">Helps Geomiq understand user preferences and improve your
            experience
            by delivering more relevant content and ads</p>
        </div>
      </g-toggle>
      <g-toggle
        :isFullWidth="true"
        :isChecked="advertising"
        class="tw-mb-3"
        @change="handleCookieToggle($event, 'advertising')"
      >
        <div class="tw-flex tw-flex-col tw-w-9/12">
          <p class="tw-font-bold tw-text-lg">Advertising</p>
          <p class="tw-whitespace-normal">Used to make advertising messages more relevant to you.
            They help by stopping the same ad from showing up too often, making sure ads look right
            for advertisers, and sometimes showing ads that match your interests.</p>
        </div>
      </g-toggle>
      <g-toggle
        :isFullWidth="true"
        :isChecked="functional"
        @change="handleCookieToggle($event, 'functional')"
      >
        <div class="tw-flex tw-flex-col tw-w-9/12">
          <p class="tw-font-bold tw-text-lg">Functional</p>
          <p class="tw-whitespace-normal">Enhances the performance and functionality of the Geomiq
            website, but is not essential to its use. However, without them, some functionality may
            not be available</p>
        </div>
      </g-toggle>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-x-2 tw-mt-3 tw-justify-between tw-items-center">
      <g-button
        isInverted
        color="tertiary"
        label="Save preferences"
        @click="enableCookies"
      />
      <g-button
        label="Accept all"
        @click="enableCookies('all')"
      />
    </div>
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';
import GToggle from '@common/components/storied/atoms/GToggle.vue';

export default {
  name: 'CookieManagePreferences',

  components: { GButton, GToggle },

  data() {
    return {
      analytics: true,
      advertising: true,
      functional: true,
    }
  },

  methods: {
    enableCookies() {
      this.$emit('handleCookies', {
        analytics: this.analytics,
        advertising: this.advertising,
        functional: this.functional,
      })
    },
    handleCookieToggle($event, cookieType) {
      this[cookieType] = $event
    },
    goBack() {
      this.$emit('goBack', true);
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>

</style>
