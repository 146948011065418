var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-2 main-wrapper"},[_c('button',{staticClass:"header-button button-no-style has-text-left is-flex is-flex-direction-column group-header has-background-white is-relative is-hidden-mobile",class:{'has-background-hover-g-light-3': _vm.active},staticStyle:{"border":"1px solid #dbdbdb"},style:(_vm.active
      ? {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomWidth: 0,
        marginBottom: 0,
        height: '100%',
      }
      : {
        borderRadius: '4px',
        marginBottom: '0.5rem',
      }),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"p-2",staticStyle:{"min-width":"240px"}},[_c('GroupedQuoteHeaderBody',{attrs:{"data":_vm.data,"index":_vm.index,"all-rfqs-length":_vm.allRfqsLength}})],1)]),_c('button',{staticClass:"button is-expanded mobile-header-button is-hidden-tablet",class:{ active: _vm.active },on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"header-button-content-mobile is-flex is-align-items-center is-justify-content-space-around is-flex-direction-column"},[_c('p',{staticClass:"is-size-7 has-text-weight-medium",staticStyle:{"text-transform":"capitalize"}},[_vm._v("\n        Supplier "+_vm._s(_vm.index + 1)+"\n      ")]),_c('p',{staticClass:"mobile-flair-text has-text-info text-transform-capitalize"},[_vm._v("\n        "+_vm._s(_vm.data.flair === 'cheapest' ? 'Best price' : _vm.data.flair)+"\n      ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }