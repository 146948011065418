/* eslint-disable camelcase */
import Vue from 'vue';
import URL_PARAMS from '@/app-buyer/consts/url-params';
import router from '@/app-buyer/router';
import {
  RFQ_CREATED_EVENT,
  RFQ_UPDATED_EVENT,
  SET_MODEL_PARSE_FAILED,
  UPDATE_DRAFT_RFQ,
  UPDATE_QUOTE,
  UPDATE_QUOTE_STATUS,
  UPDATE_UPLOADS,
  WEBSOCKET_MODULE,
} from '@/app-buyer/store/modules/web-sockets/types';
import {
  ALL_RFQS,
  CALL_INSTANT_QUOTE,
  DRAFT_RFQS,
  GET_INSTANT_QUOTE,
  RFQ_MODULE, SET_PROPERTY,
} from '@/app-buyer/store/modules/rfq/types';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_MODEL_PREVIEW,
  RFQ_MODEL,
  RFQ_MODEL_PREVIEW,
} from '@/shared/consts/slugs';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import {
  ACTIVE_PROJECT_HASH,
  ARCHIVED_PROJECTS,
  CURRENT_PROJECT,
  GET_ARCHIVED,
  GET_MRFQ_PROJECTS,
  GET_MRFQ_PROJECTS_M,
  LEAVE_PROJECT_CHANNEL,
  LISTEN_PROJECT_CHANNEL, PROJECT_MODULE,
  PROJECTS,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_PROJECT_M,
  SET_CURRENT_PROJECT_M,
  SET_MRFQS_PAGINATION,
  SET_MRFQS_PAGINATION_PAGES,
  SET_PAGINATION_PAGES,
  STORE,
} from './types';
import { DETAIL, GET, SET, SET_PAGINATION, UPDATE } from '../types';
import Project from '../../../models/Project';

const WSActions = {
  ModelFileBegunParsingEvent: [
    UPDATE_UPLOADS,
    UPDATE_QUOTE_STATUS,
  ],
  ModelFileFailedParsingEvent: [
    UPDATE_UPLOADS,
    SET_MODEL_PARSE_FAILED,
    UPDATE_QUOTE_STATUS,
  ],
  ModelFileParsedEvent: [
    UPDATE_UPLOADS,
    UPDATE_QUOTE_STATUS,
  ],
  ModelFileBegunQuotingEvent: [
    UPDATE_QUOTE_STATUS,
  ],
  ModelFileQuotedEvent: [
    UPDATE_QUOTE_STATUS,
  ],
  ModelFileFailedQuotingEvent: [
    UPDATE_QUOTE_STATUS,
  ],
  'Rfq.DraftRfqStatusUpdatedEvent': [
    UPDATE_QUOTE,
    UPDATE_QUOTE_STATUS,
  ],
  'Rfq.DraftRfqConfigurationUpdatedEvent': [
    UPDATE_DRAFT_RFQ,
  ],
  'Rfq.RfqCreatedEvent': [
    RFQ_CREATED_EVENT,
  ],
  'Rfq.RfqUpdatedEvent': [
    RFQ_UPDATED_EVENT,
  ],
  'Order.OrderRfqClonedEvent': [
    UPDATE_QUOTE,
    UPDATE_QUOTE_STATUS,
  ],
};

export default {
  /**
   * Gets the array of project for the current user
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.filters    The filters to be applied to the call
   * */

  async [GET]({ state, commit, dispatch }, {
    clear = true,
    page = 1,
    limit = 15,
    search = '',
    go_to = '',
    toFront = false,
    include_drafts = false,
  }) {
    const includes = ['draftRfqsCount'];

    if (include_drafts) includes.push('draftRfqs');

    const query = new Project().orderBy('-created_at').include(includes).where('archived', false);

    if (limit) query.limit(limit);
    if (go_to) query.params({ go_to });

    if (page) query.page(page);
    if (search) query.where('search', search);

    const { data, meta } = await query.get().catch((e) => e.response);

    commit(SET, {
      data: data.map((p) => ({
        ...p,
        _loading: true,
      })),
      toFront,
      clear,
    });

    commit(SET_PAGINATION, {
      ...meta,
    });
    commit(SET_PAGINATION_PAGES, {
      current_page: meta.current_page,
      search,
      clear,
    });

    if (state[ACTIVE_PROJECT_HASH]) return data;

    let activeProject = data[0];
    if (go_to) activeProject = data.find((d) => d.hash === go_to);
    commit(SET_ACTIVE_PROJECT_M, {
      hash: activeProject?.hash,
    });

    dispatch(SET_ACTIVE_PROJECT, {
      project: activeProject,
    });

    return data;
  },

  async [GET_ARCHIVED]({ commit }, { clear = true, page = 1, limit = 10 }) {
    const { data } = await new Project().orderBy('-created_at').include([
      'draftRfqs',
      'members.user',
      'members.role',
    ]).where('archived', true).limit(limit).page(page).get().catch((e) => e.response);
    commit(SET, {
      data: data.map((p) => ({
        ...p,
        _loading: true,
      })),
      clear,
      toFront: false,
      isArchived: true,
    });
    return data;
  },

  /**
   * Creates a project
   *
   * @param {Object} context
   * @param projectData    The data to be persisted
   * */
  async [STORE]({ commit, dispatch, state }, projectData) {
    const project = new Project(projectData);
    const data = await project.save();
    if (data) {
      commit(SET, {
        data: [data],
        toFront: true,
      });
      if (!state[ACTIVE_PROJECT_HASH]) {
        dispatch(SET_ACTIVE_PROJECT, { project: data });
      }
    }
    return data;
  },

  /**
   * Updates a projects property
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.project    The project to be updated
   * @param {string} payload.key        The project's property to be updated
   * @param {*} payload.value      The value the property is set to
   * */
  async [UPDATE]({ commit, getters, state, dispatch }, {
    project,
    key,
    value,
  }) {
    const data = await new Project({ ...project, ...{ [key]: value } }).save();
    if (data) {
      commit(UPDATE, {
        hash: data.hash,
        data,
        // archived_at is null when being unarchived
        isUnarchived: value === null && true,
      });

      if (state[ACTIVE_PROJECT_HASH] === data.hash) {
        const nextProjectHash = getters[ARCHIVED_PROJECTS]?.length
          && state[ARCHIVED_PROJECTS][0].hash;
        if (!nextProjectHash) {
          commit(`${RFQ_MODULE}/${SET_PROPERTY}`, { model: data, property: key, value }, { root: true });
        } else {
          router.push(`/quotes/${nextProjectHash}`);
        }
      }
    }
    return data;
  },

  /**
   * Get the details for a project
   *
   * @param {Object} context
   * @param project
   * */
  async [DETAIL]({ commit }, project) {
    if (project?.archived_at) return null;
    if (project) {
      Vue.set(project, '_loading', true);
      const { data } = await new Project().include([
        'draftRfqsCount',
        'members.user',
        'members.role',
        'draftRfqs.configurationProperties',
        'draftRfqs.uploads',
        'draftRfqs.uploads.parserMetadata',
        'draftRfqs.project',
        'draftRfqs.productionRequirements',
        'draftRfqs.cartItems',
        'draftRfqs.quoteNotes',
        'draftRfqs.rfqsCount', // Must go last!!
      ]).find(project.hash);
      commit(UPDATE, {
        hash: project.hash,
        data: { ...data, _detailed: true, _loading: false },
      });
      return data;
    }
    return null;
  },

  /**
   * Sets the currently active project
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {Object} payload.project    The id of the project to be set as active
   * @param {boolean} payload.force    The id of the project to be set as active
   * */

  async [SET_ACTIVE_PROJECT]({ rootState, state, commit, dispatch }, {
    project, force, noRedirect,
  }) {
    if (!state[PROJECTS].find((p) => p.hash === state[ACTIVE_PROJECT_HASH])) {
      const query = new Project().orderBy('-created_at').include([
        'draftRfqs',
        'members.user',
        'members.role',
        'unorderedRfqsCount',
      ]).where('archived', false);
      if (project?.hash) query.where('search_hash', project.hash);

      const { data: currentProject } = await query.get().catch((e) => e.response);

      commit(SET_CURRENT_PROJECT_M, currentProject);
    } else {
      const currentProject = [project];
      commit(SET_CURRENT_PROJECT_M, currentProject);
    }
    const stateProject = state[PROJECTS].find((e) => e.hash === project.hash) || state[PROJECTS].find((e) => e.hash === project.hash) !== undefined
      ? state[PROJECTS].find((e) => e.hash === project.hash)
      : state[CURRENT_PROJECT][0];
    if (stateProject) {
      // dispatch(LEAVE_PROJECT_CHANNEL, state[ACTIVE_PROJECT_HASH]);
      commit(SET_ACTIVE_PROJECT_M, stateProject);
      dispatch(LISTEN_PROJECT_CHANNEL, stateProject.hash);
      if ((!noRedirect
          && (router.currentRoute
            && router.currentRoute?.name?.includes('quote-page')
            && !router.currentRoute.path.includes(project.hash)))
        || router.currentRoute?.name === undefined
        || force) {
        const route = {
          name: 'quote-page',
          params: { [URL_PARAMS.PROJECT_HASH]: stateProject.hash },
        };

        await router.push(route);
      }
      if (stateProject._detailed) {
        const { rfqs = [], draft_rfqs: draftRfqs = [] } = stateProject;

        if (!rootState[RFQ_MODULE]?.[DRAFT_RFQS]?.length || rootState[RFQ_MODULE]?.[DRAFT_RFQS]?.[0]?.project_hash !== project.hash) {
          commit(`${RFQ_MODULE}/${SET}`, {
            rfqs,
            draftRfqs,
            clear: true,
          }, { root: true });
        }
      } else {
        const data = await dispatch(DETAIL, stateProject);
        const { rfqs = [], draft_rfqs: draftRfqs = [] } = data;
        commit(`${RFQ_MODULE}/${SET}`, {
          rfqs,
          draftRfqs,
          clear: true,
        }, { root: true });
      }
    }
  },

  [CALL_INSTANT_QUOTE]({ rootState, dispatch }, event) {
    const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];
    const toUpdate = draftRfqs.filter((p) => p.hash === event.hash);

    // auto entry to instant quote
    dispatch(`${RFQ_MODULE}/${GET_INSTANT_QUOTE}`, { draft: toUpdate[0] }, { root: true });
  },

  [LEAVE_PROJECT_CHANNEL]({ state }, { hash, leaveAllExcept }) {
    if (leaveAllExcept?.length) {
      // eslint-disable-next-line no-undef
      const openChannels = Echo.connector.channels;

      let channelsToClose = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key in openChannels) {
        if (key.toLowerCase().includes('project')) {
          channelsToClose = [...channelsToClose, key];
        }
      }

      let filteredChannelsToClose = [];
      channelsToClose.forEach((c) => {
        let hasMatch = false;
        leaveAllExcept.forEach((l) => {
          if (c.includes(l)) hasMatch = true;
        });
        if (!hasMatch) filteredChannelsToClose = [...filteredChannelsToClose, c];
      });

      filteredChannelsToClose.forEach((channel) => {
        // eslint-disable-next-line no-undef
        Echo.leave(channel);
      });
      return;
    }
    // eslint-disable-next-line no-undef
    const toLeaveHash = hash || state[ACTIVE_PROJECT_HASH];
    // eslint-disable-next-line no-undef
    if (toLeaveHash && Echo.connector.channels[`Project.${toLeaveHash}`]) {
      // eslint-disable-next-line no-undef
      Echo.leave(`Project.${toLeaveHash}`);
    }
  },

  [LISTEN_PROJECT_CHANNEL]({ state, dispatch }, hash) {
    const toListenHash = hash || state[ACTIVE_PROJECT_HASH];
    // eslint-disable-next-line no-undef
    if (toListenHash && !Echo.connector.channels[`Project.${toListenHash}`]) {
      // eslint-disable-next-line no-undef
      const channel = Echo.channel(`Project.${toListenHash}`);
      Object.entries(WSActions).forEach(([eventName, actions]) => {
        channel.listen(eventName, (event) => {
          // eslint-disable-next-line no-restricted-syntax
          actions.forEach((actionName) => {
            dispatch(actionName, event);
          });
        });
      });
    }
  },

  /**
   * Update an RFQs or Draft RFQs uploads after parsing has completed.
   *
   * @param rootState
   * @param event - ModelFileParsedEvent
   */
  [UPDATE_UPLOADS]({ state, rootState }, event) {
    const { preview, upload } = event;
    const projectRfqs = state[PROJECTS]?.find((p) => p.hash === event.project_hash)?.draft_rfqs ?? [];
    const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];
    const allRfqs = rootState[RFQ_MODULE][ALL_RFQS];

    const toUpdate = [...projectRfqs, ...draftRfqs, ...allRfqs].filter((p) => p.hash === event.hash);

    toUpdate.forEach((part) => {
      const modelFileIdx = part?.uploads?.findIndex((e) => [DRAFT_RFQ_MODEL, RFQ_MODEL]?.includes(e.type?.slug));
      const previewIdx = part?.uploads?.findIndex((e) => [DRAFT_RFQ_MODEL_PREVIEW, RFQ_MODEL_PREVIEW]?.includes(e.type?.slug));

      if ([DRAFT_RFQ_MODEL, RFQ_MODEL]?.includes(upload?.type?.slug)) {
        if (modelFileIdx === -1) {
          part?.uploads?.push(upload);
        } else {
          part?.uploads?.splice(modelFileIdx, 1, upload);
        }
      }
      if (upload?.parser_metadata) {
        const modelFile = findModelFile(part);
        Vue.set(modelFile, 'parser_metadata', upload.parser_metadata);
      }
      if (preview) {
        if (previewIdx === -1) {
          part?.uploads?.push(preview);
        }
        const hasModelChanged = part?.uploads?.[modelFileIdx]?.id !== upload?.id;
        if (hasModelChanged && previewIdx) {
          part?.uploads?.splice(previewIdx, 1, preview);
        }
      }
    });
  },

  [UPDATE_QUOTE_STATUS]({ state, rootState, commit}, {
    hash,
    project_hash,
    draft,
  }) {
    const stateProject = state[PROJECTS]?.find((p) => p.hash === project_hash);
    const stateProjectDraftRfqs = stateProject?.draft_rfqs
    let stateProjectDraftRfq = stateProjectDraftRfqs?.find((p) => p.hash === hash);

    if (stateProjectDraftRfq.version <= draft?.version) {
      // Only enter this code block if the event version number is
      // greater than the RFQ in state's version number
      // or the quote_rfq_type is manual

      // TODO replace the quote_status with draft.status. This will require a largish refactor
      // to use the status slugs rather than the translated status direct on the f/e
      stateProjectDraftRfq = {
        ...stateProjectDraftRfq,
        status: draft.status,
      }

      const updateDraftRfqs = [
        ...stateProjectDraftRfqs.filter((d) => d.hash !== hash),
        stateProjectDraftRfq,
      ]

      const updatedProjectData = {
        ...stateProject,
        draft_rfqs: updateDraftRfqs
      }

      commit(`${PROJECT_MODULE}/${UPDATE}`, {
        hash: project_hash,
        data: updatedProjectData,
      }, { root: true })

      const stateDraftRfq = rootState[RFQ_MODULE]?.[DRAFT_RFQS]?.find((p) => p.hash === hash);
      // If the user has swapped to a new project we do not need to update the DRAFT_RFQS
      // as we have already updated the PROJECT.draft_rfqs above
      if (!stateDraftRfq) return;

      const toUpdate = [
        { property: 'status', value: draft.status },
      ];

      // Updating via mutation for component reactivity
      toUpdate.forEach((u) => {
        commit(`${RFQ_MODULE}/${SET_PROPERTY}`, {
          model: stateDraftRfq,
          property: u.property,
          value: u.value,
        }, { root: true });
      })
    }
  },

  [UPDATE_QUOTE]({ state, rootState, commit }, {
    draft,
    quote,
    project_hash,
    hash,
    dimension_x,
    dimension_y,
    dimension_z,
  }) {
    const {
      quote_id,
      quote_rfq_ref,
      quote_price,
      quote_rfq_type,
      quote_status,
      quote_expires_at,
      quote_unit_price,
      quote_tool_cost,
      quote_delay,
      quote_notes,
    } = quote;
    const stateProject = state[PROJECTS]?.find((p) => p.hash === project_hash);
    const stateProjectDraftRfqs = stateProject?.draft_rfqs
    let stateProjectDraftRfq = stateProjectDraftRfqs?.find((p) => p.hash === hash);

    if (stateProjectDraftRfq.version <= draft?.version || quote_rfq_type === 'manual') {
      // Only enter this code block if the event version number is
      // greater than the RFQ in state's version number
      // or the quote_rfq_type is manual

      stateProjectDraftRfq = {
        ...stateProjectDraftRfq,
        quote_id: quote_id,
        quote_rfq_ref: quote_rfq_ref,
        quote_price: quote_price,
        quote_rfq_type: quote_rfq_type,
        quote_status: quote_status,
        quote_expires_at: quote_expires_at,
        quote_unit_price: quote_unit_price,
        quote_tool_cost: quote_tool_cost,
        quote_delay: quote_delay,
        quote_notes: quote_notes?.length ? quote_notes : null,
        dimension_x: dimension_x,
        dimension_y: dimension_y,
        dimension_z: dimension_z,
        version: draft.version, // UPDATE_QUOTE will always fire after UPDATE_DRAFT_RFQ, so if this code block is triggered, update the version number
      }

      const updateDraftRfqs = [
        ...stateProjectDraftRfqs.filter((d) => d.hash !== hash),
        stateProjectDraftRfq,
      ]

      const updatedProjectData = {
        ...stateProject,
        draft_rfqs: updateDraftRfqs
      }

      commit(`${PROJECT_MODULE}/${UPDATE}`, {
        hash: project_hash,
        data: updatedProjectData,
      }, { root: true })

      const stateDraftRfq = rootState[RFQ_MODULE]?.[DRAFT_RFQS]?.find((p) => p.hash === hash);
      // If the user has swapped to a new project we do not need to update the DRAFT_RFQS
      // as we have already updated the PROJECT.draft_rfqs above
      if (!stateDraftRfq) return;

      const toUpdate = [
        { property: 'quote_id', value: quote_id },
        { property: 'quote_rfq_ref', value: quote_rfq_ref },
        { property: 'quote_price', value: quote_price },
        { property: 'quote_rfq_type', value: quote_rfq_type },
        { property: 'quote_status', value: quote_status },
        { property: 'quote_expires_at', value: quote_expires_at },
        { property: 'quote_unit_price', value: quote_unit_price },
        { property: 'quote_tool_cost', value: quote_tool_cost },
        { property: 'quote_delay', value: quote_delay },
        { property: 'quote_notes', value: quote_notes?.length ? quote_notes : null },
        { property: 'dimension_x', value: dimension_x },
        { property: 'dimension_y', value: dimension_y },
        { property: 'dimension_z', value: dimension_z },
        { property: 'version', value: draft.version },
      ];

      // Updating via mutation for component reactivity
      toUpdate.forEach((u) => {
        commit(`${RFQ_MODULE}/${SET_PROPERTY}`, {
          model: stateDraftRfq,
          property: u.property,
          value: u.value,
        }, { root: true });
      })
    }
  },

  [UPDATE_DRAFT_RFQ]({ state, rootState, commit }, {
    project_hash,
    hash,
    configuration,
    configuration_object,
    draft,
    lead_time_dates,
    lead_time_days,
  }) {
    const stateProject = state[PROJECTS]?.find((p) => p.hash === project_hash);
    const stateProjectDraftRfqs = stateProject?.draft_rfqs
    let stateProjectDraftRfq = stateProjectDraftRfqs?.find((p) => p.hash === hash);

    if (stateProjectDraftRfq.version > draft?.version) return;
    // Only enter this code block if the event version number is
    // greater than the RFQ in state's version number

    stateProjectDraftRfq = {
      ...stateProjectDraftRfq,
      configuration: configuration,
      configuration_object: configuration_object,
      config_string: draft.config_string,
      quantity_initial: draft.quantity_initial,
      lead_time_speed: draft.lead_time_speed,
      lead_time: draft.lead_time,
      lead_time_dates: lead_time_dates,
      lead_time_days: lead_time_days,
    }

    const updateDraftRfqs = [
      ...stateProjectDraftRfqs.filter((d) => d.hash !== hash),
      stateProjectDraftRfq,
    ]

    const updatedProjectData = {
      ...stateProject,
      draft_rfqs: updateDraftRfqs
    }

    commit(`${PROJECT_MODULE}/${UPDATE}`, {
      hash: project_hash,
      data: updatedProjectData,
    }, { root: true })

    const stateDraftRfq = rootState[RFQ_MODULE]?.[DRAFT_RFQS]?.find((p) => p.hash === hash);
    // If the user has swapped to a new project we do not need to update the DRAFT_RFQS
    // as we have already updated the PROJECT.draft_rfqs above
    if (!stateDraftRfq) return;

    const toUpdate = [
      { property: 'configuration', value: configuration },
      { property: 'configuration_object', value: configuration_object },
      { property: 'config_string', value: draft.config_string },
      { property: 'quantity_initial', value: draft.quantity_initial },
      { property: 'lead_time_speed', value: draft.lead_time_speed },
      { property: 'lead_time', value: draft.lead_time },
      { property: 'lead_time_dates', value: lead_time_dates },
      { property: 'lead_time_days', value: lead_time_days },
    ];

    // Updating via mutation for component reactivity
    toUpdate.forEach((u) => {
      commit(`${RFQ_MODULE}/${SET_PROPERTY}`, {
        model: stateDraftRfq,
        property: u.property,
        value: u.value,
      }, { root: true });
    });
  },

  [SET_MODEL_PARSE_FAILED]({ state, rootState }, { hash, project_hash }) {
    const projectDraftRfqs = state[PROJECTS]?.find((p) => p.hash === project_hash)?.draft_rfqs;
    const draftRfqs = rootState[RFQ_MODULE][DRAFT_RFQS];

    const toUpdate = [...projectDraftRfqs, ...draftRfqs].filter((p) => p.hash === hash);

    toUpdate.forEach((part) => {
      part.model_parse_failed = true;
    });
  },

  async [GET_MRFQ_PROJECTS]({ commit }, { page = 1 }) {
    const query = new Project().orderBy('-created_at').where('archived', false).limit(15);
    if (page) query.page(page);

    const { data, meta } = await query.get().catch((e) => e.response);

    commit(GET_MRFQ_PROJECTS_M, data);
    commit(SET_MRFQS_PAGINATION, {
      ...meta,
    });
    commit(SET_MRFQS_PAGINATION_PAGES, {
      current_page: meta.current_page,
    });

    return data;
  },

  [RFQ_CREATED_EVENT]({ dispatch }, event) {
    dispatch(`${WEBSOCKET_MODULE}/${RFQ_CREATED_EVENT}`, event, { root: true });
  },

  [RFQ_UPDATED_EVENT]({ dispatch }, event) {
    dispatch(`${WEBSOCKET_MODULE}/${RFQ_UPDATED_EVENT}`, event, { root: true });
  },
};
