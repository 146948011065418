<template>
  <div
    v-if="isAvailable"
    class="tw-text-sm tw-font-normal tw-flex tw-flex-col xl:tw-grid"
    :class="[
      hasAbsoluteTooltip && !isReadOnly ? 'tw-mb-4 xl:tw-mb-8' : !isReadOnly ? 'tw-mb-4' : 'tw-pb-3',
      isArray ? 'configurator-element-wrapper-array' : 'configurator-element-wrapper',
      isReadOnly ? 'tw-border-b tw-border-grey-light tw-pt-3' : '',
    ]"
  >
    <ConfirmApplicableTooltip
      :confirm-applicable="confirmApplicable"
      @cancel="confirmApplicable = null"
      @confirm="finaliseUpdate(confirmApplicable.canApply)"
    />
    <template
      v-if="showLabel"
    >
        <span
          :id="`${def.entity}-label-wrapper`"
          class="tw-font-bold tw-mb-2 xl:tw-mb-0 xl:tw-content-center"
          :class="tooltipMessage ? 'tooltip-label xl:tw-mr-0' :'tw-flex tw-items-center tw-flex-wrap xl:tw-mr-2'"
        >
          {{ label }}
          <tippy
            v-if="tooltipMessage"
            tag="span"
            arrow
          >
            <template #trigger>
              <font-awesome-icon
                icon="info-circle"
                class="tw-text-md tw-text-tertiary tw-ml-05"
              />
            </template>
            <div id="buyer-app">
              <div
                class="tw-text-left tw-p-1 tw-text-sm"
                v-html="tooltipMessage"
              />
            </div>
          </tippy>
          <tippy
            v-if="hasAutoDetectedThickness"
            tag="span"
            arrow
          >
            <template #trigger>
              <img
                src="@/assets/svgs/auto-detect.svg"
                alt="auto-detect-icon"
                class="tw-inline-flex tw-h-4 tw-w-4 tw-ml-05"
              />
            </template>
            <div id="buyer-app">
              <div class="tw-text-left tw-p-1 tw-text-sm">
                <p class="tw-capitalize">Thickness <span class="tw-lowercase">auto-detected</span></p>
              </div>
            </div>
          </tippy>
        </span>
      <template v-if="secondaryTooltipMessage">
        <i>{{ secondaryTooltipMessage }}</i>
      </template>
    </template>
    <template v-if="!isReadOnly">
      <Component
        :is="componentType"
        :entity="def.entity"
        :values="values"
        :properties="options"
        :lead-value="leadValue"
        :placeholder="placeholder"
        :configurations="configurations"
        :lead-configuration="leadConfiguration"
        :parts="parts"
        :lead-hash="leadHash"
        :disabled="leadLockedConfig[def.entity]"
        :is-read-only="isReadOnly"
        :is-thickness="isThickness"
        :has-auto-detected-thickness="hasAutoDetectedThickness"
        :other-thickness="otherThickness"
        size="is-small"
        @input="handleStartUpdate"
        @set-note="$emit('set-note', $event)"
      >
        {{ label }}
      </Component>
    </template>
    <div
      v-else
      class="tw-flex tw-flex-col tw-justify-center"
    >
      <p
        v-for="name in formatValues(valueNames)"
        :key="name"
        v-html="name"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  INPUT_TYPES,
} from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-types';
import {
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import sorting from '@/app-buyer/mixins/sorting';
import ConfiguratorElementSelect
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-select.vue';
import ConfiguratorElementRadioButton
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-radio-button.vue';
import ConfiguratorElementCollapsableInput
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/ConfiguratorElementCollapsableInput.vue';
import ConfiguratorElementMaterial
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-material.vue';
import ConfiguratorElementCheckbox
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-checkbox.vue';
import ConfiguratorElementNumberInput
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-number-input.vue';
import ConfiguratorElementRadio
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-radio.vue';
import ConfiguratorElementMinMax
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/configurator-element-min-max.vue';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import ConfirmApplicableTooltip from '@/app-buyer/components/project/ConfirmApplicableTooltip.vue';
import { RFQ_MODULE, SHOULD_SHOW_PRODUCTION_QUANTITY } from '@/app-buyer/store/modules/rfq/types';

const cachedData = {};

const filterValidOptions = (property, entity, dependencies) => {
  // only return true for properties that have the right entity
  if (property.entity_slug !== entity) return false;
  // all dependencies must either be unset or included in the possible parents array
  return dependencies.every((dep) => property.parent_ids.includes(dep));
};

const sortOptions = (originalData, sortingFunction) => {
  const data = sortingFunction(originalData, ['string_value']).reverse();
  const toStartOrEnd = [['not required'], ['custom', 'other']];
  toStartOrEnd.forEach((pos, index) => {
    pos.forEach((str) => {
      const idx = data.findIndex((prop) => prop.string_value?.toLowerCase().indexOf(str) > -1);
      if (idx > -1) {
        const obj = data.splice(idx, 1)[0];
        // first array goes to start, second array goes to end of sorted values
        data[index ? 'push' : 'unshift'](obj);
      }
    });
  });
  return data;
};

export default {
  name: 'DraftConfiguratorElement',

  components: { ConfirmApplicableTooltip },

  mixins: [sorting, safeMultipleUpdate],

  props: {
    def: {
      type: Object,
      required: true,
    },
    configurations: {
      type: Object,
      default: () => ({}),
    },
    leadHash: {
      type: String,
      required: true,
    },
    parts: {
      type: Array,
      default: () => [],
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isArray: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      label: null,
      placeholder: null,
      options: [],
      confirmApplicable: null,
    };
  },

  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    ...mapState(RFQ_MODULE, {
      SHOULD_SHOW_PRODUCTION_QUANTITY,
    }),

    isAvailable() {
      return this.def.type === INPUT_TYPES.NUMBER || (this.hasAllDependencies && this.hasOptions);
    },

    showLabel() {
      return ![INPUT_TYPES.RADIO, INPUT_TYPES.CHECKBOX].includes(this.def.type);
    },

    isThickness() {
      return this.def.entity === 'thickness';
    },

    hasAutoDetectedThickness() {
      return this.isThickness && Object.values(this.configurations).some((c) => c?.['auto-detects'] && Object.keys(c?.['auto-detects'])?.length && c?.['auto-detects']?.thickness);
    },

    otherThickness() {
      return this.isThickness && this.leadConfiguration?.['other-thickness']?.value ? this.leadConfiguration?.['other-thickness']?.value : null;
    },

    dependencies() {
      const { dependencies } = this.def;
      const arr = (
        dependencies?.get
          ? dependencies.get(this.leadConfiguration)
          : dependencies
      ) || [];
      return arr.map((e) => this.leadConfiguration[e]);
    },

    hasAllDependencies() {
      const { dependencies } = this.def;
      return dependencies?.length || this.dependencies.every((e) => e != null);
    },

    hasOptions() {
      return !!this.options?.length;
    },

    leadConfiguration() {
      return this.configurations[this.leadHash];
    },

    leadValue() {
      return this.leadConfiguration[this.def.entity];
    },

    values() {
      // Get all values from configurations
      const allValues = Object.values(this.configurations).map((conf) => conf[this?.def?.entity]);
      // Remove duplicate values
      return Array.from(new Set(allValues));
    },

    valueNames() {
      return Object.values(this.configurations).map((conf) => conf[this?.def?.entity]).map((v) => {
        if (this.def?.entity === 'quantity_initial') return v;
        return this[REFERENCE_DATA_OBJECT_BY_ID]?.[v]?.name
      });
    },

    componentType() {
      switch (this.def.type) {
        case INPUT_TYPES.SELECT:
          return ConfiguratorElementSelect;
        case INPUT_TYPES.COLLAPSABLE_INPUT:
          return ConfiguratorElementCollapsableInput;
        case INPUT_TYPES.AUTOCOMPLETE:
          return ConfiguratorElementMaterial;
        case INPUT_TYPES.CHECKBOX:
          return ConfiguratorElementCheckbox;
        case INPUT_TYPES.RADIO_BUTTON:
          return ConfiguratorElementRadioButton;
        case INPUT_TYPES.NUMBER:
          return ConfiguratorElementNumberInput;
        case INPUT_TYPES.RADIO:
          return ConfiguratorElementRadio;
        case INPUT_TYPES.MIN_MAX:
          return ConfiguratorElementMinMax;
        default:
          return ConfiguratorElementSelect;
      }
    },

    tooltipMessage() {
      return this.def.tooltip?.get(this.leadConfiguration);
    },

    secondaryTooltipMessage() {
      return this.def.tooltip?.secondary(this.leadConfiguration);
    },

    hasAbsoluteTooltip() {
      return this.def.entity === 'material';
    },
  },

  watch: {
    dependencies: {
      handler() {
        this.loadData();
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    handleStartUpdate(payload) {
      this.wasManualChange = true;

      if (this.def.entity === 'service') {
        // Need to close the material dropdown before changing service-line,
        // as it causes a html jump which is seemingly impossible to debug.
        let materialDropdown = document.getElementById('material-dropdown');
        materialDropdown = [...materialDropdown.getElementsByClassName('collapse-content')];
        materialDropdown[0].style.display = 'none';
      }

      this.startUpdate(payload);
    },

    loadData() {
      const {
        type, entity, sortMethod, label,
      } = this.def;
      // In case of a number input not much needs to be done
      if (type === INPUT_TYPES.NUMBER) {
        this.label = label || '';
        return;
      }
      /* In every other case the reference data needs to be filtered to get the options.
         The results are cached in case there are multiple instances of the same component.
       */
      const identifier = `${entity.toString()},${[this.dependencies].join(',')}`;
      if (!cachedData[identifier] && type !== INPUT_TYPES.NUMBER) {
        const data = this[REFERENCE_DATA]?.filter(
          (property) => filterValidOptions(property, entity, this.dependencies),
        );
        if (sortMethod) {
          cachedData[identifier] = sortOptions(data, this[sortMethod]);
        } else {
          cachedData[identifier] = data;
        }
      }
      this.options = cachedData[identifier];

      // Labels are calculated based on the properties or if it's defined in the def
      if (this.type === INPUT_TYPES.CHECKBOX) {
        this.label = label
          || cachedData[identifier][0]?.string_value
          || cachedData[identifier][0]?.entity_name;
      } else if (this.type === INPUT_TYPES.RADIO) {
        this.label = label;
      } else {
        this.label = label || cachedData[identifier]?.[0]?.entity_name;
      }

      this.placeholder = `Select ${this.label}`;
    },

    formatValues(values) {
      return Object.entries(values.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {})).map(([key, value]) => `${key} <span class="tw-text-xs">x${value}</span>`);
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.configurator-element-wrapper {
  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}

.configurator-element-wrapper-array {
  @media (min-width: 1280px) {
    grid-template-columns: 30% 30% 30%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 30% 30%;
  }
}

.tooltip-label {
  width: 63%
}
</style>
