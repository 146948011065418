<template>
  <div
    id="buyer-app"
    class="modal-card"
  >
    <div class="modal-card-body tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs tw-flex tw-flex-col">
      <font-awesome-icon
        icon="check-circle"
        class="tw-h-12 tw-w-12 tw-text-success tw-self-center tw-mb-5"
      />
      <p class="tw-font-bold tw-text-xl tw-mb-2">
        Your manual quotes have been requested!
      </p>
      <p class="tw-text-md tw-mb-5">
        You will receive your quotes within 24 hours but it could be sooner than that. We will send
        you an email as soon as your quote is ready.
      </p>
      <p class="tw-text-md tw-mb-4">
        Meanwhile, you can go back to the Quotes page to configure more parts and request additional
        quotes.
      </p>
      <div class="tw-self-end">
        <g-button
          label="Back to Quotes"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'RfqSuccessModalContent',
  components: {
    FontAwesomeIcon,
    GButton,
  },
};
</script>

<style
  lang="scss"
  scoped
>
.loading-wheel {
  vertical-align: middle;
}
</style>
