<template>
  <tr :class="{'tw-border-t tw-pt-5': isBordered}">
    <td :class="{'tw-pl-5': isSub, 'tw-font-bold': data.bold}">
      {{ data.title }}
    </td>
    <td class="tw-text-right">
      <template v-if="!data.value">
        Included
      </template>
      <template v-else-if="data.applyFilter">
        {{ data.value | formatWithCurrency(isPenny) }}
      </template>
      <template v-else>
        {{ data.value }}
      </template>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'QuoteRow',
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    isSub: Boolean,
    isPenny: {
      type: Boolean,
      default: () => true,
    },
    isBordered: Boolean,
  },
};
</script>
<style scoped>
.bordered {
  border-top: 1px solid lightGrey;
}
</style>
