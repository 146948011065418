<template>
  <section>
    <RegistrationLayout>
      <template #title>
        Create account & get quotes now
      </template>
      <template #subtitle>
        Get a rapid quote and receive parts in as soon as 5 days
      </template>
      <ValidationObserver v-slot="{ passes }">
        <form
          data-testid="registration-form"
          @submit.prevent.stop
        >
          <BInputWithValidator
            v-model="email"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.email && messages.email[0] && 'is-danger'"
            :message="messages.email && messages.email[0]"
            label="Business Email"
            name="email"
            placeholder="name@company.com"
            rules="required"
            trim
            vid="email"
            @input="validateEmail"
          />
          <BMessage
            v-if="showResend"
            class="pt-3"
            type="is-warning"
          >
            An account already exists for this email address,
            to register please click the link in your email.<br>
            <a
              v-if="!resendSubmitted"
              @click="handleResend"
            >Resend email</a>
            <section v-else>
              Email Sent
            </section>
          </BMessage>
          <BInputWithValidator
            v-model="password"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.password && messages.password[0] && 'is-danger'"
            :message="messages.password && messages.password[0]"
            name="password"
            password-reveal
            placeholder="Your password"
            rules="required"
            type="password"
            vid="password"
          >
            <template #label>
              <div class="is-flex is-justify-content-space-between is-align-content-flex-end mt-3">
                <span class="has-text-white is-size-7-touch has-text-weight-bold">
                  Create password
                </span>
                <a
                  tabindex="-1"
                  v-if="FORCED"
                  @click="FORCE_AUTH('login')"
                >
                  Already have an account?
                </a>
                <RouterLink
                  v-else
                  tabindex="-1"
                  class="has-text-decoration-underline is-flex is-align-items-center is-size-7"
                  data-testid="login-link"
                  to="/login"
                >
                  Already have an account?
                </RouterLink>
              </div>
            </template>
          </BInputWithValidator>
          <div class="registration-input-row">
            <BInputWithValidator
              v-model="firstName"
              custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
              :field-type="messages.first_name && messages.first_name[0] && 'is-danger'"
              :message="messages.first_name && messages.first_name[0]"
              label="First Name"
              name="first_name"
              placeholder="Your first name"
              rules="required"
              vid="first_name"
            />
            <BInputWithValidator
              v-model="lastName"
              custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
              :field-type="messages.last_name && messages.last_name[0] && 'is-danger'"
              :message="messages.last_name && messages.last_name[0]"
              label="Last Name"
              name="last_name"
              placeholder="Your last name"
              rules="required"
              vid="last_name"
            />
          </div>
          <BInputWithValidator
            v-model="contact_number"
            custom-class="has-text-white is-size-7-touch has-text-weight-bold mt-3"
            :field-type="messages.contact_number && messages.contact_number[0] && 'is-danger'"
            :message="messages.contact_number && messages.contact_number[0]"
            input-type="number"
            label="Phone Number"
            min-length="5"
            name="contact_number"
            placeholder="Your contact number"
            rules="required"
            type="number"
            vid="contact_number"
          />
          <BField
            custom-class="has-text-white mt-3"
            data-testid="register-details-company-size-wrapper"
            label="Company size"
          >
            <BSelect
              v-model="company_size"
              data-testid="register-details-company-size"
              expanded
              name="company-size"
              placeholder="Please select a company size"
              required
            >
              <option
                v-for="(companySize, slug) in COMPANY_SIZES"
                :key="slug"
                :value="slug"
              >
                {{ companySize }}
              </option>
            </BSelect>
          </BField>
          <div
            v-if="company_size === '1-49'"
          >
            <div class="tw-font-bold tw-pb-2">Are you a Design Agency or Engineering Consultancy?</div>
            <div class="tw-flex">
              <BInputWithValidator
                v-model="isAgencyOrConsultancy"
                name="is-agency"
                :native-value=true
                input-type="radio"
                rules="required:true"
                type="info"
                class="tw-mr-4 hover:tw-text-tertiary"
              >
                <span class="tw-text-white">Yes</span>
              </BInputWithValidator>
              <BInputWithValidator
                v-model="isAgencyOrConsultancy"
                name="is-agency"
                :native-value=false
                input-type="radio"
                rules="required:true"
                type="info"
                class="hover:tw-text-tertiary"
              >
                <span class="tw-text-white">No</span>
              </BInputWithValidator>
            </div>
          </div>

          <div class="has-text-white mt-4 mb-1">
            <BInputWithValidator
              v-model="agreement"
              data-testid="terms"
              input-type="checkbox"
              name="terms"
              rules="required:true"
              type="is-v2-supporting-1"
              vid="terms"
            >
              <p class="has-text-white is-size-7">
                I agree to the <a
                href="https://geomiq.com/user-terms-conditions/"
                class="has-text-decoration-underline"
                target="_blank"
              >Terms and conditions</a>&nbsp; and the
                <a
                  class="has-text-decoration-underline"
                  href="https://geomiq.com/privacy-policy/"
                  target="_blank"
                >Privacy policy</a>
              </p>
            </BInputWithValidator>
          </div>

          <BInputWithValidator
            v-model="subscribe"
            input-type="checkbox"
            name="subscribe"
            rules="required:false"
            type="is-v2-supporting-1"
            vid="subscribe"
            data-testid="subscribe"
          >
            <p class="is-flex is-align-items-center is-size-7 has-text-white">
              Receive updates about Product & Exclusive offers
            </p>
          </BInputWithValidator>

          <div class="is-flex mt-2 is-align-items-center">
            <g-button
              :isLoading="submitted"
              nativeType="submit"
              label="Create Account"
              size="lg"
              class="tw-mr-4 tw-w-56"
              @click="passes(startRegister)"
              data-testid="sign-up-button"
            />
            <g-button
              :href="partnerForm"
              htmlType="a"
              nativeType="submit"
              label="Sign up as a manufacturing partner"
              type="underline"
              color="tertiary"
              class="tw-border-none"
            />
          </div>
        </form>
      </ValidationObserver>
    </RegistrationLayout>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from 'lodash/function';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import BInputWithValidator from '@/shared/components/inputs/BInputWithValidator.vue';
import {
  AUTH_MODULE, FORCE_AUTH, FORCED, REGISTER, UPLOAD_BEFORE_AUTH
} from '@/app-buyer/store/modules/auth/types';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import RegistrationLayout from '@/app-buyer/components/registration/RegistrationLayout.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { ATTACH_RFQS, RFQ_MODULE, DRAFT_RFQS } from '@/app-buyer/store/modules/rfq/types';
import { UPLOAD_MODULE, PARTS_PARSING } from '@/app-buyer/store/modules/upload/types';
import COMPANY_SIZES from './company-sizes.json';

export default {
  name: 'RegistrationForm',

  components: {
    RegistrationLayout,
    BInputWithValidator,
    ValidationObserver,
    GButton,
  },

  data() {
    return {
      hasRegistered: false,
      isAllPartsParsing: false,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      contact_number: '',
      agreement: false,
      subscribe: true,
      submitted: false,
      messages: {},
      showResend: false,
      resendSubmitted: false,
      company_size: null,
      isAgencyOrConsultancy: null,
      COMPANY_SIZES
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      FORCED,
      UPLOAD_BEFORE_AUTH,
    }),

    ...mapState(UPLOAD_MODULE, {
      PARTS_PARSING,
    }),

    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    partnerForm() {
      return `${getEnvironmentVariable('VUE_APP_VENDOR_URL')}/register`;
    },
  },

  watch: {
    [PARTS_PARSING](nv) {
      if (nv === this[DRAFT_RFQS]?.length && this.hasRegistered) {
        this.$emit('progress', 'success');
        this.submitted = false;
      } else if (nv === this[DRAFT_RFQS]?.length && !this.hasRegistered) {
        this.isAllPartsParsing = true;
      }
    },
  },

  methods: {
    ...mapActions(AUTH_MODULE, {
      register: REGISTER,
    }),
    ...mapActions(RFQ_MODULE, {
      ATTACH_RFQS,
    }),
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),
    async startRegister() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      this.submitted = true;
      const response = await this.register({
        email: this.email,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        contact_number: this.contact_number,
        company_size: this.company_size,
        is_agency_or_consultancy: this.isAgencyOrConsultancy,
        subscribe: this.subscribe,
        user_type: 'buyer',
        agreement: this.agreement,
        captcha: token,
      });
      if (response.status < 300) {
        this.hasRegistered = true;
        // SEGMENT TRACKING TO REMOVE
        if (this.$gtm) {
          this.$gtm.trackEvent({
            event: 'user-action',
            user_id: response.data?.data?.id,
            'user-action-type': 'sign-up',
            email: this.email,
          });
        }
        // SEGMENT TRACKING TO REMOVE

        // If the user has uploaded a file(s) before signing in or registering,
        // we need to ensure all drafts have a user assigned before proceeding
        if (this[UPLOAD_BEFORE_AUTH]) await this[ATTACH_RFQS]({ user_id: response.data.data.id });

        if ((this[UPLOAD_BEFORE_AUTH] && this.isAllPartsParsing) || !this[UPLOAD_BEFORE_AUTH]) {
          this.$nextTick(() => {
            this.$emit('progress', 'success');
          });
          this.submitted = false;
        }
      } else {
        this.messages = response.data.errors;
        this.submitted = false;
      }
    },

    async getRecaptchaToken() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
          );
          resolve(token);
        });
      });
    },

    validateEmail: debounce(async function validateEmail() {
      let token = null;
      if (getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')) {
        token = await this.getRecaptchaToken();
      }
      const { data, status } = await Api.post(ENDPOINTS.VALIDATIONS.EMAIL, {
        email: this.email,
        captcha: token,
      }).catch((e) => e.response);
      if (status < 300) {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = false;
      } else if (data?.errors?.email[0] === 'An account already exists for this email address.') {
        this.messages = {
          ...this.messages,
          email: null,
        };
        this.showResend = true;
      } else {
        this.messages = {
          ...this.messages,
          email: data?.errors?.email[0] !== 'The email field is required.' ? data.errors.email : '',
        };
        console.log(this.messages);
        this.showResend = false;
      }
    }, 500),

    async handleResend() {
      await Api.post(ENDPOINTS.AUTH.REGISTER_BASIC_RESEND, {
        email: this.email,
      });

      this.resendSubmitted = true;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
// height of registration fields made shorter
::v-deep .input {
  height: 2.3em;
}

::v-deep span.icon.is-right {
  height: 2.3em;
}

::v-deep span select, span.select select {
  height: 2.3em;
}

.registration-input-row {
  display: flex;
  justify-content: space-between;

  span {
    flex-grow: 1;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
</style>
