/* eslint-disable func-names,no-use-before-define */
import Vue from 'vue';
import Router from 'vue-router';
import { parse, stringify } from 'qs';
import { GET } from '@/app-buyer/store/modules/types';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { CART_MODULE, SET_PAGE_BEFORE_CART } from '@/app-buyer/store/modules/cart/types';
import {
  AUTH_MODULE,
  LOGGED_IN,
  LOGIN_SUCCESS,
  LOGIN_VIA_LINK,
  MASQUERADING,
} from '@/app-buyer/store/modules/auth/types';
import QuotesPage from '@/app-buyer/views/quote-list/QuotesPage.vue';
import Project from '@/app-buyer/views/project/Project.vue';
import loginPage from '../views/login-page/login-page.vue';
import registrationPage from '../views/registration-page/registration-page.vue';
import URL_PARAMS from '../consts/url-params';
import getEnvironmentVariable from '@/shared/misc/env-variable';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

/**
 * Check if user is partial and needs to fill in data
 * @type {function(): *}
 */
const checkPartial = () => {
  const store = router?.app?.$store;
  return store?.state?.[USER_MODULE]?.[USER_DATA]?.user?.partial;
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: `/quotes/:${URL_PARAMS.PROJECT_HASH}?/:${URL_PARAMS.DRAFT_HASH}?`,
      name: 'home',
      component: Project,
      meta: {
        title: 'Home',
        showsProjects: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: loginPage,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/register/:state?',
      name: 'register',
      component: registrationPage,
      meta: {
        title: 'Register',
      },
      props: true,
    },
    {
      path: '/password/reset/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ '../views/reset-password-page/ResetPasswordPage.vue'),
      meta: {
        title: 'Reset password',
      },
    },
    {
      path: '/email/unsubscribe/:userHashId/:type?',
      name: 'unsubscribe',
      component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/email-subscription/UnsubscribePage.vue'),
      meta: {
        title: 'Email Subscription',
      },
    },
    {
      path: `/quotes/:${URL_PARAMS.PROJECT_HASH}?/:${URL_PARAMS.DRAFT_HASH}?`,
      name: 'quote-page',
      component: Project,
      props: true,
      meta: {
        title: 'Quotes',
        showsProjects: true,
      },
    },
    // {
    //   path: `/blah/:${URL_PARAMS.RFQ_ID}?`,
    //   name: 'quote-list',
    //   component: QuotesPage,
    //   meta: {
    //     requireAuth: true,
    //     title: 'Quotes',
    //   },
    // },
    {
      path: '/orders/:type?',
      name: 'orders',
      component: () => import(/* webpackChunkName: "orders" */ '../views/orders/OrdersPage.vue'),
      props: true,
      meta: {
        requireAuth: true,
        title: 'Orders',
      },
    },
    {
      path: '/orders/:id',
      name: 'order-group',
      component: () => import(/* webpackChunkName: "order-group" */ '../views/order-group/order-group.vue'),
      meta: {
        requireAuth: true,
        title: 'Order group',
      },
      props: true,
    },
    {
      path: '/profile',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "user-profile" */ '../views/user-profile/user-profile.vue'),
      meta: {
        requireAuth: true,
        title: 'Profile',
      },
    },
    {
      path: '/order/:id',
      name: 'order-details',
      component: () => import(/* webpackChunkName: "order-details" */ '../views/order-details/order-details.vue'),
      props: true,
      meta: {
        requireAuth: true,
        title: 'Order details',
      },
    },
    {
      path: `/invoices`,
      name: 'invoice-list',
      props: true,
      component: () => import(/* webpackChunkName: "invoice-list" */ '../views/invoice-list/invoice-list.vue'),
      meta: {
        requireAuth: true,
        title: 'Invoices',
      },
    },
    {
      path: `/invoices/:${URL_PARAMS.INVOICE_ID}`,
      name: 'invoice-details',
      component: () => import(/* webpackChunkName: "InvoiceShow" */ '../components/invoice/InvoiceShow.vue'),
      meta: {
        requireAuth: true,
        title: 'Invoice details',
      },
    },
    {
      path: '/cart',
      component: () => import(/* webpackChunkName: "cart" */ '../views/cart/cart.vue'),
      children: [
        {
          path: '',
          name: 'cart',
          component: () => import(/* webpackChunkName: "cart-list" */ '../components/cart-component/CartList.vue'),
          meta: {
            requireAuth: true,
            title: 'Your cart',
          },
        },
        {
          path: 'checkout',
          name: 'cart-checkout',
          component: () => import(/* webpackChunkName: "cart-checkout" */ '../components/cart-component/CartCheckout.vue'),
          meta: {
            requireAuth: true,
            title: 'Checkout',
          },
          props: {
            stripePayment: null,
          },
        },
        {
          path: 'confirmation',
          name: 'cart-confirmation',
          component: () => import(/* webpackChunkName: "cart-confirmation" */ '../components/cart-component/CartConfirmation.vue'),
          meta: {
            requireAuth: true,
            title: 'Confirmation',
          },
        },
      ],
    },
    {
      path: `/cart-transfer/:${URL_PARAMS.CART_TRANSFER_DIRECTION}?`,
      name: 'cart-transfer',
      component: () => import(/* webpackChunkName: "cart-transfer" */ '../views/cart-transfer/cart-transfer.vue'),
      meta: {
        requireAuth: true,
        title: 'Cart transfer',
      },
    },
    {
      path: '/email/verify/:userId',
      name: 'email-verification',
      component: () => import(/* webpackChunkName: "email-verify" */ '../views/email-verification/email-verification.vue'),
      meta: {
        title: 'Email verification',
      },
    },
    {
      path: '/complete-registration',
      name: 'finish-register',
      component: () => import(/* webpackChunkName: "finish-register" */ '../views/finish-register/finish-register.vue'),
      meta: {
        title: 'Finish register',
      },
    },
    {
      path: '/403',
      name: '403',
      component: () => import(/* webpackChunkName: "not-authorised" */ '../views/not-authorised/not-authorised.vue'),
      meta: {
        title: 'Not Authorised',
      },
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "not-found" */ '../views/not-found/not-found.vue'),
      meta: {
        title: 'Page not found',
      },
    },
  ],
  parseQuery(query) {
    return parse(query);
  },
  stringifyQuery(query) {
    const result = stringify(query);
    return result ? (`?${result}`) : '';
  },
});

const handleProjectGetter = async (to) => {
  const store = router?.app?.$store;
  let isHashProjectLoaded = false;

  if (!store?.state?.[AUTH_MODULE]?.[LOGGED_IN]) return;
  const isAnyProjectLoaded = !!store.state.PROJECT_MODULE?.PROJECTS?.length;

  if (to?.params?.PROJECT_HASH) {
    // if the param has a PROJECT_HASH then check to see if the hash
    // exists within the PROJECTS array
    isHashProjectLoaded = store.state.PROJECT_MODULE?.PROJECTS?.some(
      (p) => p.hash === to?.params?.PROJECT_HASH,
    );
    // if not then check to see if the PROJECTS array is populated
  }

  if (!isHashProjectLoaded && !isAnyProjectLoaded) {
    await store.dispatch('PROJECT_MODULE/GET', {
      go_to: to.params.PROJECT_HASH,
      clear: !!to.params.PROJECT_HASH,
    });
  }
};

/**
 * Before each navigation step we check the user token, if the route has the `requireAuth` property
 * set to true in the `meta` property another step is done to redirect the user if they are not
 * authenticated.
 */
router.beforeEach(async (to, from, next) => {
  const store = router?.app?.$store;

  if (
    !store?.state?.[AUTH_MODULE]?.[LOGGED_IN]
    && to.path.includes('login-via-link')
  ) {
    await store.dispatch(`${AUTH_MODULE}/${LOGIN_VIA_LINK}`, {
      signedPath: window.location.pathname + window.location.search,
    });
  }

  // eslint-disable-next-line no-param-reassign
  if (to.name === 'register') {
    to.meta.title = `${to.meta.title}${to.params?.state ? ` ${to.params?.state}` : ''}`;
  }

  if (to.name !== 'finish-register' && checkPartial()) {
    next({ name: 'finish-register' });
  } else if (to.name === 'finish-register' && !checkPartial()) {
    next('/');
  }
  document.title = `Geomiq${to?.meta?.title ? ` | ${to.meta.title}` : ''}`;

  // Fetch USER_DATA if none
  if (!store?.state?.[USER_MODULE]?.[USER_DATA]) {
    try {
      const { data, status } = await store.dispatch(`${USER_MODULE}/${GET}`);
      // if User is admin or superadmin redirect to admin-spa
      if (
        data?.user?.roles?.includes('admin')
        || data?.user?.roles?.includes('superadmin')
      ) {
        return window.location.href = `${getEnvironmentVariable('VUE_APP_ADMIN_URL')}`;
      }

      // if they have USER_DATA
      if (status < 300) {
        // if User is not already signed in, do so.
        if (!store?.state?.[AUTH_MODULE]?.[LOGGED_IN]) {
          await store.dispatch(`${AUTH_MODULE}/${LOGIN_SUCCESS}`, {});
        }

        // if on their way to the login page, redirect them to the quotes page
        if (to.name === 'login') {
          next({ name: 'quote-page' });
        } else next();
      }
    } catch {
      // if they don't have USER_DATA either redirect
      // to login page or not depending on requireAuth
      if (to.meta?.requireAuth) next({ name: 'login' });
      else next();
    }
  }

  // Set in state the from page details if navigating to the checkout flow.
  // We need this information to add a dynamic breadcrumb button
  const checkoutFlowNames = ['cart', 'cart-checkout'];

  if (!checkoutFlowNames.includes(from.name) && checkoutFlowNames.includes(to.name)) {
    store.commit(`${CART_MODULE}/${SET_PAGE_BEFORE_CART}`, { from })
  }

  next();
});

router.afterEach(async (to) => {
  await handleProjectGetter(to);
  let fullUrl = router.options.base ? router.options.base : '';
  if (!fullUrl.endsWith('/')) {
    fullUrl += '/';
  }
  fullUrl += to.fullPath.startsWith('/') ? to.fullPath.substr(1) : to.fullPath;

  // SEGMENT TRACKING
  if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED') && window.analytics) {
    window.analytics.page(`${to.meta?.title ?? 'No meta.title on page'}`, {
      isMasquerading: !!router?.app?.$store.state[AUTH_MODULE][MASQUERADING],
    });
  }

  // SEGMENT TRACKING TO REMOVE
  if (!window.dataLayer) return;

  window.dataLayer.push({
    event: 'content-view',
    'content-name': fullUrl,
    'content-view-name': to.name,
    user_id: router.app.$store.state?.USER_MODULE?.USER_DATA?.user?.id,
  });
  // SEGMENT TRACKING TO REMOVE
});

export default router;
