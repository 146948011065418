<template>
  <p
    v-if="hasParts"
    class="tw-italic tw-text-md"
  >
    Your configuration options will show here after you've selected a part.
  </p>
  <p
    v-else
    class="tw-italic tw-text-md"
  >
    Your configuration options will show here after you have uploaded your first part.
  </p>
</template>

<script>
import { mapState } from 'vuex';
import { DRAFT_RFQS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'PartFormPlaceholder',
  props: {
    hasParts: Boolean,
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),
  },
};
</script>
