<template>
  <div>
    <div class="tw-flex tw-flex-1 tw-flex-col tw-w-40 tw-h-full tw-text-md tw-text-center
                tw-border tw-rounded-xs
                 tw-cursor-pointer"
         :class="{ 'tw-cursor-pointer tw-bg-tertiary tw-bg-opacity-20 tw-border-tertiary': isSelected && !isDisabled,
                    'tw-cursor-pointer tw-border-grey-light hover:tw-border-grey-dark': !isSelected && !isDisabled,
                    'tw-cursor-not-allowed tw-opacity-40 tw-border-grey-light': !isSelected && isDisabled,
                    'tw-cursor-not-allowed tw-opacity-40 tw-bg-tertiary tw-bg-opacity-20 tw-border-tertiary ': isSelected && isDisabled}"
         @click="selectCard">
      <div class="tw-ml-auto tw-p-2">
        <g-tag :type="tagType" :color="tagClasses" :is-inverted="isTagInverted" :label="costText"/>
      </div>
      <div class="tw-mx-auto">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="inspection">
            <g id="Vector">
              <path d="M22.2729 19.5029L20.4361 17.6661C20.3531 17.5831 20.2408 17.5371 20.1228 17.5371H19.8225C20.331 16.8867 20.6332 16.0687 20.6332 15.1788C20.6332 13.0619 18.9179 11.3466 16.801 11.3466C14.6841 11.3466 12.9688 13.0619 12.9688 15.1788C12.9688 17.2957 14.6841 19.011 16.801 19.011C17.6909 19.011 18.5089 18.7089 19.1593 18.2004V18.5007C19.1593 18.6186 19.2053 18.731 19.2882 18.8139L21.1251 20.6507C21.2983 20.8239 21.5783 20.8239 21.7497 20.6507L22.2711 20.1294C22.4443 19.9562 22.4443 19.6761 22.2729 19.5029ZM16.801 17.5371C15.4984 17.5371 14.4427 16.4832 14.4427 15.1788C14.4427 13.8762 15.4966 12.8205 16.801 12.8205C18.1036 12.8205 19.1593 13.8744 19.1593 15.1788C19.1593 16.4814 18.1054 17.5371 16.801 17.5371Z" fill="#338DBC"/>
              <path d="M13.5181 13.2005C13.2589 13.6295 13.0815 14.1139 13.0075 14.6313H13.5181V13.2005Z" fill="#338DBC"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1479 15.7442H6.28274C5.97533 15.7442 5.72629 15.4953 5.72629 15.1879V11.8486C5.72629 11.5408 5.97533 11.2919 6.28274 11.2919H14.0747C14.1069 11.2919 14.1385 11.2947 14.1691 11.3C14.9192 10.79 15.8253 10.4921 16.801 10.4921C16.8197 10.4921 16.8387 10.4924 16.8574 10.4924V9.06547H12.1267C11.6675 9.06547 11.2919 8.69007 11.2919 8.2309V3.5H4.33493C3.87225 3.5 3.5 3.87225 3.5 4.33493V20.4751C3.5 20.9378 3.87225 21.31 4.33493 21.31H16.0227C16.4853 21.31 16.8574 20.9378 16.8574 20.4751V19.8652C16.8387 19.8652 16.8197 19.8655 16.801 19.8655C15.8421 19.8655 14.9508 19.5778 14.2082 19.0839H11.5701C11.4164 19.0839 11.2919 18.9591 11.2919 18.8056V18.249C11.2919 18.1662 11.3279 18.0919 11.3848 18.0411C11.4341 17.9973 11.4989 17.9706 11.5701 17.9706H13.0359C12.5627 17.3334 12.2474 16.5721 12.1479 15.7442ZM5.77028 5.85406C5.74242 5.89752 5.72629 5.94905 5.72629 6.00443V6.56104C5.72629 6.71492 5.85073 6.83935 6.0046 6.83935H8.78734C8.94104 6.83935 9.06565 6.71492 9.06565 6.56104V6.00443C9.06565 5.8509 8.94104 5.72612 8.78734 5.72612H6.0046C5.90628 5.72612 5.81988 5.77729 5.77028 5.85406ZM6.0046 7.95259C5.85073 7.95259 5.72629 8.07702 5.72629 8.2309V8.78716C5.72629 8.94104 5.85073 9.06547 6.0046 9.06547H8.78734C8.94104 9.06547 9.06565 8.94104 9.06565 8.78716V8.2309C9.06565 8.07702 8.94104 7.95259 8.78734 7.95259H6.0046Z" fill="#338DBC"/>
              <path d="M12.1458 14.6313C12.242 13.8041 12.5534 13.0428 13.0226 12.4048H6.83935V14.6313H12.1458Z" fill="#338DBC"/>
              <path d="M14.1754 17.9706C13.5622 17.3937 13.1392 16.617 13.0102 15.7442H14.0747C14.2321 15.7442 14.3742 15.679 14.4755 15.5738C14.6632 16.6895 15.6329 17.5371 16.801 17.5371C16.8199 17.5371 16.8387 17.5364 16.8574 17.5364V19.0106C16.8387 19.0106 16.8199 19.011 16.801 19.011C15.9955 19.011 15.2482 18.7628 14.6313 18.3383V18.249C14.6313 18.0951 14.5067 17.9706 14.353 17.9706H14.1754Z" fill="#338DBC"/>
              <path d="M16.8574 11.347C16.8387 11.347 16.8199 11.3466 16.801 11.3466C15.9955 11.3466 15.2482 11.5948 14.6313 12.0193V14.2524C14.9907 13.4105 15.8259 12.8205 16.801 12.8205C16.8199 12.8205 16.8387 12.8212 16.8574 12.8212V11.347Z" fill="#338DBC"/>
              <path d="M16.8574 13.6761C16.8387 13.6754 16.8199 13.6751 16.801 13.6751C15.969 13.6751 15.2973 14.3477 15.2973 15.1788C15.2973 16.0109 15.9699 16.6825 16.801 16.6825C16.8199 16.6825 16.8387 16.6822 16.8574 16.6815V13.6761Z" fill="#338DBC"/>
              <path d="M13.2086 3.74361L16.614 7.15236C16.7705 7.30904 16.8574 7.5211 16.8574 7.74018V7.95259H12.405V3.5H12.6172C12.8398 3.5 13.052 3.58693 13.2086 3.74361Z" fill="#338DBC"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="tw-w-10/12 tw-mx-auto tw-text-center tw-text-primary tw-font-bold tw-p-2">{{ item.string_value }}</div>
      <div class="tw-mt-auto tw-py-2"
           :class="{'tw-text-primary hover:tw-bg-tertiary hover:tw-bg-opacity-40 tw-cursor-pointer' : isSelected && !isDisabled,
                    'tw-text-grey-dark hover:tw-bg-grey-lighter tw-cursor-pointer': !isSelected && !isDisabled,
                    'tw-cursor-not-allowed': (!isSelected && isDisabled) || (isSelected && isDisabled)}"
          @click="toggleDetails()">
        <span class="tw-mr-2 tw-font-bold">What's Included</span>
        <font-awesome-icon :icon="isDetailsOpen ? 'chevron-up' : 'chevron-down'"/>
      </div>
    </div>
  </div>
</template>

<script>

import GTag from '@common/components/storied/atoms/GTag.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'InspectionTypeCard',
  components: { GTag, FontAwesomeIcon },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isDetailsOpen: {
      type: Boolean,
      default: false
    },
    tagType: {
      type: String,
      default: 'secondary',
    },
    isTagInverted: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },

  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    tagClasses() {
      if (!this.item.quote_addon_label_price) return 'success'; // Free
      if (this.item.quote_addon_label_price === 1) return 'tertiary' // Variable cost
      if (this.item.quote_addon_label_price < 15000) return 'secondary'; // £
      if (this.item.quote_addon_label_price < 25000) return 'pending'; // ££
      return 'error'; // £££
    },

    costText() {
      if (!this.item.quote_addon_label_price) return 'Free';
      if (this.item.quote_addon_label_price === 1) return 'Variable';
      if (this.item.quote_addon_label_price < 15000) return '£';
      if (this.item.quote_addon_label_price < 25000) return '££';
      return '£££';
    }
  },
  methods: {
    selectCard() {
      if (!this.isDisabled) this.$emit('selectCard');
    },
    toggleDetails() {
      if (!this.isDisabled) this.$emit('toggleDetails', this.item.id);
    },
  }

}
</script>



<style
  scoped
  lang="scss"
>

</style>
