<template>
  <portal
    v-if="modalActive"
    to="modal"
  >
    <transition
      :name="animation"
    >
      <div
        v-if="modalActive"
        class="g-modal tw-inset-0 tw-fixed tw-flex tw-justify-center tw-items-center tw-z-40"
      >
        <div
          class="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-50"
          @click="cancel('outside')"
        />
        <div
          class="tw-overflow-auto tw-relative tw-flex tw-items-center tw-justify-center"
          :style="{ width: modalWidth, height: modalHeight }"
        >
          <slot />
          <div
            v-if="showX"
            type="button"
            class="tw-h-8 tw-max-h-8 tw-max-w-8 tw-min-h-8 tw-min-w-8 tw-w-8 tw-text-white tw-bg-none tw-text-lg tw-fixed tw-right-5 tw-top-5 tw-cursor-pointer"
            @click="cancel('x')"
          >
            <font-awesome-icon icon="times-circle" />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GModal',
  components: {
    FontAwesomeIcon,
  },
  props: {
    isActive: Boolean,
    animation: {
      type: String,
      default: 'zoom-out',
    },
    modalWidth: {
      type: String,
      default: '',
    },
    modalHeight: {
      type: String,
      default: '',
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => {
        return ['escape', 'x', 'outside', 'button']
      },
    },
  },
  data() {
    return {
      modalActive: this.isActive || false,
      animating: !this.isActive,
    }
  },
  computed: {
    cancelOptions() {
      return typeof this.canCancel === 'boolean'
        ? this.canCancel
          ? ['escape', 'x', 'outside', 'button']
          : []
        : this.canCancel
    },
    showX() {
      return this.cancelOptions.indexOf('x') >= 0
    },
  },
  watch: {
    isActive(value) {
      this.modalActive = value;
    },
  },
  methods: {
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return
      this.$emit('cancel')
      this.close()
    },
    close() {
      this.$emit('close');
      this.$emit('update:isActive', false);
      this.modalActive = false;
    },
  },

}
</script>
