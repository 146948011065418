<template>
  <div class="mobile-configurator-wrapper tw-fixed tw-inset-x-0 tw-bottom-0 tw-bg-white tw-overflow-y-auto">
    <div class="tw-sticky tw-top-0 tw-pt-4 tw-pb-1 tw-mb-2 tw-bg-white tw-z-10 tw-px-4 tw-border-b tw-border-grey-light">
      <g-button
        isInverted
        :hasBorder="false"
        iconLeft="chevron-left"
        label="Back to quotes"
        type="primary-icon"
        color="tertiary"
        size="sm"
        class="tw-mb-2"
        style="padding-left: 0;"
        @click="$emit('closeMobileConfigurator')"
      />
      <h1 class="tw-mb-2 tw-text-xl tw-font-bold">
        Configure {{ selectedPartName }}
      </h1>
      <template v-if="selectedPartsInCart">
        <g-message
          iconLeft="lock"
          isFullWidth
          :hasLeftBorder="false"
          size="md"
          color="grey-lighter"
          iconColor="grey-dark"
          class="tw-items-start"
        >
          <div class="tw-flex tw-flex-col tw-items-start">
            <p class="tw-text-sm tw-font-bold">{{
                GET_ALL_SELECTED_PARTS?.length > 1 ? 'One of your quotes is locked' : 'Quote locked'
              }}</p>
            <p class="tw-text-sm">{{
                GET_ALL_SELECTED_PARTS?.length > 1 ? 'To make changes, please make sure all of your selected parts have been removed from the cart first.' :
                  'This part has been added to the cart.To make changes to it, please remove it from the cart first.'
              }}</p>

            <g-button
              isInverted
              :label="`Remove ${selectedPartsInCart} part${selectedPartsInCart > 1 ? 's' : ''} from cart`"
              color="error"
              iconLeft="trash"
              type="primary-icon"
              size="sm"
              class="tw-mt-1"
              @click="removeSelectedPartsFromCart"
            />
          </div>
        </g-message>
      </template>
      <template v-if="isSelectedAwaitingQuote && !selectedPartsInCart">
        <g-message
          iconLeft="info-circle"
          isFullWidth
          :hasLeftBorder="false"
          size="md"
          color="grey-lighter"
          iconColor="grey-dark"
          class="tw-items-start"
        >
          <div class="tw-flex tw-flex-col">
            <p class="tw-text-sm tw-font-bold">Awaiting quote</p>
            <p class="tw-text-sm">{{
                GET_ALL_SELECTED_PARTS?.length > 1 ? 'One of your selected parts is awaiting a quote. Making changes to it will result in a new quote being created. Your old quote will still be kept.' :
                  'This part is awaiting a quote. Making changes to it will result in a new quote being created. Your old quote will still be kept.'
              }}
            </p>
          </div>
        </g-message>
      </template>
    </div>
    <PartFormDraftConfigurator
      :parts="[part]"
      :is-read-only="isReadOnly"
      class="tw-px-4"
    />
    <div class="tw-sticky tw-bottom-0 tw-flex tw-items-center tw-justify-center tw-mt-2 tw-z-10 tw-bg-white tw-border-t tw-border-grey-light">
      <g-button
        :isDisabled="calculating"
        :label="calculating ? 'Applying changes...' : 'Exit configuration'"
        :type="calculating ? 'primary-icon' : 'primary'"
        hasIconSpinning
        isFullWidth
        icon-left="sync"
        color="tertiary"
        class="tw-m-4"
        @click="$emit('closeMobileConfigurator')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PartFormDraftConfigurator
  from '@/app-buyer/components/project/PartFormDraftConfigurator.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GMessage from '@common/components/storied/molecules/GMessage.vue';
import {
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
  RFQ_UPDATE_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import { truncateStringInMiddle } from '@common/helpers/index';

export default {
  name: 'PartTableGroupRowForm',

  components: {
    GMessage,
    PartFormDraftConfigurator,
    GButton,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
    }),
    ...mapState(RFQ_MODULE, {
      RFQ_UPDATE_HASHES,
    }),

    isSelectedAwaitingQuote() {
      return this[GET_ALL_SELECTED_PARTS]?.some(part => part.quote_status?.toLowerCase() === 'awaiting quote');
    },

    selectedPartsInCart() {
      return this[GET_ALL_SELECTED_PARTS]?.filter?.(part => part.cart_item_id)?.length || 0;
    },

    calculating() {
      return !!this[RFQ_UPDATE_HASHES].length;
    },

    selectedPartName() {
      return this[GET_ALL_SELECTED_PARTS]?.length === 1 ? truncateStringInMiddle({ str: this[GET_ALL_SELECTED_PARTS]?.[0]?.name, maxLength: 25 }) : ' parts';
    },

    isReadOnly() {
      return !!this.selectedPartsInCart;
    },
  },

  methods: {
    removeSelectedPartsFromCart() {
      this.$emit('remove-from-cart', { removeAllInCart: false })
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.mobile-configurator-wrapper {
  top: 3.25rem;
  z-index: 9999;
}
</style>
