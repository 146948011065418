<template>
  <div class="number-input-wrapper tw-relative">
    <g-number
      v-bind:value.sync="formattedValue"
      isFullWidth
      :input-label="hasMixedValues ? 'Mixed' : null"
      :has-mixed-values="hasMixedValues"
      id="quantity_initial"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash/function';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GNumber from '@common/components/storied/atoms/GNumber.vue';

export default {
  name: 'ConfiguratorElementNumberInput',
  components: {
    FontAwesomeIcon,
    GButton,
    GNumber,
  },
  props: {
    leadValue: {
      type: [Number, String],
      default: () => null,
    },
    min: {
      type: Number,
      default: () => 0,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    entity: {
      type: String,
      default: () => '',
    },
    leadHash: {
      type: String,
      default: '',
    },
    parts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newValue: this.leadValue,
      correctedValue: false,
      showButton: false,
    };
  },
  computed: {
    formattedValue: {
      get() {
        return this.newValue;
      },
      set(newVal) {
        const oldVal = this.newValue;
        const value = newVal
        this.newValue = value;
        if (value) {
          const isInteger = Number.isInteger(+value);
          if (isInteger) {
            const numberValue = Number.parseInt(value, 10);
            if (numberValue >= (this.min || 0)) {

              this.updateQuantity({ value: numberValue })
            }
            this.$nextTick(() => {
              this.newValue = numberValue;
            });
          } else {
            this.$nextTick(() => {
              this.newValue = oldVal;
            });
          }
        }
      },
    },
    hasMixedValues() {
      return ([...new Set(this.parts?.map((p) => p.quantity_initial))]?.length > 1);
    },
  },
  watch: {
    leadValue(val) {
      this.newValue = val;
    },
  },
  methods: {
    updateQuantity: debounce(function debounceUpdateQuantity({ value }) {
      this.$emit('input', value);
    }, 500),
  },
};
</script>

<style
  lang="scss"
  scoped
>
.mixed-flag-wrapper {
  top: 0.6rem;
  left: 0.55rem;
}
</style>
