<template>
  <div :class="[isReadOnly ? 'tw-border-b tw-border-grey-light tw-pt-3' : '',]">
    <div
      v-if="!isReadOnly"
      class="production-requirements-wrapper tw-mb-4 tw-flex tw-flex-col xl:tw-grid xl:tw-items-center"
    >
      <label class="label tw-text-sm tw-mb-2 xl:tw-mr-2 xl:tw-mb-0">
        <span class="tw-flex tw-items-center">Production Requirements
          <span class="tw-text-error tw-ml-1">*</span>
        </span>
      </label>
      <GmButton
        :type="hasProdReq && hasValidProdReq ? 'light' : 'danger'"
        class="prod-req-btn tw-py-2 tw-pr-2 tw-text-sm tw-justify-between tw-text-primary"
        fullwidth
        icon-pack="fas"
        icon-right="plus"
        outlined
        size="small"
        @click="showProjectRequirementsModal = true"
      >
        {{ prodReqString }}
      </GmButton>
      <BModal
        :active.sync="showProjectRequirementsModal"
        has-modal-card
      >
        <ProductionRequirementsModal
          :configuration="configuration"
          @cancel="showProjectRequirementsModal = false"
          @update-config="updateConfig"
        />
      </BModal>
    </div>

    <div
      v-else
      class="production-requirements-wrapper tw-mb-4 tw-flex tw-flex-col xl:tw-grid xl:tw-items-center"
    >
      <p class="label tw-text-sm tw-mb-2 xl:tw-mr-2 xl:tw-mb-0">Production Requirements</p>
      <div>
        <p
          v-for="readOnlyProdReq in readOnlyProdReqs"
          class="tw-mb-1 tw-text-sm"
        >{{ readOnlyProdReq }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import ProductionRequirementsModal
  from '@/app-buyer/components/project/ProductionRequirementsModal.vue';
import {
  GET_ALL_SELECTED_PARTS,
  PRODUCTION_REQUIREMENTS_MODAL,
  REVISE_REQUOTE_MODAL_RFQS,
  REVISE_REQUOTE_SELECTED_RFQS,
  RFQ_MODULE,
  SET_PRODUCTION_REQUIREMENTS_MODAL,
} from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ProductionRequirements',

  components: {
    ProductionRequirementsModal,
  },

  props: {
    leadHash: {
      type: String,
      default: null,
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    isReviseRequote: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    parts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showProjectRequirementsModal: false,
    };
  },

  computed: {
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),

    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
      REVISE_REQUOTE_SELECTED_RFQS,
    }),

    ...mapState(RFQ_MODULE, {
      PRODUCTION_REQUIREMENTS_MODAL,
      REVISE_REQUOTE_MODAL_RFQS,
    }),

    selectedParts() {
      return this.isReviseRequote ? this[REVISE_REQUOTE_SELECTED_RFQS] : this[GET_ALL_SELECTED_PARTS];
    },

    leadConfig() {
      return this.configurations?.[this.leadHash];
    },
    configuration() {
      return this.selectedParts?.find((p) => p.hash === this.leadHash)?.production_requirements || {};
    },
    isInjectionMoulding() {
      return this.leadConfig.service === (this[REFERENCE_DATA_OBJECT_BY_SLUG] && this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['injection-moulding']?.id);
    },
    hasProdReq() {
      return this.selectedParts.every((part) => part.production_requirements);
    },
    hasValidProdReq() {
      return this[GET_ALL_SELECTED_PARTS].every((part) => new Date(part?.production_requirements?.t1_sample_deliver_by) > new Date());
    },
    prodReqString() {
      let prodReq = [];
      this.selectedParts.forEach((part) => {
        if (part.production_requirements) {
          // eslint-disable-next-line camelcase
          const { t1_sample_deliver_by, development_stage } = part.production_requirements;

          let developmentStage = development_stage.replace(/-/g, ' ');
          developmentStage = developmentStage[0].toUpperCase()
            + developmentStage.slice(1).toLowerCase();

          let cleanDate = new Date(t1_sample_deliver_by);
          cleanDate = cleanDate.toLocaleDateString('en-GB');

          prodReq = [...prodReq, `${cleanDate} - ${developmentStage}`];
        } else {
          prodReq = [...prodReq, 'Add your production requirements'];
        }
      });

      let hasDuplicates = false;

      prodReq.forEach((req) => {
        if (req !== prodReq[0]) hasDuplicates = true;
      });

      if (!hasDuplicates) return prodReq[0];
      return `Multiple selected (${prodReq.length})`;
    },

    readOnlyProdReqs() {
      const prodReq = [];

      this.parts?.forEach((part) => {
        if (part.production_requirements) {
          // eslint-disable-next-line camelcase
          const { t1_sample_deliver_by, development_stage } = part.production_requirements;

          let developmentStage = development_stage.replace(/-/g, ' ');
          developmentStage = developmentStage[0].toUpperCase()
            + developmentStage.slice(1).toLowerCase();

          let cleanDate = new Date(t1_sample_deliver_by);
          cleanDate = cleanDate.toLocaleDateString('en-GB');

          const str = cleanDate + ' - ' + developmentStage;

          prodReq.push(str)
        }
      });

      return [...new Set(prodReq)];
    },
  },

  watch: {
    [PRODUCTION_REQUIREMENTS_MODAL](nv) {
      if (nv) this.showProjectRequirementsModal = true;
    },
  },

  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_PRODUCTION_REQUIREMENTS_MODAL,
    }),

    updateConfig(projectConfig) {
      const validHashes = Object.entries(this.configurations).filter(([, config]) => config.service === this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['injection-moulding']?.id).map(([hash]) => hash);
      if (validHashes.length) {
        validHashes.forEach((hash) => {
          const part = this.selectedParts?.find((p) => p.hash === hash);
          if (!part) return;
          this.$set(part, 'production_requirements', projectConfig?.production_requirements);
        });
        Object.entries(projectConfig).forEach(([entitySlug, value]) => {
          this.$emit('update-config', {
            entitySlug,
            canApply: validHashes.reduce((res, hash) => ({ ...res, [hash]: value }), {}),
          });
        });
      }
      this.showProjectRequirementsModal = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.production-requirements-wrapper {
  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}

.prod-req-btn:hover {
  background-color: white !important;
  cursor: pointer;
}

::v-deep {
  svg {
    font-size: theme('fontSize.sm');
  }
}
</style>
