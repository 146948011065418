import {
  ACCESS_TOKEN,
  FILES_BEFORE_AUTH,
  FORCED,
  LOGGED_IN,
  MASQUERADING,
  UPLOAD_BEFORE_AUTH,
  FILES_UPLOADED_BEFORE_AUTH,
} from './types';

export default function getState() {
  return {
    [LOGGED_IN]: false,
    [ACCESS_TOKEN]: null,
    [MASQUERADING]: false,
    [FORCED]: null,
    [UPLOAD_BEFORE_AUTH]: false,
    [FILES_BEFORE_AUTH]: [],
    [FILES_UPLOADED_BEFORE_AUTH]: 0,
  };
}
