<template>
  <div
    v-if="_dragType !== 'draft-rfq-supporting-file'"
    class="visible-on-dragover part-table-drop-area tw-flex tw-items-center tw-justify-center tw-z-50 tw-fixed tw-w-full tw-inset-0"
    @drop.prevent="handleDrop"
    @dragenter.prevent
    @dragleave.prevent
    @dragover.prevent
  >
    <p class="tw-font-bold tw-text-white tw-text-lg tw-text-center">
      Drop your <br> model files here
    </p>

    <g-modal
      :is-active.sync="isPdfModalOpen"
      :canCancel="false"
      width="1000"
      modalWidth="tw-w-full"
      modalHeight="tw-h-full"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs">
        <div class="tw-flex tw-items-center">
          <font-awesome-icon icon="exclamation-circle" class="tw-text-pending tw-mr-1 tw-text-lg" />
          <p class="tw-font-bold tw-text-lg">Upload {{ hasMultiplePdfs ? 'CAD files' : 'a CAD file' }} instead of {{ hasMultiplePdfs ? 'PDFs' : 'a PDF' }} for a quicker, more competitive quote</p>
        </div>
        <div class="tw-flex tw-items-center tw-justify-end tw-mt-6">
          <g-button
            isInverted
            :label="`Continue with PDF${hasMultiplePdfs ? 's' : ''}`"
            color="tertiary"
            class="tw-mr-3"
            @click="handleCreateQuotes(files)"
          />
          <g-button
            :label="`Upload ${hasMultiplePdfs ? 'CAD files' : 'a CAD file'}`"
            @click="handleUploadCAD"
          />
        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { PROJECT_MODULE, ACTIVE_PROJECT } from '@/app-buyer/store/modules/projects/types';
import { CREATE_DRAFT_RFQ, RFQ_MODULE, DRAFT_RFQS } from '@/app-buyer/store/modules/rfq/types';
import rfqService from '@/app-buyer/api/services/rfq';
import checkPdfUpload from '@/app-buyer/mixins/check-pdf-upload';
import GModal from '@/app-buyer/components/GModal';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'PartTableDropArea',

  components: {
    GButton,
    GModal,
  },

  mixins: [
    accessDragData,
    checkPdfUpload,
  ],

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      CREATE_DRAFT_RFQ,
    }),

    async createDraftFromSupporting({ file, ownerHash }) {
      const fileTypeId = this[REFERENCE_DATA_OBJECT_BY_SLUG][file?.extension?.toLowerCase()]?.id;
      const service = this._preSelectServiceByFileType(file.extension);
      const preset = this._findPreset({ service }, {});

      const originalDraft = this[DRAFT_RFQS].find((d) => d.hash === ownerHash);
      if (originalDraft) {
        originalDraft.uploads = originalDraft.uploads.filter((u) => u.id !== file.id);
      }

      const properties = {
        ...preset,
        ...{
          'file-type': fileTypeId,
          project_hash: this[ACTIVE_PROJECT]?.hash,
          name: file.client_original_name,
        },
      };

      const { model } = await this[CREATE_DRAFT_RFQ]({ properties, isDragged: true });

      if (model) {
        try {
          const { data } = await rfqService.attachSupportingFileAsModelFile(file, model.hash);
          data.forEach((newFile) => {
            model.uploads.push(newFile);
          });
        } catch {
          originalDraft.uploads.push(file);
        }
      }
    },

    handleDrop(event) {
      if (event._handledByComponent) return;
      if (this._dragType === DRAFT_RFQ_SUPPORTING_FILE) {
        this.createDraftFromSupporting(this._dragData);
      } else {
        const files = [].slice.apply(event.dataTransfer.files);
        if (files && files.length) {
          this.handleAttemptCreateQuotes(files)
        }
      }

      this._removeDragData();
    },
  },
};
</script>

<style lang="scss" scoped>
.part-table-drop-area {
  background-color: rgba(0,0,0,0.5);
  border: 2px dashed white;
  border-radius: 5px;
}

@media (min-width: 1150px) {
  .part-table-drop-area {
    width: 35%;
  }
}
</style>
