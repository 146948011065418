<template>
  <span
    v-bind="$attrs"
    v-on="$listeners"
    :class="classList"
    class="tw-justify-center tw-inline-flex tw-items-center"
  >
    <font-awesome-icon
      v-if="iconLeft"
      :icon="iconLeft"
      class="tw-justify-center tw-mx-1 tw-items-center tw-flex tw-text-sm"
      :class="tagIconColor"
    />
    <span
      :class="[{ 'tw-max-w-10 tw-truncate': isEllipsis }, { 'tw-font-bold': isLabelBold }]"
      class="tw-pt-01 tw-text-sm"
    >{{ label }}</span>
    <font-awesome-icon
      v-if="iconRight"
      :icon="iconRight"
      class="tw-justify-center tw-mx-1 tw-items-center tw-flex tw-text-sm"
      :class="tagIconColor"
    />
    <font-awesome-icon
      v-if="isClosable"
      icon="times-circle"
      class="tw-ml-1 tw-cursor-pointer tw-text-sm"
      :class="tagIconColor"
      @click="close"
    />
  </span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Gtag',
  components: {
    FontAwesomeIcon,
  },

  props: {
    isEllipsis: Boolean,
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return [
          'primary',
          'secondary',
          'tertiary'
        ].includes(value);
      },
    },
    color: {
      type: String,
      default: 'grey-lighter',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    isClosable: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
    isLabelBold: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    classList() {
      let classes = [];
      classes.push(this.typeClasses);
      if (this.isFullWidth) classes.push('tw-w-full');
      return classes.filter(Boolean).join(' ');
    },
    typeClasses() {
      if (this.type === 'primary') {
        switch (this.color) {
          case 'primary':
            return 'tw-text-primary tw-border tw-border-primary tw-bg-primary tw-rounded-xs tw-justify-center tw-pl-2 tw-text-md tw-leading-6';
          case 'secondary':
            return 'tw-text-primary tw-border tw-border-secondary tw-bg-secondary tw-rounded-xs tw-px-1 tw-text-md tw-leading-6';
          case 'tertiary':
            return 'tw-text-primary tw-border tw-border-tertiary tw-bg-tertiary tw-rounded-xs tw-pl-2 tw-pr-1 tw-text-md tw-leading-6';
          case 'error':
            return 'tw-text-primary tw-border tw-border-error tw-bg-error tw-rounded-xs tw-px-1 tw-text-md tw-leading-6';
          case 'success':
            return 'tw-text-primary tw-border tw-border-success tw-bg-success tw-rounded-xs tw-px-1 tw-text-md tw-leading-6';
          case 'pending':
            return 'tw-text-primary tw-border tw-border-pending tw-bg-pending tw-rounded-xs tw-px-1 tw-text-md tw-leading-6';
          default:
            return 'tw-text-primary tw-border tw-border-grey-lighter tw-bg-grey-lighter tw-rounded-xs tw-px-1 tw-text-md tw-leading-6';
        }
      }
      if (this.type === 'secondary') {
        if (this.isInverted) {
          switch (this.color) {
            case 'primary':
              return 'tw-text-white tw-bg-primary tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'secondary':
              return 'tw-text-white tw-bg-secondary tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'tertiary':
              return 'tw-text-white tw-bg-tertiary tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'error':
              return 'tw-text-white tw-bg-error tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'success':
              return 'tw-text-white tw-bg-success tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'pending':
              return 'tw-text-white tw-bg-pending tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            default:
              return 'tw-text-white tw-bg-grey-lighter tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
          }
        } else {
          switch (this.color) {
            case 'primary':
              return 'tw-text-primary tw-bg-primary tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'secondary':
              return 'tw-text-secondary tw-bg-secondary tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'tertiary':
              return 'tw-text-tertiary tw-bg-tertiary tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'error':
              return 'tw-text-error tw-bg-error tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'success':
              return 'tw-text-success tw-bg-success tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            case 'pending':
              return 'tw-text-pending tw-bg-pending tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
            default:
              return 'tw-text-primary tw-bg-grey-lighter tw-bg-opacity-20 tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-5';
          }
        }
      }
      if (this.type === 'tertiary') {
        switch (this.color) {
          case 'primary':
            return 'tw-text-primary tw-border tw-border-primary tw-bg-white tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
          case 'secondary':
            return 'tw-text-secondary tw-border tw-border-secondary tw-bg-white tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
          case 'tertiary':
            return 'tw-text-tertiary tw-border tw-border-tertiary tw-bg-white tw-rounded-xs tw-pl-2 tw-pr-2 tw-text-sm tw-font-bold tw-leading-4';
          case 'error':
            return 'tw-text-error tw-border tw-border-error tw-bg-white tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
          case 'success':
            return 'tw-text-success tw-border tw-border-success tw-bg-white tw-R tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
          case 'pending':
            return 'tw-text-pending tw-border tw-border-pending tw-bg-white tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
          default:
            return 'tw-text-tertiary tw-border tw-border-tertiary tw-bg-white tw-rounded-xs tw-px-1 tw-text-sm tw-font-bold tw-leading-4';
        }
      }
    },
    tagIconColor() {
      if (this.iconColor === 'secondary') return `tw-text-secondary`;
      if (this.iconColor === 'tertiary') return `tw-text-tertiary`;
      if (this.iconColor === 'error') return `tw-text-error`;
      if (this.iconColor === 'success') return `tw-text-success`;
      if (this.iconColor === 'grey-dark') return `tw-text-grey-dark`;
      if (this.iconColor === 'grey-darkest') return `tw-text-grey-darkest`;
      if (this.iconColor === 'grey-light') return `tw-text-grey-light`;
      if (this.iconColor === 'white') return `tw-text-white`;
      if (this.iconColor === 'transparent') return `tw-text-primary`;
      // secondary is default
      return `tw-text-primary`;
    }
  },
  methods: {
    close(event) {
      this.$emit('close', event)
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>

</style>
