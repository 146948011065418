/* eslint-disable camelcase */
import {
  CART,
  CART_DATA,
  CONFIRMED_CART_DATA,
  DISCOUNT_CODE,
  DISCOUNT_CODE_NAME,
  DISCOUNT_RATE,
  DISCOUNT_VALUE,
  ITEMS_TOTAL,
  ORDER_BILLING_EMAIL_ADDRESS,
  ORDER_REFERENCE_FILE,
  ORDER_REFERENCE_NUMBER,
  PAGE_BEFORE_CART,
  PAYMENT_METHOD,
  PAYMENT_PROGRESS,
  PAYMENT_PROGRESS_PAID,
  SAVE_CARD,
  SET_CONFIRMED_CART_DATA,
  SET_DISCOUNT_VALUE,
  SET_ORDER_BILLING_EMAIL_ADDRESS,
  SET_ORDER_REFERENCE_FILE,
  SET_ORDER_REFERENCE_NUMBER,
  SET_PAGE_BEFORE_CART,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_PROGRESS,
  SET_SAVE_CARD,
  SET_STRIPE,
  SHIPPING,
  STRIPE,
  SUBTOTAL,
  TAX_AMOUNT,
  TOTAL_AMOUNT,
} from './types';
import { DELETE, RESET_STATE, SET } from '../types';
import getState from './state';

export default {
  /**
   * Sets data in the state
   *
   * @param state
   * @param payload
   * @param payload.data
   * @param payload.clear
   * */
  [SET](state, { data, clear }) {
    if (clear) {
      state[CART].splice(0, state[CART].length);
    }
    state[CART_DATA] = data;
    data.items.forEach((item) => state[CART].push(item));
    const {
      shipping_ex_vat,
      shipping_inc_vat,
      shipping_vat,
      shipping_before_discount,
      combined_shipping_discount,
    } = data;
    state[TOTAL_AMOUNT] = data.total_inc_vat;
    state[ITEMS_TOTAL] = data.items_subtotal;
    state[SUBTOTAL] = data.subtotal_ex_vat;
    state[TAX_AMOUNT] = data.vat;
    state[SHIPPING] = {
      shipping_ex_vat,
      shipping_inc_vat,
      shipping_vat,
      shipping_before_discount,
      combined_shipping_discount,
    };
    state[DISCOUNT_VALUE] = data.discount_value;
    state[DISCOUNT_RATE] = data.discount_rate;
    state[DISCOUNT_CODE] = data.discount_code;
    state[DISCOUNT_CODE_NAME] = data.discount_code_name;
  },
  /**
   * Removes an item from the state's cart
   * @param state
   * @param item
   */
  [DELETE](state, item) {
    state[CART].splice(state[CART].indexOf(item), 1);
  },
  /**
   *
   * @param state
   * @param stripe
   */
  [SET_STRIPE](state, stripe) {
    state[STRIPE] = stripe;
  },
  /**
   *
   * @param state
   * @param bool
   */
  [SET_SAVE_CARD](state, bool) {
    state[SAVE_CARD] = bool;
  },
  /**
   *
   * @param state
   * @param paymentMethod
   */
  [SET_PAYMENT_METHOD](state, paymentMethod) {
    state[PAYMENT_METHOD] = paymentMethod;
  },
  /**
   *
   * @param state
   * @param progress
   */
  [SET_PAYMENT_PROGRESS](state, progress) {
    state[PAYMENT_PROGRESS] = progress;
    state[PAYMENT_PROGRESS_PAID] = progress.status === 3;
  },
  /**
   *
   * @param state
   */
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
  /**
   *
   * @param state
   * @param orderReferenceNumber
   */
  [SET_ORDER_REFERENCE_NUMBER](state, orderReferenceNumber) {
    state[ORDER_REFERENCE_NUMBER] = orderReferenceNumber;
  },
  /**
   *
   * @param state
   * @param billingEmailAddress
   */
  [SET_ORDER_BILLING_EMAIL_ADDRESS](state, billingEmailAddress) {
    state[ORDER_BILLING_EMAIL_ADDRESS] = billingEmailAddress;
  },
  /**
   *
   * @param state
   * @param orderReferenceFile
   */
  [SET_ORDER_REFERENCE_FILE](state, orderReferenceFile) {
    state[ORDER_REFERENCE_FILE] = orderReferenceFile;
  },
  /**
   * Set discount value.
   *
   * @param state
   * @param discountValue
   */
  [SET_DISCOUNT_VALUE](state, discountValue) {
    state[DISCOUNT_VALUE] = discountValue;
  },
  [SET_CONFIRMED_CART_DATA](state, confirmedCartData) {
    state[CONFIRMED_CART_DATA] = confirmedCartData;
  },
  [SET_PAGE_BEFORE_CART](state, page) {
    state[PAGE_BEFORE_CART] = page.from;
  },
};
