<template>
  <tr
    class="order-table-row is-size-7 has-background-white"
    data-testid="order-table-row"
  >
    <td v-if="reorderInitialised">
      <BCheckbox
        :value="isRowSelectedForReorder"
        size="is-small"
        @input="handleRowChecked"
      />
    </td>
    <td>
      <BTag
        data-testid="order-details-ref"
        type="is-g-light-1"
      >
        {{ rfq.ref }}
      </BTag>
    </td>
    <td>
      <PartThumbnail :part="rfq" />
    </td>
    <td
      class="name"
      data-testid="part-name"
    >
      {{ rfq.name }}
    </td>
    <td data-testid="service">
      <TOC
        v-slot="{ value }"
        :data="rfq"
        path="configuration_object.service.string_value"
      >
        {{ value }}
      </TOC>
    </td>
    <td data-testid="quantity">
      <BInput
        v-if="reorderInitialised"
        :disabled="!isRowSelectedForReorder"
        :min="rfq.quantity_initial"
        :validation-message="`You can reorder quantities equal to/ greater than ${
          rfq.quantity_initial
        } (original quantity)`"
        :value="rfq.quantity_initial"
        size="is-small"
        style="width: 10ch;"
        @input="updateQuantities(rfq.id, $event)"
      />
      <template v-else>
        {{ rfq.quantity_initial }}
      </template>
    </td>
    <td data-testid="material">
      <TOC
        v-slot="{ value }"
        :data="rfq"
        path="configuration_object.material.string_value"
      >
        {{ value }}
      </TOC>
    </td>
    <td data-testid="delivery-date">
      <TOC
        v-slot="{ value }"
        :data="rfq"
        path="order.delivery_date"
      >
        <span v-if="deliveredAt">Delivered: </span>
        <template v-if="deliveredAt">{{ deliveredAt.created_at | formatDate('LL')}}</template>
        <template v-else>{{ leadTimeDate({ date: value }) }}</template>
      </TOC>
    </td>
    <td>
      <OrderTableStatus :order="rfq.order" />
    </td>
    <td
      class="has-text-right"
      data-testid="unit-price"
    >
      {{ unitPrice | formatWithCurrency(false) }}
    </td>
    <td
      class="has-text-right has-text-weight-bold"
      data-testid="subtotal"
    >
      {{ rfq.order.amount | formatWithCurrency(false) }}
    </td>
    <td>
      <g-button
        :to="{name: 'order-details', params: {id: rfq.order.id}}"
        isInverted
        htmlType="router-link"
        color="tertiary"
        label="Details"
        data-testid="order-details-btn"
      />
    </td>
    <td>
      <tippy
        content="Chat"
        placement="bottom"
        arrow
        boundary="window">
        <BButton
          icon-left="envelope"
          @click="showIntercom">
        </BButton>
      </tippy>
    </td>
  </tr>
</template>
<script>
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';
import TOC from '@/shared/components/template-optional-chaining/TemplateOptionalChaining.vue';
import OrderTableStatus from '@/app-buyer/views/orders/OrderTableStatus.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import leadTimeCalculator from '@/app-buyer/mixins/lead-time-calculator';

export default {
  name: 'OrderTableRow',
  components: {
    OrderTableStatus,
    TOC,
    PartThumbnail,
    GButton
  },
  mixins: [
    leadTimeCalculator,
  ],

  props: {
    rfq: {
      type: Object,
      required: true,
    },

    thAttrs: {
      type: Function,
      default: null,
    },

    reorderingInvoiceId: {
      type: [String, Number],
      default: null,
    },

    selectedForReorder: {
      type: Array,
      default: () => [],
    },

    newQuantities: {
      type: Object,
      default: () => ({}),
    },

    showDetails: {
      type: Array,
      default: () => [],
    },

    orderId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    isRowSelectedForReorder() {
      const selected = (el) => el.id === this.rfq.id;
      return this.selectedForReorder.some(selected);
    },

    reorderInitialised() {
      return this.reorderingInvoiceId === this.orderId;
    },

    unitPrice() {
      return this.rfq.order.amount / this.rfq.quantity_initial;
    },

    deliveredAt() {
      return this.rfq.order.product_lifecycle.statuses
        .find((status) => status.slug === 'customer-received') ?? null;
    },
  },

  methods: {
    updateQuantities(id, val) {
      this.$emit('update:new-quantities', Object.assign({}, this.newQuantities, {
        [id]: Number.parseInt(val) || null,
      }));
    },
    handleLoadMoreClick() {
      this.loading = true;
      this.$emit('load-details', () => {
        this.loading = false;
      });
    },
    handleRowChecked(e) {
      let reorder = [];
      if (e && !this.isRowSelectedForReorder) {
        reorder = [...this.selectedForReorder, this.rfq];
      } else {
        reorder = this.selectedForReorder.filter((r) => r.id !== this.rfq.id);
      }
      this.$emit('update:selected-for-reorder', reorder);
    },
    showIntercom() {
      Intercom('showNewMessage');
    }
  },
};
</script>

<style
  lang="scss"
  scoped
>
.order-table-row {
  .name {
    word-break: break-all;
  }
}
</style>
