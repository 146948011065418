<template>
  <header class="tw-flex tw-flex-col tw-px-4 tw-pt-2 xl:tw-pt-3 xl:tw-block xl:tw-px-3">
    <div class="tw-grid tw-grid-cols-2 tw-items-center">
      <div class="tw-flex tw-flex-col xl:tw-flex-row xl:tw-items-end">
        <div class="tw-flex tw-items-center">
          <p class="tw-overflow-ellipsis tw-overflow-hidden tw-mr-2 tw-whitespace-nowrap tw-text-xl tw-font-bold xl:tw-text-lg xl:tw-w-full">
            {{ activeProject.name }}
          </p>
          <font-awesome-icon
            v-if="!IS_DESKTOP"
            icon="chevron-down"
            class="tw-text-lg tw-ml-1"
            @click="handleOpenMobileSidebar"
          />
        </div>
        <p class="tw-text-sm tw-font-normal tw-whitespace-nowrap tw-mt-05 xl:tw-mt-0 xl:tw-ml-1">
          {{ activeProject.ref }} ({{
            DRAFT_RFQS?.length
          }} part{{ DRAFT_RFQS?.length > 1 ? 's' : '' }})
        </p>
      </div>
      <div class="tw-flex tw-items-center tw-justify-self-end tw-self-start">
        <ProjectMembers with-invite />
        <template v-if="IS_DESKTOP">
          <PartTableSearch class="tw-ml-2 tw-mb-0 tw-w-40" />
        </template>
      </div>
    </div>
    <template v-if="!IS_DESKTOP">
      <div class="tw-grid tw-items-center tw-grid-cols-3 tw-gap-x-2 tw-mt-2">
        <PartTableSearch
          :is-desktop="IS_DESKTOP"
          class="tw-flex-grow tw-col-span-2"
        />
        <PartTableHeaderUpload class="tw-flex-grow tw-col-span-1" />
      </div>
    </template>
  </header>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ProjectMembers from '@/app-buyer/components/project/ProjectMembers.vue';
import PartTableHeaderUpload from '@/app-buyer/components/project/PartTableHeaderUpload';
import PartTableSearch from '@/app-buyer/components/project/PartTableSearch';
import {
  IS_DESKTOP,
  NAVIGATION_MODULE,
  SET_IS_MOBILE_SIDEBAR_OPEN,
} from '@/app-buyer/store/modules/navigation/types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DRAFT_RFQS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';

export default {
  name: 'ProjectContentHeader',
  components: {
    ProjectMembers,
    PartTableHeaderUpload,
    PartTableSearch,
    FontAwesomeIcon,
  },

  props: {
    activeProject: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),
  },

  methods: {
    ...mapMutations(NAVIGATION_MODULE, {
      SET_IS_MOBILE_SIDEBAR_OPEN,
    }),

    handleOpenMobileSidebar() {
      this[SET_IS_MOBILE_SIDEBAR_OPEN](true);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
