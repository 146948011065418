<template>
  <div class="part-row-wrapper tw-bg-white tw-rounded-sm">
    <div
      :ref="`part-row-${part.hash}`"
      :class="{
      'is-selected tw-bg-tertiary-light' : isSelected,
      'is-transparent': isTransparent,
      'has-bottom-progress': part.uploadPercent < 100 && part.uploadPercent > 0,
      'is-dragged': isDragged,
    }"
      :style="part.uploadPercent < 100 && `background-size: ${part.uploadPercent}% 5px;`"
      class="part-row tw-flex tw-flex-col tw-cursor-pointer tw-relative tw-pt-3 tw-transition tw-ease-in-out tw-duration-300 tw-text-md tw-rounded-sm"
      data-testid="single-part-row"
      @click="selectSingle"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <part-table-group-row-info
        :part="part"
        :is-selected.sync="isSelected"
        :has-parsed="hasParsed"
        :has-failed="hasFailed"
        :is-analysing="isAnalysing"
        :is-long-analysis="isLongAnalysis"
        :is-uploading="isUploading"
        :is-hovered="isHovered"
        :issue-messages="issueMessages"
        :has-errors="hasAnyErrors"
        :part-errors="partErrors"
        :is-pricing="isPricing"
        :is-calculating-new-price="isCalculatingNewPrice"
        :is-model-file-type-instant-quotable="isModelFileTypeInstantQuotable"
        @add-more-files="$refs.partSupporting.clickUpload()"
        @duplicate-with-file="handleDuplicateWithFile"
      >
        <button
          v-if="isDraggable"
          :draggable="isDraggable"
          class="tw-absolute tw-cursor-move tw-py-2 tw-pr-2 tw-pl-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-transition-opacity"
          :class="isHovered ? 'tw-opacity-100' : 'tw-opacity-0'"
          @dragend="onDragEnd"
          @dragstart="onDragStart(part, DRAFT_RFQ_MODEL, $event)"
        >
          <font-awesome-icon
            icon="grip-vertical"
            class=" tw-text-md"
            :class="isSelected ? 'tw-text-grey-dark' : 'tw-text-grey-light'"
          />
        </button>
      </part-table-group-row-info>

      <!-- Error messages -->
      <div
        v-if="hasHardErrors"
        class="error-message-wrapper tw-mx-4 tw-mb-2 xl:tw-ml-8"
      >
        <part-error-message
          v-for="(issue, i) in issueMessages"
          :key="i"
          :part="part"
          :issue="issue"
          :show-cta="IS_DESKTOP"
        />
      </div>
      <div
        v-if="isSelectedOtherThickness && !part.quote_id"
        class="other-thickness-message-wrapper tw-mx-4 tw-mb-2 xl:tw-ml-8"
      >
        <part-row-other-thickness :part="part" />
      </div>

      <div
        v-if="IS_DESKTOP"
        class="part-row-chin tw-flex tw-justify-between tw-pt-1 tw-pr-4 tw-mb-1 tw-pl-8 tw-border-solid lg:tw-border-t lg:tw-border-grey-light"
      >
        <div class="tw-flex tw-items-center">
          <g-button
            id="delete-part"
            iconLeft="trash-alt"
            label="Delete part"
            type="primary-icon"
            color="transparent"
            size="sm"
            fontWeight="medium"
            class="chin-btn"
            style="padding-left: 0;"
            @click="handleDeletePart"
          />
          <span class="tw-text-grey-light">|</span>
          <g-button
            id="duplicate-part"
            iconLeft="clone"
            label="Duplicate part"
            type="primary-icon"
            color="transparent"
            size="sm"
            fontWeight="medium"
            class="chin-btn"
            :is-loading="isCloning"
            @click="handleDuplicatePart"
            :disabled="isUploading || isAnalysing || isCloning"
          />
          <template v-if="(hasParsed || isAnalysing) && !isUploading">
            <span class="tw-text-grey-light">|</span>
            <dfm-modal
              :part="part"
              :is-analysing="isAnalysing"
            />
          </template>
          <template v-if="hasQuoteNotes">
            <span class="tw-text-grey-light">|</span>
            <single-quote-notes-modal
              ref="singleQuoteNotesModal"
              :part="part"
            />
          </template>
        </div>
        <div class="tw-flex tw-items-center">
          <p
            v-if="isLongAnalysis"
            class="tw-text-sm tw-text-grey-darkest"
          >This file is taking a little longer than usual to analyse, please wait</p>
          <previous-quotes-modal
            v-if="part.manual_rfqs_count"
            :part="part"
            :isPartInCart="isPartInCart"
            @removeFromCart="handleRemoveFromCart"
          />
          <div
            v-if="!isStepFile && !isStlFile && part.status === 'manual'"
            class="tw-flex tw-items-center tw-ml-2"
          >
            <font-awesome-icon
              icon="magic"
              class="tw-text-tertiary tw-mr-1"
            />
            <p class="tw-text-sm">
              We recommend uploading a <span class="tw-font-bold">STEP file</span> to get an auto
              quote
            </p>
          </div>
          <template v-if="!isCalculatingNewPrice">
            <g-button
              v-if="isPartInCart"
              isInverted
              label="Remove from cart"
              iconLeft="shopping-cart"
              type="primary-icon"
              color="error"
              size="sm"
              class="tw-ml-2"
              @click="handleRemoveFromCart"
            />
            <tippy
              v-else-if="part.quote_id && !hasAnyErrors"
              arrow
              interactive
              :enabled="quoteNoteConfirmation && part.quote_notes"
              :visible="quoteNoteConfirmation && part.quote_notes"
              :maxWidth="300"
              theme="primary"
              trigger="manual"
              @hidden="quoteNoteConfirmation = false"
            >
              <div id="buyer-app">
                <QuoteConfirmPrompt
                  v-if="part.quote_id && quoteNoteConfirmation"
                  :client-notes="part.quote_notes?.[0]?.content"
                  :quote-id="part.quote_id"
                  :hash="part.hash"
                  confirm-text="Approve and add to cart"
                  @confirm="removeNotificationAndAddToCart"
                />
              </div>
              <g-button
                slot="trigger"
                label="Add to cart"
                iconLeft="shopping-cart"
                type="primary-icon"
                color="tertiary"
                size="sm"
                class="tw-ml-2"
                @click="attemptAddToCart"
              />
            </tippy>
            <g-button
              v-if="part.status === 'manual' && !hasAnyErrors"
              label="Get manual quote"
              type="primary"
              size="sm"
              class="tw-ml-2"
              @click="handleRequestManualQuote"
            />
          </template>
        </div>
      </div>

      <!-- Action Buttons (mobile only) -->
      <div
        v-if="!IS_DESKTOP"
        class="tw-pt-4 tw-px-5 tw-mb-5 tw-border-t tw-border-grey-light"
      >
        <g-button
          v-if="isPartInCart"
          :isDisabled="isCalculatingNewPrice"
          isInverted
          isFullWidth
          label="Remove from cart"
          iconLeft="shopping-cart"
          type="primary-icon"
          color="error"
          size="md"
          class="tw-mb-3"
          @click="handleRemoveFromCart"
        />
        <tippy
          v-else-if="part.quote_id && !hasAnyErrors"
          arrow
          interactive
          :enabled="quoteNoteConfirmation && part.quote_notes?.length"
          :visible="quoteNoteConfirmation && part.quote_notes?.length"
          :maxWidth="300"
          theme="primary"
          trigger="manual"
          @hidden="quoteNoteConfirmation = false"
        >
          <div id="buyer-app">
            <QuoteConfirmPrompt
              v-if="part.quote_id && quoteNoteConfirmation"
              :client-notes="part.quote_notes?.[0]?.content"
              :quote-id="part.quote_id"
              :hash="part.hash"
              confirm-text="Approve and add to cart"
              @confirm="removeNotificationAndAddToCart"
            />
          </div>
          <g-button
            isFullWidth
            :isDisabled="isCalculatingNewPrice"
            slot="trigger"
            label="Add to cart"
            iconLeft="shopping-cart"
            type="primary-icon"
            color="tertiary"
            size="md"
            class="tw-mb-3"
            @click="attemptAddToCart"
          />
        </tippy>
        <previous-quotes-modal
          v-if="part.manual_rfqs_count"
          :part="part"
          :isPartInCart="isPartInCart"
          class="tw-mb-3"
          @removeFromCart="handleRemoveFromCart"
        />
        <g-button
          isFullWidth
          isInverted
          :isDisabled="isCalculatingNewPrice"
          label="Configure part"
          color="tertiary"
          class="tw-mb-3"
          @click="$emit('openConfigurator', part)"
        />
        <part-actions
          :part="part"
          :is-admin-style="false"
          :is-analysing="isAnalysing"
          :has-quote-notes="hasQuoteNotes"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  DRAFT_RFQ_MODEL,
  DRAFT_RFQ_SUPPORTING_FILE,
  IGES,
  IGS,
  RFQ_SUPPORTING_FILE,
  STEP,
  STL,
  STP,
} from '@/shared/consts/slugs';
import {
  APPEND_FILE,
  DRAFT_CLONE,
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  INSTANT_QUOTE_FILE_TYPES,
  RFQ_MODULE,
  RFQ_UPDATE_HASHES,
  SELECTED_PART_HASHES,
  SET_PROPERTY,
} from '@/app-buyer/store/modules/rfq/types';
import { CART, CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import { DELETE } from '@/app-buyer/store/modules/types';
import PartSupporting from '@/app-buyer/components/project/PartSupporting.vue';
import PartErrorMessage from '@/app-buyer/components/project/PartErrorMessage.vue';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import { G_SERVE_MODULE, GET_SPECIFICATION } from '@/app-buyer/store/modules/g-serve/types';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import draggableElement from '@/app-buyer/mixins/draggable-element';
import leadTimeCalculator from '../../mixins/lead-time-calculator';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import {
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import PartTableGroupRowInfo from '@/app-buyer/components/project/PartTableGroupRowInfo';
import PartActions from '@/app-buyer/components/project/PartActions.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import DfmModal from '@/app-buyer/components/project/DfmModal.vue';
import SingleQuoteNotesModal from '@/app-buyer/components/project/SingleQuoteNotesModal.vue';
import addRemoveQuoteCart from '@/app-buyer/mixins/add-remove-quote-cart';
import QuoteConfirmPrompt from '@/app-buyer/components/project/QuoteConfirmPrompt.vue';
import { hasBeen15Mins, hasBeenXMins } from '@/app-buyer/store/modules/rfq/draftIssues';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';
import PreviousQuotesModal from '@/app-buyer/components/project/PreviousQuotesModal.vue';
import PartRowOtherThickness from '@/app-buyer/components/project/PartRowOtherThickness.vue';

export default {
  name: 'PartTableGroupRow',

  components: {
    PartRowOtherThickness,
    PreviousQuotesModal,
    QuoteConfirmPrompt,
    PartTableGroupRowInfo,
    PartSupporting,
    PartErrorMessage,
    PartActions,
    DfmModal,
    SingleQuoteNotesModal,
    GButton,
  },

  mixins: [
    partSelectMixin,
    draggableElement,
    leadTimeCalculator,
    addRemoveQuoteCart,
  ],

  inject: {
    projectContext: {
      default: null,
    },
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isHovered: false,
      quoteNoteConfirmation: false,
      DRAFT_RFQ_MODEL,
      nonDraggableFileTypes: [IGS, IGES, STP, STEP],
      isCloning: false,
      timeForLongAnalysis: 15,
      longAnalysisTimer: null,
      isLongAnalysis: false,
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
      RFQ_UPDATE_HASHES,
    }),

    ...mapState(CART_MODULE, {
      CART,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
    }),

    ...mapGetters(RFQ_MODULE, {
      INSTANT_QUOTE_FILE_TYPES,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
    }),

    issueMessages() {
      if (this[DRAFT_RFQ_ISSUES][this.part.hash]?.length && Object.keys(this.part).length) {
        const issueMessages = this[DRAFT_RFQ_ISSUES][this.part?.hash].filter((issue) => issue.type !== 'parsing');
        if (issueMessages.length) return issueMessages;
      }
      return [];
    },

    isSelectedOtherThickness() {
      return (
        this.part?.configuration?.service === this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['sheet-metal']?.id
        && this.part?.configuration?.thickness === this[REFERENCE_DATA_OBJECT_BY_SLUG]?.['other-thickness']?.id
      );
    },

    isStepFile() {
      if (!this.part?.configuration_object?.['file-type']?.slug) return true;
      return this.part?.configuration_object?.['file-type']?.slug === STP || this.part?.configuration_object?.['file-type']?.slug === STEP;
    },

    isStlFile() {
      return this.part?.configuration_object?.['file-type']?.slug === STL;
    },

    hasHardErrors() {
      return !!this.issueMessages?.length;
    },

    hasAnyErrors() {
      return !!this[DRAFT_RFQ_ISSUES][this.part.hash]?.length
    },

    partErrors() {
      return this[DRAFT_RFQ_ISSUES][this.part.hash] || null
    },

    isAnalysing() {
      return (this.part.status === 'parsing' || !this.part.status || (this.part.status === 'pending' && !hasBeenXMins(this.modelFile?.created_at, 3)))
        && !this.hasFailed
        && !hasBeen15Mins(this.modelFile?.created_at);
    },

    isPricing() {
      return !this.isAnalysing
        && this.isCalculatingNewPrice;
    },

    isModelFileTypeInstantQuotable() {
      return !!this[INSTANT_QUOTE_FILE_TYPES]?.includes(this.modelFile?.extension);
    },

    isCalculatingNewPrice() {
      return !!(this[RFQ_UPDATE_HASHES].includes(this.part.hash) || this.part.status === 'quoting');
    },

    isUploading() {
      return this.part?.status === 'uploading';
    },

    hasParsed() {
      return !!this.modelFile?.parser_metadata?.completed_at;
    },

    hasFailed() {
      return !!this.modelFile?.parser_metadata?.failed_at || this.issueMessages?.some((issue) => issue.isHardFail) || this.part.model_parse_failed;
    },

    modelFile() {
      return findDraftModelFile(this.part);
    },

    supportingFiles() {
      return this.part?.uploads?.filter((u) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(u.type.slug))
        || [];
    },

    fileType() {
      return this.modelFile?.extension?.toUpperCase();
    },

    isTransparent() {
      return this.projectContext?.highlightedParts?.length
        && !this.projectContext?.highlightedParts.includes(this.part?.hash);
    },

    isSelected: {
      get() {
        return this[SELECTED_PART_HASHES].includes(this.part.hash);
      },
      set(value) {
        this.addRemoveFromSelection(!value);
      },
    },

    isDraggable() {
      return this[IS_DESKTOP] && !this.nonDraggableFileTypes.includes(this.fileType) && !this.supportingFiles.length;
    },

    isPartInCart() {
      return !!(this[CART].some((cart) => cart.quote_proposal_id === this.part.quote_id)
        || this.part?.cart_item_id);
    },

    hasQuoteNotes() {
      return !!this.part?.quote_notes?.length;
    },
  },


  watch: {
    isUploading: {
      handler(nv) {
        if (!nv && (!this.part.status || this.part.status === 'parsing') && !MANUAL_QUOTE_EXTENSIONS.includes(this.modelFile?.extension?.toLowerCase())) {
          const seconds = this.timeForLongAnalysis;
          const delay = seconds * 1000;
          this.longAnalysisTimer = setTimeout(() => {
            this.isLongAnalysis = true
          }, delay);
        }
      },
      immediate: true,
    },

    isAnalysing(nv) {
      if (!nv) {
        this.isLongAnalysis = false;
        if (this.longAnalysisTimer) clearTimeout(this.longAnalysisTimer);
      }
    },
  },

  methods: {
    ...mapActions(G_SERVE_MODULE, {
      GET_SPECIFICATION,
    }),

    ...mapActions(RFQ_MODULE, {
      DRAFT_CLONE,
      APPEND_FILE,
      DELETE,
    }),

    ...mapMutations(RFQ_MODULE, {
      SET_PROPERTY,
    }),

    forceClearNoteNotifications() {
      if (this.hasQuoteNotes) this.$refs.singleQuoteNotesModal.forceClearNotification();
    },

    removeNotificationAndAddToCart() {
      this.$refs.singleQuoteNotesModal.forceClearNotification();
      this.handleAddToCart()
    },

    attemptAddToCart() {
      if (!this.part?.quote_notes?.length) this.handleAddToCart();

      const hasAcceptedQuoteNote = localStorage.getItem(`notes_${this.part.hash}_quote_${this.part.quote_id}`);

      if (hasAcceptedQuoteNote) this.handleAddToCart();
      else this.quoteNoteConfirmation = true;
    },

    handleAddToCart() {
      this.addQuoteToCart({ quote_id: this.part.quote_id, part: this.part });
    },

    async handleRemoveFromCart() {
      await this.removeQuoteFromCartByCartItemId({ id: this.part.cart_item_id, part: this.part });
    },

    async handleDuplicateWithFile(eventFiles) {
      const newDraft = await this.handleDuplicatePart()
      await this.uploadSupportingFiles(eventFiles, newDraft)
    },

    async handleDuplicatePart() {
      this.isCloning = true;
      let draft = null;
      try {
        draft = await this[DRAFT_CLONE](this.part);

        if (!draft) {
          return;
        }

        this.$buefy.snackbar.open({
          message: `<div class="message-wrapper">
            <p class="message-title">File duplicated</p>
            <p class="message-content">${this.part.name} has been successfully duplicated.</p>
          </div>`,
          type: 'is-white',
          size: 'is-small',
          position: 'is-top-right',
          duration: 6000,
          pauseOnHover: true,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isCloning = false;
      }

      return draft;
    },

    async uploadSupportingFiles(eventFiles, draft = null) {
      if (!draft) {
        draft = this.part
      }

      this.loading = true;
      try {
        const files = [].slice.apply(eventFiles);
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          // eslint-disable-next-line no-await-in-loop
          await this[APPEND_FILE]({
            draft,
            file,
          });
        }
      } finally {
        this.loading = false;
      }
    },

    handleDeletePart() {
      this.$buefy.dialog.confirm({
        message: `Are you sure you want to <b>remove</b> ${this.part.name}?`,
        confirmText: 'Remove',
        onConfirm: () => {
          const delIndex = this[DRAFT_RFQS].findIndex((d) => d.hash === this.part.hash);

          if (this[DRAFT_RFQS]?.length >= delIndex) {
            if (this[DRAFT_RFQS]?.[delIndex - 1]?.hash) this.selectParts([this[DRAFT_RFQS]?.[delIndex - 1]?.hash])
            else if (this[DRAFT_RFQS]?.[delIndex + 1]?.hash) this.selectParts([this[DRAFT_RFQS]?.[delIndex + 1]?.hash])
          }

          this[DELETE](this.part);
        },
        type: 'is-danger',
      });
    },

    handleRequestManualQuote() {
      // true is submitting the selected parts
      this.$emit('request-quotes', true);
    },

    addRemoveFromSelection(isSelected) {
      const newHashes = isSelected
        ? this[SELECTED_PART_HASHES].filter((h) => h !== this.part.hash)
        : [...this[SELECTED_PART_HASHES], this.part.hash];
      this.selectParts(...newHashes);
    },

    selectSingle(event) {
      if (event?.shiftKey) {
        this.$emit('shift-select', this.part);
        return;
      }

      // for bulk updates we don't want to deselect other parts if the part is already selected
      // when clicking on the quantity_initial duplicate-part, or delete-part buttons
      // const ignoreIds = ['quantity_initial', 'quantity_initial_add', 'quantity_initial_subtract', 'duplicate-part', 'delete-part'];
      const ignoreIds = ['quantity_initial', 'quantity_initial_add', 'quantity_initial_subtract'];
      if (
        this[SELECTED_PART_HASHES]?.length &&
        this[SELECTED_PART_HASHES].includes(this.part.hash) &&
        ignoreIds.includes(event?.target?.id)
      ) return;

      // if the click source is the checkbox further actions are prevented
      if (event?.target?.classList.contains('check') || event?.target?.classList.contains('checkbox-wrapper')) return;
      if (event?.ctrlKey) {
        this.addRemoveFromSelection(this[SELECTED_PART_HASHES].includes(this.part.hash));
      } else {
        this.selectParts(this.part.hash);
      }
    },

    loadGServeData() {
      const isGServeFile = this.modelFile?.parser_metadata?.parser_slug === 'g-serve';
      const urn = this.modelFile?.parser_metadata?.parser_uuid;
      if (isGServeFile && urn) {
        this[GET_SPECIFICATION]({
          urn,
          hash: this.part.hash,
        });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-row {
  min-height: 65px;
  position: relative;

  .text-field {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .part-options-wrapper {
    overflow: initial;
  }

  &:hover:not(.is-selected) {
    background-color: theme('colors.grey-lightest');
  }

  &.is-transparent {
    opacity: .1;
  }

  &.is-dragged {
    opacity: .25;
  }

  .error-message-wrapper {
    ::v-deep {
      .message:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

::v-deep {
  button {
    &.chin-btn {
      color: theme('colors.tertiary') !important;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.preview {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 5rem;
  height: 100%;
  opacity: 0.3;
  filter: opacity(25%);
  pointer-events: none;
  z-index: 1000;
}
</style>
