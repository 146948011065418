<template>
  <section class="is-flex is-flex-direction-column">
    <RegistrationLayout class="is-flex-grow-1">
      <template #title>
        Thanks! You have been successfully signed up.
      </template>
      <template #subtitle>
        Here's a video with a quick overview of how our platform can help deliver your manufacturing
        projects cheaper, faster and with industry leading quality.
      </template>
      <template>
        <p class="title is-5 has-text-white my-5">
          What happens next?
        </p>
        <p class="mb-5">
          We've sent you an email outlining all the benefits of the Geomiq platform.
        </p>
        <p class="mb-5">
          One of our account managers will also be in touch soon,
          to help you get used to the platform.
        </p>
        <p class="mb-5">
          Or if you're ready to upload your first quote, just click the button below to get started.
        </p>
      </template>
      <div>
        <g-button
          nativeType="submit"
          label="Get a quote now"
          size="lg"
          class="tw-mr-4 tw-w-56"
          @click="$emit('progress', 'final')"
          data-testid="register-success-btn"
        />
      </div>
    </RegistrationLayout>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import RegistrationLayout from '@/app-buyer/components/registration/RegistrationLayout.vue';
import RegistrationVideoLink from '@/app-buyer/components/registration/RegistrationVideoLink.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import {
  AUTH_MODULE,
  FILES_BEFORE_AUTH,
  FILES_UPLOADED_BEFORE_AUTH,
} from '@/app-buyer/store/modules/auth/types';

export default {
  name: 'RegistrationSuccess',
  components: {
    RegistrationVideoLink,
    RegistrationLayout,
    GButton,
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      FILES_UPLOADED_BEFORE_AUTH,
      FILES_BEFORE_AUTH,
    }),

    isFilesSubmitting() {
      return this[FILES_UPLOADED_BEFORE_AUTH] < this[FILES_BEFORE_AUTH]?.length;
    },
  },
};
</script>

<style scoped>

</style>
