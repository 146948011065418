<template>
  <div
    class="tw-rounded-xs tw-p-3 tw-inline-flex tw-items-start"
    :class="[messageColor, messageSize, leftBorder, { 'tw-w-full' : isFullWidth}]"
  >
    <font-awesome-icon
      v-if="iconLeft"
      :spin="hasIconSpinning"
      :icon="['fas', `${iconLeft}`]"
      :class="[
          iconSize,
          size === 'sm' ? 'tw-mr-1' : 'tw-mr-2',
          messageIconColor || messageColor,
        ]"
      class="tw-pointer-events-none"
    />
    <slot />
    <font-awesome-icon
      v-if="iconRight"
      :spin="hasIconSpinning"
      :icon="['fas', `${iconRight}`]"
      :class="[
          iconSize,
          size === 'sm' ? 'tw-mr-1' : 'tw-mr-2',
          messageIconColor || messageColor,
        ]"
      class="tw-pointer-events-none tw-ml-auto"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GMessage',

  components: {
    FontAwesomeIcon,
  },

  props: {
    iconRight: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    hasIconSpinning: {
      type: Boolean,
      default: false,
    },
    hasLeftBorder: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
    color: {
      type: String,
      default: 'secondary',
      validator: (value) => [
        'secondary',
        'primary',
        'pending',
        'tertiary',
        'error',
        'success',
        'grey-dark',
        'grey-light',
        'grey-lighter',
        'white',
        'transparent',
      ].includes(value),
    },
    borderColor: {
      type: String,
      default: '',
      validator: (value) => [
        'secondary',
        'primary',
        'pending',
        'tertiary',
        'error',
        'success',
        'grey-dark',
        'grey-light',
        'grey-lighter',
        'white',
        'transparent',
        '',
      ].includes(value),
    },
    iconColor: {
      type: String,
      validator: (value) => [
        'primary',
        'pending',
        'tertiary',
        'error',
        'success',
        'grey-dark',
        'grey-light',
        'grey-lighter',
        'white',
        'transparent',
      ].includes(value),
    },
  },

  computed: {
    messageColor() {
      if (this.color === 'primary') return `tw-text-white tw-bg-primary-light-pressed`;
      if (this.color === 'tertiary') return `tw-text-primary tw-bg-tertiary-light`;
      if (this.color === 'error') return `tw-text-primary tw-bg-error-light`;
      if (this.color === 'success') return `tw-text-primary tw-bg-success-light`;
      if (this.color === 'pending') return `tw-text-primary tw-bg-pending-light`;
      if (this.color === 'grey-dark') return `tw-text-primary tw-bg-grey-light`;
      if (this.color === 'grey-light') return `tw-text-primary tw-bg-grey-lighter`;
      if (this.color === 'grey-lighter') return `tw-text-primary tw-bg-grey-lightest`;
      if (this.color === 'white') return `tw-text-primary tw-bg-white tw-border-solid tw-border-l-4 tw-border-grey-lighter`;
      if (this.color === 'transparent') return `tw-text-primary tw-bg-transparent tw-bg-transparent `;
    },

    messageIconColor() {
      return `tw-text-${this.iconColor}`;
    },

    leftBorder() {
      if (!this.hasLeftBorder) return;

      const defaultClasses = `tw-border-solid tw-border-l-4`;

      if (this.color === 'primary') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'primary'}`;
      if (this.color === 'tertiary') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'tertiary'}`;
      if (this.color === 'error') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'error'}`;
      if (this.color === 'success') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'success'}`;
      if (this.color === 'pending') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'pending'}`;
      if (this.color === 'grey-dark') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'grey-darkest'}`;
      if (this.color === 'grey-light') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'grey-dark'}`;
      if (this.color === 'grey-lighter') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'grey-light'}`;
      if (this.color === 'white') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'grey-lighter'}`;
      if (this.color === 'transparent') return `${defaultClasses} tw-border-${this.borderColor ? this.borderColor : 'transparent'}`;
    },

    iconSize() {
      if (this.size === 'sm') return 'tw-w-3 tw-h-3';
      if (this.size === 'lg') return 'tw-w-4 tw-h-4';
      // md is default
      return 'tw-w-[14px] tw-h-[14px]';
    },

    messageSize() {
      if (this.size === 'sm') return 'tw-p-2 tw-text-sm';
      if (this.size === 'lg') return 'tw-p-4 tw-text-lg ';
      // md is default
      return 'tw-p-3 tw-text-md';
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>

</style>
