<template>
  <div class="part-table-group-wrapper tw-mb-4">
    <PartTableGroupRow
      v-for="(part, i) in group.parts"
      :key="part.hash"
      :part="part"
      @shift-select="$emit('shift-select', part)"
      @openConfigurator="handleOpenConfigurator"
    />
    <PartTableGroupRowForm
      v-if="showMobileConfiguration"
      :part="part"
      @closeMobileConfigurator="handleCloseConfigurator"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PartTableGroupRow from '@/app-buyer/components/project/PartTableGroupRow.vue';
import { RFQ_MODULE, SELECTED_PART_HASHES } from '@/app-buyer/store/modules/rfq/types';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableGroupRowForm from '@/app-buyer/components/project/PartTableGroupRowForm.vue';
import PartTableHead from '@/app-buyer/components/project/PartTableHead.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';

export default {
  name: 'PartTableGroup',

  components: {
    PartTableGroupRowForm,
    PartTableGroupRow,
    FontAwesomeIcon,
    PartTableHead,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    partSelectMixin,
  ],

  data() {
    return {
      renderKey: 0,
      showMobileConfiguration: false,
      part: null,
      pdfMessage: 'We can quote quicker, more accurately, and manufacture even faster with a 3D part file than with a PDF',
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    partHashesInGroup() {
      return this.group.parts.map((part) => part.hash);
    },
    areSomeInGroupSelected() {
      return !this.areAllInGroupSelected
        && this.group.parts.some((part) => this[SELECTED_PART_HASHES].includes(part.hash));
    },
    areAllInGroupSelected: {
      get() {
        return this.group.parts.every((part) => this[SELECTED_PART_HASHES].includes(part.hash));
      },
      set(value) {
        const { areSomeInGroupSelected } = this;
        const newHashes = (value && !areSomeInGroupSelected)
          ? [...this[SELECTED_PART_HASHES], ...this.partHashesInGroup]
          : this[SELECTED_PART_HASHES].filter((hash) => !this.partHashesInGroup.includes(hash));
        this.selectParts(...newHashes);
        if (areSomeInGroupSelected) {
          this.$nextTick(() => this.renderKey++);
        }
      },
    },
  },

  methods: {
    handleOpenConfigurator(part) {
      this.showMobileConfiguration = true;
      this.part = part;
    },
    handleCloseConfigurator() {
      this.showMobileConfiguration = false;
      this.part = null;
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.title-wrapper {
  top: 53px;

  @media (min-width: 1280px) {
    top: 44px;

    .part-table-row-grid {
      grid-template-columns: 60% 20% 3% 17%;
    }
  }
}
</style>
