/* eslint-disable camelcase */
import { mapMutations, mapState } from 'vuex';
import CartItem from '@/app-buyer/models/CartItem';
import Api from '@/app-buyer/api/api';
import {
  METADATA,
  SET_METADATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';
import { SET } from '@/app-buyer/store/modules/types';
import { CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import Quote from '@/app-buyer/models/Quote';
import { RFQ_MODULE, SET_PROPERTY } from '@/app-buyer/store/modules/rfq/types';
import { PROJECT_MODULE, PROJECTS } from '@/app-buyer/store/modules/projects/types';

const addRemoveQuoteCart = {
  computed: {
    ...mapState(USER_MODULE, {
      METADATA,
    }),

    ...mapState(PROJECT_MODULE, {
      PROJECTS,
    })
  },
  methods: {
    ...mapMutations(USER_MODULE, {
      SET_METADATA,
    }),

    ...mapMutations(CART_MODULE, {
      setCart: SET,
    }),

    ...mapMutations(RFQ_MODULE, {
      SET_PROPERTY,
    }),

    async addQuoteToCart({ quote_id, part }) {
      try {
        const query = new CartItem({ quote_id });
        const data = await query.save();

        this.setCart({ data, clear: true });

        if (part) {
          const cartItem = data?.items?.find((i) => i.quote_proposal_id === quote_id);
          const projectToUpdate = this.$store.state[PROJECT_MODULE]?.[PROJECTS]?.find((p) => p.hash === part.project_hash);
          const draftRfqToUpdate = projectToUpdate?.draft_rfqs?.find((rfq) => rfq.hash === part.hash);
          draftRfqToUpdate.cart_item_id = cartItem.id;

          this[SET_PROPERTY]({
            model: part,
            property: 'cart_item_id',
            value: cartItem.id,
          });
        }

        this.$buefy.toast.open({
          type: 'is-success',
          message: `Added item to the cart`,
        });

        return data;
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Couldn't add item from cart`,
        });
      }
    },
    async removeQuotesFromCart({ ids, parts }) {
      try {
        const res = await Api.delete(`/api/v2/cart-items/bulk-destroy/${ids.join(',')}`);

        if (parts) {
          parts.forEach((part) => {
            const projectToUpdate = this.$store.state[PROJECT_MODULE]?.[PROJECTS]?.find((p) => p.hash === part.project_hash);
            const draftRfqToUpdate = projectToUpdate?.draft_rfqs?.find((rfq) => rfq.hash === part.hash);
            draftRfqToUpdate.cart_item_id = null;

            this[SET_PROPERTY]({
              model: part,
              property: 'cart_item_id',
              value: null,
            });
          })
        }

        this.setCart({ data: res?.data?.data, clear: true });
        this.updateMetadataIfSuccessful(res?.data?.data, 'removeMultiple', ids.length);

        this.$buefy.toast.open({
          type: 'is-success',
          message: `Removed ${ids.length} item${ids.length > 1 ? 's' : ''} from the cart`,
        });
      } catch (e) {
        console.log(e)
        throw e;
      }
    },
    async removeQuoteFromCartByCartItemId({ id, part }) {
      try {
        const query = new CartItem({ id });
        const res = await query.delete();

        this.setCart({ data: res.data.data, clear: true });

        if (part) {
          const projectToUpdate = this.$store.state[PROJECT_MODULE]?.[PROJECTS]?.find((p) => p.hash === part.project_hash);
          const draftRfqToUpdate = projectToUpdate?.draft_rfqs?.find((rfq) => rfq.hash === part.hash);
          draftRfqToUpdate.cart_item_id = null;

          this[SET_PROPERTY]({
            model: part,
            property: 'cart_item_id',
            value: null,
          });
        }

        this.$buefy.toast.open({
          type: 'is-success',
          message: `Removed item to the cart`,
        });

        return res?.data?.data;
      } catch (e) {
        console.log(e);
        this.$buefy.toast.open({
          type: 'is-danger',
          message: `Couldn't remove item from cart`,
        });
      }
    },
    async addMultipleQuotesToCart({ quote_ids, parts }) {
      try {
        const res = await Api.post('/api/v2/cart-items/bulk-store', { quote_ids });

        if (parts) {
          parts.forEach((part) => {

            const cartItem = res?.data?.data?.items?.find((i) => i.quote_proposal_id === part.quote_id);
            const projectToUpdate = this.$store.state[PROJECT_MODULE]?.[PROJECTS]?.find((p) => p.hash === part.project_hash);
            const draftRfqToUpdate = projectToUpdate?.draft_rfqs?.find((rfq) => rfq.hash === part.hash);
            draftRfqToUpdate.cart_item_id = cartItem.id;

            this[SET_PROPERTY]({
              model: part,
              property: 'cart_item_id',
              value: cartItem.id,
            });
          })
        }

        this.$buefy.toast.open({
          type: 'is-success',
          message: `Added ${quote_ids.length} item${quote_ids.length > 1 ? 's' : ''} to the cart`,
        });

        this.setCart({ data: res.data.data, clear: true });
        this.updateMetadataIfSuccessful(res?.data?.data, 'addMultiple', quote_ids.length);
      } catch (e) {
        console.log(e)
        throw e;
      }
    },
    updateMetadataIfSuccessful(data, type, num) {
      if (!this[METADATA]) return;
      switch (type) {
        case 'remove':
          this[SET_METADATA]({
            ...this[METADATA],
            cart_count: this[METADATA].cart_count - 1,
          });
          break;
        case 'add':
          this[SET_METADATA]({
            ...this[METADATA],
            cart_count: this[METADATA].cart_count + 1,
          });
          break;
        case 'addMultiple':
          this[SET_METADATA]({
            ...this[METADATA],
            cart_count: this[METADATA].cart_count + num,
          });
          break;
        case 'removeMultiple':
          this[SET_METADATA]({
            ...this[METADATA],
            cart_count: this[METADATA].cart_count - num,
          });
          break;
        default:
          break;
      }
    },
  },
};

export default addRemoveQuoteCart;
