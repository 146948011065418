<template>
  <nav
    v-click-outside="() => menuOpen = false"
    aria-label="main navigation"
    class="navbar tw-bg-primary tw-relative tw-z-40 lg:tw-px-5"
    role="navigation"
  >
    <div class="navbar-brand tw-mr-1">
      <router-link
        to="/quotes"
        class="navbar-item no-box-shadow"
      >
        <div
          class="large-brand-holder"
        >
          <icon-font
            icon="geomiq-text"
            style="font-size: 1.25rem; color: white; font-weight: 100; margin-top: 3px;"
          />
        </div>
      </router-link>
    </div>

    <router-link
      v-for="item in navbarItems.topMenu"
      :to="item.link"
      class="tw-px-3 tw-pt-2 tw-pb-1 tw-ml-3 tw-flex tw-items-center tw-font-bold tw-justify-center tw-border-b-4 tw-group"
      :class="isActiveNavRoute(item.link) ? 'tw-border-secondary' : 'tw-border-transparent'"
    >
      <div
        class="tw-text-md tw-text-white tw-transition-colors xl:group-hover:tw-text-grey-light"
      >
        <font-awesome-icon
          v-if="item.icon"
          :icon="item.icon"
          class="tw-mr-1"
        />
        {{ item.name }}
      </div>
    </router-link>

    <div
      :class="{'is-active' : menuOpen}"
      class="navbar-menu"
    >
      <div class="navbar-end">
        <a
          v-if="loggedIn && roles.includes('vendor')"
          :href="apiUrl"
          class="navbar-item tw-hidden tw-flex tw-items-center tw-justify-center lg:tw-flex"
        >
          <strong>
            Switch to Vendor
          </strong>
        </a>
        <div
          v-show="loggedIn"
          class="navbar-item has-dropdown tw-cursor-pointer tw-group"
          data-testid="menu-dropdown"
        >
          <a class="navbar-link">
            <font-awesome-icon
              v-if="MASQUERADING"
              icon="user-secret"
              class="tw-text-lg tw-transition-colors tw-transition-colors xl:group-hover:tw-text-grey-light"
            />
            <p class="tw-ml-2 tw-transition-colors xl:group-hover:tw-text-grey-light">{{ username || '' }}</p>
            <font-awesome-icon
              icon="chevron-down"
              class="tw-text-md tw-transition-colors tw-ml-2 tw-transition-colors xl:group-hover:tw-text-grey-light"
            />
          </a>
          <div
            class="navbar-dropdown tw-w-40"
            data-testid="navbar-dropdown"
            @click="handleCloseMenu"
          >
            <component
              :is="item.tag || 'a'"
              v-for="item in navbarItems['subMenu']"
              :key="item.name"
              :data-testid="`${item.name}-nav-dropdown-link`"
              :href="item.link"
              :target="(!item.tag || item.tag === 'a') && '_blank'"
              :to="item.tag === 'router-link' && item.link"
              class="navbar-item no-box-shadow"
            >
              <font-awesome-icon
                v-if="item.icon"
                :icon="item.icon"
                :class="item.hasCount && metadata?.[item.hasCount] ? 'tw-text-white' : 'tw-text-grey-light'"
              />
              <span v-else>{{ item.name }}</span>
              <gm-tag
                v-if="item.hasCount && metadata && metadata[item.hasCount]"
                class="count"
                rounded
                style="min-width: 0.8rem;"
                type="is-danger"
              >
                {{ ' ' + metadata[item.hasCount] }}
              </gm-tag>
            </component>

            <b-navbar-item
              class="no-box-shadow"
              @click="logOut"
            >
              {{ MASQUERADING ? 'Back to admin' : 'Logout' }}
              <b-icon
                v-show="MASQUERADING"
                icon="user-secret"
              />
            </b-navbar-item>
          </div>
        </div>

        <navbar-notifications
          v-if="loggedIn"
          class="tw-mr-1"
        />

        <navbar-cart
          v-if="loggedIn"
          :metadata-cart-count="metadata?.['cart_count']"
          :metadata-cart-total="metadata?.['cart_total']"
        />

        <div
          v-if="!loggedIn"
          class="navbar-item"
        >
          <div class="buttons">
            <RouterLink
              class="gm-theme gm-button small link"
              data-testid="register-button"
              to="/register"
            >
              Sign up
            </RouterLink>
          </div>
        </div>
        <div
          v-if="!loggedIn"
          class="navbar-item"
        >
          <div class="buttons">
            <RouterLink
              class="gm-theme gm-button small link"
              data-testid="login-button"
              to="/login"
            >
              Log in
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import clickOutside from 'buefy/src/directives/clickOutside';
import {
  IS_DESKTOP,
  NAVIGATION_MODULE,
  RFQ_ENGINEER_VIEW,
} from '../../store/modules/navigation/types';
import { AUTH_MODULE, LOGGED_IN, LOGOUT, MASQUERADING } from '../../store/modules/auth/types';
import {
  GET_METADATA,
  GET_USER_NAME,
  GET_USER_ROLES,
  METADATA,
  USER_MODULE,
} from '../../store/modules/user/types';
import ProfileDataWidget from '../user-profile/profile-data-widget.vue';
import StepIndicator from '../step-indicator/step-indicator.vue';
import NavbarNotifications from './navbar-notifications.vue';
import {
  ACTIVE_PROJECT,
  PAGINATION_PAGES,
  PROJECT_MODULE,
  PROJECTS,
} from '../../store/modules/projects/types';
import getEnvironmentVariable from '../../../shared/misc/env-variable';
import { GET, PAGINATION } from '@/app-buyer/store/modules/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import NavbarCart from '@/app-buyer/components/navbar/NavbarCart.vue';

const navbarItems = {
  end: [
    {
      name: 'cart',
      link: '/cart',
      icon: 'shopping-cart',
      tag: 'router-link',
      hasCount: 'cart_count',
      requiresAuth: true,
    },
  ],
  topMenu: [
    {
      name: 'Quotes',
      link: '/quotes',
      tag: 'router-link',
      icon: 'file-invoice',
    },
    {
      name: 'Orders',
      link: '/orders',
      tag: 'router-link',
      icon: 'clipboard-list-check',
    },
  ],
  subMenu: [
    {
      name: 'invoices',
      link: '/invoices',
      tag: 'router-link',
      requiresAuth: true,
    },
    {
      name: 'cart transfer',
      link: '/cart-transfer',
      tag: 'router-link',
      hasCount: 'cart_transfer_count',
      requiresAuth: true,
    },
    {
      name: 'resources',
      link: 'https://geomiq.com/knowledgebase/',
      tag: 'a',
    },
    {
      name: 'contact',
      link: 'https://geomiq.com/contact-us/',
      tag: 'a',
    },
    {
      name: 'profile',
      link: '/profile',
      tag: 'router-link',
    },
  ],
};

export default {
  name: 'Navbar',

  components: {
    NavbarNotifications,
    StepIndicator,
    ProfileDataWidget,
    GButton,
    NavbarCart,
  },

  directives: {
    clickOutside,
  },

  data() {
    return {
      navbarItems,
      menuOpen: false,
      noClose: false,
      apiUrl: getEnvironmentVariable('VUE_APP_VENDOR_URL'),
    };
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      loggedIn: LOGGED_IN,
      MASQUERADING,
    }),
    ...mapState(PROJECT_MODULE, {
      PROJECTS,
      PAGINATION,
      PAGINATION_PAGES,
    }),
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    ...mapState(NAVIGATION_MODULE, {
      RFQ_ENGINEER_VIEW,
      IS_DESKTOP,
    }),
    ...mapState(USER_MODULE, {
      metadata: METADATA,
    }),
    ...mapGetters(USER_MODULE, {
      username: GET_USER_NAME,
      roles: GET_USER_ROLES,
    }),
  },

  methods: {
    ...mapActions(AUTH_MODULE, {
      logOut: LOGOUT,
    }),

    ...mapActions(USER_MODULE, {
      getMetaData: GET_METADATA,
    }),

    ...mapActions(PROJECT_MODULE, {
      GET,
    }),

    isActiveNavRoute(link) {
      return this.$route?.path?.includes(link);
    },

    handleCloseMenu(e) {
      if (this.noClose || [...e.srcElement?.classList]?.includes('no-close-menu')) {
        this.noClose = false;
        return;
      }

      this.menuOpen = false;
      this.noClose = false;
    },

    async handleChangePage(nextPage = true) {
      this.isLoadingMoreProjects = true;

      const pageChangeTo = nextPage ? Number(Math.max(...this[PAGINATION_PAGES]) + 1) : Number(Math.min(...this[PAGINATION_PAGES]) - 1);

      await this.GET({ clear: false, page: pageChangeTo, toFront: !nextPage });
      this.isLoadingMoreProjects = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

.large-brand-holder {
  align-items: center;
  display: flex;
  margin: -0.75rem;
}

.navbar {
  .navbar-dropdown {
    max-height: 55vh;
    overflow: auto;

    @media (min-width: 1280px) {
      max-height: initial;
    }
  }
}
</style>
