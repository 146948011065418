<template>
  <div
    :class="`${type}-table`"
    :data-testid="`${type}-table`"
  >
    <table class="table tw-w-full tw-relative">
      <slot name="table-header" />
      <tbody
        v-for="(table, i) in updatedTableData"
        :key="table.id"
        :style="tdStyle(table)"
      >
        <tr>
          <td
            :colspan="reorderingInvoiceId ? headingLength + 1 : headingLength"
            class="info-block"
          >
            <OrdersInfoBlock
              v-if="type === 'orders'"
              :is-same-vendor.sync="isSameVendor"
              :loading="loading"
              :new-quantities.sync="newQuantities"
              :open-detailed="openDetailed"
              :order="table"
              :pagination="pagination"
              :reordering-invoice-id="reorderingInvoiceId"
              :selected-for-reorder.sync="selectedForReorder"
              @handleDetailClick="handleRowClick"
              @start-reorder="startReorder"
              @confirm-reorder="$emit('confirm-reorder')"
              @cancel-reorder="$emit('cancel-reorder')"
            />
            <QuotesInfoBlock
              v-else-if="type === 'quotes'"
              :has-load-more="hasLoadMore(table)"
              :listing="table"
              :has-been-loaded="hasBeenLoaded"
              data-testid="quote-header-line"
              @cancelled="$emit('cancelled')"
              @load-more="handleLoadMoreClick(table.id, i)"
              @update-active-quote-group="handleActiveQuoteGroup"
              @update:project="$emit('update:project')"
            />
          </td>
        </tr>
        <template v-if="type === 'orders'">
          <OrderTableRow
            v-for="rfq in cleanOrderRfq(table)"
            v-show="openDetailed.includes(table.id)"
            :key="rfq.hash"
            :new-quantities="newQuantities"
            :order-id="table.id"
            :reordering-invoice-id="reorderingInvoiceId"
            :rfq="rfq"
            :selected-for-reorder="selectedForReorder"
            @update:selected-for-reorder="$emit('update:selected-for-reorder', $event)"
            @update:new-quantities="$emit('update:new-quantities', $event)"
          />
        </template>
        <template v-else-if="type === 'quotes'">
          <template v-for="rfq in table.rfqs">
            <QuoteTableRow
              :key="rfq.hash"
              :active-quote-groups="activeQuoteGroups"
              :listing="table"
              :rfq="rfq"
              :table-id="table.id"
              data-testid="quote-line"
              @cancelled="handleCancelled"
              @duplicated="$emit('duplicated')"
              @open-quote-details="handleOpenQuoteDetails"
            />
            <tr
              v-if="openedQuoteDetails.includes(rfq.hash)"
              :key="rfq.id + '-child'"
            >
              <td
                class="details-wrapper"
                colspan="13"
              >
                <RfqListDetail
                  :open="true"
                  :quote-group-id="openedQuoteDetailsActiveGroup ?
                    openedQuoteDetailsActiveGroup.id : null"
                  :rfq="rfq"
                  :listing="table"
                  :active-quote-groups="activeQuoteGroups"
                />
              </td>
            </tr>
          </template>
          <template v-for="index in pendingSkeletons(table)">
            <tr :key="index">
              <td colspan="13">
                <b-skeleton
                  :animated="true"
                  height="50px"
                />
              </td>
            </tr>
          </template>
        </template>
        <tr
          v-if="hasPendingRfqs(table)"
          class="tw-bg-white"
        >
          <td colspan="13">
            <BButton
              :disabled="true"
              expanded
              fullwidth
              outlined
              size="is-small"
            >
              Processing quotes ({{
                table.rfqCreatedEventCount ? table.rfqCreatedEventCount : 1
              }}/{{ hasPendingRfqs(table) }})
              <span class="tw-ml-1 loading-wheel">
                <font-awesome-icon
                  icon="spinner"
                  spin
                />
              </span>
            </BButton>
          </td>
        </tr>
        <tr
          v-else-if="hasLoadMore(table)"
          class="tw-bg-white"
        >
          <td colspan="13">
            <g-button
              :isDisabled="loading"
              :isLoading="loadMoreLoading[i]"
              isFullWidth
              isInverted
              color="tertiary"
              label="Load more"
              fontWeight="normal"
              @click="handleLoadMoreClick(table.id, i)"
              data-testid="order-details-load-more"
            />
          </td>
        </tr>
        <tr style="height:2rem;" />
      </tbody>
      <BLoading
        :active="loading"
        :is-full-page="true"
      />
    </table>
    <NoFiltersMatch
      v-if="!firstLoad && !tableData.length"
      @clear-filters="$emit('clear-filters')"
    />
    <BPagination
      v-if="pagination && tableData.length"
      :current="pagination.current_page"
      :per-page="pagination.per_page"
      :total="pagination.total"
      :range-before="5"
      :range-after="5"
      data-testid="pagination"
      size="is-small"
      @change="$emit('page-change', $event)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { QUOTES_MODULE, REQUESTED_QUOTE_INFO } from '@/app-buyer/store/modules/quotes/types';
import OrderTableRow from '@/app-buyer/components/info-table/OrderTableRow.vue';
import OrdersInfoBlock from '@/app-buyer/components/info-table/OrdersInfoBlock.vue';
import QuoteTableRow from '@/app-buyer/components/info-table/QuoteTableRow.vue';
import QuotesInfoBlock from '@/app-buyer/components/info-table/QuotesInfoBlock.vue';
import RfqListDetail from '@/app-buyer/components/rfq-list/RfqListDetail.vue';
import NoFiltersMatch from '@/app-buyer/components/info-table/NoFiltersMatch.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  components: {
    OrderTableRow,
    OrdersInfoBlock,
    QuoteTableRow,
    QuotesInfoBlock,
    RfqListDetail,
    NoFiltersMatch,
    FontAwesomeIcon,
    GButton,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'orders',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    isSameVendor: {
      type: Boolean,
      default: false,
    },
    reorderingInvoiceId: {
      type: [String, Number],
      default: null,
    },
    selectedForReorder: {
      type: Array,
      default: () => [],
    },
    newQuantities: {
      type: Object,
      default: () => ({}),
    },
    headingLength: {
      type: Number,
      required: true,
    },
    hasBeenLoaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openDetailed: [],
      checkedRows: [],
      activeQuoteGroups: [],
      openedQuoteDetails: [],
      openedQuoteDetailsActiveGroup: null,
      loadMoreLoading: [],
      firstLoad: true,
      updatedTableData: null,
    };
  },

  computed: {
    ...mapState(QUOTES_MODULE, {
      REQUESTED_QUOTE_INFO,
    }),

    rfqCount() {
      return this.type === 'quotes' ? 'active_rfq_count' : 'rfq_count';
    },
  },

  watch: {
    tableData: {
      handler(nv) {
        this.firstLoad = false;
        this.openDetailed = nv.map((order) => order.id);
        if (this.type === 'quotes' && this.tableData?.length) this.handleActiveQuotes();
        this.updatedTableData = nv;
      },
      immediate: true,
    },
  },

  methods: {
    hasLoadMore(table) {
      return table[this.rfqCount] > (this.cleanOrderRfq(table) && this.cleanOrderRfq(table).length);
    },

    hasPendingRfqs(table) {
      const updatedListing = this[REQUESTED_QUOTE_INFO]?.filter((qInfo) => qInfo.id === table.id);

      if (!updatedListing?.length
        || updatedListing[0]?.rfqs_count === table.rfqCreatedEventCount) return 0;
      return updatedListing[0]?.rfqs_count;
    },

    pendingSkeletons(table) {
      const numberBeforeLoadMore = 5;
      const updatedListing = this[REQUESTED_QUOTE_INFO]?.filter((qInfo) => qInfo.id === table.id);

      if (!updatedListing?.length
        || table.rfqs?.length >= numberBeforeLoadMore) return 0;

      if (updatedListing[0]?.rfqs_count <= numberBeforeLoadMore) {
        return updatedListing[0]?.rfqs_count - table.rfqs?.length;
      }

      return numberBeforeLoadMore - table.rfqs?.length;
    },

    cleanOrderRfq(rfq) {
      return (rfq.rfqs || rfq.last_5_rfqs);
    },

    handleRowClick(order) {
      const idx = this.openDetailed.indexOf(order);
      if (idx === -1) {
        this.openDetailed.push(order);
      } else {
        this.openDetailed.splice(idx, 1);
      }
    },

    handleLoadMoreClick(id, i) {
      this.loadMoreLoading = this.updatedTableData?.map(() => false);
      this.loadMoreLoading[i] = true;
      this.$emit('load-more', { id, i });
    },

    handleLoadMoreLoading(status, i) {
      this.loadMoreLoading[i] = status;
    },

    startReorder({ order, sameVendor }) {
      this.openDetailed = [order.id];
      this.$emit('start-reorder', { order, sameVendor });
    },

    handleActiveQuotes() {
      let groups = [];
      this.tableData.forEach((table) => {
        if (!table.quoteGroups.length) return;
        groups = [...groups, { id: table.id, index: 0 }];
      });
      this.activeQuoteGroups = groups;
    },

    handleActiveQuoteGroup({ listingId, index }) {
      this.activeQuoteGroups.find((q) => q.id === listingId).index = index;
    },

    handleOpenQuoteDetails({ openDetailed, activeGroup }) {
      this.openedQuoteDetails = openDetailed;
      this.openedQuoteDetailsActiveGroup = activeGroup;
    },

    tdStyle(table) {
      const style = {
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        fontSize: '0.75rem',
      };
      if (this.openDetailed.includes(table.id)) {
        return {
          ...style,
          backgroundColor: '#fafafa',
          boxShadow: window.innerWidth > 1024 ? 'inset 0 10px 10px -10px rgba(0, 0, 0, 0.2)' : '',
        };
      }
      return style;
    },

    async handleCancelled({ tableId, rfqHash }) {
      let fetchPage = false;
      const tD = this.updatedTableData.map((table) => {
        if (table.id !== tableId) return table;

        const filteredRfqs = table.rfqs.filter((r) => r.hash !== rfqHash);
        if (filteredRfqs?.length) {
          return { ...table, rfqs: filteredRfqs, active_rfq_count: table.active_rfq_count - 1 };
        }

        fetchPage = true;
        return null;
      });
      this.updatedTableData = tD.filter((t) => t);

      if (fetchPage) this.$emit('cancelled');
    },
  },
};
</script>

<style lang="scss">
table.table {
  border-collapse: separate;

  .info-block {
    padding: 0;
  }

  .quote-table-row,
  .order-table-row {
    td {
      vertical-align: middle;
    }
  }

  .loading-wheel {
    vertical-align: middle;
  }

  .quote-table-row:hover {
    background-color: #fafafa !important;
  }
}
</style>
