export const SETUP = 'SETUP';
export const CHAT_MESSAGE_CREATED = 'CHAT_MESSAGE_CREATED';
export const WEBSOCKET_TEST_EVENT = 'WEBSOCKET_TEST_EVENT';
export const CART_PAYMENT_SUCCESSFUL_EVENT = 'CART_PAYMENT_SUCCESSFUL_EVENT';
export const CART_PAYMENT_FAILED_EVENT = 'CART_PAYMENT_FAILED_EVENT';
export const QUOTE_STATUS_CHANGED_EVENT = 'QUOTE_STATUS_CHANGED_EVENT';
export const ORDERS_CREATION_FAILED_EVENT = 'ORDERS_CREATION_FAILED_EVENT';
export const ORDERS_CREATION_SUCCEEDED_EVENT = 'ORDERS_CREATION_SUCCEEDED_EVENT';
export const RFQ_STATUS_CHANGED = 'RFQ_STATUS_CHANGED';
export const WEBSOCKET_MODULE = 'WEBSOCKET_MODULE';
export const UPDATE_UPLOADS = 'UPDATE_UPLOADS';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const UPDATE_QUOTE_STATUS = 'UPDATE_QUOTE_STATUS';
export const SET_MODEL_PARSE_FAILED = 'SET_MODEL_PARSE_FAILED';
export const RFQ_CREATED_EVENT = 'RFQ_CREATED_EVENT';
export const BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT = 'BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT';
export const BATCH_RFQ_SUBMISSION_FAILED_EVENT = 'BATCH_RFQ_SUBMISSION_FAILED_EVENT';
export const BATCH_RFQ_UPDATE_SUCCEEDED_EVENT = 'BATCH_RFQ_UPDATE_SUCCEEDED_EVENT';
export const BATCH_RFQ_UPDATE_FAILED_EVENT = 'BATCH_RFQ_UPDATE_FAILED_EVENT';
export const RFQ_UPDATED_EVENT = 'RFQ_UPDATED_EVENT';
export const UPDATE_DRAFT_RFQ = 'UPDATE_DRAFT_RFQ';
