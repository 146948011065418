<template>
  <BDropdown
    position="is-bottom-left"
    @click.native.stop
  >
    <template #trigger>
      <BButton
        size="is-small"
        icon-left="ellipsis-h"
        class="option-button"
      />
    </template>
    <BDropdownItem
      v-for="item in options"
      :key="item.label"
      :has-link="['a', 'RouterLink'].includes(item.component)"
      class="dropdown-item is-clickable"
    >
      <Component
        :is="!item.hasLink ? 'div' : 'RouterLink'"
        class="is-flex is-flex-align-centered py-2"
        v-bind="item.bind"
        v-on="item.on"
      >
        <div
          style="flex: none; width: 1rem; margin-bottom: -1px;"
          class="mr-3"
        >
          <BIcon
            :icon="item.icon"
            pack="fas"
          />
        </div>
        <p class="has-text-left is-flex-grow-1">
          {{ item.label }}
        </p>
      </Component>
    </BDropdownItem>
  </BDropdown>
</template>
<script>

import { mapActions } from 'vuex';
import { CREATE } from '@/app-buyer/store/modules/types';
import Api from '@/app-buyer/api/api';
import CancelModal from '@/app-buyer/components/rfq-list/CancelModal.vue';
import modifyMixin from '../../mixins/modify-mixin';

export default {
  name: 'QuoteListingOptions',
  mixins: [modifyMixin],

  props: {
    listing: {
      type: Object,
      required: true,
    },
    hasLoadMore: {
      type: Boolean,
      default: false,
    },
    hasBeenLoaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      shouldOpenRR: false,
      options: [
        {
          icon: 'eye',
          label: 'View parts in project',
          component: 'RouterLink',
          hasLink: false,
          on: {
            click: () => {
              this.redirectToSelectedDraft({
                project_hash: this.listing.rfqs[0].project_hash,
                draft_hash: this.listing.rfqs[0].draft_hash,
              });
            },
          },
        },

        {
          icon: 'cog',
          label: 'Revise & Re-quote',
          component: 'RouterLink',
          hasLink: false,
          on: {
            click: () => {
              this.handleRRClick();
            },
          },
        },

        {
          icon: 'comment-dots',
          label: 'Chat about these quotes',
          on: {
            click: () => {
              Intercom('showNewMessage');
            },
          },
        },

        {
          icon: 'trash',
          label: 'Cancel & remove group',
          on: {
            click: () => {
              const modal = this.$buefy.modal.open({
                component: CancelModal,
                props: {
                  cancelLabel: 'group',
                },
                events: {
                  cancel: (cancelReason, explanation) => Api.post(
                    `api/listings/${this.listing.id}`,
                    {
                      cancel_reason: `${cancelReason.val}${explanation ? `. ${explanation}` : ''}`,
                      _method: 'DELETE',
                    },
                  ).then(() => {
                    this.$emit('canceled');
                    modal.close();
                  }),
                },
                hasModalCard: true,
              });
            },
          },
        },
      ],
    };
  },

  watch: {
    hasBeenLoaded(nv, ov) {
      if (nv && nv !== ov && this.shouldOpenRR) {
        this.handleReviseRequote({
          selectedRfqs: this.listing?.rfqs.map((rfqs) => rfqs.hash),
          rfqs: this.listing?.rfqs,
          listingHash: this.listing.id,
        });
        this.shouldOpenRR = false;
      }
    },
  },

  methods: {

    handleRRClick() {
      if (!this.hasLoadMore) {
        this.handleReviseRequote({
          selectedRfqs: this.listing?.rfqs.map((rfqs) => rfqs.hash),
          rfqs: this.listing?.rfqs,
          listingHash: this.listing.id,
        });
      } else {
        this.shouldOpenRR = true;
        this.$emit('load-more', { id: this.listing.id });
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.dropdown-item:hover {
  background-color: whitesmoke;
  color: #0a0a0a;
}
</style>
