<template>
  <div class="tw-flex tw-items-center">
    <g-dropdown
      isInverted
      :isFullWidth="!IS_DESKTOP"
      :size="isAdminStyle ? 'sm' : 'md'"
      :color="isAdminStyle ? 'grey-dark' : 'tertiary'"
      :fontWeight="isAdminStyle ? 'normal' : 'bold'"
      ref="actionsDropdown"
      label="Actions"
      iconLeft="chevron-down"
      :class="{ 'admin-dropdown' : isAdminStyle }"
    >
      <div class="dropdown-content tw-px-1 tw-py-01">
        <g-button
          v-if="IS_DESKTOP"
          isFullWidth
          :isInverted="!isAdminStyle"
          :label="selectPartsBtnText"
          :color="isAdminStyle ? 'white' : 'tertiary'"
          type="primary-icon"
          iconLeft="mouse-pointer"
          fontWeight="normal"
          size="sm"
          style="justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;"
          @click="handleSelectParts"
        />
        <g-button
          v-if="SELECTED_PART_HASHES.length"
          isFullWidth
          :isInverted="!isAdminStyle"
          :label="deleteSelectedBtnText"
          :color="isAdminStyle ? 'white' : 'tertiary'"
          type="primary-icon"
          iconLeft="trash"
          fontWeight="normal"
          size="sm"
          style="justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;"
          @click="handleDeleteSelected"
        />
        <g-button
          v-if="IS_DESKTOP && SELECTED_PART_HASHES?.length === 1"
          isFullWidth
          :isInverted="!isAdminStyle"
          :color="isAdminStyle ? 'white' : 'tertiary'"
          label="Upload revision"
          type="primary-icon"
          iconLeft="edit"
          fontWeight="normal"
          size="sm"
          style="justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;"
          @click="handleUploadRevision"
        />
        <g-button
          v-if="SELECTED_PART_HASHES.length"
          isFullWidth
          :isInverted="!isAdminStyle"
          :label="moveSelectedBtnText"
          :color="isAdminStyle ? 'white' : 'tertiary'"
          type="primary-icon"
          iconLeft="arrow-to-right"
          fontWeight="normal"
          size="sm"
          style="justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;"
          @click="moveSelected"
        />
        <dfm-modal
          v-if="!IS_DESKTOP && part"
          :part="part"
          :is-analysing="isAnalysing"
          :is-actions-dropdown="true"
        />
        <single-quote-notes-modal
          v-if="hasQuoteNotes && !IS_DESKTOP && part"
          ref="singleQuoteNotesModal"
          :part="part"
          :is-actions-dropdown="true"
        />
      </div>
    </g-dropdown>

    <input
      v-show="false"
      ref="fileInput"
      type="file"
      @input="updateModelFile"
    >

    <b-modal
      v-model="modalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal
      width="410px"
      class="dialog"
    >
      <part-table-bulk-operation-option
        :selected-parts="selectedParts"
        @selected-option="optionSelected = $event"
        @close-modal="modalActive = $event"
        @moveRfqsToProject="moveRfqsToProject"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  BATCH_DELETE,
  DRAFT_RFQS,
  MOVE_RFQ,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
  SORT_BY,
  UPDATE_DRAFT,
} from '@/app-buyer/store/modules/rfq/types';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableBulkOperationOption
  from '@/app-buyer/components/project/PartTableBulkOperationOption.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GDropdown from '@common/components/storied/molecules/GDropdown.vue';
import {
  GET_MRFQ_PROJECTS,
  GET_UPDATED_PROJECTS_MRFQ,
  PROJECT_MODULE,
  RESET_PROJECTS_MRFQ,
} from '@/app-buyer/store/modules/projects/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import DfmModal from '@/app-buyer/components/project/DfmModal.vue';
import SingleQuoteNotesModal from '@/app-buyer/components/project/SingleQuoteNotesModal.vue';

export default {
  name: 'PartActions',

  components: {
    SingleQuoteNotesModal,
    DfmModal,
    PartTableBulkOperationOption,
    GButton,
    GDropdown,
  },

  props: {
    part: {
      type: Object,
      required: false,
      default: null,
    },
    isAdminStyle: {
      type: Boolean,
      default: true,
    },
    isAnalysing: {
      type: Boolean,
      default: false,
    },
    hasQuoteNotes: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      renderKey: 0,
      modalActive: false,
      optionSelected: '',
    };
  },

  mixins: [
    partSelectMixin,
  ],

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
      SORT_BY,
    }),

    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    someSelected() {
      return !this.allDraftsSelected
        && this[DRAFT_RFQS].some((d) => this[SELECTED_PART_HASHES].includes(d.hash));
    },

    allDraftsSelected: {
      get() {
        return this[DRAFT_RFQS].every((d) => this[SELECTED_PART_HASHES].includes(d.hash));
      },
      set(value) {
        const { someSelected } = this;
        const newHashes = (value && !someSelected) ? this[DRAFT_RFQS].map((d) => d.hash) : [];
        this.selectParts(...newHashes);
        if (someSelected) {
          this.$nextTick(() => this.renderKey++);
        }
      },
    },

    allPartsSelected() {
      return this.selectedParts?.length === this[DRAFT_RFQS]?.length;
    },

    selectPartsBtnText() {
      return this.allPartsSelected ? `Deselect all parts (${this.selectedParts?.length})` : `Select all parts (${this[DRAFT_RFQS]?.length})`;
    },

    deleteSelectedBtnText() {
      if (this[IS_DESKTOP]) return `Remove selected parts (${this.selectedParts?.length})`;
      return 'Remove part';
    },

    moveSelectedBtnText() {
      if (this[IS_DESKTOP]) return `Move parts to project (${this.selectedParts?.length})`;
      return 'Move part to project';

    },

    selectedParts() {
      if (this.part) return [this.part];
      else return this[DRAFT_RFQS].filter((part) => this[SELECTED_PART_HASHES].includes(part.hash));
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      BATCH_DELETE,
      MOVE_RFQ,
      GET_UPDATED_PROJECTS_MRFQ,
      UPDATE_DRAFT,
    }),

    ...mapActions(PROJECT_MODULE, {
      GET_MRFQ_PROJECTS,
    }),

    ...mapMutations(PROJECT_MODULE, {
      RESET_PROJECTS_MRFQ,
    }),

    handleSelectParts() {
      if (this.allPartsSelected) {
        this.selectParts(...[]);
      } else {
        this.selectParts(...this[DRAFT_RFQS].map((part) => part.hash));
      }
      this.$refs.actionsDropdown.handleClose();
    },

    handleDeleteSelected() {
      if (this.selectedParts?.length > 0) {
        this.$buefy.dialog.confirm({
          message: `Are you sure you want to <b>remove</b> ${this.selectedParts?.length === 1
            ? `this ${this.selectedParts?.length} part?`
            : `these ${this.selectedParts?.length} parts?`} `,
          confirmText: 'Remove',
          onConfirm: () => {
            this[BATCH_DELETE](this.selectedParts);
          },
          type: 'is-danger',
        });
      }
    },

    async moveSelected() {
      if (this.selectedParts?.length > 0) {
        this[RESET_PROJECTS_MRFQ]();
        await this[GET_MRFQ_PROJECTS]({});
        this.modalActive = true;
      }
    },

    moveRfqsToProject() {
      const toHash = this.optionSelected?.hash;
      this.selectedParts.forEach((part) => {
        const { hash } = part;
        this[MOVE_RFQ]({
          hash, to: toHash, isDraft: true,
        });
      });
      this[GET_UPDATED_PROJECTS_MRFQ]({ to: toHash });
      this.modalActive = false;
    },

    handleUploadRevision() {
      if (this.$refs.fileInput) this.$refs.fileInput.click();
    },

    async updateModelFile(event) {
      const modelFile = event?.target?.files?.[0];
      if (!modelFile) return;
      const properties = { ...this.selectedParts[0].configuration };
      properties['file-type'] = this.selectedParts[0]?.configuration?.['file-type'];
      if (properties['file-type']) {
        properties.project_hash = this.selectedParts[0].project_hash;
        properties.name = modelFile.name;
        await this[UPDATE_DRAFT]({
          draft: this.selectedParts[0],
          properties,
          files: {
            modelFile,
          },
          immediate: true,
        });

        // SEGMENT TRACKING
        if (getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.track('Model file modified', {
            isMasquerading: !!this[MASQUERADING],
            projectHash: this.selectedParts[0].project_hash,
            draftRfq: {
              name: modelFile.name,
              draftHash: this.selectedParts[0].hash,
            },
          });
        }
      }
    },

  },
};
</script>

<style
  lang="scss"
  scoped
>
.dropdown-content {
  min-width: 12rem;

  @screen xl {
    min-width: 14rem;
  }
}

.admin-dropdown {
  &.g-dropdown {
    ::v-deep {
      button {
        border-color: theme('colors.grey-light') !important;
      }
    }
  }

  .dropdown-content {
    ::v-deep {
      button {
        border-color: theme('colors.transparent') !important;
      }
    }
  }
  ::v-deep {
    button {
      background-color: theme('colors.white') !important;
    }

    span {
      color: theme('colors.primary');
    }
  }
}
</style>
