<template>
  <label
    class="tw-flex tw-items-center tw-justify-between tw-mb-0 tw-whitespace-nowrap tw-text-primary tw-cursor-pointer"
    :class="[{ 'tw-w-full' : isFullWidth }, toggleSize]"
  >
    <template v-if="isLabelLeft">
      <slot/>
    </template>
    <input
      v-model="localChecked"
      type="checkbox"
      class="tw-opacity-0 tw-w-0 tw-h-0"
      @change="handleChange"
    >
    <span
      class="switch tw-relative tw-inline-block tw-mb-0 tw-text-md"
      :class="{ 'tw-mr-1' : label, 'checked' : localChecked }"
    >
      <span class="slider tw-absolute tw-cursor-pointer tw-inset-0 tw-transition-all tw-rounded-lg"/>
    </span>
    <template v-if="!isLabelLeft">
      <slot/>
    </template>
  </label>
</template>

<script>
export default {
  name: 'GToggle',
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isLabelLeft: {
      type: Boolean,
      default: true,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
  },

  data() {
    return {
      localChecked: false,
    }
  },

  watch: {
    isChecked: {
      handler(val) {
        this.localChecked = val;
      },
      immediate: true,
    },
  },

  computed: {
    toggleSize() {
      return `size-${this.size} tw-text-${this.size}`
    },
  },

  methods: {
    handleChange() {
      this.$emit('change', this.localChecked);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.slider {
  background-color: theme('colors.grey-darkest');
}

.slider:before {
  position: absolute;
  content: "";
  background-color: #FFFFFF;
  transition: .3s;
  border-radius: 50%;
}

.checked {
  .slider {
    background-color: theme('colors.tertiary');
  }
}

input:focus {
  .slider {
    box-shadow: 0 0 1px theme('colors.tertiary');
  }
}

.size-sm {
  .switch {
    width: 32px;
    height: 16px;
  }

  .slider:before {
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 2px;
  }

  .checked {
    .slider:before {
      transform: translateX(12px);
    }
  }
}
.size-md {
  .switch {
    width: 44px;
    height: 24px;
  }

  .slider:before {
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
  }

  .checked {
    .slider:before {
      transform: translateX(20px);
    }
  }
}
.size-lg {
  .switch {
    width: 54px;
    height: 30px;
  }

  .slider:before {
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
  }

  .checked {
    .slider:before {
      transform: translateX(24px);
    }
  }
}

</style>
