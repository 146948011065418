<template>
  <div
    class="tw-relative part-supporting-wrapper"
    :class="btnType === 'info' && 'tw-py-2'"
  >
    <p v-if="!fromError" class="tw-text-sm">Supporting files:</p>
    <input
      v-show="false"
      ref="supporting-file-input"
      :accept="acceptableSupportingFiles"
      multiple
      type="file"
      @input="handleNewSupportingFile($event.target.files)"
    >
    <PartSupportingFiles
      v-if="hasSupportingFiles"
      :part="part"
      :supporting-files="supportingFiles"
      @add-more-files="$refs['supporting-file-input'].click()"
      @shift-select="$emit('shift-select')"
    />
    <PartSupportingConfiguration
      v-else-if="hasConfiguration3D"
      :loading="removingSpecs"
      :part="part"
      @remove="startRemoveSpecifications"
    />
    <div v-else-if="fromError" class="">
      <g-button
        :color="btnType"
        :label="btnText"
        :iconLeft="btnType === 'tertiary' ? 'file-pdf' : ''"
        type="primary-icon"
        isInverted
        size="sm"
        class="tw-justify-center tw-whitespace-nowrap"
        @click="$refs['supporting-file-input'].click()"
      />
    </div>
    <div
      v-else-if="!loading"
      class="tw-flex tw-flex-col"
    >
      <div class="">
        <button
          class="tw-text-tertiary tw-mt-1 tw-text-sm tw-font-medium"
          @click="$refs['supporting-file-input'].click()"
          :disabled="part.cart_item_id"
        >
          <font-awesome-icon
            icon="plus"
            class="tw-mr-1"
          />
          <span class="tw-text-tertiary">Add PDF file</span>
        </button>
      </div>
      <div
        v-if="canBeConfigured3D && showEditor"
      >
        <button
          class="tw-text-tertiary tw-mt-1 tw-text-sm tw-font-medium"
          @click="open3DEditor"
          :disabled="part.cart_item_id"
        >
          <font-awesome-icon
            icon="cube"
            class="tw-mr-1"
          />
          <span class="tw-text-tertiary">Use 3D Tool</span>
        </button>
      </div>
    </div>
    <div
      v-else-if="loading"
      class="button-group tw-w-40"
    >
      <b-button
        :type="loading ? 'info' : ''"
        class="tw-py-1 tw-justify-center"
        disabled
        expanded
        rounded
        size="small"
        style="font-size: 13px; white-space: nowrap; height: 1.6rem"
      >
        {{ loading ? 'Uploading files' : 'Not required' }}
      </b-button>
    </div>
    <PartSupportingDropArea
      v-if="!hasConfiguration3D"
      :part="part"
      @upload="handleNewSupportingFile"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import PartSupportingFiles from '@/app-buyer/components/project/PartSupportingFiles.vue';
import PartSupportingConfiguration
  from '@/app-buyer/components/project/PartSupportingConfiguration.vue';
import { DRAFT_RFQ_SUPPORTING_FILE, RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import { APPEND_FILE, RFQ_MODULE, SET_VIEWED } from '@/app-buyer/store/modules/rfq/types';
import { getAcceptableUploads, part3DconfigMixin } from '@/app-buyer/components/project/mixins';
import PartSupportingDropArea from '@/app-buyer/components/project/PartSupportingDropArea.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import NewSupportingFileChoiceModal from '@/app-buyer/components/NewSupportingFileChoiceModal.vue';

export default {
  name: 'PartSupporting',

  components: {
    PartSupportingDropArea,
    PartSupportingConfiguration,
    PartSupportingFiles,
    GButton,
  },

  mixins: [part3DconfigMixin, getAcceptableUploads],

  props: {
    part: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      default: 'Add supporting file',
    },
    btnType: {
      type: String,
      default: 'tertiary',
    },
    showEditor: {
      type: Boolean,
      default: true,
    },
    fromError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      buefyModal: null,
    };
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    supportingFiles() {
      return this.part?.uploads?.filter((u) => [RFQ_SUPPORTING_FILE, DRAFT_RFQ_SUPPORTING_FILE].includes(u.type.slug));
    },
  },

  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),
    ...mapActions(RFQ_MODULE, {
      APPEND_FILE,
    }),

    handleNewSupportingFile(eventFiles) {
      if (
        ! ['awaiting', 'manual'].includes(this.part.status)
        && ! (this.part.status === 'quoted' && this.part.quote_rfq_type === 'manual')
      ) {
        this.uploadSupportingFiles(eventFiles)
        return
      }

      this.buefyModal = this.$buefy.modal.open({
        parent: this,
        component: NewSupportingFileChoiceModal,
        width: '600px',
        hasModalCard: true,
        trapFocus: true,
        events: {
          'overwrite': () => this.handleOverwrite(eventFiles),
          'duplicate': () => this.handleDuplicateWithFile(eventFiles),
        },
        onCancel: () => this.$refs['supporting-file-input'].value = null,
      });
    },

    handleOverwrite(event) {
      this.uploadSupportingFiles(event)
      // TODO: Set status back to "manual quote needed"
      this.buefyModal.close()
    },

    handleDuplicateWithFile(event) {
      this.$emit('duplicate-with-file', event)
      this.buefyModal.close()
    },

    async uploadSupportingFiles(eventFiles) {
      this.loading = true;
      try {
        const files = [].slice.apply(eventFiles);
        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
          // eslint-disable-next-line no-await-in-loop
          await this[APPEND_FILE]({
            draft: this.part,
            file,
          });
        }
      } finally {
        this.loading = false;
      }
    },

    open3DEditor() {
      this[SET_VIEWED](this.part);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

::v-deep {
  .upload-button {
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;

    span.is-absolute {
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0;
    }

    &:hover span.is-absolute {
      opacity: 1;
    }
  }

  button:disabled {
    opacity: 0.5;
  }

  .button-group {
    .button:disabled {
      color: rgba(1, 1, 1, 0.4);
      opacity: 1;
    }
  }
}

.default-styling {
  ::v-deep {
    .button-group {
      width: 210px;
    }
  }
}
</style>
