<template>
  <div
    id="buyer-app"
    class="modal-card"
  >
    <div class="modal-card-body tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs tw-flex tw-flex-col">
      <div class="tw-flex tw-items-start tw-mb-5">
        <div>
          <font-awesome-icon
            icon="exclamation-circle"
            class="tw-h-6 tw-w-6 tw-text-pending tw-self-center tw-mr-3"
          />
        </div>
        <strong class="tw-text-md">Adding a supporting file requires a new manual quote. You can overwrite your requested
          quote or create a new quote by duplicating the part.</strong>
      </div>
      <div class="tw-self-end tw-flex">
        <g-button
          label="Overwrite quote"
          :has-border="true"
          :is-inverted="true"
          color="tertiary"
          class="tw-mr-3"
          @click="$emit('overwrite')"
        />
        <g-button
          label="Duplicate part"
          @click="$emit('duplicate')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'NewSupportingFileChoiceModal',
  components: { GButton },
}
</script>

<style
  scoped
  lang="scss"
>

</style>
