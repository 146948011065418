<template>
  <div
    class="extras-wrapper tw-flex tw-flex-col xl:tw-grid xl:tw-gap-y-4"
    :class="[isReadOnly ? 'tw-border-b tw-border-grey-light tw-pt-3 tw-pb-3' : 'tw-mb-4']"
  >
    <!--    Inspection and Certificates-->
    <p class="tw-font-bold tw-text-sm tw-mb-2 tw-mr-2 xl:tw-mr-2 xl:tw-mb-0 xl:tw-self-center">
      Inspection & Certificates
    </p>

    <div
      v-if="!isReadOnly"
      class="tw-flex tw-items-center tw-justify-between tw-text-md tw-bg-white tw-border tw-rounded-xs tw-border-grey-light tw-p-2 tw-cursor-pointer"
      @click.self="showInspectionModal = true"
    >

      <div
        class="tw-flex tw-gap-1 tw-items-center tw-flex-wrap"
        @click.self="showInspectionModal = true"
      >
        <span
          v-if="!(Object.keys(configurations).length === 1 || isSameCertificates)"
          class="tw-text-sm tw-font-bold tw-text-grey-dark tw-pr-1"
        >
          Multiple selected
        </span>
        <template v-if="Object.keys(configurations).length === 1 || isSameCertificates">
          <g-tag
            :key="`inspection-preview-${selectedInspection.slug}`"
            :is-closable="selectedInspection.slug !== 'standard-inspection' && !isLocked"
            :label="`${selectedInspection.name} ${selectedInspection.slug === 'standard-inspection' ? '(Default)' : ''} `"
            class="tw-cursor-default"
            @close="setStandardInspection"
            @click.stop
          />
          <g-tag
            v-for="(certificate, i) in selectedCertificates"
            is-closable
            :key="`extra-addon-preview-${i}`"
            :label="certificate.name"
            class="tw-cursor-default"
            @close="removeExtraItem(certificate.id)"
            @click.stop
          />
        </template>

      </div>

      <div
        class="tw-flex tw-justify-between"
        @click="showInspectionModal = true"
      >
        <div class="tw-ml-1">
          <font-awesome-icon
            icon="plus"
            class="tw-text-sm"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-sm tw-flex tw-flex-col tw-justify-center"
    >
      <template v-if="readOnlyCertificateInspectionNames?.length">
        <p
          v-for="name in formatValues(readOnlyCertificateInspectionNames)"
          :key="name"
          class=""
        >{{ name }}</p>
      </template>
      <template v-else>
        None selected
      </template>
    </div>

    <div v-if="isReadOnly" class="tw-col-span-2 tw-border-b tw-border-grey-light"></div>


    <!--    Part Making And Screws-->
    <p class="tw-font-bold tw-text-sm tw-mt-4 tw-mb-2 tw-mr-2 xl:tw-my-0 xl:tw-self-center">
      Engraving, Marking & Printing
    </p>

    <div
      v-if="!isReadOnly"
      class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-text-sm tw-border tw-rounded-xs tw-border-grey-light tw-p-2 tw-cursor-pointer"
      @click.self="showOtherExtrasModal = true"
    >

      <div
        v-if="selectedPartMakingAndScrews.length"
        class="tw-flex tw-gap-1 tw-items-center tw-flex-wrap"
        @click.self="showOtherExtrasModal = true"
      >
        <template v-if="Object.keys(configurations).length === 1 || isSamePartMakingAndScrews">
          <g-tag
            v-for="(item, i) in selectedPartMakingAndScrews"
            :key="`part-making-screws-preview-${i}`"
            is-closable
            :label="item.name"
            class="tw-cursor-default"
            @close="removeExtraItem(item.id)"
          />
        </template>
        <span
          v-else
          class="tw-text-sm tw-font-bold tw-text-grey-dark tw-pr-1"
        >
          Multiple selected
        </span>
      </div>
      <div
        v-else
        @click="showOtherExtrasModal = true"
      >None selected
      </div>

      <div
        class="tw-flex tw-justify-between"
        @click="showOtherExtrasModal = true"
      >
        <div class="tw-ml-1">
          <font-awesome-icon icon="plus" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-text-sm tw-flex tw-flex-col tw-justify-center"
    >
      <template v-if="readOnlyPartMarkingScrewsNames?.length">
        <p
          v-for="name in formatValues(readOnlyPartMarkingScrewsNames)"
          :key="name"
          class=""
        >{{ name }}</p>
      </template>
      <template v-else>
        None selected
      </template>
    </div>

    <g-modal
      :is-active.sync="showInspectionModal"
      moda-width="90vw"
      modal-height="90vh"
      :can-cancel="['escape']"
    >
      <GInspectionModal
        :configurations="configurations"
        :inspections="inspections"
        :selectedInspection="selectedInspection"
        :certificates="certificateStandards"
        :selectedCertificatesInitialIds="selectedCertificatesIds"
        :locked-configurations="lockedConfigurations"
        :lead-hash="leadHash"
        :lead-config="leadConfig"
        :is-same-inspection="isSameInspection"
        :is-same-certificates="isSameCertificates"
        @effect="$emit('effect', $event)"
        @close="showInspectionModal = false"
        @update-config="$emit('update-config', $event)"
        @start-update="startUpdate"
      />
    </g-modal>
    <g-modal
      :is-active.sync="showOtherExtrasModal"
      modal-width="88vw"
      :can-cancel="['escape']"
    >
      <GOtherExtrasModal
        :configurations="configurations"
        :locked-configurations="lockedConfigurations"
        :lead-hash="leadHash"
        :lead-config="leadConfig"
        :is-same-part-making-and-screws="isSamePartMakingAndScrews"
        @effect="$emit('effect', $event)"
        @close="showOtherExtrasModal = false"
        @update-config="$emit('update-config', $event)"
      />
    </g-modal>
  </div>
</template>
<script>
import InspectionModal from '@/app-buyer/components/project/InspectionModal.vue';
import GInspectionModal from '@/app-buyer/components/InspectionModal.vue';
import OtherExtrasModal from '@/app-buyer/components/project/OtherExtrasModal.vue';
import GModal from '@/app-buyer/components/GModal';
import GOtherExtrasModal from '@/app-buyer/components/OtherExtrasModal.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import GTag from '@common/components/storied/atoms/GTag.vue';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import {
  ADDONS,
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_PDF_REQUIRED,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { mapGetters, mapState } from 'vuex';
import { CERTIFICATE_STANDARD, PART_MARKING, SCREWS } from '@/shared/consts/slugs';

export default {
  name: 'PartFormDraftConfiguratorExtras',
  components: {
    OtherExtrasModal,
    InspectionModal,
    GInspectionModal,
    GModal,
    GOtherExtrasModal,
    FontAwesomeIcon,
    GTag,
  },
  mixins: [safeMultipleUpdate],
  props: {
    leadHash: {
      type: String,
      default: null,
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInspectionModal: false,
      showOtherExtrasModal: false,
      selectedStandardsIds: [],
    };
  },
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_PDF_REQUIRED,
      REFERENCE_DATA_OBJECT_BY_ID,
    }),
    values() {
      // Get all values from configurations
      const allValues = Object.values(this.configurations).map((conf) => conf.inspection);
      // Remove duplicate values
      return [...new Set(allValues)];
    },
    inspections() {
      const insp = this[REFERENCE_DATA]?.filter((r) => r.entity_slug === 'inspection' || r.entity_slug === 'video-inspection');
      return [
        insp.find((i) => i.slug === 'standard-inspection'),
        ...insp.filter((i) => i.slug !== 'standard-inspection' && i.slug !== '360-video-inspection'),
      ].filter(Boolean) || [];
    },
    filteredAddons() {
      return this[ADDONS]?.map((a) => {
        const children = this[REFERENCE_DATA]?.filter((p) => p.parent_ids.includes(a.id));
        return {
          ...a,
          children: children.filter((c) => this.checkParents(c.id, this.leadConfig)),
        };
      });
    },
    filterISO() {
      return this[REFERENCE_DATA]?.filter((r) => r.entity_slug.includes('iso-')) || [];
    },
    certificateStandards() {
      const video360 = this[REFERENCE_DATA]?.filter((r) => r.entity_slug === 'video-inspection').find((i) => i.slug === '360-video-inspection');
      let addonsList = this.filteredAddons?.find((e) => e.slug === CERTIFICATE_STANDARD)?.children || [];
      if (video360) {
        addonsList = [...addonsList, video360];
      }

      return [...addonsList, ...this.filterISO];
    },
    partMarkings() {
      return this.filteredAddons?.find((e) => e.slug === PART_MARKING)?.children || [];
    },
    screws() {
      return this.filteredAddons?.find((e) => e.slug === SCREWS)?.children || [];
    },
    allAddonOptions() {
      return [
        ...this.partMarkings,
        ...this.certificateStandards,
        ...this.screws,
      ];
    },
    leadConfig() {
      return this.configurations?.[this.leadHash];
    },
    selectedInspection() {
      return this.inspections.find(inspection => inspection.id === this.configurations[this.leadHash].inspection) || {};
    },
    selectedCertificates() {
      return this.certificateStandards.filter(item => Object.values(this.leadConfig).includes(item.id))
    },
    selectedCertificatesIds() {
      return this.selectedCertificates.map(item => item.id)
    },
    selectedPartMakingAndScrews() {
      return [...this.partMarkings, ...this.screws].filter(item => Object.values(this.leadConfig).includes(item.id))
    },
    isSameInspection() {
      return new Set(Object.values(this.configurations).map(config => config.inspection)).size === 1;
    },
    isSameCertificates() {
      const addonKeys = this.certificateStandards.map(item => item.entity_slug);
      const configValues = Object.values(this.configurations);

      return addonKeys.every(key => {
        const firstValue = configValues[0]?.[key] ?? null;
        return configValues.every(config => (config?.[key] ?? null) === firstValue);
      });
    },
    isSamePartMakingAndScrews() {
      const addonKeys = [...this.partMarkings, ...this.screws].map(item => item.entity_slug);
      const configValues = Object.values(this.configurations);

      return addonKeys.every(key => {
        const firstValue = configValues[0]?.[key] ?? null;
        return configValues.every(config => (config?.[key] ?? null) === firstValue);
      });
    },
    isLocked() {
      return this.leadLockedConfig?.inspection?.lockedBy || false;
    },

    readOnlyPartMarkingScrewsNames() {
      const configValues = Object.values(this.configurations)
      const addonKeys = [...this.partMarkings, ...this.screws].map(item => item.entity_slug);
      const names = [];

      configValues.map((config) => {
        Object.keys(config).forEach(key => {
          if (addonKeys.includes(key)) {
            names.push(this[REFERENCE_DATA_OBJECT_BY_ID]?.[config[key]]?.name)
          }
        })
      })

      return names;
    },

    readOnlyCertificateInspectionNames() {
      const configValues = Object.values(this.configurations)
      const addonKeys = this.certificateStandards.map(item => item.entity_slug);
      const names = [];

      configValues.map((config) => {
        Object.keys(config).forEach(key => {
          if (addonKeys.includes(key) || key === 'inspection') {
            names.push(this[REFERENCE_DATA_OBJECT_BY_ID]?.[config[key]]?.name)
          }
        })
      })

      return names;
    },
  },
  watch: {
    leadConfig: {
      handler(val) {
        const addonIds = [...this.certificateStandards, ...this.partMarkings, ...this.screws].map((e) => e.id);
        this.selectedStandardsIds = Object.values(val).filter((v) => addonIds.includes(v));
      },
      immediate: true,
    },
  },
  methods: {
    removeExtraItem(id) {
      const entitySlug = this.allAddonOptions.find((e) => e.id === id)?.entity_slug;
      const toleranceSlug = this[REFERENCE_DATA_OBJECT_BY_ID]?.[id]?.slug;
      const toleranceRequiresPdf = this[REFERENCE_DATA_PDF_REQUIRED]?.[toleranceSlug];

      if (entitySlug && entitySlug === 'ppap-package' && !toleranceRequiresPdf) {
        this.startUpdate(375, { entitySlug: 'inspection', values: [null] });
      }

      if (entitySlug) {
        this.startUpdate(null, { entitySlug, values: [null] });
      }
    },
    setStandardInspection() {
      this.startUpdate(375, { entitySlug: 'inspection' });
    },
    formatValues(values) {
      return Object.entries(values.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {})).map(([key, value]) => `${key} x${value}`);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.extras-wrapper {
  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}

::v-deep {
  .modal-card {
    width: 100%;
  }
}
</style>
