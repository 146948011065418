<template>
  <div class="tw-inline-flex">
    <div class="tw-relative">
      <g-button
        :isFullWidth="isActionsDropdown"
        :isInverted="isActionsDropdown"
        :color="isActionsDropdown ? 'tertiary' : 'transparent'"
        label="View Geomiq note"
        iconLeft="sticky-note"
        type="primary-icon"
        size="sm"
        fontWeight="medium"
        class="chin-btn"
        :style="isActionsDropdown ?'justify-content: flex-start; border-color: transparent; padding-top: 0.75rem; padding-bottom: 0.75rem;' : ''"
        @click="handleOpenQuoteNotesModal"
      />
      <div
        v-if="showQuoteNoteNotification"
        class="quote-note-notification tw-bg-error tw-w-2 tw-h-2 tw-absolute tw-rounded-xl tw-border tw-border-tertiary-light"
        :class="isActionsDropdown ? 'tw-top-3' : 'tw-top-1'"
      ></div>
    </div>
    <g-modal
      :is-active.sync="isOpen"
      :can-cancel="['escape']"
      width="1000"
      modalWidth="tw-w-full"
      modalHeight="tw-h-full"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs tw-w-10/12 xl:tw-w-1/2">
        <div class="tw-flex tw-items-center tw-justify-between tw-pb-3">
          <p class="tw-font-bold tw-text-xl">View Geomiq Note</p>
          <button
            class="tw-text-black tw-text-lg"
            @click="isOpen = false"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>
        <p class="tw-text-md tw-mb-3">You have a Geomiq note about this quote. You need to approve
          it before you can add your part to cart:</p>
        <div
          v-for="note in notes"
          class="tw-w-full tw-rounded-xs tw-bg-grey-lighter tw-italic tw-text-md tw-p-4 tw-mb-1"
        >
          {{ note.content }}
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-mt-6">
          <g-button
            isInverted
            label="Cancel"
            color="tertiary"
            @click="isOpen = false"
          />
          <g-button
            label="Approve note"
            @click="handleApproveQuoteNote"
          />
        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
import GModal from '@/app-buyer/components/GModal';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GSelect from '@common/components/storied/molecules/GSelect.vue';

export default {
  name: 'SingleQuoteNotesModal',

  components: {
    GModal,
    GButton,
    GSelect,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isActionsDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      hasBeenAccepted: false,
    }
  },

  computed: {
    notes() {
      return this.part.quote_notes;
    },

    hasPreviouslyAcceptedNote() {
      return localStorage.getItem(`notes_${this.part.hash}_quote_${this.part.quote_id}`);
    },

    showQuoteNoteNotification() {
      return !this.hasBeenAccepted && !this.hasPreviouslyAcceptedNote
    },
  },

  methods: {
    handleOpenQuoteNotesModal() {
      this.isOpen = !this.isOpen;
    },

    handleApproveQuoteNote() {
      localStorage.setItem(`notes_${this.part.hash}_quote_${this.part.quote_id}`, 'true');
      this.hasBeenAccepted = true;
      this.isOpen = false;
    },

    forceClearNotification() {
      this.hasBeenAccepted = true;
    },
  },
}
</script>


<style
  scoped
  lang="scss"
>
.quote-note-notification {
  left: 6px;
}

::v-deep {
  button {
    &.chin-btn {
      color: theme('colors.tertiary') !important;
    }
  }
}
</style>
