export const dfmInfo = {
  'cnc': [
    {
      title: 'Your Part Can Be Manufactured',
      body: 'Your part has passed all of the manufacturability checks below.',
      icon: 'award',
      isHighlighted: true,
    },
    {
      title: 'Acceptable Minimum Radius',
      body: 'Your part has an acceptable minimum radius on all detected corners.',
      icon: 'check-circle',
    },
    {
      title: 'Singular Body Detected',
      body: 'We have not detected multiple bodies in your file, ensuring it is manufacturable.',
      icon: 'check-circle',
    },
    {
      title: 'No Undercuts Detected',
      body: 'It looks like your part has no undercuts that will impact its manufacturability.',
      icon: 'check-circle',
    },
    {
      title: 'Part Can Be Jigged',
      body: 'Your part is capable of jigging, ensuring a smooth manufacturing process.',
      icon: 'check-circle',
    },
    {
      title: 'All Areas Accessible',
      body: 'Your part features no inaccessible areas that would cause manufacturing issues.',
      icon: 'check-circle',
    },
    {
      title: 'Surface Finish Compatible',
      body: 'Your part is capable of surface finishing, allowing for enhanced aesthetics.',
      icon: 'check-circle',
    },
  ],
  'sheet-metal': [
    {
      title: 'Your Part Can Be Manufactured',
      body: 'Your part has passed all of the manufacturability checks below.',
      icon: 'award',
      isHighlighted: true,
    },
    {
      title: 'File Parsing Complete',
      body: 'Your part file is capable of being made without any manufacturing issues.',
      icon: 'check-circle',
    },
    {
      title: 'Singular Body Detected',
      body: 'We have not detected multiple bodies in your file, ensuring it is manufacturable.',
      icon: 'check-circle',
    },
    {
      title: 'File Type Compatible',
      body: 'Your part is the correct file type for Sheet Metal fabrication.',
      icon: 'check-circle',
    },
    {
      title: 'Bending Requirements Analysed',
      body: 'Your bending requirements have been analysed for Sheet Metal fabrication.',
      icon: 'check-circle',
    },
    {
      title: 'Surface Finish Compatible',
      body: 'Your part is capable of surface finishing, allowing for enhanced aesthetics.',
      icon: 'check-circle',
    },
  ],
  'injection-moulding': [
    {
      title: 'Your Part Can Be Manufactured',
      body: 'Your part has passed all of the manufacturability checks below.',
      icon: 'award',
      isHighlighted: true,
    },
    {
      title: 'File Parsing Complete',
      body: 'Your part file is capable of being Injection Moulded without any manufacturing issues.',
      icon: 'check-circle',
    },
    {
      title: 'Singular Body Detected',
      body: 'We have not detected multiple bodies in your file, ensuring it is manufacturable.',
      icon: 'check-circle',
    },
    {
      title: 'File Type Compatible',
      body: 'Your part is the correct file type to be Injection Moulded.',
      icon: 'check-circle',
    },
    {
      title: 'No Holes Detected',
      body: 'We have not detected any holes in your part.',
      icon: 'check-circle',
    },
    {
      title: 'Surface Finish Compatible',
      body: 'Your part is capable of surface finishing, allowing for enhanced aesthetics.',
      icon: 'check-circle',
    },
  ],
  '3d-printing': [
    {
      title: 'Your Part Can Be Manufactured',
      body: 'Your part has passed all of the manufacturability checks below.',
      icon: 'award',
      isHighlighted: true,
    },
    {
      title: 'File Parsing Complete',
      body: 'Your part file is capable of being 3D Printed without any manufacturing issues.',
      icon: 'check-circle',
    },
    {
      title: 'Singular Body Detected',
      body: 'We have not detected multiple bodies in your file, ensuring it is manufacturable.',
      icon: 'check-circle',
    },
    {
      title: 'File Type Compatible',
      body: 'Your part is the correct file type to be 3D Printed.',
      icon: 'check-circle',
    },
    {
      title: 'Surface Is Watertight',
      body: 'Your part features a watertight surface, ensuring integrity and manufacturability.',
      icon: 'check-circle',
    },
    {
      title: 'Surface Finish Compatible',
      body: 'Your part is capable of surface finishing, allowing for enhanced aesthetics.',
      icon: 'check-circle',
    },
  ],
}
