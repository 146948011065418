<template>
  <div
    class="g-number tw-inline-flex tw-flex-col tw-relative"
    :class="{ 'tw-w-full' : isFullWidth }"
  >
    <label
      v-if="label"
      :for="id"
      class="tw-text-sm tw-text-primary tw-mb-05"
    >
      {{ label }}
    </label>
    <div
      class="tw-flex tw-items-center tw-text-primary tw-text-sm tw-cursor-pointer tw-rounded-xs tw-border tw-px-1 tw-bg-white"
      :class="[isError ? 'tw-border-error' : isFocused ? 'tw-border-tertiary' : 'tw-border-grey-light', isFullWidth ? 'tw-w-full' : 'tw-w-min']"
    >
      <div class="tw-py-05 tw-flex tw-items-center tw-justify-between tw-w-full">
        <div class="input-wrapper tw-relative">
          <input
            type="number"
            :id="id"
            :name="id"
            :min="min"
            :value="hasMixedValues ? mixedValue : localValue"
            class="tw-text-sm tw-w-10 tw-mx-1 tw-p-1"
            :class="isFullWidth ? 'tw-w-full' : 'tw-w-10'"
            @keydown="(e) => {if (e.key ==='.') e.preventDefault()}"
            @input="handleEdit"
            @focus="isFocused = true"
            @blur="isFocused = false"
          >
          <span
            v-if="hasMixedValues && !isFocused"
            class="placeholder tw-absolute tw-top-1 tw-left-1 tw-bg-white tw-pointer-events-none"
          >
            {{ inputLabel }}
          </span>
        </div>
        <div class="tw-flex tw-flex-col tw-mr-05">
          <div
            id="quantity_initial_add"
            class="tw-px-1 tw-text-grey-light tw-transition-colors xl:hover:tw-text-primary tw-text-xs"
            @click="handleAdd"
          >
            <font-awesome-icon
              icon="chevron-up"
              class="tw-pointer-events-none"
            />
          </div>
          <div
            id="quantity_initial_subtract"
            class="tw-px-1 tw-text-grey-light tw-transition-colors tw-text-xs"
            :class="localValue <= min ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer xl:hover:tw-text-primary'"
            @click="handleMinus"
          >
            <font-awesome-icon
              icon="chevron-down"
              class="tw-pointer-events-none"
            />
          </div>
        </div>
      </div>
      <slot />
    </div>
    <div
      v-if="isError && errorMessage"
      class="tw-mt-1 tw-text-error tw-text-sm tw-flex tw-items-center"
    >
      <font-awesome-icon
        icon="exclamation-circle"
        class="tw-mr-1"
      />
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GNumber',

  components: {
    FontAwesomeIcon,
  },

  props: {
    label: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: () => 'quantity',
      required: true,
    },
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    inputLabel: {
      type: String,
      default: null,
    },
    hasMixedValues: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localValue: this.value,
      isError: false,
      errorMessage: '',
      isFocused: false,
      mixedValue: '',
    };
  },

  watch: {
    value(nv) {
      if (nv) this.localValue = nv;
    },
    hasMixedValues(nv) {
      if (!nv) this.mixedValue = '';
    },
  },

  methods: {
    handleAdd() {
      if (this.hasMixedValues) this.mixedValue = '1';

      this.localValue = this.hasMixedValues ? 1 : (this.localValue || 0) + 1;
      this.handleUpdate(this.localValue);
    },

    handleMinus() {
      if (this.localValue <= this.min || this.hasMixedValues) return;

      this.localValue = this.localValue - 1;
      this.handleUpdate(this.localValue);
    },

    handleEdit(e) {
      if (this.hasMixedValues) this.mixedValue = `${this.mixedValue}${e.data}`;

      const val = this.hasMixedValues ? parseInt(this.mixedValue) : parseInt(e.target.value);

      if (!val || val < this.min) {
        this.isError = true;
        this.localValue = val;
        this.errorMessage = `Minimum ${this.min} required`;
        return;
      }

      this.handleUpdate(val);
    },

    handleUpdate(val) {
      this.isError = false;
      this.localValue = val;
      this.$emit('update:value', val);
    },
  },

}
</script>

<style
  scoped
  lang="scss"
>
.g-number {
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;

    &:focus-visible {
      outline: none;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
