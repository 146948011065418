<template>
  <div
    class="part-handler-wrapper tw-flex tw-items-start"
    :class="{'tw-flex-col' : isColumn}"
  >
    <div
      class="checkbox-wrapper tw-flex"
      @click="handleClick"
      @mouseenter="handleHover(true)"
      @mouseleave="handleHover(false)"
    >
      <BCheckbox
        ref="checkbox"
        v-model="scopedIsSelected"
        class="tw-pointer-events-none"
        :class="{ 'tw-mb-4' : isColumn }"
        type="is-info"
      />
    </div>
    <div class="tw-flex tw-flex-col tw-items-center">
      <PartThumbnail
        :part="part"
        :show-hover-state="isHovered"
        :size="thumbnailSize"
        @isAnalysing="(e) => $emit('isAnalysing', e)"
      />
      <p
        v-if="dimensions"
        class="tw-text-xs tw-font-medium tw-text-grey-dark tw-text-center tw-mt-1"
      >{{ dimensions }}</p>
    </div>
  </div>
</template>

<script>
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { mapState } from 'vuex';

export default {
  name: 'PartHandler',

  components: {
    PartThumbnail,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isColumn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scopedIsSelected: this.isSelected,
      isHovered: false,
    };
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    thumbnailSize() {
      if (this[IS_DESKTOP]) return 80;
      return 42;
    },

    dimensions() {
      if (!this.part.dimension_x || !this.part.dimension_y || !this.part.dimension_z) return '';
      return `${parseFloat(this.part.dimension_x.toFixed(2))} x ${parseFloat(this.part.dimension_y.toFixed(2))} x ${parseFloat(this.part.dimension_z.toFixed(2))}mm`;
    },
  },

  watch: {
    isSelected(nv, ov) {
      if (nv !== ov) this.scopedIsSelected = nv;
    },
  },

  methods: {
    handleClick() {
      this.scopedIsSelected = !this.scopedIsSelected;
      this.$emit('update:is-selected', this.scopedIsSelected);
    },
    handleHover(event) {
      this.isHovered = event;

      let check = this.$refs.checkbox.$el.getElementsByClassName('check');
      check = [...check];

      check[0].style.borderColor = event ? '#338dbc' : '';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
}
</style>
