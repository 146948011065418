<template>
  <div class="no-quotes-wrapper tw-py-5 tw-flex tw-items-center">
    <p class="tw-font-bold tw-text-md tw-mr-1">
      We couldn't find any Quotes that match your current filters.
      Try changing one of the filters or
    </p>
    <g-button
      type="underline"
      color="tertiary"
      label="Clear all filters"
      class="tw-border-none"
      @click="handleClearFilters"
    />
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'NoFiltersMatch',
  components: {
    GButton,
  },
  methods: {
    handleClearFilters() {
      this.$emit('clear-filters');
    },
  },
};
</script>

<style lang="scss" scoped>

.no-quotes-wrapper {
  border-bottom: solid #dbdbdb 1px;
  border-top: solid #dbdbdb 1px;
}

</style>
