<template>
  <form @submit.prevent="submit">
    <div class="modal-card feedback">
      <header class="tw-bg-white modal-card-head tw-px-8">
        <div class="tw-w-full">
          <h2 class="title tw-text-xl tw-mt-4">
            Rate your orders to improve your recommended suppliers
          </h2>
          <BMessage
            type="is-info"
            size="is-small"
            class="has-text-weight-bold"
          >
            The more orders you rate, the better we can match you to your favourite suppliers for future quotes
          </BMessage>
        </div>
      </header>
      <div class="feedback-items-wrapper tw-px-6 tw-bg-white">
        <FeedbackOrderSingle
          v-for="(invoice, index) in invoices"
          :key="invoice.id"
          v-model="feedbacks[index]"
          :invoice="invoice"
          :is-last="index === invoices.length - 1"
        />
      </div>
      <footer
        class="modal-card-foot tw-justify-between tw-bg-white tw-px-8"
        style="border-top: 1px solid #efefef"
      >
        <g-button
          v-if="canDelay"
          label="Not sure yet, ask me again in 1 week"
          type="underline"
          color="tertiary"
          fontWeight="normal"
          @click="postpone"
        />
        <g-button
          :isLoading="loading"
          :isDisabled="!isReady"
          label="Submit"
          size="lg"
          nativeType="submit"
          htmlType="button"
          class="tw-w-32"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackOrderSingle from '@/app-buyer/components/feedback-modal/FeedbackOrderSingle.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import { AUTH_MODULE, MASQUERADING } from '@/app-buyer/store/modules/auth/types';

export default {
  name: 'FeedbackOrder',
  components: {
    FeedbackOrderSingle,
    GButton,
  },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    canDelay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      feedbacks: this.invoices
        .map((invoice) => ({ rating: null, comment: null, invoice_id: invoice.id })),
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      MASQUERADING,
    }),
    isReady() {
      return this.feedbacks
        .every((feedback) => feedback.rating != null);
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      const responses = await Promise.all(this.feedbacks
        .map((feedback) => Api.post(ENDPOINTS.ORDERS.FEEDBACK, feedback).catch((e) => e.response)));
      this.loading = false;
      if (responses.every(({ status }) => status < 300)) {
        this.$emit('submitted');
      } else {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Something went wrong, your feedback was not submitted!',
        });
        this.$emit('close');
      }
    },
    postpone() {
      this.$emit('close');
      this.feedbacks
        .forEach((fb) => this.$cookies.set(`dont_ask_about.${fb.invoice_id}`, 1, '7d'));
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card.feedback {
  width: 800px;
  max-width: 90vw;
  max-height: 90vh;
  margin: auto !important;

  .feedback-items-wrapper {
    overflow: auto;
  }

  .modal-card-foot {
    position: sticky;
    bottom: 0;
  }
}
</style>
