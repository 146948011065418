<template>
  <div
    class="navbar-item no-box-shadow tw-relative tw-group"
    style="cursor: pointer;"
    data-testid="navbar-notifications"
    @click="clickHandler"
  >
    <font-awesome-icon
      slot="trigger"
      icon="bell"
      class="tw-text-white tw-text-lg tw-transition-colors xl:group-hover:tw-text-grey-light"
    />
    <gm-tag
      v-if="hasUnreadNotification && !clicked"
      class="count tw-absolute tw-bg-pending tw-h-4 tw-w-4 tw-text-sm tw-left-4 tw-top-3 tw-border-1 tw-border-solid tw-border-primary tw-transition-colors xl:group-hover:tw-bg-pending-hover"
      rounded
    >
      {{ totalCount }}
    </gm-tag>
    <div
      v-show="open"
      v-click-outside="handleClickOutside"
      class="is-absolute notification-container"
    >
      <ul style="min-width: 30ch">
        <li
          v-for="notification in notifications"
          :key="notification.id"
          class="navbar-item is-size-7"
          data-testid="navbar-notification"
          @click="handleNotificationClick(notification)"
        >
          <BIcon
            class="mr-2"
            :icon="notification.data_icon"
          />
          {{ notification.data_body }}
        </li>
      </ul>
      <div
        v-show="cartTransferCount"
        class="navbar-item"
        data-testid="cart-transfer-notification"
      >
        <router-link to="/cart-transfer">
          {{ cartTransferNotification }}
        </router-link>
      </div>
      <div
        v-show="!hasNotification"
        class="navbar-item"
      >
        <a @click.stop="open = false">You have no notifications.</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import clickOutside from 'buefy/src/directives/clickOutside';
import Api from '@/app-buyer/api/api';
import { METADATA, USER_DATA, USER_MODULE } from '../../store/modules/user/types';

export default {
  name: 'NavbarNotifications',
  directives: {
    clickOutside,
  },
  data() {
    return {
      clicked: false,
      open: false,
      subscription: null,
      notifications: [],
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      METADATA,
      USER_DATA,
    }),
    cartTransferCount() {
      return this[METADATA]?.cart_transfer_count;
    },
    totalCount() {
      return (this.cartTransferCount || 0)
        + this.unreadNotifications.length;
    },
    cartTransferNotification() {
      if (this.cartTransferCount > 0) {
        return `You have ${this.cartTransferCount} cart transfers waiting!`;
      }
      return null;
    },
    hasNotification() {
      return this.cartTransferNotification || this.notifications.length;
    },
    hasUnreadNotification() {
      return !!this.totalCount;
    },
    unreadNotifications() {
      return this.notifications.filter((notification) => !notification.read_at);
    },
    userId() {
      return this[USER_DATA]?.user?.id;
    },
  },
  created() {
    this.fetchUserNotifications();
  },
  methods: {
    clickHandler() {
      this.open = !this.open;
      this.clicked = true;
      setTimeout(() => {
        this.opened = this.open;
      }, 500);
    },
    handleClickOutside() {
      if (this.opened && this.open) {
        this.open = false;
        this.opened = false;
      }
    },
    async fetchUserNotifications() {
      const {
        data,
        status,
      } = await Api.get('/api/v2/notifications').catch((error) => error.response);
      if (status === 200) {
        this.notifications = data.data;
      }
    },
    handleNotificationClick(notification) {
      Api.put('/api/v2/notifications', {
        mark_as_read: [notification.id],
      });
      window.location.href = notification.data_url;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.notification-container {
  bottom: 0;
  right: 0;
  background-color: #131a22;
  border-radius: 0 0 4px 4px;
  transform: translateY(100%);

  a {
    white-space: nowrap;
    text-transform: none !important;

    &:first-letter {
      text-transform: uppercase !important;
    }
  }
}
</style>
