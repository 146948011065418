<template>
  <div
    class="is-absolute is-inset-0 has-background-white px-6 pb-6 tw-z-10"
  >
    <div class="is-flex is-justify-space-between tw-pt-2">
      <div
        class="shiny-effect py-4"
      >
        <div
          class="shiny-effect-child"
          :style="`width: ${activeProjectNameLength}ch; height: 2rem;`"
        />
      </div>
      <div
        class="shiny-effect py-4 is-flex is-align-items-center"
      >
        <template>
          <div
            v-for="member in activeProjectMembersCount"
            :key="member"
            class="shiny-effect-child mr-2"
            style="width: 1.5rem; height: 1.5rem; border-radius: 1.5rem;"
          />
        </template>
        <div
          class="shiny-effect-child ml-2"
          style="width: 9ch; height: 2rem; border-radius: 2rem;"
        />
      </div>
    </div>
    <div
      class="shiny-effect is-flex tw-mb-2 tw-pb-3"
    >
      <div
        class="shiny-effect-child mr-2"
        style="width: 250px; height: 2.5rem;"
      />
      <div
        class="shiny-effect-child"
        style="width: 200px; ; height: 2.5rem;"
      />
    </div>
    <TransitionGroup
      name="fade"
      class="is-flex-grow-1 is-flex is-flex-direction-column"
      mode="out-in"
    >
      <template v-if="activeProjectPartsCount">
        <div
          key="withpartstop"
          class="is-flex-grow-1 is-flex is-flex-direction-column"
        >
          <div class="is-flex-grow-1">
            <div class="mb-2 is-flex is-height-5">
              <div
                class="shiny-effect is-width-100 is-height-100"
              >
                <div class="shiny-effect-child is-width-100 is-height-100" />
              </div>
            </div>
            <div
              v-for="n in activeProjectPartsCount"
              :key="n"
            >
              <PartElementPlaceholder />
            </div>
          </div>
          <div
            class="is-flex mt-4"
            style="height: 80px;"
          >
            <div
              class="shiny-effect is-width-100 is-height-100"
            >
              <div class="shiny-effect-child is-width-100 is-height-100" />
            </div>
          </div>
        </div>
        <div
          key="withpartbottom"
          class="ml-4 px-4"
          style="flex: none; width: 25rem;opacity: 0.75"
        >
          <div
            v-for="n in 10"
            :key="n"
            class="is-height-5 is-flex mb-4"
          >
            <div
              class="shiny-effect is-width-100 is-height-100"
            >
              <div class="shiny-effect-child is-width-100 is-height-100" />
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        key="noparts"
        class="py-4 is-width-100"
        style="height: calc(100vh - 17.5rem);"
      >
        <div
          class="is-width-100 is-height-100 shiny-effect p-4"
          style="border-radius: 5px; border: 2px dashed #ddd;"
        >
          <div class="shiny-effect-child is-width-100 is-height-100" />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PartElementPlaceholder from '@/app-buyer/components/placeholders/part-element-placeholder.vue';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'ProjectContentLoader',
  components: { PartElementPlaceholder },
  computed: {
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    activeProjectPartsCount() {
      return  this[ACTIVE_PROJECT]?.draft_rfqs?.length > 4 ? 4 : this[ACTIVE_PROJECT]?.draft_rfqs?.length;
    },

    activeProjectNameLength() {
      return this[ACTIVE_PROJECT]?.name?.length || 10;
    },

    activeProjectMembersCount() {
      return this[ACTIVE_PROJECT]?.members?.length || 0;
    },
  },
};
</script>

<style scoped>
  .shiny-effect-child {
    border-radius: 5px;
  }
</style>
