<template>
  <button
    :class="{
      'is-active' : isActive && $route.name === 'quote-page',
      dragover,
      'drop-available': canDrop
    }"
    class="project-list-element is-relative hover-parent tw-mx-2 tw-my-1 tw-rounded-xs"
    data-testid="project-pill"
    @click.stop="$emit('select')"
    @dragenter="handleDragOver"
    @dragleave="dragover = false"
    @dragover="handleDragOver"
    @drop="handlePartDrop"
  >
    <div
      class="no-outline tw-w-full"
    >
      <div
        class="tw-flex tw-items-center"
      >
        <div class="tw-flex-1 fill-fix-width">
          <p
            class="project-title tw-text-white tw-text-left tw-text-sm tw-font-bold"
            data-testid="project-title"
          >
            {{ project.name || 'Untitled project' }}
          </p>
          <p class="tw-text-white tw-text-left tw-text-sm">
            {{ project.ref }} ({{ project?.draft_rfqs_count || 0 }} parts)
          </p>
        </div>
        <div
          v-if="isProjectOwner && !project.archived_at"
          class="is-flex-grow-0 visible-on-hover"
        >
          <a
            class="has-text-white m-r-sm"
            data-testid="rename-project-btn"
            @click.stop="$emit('prompt-rename')"
          >
            <font-awesome-icon icon="edit" class="tw-text-md" />
          </a>
        </div>
        <div
          v-if="isProjectOwner"
          class="is-flex-grow-0 visible-on-hover"
        >
          <a
            v-if="project.archived_at"
            data-testid="unarchive-project-btn"
            class="tw-text-white"
            @click.stop="$emit('confirm-action', 'unarchive')"
          >
            <font-awesome-icon icon="box-open" class="tw-text-md" />
          </a>
          <a
            v-else
            class="has-text-white visible-on-hover"
            data-testid="archive-project-btn"
            @click.stop="$emit('confirm-action', 'archive')"
          >
            <font-awesome-icon icon="archive" class="tw-text-md" />
          </a>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import { PROJECT_MODULE, SET_PROJECT_INVITE_MODAL } from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'ProjectSidebarElement',
  props: {
    project: {
      type: Object,
      required: true,
    },
    darkText: {
      type: Boolean,
      default: () => false,
    },
    activeProject: {
      type: Object,
      default: () => null,
    },
    canDrop: Boolean,
  },
  data() {
    return {
      hovered: false,
      dragover: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
    isActive() {
      return this.activeProject && this.project.hash === this.activeProject.hash;
    },
    projectOwner() {
      return this.project.members?.find((member) => member.role === 'owner');
    },
    isProjectOwner() {
      return this.userData?.user.id === this.projectOwner?.user.id;
    },
  },
  methods: {
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_INVITE_MODAL,
    }),
    handleDragOver(ev) {
      ev.preventDefault();
      if (this.canDrop) {
        this.dragover = true;
      }
    },
    handlePartDrop(ev) {
      this.dragover = false;
      this.$emit('part-drop', ev);
    },
    handleShowInviteModal() {
      this[SET_PROJECT_INVITE_MODAL](true);
    },
  },
};
</script>

<style lang="scss" scoped>

.project-list-element {
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none !important;
  overflow-x: hidden;
  padding: 0.5rem;
  width: calc(100% - 1rem);

  .wrapper {
    width: 100%;
  }

  p.project-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1rem;
  }

  &.drop-available {
    border: 2px dashed rgba(variables.$v2-neutral-4, 0.5);
  }

  &.dragover {
    border: 2px dashed variables.$v2-neutral-4;
  }

  p.project-title,
  p.project-title.span {
    color: rgba(white, 0.75);
  }

  a {
    align-items: center;
    display: flex;
  }

  a {
    @media all and (max-width: variables.$desktop) {
      opacity: 1;
    }
  }

  &:hover {
    background-color: rgba(white, 0.1);

    p {
      color: white;
    }
  }

  &.is-active {
    background-color: rgba(white, 0.1);

    p {
      color: white;
    }
  }
}

@screen xl {
  .visible-on-hover {
    display: none;
  }

  .hover-parent:hover .visible-on-hover {
    display: block;
  }
}
</style>
