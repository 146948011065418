<template>
  <div
    class="notes-wrapper tw-flex tw-flex-col xl:tw-mb-3 xl:tw-grid"
    :class="{ 'tw-pt-3' : isReadOnly }"
  >
    <p class="tw-font-bold tw-text-sm tw-mb-2 xl:tw-mr-2 xl:tw-mb-0">Notes</p>
    <div
      v-if="!isReadOnly"
      class=""
    >
      <div v-if="showNotes">
        <ConfirmApplicableTooltip
          :confirm-applicable="confirmApplicable"
          @cancel="cancelNote"
          @confirm="handleConfirm"
        />
        <BInput
          v-model="notes"
          maxlength="400"
          type="textarea"
          custom-class="tw-text-md tw-mt-2"
          @input="debounceUpdate"
        />
      </div>
      <button
        class="tw-text-tertiary tw-font-bold tw-flex tw-items-center tw-text-md tw-relative"
        :class="{ 'tw-bottom-4' : showNotes }"
        @click="showNotes = !showNotes"
      >
        <font-awesome-icon
          :icon="showNotes ? 'minus' : 'plus'"
          class="tw-mr-1"
        />
        <span class="tw-text-tertiary tw-text-sm tw-whitespace-nowrap">{{
            notesMessage
          }}</span><span class="tw-font-normal tw-text-xs tw-ml-1 tw-text-primary tw-text-left">(This requires a manual quote and delays quotation)</span>
      </button>
    </div>
    <div
      v-else
      class="tw-text-sm tw-flex tw-flex-col tw-justify-center"
    >
      <template v-if="readOnlyNotes?.length">
        <p
          v-for="note in readOnlyNotes"
          :key="note"
          class=""
        >{{ note }}</p>
      </template>
      <template v-else>
        None
      </template>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash/function';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import ConfirmApplicableTooltip from '@/app-buyer/components/project/ConfirmApplicableTooltip.vue';

export default {
  name: 'PartFormNoteComponent',
  components: { ConfirmApplicableTooltip },
  mixins: [safeMultipleUpdate],
  props: {
    configurations: {
      type: Object,
      required: true,
    },
    leadHash: {
      type: String,
      default: null,
    },
    parts: {
      type: Array,
      default: () => [],
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notes: null,
      showNotes: false,
    };
  },
  computed: {
    values() {
      return Array.from(new Set(Object.values(this.configurations).map((e) => e.notes)));
    },
    leadNote() {
      return this.configurations?.[this.leadHash]?.notes;
    },

    notesMessage() {
      if (this.showNotes) {
        return 'Hide notes';
      }
      if (this.parts?.[0].notes) {
        return 'Edit notes';
      }
      return 'Add notes';
    },

    readOnlyNotes() {
      const configValues = Object.values(this.configurations);
      const notes = [];

      configValues.map((config) => {
        Object.keys(config).forEach(key => {
          if (['notes'].includes(key) && config[key]) {
            notes.push(config[key])
          }
        })
      })

      return notes;
    },
  },
  watch: {
    leadNote: {
      handler(value) {
        this.notes = value;
      },
      immediate: true,
    },
  },
  methods: {
    debounceUpdate: debounce(function doDebounceUpdate() {
      this.startUpdate(this.notes, { entitySlug: 'notes' });
    }, 500),
    cancelNote() {
      this.notes = this.leadNote;
      this.confirmApplicable = null;
    },
    handleConfirm({ canApply }) {
      this.finaliseUpdate(canApply, 'notes');
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.notes-wrapper {
  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}
</style>
