import getEnvironmentVariable from '../../shared/misc/env-variable';

const luckyOrangeBody = `
        window.__lo_site_id = 157380;
        (function() {
            var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
            wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
        })();
    `;
// SEGMENT TRACKING TO REMOVE
const gTagScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${getEnvironmentVariable('VUE_APP_GTM_ID')}');
    `;
// SEGMENT TRACKING TO REMOVE

const segmentScript = `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${getEnvironmentVariable('VUE_APP_SEGMENT_ID')}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${getEnvironmentVariable('VUE_APP_SEGMENT_ID')}");
  analytics.page();
  }}();
`;

const scripts = [
  {
    body: luckyOrangeBody,
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.head,
  },
  {
    src: 'https://js.hs-scripts.com/9195657.js',
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.body,
    attributes: {
      id: 'hs-script-loader',
      async: true,
      defer: true,
    },
  },
  // SEGMENT TRACKING
  {
    body: segmentScript,
    required: getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED'),
    target: document.head,
  },
  // SEGMENT TRACKING TO REMOVE
  {
    body: gTagScript,
    required: getEnvironmentVariable('VUE_APP_ENV') === 'production',
    target: document.head,
  },
  // SEGMENT TRACKING TO REMOVE
];

const essentialScripts = [
  {
    src: `https://www.google.com/recaptcha/api.js?render=${getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY')}`,
    required: !!getEnvironmentVariable('VUE_APP_RECAPTCHA_KEY'),
    target: document.body,
  },
];

const externalScripts = {
  mounted() {
    essentialScripts.forEach(this.addScript);
  },

  methods: {
    addScripts() {
      scripts.forEach(this.addScript);
    },

    addScript(obj) {
      if (obj.required) {
        const script = document.createElement('script');
        if (obj.attributes) {
          Object.entries(obj.attributes).forEach(([attribute, value]) => {
            script.setAttribute(attribute, value === true ? attribute : value);
          });
        }
        if (obj.body) {
          const innerScript = document.createTextNode(obj.body);
          script.appendChild(innerScript);
        }
        if (obj.src) script.src = obj.src;
        obj.target.appendChild(script);
      }
    },
  },
}

export default externalScripts;
