export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN';
export const CREATE_LOGIN_LINK = 'CREATE_LOGIN_LINK';
export const LOGIN_VIA_LINK = 'LOGIN_VIA_LINK';
export const LOGOUT = 'LOGOUT';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGGED_IN = 'LOGGED_IN';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const MASQUERADING = 'MASQUERADING';
export const SET_MASQUERADING = 'SET_MASQUERADING';
export const AUTH_MODULE = 'AUTH_MODULE';
export const FORCE_AUTH = 'FORCE_AUTH';
export const FORCED = 'FORCED';
export const UPLOAD_BEFORE_AUTH = 'UPLOAD_BEFORE_AUTH';
export const SET_FILES_BEFORE_AUTH = 'SET_FILES_BEFORE_AUTH';
export const FILES_BEFORE_AUTH = 'FILES_BEFORE_AUTH';
export const SET_FILES_UPLOADED_BEFORE_AUTH = 'SET_FILES_UPLOADED_BEFORE_AUTH';
export const FILES_UPLOADED_BEFORE_AUTH = 'FILES_UPLOADED_BEFORE_AUTH';
