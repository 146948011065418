<template>
  <div class="previous-quotes-grid tw-text-md tw-py-4 tw-border-b tw-border-grey-light tw-px-4 xl:tw-px-6 xl:tw-grid">
    <div
      v-if="IS_DESKTOP"
      class="tw-inline-flex tw-justify-self-start tw-self-start tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-py-05 tw-px-1 tw-bg-grey-lighter tw-text-grey-darkest tw-whitespace-nowrap xl:tw-text-sm"
    >
      <p>{{ part.ref }}</p>
    </div>
    <div class="internal-grid tw-grid xl:tw-flex">
      <PartThumbnail
        :part="part"
        :size="45"
        class="tw-self-start"
      />
      <div class="tw-flex tw-flex-col xl:tw-ml-2">
        <div
          v-if="!IS_DESKTOP"
          class="tw-inline-flex tw-justify-self-start tw-self-start tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-py-05 tw-px-1 tw-bg-grey-lighter tw-text-grey-darkest tw-whitespace-nowrap tw-mb-1 xl:tw-text-sm"
        >
          <p>{{ part.ref }}</p>
        </div>
        <p class="tw-font-bold">{{ part.name }}</p>
        <p class="">{{ formatDate(part.quote?.[0]?.sent_at || part.created_at) }}</p>
        <p
          v-for="config in configMapped"
          class="tw-text-grey-dark tw-text-sm"
        > {{ config.title }}: {{ config.value }}</p>
      </div>
    </div>

    <template v-if="IS_DESKTOP">
      <p>{{ part.quantity_initial }}</p>
      <p>{{ part.lead_time_days[part.lead_time_speed] }} business days</p>
      <div
        v-if="price"
        class="tw-flex tw-flex-col"
      >
        <p class="tw-text-md">Price per unit:</p>
        <p class="tw-font-bold tw-text-lg">{{ price_unit | formatWithCurrency }}</p>
        <template v-if="tool_cost">
          <p class="tw-text-sm tw-mt-1">Tool cost:</p>
          <p class="tw-font-bold tw-text-sm">{{
              tool_cost | formatWithCurrency
            }}</p>
        </template>
        <p class="tw-text-sm tw-mt-1">Subtotal:</p>
        <p class="tw-font-bold tw-text-sm">{{
            price | formatWithCurrency
          }}</p>
      </div>
      <div v-else>
        <p class="">Awaiting quote</p>
      </div>
    </template>

    <template v-else>
      <div class="internal-grid tw-grid">
        <div class=""></div>
        <div class="">
          <p class="tw-font-bold tw-text-md tw-mt-3">Qty:</p>
          <p>{{ part.quantity_initial }}</p>
          <p class="tw-font-bold tw-text-md tw-mt-3">Lead time:</p>
          <p>{{ part.lead_time_days[part.lead_time_speed] }} business days</p>
          <div
            v-if="price"
            class="tw-flex tw-flex tw-flex-wrap tw-w-full tw-mt-3"
          >
            <div class="tw-flex tw-flex-col tw-pr-12">
              <p class="tw-text-sm">Price per unit:</p>
              <p class="tw-font-bold tw-text-lg">{{ price_unit | formatWithCurrency }}</p>
            </div>
            <template v-if="tool_cost">
              <div class="tw-flex tw-flex-col tw-pr-12">
                <p class="tw-text-sm">Tool cost:</p>
                <p class="tw-font-bold tw-text-sm">{{
                    tool_cost | formatWithCurrency
                  }}</p>
              </div>
            </template>
            <div class="tw-flex tw-flex-col ">
              <p class="tw-text-sm">Subtotal:</p>
              <p class="tw-font-bold tw-text-sm">{{
                  price | formatWithCurrency
                }}</p>
            </div>
          </div>
          <div
            v-else
            class="tw-mt-3"
          >
            <p class="tw-font-bold tw-text-md tw-mt-3">Subtotal:</p>
            <p class="">Awaiting quote</p>
          </div>
        </div>
      </div>
    </template>

    <div class="tw-mt-3 xl:tw-mt-0 xl:tw-ml-auto">
      <tippy
        arrow
        interactive
        :enabled="isPartInCart && !isRfqAlreadyActive && IS_DESKTOP"
        class="xl:tw-inline-flex"
      >
        <template #trigger>
          <g-button
            :isDisabled="isPartInCart && !isRfqAlreadyActive"
            :isInverted="!isRfqAlreadyActive"
            :isLoading="isLoading"
            :isFullWidth="!IS_DESKTOP"
            :label="isRfqAlreadyActive ? 'Selected' : 'Select quote'"
            :type="isRfqAlreadyActive ? 'primary-icon' : 'primary'"
            :size="IS_DESKTOP ? 'sm' : 'md'"
            iconRight="eye"
            color="tertiary"
            :class="{'tw-pointer-events-none' : isRfqAlreadyActive }"

            @click="handleSelectPreviousQuote"
          />
        </template>
        <div id="buyer-app">
          <div class="tw-text-left tw-p-1 tw-text-sm">
            <p class="">To select this quote, please remove your selected quote from the cart
              first.</p>
            <g-button
              :hasBorder="false"
              label="Remove from cart"
              type="underline"
              color="white"
              size="sm"
              fontWeight="normal"
              @click="() => $emit('removeFromCart')"
            />
          </div>
        </div>
      </tippy>
      <div
        v-if="!IS_DESKTOP && isPartInCart && !isRfqAlreadyActive"
        class="tw-mt-2 tw-flex"
      >
        <font-awesome-icon
          icon="info-circle"
          class="tw-text-tertiary tw-font-sm tw-mr-1"
        />
        <p class="tw-text-sm">To select this quote, please remove your selected quote from the cart
          first. <span><g-button
            label="Remove from cart"
            type="underline"
            color="primary"
            size="sm"
            fontWeight="bold"
            style="display: inline-flex"
            @click="() => $emit('removeFromCart')"
          /></span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PartThumbnail from '@/app-buyer/components/project/PartThumbnail.vue';
import { INJECTION_MOULDING, SHEET_METAL, THREE_D_PRINTING } from '@/shared/consts/slugs';
import { getProperty } from '@/app-buyer/mixins/get-property';
import findSecondaryFinishParentMixin from '@/app-buyer/mixins/findSecondaryFinishParent';
import GButton from '@common/components/storied/atoms/GButton.vue';
import {
  GET_FIRST_SELECTED_PART,
  RFQ_MODULE,
  SET_PREVIOUS_QUOTE,
  SET_PROPERTY,
} from '@/app-buyer/store/modules/rfq/types';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';

const generalPropertiesOrder = [
  'service',
  'material',
  'tolerance',
  'surface-roughness',
  'primary-finish',
  'secondary-finish',
  'polishing-type',
  'hrc',
  {
    title: 'Inspection',
    key: 'inspection.string_value',
    append: 'video-inspection.string_value',
  },
  'mill-certificate',
  'iso-9001',
  'iso-13485',
  'engraving',
  'laser-marking',
  'screen-printing',
  'xrf-analysis',
  'part-marking',
  'screws',
];

const sheetMetalPropertiesOrder = [
  'service',
  'material',
  'secondary-finish',
  'primary-finish',
  'bending',
  'hole-countersinking',
  'hole-threading',
  'thickness',
  {
    title: 'Inspection',
    key: 'inspection.string_value',
    append: 'video-inspection.string_value',
  },
  'mill-certificate',
  'iso-9001',
  'iso-13485',
  'engraving',
  'xrf-analysis',
  'screws',
];

const injectionMouldingPropertiesOrder = [
  'service',
  'material',
  'tolerance',
  'a-side-finish',
  'b-side-finish',
  {
    title: 'Inspection',
    key: 'inspection.string_value',
    append: 'video-inspection.string_value',
  },
  'mill-certificate',
  'iso-9001',
  'iso-13485',
  'xrf-analysis',
  'screws',
];

const threeDPrintingPropertiesOrder = [
  'service',
  'technology',
  'material',
  'tolerance',
  'vibro-polishing',
  'colour',
  'primary-finish',
  'secondary-finish',
  'layer-height',
  'infill',
  {
    title: 'Inspection',
    key: 'inspection.string_value',
    append: 'video-inspection.string_value',
  },
  'mill-certificate',
  'iso-9001',
  'iso-13485',
  'xrf-analysis',
  'screws',
];


const getEntities = (slug) => {
  switch (slug) {
    case INJECTION_MOULDING:
      return injectionMouldingPropertiesOrder;
    case SHEET_METAL:
      return sheetMetalPropertiesOrder;
    case THREE_D_PRINTING:
      return threeDPrintingPropertiesOrder;
    default:
      return generalPropertiesOrder;
  }
};

export default {
  name: 'PreviousQuote',

  components: {
    PartThumbnail,
    GButton,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isPartInCart: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    findSecondaryFinishParentMixin,
  ],

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters(RFQ_MODULE, {
      GET_FIRST_SELECTED_PART,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    isRfqAlreadyActive() {
      return this.part?.config_string === this.GET_FIRST_SELECTED_PART?.config_string
        && this.part?.ref === this.GET_FIRST_SELECTED_PART?.quote_rfq_ref;
    },

    price() {
      return this.part?.quotes?.[0]?.subtotal;
    },

    price_unit() {
      return this.part?.quotes?.[0]?.unit_price;
    },

    tool_cost() {
      return this.part?.quotes?.[0]?.tool_cost;
    },

    rfqData() {
      return this.part.configuration_object;
    },

    configMapped() {
      if (this.rfqData) {
        const data = getEntities(this.rfqData?.service?.slug).map((entity) => this.getPair(entity));
        data.push({
          title: 'Your notes',
          value: this.clientNotes,
        });
        data.push({
          title: 'Geomiq notes',
          value: this.geomiqNotes,
        });
        return data?.filter(e => e.title && e.value);
      }
      return [];
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      SET_PREVIOUS_QUOTE,
    }),

    async handleSelectPreviousQuote() {
      if (this.isRfqAlreadyActive) return;

      try {
        this.isLoading = true;
        const part = await this[SET_PREVIOUS_QUOTE]({
          hash: this.part.draft_hash,
          rfq_id: this.part.id,
        });
        // Most properties come back in the configuration updated event websocket,
        // which, will update the configuration form, except the notes, however.
        this.$store.commit(`${RFQ_MODULE}/${SET_PROPERTY}`, {
          model: part,
          property: 'notes',
          value: part.notes,
        });

        // Add a timeout to give the Websocket time to fire
        await new Promise(resolve => setTimeout(resolve, 1000));

        this.$emit('closeModal');

        this.$buefy.snackbar.open({
          message: `<div class="message-wrapper">
            <p class="message-title">Viewing a previous quote for ${this.part.name}</p>
            <p class="message-content">You have switched to viewing a previous manual quote for ${this.part.name}</p>
          </div>`,
          type: 'is-white',
          size: 'is-small',
          position: 'is-top-right',
          duration: 3000,
          pauseOnHover: true,
          actionText: null,
        });
      } catch (e) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Could not view this quote',
        });
      } finally {
        this.isLoading = false;
      }
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year}, ${hours}:${minutes}`;
    },

    getPair(entity) {
      if (typeof entity === 'string') {
        const config = this.rfqData[entity];
        if (entity === 'secondary-finish') {
          const parent = this.findSecondaryFinishParent(config);
          if (parent) {
            return {
              title: config?.entity_name,
              value: `${parent?.string_value}: ${config?.string_value}`,
            };
          }
        }
        return {
          title: config?.entity_name,
          value: config?.string_value,
        };
      }
      const {
        title, key, applyFilter, delay, append,
      } = entity;
      let value = getProperty(this.rfqData, key);
      if (key === 'proposal.part_entry.unit_cost') {
        value *= 0.01;
      }

      if (append) {
        const appendable = getProperty(this.rfqData, append);
        if (appendable) {
          value += ` <br> ${appendable}`;
        }
      }

      return {
        title,
        value,
        applyFilter,
        delay,
      };
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.previous-quotes-grid {
  @screen xl {
    grid-template-columns: 9% 35% 10% 19% 17% 10%;
  }

  .internal-grid {
    grid-template-columns: 17% 83%;
  }
}
</style>
