/**
 * File for bootstrapping the Application.
 */

/*
|--------------------------------------------------------------------------
| Set Application Environment Variables
|--------------------------------------------------------------------------
|
| Typically environment variables are set at build time, the downsides of which
| being that you need to rebuild an image to change them. Here we establish
| a global object that can contain these variables in a way that can be
| updated at runtime. The content of _env_ should be set prior to this
| point and so here we simply ensure that the object is defined.
*/

if (window._env_ === undefined) {
  window._env_ = {};
}
