<template>
  <div class="tw-overflow-auto">
    <table class="tw-w-full tw-h-full">
      <thead class="tw-text-md tw-font-bold tw-bg-grey-lighter">
        <tr class="">
          <th>
            <p class="tw-p-2">Material</p>
          </th>
          <th colspan="100%" class="xl:tw-w-9/12">
            <p class="tw-py-2 tw-pr-2">Thickness Available (mm)</p>
          </th>
        </tr>
      </thead>
      <tbody class="">
        <tr v-for="material in materialData" :key="material.slug" class="tw-text-sm tw-p-1 tw-border-b tw-border-grey-light">
          <td>
            <div class="tw-flex tw-flex-col tw-p-2 tw-mr-3 tw-whitespace-nowrap tw-w-32 xl:tw-w-48">
              <p class="tw-text-md tw-whitespace-normal">{{ material.string_name }}</p>
              <p class="tw-text-sm tw-whitespace-normal">{{ material.string_name_other }}</p>
            </div>
          </td>
          <td class="tw-flex tw-flex-wrap tw-items-center tw-h-full xl:tw-flex-nowrap">
            <p v-for="thickness in material.thicknesses" class="tw-w-6 tw-py-2 tw-mr-3">{{ thickness }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ThicknessMaterialTable',

  props: {
    materialData: {
      type: Array,
      required: true,
    },
  },
}
</script>


<style
  scoped
  lang="scss"
>
tbody {
  td {
    min-width: theme('spacing.8');
  }
}
</style>
