import {
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowToRight,
  faArrowUp,
  faAward,
  faBackspace,
  faBackward,
  faBadgeCheck,
  faBars,
  faBell,
  faBellSlash,
  faBold,
  faBolt,
  faBook,
  faBox,
  faBoxOpen,
  faBuilding,
  faBullseye,
  faCalculator,
  faCalendar,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faClipboardListCheck,
  faClock,
  faClone,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCog,
  faCogs,
  faCoins,
  faCommentAlt,
  faCommentDots,
  faComments,
  faCompress,
  faCopy,
  faCreditCard,
  faCrown,
  faCube,
  faDesktop,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faFilter,
  faFire,
  faFlag,
  faFlagAlt,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faFontCase,
  faGavel,
  faGlobe,
  faGripVertical,
  faHammer,
  faHandPointLeft,
  faHistory,
  faImage,
  faInbox,
  faIndustry,
  faInfoCircle,
  faItalic,
  faLayerGroup,
  faListOl,
  faListUl,
  faLock,
  faLockOpen,
  faMagic,
  faMapMarkerAlt,
  faMarker,
  faMedal,
  faMinus,
  faMinusCircle,
  faMousePointer,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPen,
  faPencil,
  faPencilAlt,
  faPenSquare,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faPoundSign,
  faPrint,
  faQuestionCircle,
  faRecycle,
  faRedo,
  faRobot,
  faSave,
  faSearch,
  faSearchPlus,
  faShare,
  faShippingFast,
  faShoppingCart,
  faSignIn,
  faSort,
  faSortAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStarHalfAlt,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTachometerAlt,
  faTag,
  faTh,
  faThLarge,
  faThList,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTrophyAlt,
  faTruck,
  faUnderline,
  faUndo,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVideo,
  faWeightHanging,
  faWrench,
  faChartBar,
  faTools,
} from '@ManufacturingSource/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowToRight,
  faArrowUp,
  faAward,
  faBackspace,
  faBackward,
  faBadgeCheck,
  faBars,
  faBell,
  faBellSlash,
  faBold,
  faBolt,
  faBook,
  faBox,
  faBoxOpen,
  faBuilding,
  faBullseye,
  faCalculator,
  faCalendar,
  faCalendarDay,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faClipboardListCheck,
  faClock,
  faClone,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCog,
  faCogs,
  faCoins,
  faCommentAlt,
  faCommentDots,
  faComments,
  faCompress,
  faCopy,
  faCreditCard,
  faCrown,
  faCube,
  faDesktop,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faFilter,
  faFire,
  faFlag,
  faFlagAlt,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faFontCase,
  faGavel,
  faGlobe,
  faGripVertical,
  faHammer,
  faHandPointLeft,
  faHistory,
  faImage,
  faInbox,
  faIndustry,
  faInfoCircle,
  faItalic,
  faLayerGroup,
  faListOl,
  faListUl,
  faLock,
  faLockOpen,
  faMagic,
  faMarker,
  faMedal,
  faMinus,
  faMinusCircle,
  faMousePointer,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPen,
  faPencil,
  faPencilAlt,
  faPenSquare,
  faPercentage,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPoll,
  faPoundSign,
  faPrint,
  faQuestionCircle,
  faRecycle,
  faRedo,
  faRobot,
  faSave,
  faSearch,
  faSearchPlus,
  faShippingFast,
  faShoppingCart,
  faSignIn,
  faSort,
  faSortAlt,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStarHalfAlt,
  faStickyNote,
  faSync,
  faSyncAlt,
  faTachometerAlt,
  faTag,
  faTh,
  faThLarge,
  faThList,
  faThumbsUp,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTrophy,
  faTrophyAlt,
  faTruck,
  faUnderline,
  faUndo,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVideo,
  faWeightHanging,
  faWrench,
  faChartBar,
  faTools,
  faShare,
  faMapMarkerAlt,
);
