var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.htmlType,_vm._g(_vm._b({tag:"component",staticClass:"gm-button gm-theme",class:[
    _vm.type,
    _vm.size,
    {
      outlined: _vm.outlined,
      fullwidth: _vm.fullwidth,
      loading: _vm.loading,
      rounded: _vm.rounded,
      'has-shadow' : _vm.hasShadow,
      'is-3d': _vm.is3d,
      'link': _vm.isLink,
      'tw-text-primary': _vm.isBlack
    },
    {'is-inline-flex is-align-items-center': !!(_vm.iconLeft || _vm.iconRight) }
  ],attrs:{"type":_vm.nativeType}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.iconLeft)?_c('font-awesome-icon',{staticStyle:{"font-size":"11px"},attrs:{"icon":_vm.iconLeft}}):_vm._e(),_vm._t("default"),(_vm.iconRight)?_c('font-awesome-icon',{staticStyle:{"font-size":"11px"},attrs:{"icon":_vm.iconRight}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }