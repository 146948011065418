import { render, staticRenderFns } from "./PartRowOtherThickness.vue?vue&type=template&id=70db2d31&scoped=true"
import script from "./PartRowOtherThickness.vue?vue&type=script&lang=js"
export * from "./PartRowOtherThickness.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70db2d31",
  null
  
)

export default component.exports