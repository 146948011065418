/* eslint-disable camelcase */
import { mapActions, mapMutations, mapState } from 'vuex';
import { programmaticUpload } from '@/shared/mixins';
import Api from '@/app-buyer/api/api';
import ENDPOINTS from '@/app-buyer/api/endpoints';
import { SET } from '@/app-buyer/store/modules/types';
import { G_SERVE_MODULE } from '@/app-buyer/store/modules/g-serve/types';
import notificationInjection from '../../shared/components/notification/notification-injection-mixin';
import {
  REFERENCE_DATA, REFERENCE_MODULE,
} from '../store/modules/reference-data/types';
import {
  BATCH_CREATE_DRAFT_RFQ,
  RFQ_MODULE,
  SET_SELECTED_PART_HASHES,
  SET_REVISE_REQUOTE_MODAL_RFQS,
  UPDATE_DRAFT,
} from '../store/modules/rfq/types';
import { getNameAndExtension, getMimeType } from '@/app-buyer/mixins/base-upload';
import { DRAFT_RFQ_MODEL } from '@/shared/consts/slugs';

const modifyMixin = {
  mixins: [notificationInjection, programmaticUpload],
  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),
  },
  methods: {
    ...mapActions(RFQ_MODULE, {
      BATCH_CREATE_DRAFT_RFQ,
      updateDraft: UPDATE_DRAFT,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_MODAL_RFQS,
    }),
    ...mapMutations(G_SERVE_MODULE, {
      SET,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_SELECTED_PART_HASHES,
    }),
    promptForFiles(model) {
      this.$buefy.dialog.confirm({
        message: 'Would you like to change the uploaded file?',
        type: 'is-v2-supporting-1',
        hasIcon: true,
        confirmText: 'Upload file',
        onConfirm: () => this._triggerUpload((file) => {
          this.createModifiableDraft(null, file);
        }, '', false),
        cancelText: 'Keep file',
        onCancel: (e) => (e === 'button' ? this.redirectToSelectedDraft(model) : null),
      });
    },
    handleReviseRequote({ selectedRfqs, rfqs, listingHash }) {
      // Set an original order to the RFQs
      let rfqsWithOrder = [];

      rfqs.forEach((rfq, i) => {
        rfqsWithOrder = [
          ...rfqsWithOrder,
          {
            ...rfq,
            order: i,
          },
        ];
      });

      this[SET_REVISE_REQUOTE_MODAL_RFQS]({ selectedRfqs, listingHash, rfqs: rfqsWithOrder });
    },
    async redirectToSelectedDraft(rfq) {
      this[SET_SELECTED_PART_HASHES]([rfq.draft_hash]);
      const path = `/project/${rfq.project_hash}/${rfq.draft_hash}`;
      await this.$router.push(path);
    },

    async createModifiableDraft(clearList, modelFile = null) {
      const notification = this._addNotification({
        message: `Creating modifiable duplicate of ${this.rfq.name}`,
        type: 'is-info',
        duration: 5000,
      });
      const {
        name,
        quantity_initial,
        quantity_production,
        project_hash,
        notes,
        revision,
        configuration,
      } = this.rfq;
      Object.keys(configuration).forEach((key) => {
        if (clearList && clearList.includes(configuration[key])) {
          delete configuration[key];
        }
      });
      let properties = {
        name,
        quantity_initial,
        quantity_production,
        project_hash,
        notes,
        revision,
        ...configuration,
        origin_rfq_id: this.rfq.id,
      };

      if (modelFile) {
        const { extension } = getNameAndExtension(modelFile.name);
        const fileTypeId = this[REFERENCE_DATA]?.find((e) => e.slug === extension)?.id;
        const upload_intents = [{
          client_original_name: modelFile.name,
          bytes: modelFile.size,
          mime_type: getMimeType(extension),
          extension,
          type: DRAFT_RFQ_MODEL,
        }];

        properties = {
          ...properties,
          'file-type': fileTypeId,
          name: modelFile.name,
          upload_intents,
        };
      }

      try {
        const response = await this[BATCH_CREATE_DRAFT_RFQ]({ draftRfqs: [properties] });
        console.log(response);
        // TODO connect this once I have a response
        if (!modelFile) await this.copyGServeSpecs(properties);
        //   await this.$router.push(`/project/${project_hash}/${model.hash}`);
      } catch (e) {
        console.log(e);
        // await this.checkArchivedProperties(data.e);
      } finally {
        this._removeNotification(notification.id);
      }
    },

    async copyGServeSpecs(model) {
      const originalParserData = this.rfq.uploads?.find((e) => e.type?.slug === 'rfq-model')?.parser_metadata;
      const isGServeConfigured = originalParserData && originalParserData.parser_slug === 'g-serve';
      // Check if original file is parsed by g-serve
      if (isGServeConfigured) {
        const urn = this.rfq.uploads?.find((e) => e.type?.slug === 'rfq-model')?.parser_metadata?.parser_uuid;
        // Retrieve specification for original file
        const response = await Api.get(
          ENDPOINTS.G_SERVE.SPECIFICATION,
          { __pathParams: { urn } },
        ).catch((e) => e.response);
        const originalFeatures = response?.data?.features;
        // Return if no specification is set
        if (!originalFeatures) return;
        // Poll for parsed new part
        const interval = setInterval(async () => {
          const { data } = await Api.get(
            ENDPOINTS.DRAFT_RFQS.DETAIL,
            { __pathParams: { hash: model.hash } },
          );
          if (!data.data) {
            clearInterval(interval);
            return;
          }
          const newParserData = data.data.uploads?.find((e) => e.type?.slug === 'draft-rfq-model')?.parser_metadata;
          if (newParserData) {
            // Check if new part is also parsed by g-serve
            if (newParserData.parser_slug !== 'g-serve') {
              clearInterval(interval);
              return;
            }
            if (newParserData.parser_uuid) {
              // Retrieve new parts data and replace features with original parts features
              const { data: newData } = await Api.get(
                ENDPOINTS.G_SERVE.DATA,
                { __pathParams: { urn: newParserData.parser_uuid } },
              );
              if (newData) {
                Api.post(ENDPOINTS.G_SERVE.SPECIFICATION, {
                  ...newData,
                  features: originalFeatures,
                }, { __pathParams: { urn: newParserData.parser_uuid } });
                this[SET]({
                  model: data.data,
                  holes: originalFeatures.holes,
                  tolerances: originalFeatures.tolerances,
                  bosses: originalFeatures.bosses,
                  threadInputs: originalFeatures.thread_inputs,
                });
                clearInterval(interval);
              }
            }
          }
        }, 2000);
      }
    },
    async checkArchivedProperties(errors) {
      if (errors.archived_properties?.length) {
        const message = ['Unfortunately the following configurations are not available.<br>'];
        const toClear = [];
        errors.archived_properties.forEach((id) => {
          const propertyArray = Object.values(this.rfq.configuration_object);
          const property = propertyArray?.find((e) => e.id === id);
          const entityName = property?.entity_name;
          const propertyValue = property?.string_value;
          toClear.push(id);

          if (entityName) message.push(`<b>${entityName}: ${propertyValue}</b>`);
        });
        message.push('<br>Would you like to create a new quote request and update the configuration instead?');
        this.$buefy.dialog.confirm({
          message: message.join('<br>'),
          confirmText: 'Create new quote request',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => this.createModifiableDraft(toClear),
        });
      }
    },
  },
};

export default modifyMixin;
