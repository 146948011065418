var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.htmlType,_vm._g(_vm._b({tag:"component",staticClass:"g-button tw-transition-all tw-relative tw-cursor-pointer tw-flex tw-items-center tw-group",class:[
    _vm.btnColor,
    _vm.btnRingColor,
    _vm.btnSize,
    _vm.btnFontWeight,
    _vm.justifyContent,
    _vm.type === 'pill' || _vm.type === 'pill-icon' || _vm.type === 'icon-circle' ? 'tw-rounded-xl' : 'tw-rounded-xs',
    {
      'tw-w-full': _vm.isFullWidth,
      'tw-opacity-40 tw-pointer-events-none': _vm.isDisabled || _vm.isLoading,
     },
  ],attrs:{"type":_vm.nativeType}},'component',_vm.$attrs,false),_vm.$listeners),[(
      _vm.iconLeft &&
      (_vm.type === 'primary-icon' ||
      _vm.type === 'icon' ||
      _vm.type === 'icon-circle' ||
      _vm.type === 'pill-icon' ||
      _vm.type === 'underline')
    )?_c('font-awesome-icon',{staticClass:"tw-pointer-events-none",class:[
        _vm.iconSize,
        _vm.iconColor,
        _vm.type === 'icon' || _vm.type === 'icon-circle' ? '' : _vm.size === 'sm' || _vm.size === 'xs' || _vm.type === 'underline' ? 'tw-mr-1' : 'tw-mr-2',
        {'tw-invisible': _vm.isLoading },
      ],attrs:{"spin":_vm.hasIconSpinning,"icon":['fas', `${_vm.iconLeft}`]}}):_vm._e(),(_vm.type !== 'icon' && _vm.type !== 'icon-circle')?_c('span',{staticClass:"tw-pointer-events-none",class:{'tw-invisible': _vm.isLoading }},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(
      _vm.iconRight &&
     (_vm.type === 'primary-icon' ||
      _vm.type === 'icon' ||
      _vm.type === 'icon-circle' ||
      _vm.type === 'pill-icon' ||
      _vm.type === 'underline')
    )?_c('font-awesome-icon',{staticClass:"tw-pointer-events-none",class:[
        _vm.iconSize,
        _vm.iconColor,
        _vm.type === 'icon' || _vm.type === 'icon-circle' ? '' : _vm.size === 'sm' || _vm.size === 'xs' || _vm.type === 'underline' ? 'tw-ml-1' : 'tw-ml-2',
        {'tw-invisible': _vm.isLoading },
      ],attrs:{"spin":_vm.hasIconSpinning,"icon":['fas', `${_vm.iconRight}`]}}):_vm._e(),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"tw-absolute tw-pointer-events-none",class:_vm.iconSize,attrs:{"icon":"spinner","spin":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }