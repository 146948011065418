/* eslint-disable camelcase */
import path from 'path-browserify';
import axios from 'axios';
import { VIEWER_MODULE } from '@/app-buyer/store/modules/viewer/types';
import {
  CREATE_LOGIN_LINK,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_VIA_LINK,
  LOGOUT,
  MASQUERADING,
  REGISTER,
  SET_LOGGED_IN,
  UPLOAD_BEFORE_AUTH,
} from './types';
import {
  NAVIGATION_MODULE,
  ON_LOGIN_REDIRECT,
  SET_LOGIN_MODAL,
} from '../navigation/types';
import ENDPOINTS from '../../../api/endpoints';
import Api from '../../../api/api';
import router from '../../../router';
import {
  CHANNEL_SETUP,
  GET,
  RESET_STATE,
  TERMS_AND_CONDITIONS_MODAL,
} from '../types';
import { GET_METADATA, USER_DATA, USER_MODULE } from '../user/types';
import {
  ATTACH_RFQS,
  DRAFT_RFQS,
  RFQ_MODULE,
  SET_DRAFT_COUNT,
  SET_UPLOADED_DRAFTS,
} from '../rfq/types';
import { config } from '@/shared/misc/env-variable';
import { PROJECT_MODULE } from '../projects/types';

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  /**
   * Logs in the user
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {string} payload.email
   * @param {string} payload.password
   * @param {string} payload.remember
   * @returns {Promise<Object>}
   */
  async [LOGIN]({ commit, dispatch }, { email, password, remember }) {
    try {
      // fetch CSRF token from Sanctum
      // the X-XSRF-TOKEN gets set to axios automatically
      await axios.get(`${config('API_URL')}${ENDPOINTS.AUTH.CSRF}`);

      const {
        status,
        data: { data },
      } = await axios.post(`${config('API_URL')}${ENDPOINTS.AUTH.LOGIN}`, {
        email,
        password,
        remember,
      }, {
        withCredentials: true,
        withXSRFToken: true,
      });
      // to fix the issue when there is a 'random' 419 CSRF mismatch error when logging in
      if (status === 419) {
        return window.location.href = `${config('BUYER_URL')}`;
      }

      // send the user to the correct domain
      if (data?.roles?.includes('admin') || data?.roles?.includes('superadmin')) {
        return window.location.href = `${config('ADMIN_URL')}`;
      }
      if (data?.roles?.includes('vendor')) {
        return window.location.href = `${config('VENDOR_URL')}`;
      }

      dispatch(LOGIN_SUCCESS, { ...data, email });

      return {
        status,
        data,
      };
    } catch (error) {
      return error.response;
    }
  },
  async [CREATE_LOGIN_LINK]({ commit, dispatch }, { email }) {
    try {
      await axios.get(`${config('API_URL')}${ENDPOINTS.AUTH.CSRF}`);

      const {
        status,
      } = await axios.post(`${config('API_URL')}${ENDPOINTS.AUTH.LOGIN_LINK}`, {
        email,
      }, {
        withCredentials: true,
        withXSRFToken: true,
      });

      return status;
    } catch (error) {
      console.error(error);

      return error.response.status;
    }
  },
  async [LOGIN_VIA_LINK]({ commit, dispatch }, { signedPath }) {
    try {
      await axios.get(`${config('API_URL')}${ENDPOINTS.AUTH.CSRF}`);

      const url = new URL(path.join('api', signedPath), config('API_URL'));

      const {
        status,
        data: { data },
      } = await axios.post(
        url.href,
        {},
        {
          withCredentials: true,
          withXSRFToken: true,
        },
      );

      // send the user to the correct domain
      if (data?.roles?.includes('admin') || data?.roles?.includes('superadmin')) {
        return window.location.href = `${config('ADMIN_URL')}`;
      }
      if (data?.roles?.includes('vendor')) {
        return window.location.href = `${config('VENDOR_URL')}`;
      }

      dispatch(LOGIN_SUCCESS, { ...data, email: data.email });

      if (data?.roles?.includes('buyer')) {
        return window.location.href = `${config('BASE_URL')}`;
      }

      return {
        status,
        data,
      };
    } catch (error) {
      console.log(error);

      return error.response;
    }
  },
  /**
   * Logs out the user
   *
   * @param {Object} context
   * @returns {Promise<boolean>}
   */
  async [LOGOUT]({ state, commit }) {
    commit(`${USER_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${VIEWER_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${PROJECT_MODULE}/${RESET_STATE}`, {}, { root: true });
    commit(`${RFQ_MODULE}/${RESET_STATE}`, {}, { root: true });

    try {
      const { data } = await Api.post(ENDPOINTS.AUTH.LOGOUT);
      window.sessionStorage.removeItem('vuex');

      if (data?.redirect) {
        return window.location.href = data?.redirect;
      }
    } catch (err) {
      console.log(err.response);
    }

    commit(RESET_STATE);
    // commit(SET_ACCESS_TOKEN, {});
    commit(SET_LOGGED_IN, false);
    await router.push('/login');
    return true;
  },
  /**
   * Does the required steps after login
   *
   * @param {Object} context
   * @param {Object} responseData
   * @returns {Promise<boolean>}
   */
  async [LOGIN_SUCCESS]({
    commit, dispatch, rootState, state,
  }, {
    email,
    enabled_features,
  }) {
    if (config('NEXT_URL') && config('OG_BUYER_URL') && !state[UPLOAD_BEFORE_AUTH]) {
      const features = enabled_features || rootState[USER_MODULE]?.[USER_DATA]?.user?.enabled_features;
      const nextSites = ['alpha', 'beta'];
      const nextSiteOn = features?.some((f) => nextSites.includes(f));
      const onNextSite = nextSites.some(substring => window.location.href.includes(substring));

      if (nextSiteOn && !onNextSite) {
        return window.location.href = `${config('NEXT_URL')}`;
      } else if (!nextSiteOn && onNextSite) {
        return window.location.href = `${config('OG_BUYER_URL')}`;
      }
    }

    commit(SET_LOGGED_IN, true);
    const redirectRouteNames = ['rfq-form-no-selected', 'login', 'reset-password'];
    const redirect = rootState[NAVIGATION_MODULE][ON_LOGIN_REDIRECT];
    commit(`${NAVIGATION_MODULE}/${SET_LOGIN_MODAL}`, { visible: false }, { root: true });

    if (!state[UPLOAD_BEFORE_AUTH]) await dispatch(`${USER_MODULE}/${GET}`, {}, { root: true });

    // We seem to need to wait time before calling the Project GET
    // otherwise we return with an empty array
    if (state[UPLOAD_BEFORE_AUTH]) {
      await sleep(1000);
      await dispatch(`${PROJECT_MODULE}/${GET}`, {}, { root: true });
    }

    const isMasquerading = state[MASQUERADING];

    dispatch(`${USER_MODULE}/${GET_METADATA}`, {}, { root: true });
    if (redirect) {
      await router.push(redirect);
    } else if (redirectRouteNames.includes(router.currentRoute.name)) {
      await router.push({ name: 'quote-page' });
    }

    // SEGMENT TRACKING TO REMOVE
    if (!isMasquerading && config('GTM_ENABLED')) {
      const user_id = rootState[USER_MODULE][USER_DATA].user.id;
      // eslint-disable-next-line no-unused-expressions
      window.dataLayer?.push({
        event: 'user-action',
        'interaction-type': false,
        target: null,
        'target-properties': null,
        'user-action-type': 'login',
        user_id,
      });
    }
    // SEGMENT TRACKING TO REMOVE

    // SEGMENT TRACKING
    if (config('SEGMENT_ENABLED')) {
      window.analytics.track('User logged in', {
        email,
        isMasquerading: !!isMasquerading,
      });
      window.analytics.alias(rootState[USER_MODULE][USER_DATA].user.id);
    }

    dispatch(`${USER_MODULE}/${TERMS_AND_CONDITIONS_MODAL}`, { masquerading: isMasquerading }, { root: true });
    return true;
  },
  /**
   * Registers a user
   *
   * @param {Object} context
   * @param {Object} userdata
   * @returns {Promise<Object>}
   */
  async [REGISTER]({ dispatch }, userdata) {
    await axios.get(`${config('API_URL')}${ENDPOINTS.AUTH.CSRF}`);
    // eslint-disable-next-line no-param-reassign
    userdata.user_type = 'buyer';
    const { data, status } = await Api.post(ENDPOINTS.AUTH.REGISTER, userdata)
      .catch((e) => e.response);
    if (status < 300) {
      // SEGMENT TRACKING
      if (config('SEGMENT_ENABLED')) {
        window.analytics.track('User registered', {
          email: userdata.email,
        });
      }

      await dispatch(LOGIN, userdata);
      return {
        status,
        data,
      };
    }
    return {
      status,
      data,
    };
  },
};
