import {
  ALL_RFQS,
  DRAFT_COUNT,
  DRAFT_RFQS,
  FILTERED_PARTS,
  FORM_ERRORS,
  INSTANT_QUOTE_RFQ_IDS,
  LOADING,
  PRESETS,
  PRODUCTION_REQUIREMENTS_MODAL,
  PROGRESSES,
  REVISE_REQUOTE_CONFIG,
  REVISE_REQUOTE_MODAL_RFQS,
  RFQ_UPDATE_HASHES,
  RFQS,
  SELECTED_PART_HASHES,
  SORT_BY,
  UPDATE_CALLS,
  UPLOAD_PROGRESSES,
  UPLOADED_DRAFTS,
  UPLOADING_QUOTES,
  VIEWED,
  UPDATING_PROPERTIES,
  PREVIOUS_QUOTES,
} from './types';
import { CACHE, PAGINATION } from '../types';

export default function getState() {
  return {
    [DRAFT_RFQS]: [],
    [RFQS]: [],
    [PROGRESSES]: {},
    [UPLOADING_QUOTES]: [],
    [CACHE]: {},
    [ALL_RFQS]: [],
    [PAGINATION]: {},
    [FORM_ERRORS]: {},
    [PRESETS]: [],
    [FILTERED_PARTS]: null,
    [UPDATE_CALLS]: {},
    [UPLOAD_PROGRESSES]: [],
    [LOADING]: {},
    [SELECTED_PART_HASHES]: [],
    [VIEWED]: null,
    [SORT_BY]: {
      field: 'created_at',
      type: Date,
      direction: 1,
    },
    [DRAFT_COUNT]: 0,
    [UPLOADED_DRAFTS]: 0,
    [PRODUCTION_REQUIREMENTS_MODAL]: false,
    [RFQ_UPDATE_HASHES]: [],
    [REVISE_REQUOTE_MODAL_RFQS]: {},
    [REVISE_REQUOTE_CONFIG]: [],
    [INSTANT_QUOTE_RFQ_IDS]: [],
    [UPDATING_PROPERTIES]: null,
    [PREVIOUS_QUOTES]: [],
  };
}
