<template>
  <component
    :is="htmlType"
    :type="nativeType"
    v-bind="$attrs"
    v-on="$listeners"
    class="g-button tw-transition-all tw-relative tw-cursor-pointer tw-flex tw-items-center tw-group"
    :class="[
      btnColor,
      btnRingColor,
      btnSize,
      btnFontWeight,
      justifyContent,
      type === 'pill' || type === 'pill-icon' || type === 'icon-circle' ? 'tw-rounded-xl' : 'tw-rounded-xs',
      {
        'tw-w-full': isFullWidth,
        'tw-opacity-40 tw-pointer-events-none': isDisabled || isLoading,
       },
    ]"
  >
    <font-awesome-icon
      v-if="
        iconLeft &&
        (type === 'primary-icon' ||
        type === 'icon' ||
        type === 'icon-circle' ||
        type === 'pill-icon' ||
        type === 'underline')
      "
      :spin="hasIconSpinning"
      :icon="['fas', `${iconLeft}`]"
      :class="[
          iconSize,
          iconColor,
          type === 'icon' || type === 'icon-circle' ? '' : size === 'sm' || size === 'xs' || type === 'underline' ? 'tw-mr-1' : 'tw-mr-2',
          {'tw-invisible': isLoading },
        ]"
      class="tw-pointer-events-none"
    />
    <span
      v-if="type !== 'icon' && type !== 'icon-circle'"
      :class="{'tw-invisible': isLoading }"
      class="tw-pointer-events-none"
    >{{ label }}</span>
    <font-awesome-icon
      v-if="
        iconRight &&
       (type === 'primary-icon' ||
        type === 'icon' ||
        type === 'icon-circle' ||
        type === 'pill-icon' ||
        type === 'underline')
      "
      :spin="hasIconSpinning"
      :icon="['fas', `${iconRight}`]"
      :class="[
          iconSize,
          iconColor,
          type === 'icon' || type === 'icon-circle' ? '' : size === 'sm' || size === 'xs' || type === 'underline' ? 'tw-ml-1' : 'tw-ml-2',
          {'tw-invisible': isLoading },
        ]"
      class="tw-pointer-events-none"
    />
    <font-awesome-icon
      v-show="isLoading"
      icon="spinner"
      :class="iconSize"
      class="tw-absolute tw-pointer-events-none"
      spin
    />
  </component>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasIconSpinning: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    justifyContent: {
      type: String,
      default: 'tw-justify-center',
    },
    htmlType: {
      type: String,
      default: 'button',
      validator: (value) => [
        'button',
        'router-link',
        'a',
      ].includes(value),
    },
    nativeType: {
      type: String,
      default: 'button',
      validator: (value) => [
        'button',
        'submit',
        'reset',
      ].includes(value),
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'primary-icon',
        'pill',
        'pill-icon',
        'underline',
        'icon',
        'icon-circle'
      ].includes(value),
    },
    color: {
      type: String,
      default: 'secondary',
      validator: (value) => [
        'primary',
        'secondary',
        'tertiary',
        'error',
        'success',
        'grey-darkest',
        'grey-dark',
        'grey-light',
        'white',
        'transparent',
        'admin',
      ].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'xs',
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
    fontWeight: {
      type: String,
      default: 'bold',
      validator: (value) => [
        'light',
        'normal',
        'medium',
        'bold',
      ].includes(value),
    },
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    btnSize() {
      if (this.type === 'underline') {
        if (this.size === 'xs') return 'tw-text-xs';
        if (this.size === 'sm') return 'tw-text-sm';
        if (this.size === 'lg') return 'tw-text-lg';
        // md is default
        return 'tw-text-md';
      }

      if (this.type === 'pill' || this.type === 'pill-icon') {
        if (this.size === 'sm' || this.size === 'xs') return 'tw-px-2 tw-py-1 tw-text-sm';
        if (this.size === 'lg') return 'tw-px-3 tw-py-2 tw-text-lg';
        // md is default
        return 'tw-px-3 tw-py-1 tw-text-md';
      }

      if (this.type === 'icon' || this.type === 'icon-circle') {
        if (this.size === 'sm' || this.size === 'xs') return 'tw-h-7 tw-w-7';
        if (this.size === 'lg') return 'tw-h-12 tw-w-12';
        // md is default
        return 'tw-h-10 tw-w-10';
      }

      if (this.size === 'sm' || this.size === 'xs') return 'tw-px-2 tw-py-1 tw-text-sm';
      if (this.size === 'lg') return 'tw-px-4 tw-py-2 tw-text-lg ';
      // md is default
      return 'tw-px-4 tw-py-2 tw-text-md';
    },

    btnFontWeight() {
      if (this.fontWeight === 'light') return 'tw-font-light';
      if (this.fontWeight === 'normal') return 'tw-font-normal';
      if (this.fontWeight === 'medium') return 'tw-font-medium';
      // bold is default
      return 'tw-font-bold';
    },

    iconSize() {
      if (this.size === 'xs') return 'tw-w-2 tw-h-2';
      if (this.size === 'sm') return 'tw-w-3 tw-h-3';
      if (this.size === 'lg') return 'tw-w-4 tw-h-4';
      // md is default
      return 'tw-w-[14px] tw-h-[14px]';
    },

    btnColor() {
      if (this.type === 'underline') {
        if (this.color === 'primary') return `tw-text-primary tw-shadow-none tw-underline`;
        if (this.color === 'tertiary') return `tw-text-tertiary tw-shadow-none tw-underline`;
        if (this.color === 'error') return `tw-text-error tw-shadow-none tw-underline`;
        if (this.color === 'success') return `tw-text-success tw-shadow-none tw-underline`;
        if (this.color === 'grey-darkest') return `tw-text-grey-darkest tw-shadow-none tw-underline`;
        if (this.color === 'grey-dark') return `tw-text-grey-dark tw-shadow-none tw-underline`;
        if (this.color === 'grey-light') return `tw-text-grey-light tw-shadow-none tw-underline`;
        if (this.color === 'white') return `tw-text-white tw-shadow-none tw-underline`;
        if (this.color === 'transparent') return `tw-text-primary tw-shadow-none tw-underline`;
        // secondary is default
        return `tw-text-secondary tw-shadow-none tw-underline`;
      }

      if (this.isInverted) {
        if (this.color === 'primary') return `tw-text-primary xl:hover:tw-bg-primary xl:active:tw-bg-primary xl:hover:tw-text-white xl:active:tw-text-white`;
        if (this.color === 'tertiary') return `tw-text-tertiary xl:hover:tw-bg-tertiary xl:active:tw-bg-tertiary xl:hover:tw-text-white xl:active:tw-text-white`;
        if (this.color === 'error') return `tw-text-error xl:hover:tw-bg-error xl:active:tw-bg-error xl:hover:tw-text-white xl:active:tw-text-white`;
        if (this.color === 'success') return `tw-text-success xl:hover:tw-bg-success xl:active:tw-bg-success xl:hover:tw-text-white xl:active:tw-text-white`;
        if (this.color === 'grey-darkest') return `tw-text-grey-darkest xl:hover:tw-bg-grey-darkest xl:active:tw-bg-grey-darkest xl:hover:tw-text-primary xl:active:tw-text-primary`;
        if (this.color === 'grey-dark') return `tw-text-grey-dark xl:hover:tw-bg-grey-dark xl:active:tw-bg-grey-dark xl:hover:tw-text-primary xl:active:tw-text-primary`;
        if (this.color === 'grey-light') return `tw-text-grey-light xl:hover:tw-bg-grey-light xl:active:tw-bg-grey-light xl:hover:tw-text-primary xl:active:tw-text-primary`;
        if (this.color === 'white') return `tw-text-white xl:hover:tw-bg-white xl:active:tw-bg-white xl:hover:tw-text-primary xl:active:tw-text-primary`;
        if (this.color === 'transparent') return `tw-text-primary tw-bg-transparent tw-shadow-none xl:hover:tw-shadow xl:active:tw-shadow`;
        if (this.color === 'admin') return `tw-text-primary tw-bg-white`;
        // secondary is default
        return `tw-text-secondary xl:hover:tw-bg-secondary xl:active:tw-bg-secondary xl:hover:tw-text-primary xl:active:tw-text-primary`;
      }

      if (this.color === 'primary') return `tw-text-white tw-bg-primary xl:hover:tw-bg-primary-light xl:active:tw-bg-primary-light`;
      if (this.color === 'tertiary') return `tw-text-white tw-bg-tertiary xl:hover:tw-bg-tertiary-hover xl:active:tw-bg-tertiary-hover`;
      if (this.color === 'error') return `tw-text-white tw-bg-error`;
      if (this.color === 'success') return `tw-text-white tw-bg-success`;
      if (this.color === 'grey-darkest') return `tw-text-primary tw-bg-grey-darkest xl:hover:tw-bg-primary-light xl:active:tw-bg-primary-light`;
      if (this.color === 'grey-dark') return `tw-text-primary tw-bg-grey-dark xl:hover:tw-bg-grey-darkest xl:active:tw-bg-grey-darkest`;
      if (this.color === 'grey-light') return `tw-text-primary tw-bg-grey-light xl:hover:tw-bg-grey-dark xl:active:tw-bg-grey-dark`;
      if (this.color === 'white') return `tw-text-primary tw-bg-white xl:hover:tw-bg-grey-lightest xl:active:tw-bg-grey-lightest`;
      if (this.color === 'transparent') return `tw-text-primary tw-bg-transparent`;
      if (this.color === 'admin') return `tw-text-primary tw-bg-white`;
      // secondary is default
      return `tw-text-primary tw-bg-secondary xl:hover:tw-bg-secondary-hover xl:active:tw-bg-secondary-hover`;
    },

    btnRingColor() {
      if (!this.hasBorder || this.type === 'underline' || this.color === 'transparent') return `tw-inset tw-border tw-border-transparent`;
      if (this.color === 'primary') return `tw-inset tw-border tw-border-primary`;
      if (this.color === 'tertiary') return `tw-inset tw-border tw-border-tertiary`;
      if (this.color === 'error') return `tw-inset tw-border tw-border-error`;
      if (this.color === 'success') return `tw-inset tw-border tw-border-success`;
      if (this.color === 'grey-darkest') return `tw-inset tw-border tw-border-grey-darkest`;
      if (this.color === 'grey-dark') return `tw-inset tw-border tw-border-grey-dark`;
      if (this.color === 'grey-light') return `tw-inset tw-border tw-border-grey-light`;
      if (this.color === 'white') return `tw-inset tw-border tw-border-white`;
      if (this.color === 'admin') return `tw-inset tw-border tw-border-grey-light`;
      // secondary is default
      return `tw-inset tw-border tw-border-secondary`;
    },

    iconColor() {
      if (this.color === 'admin') return `tw-text-grey-dark xl:group-hover:tw-text-primary`;

      return `${this.color}`;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
