<template>
  <section
    class="wrapper tw-w-full tw-relative tw-flex tw-text-xs tw-flex-col"
    :class="{ 'has-data': !!rfqData }"
  >
    <HorizontalDotLoader v-show="!rfqData" />
    <article
      v-if="rfqData"
      class="tw-flex tw-text-md tw-items-stretch tw-w-full tw-flex-col tw-flex-wrap sm:tw-flex-row"
    >
      <div class="column">
        <section class="columns">
          <div class="column is-4">
            <PartMappedData :data-mapped="dataMapped.filter(e => e.title && e.value)">
              <template #modelFile>
                <tr>
                  <td class="tw-font-bold">
                    Model file
                  </td>
                  <td class="tw-flex">
                    <g-button
                      :label="modelFile[0].client_original_name"
                      htmlType="a"
                      nativeType="button"
                      type="underline"
                      color="tertiary"
                      fontWeight="normal"
                      @click="downloadItem(
                        {url: modelFile[0].url, label: modelFile[0].client_original_name}
                      )"
                    />
                  </td>
                </tr>
              </template>
              <template
                v-if="supportingFiles.length"
                #supportingFile
              >
                <tr
                  v-for="supportingFile in supportingFiles"
                  :key="supportingFile.hash_name"
                >
                  <td class="tw-text-bold">
                    Supporting file
                  </td>
                  <td class="tw-flex">
                    <g-button
                      :label="supportingFile.client_original_name"
                      htmlType="a"
                      nativeType="button"
                      type="underline"
                      color="tertiary"
                      fontWeight="normal"
                      @click="downloadItem(
                        {url: supportingFile.url, label: supportingFile.client_original_name}
                      )"
                    />
                  </td>
                </tr>
              </template>
            </PartMappedData>
          </div>
          <div class="column is-4">
            <QuoteDetails
              :quote="quoteToShow"
              :rfq="rfq"
            />
          </div>
          <div class="column is-4">
            <QuoteDetails
              v-if="hasProduction"
              :rfq="rfq"
              :quote="prodQuote"
              is-production
            />
          </div>
        </section>
      </div>
    </article>
  </section>
</template>

<script>
/* eslint-disable camelcase */

import { INJECTION_MOULDING, SHEET_METAL, THREE_D_PRINTING } from '@/shared/consts/slugs';
import { downloadItem } from '@/shared/mixins';
import Rfq from '@/app-buyer/models/Rfq';
import QuoteDetails from '@/app-buyer/components/rfq-list/QuoteDetails.vue';
import HorizontalDotLoader
from '@/shared/components/loaders/horizontal-dot-loader/horizontal-dot-loader.vue';
import PartMappedData from '@/app-buyer/components/rfq-list/PartMappedData.vue';
import GButton from '@/common/components/storied/atoms/GButton.vue';
import findSecondaryFinishParentMixin from '@/app-buyer/mixins/findSecondaryFinishParent';
import { getProperty } from '@/app-buyer/mixins/get-property';

const generalPropertiesOrder = [
  'material',
  'tolerance',
  'surface-roughness',
  'primary-finish',
  'secondary-finish',
  'polishing-type',
  'hrc',
  {
    title: 'Inspection',
    key: 'inspection.string_value',
    append: 'video-inspection.string_value',
  },
  'mill-certificate',
  'iso-9001',
  'iso-13485',
  'engraving',
  'laser-marking',
  'screen-printing',
  'xrf-analysis',
  'part-marking',
  'screws',
];

const sheetMetalPropertiesOrder = [
  'material',
  'primary-finish',
  'secondary-finish',
  'thickness',
];

const injectionMouldingPropertiesOrder = [
  'material',
  'a-side-finish',
  'b-side-finish',
];

const threeDPrintingPropertiesOrder = [
  'technology',
  'material',
  'vibro-polishing',
  'colour',
  'primary-finish',
  'secondary-finish',
  'layer-height',
  'infill',
];


const getEntities = (slug) => {
  switch (slug) {
    case INJECTION_MOULDING:
      return injectionMouldingPropertiesOrder;
    case SHEET_METAL:
      return sheetMetalPropertiesOrder;
    case THREE_D_PRINTING:
      return threeDPrintingPropertiesOrder;
    default:
      return generalPropertiesOrder;
  }
};

export default {
  name: 'RfqListDetail',
  components: {
    PartMappedData,
    HorizontalDotLoader,
    QuoteDetails,
    GButton,
  },
  mixins: [
    downloadItem,
    findSecondaryFinishParentMixin,
  ],
  props: {
    rfq: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: () => false,
    },
    quoteGroupId: {
      type: Number,
      default: () => null,
    },
    activeQuoteGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    listing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rfqData: null,
      viewingFile: false,
      filePath: false,
    };
  },
  computed: {
    active() {
      return this.activeQuoteGroups?.find(
        (q) => q.id === this.listing?.id,
      )?.index || 0;
    },
    activeGroup() {
      if (!this.hasMultipleQuotes) {
        return null;
      }
      let activeGroupIndex = 0;

      if (this.activeQuoteGroups) {
        activeGroupIndex = this.activeQuoteGroups?.find(
          (q) => q.id === this.listing?.id,
        )?.index;
      }

      return this.listing?.quoteGroups?.[activeGroupIndex];
    },
    hasMultipleQuotes() {
      return this.listing?.rfqs.some((rfq) => rfq?.quotes?.length > 1);
    },
    isInjectionMoulding() {
      return this.rfqData?.service?.slug === INJECTION_MOULDING;
    },
    dataMapped() {
      if (this.rfqData) {
        const data = getEntities(this.rfqData?.service?.slug).map((entity) => this.getPair(entity));
        data.push({
          title: 'Your notes',
          value: this.clientNotes,
        });
        data.push({
          title: 'Geomiq notes',
          value: this.geomiqNotes,
        });
        return data;
      }
      return [];
    },
    quoteToShow() {
      return this.activeGroup?.id
        ? this.rfqData?.quotes.find((q) => q.quote_group_id === this.activeGroup?.id)
        : this.rfqData?.quotes[0];
    },
    prodQuote() {
      const quote = this.activeGroup?.id
        ? this.rfqData?.quotes.find((q) => q.quote_group_id === this.activeGroup?.id)
        : this.rfqData?.quotes[0];

      const prodQ = quote?.productionQuote ?? null;

      if (prodQ === null) {
        return null
      }

      return {
        ...prodQ,
        unit_cost: prodQ?.quoted_price ? (prodQ.quoted_price / this.rfqData?.quantity_production).toString() : null,
        subtotal: prodQ?.quoted_price,
      };
    },
    geomiqNotes() {
      const notes = this.quoteToShow?.notes?.map((e) => e.content).join(' | ');
      const secondaryMessage = 'We are able to produce this part as requested (Subject to second stage DFM approval)';
      const finalMessage = 'We are reviewing the manufacturability of your part.';
      if (notes && this.quoteToShow?.subtotal) return notes;
      if (this.quoteToShow?.subtotal) return secondaryMessage;
      return finalMessage;
    },
    clientNotes() {
      return this.rfqData?.notes;
    },
    hasProduction() {
      return !!this.rfqData.quantity_production;
    },
    modelFile() {
      return this.rfq?.uploads?.filter((u) => u.type?.slug === 'rfq-model');
    },
    supportingFiles() {
      return this.rfq?.uploads?.filter((u) => u.type?.slug === 'rfq-supporting-file');
    },
  },
  watch: {
    open: {
      handler(value) {
        if (value && !this.rfqData) {
          this.getRfqData();
        }
      },
      immediate: true,
    },
    'rfq.status': function statusHandler() {
      this.getRfqData();
    },
  },
  methods: {
    getConfigData(propertyName) {
      return this.rfqData?.[propertyName]?.string_value;
    },
    getPair(entity) {
      if (typeof entity === 'string') {
        const config = this.rfqData[entity];
        if (entity === 'secondary-finish') {
          const parent = this.findSecondaryFinishParent(config);
          if (parent) {
            return {
              title: config?.entity_name,
              value: `${parent?.string_value}: ${config?.string_value}`,
            };
          }
        }
        return {
          title: config?.entity_name,
          value: config?.string_value,
        };
      }
      const {
        title, key, applyFilter, delay, append,
      } = entity;
      let value = getProperty(this.rfqData, key);
      if (key === 'proposal.part_entry.unit_cost') {
        value *= 0.01;
      }

      if (append) {
        const appendable = getProperty(this.rfqData, append);
        if (appendable) {
          value += ` <br> ${appendable}`;
        }
      }

      return {
        title,
        value,
        applyFilter,
        delay,
      };
    },
    viewSupporting(file) {
      this.filePath = file.url;
      this.viewingFile = true;
    },
    async getRfqData() {
      const query = new Rfq();
      query.include([
        'configurationProperties',
        'sentQuotes.entries.type',
        'sentQuotes.notes',
        'sentQuotes.productionQuote',
      ]);
      const {
        data: {
          notes,
          quantity_initial,
          quantity_production,
          configuration_object,
          quotes,
        },
      } = await query.find(this.rfq.hash);
      this.rfqData = {
        ...configuration_object,
        notes,
        quantity_initial,
        quantity_production,
        quotes,
      };
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

section.wrapper {
  max-height: 2rem;
  transition: max-height .5s;

  &.has-data {
    max-height: 100rem;
  }
}

::v-deep {
  .table {
    background-color: transparent;
    max-width: 500px;
    width: 100%;

    th {
      font-size: 0.75rem;
      border-bottom: 1px solid variables.$g-light-2 !important;
      padding-bottom: 1rem;
    }

    tr:first-child td {
      padding-top: 1rem;
    }

    td {
      border: none;
    }
  }
}

.is-flex-direction-column-mobile {
  @media all and (max-width: variables.$desktop) {
    flex-direction: column;
  }
}

embed {
  min-height: 80vh;
  width: 100%;
}
</style>
