export const ADD_TO_DRAFTS = 'ADD_TO_DRAFTS';
export const SET_PROGRESS = 'SET_PROGRESS';
export const PROGRESSES = 'PROGRESSES';
export const BASIC_DRAFT_RFQS = 'BASIC_QUOTES';
export const INSTANT_DRAFT_RFQS = 'INSTANT_DRAFT_RFQS';
export const DRAFT_RFQS = 'DRAFT_RFQS';
export const UPLOADING_QUOTES = 'UPLOADING_QUOTES';
export const RFQS = 'RFQS';
export const SELECTED = 'SELECTED';
export const ACTIVE = 'ACTIVE';
export const ONE_ACTIVE = 'ONE_ACTIVE';
export const SET_PROPERTY = 'SET_PROPERTY';
export const UPDATE_DRAFT = 'UPDATE_DRAFT';
export const UPDATE_RFQ = 'UPDATE_RFQ';
export const RFQ_DETAIL = 'RFQ_DETAIL';
export const DRAFT_DETAIL = 'DRAFT_DETAIL';
export const GET_ALL_RFQS_BY_PROJECT = 'GET_ALL_RFQS_BY_PROJECT';
export const GET_ALL_RFQS = 'GET_ALL_RFQS';
export const ALL_RFQS = 'ALL_RFQS';
export const CREATE_DRAFT_RFQ = 'CREATE_DRAFT_RFQ';
export const CREATE_RFQS = 'CREATE_RFQS';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const FORM_ERRORS = 'FORM_ERRORS';
export const MOVE_RFQ = 'MOVE_RFQ';
export const APPEND_FILE = 'APPEND_FILE';
export const GET_PRESETS = 'GET_PRESETS';
export const SET_PRESETS = 'SET_PRESETS';
export const PRESETS = 'PRESETS';
export const ATTACH_RFQS = 'ATTACH_RFQS';
export const FILTERED_PARTS = 'FILTERED_PARTS';
export const SET_FILTERED = 'SET_FILTERED';
export const SWAP_UPLOADING = 'SWAP_UPLOADING';
export const UPDATE_CALLS = 'UPDATE_CALLS';
export const DEBOUNCE_DRAFT_UPDATE = 'DEBOUNCE_DRAFT_UPDATE';
export const SAVE_DRAFT_UPDATE = 'SAVE_DRAFT_UPDATE';
export const STEP_DRAFTS = 'STEP_DRAFTS';
export const PDF_DRAFTS = 'PDF_DRAFTS';
export const IGS_DRAFTS = 'IGS_DRAFTS';
export const DWG_DRAFTS = 'DWG_DRAFTS';
export const DXF_DRAFTS = 'DXF_DRAFTS';
export const STL_DRAFTS = 'STL_DRAFTS';
export const UPLOADING_PARTS = 'UPLOADING_PARTS';
export const UPLOAD_PROGRESSES = 'UPLOAD_PROGRESSES';
export const ADD_UPLOAD_PROGRESS = 'ADD_UPLOAD_PROGRESS';
export const UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS';
export const SORT_PARTS = 'SORT_PARTS';
export const BATCH_CREATE_DRAFT_RFQ = 'BATCH_CREATE_DRAFT_RFQ';
export const RFQ_MODULE = 'RFQ_MODULE';
export const SET_LOADING = 'SET_LOADING';
export const LOADING = 'LOADING';
export const UPLOAD_PERCENT = 'UPLOAD_PERCENT';
export const PARTS_IN_CURRENT_PROJECT_COUNT = 'PARTS_IN_CURRENT_PROJECT_COUNT';
export const SELECTED_PART_HASHES = 'SELECTED_PART_HASHES';
export const SET_SELECTED_PART_HASHES = 'SET_SELECTED_PART_HASHES';
export const GET_FIRST_SELECTED_PART = 'FIRST_SELECTED_PART';
export const GET_ALL_SELECTED_PARTS = 'GET_ALL_SELECTED_PARTS';
export const DRAFT_RFQ_ISSUES = 'DRAFT_RFQ_ISSUES';
export const RFQ_ISSUES = 'RFQ_ISSUES';
export const REMOVE_SUPPORTING_FILE = 'REMOVE_SUPPORTING_FILE';
export const SWAP_SUPPORTING_FILES = 'SWAP_SUPPORTING_FILES';
export const VIEWED = 'VIEWED';
export const SET_VIEWED = 'SET_VIEWED';
export const GET_VIEWED = 'GET_VIEWED';
export const SORTED_DRAFTS = 'SORTED_DRAFTS';
export const SORT_BY = 'SORT_BY';
export const SET_SORT_BY = 'SET_SORT_BY';
export const DRAFT_COUNT = 'DRAFT_COUNT';
export const SET_DRAFT_COUNT = 'SET_DRAFT_COUNT';
export const UPLOADED_DRAFTS = 'UPLOADED_DRAFTS';
export const SET_UPLOADED_DRAFTS = 'SET_UPLOADED_DRAFTS';
export const PRODUCTION_REQUIREMENTS_MODAL = 'PRODUCTION_REQUIREMENTS_MODAL';
export const SET_PRODUCTION_REQUIREMENTS_MODAL = 'SET_PRODUCTION_REQUIREMENTS_MODAL';
export const SET_REVISE_REQUOTE_MODAL_RFQS = 'SET_REVISE_REQUOTE_MODAL_RFQS';
export const REVISE_REQUOTE_MODAL_RFQS = 'REVISE_REQUOTE_MODAL_RFQS';
export const REVISE_REQUOTE_CONFIG = 'REVISE_REQUOTE_CONFIG';
export const SET_REVISE_REQUOTE_CONFIG = 'SET_REVISE_REQUOTE_CONFIG';
export const BATCH_DELETE = 'BATCH_DELETE';
export const REVISE_RFQS = 'REVISE_RFQS';
export const REVISE_REQUOTE_ISSUES = 'REVISE_REQUOTE_ISSUES';
export const REVISE_REQUOTE_SELECTED_RFQS = 'REVISE_REQUOTE_SELECTED_RFQS';
export const SET_RFQ_UPDATE_HASHES = 'SET_RFQ_UPDATE_HASHES';
export const RFQ_UPDATE_HASHES = 'RFQ_UPDATE_HASHES';
export const GET_INSTANT_QUOTE = 'GET_INSTANT_QUOTE';
export const CALL_INSTANT_QUOTE = 'CALL_INSTANT_QUOTE';
export const SET_INSTANT_QUOTE = 'SET_INSTANT_QUOTE';
export const SET_INSTANT_QUOTE_RFQ_IDS = 'SET_INSTANT_QUOTE_RFQ_IDS';
export const INSTANT_QUOTE_RFQ_IDS = 'INSTANT_QUOTE_RFQ_IDS';
export const SHOULD_SHOW_PRODUCTION_QUANTITY = 'SHOULD_SHOW_PRODUCTION_QUANTITY';
export const SAVE_DRAFT_FILE_UPDATE = 'SAVE_DRAFT_FILE_UPDATE';
export const SET_UPDATING_PROPERTIES = 'SET_UPDATING_PROPERTIES';
export const UPDATING_PROPERTIES = 'UPDATING_PROPERTIES';
export const INSTANT_QUOTE_FILE_TYPES = 'INSTANT_QUOTE_FILE_TYPES';
export const DRAFT_CLONE = 'DRAFT_CLONE';
export const GET_PREVIOUS_QUOTES = 'GET_PREVIOUS_QUOTES';
export const SET_PREVIOUS_QUOTES = 'SET_PREVIOUS_QUOTES';
export const PREVIOUS_QUOTES = 'PREVIOUS_QUOTES';
export const SET_PREVIOUS_QUOTE = 'SET_PREVIOUS_QUOTE';
