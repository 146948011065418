import Vue from 'vue';
import {
  _ADD_NOTIFICATION,
  _NOTIFICATION_MODULE,
} from '@/shared/components/notification/vuex-setup';
import {
  BATCH_RFQ_SUBMISSION_FAILED_EVENT,
  BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT,
  BATCH_RFQ_UPDATE_FAILED_EVENT,
  BATCH_RFQ_UPDATE_SUCCEEDED_EVENT,
  CART_PAYMENT_FAILED_EVENT,
  CART_PAYMENT_SUCCESSFUL_EVENT,
  WEBSOCKET_TEST_EVENT,
  ORDERS_CREATION_FAILED_EVENT,
  ORDERS_CREATION_SUCCEEDED_EVENT,
  QUOTE_STATUS_CHANGED_EVENT,
  RFQ_CREATED_EVENT,
  RFQ_STATUS_CHANGED,
  RFQ_UPDATED_EVENT,
  SETUP,
} from './types';
import { CART_MODULE, SET_PAYMENT_PROGRESS } from '../cart/types';
import { GET } from '../types';
import { PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';
import {
  ALL_RFQS,
  RFQ_MODULE,
  RFQS,
} from '../rfq/types';
import { GET_METADATA, USER_MODULE } from '../user/types';
import {
  GET_QUOTES,
  PENDING_REVISIONS,
  QUOTES,
  QUOTES_LOADING,
  QUOTES_MODULE,
  REQUESTED_QUOTE_INFO,
  SET_PENDING_REVISIONS,
  SET_QUOTES,
  SET_QUOTES_LOADING,
  SET_REQUESTED_QUOTE_INFO,
} from '@/app-buyer/store/modules/quotes/types';

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const updateQuoteRfq = async ({
  rootState, commit, dispatch, eventRfq,
}) => {
  return;

  // if (!rootState[QUOTES_MODULE][QUOTES]?.length) {
  //   await dispatch(`${QUOTES_MODULE}/${GET_QUOTES}`, { page: 1 }, { root: true });
  // }
  // const quotes = rootState[QUOTES_MODULE][QUOTES];
  //
  // if (!quotes?.length) {
  //   await sleep(2500);
  //   await updateQuoteRfq({
  //     rootState, commit, dispatch, eventRfq,
  //   });
  //   return;
  // }
  //
  // const quoteToUpdate = quotes.filter((q) => q.id === eventRfq.job_listing?.id);
  //
  // if (!quoteToUpdate?.length) return;
  //
  // if (!quoteToUpdate[0]?.rfqCreatedEventCount) quoteToUpdate[0].rfqCreatedEventCount = 1;
  // else quoteToUpdate[0].rfqCreatedEventCount += 1;
  //
  // const requestedQuoteInfo = rootState[QUOTES_MODULE]?.[REQUESTED_QUOTE_INFO]?.filter(
  //   (qInfo) => qInfo.id === eventRfq.job_listing?.id,
  // );
  //
  // quoteToUpdate[0].active_rfq_count = requestedQuoteInfo?.[0]?.rfqs_count;
  //
  // if (quoteToUpdate[0]?.rfqCreatedEventCount <= 5) {
  //   const indexToReplace = quoteToUpdate[0].rfqs.findIndex((rfq) => rfq.hash === eventRfq.hash);
  //
  //   if (indexToReplace >= 0) quoteToUpdate[0].rfqs.splice(indexToReplace, 1, eventRfq);
  //   else quoteToUpdate[0].rfqs = [...quoteToUpdate[0].rfqs, eventRfq];
  // }
  //
  // const qIndex = quotes.findIndex((q) => q.id === eventRfq.job_listing?.id);
  // quotes.splice(qIndex, 1, quoteToUpdate[0]);
  //
  // setTimeout(() => {
  //   commit(`${QUOTES_MODULE}/${SET_QUOTES}`, quotes, { root: true });
  //
  //   if (quoteToUpdate?.[0]?.rfqCreatedEventCount >= requestedQuoteInfo?.[0]?.rfqs_count) {
  //     commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`,
  //       rootState[QUOTES_MODULE]?.[REQUESTED_QUOTE_INFO]?.filter(
  //         (qInfo) => qInfo.id !== eventRfq.job_listing?.id,
  //       ),
  //       { root: true });
  //   }
  // }, 500);
};

export default {
  /**
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.id
   * @param {string} payload.token
   */
  [SETUP]({ commit, state, dispatch }, { id, token }) {
    // eslint-disable-next-line no-undef
    Echo.connector.options.auth.headers.Authorization = `Bearer ${token}`;
    // eslint-disable-next-line no-undef
    commit('setChannel', Echo.private(`User.${id}`));
    // eslint-disable-next-line no-restricted-syntax
    for (const WSEvent of Object.keys(state.channels)) {
      state.channel.listen(WSEvent, (e) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const action of state.channels[WSEvent]) {
          dispatch(action, e);
        }
      });
    }
  },
  /**
   * Test event to trigger notification
   * @param context
   * @param event
   */
  [WEBSOCKET_TEST_EVENT]({ commit }, event) {
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      message: event.message,
      type: 'is-info',
    }, { root: true });
  },
  /**
   * Event to trigger successful payment indicators
   * @param commit
   */
  async [CART_PAYMENT_SUCCESSFUL_EVENT]({ commit }) {
    commit(`${CART_MODULE}/${SET_PAYMENT_PROGRESS}`, {
      status: 1,
      message: 'payment successful',
      type: 'is-success',
    }, { root: true });
    commit(`${CART_MODULE}/${SET_PAYMENT_PROGRESS}`, {
      status: 3,
      message: 'order created',
    }, { root: true });
  },
  /**
   * Event to trigger failed payment indicators
   * @param commit
   */
  [CART_PAYMENT_FAILED_EVENT]({ commit }) {
    commit(`${CART_MODULE}/${SET_PAYMENT_PROGRESS}`, {
      status: 2,
      message: 'payment failed',
    }, { root: true });
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      message: 'Payment failed!',
      confirmText: 'Ok',
      indefinite: true,
      type: 'is-danger',
    }, { root: true });
  },
  /**
   * Update quote status
   * TODO update quote data too so when the quote is recieved the price can be set too
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.quoteId
   * @param {string} payload.status
   */
  [QUOTE_STATUS_CHANGED_EVENT]({ rootState, commit }, { quoteId, status }) {
    commit(RFQ_STATUS_CHANGED, { id: quoteId, status });
    rootState[RFQ_MODULE][ALL_RFQS].some((e) => {
      if (e.id === quoteId) {
        Vue.set(e, 'status', status);
        return e.id === quoteId;
      }
      return false;
    });
    rootState[RFQ_MODULE][RFQS].some((e) => {
      if (e.id === quoteId) {
        Vue.set(e, 'status', status);
        return e.id === quoteId;
      }

      return false;
    });
  },
  /**
   * After payment this event shows if the order creation failed
   * @param {Object} context
   */
  [ORDERS_CREATION_FAILED_EVENT]({ commit }) {
    commit(`${CART_MODULE}/${SET_PAYMENT_PROGRESS}`, {
      status: 2,
      message: 'order creation failed',
    }, { root: true });
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      status: 4,
      message: 'order creation failed',
      indefinite: true,
      confirmText: 'Ok',
      type: 'is-danger',
      unique: true,
    }, { root: true });
  },
  /**
   * After payment this event shows if the order is created successfully
   * @param {Object} context
   */
  [ORDERS_CREATION_SUCCEEDED_EVENT]({ commit, dispatch }) {
    commit(`${CART_MODULE}/${SET_PAYMENT_PROGRESS}`, {
      status: 3,
      message: 'order created',
    }, { root: true });
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      message: 'Order created',
      confirmText: 'Ok',
      type: 'is-success',
      unique: true,
    }, { root: true });
    dispatch(`${USER_MODULE}/${GET_METADATA}`, {}, { root: true });
  },

  async [RFQ_CREATED_EVENT]({ rootState, commit, dispatch }, eventRfq) {
    await updateQuoteRfq({
      rootState, commit, dispatch, eventRfq,
    });
  },

  [BATCH_RFQ_SUBMISSION_SUCCEEDED_EVENT]({ commit }) {
    commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, null, { root: true });
  },

  [BATCH_RFQ_SUBMISSION_FAILED_EVENT]({ commit }) {
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      status: 4,
      message: 'RFQ submission failed',
      indefinite: true,
      confirmText: 'Ok',
      type: 'is-danger',
    }, { root: true });
    commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, null, { root: true });
  },

  async [RFQ_UPDATED_EVENT]({ rootState, commit, dispatch }, eventRfq) {
    if (rootState[QUOTES_MODULE][QUOTES_LOADING]) commit(`${QUOTES_MODULE}/${SET_QUOTES_LOADING}`, false, { root: true });
    await updateQuoteRfq({
      rootState, commit, dispatch, eventRfq,
    });
    commit(`${QUOTES_MODULE}/${SET_PENDING_REVISIONS}`, rootState[QUOTES_MODULE]?.[PENDING_REVISIONS]?.filter((hash) => hash !== eventRfq.hash), { root: true });
  },

  [BATCH_RFQ_UPDATE_SUCCEEDED_EVENT]({ commit }) {
    commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, null, { root: true });
    commit(`${QUOTES_MODULE}/${SET_PENDING_REVISIONS}`, null, { root: true });
  },

  [BATCH_RFQ_UPDATE_FAILED_EVENT]({ commit }) {
    commit(`${_NOTIFICATION_MODULE}/${_ADD_NOTIFICATION}`, {
      status: 4,
      message: 'RFQ update failed',
      indefinite: true,
      confirmText: 'Ok',
      type: 'is-danger',
    }, { root: true });
    commit(`${QUOTES_MODULE}/${SET_REQUESTED_QUOTE_INFO}`, null, { root: true });
    commit(`${QUOTES_MODULE}/${SET_PENDING_REVISIONS}`, null, { root: true });
  },
};
