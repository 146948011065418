<template>
  <div
    v-if="shouldShowMembers"
    class="tw-flex tw-flex-wrap"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-for="member in members"
      :key="member.id"
      class="member-avatar-wrapper tw-transition-all"
      :class="isHovered ? 'tw-mr-05' : 'tw-mr-05 xl:tw--mx-1'"
    >
      <MemberAvatar :user="member.user" />
    </div>
    <div v-if="project.members?.length > this.max && !showAll">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-w-7 tw-h-7 tw-bg-grey-light tw-text-grey-darkest tw-rounded-xxl tw-text-sm tw-font-bold tw-border tw-border-grey-lighter tw-cursor-pointer"
        @click="handleShowAll"
      >
        <p>+{{ project.members?.length - members.length }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MemberAvatar from '@/app-buyer/components/teams/MemberAvatar.vue';
import { GET_USER_ID, USER_MODULE } from '@/app-buyer/store/modules/user/types';

export default {
  name: 'ProjectMemberAvatars',

  components: {
    MemberAvatar,
  },

  props: {
    project: {
      type: Object,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isHovered: false,
      showAll: false,
    }
  },

  computed: {
    ...mapGetters(USER_MODULE, {
      GET_USER_ID,
    }),

    shouldShowMembers() {
      return this.project?.members?.some((m) => m?.user?.id !== this[GET_USER_ID]);
    },

    members() {
      if (this.max === null || this.showAll) return this.project.members;
      return this.project.members.slice(0, this.max);
    },
  },

  methods: {
    handleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
