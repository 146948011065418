<template>
  <div class="tw-relative tw-flex-grow">
    <div
      v-if="calculating"
      class="tw-flex tw-absolute tw-top-0 tw-right-0 tw-text-grey-dark tw-items-center tw-text-sm"
    >
      <font-awesome-icon
        icon="spinner"
        spin
        class="tw-mr-1"
      />
      Saving changes
    </div>
    <p class="tw-mb-2 tw-text-lg tw-font-bold">
      Configure
      <template v-if="selectedPartName">
        {{ selectedPartName }}
      </template>
      <template v-if="selectedPartAmount > 1">
        ({{ selectedPartAmount }})
      </template>
    </p>
    <template v-if="selectedPartsInCart">
      <g-message
        iconLeft="lock"
        isFullWidth
        :hasLeftBorder="false"
        size="md"
        color="grey-lighter"
        iconColor="grey-dark"
        class="tw-items-start"
      >
        <div class="tw-flex tw-flex-col tw-items-start">
          <p class="tw-text-sm tw-font-bold">{{
              selectedPartsInCart === GET_ALL_SELECTED_PARTS?.length ? `Your selected quote${selectedPartsInCart === 1 ? ' is' : 's are'} locked` : `${selectedPartsInCart} of your selected quotes ${selectedPartsInCart > 1 ? 'are' : 'is'} locked`
            }}</p>
          <p class="tw-text-sm">{{
              GET_ALL_SELECTED_PARTS?.length > 1 ? 'To make changes, please make sure all of your selected parts have been removed from the cart first.' :
                'This part has been added to the cart.To make changes to it, please remove it from the cart first.'
            }}</p>

          <g-button
            isInverted
            :label="`Remove ${selectedPartsInCart} part${selectedPartsInCart > 1 ? 's' : ''} from cart`"
            color="error"
            iconLeft="trash"
            type="primary-icon"
            size="sm"
            class="tw-mt-1"
            @click="removeSelectedPartsFromCart"
          />
        </div>
      </g-message>
    </template>
    <template v-if="isSelectedAwaitingQuote && !selectedPartsInCart">
      <g-message
        iconLeft="info-circle"
        isFullWidth
        :hasLeftBorder="false"
        size="md"
        color="grey-lighter"
        iconColor="grey-dark"
        class="tw-items-start"
      >
        <div class="tw-flex tw-flex-col">
          <p class="tw-text-sm tw-font-bold">Awaiting quote</p>
          <p class="tw-text-sm">{{
              GET_ALL_SELECTED_PARTS?.length > 1 ? 'You have requested a manual quote for one of your selected parts part. Making any new changes will create a new quote.' :
                'You have requested a manual quote for this part. Making any new changes will create a new quote.'
            }}
          </p>
        </div>
      </g-message>
    </template>
    <template v-if="!!selectedPartAmount">
      <PartFormDraftConfigurator
        :parts="GET_ALL_SELECTED_PARTS"
        :is-read-only="isReadOnly"
        class="tw-pt-2"
      />
    </template>
    <PartFormPlaceholder
      v-else
      :has-parts="hasParts"
      class="tw-mt-6"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
  RFQ_UPDATE_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import PartFormPlaceholder from '@/app-buyer/components/project/PartFormPlaceholder.vue';
import PartFormDraftConfigurator
  from '@/app-buyer/components/project/PartFormDraftConfigurator.vue';
import GMessage from '@common/components/storied/molecules/GMessage.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { truncateStringInMiddle } from '@common/helpers/index';

export default {
  name: 'PartForm',

  components: {
    PartFormDraftConfigurator,
    PartFormPlaceholder,
    GMessage,
    GButton,
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      RFQ_UPDATE_HASHES,
    }),
    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
    }),

    isSelectedAwaitingQuote() {
      return this[GET_ALL_SELECTED_PARTS]?.some(part => part.quote_status?.toLowerCase() === 'awaiting quote');
    },

    selectedPartsInCart() {
      return this[GET_ALL_SELECTED_PARTS]?.filter?.(part => part.cart_item_id)?.length || 0;
    },

    calculating() {
      return !!this[RFQ_UPDATE_HASHES]?.length;
    },

    selectedPartName() {
      return this[GET_ALL_SELECTED_PARTS]?.length === 1 ? truncateStringInMiddle({ str: this[GET_ALL_SELECTED_PARTS]?.[0]?.name, maxLength: 30 }) : ' parts';
    },

    selectedPartAmount() {
      return this[GET_ALL_SELECTED_PARTS]?.length;
    },

    hasParts() {
      return !!this[DRAFT_RFQS]?.length;
    },

    isReadOnly() {
      return !!this.selectedPartsInCart;
    },

    // readOnlyConfig() {
    //   const configs = [];
    //
    //   this[GET_ALL_SELECTED_PARTS]?.forEach((part) => {
    //     Object.keys(part.configuration_object).forEach((key) => {
    //       configs.push({
    //         entity_name: part.configuration_object[key]?.entity_name,
    //         name: part.configuration_object[key].name,
    //       })
    //     });
    //   })
    //
    //   return configs.reduce((acc, item) => {
    //     if (!acc[item.entity_name]) {
    //       acc[item.entity_name] = [];
    //     }
    //     acc[item.entity_name].push(item.name);
    //     return acc;
    //   }, {});
    // },
  },

  methods: {
    removeSelectedPartsFromCart() {
      this.$emit('remove-from-cart', { removeAllInCart: false })
    },

    // formatValues(values) {
    //  return Object.entries(values.reduce((acc, curr) => {
    //     acc[curr] = (acc[curr] || 0) + 1;
    //     return acc;
    //   }, {})).map(([key, value]) => `${key} x${value}`);
    // }
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep {
  .collapse-trigger > button.button,
  .dropdown-trigger > button.button {
    min-height: 35px;
  }

  .field.has-addons .button {
    border-radius: 4px;
    min-height: 35px;
  }
}
</style>
