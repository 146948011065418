<template>
  <g-modal
    :is-active.sync="isModalActive"
    :can-cancel="false"
    modalWidth="100%"
    modalHeight="100%"
  >
    <div
      v-if="initialOptions"
      class="tw-m-4 tw-rounded-xs tw-bg-white tw-p-8 tw-text-md"
      style="max-width: 600px"
    >
      <p class="tw-mb-3 tw-text-xl tw-font-bold tw-leading-8 tw-text-primary">
        We are always working to improve our services. To do this we use
        cookies.
      </p>
      <p
        class="tw-text-primary tw-pb-3"
      >
        We would like to use our own and third party cookies to improve our
        product and services.
      </p>
      <p>
        By clicking “Accept” you agree to our website's cookie use as described in our <span><a
        class="tw-text-primary tw-underline"
        href="https://geomiq.com/privacy-policy/#:~:text=support%40Geomiq.com-,COOKIES,-Below%20is%20a"
        target="_blank"
      >
          Cookie Policy
        </a></span>. You can change your cookie settings at any time by clicking “Manage
        Preferences.”
      </p>
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-2 tw-mt-3 tw-justify-between tw-items-center">
        <g-button
          isInverted
          label="Manage preferences"
          color="tertiary"
          @click="initialOptions = false"
        />
        <g-button
          label="Accept cookies"
          @click="enableCookies"
        />
      </div>
    </div>
    <cookie-manage-preferences
      v-else
      @goBack="initialOptions = $event"
      @handleCookies="handleCookies"
    />
  </g-modal>
</template>

<script>
import GModal from '@/app-buyer/components/GModal.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GToggle from '@common/components/storied/atoms/GToggle.vue';
import externalScripts from '@/app-buyer/mixins/external-scripts';
import CookieManagePreferences from '@/app-buyer/components/cookies/CookieManagePreferences.vue';
import { mapState, mapMutations } from 'vuex';
import { USER_MODULE, COOKIE_PREFERENCES, SET_COOKIE_PREFERENCES } from '@/app-buyer/store/modules/user/types';
import getEnvironmentVariable from '@/shared/misc/env-variable';

export default {
  name: 'CookiePopup',

  components: {
    GModal,
    CookieManagePreferences,
    GButton,
    GToggle,
  },

  data() {
    return {
      isCookieSet: false,
      initialCheck: false,
      initialOptions: true,
      advertising: true,
      analytics: true,
      functional: true,
    };
  },

  mixins: [externalScripts],

  computed: {
    ...mapState(USER_MODULE, {
      COOKIE_PREFERENCES,
    }),

    isSet() {
      return this.isCookieSet;
    },

    isModalActive() {
      return this.initialCheck && !this.isSet;
    },

    selectedOptions() {
      return {
        functional: this.functional,
        analytics: this.analytics,
        advertising: this.advertising,
      }
    },
  },

  mounted() {
    if (getEnvironmentVariable('VUE_APP_ENV') !== 'production') return;

    this.getCookie();

    if (
      this[COOKIE_PREFERENCES]?.analytics ||
      this[COOKIE_PREFERENCES]?.advertising
    ) this.addScripts();
  },

  methods: {
    ...mapMutations(USER_MODULE, {
      SET_COOKIE_PREFERENCES,
    }),

    handleCookies({ functional, analytics, advertising }) {
      this.functional = functional;
      this.analytics = analytics;
      this.advertising = advertising;

      this.enableCookies()
    },

    enableCookies() {
      this.setCookie({ ...this.selectedOptions });
      this.isCookieSet = true;
      if (this.selectedOptions.advertising || this.selectedOptions.analytics) this.addScripts();
    },

    getCookie() {
      this.initialCheck = true;
      const gCookie = 'gcookie=';
      // Split the cookie string into individual cookies
      const cookies = document.cookie.split(';');

      // Iterate over each cookie to find the one matching the name
      for (let cookie of cookies) {
        // Trim leading spaces
        cookie = cookie.trim();
        // Check if the cookie starts with the desired name
        if (cookie.indexOf(gCookie) === 0) {
          // Return the value of the matched cookie
          let cookieValue = cookie.substring(gCookie.length);
          cookieValue = JSON.parse(cookieValue);
          this[SET_COOKIE_PREFERENCES](cookieValue);
          this.isCookieSet = true;
          return cookie;
        }
      }
      // Return null if the cookie is not found
      return null;
    },

    setCookie(preferences) {
      const date = new Date();
      // Set the expiration date
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      // Set the cookie with desired attributes
      document.cookie = `gcookie=${JSON.stringify(preferences)}; ${expires}; path=/; domain=.geomiq.com; SameSite=Lax; Secure`;

      this[SET_COOKIE_PREFERENCES](this.selectedOptions);
    },
  },
};
</script>

<style scoped></style>
