
export const getInitials = ({ name }) => {
  const names = name.split(' ');
  if (names?.length > 1) return `${names[0].substring(0, 1)}${names[names.length - 1].substring(0, 1)}`;

  return `${names[0].substring(0, 1)}`;
}

export const truncateStringInMiddle = ({ str, maxLength }) => {
  const ellipsis = '...';
  const lastPartLength = 6;

  if (str.length <= maxLength) {
    return str;
  }

  const firstPartLength = maxLength - lastPartLength - ellipsis.length;
  const firstPart = str.substring(0, firstPartLength);
  const lastPart = str.substring(str.length - lastPartLength);

  return `${firstPart}${ellipsis}${lastPart}`;
}

export function queueHandlerFactory({ maxExecuting, checkTimeout }) {
  return {
    queue: [],
    executing: 0,
    checkTimeout,
    maxExecuting,
    async add(fn) {
      if (this.executing >= this.maxExecuting) {
        this.queue.push(fn);
        return this.waitForExecution(fn);
      }
      return this.execute(fn);
    },
    async execute(fn) {
      this.executing += 1;
      const result = await fn();
      this.executing -= 1;
      return result;
    },
    waitForExecution(fn) {
      return new Promise((resolve) => {
        const obj = this;
        const interval = setInterval(() => {
          if (obj.executing < obj.maxExecuting && obj.queue.indexOf(fn) === 0) {
            clearInterval(interval);
            obj.queue.shift();
            resolve(obj.execute(fn));
          }
        }, checkTimeout);
      });
    },
  };
}

// originally needed for GTooltip.vue
export function createAbsoluteElement(el) {
  const root = document.createElement('div')
  root.style.position = 'absolute'
  root.style.left = '0px'
  root.style.top = '0px'
  root.style.width = '100%'
  const wrapper = document.createElement('div')
  root.appendChild(wrapper)
  wrapper.appendChild(el)
  document.body.appendChild(root)
  return root
}

// originally needed for GTooltip.vue
export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}
