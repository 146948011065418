import '../shared/misc/polyfills';
import './bootstrap';
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import PortalVue from 'portal-vue';
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueSanitize from 'vue-sanitize';
import VueGtm from 'vue-gtm';
import * as Sentry from '@sentry/browser';
import router from './router';
import store from './store';
import App from './App.vue';

import '../tailwind.css';
import '../shared/mixins';
import '../shared/filters';
import '../shared/directives';
import '../shared/components';
import '../shared/vee-validate';
import '../shared/misc/echo';
import '../assets/scss/app.scss';
import getEnvironmentVariable from '../shared/misc/env-variable';

import clickOutside from '@/common/directives/clickOutside';
Vue.directive('click-outside', clickOutside);

import '@common/assets/icons/fa-library.js';
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

if (getEnvironmentVariable('VUE_APP_GTM_ENABLED')) {
  Vue.use(VueGtm, {
    id: (getEnvironmentVariable('VUE_APP_GTM_ID')),
    // vueRouter: router,
  });
}

if (getEnvironmentVariable('VUE_APP_SENTRY_ENABLED')) {
  Sentry.init({
    dsn: getEnvironmentVariable('VUE_APP_SENTRY_DSN'),
  });
}

Vue.use(VueTippy);
Vue.use(VueClipboard);
Vue.use(PortalVue);
Vue.use(VueSanitize, VueSanitize.defaults);
Vue.use(VueCookies);
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'vue-fontawesome',
});

Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('Tippy', TippyComponent);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#buyer-app');

export default app;
export const appRouter = app.$router;

if (window.Cypress) {
  window.buyer_app = app;
}
