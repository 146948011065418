<template>
  <div
    class="additional-processes-wrapper tw-flex tw-flex-col xl:tw-grid xl:tw-gap-y-4"
    :class="[isReadOnly ? 'tw-border-b tw-border-grey-light tw-pt-3 tw-pb-3' : 'tw-mb-4']"
  >
    <p class="tw-font-bold tw-text-sm tw-mt-4 tw-mb-2 tw-mr-5 xl:tw-my-0 xl:tw-self-center">
      Additional Processes
      <tippy
        v-if="shouldShowAutoDetectTooltip"
        tag="span"
        arrow
      >
        <template #trigger>
          <img
            src="@/assets/svgs/auto-detect.svg"
            alt="auto-detect-icon"
            class="tw-inline-flex tw-h-4 tw-w-4"
          />
        </template>
        <div id="buyer-app">
          <div class="tw-text-left tw-p-1 tw-text-sm">
            <p class="tw-capitalize">{{ autoDetections }} <span class="tw-lowercase">auto-detected</span></p>
          </div>
        </div>
      </tippy>
    </p>


    <div
      v-if="!isReadOnly"
      class="tw-flex tw-items-center tw-justify-between tw-bg-white tw-text-sm tw-border tw-rounded-xs tw-border-grey-light tw-p-2"
    >

      <div
        v-if="additionalProcesses.length"
        class="tw-flex tw-gap-1 tw-items-center tw-flex-wrap"
      >
        <template v-if="Object.keys(configurations)?.length === 1 || isSameAdditionalProcesses">
          <g-tag
            v-for="item in additionalProcesses"
            :label="formatName({ entitySlug: item?.entity?.slug, name: item.name })"
            :isClosable="item.name.includes('Has') && !isEntityAutoDetected({ entitySlug:item.entity.slug })"
            :isLabelBold="item.name.includes('Has')"
            :iconLeft="item.name.includes('Has') ? 'check-circle' : 'plus'"
            iconColor="grey-darkest"
            :class="{ 'tw-pointer-events-none' : isEntityAutoDetected({ entitySlug:item.entity.slug })}"
            class="tw-cursor-pointer tw-capitalize"
            @click="handleClickAdditionalProcesses(item)"
          />
        </template>
        <span
          v-else
          class="tw-text-sm tw-font-bold tw-text-grey-dark tw-pr-1"
        >
          Multiple selected
        </span>
      </div>
      <div v-else>
        None selected
      </div>
    </div>
    <div
      v-else
      class="tw-text-sm tw-flex tw-flex-col tw-justify-center"
    >
      <template v-if="readOnlyAdditionalProcessesNames?.length">
        <p
          v-for="name in formatValues(readOnlyAdditionalProcessesNames)"
          :key="name"
          class="tw-capitalize"
        >{{ name }}</p>
      </template>
      <template v-else>
        None selected
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { safeMultipleUpdate } from '@/app-buyer/components/project/mixins';
import {
  REFERENCE_DATA,
  REFERENCE_DATA_OBJECT_BY_ID,
  REFERENCE_DATA_OBJECT_BY_SLUG,
  REFERENCE_MODULE,
} from '@/app-buyer/store/modules/reference-data/types';
import { BENDING, HOLE_COUNTERSINKING, HOLE_THREADING } from '@/shared/consts/slugs';
import GTag from '@common/components/storied/atoms/GTag.vue';

export default {
  name: 'SheetMetalAdditionalProcesses',

  components: {
    GTag,
  },

  props: {
    leadHash: {
      type: String,
      default: null,
    },
    configurations: {
      type: Object,
      required: true,
    },
    lockedConfigurations: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    safeMultipleUpdate,
  ],

  computed: {
    ...mapState(REFERENCE_MODULE, {
      REFERENCE_DATA,
    }),

    ...mapGetters(REFERENCE_MODULE, {
      REFERENCE_DATA_OBJECT_BY_SLUG,
      REFERENCE_DATA_OBJECT_BY_ID,
    }),

    leadConfig() {
      return this.configurations?.[this.leadHash];
    },

    bending() {
      return this[REFERENCE_DATA].filter((r) => r.entity.slug === BENDING)
    },

    ['hole-countersinking']() {
      return this[REFERENCE_DATA].filter((r) => r.entity.slug === HOLE_COUNTERSINKING)
    },

    ['hole-threading']() {
      return this[REFERENCE_DATA].filter((r) => r.entity.slug === HOLE_THREADING)
    },

    additionalProcesses() {
      return [...this.bending, ...this['hole-countersinking'], ...this['hole-threading']].filter((item) => {
        let val = false;

        for (const [key, value] of Object.entries(this.leadConfig)) {
          if (['bending', 'hole-countersinking', 'hole-threading'].includes(key) && value === item.id) val = true;
        }

        return val
      })
    },

    isSameAdditionalProcesses() {
      const additionalProcessKeys = [...this.bending, ...this['hole-countersinking'], ...this['hole-threading']].map(item => item.entity_slug);
      const configValues = Object.values(this.configurations);

      return additionalProcessKeys.every(key => {
        const firstValue = configValues[0]?.[key] ?? null;
        return configValues.every(config => (config?.[key] ?? null) === firstValue && config?.['auto-detects']?.[key] === configValues?.[0]?.['auto-detects']?.[key]);
      });
    },

    readOnlyAdditionalProcessesNames() {
      const configValues = Object.values(this.configurations)
      const addonKeys = [...this.bending, ...this['hole-countersinking'], ...this['hole-threading']].map(item => item.entity_slug);
      const names = [];

      configValues.map((config) => {
        Object.keys(config).forEach(key => {
          if (addonKeys.includes(key)) {
            names.push(this[REFERENCE_DATA_OBJECT_BY_ID]?.[config[key]]?.name)
          }
        })
      });

      return names.filter((n) => n.includes('Has'));
    },

    shouldShowAutoDetectTooltip() {
      const configs = Object.values(this.configurations)

      if (!configs?.length) return false;

      let val = false;

      configs.forEach((c) => {
        if (!c?.['auto-detects']) return

        const autoDetects = Object.keys(c?.['auto-detects']);

        if (autoDetects?.some((auto) => [
          'bend',
          'bending',
          'hole-countersinking',
          'hole-threading',
        ].includes(auto))) {
          val = true
        }
      })

      return val
    },

    autoDetections() {
      let autoDetectionsArray = [];

      Object.values(this.configurations).forEach((config) => {
        if (config?.['auto-detects']) {
          autoDetectionsArray = [...autoDetectionsArray, ...Object.keys(config?.['auto-detects'])]
        }
      })

      autoDetectionsArray = [...new Set(autoDetectionsArray)].filter((a) => a !== 'thickness');

      if (autoDetectionsArray.length === 0) return '';
      else if (autoDetectionsArray.length === 1) return `${autoDetectionsArray[0]}`;
      else if (autoDetectionsArray.length === 2) return `${autoDetectionsArray[0]} and ${autoDetectionsArray[1]}`;
      else {
        const lastString = autoDetectionsArray.pop();
        return `${autoDetectionsArray.map((str) => `${str}`).join(', ')}, and ${lastString}`;
      }
    },
  },

  methods: {
    formatName({ name, entitySlug }) {
      let cleanName = name;
      const configValues = Object.values(this.configurations);

      if (configValues.some((config) => config?.['auto-detects']?.[entitySlug])) cleanName = `${cleanName} (Detected)`;

      const mapObj = {
        No: 'Add',
        Has: '',
      };
      const regEx = new RegExp(Object.keys(mapObj).join('|'), 'gi');

      return cleanName.replace(regEx, (matched) => {
        return mapObj[matched];
      });
    },

    formatValues(values) {
      return Object.entries(values.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {})).map(([key, value]) => `${key} x${value}`);
    },

    handleClickAdditionalProcesses(item) {
      const oppositeProcess = this[item.entity_slug].filter((p) => p.id !== item.id);

      this.startUpdate(oppositeProcess[0].id, { entitySlug: item.entity_slug, values: [null] });
    },

    isEntityAutoDetected({ entitySlug }) {
      return !!this.configurations[this.leadHash]?.['auto-detects']?.[entitySlug];
    }
  },
}
</script>
<style
  scoped
  lang="scss"
>
.additional-processes-wrapper {
  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}
</style>
