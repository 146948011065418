import { render, staticRenderFns } from "./circular-percentage.vue?vue&type=template&id=5809e896&scoped=true"
import script from "./circular-percentage.vue?vue&type=script&lang=js"
export * from "./circular-percentage.vue?vue&type=script&lang=js"
import style0 from "./circular-percentage.vue?vue&type=style&index=0&id=5809e896&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5809e896",
  null
  
)

export default component.exports