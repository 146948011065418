<template>
  <div
    class="leadtime-wrapper tw-flex tw-flex-col xl:tw-grid"
    :class="[
      { 'tw-cursor-not-allowed tw-bg-opacity-80' : isReviseRequote },
      isReadOnly ? 'tw-border-b tw-border-grey-light tw-py-3' : 'tw-pb-4',
    ]"
  >
    <confirm-applicable-tooltip
      :confirm-applicable="confirmApplicable"
      @cancel="confirmApplicable = null"
      @confirm="handleUpdateLeadTime"
    />
    <p
      class="tw-text-sm tw-font-bold tw-mb-2 xl:tw-mr-2 xl:tw-mb-0"
      :class="{ 'xl:tw-self-center' : isReadOnly} "
    >Lead Time</p>

    <div v-if="!isReadOnly">

      <!-- Lead time cards -->
      <div
        class="tw-grid tw-grid-cols-3 tw-gap-x-3 tw-text-sm"
        :class="{ 'tw-pointer-events-none' : isReviseRequote }"
      >
        <div
          v-for="leadTimeOption in leadTimeOptions"
          :key="leadTimeOption.string"
          class="leadtime-card tw-cursor-pointer tw-p-2 tw-rounded-xs tw-inset tw-ring-1"
          :class="[
              leadTimeOption.isActive ? 'tw-bg-tertiary-light tw-ring-tertiary' : isReviseRequote ? 'tw-bg-grey-dark tw-ring-grey-light' : 'tw-bg-white tw-ring-grey-light',
              { 'tw-bg-opacity-20' : isReviseRequote }
            ]"
          @click="handleConfirmUpdateLeadTime({ leadTimeOption })"
        >

          <!-- Lead time content -->
          <span
            v-for="num in leadTimeOption.dollars"
            class="tw-font-bold"
            :class="leadTimeOption.isActive ? 'tw-text-tertiary-grey' : 'tw-text-grey-light'"
          >£</span>
          <p
            class="tw-font-bold tw-text-sm tw-my-05"
            :class="{ 'tw-text-grey-dark' : isReviseRequote && !leadTimeOption.isActive }"
          >{{ leadTimeOption.label }}</p>
          <template v-if="!isReviseRequote && !isDirty && !isCalculating">
            <p class="tw-text-sm">{{ leadTimeOption.leadTimeOption }}</p>
            <p class="tw-text-sm">
              days
              <template v-if="leadTimeOption.hasDelay && parts.length === 1">
                <tippy
                  arrow
                  interactive
                  class="tw-inline-flex"
                >
                  <template #trigger>
                    <button>
                      <font-awesome-icon
                        icon="info-circle"
                        class="tw-text-tertiary"
                      />
                    </button>
                  </template>
                  <div id="buyer-app">
                    <div class="tw-text-left tw-p-1 tw-text-sm">
                      <p class="">Fastest lead time at this price. Need a faster lead time?</p>
                      <g-button
                        :hasBorder="false"
                        label="Speak to advisor"
                        type="underline"
                        color="white"
                        size="sm"
                        fontWeight="normal"
                        @click="handleOpenIntercomLeadTime"
                      />
                    </div>
                  </div>
                </tippy>
              </template>
            </p>
            <p
              v-if="leadTimeOption.hasDelay && parts.length === 1"
              class="tw-text-xs tw-text-tertiary-grey tw-line-through tw-font-medium"
            >{{ leadTimeOption.leadTimeNoDelay }} business days</p>
          </template>

          <!-- Lead time content loading skeletons -->
          <template v-else>
            <b-skeleton
              animated
              :rounded="false"
              width="50%"
              height="0.4rem"
            />
            <b-skeleton
              animated
              :rounded="false"
              width="30%"
              height="0.4rem"
            />
          </template>
        </div>
      </div>

      <!-- Delivery dates -->
      <div
        v-if="!isReviseRequote"
        class="tw-flex-col tw-text-md tw-mt-2"
      >
        <p class="tw-text-xs tw-mb-05">Shipping (1-2) business days</p>
        <div class="tw-flex tw-text-md">
          <font-awesome-icon
            icon="truck"
            class="tw-mr-1 tw-mt-05 tw-relative tw-text-md"
          />
          <p
            v-if="isCalculating || isDirty"
            class="tw-text-primary tw-font-bold tw-text-md"
          >Calculating delivery...</p>
          <template v-else>
            <p class="tw-mr-1 tw-font-normal">Delivery to
              <country-picker-modal />
              by
              <span class="tw-font-bold tw-mr-1 tw-text-success">{{
                  leadTimeDate({ date: sortedDates[0] })
                }}<span class="tw-font-normal tw-text-primary">{{
                    sortedDates.length > 1 ? ' to ' : ''
                  }}</span><template v-if="sortedDates.length > 1">{{
                    leadTimeDate({ date: sortedDates[sortedDates.length - 1] })
                  }}</template></span>
            </p>
          </template>
        </div>
      </div>

      <!-- Message if R&R -->
      <p
        v-else
        class="tw-text-sm tw-mt-2"
      >To change lead times, please request a new quote from the
        <span class="tw-font-bold">Projects</span> page</p>
    </div>
    <div
      v-else
      class="tw-text-sm"
    >
      <div
        v-for="readOnlyLeadTime in readOnlyLeadTimes"
        class="tw-mb-1"
      >
        <p><span class="tw-capitalize">{{ readOnlyLeadTime.lead_time_speed }}</span>
          ({{ readOnlyLeadTime.lead_time }} business days)</p>
        <div class="tw-flex tw-items-center tw-text-sm">
          <font-awesome-icon
            icon="truck"
            class="tw-mr-1 tw-relative tw-text-sm"
          />
          <p class="tw-mr-1 tw-font-bold">Delivery to {{ USER_COUNTRY }} by</p>
          <span class="tw-font-bold tw-mr-1 tw-text-success">
            {{ leadTimeDate({ date: readOnlyLeadTime.delivery_date }) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { RFQ_MODULE, RFQ_UPDATE_HASHES, UPDATE_DRAFT } from '@/app-buyer/store/modules/rfq/types';
import ConfirmApplicableTooltip from '@/app-buyer/components/project/ConfirmApplicableTooltip.vue';
import leadTimeCalculator from '../../mixins/lead-time-calculator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import CountryPickerModal from '@/app-buyer/components/project/CountryPickerModal.vue';
import { USER_COUNTRY, USER_MODULE } from '@/app-buyer/store/modules/user/types';
import GMessage from '@common/components/storied/molecules/GMessage.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { PROJECT_MODULE, ACTIVE_PROJECT } from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'LeadTimeDraftConfigurator',

  components: {
    CountryPickerModal,
    ConfirmApplicableTooltip,
    GMessage,
    GButton,
    FontAwesomeIcon,
  },

  mixins: [
    leadTimeCalculator,
  ],


  props: {
    parts: {
      type: Array,
      required: true,
    },
    configurations: {
      type: Object,
      default: () => {
      },
    },
    leadHash: {
      type: String,
      default: null,
    },
    isReviseRequote: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      confirmApplicable: null,
      leadTimeOptionData: null,
      isDirty: false,
    }
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      RFQ_UPDATE_HASHES,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapState(USER_MODULE, {
      USER_COUNTRY,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    isCalculating() {
      return !!this[RFQ_UPDATE_HASHES].length;
    },

    leadTimeOptions() {
      return [
        {
          label: 'Expedited',
          string: 'expedited',
          dollars: 3,
          ...this.leadTimeHelper({ speed: 'expedited' }),
        },
        {
          label: 'Standard',
          string: 'standard',
          dollars: 2,
          ...this.leadTimeHelper({ speed: 'standard' }),
        },
        {
          label: 'Economy',
          string: 'economy',
          dollars: 1,
          ...this.leadTimeHelper({ speed: 'economy' }),
        },
      ]
    },
    sortedDates() {
      const dates = this.parts?.map((part) => part?.lead_time_dates?.[part?.lead_time_speed]);
      const cleanDates = dates?.map((date) => date?.substring(0, 10));
      return [...new Set(cleanDates?.sort())];
    },

    readOnlyLeadTimes() {
      const leadTimes = [];

      this.parts?.forEach((part) => {
        leadTimes.push({
          lead_time: part?.lead_time_days?.[part.lead_time_speed],
          lead_time_speed: part?.lead_time_speed,
          delivery_date: part?.lead_time_dates?.[part?.lead_time_speed],
        });
      });

      const key = 'lead_time';

      return [...new Map(leadTimes.map(item => [item[key], item])).values()];
    },
  },

  watch: {
    isCalculating(nv, ov) {
      if (!nv && ov) this.isDirty = false
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),

    handleConfirmUpdateLeadTime({ leadTimeOption }) {
      this.isDirty = true;
      if (this.parts.length === 1 || !this.isReviseRequote) return this.handleUpdateLeadTime({ leadTimeOption });

      this.leadTimeOptionData = leadTimeOption;

      this.confirmApplicable = {
        count: this.parts.length,
        property: `${leadTimeOption.label} lead time`,
        ids: this.parts.map((p) => p.hash),
      };
    },

    handleUpdateLeadTime({ leadTimeOption }) {
      let updatedLeadTimeSpeedConfig = {};

      this.parts.forEach((p) => {
        updatedLeadTimeSpeedConfig = {
          ...updatedLeadTimeSpeedConfig,
          [p.hash]: leadTimeOption?.string ?? this.leadTimeOptionData?.string,
        };
      });

      this.$emit('update-config', 'lead_time_speed', updatedLeadTimeSpeedConfig);

      this.leadTimeOptionData = null;
    },

    leadTimeHelper({ speed }) {
      let base = this.parts?.[0]?.lead_time_days?.[speed];
      if (this.parts?.[0]?.lead_time_speed === speed) base += this.parts?.[0]?.quote_delay || 0;

      let low = base;
      let high = base;
      let isActive = false;
      let hasDelay = false;
      let leadTimeNoDelay = 0;

      this.parts.forEach((p) => {
        let calculatedTime = p.lead_time_days?.[speed];
        if (p.lead_time_speed === speed) calculatedTime += p.quote_delay || 0;

        if (low > calculatedTime) low = calculatedTime;
        if (high < calculatedTime) high = calculatedTime;
        if (speed === p.lead_time_speed) isActive = true;
        if (p.lead_time_speed === speed && p.quote_delay) {
          hasDelay = !!p.quote_delay;
          leadTimeNoDelay = p.lead_time_days?.[speed];
        }
      });

      return {
        leadTimeOption: `${low}${low !== high ? `-${high}` : ''} business`,
        isActive,
        hasDelay,
        leadTimeNoDelay,
      };
    },

    handleOpenIntercomLeadTime() {
      Intercom('trackEvent', 'QuoteDelay-FasterLeadTime', { project_ref: this[ACTIVE_PROJECT]?.ref });
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.leadtime-wrapper {
  .leadtime-card {
    min-height: 84px;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 26% 74%;
  }
  @media (min-width: 1669px) {
    grid-template-columns: 30% 70%;
  }
}

::v-deep .b-skeleton + .b-skeleton {
  margin-top: 0;
}
</style>
