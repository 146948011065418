<template>
  <div class="part-row-quantity-wrapper">
    <template v-if="isReadOnly">
      <p class="tw-text-sm tw-mt-05">Qty:</p>
      <p class="tw-text-sm tw-mt-1">{{ quantity }}</p>
    </template>
    <template v-else>
      <g-number
        v-bind:value.sync="quantity"
        label="Qty:"
        id="quantity_initial"
        style="display: flex"
      />
      <template v-if="!hideAnotherQty">
        <div
          v-if="canShowAdditionalQuantityBtn && !showAdditionalQuantity"
          class="xl:tw-absolute"
        >
          <button
            class="tw-text-tertiary tw-mt-1 tw-text-sm tw-font-medium"
            @click="handleShowAdditionalQuantity"
          >
            <font-awesome-icon
              icon="plus"
              class="tw-mr-1"
            />
            <span class="tw-text-tertiary">Another Qty</span>
          </button>
        </div>
        <div
          v-else
          class="tw-flex tw-items-center tw-relative tw-w-min"
        >
          <g-number
            v-bind:value.sync="quantityProduction"
            label="Another Qty:"
            id="quantity_production"
            class="tw-mt-2"
          >
            <!--      <div-->
            <!--        class="tw-border-l tw-border-grey-light tw-ml-2 tw-py-1 tw-pl-2"-->
            <!--        @click="removeAnotherQuantity"-->
            <!--      >-->
            <!--        <font-awesome-icon-->
            <!--          icon="trash-alt"-->
            <!--          class="tw-text-grey-dark tw-text-md"-->
            <!--        />-->
            <!--      </div>-->
          </g-number>
          <button
            class="remove-another-qty-btn tw-absolute tw--right-4"
            @click="removeAnotherQuantity"
          >
            <font-awesome-icon
              icon="trash-alt"
              class="tw-text-grey-dark tw-text-md"
            />
          </button>
        </div>
      </template>
    </template>

    <g-modal
      :is-active.sync="isQtyModalOpen"
      :can-cancel="false"
      modalWidth="100%"
      modalHeight="100%"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs tw-w-9/12 xl:tw-w-2/5">
        <div class="tw-flex tw-items-center tw-justify-between tw-pb-5">
          <p class="tw-font-bold tw-text-xl">Apply this quantity to all selected parts or just one?</p>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between">
          <g-button
            label="Cancel"
            type="primary"
            color="admin"
            @click="isQtyModalOpen = false"
          />

          <div class="tw-flex">
            <g-button
              isInverted
              label="Apply to just one"
              type="primary"
              color="tertiary"
              class="tw-mr-2"
              @click="updateQuantity({ quantity_initial: pendingInitialQuantity, force: true, updateAll: false })"
            />
            <g-button
              :label="`Apply to all (${selectedPartsNotInCart?.length})`"
              type="primary"
              color="secondary"
              @click="updateQuantity({ quantity_initial: pendingInitialQuantity, force: true, updateAll: true })"
            />
          </div>

        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash/function';
import {
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
  UPDATE_DRAFT,
} from '@/app-buyer/store/modules/rfq/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GNumber from '@common/components/storied/atoms/GNumber.vue';
import GModal from '@/app-buyer/components/GModal';

export default {
  name: 'PartTableGroupRowQuantity',

  components: {
    GButton,
    GNumber,
    GModal,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    hideAnotherQty: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      quantityInitial: 0,
      isQtyModalOpen: false,
      pendingInitialQuantity: null,
      showAdditionalQuantity: !!this.part.quantity_production,
    };
  },

  computed: {
    ...mapGetters(RFQ_MODULE, {
      GET_ALL_SELECTED_PARTS,
    }),

    quantity: {
      get() {
        return this.part?.quantity_initial;
      },
      set(val) {
        if (!val) return;
        this.updateQuantity({ quantity_initial: val, force: false, updateAll: false });
      },
    },

    quantityProduction: {
      get() {
        return this.part?.quantity_production || 0;
      },
      set(val) {
        if (val < 0) return;
        this.updateQuantityProduction(val);
      },
    },

    canShowAdditionalQuantityBtn() {
      return this.part.quote_rfq_type !== 'auto';
    },

    selectedPartsNotInCart() {
      return this[GET_ALL_SELECTED_PARTS].filter((p) => !p.cart_item_id);
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),

    handleShowAdditionalQuantity() {
      this.showAdditionalQuantity = !this.showAdditionalQuantity;
    },

    removeAnotherQuantity() {
      this.quantityProduction = 0;
      this.showAdditionalQuantity = false;
    },

    // eslint-disable-next-line camelcase
    updateQuantity: debounce(function debounceUpdateQuantity({ quantity_initial, force, updateAll }) {
      if (this[GET_ALL_SELECTED_PARTS]?.length > 1 && !force) {
        this.isQtyModalOpen = true;
        this.pendingInitialQuantity = quantity_initial;
        return;
      }

      if (updateAll) {
        this.selectedPartsNotInCart.forEach((p) => {
          this.sendUpdateAction({ part: p, quantity_initial });
        });
      } else {
        this.sendUpdateAction({ part: this.part, quantity_initial });
      }

      this.isQtyModalOpen = false;
      this.pendingInitialQuantity = null;
    }, 500),

    sendUpdateAction({ part, quantity_initial }) {
      const properties = {
        name: part.name,
        project_hash: part.project_hash,
        'file-type': part.configuration['file-type'],
        quantity_initial,
      };
      this[UPDATE_DRAFT]({
        draft: part,
        properties,
      });
    },

    // this method will need to be updated with the bulk updates changes before its used
    // don't know if/when production_quantity is coming back online so will leave it commented for now
    // eslint-disable-next-line camelcase
    // updateQuantityProduction: debounce(function debounceUpdateQuantityProduction(quantity_production) {
    //   const properties = {
    //     name: this.part.name,
    //     project_hash: this.part.project_hash,
    //     'file-type': this.part.configuration['file-type'],
    //     quantity_production,
    //   };
    //   this[UPDATE_DRAFT]({
    //     draft: this.part,
    //     properties,
    //   });
    // }, 300),
  },
};
</script>

<style
  scoped
  lang="scss"
>
.remove-another-qty-btn {
  bottom: 0.4rem
}
</style>
