<template>
  <div>
    <input
      v-show="false"
      ref="supporting-file-input"
      type="file"
      @input="uploadFile"
    >
    <g-button
      :label="btnText"
      isInverted
      size="sm"
      color="error"
      type="primary-icon"
      class="tw-justify-center tw-whitespace-nowrap"
      @click="$refs['supporting-file-input'].click()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { RFQ_MODULE, UPDATE_DRAFT } from '@/app-buyer/store/modules/rfq/types';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'PartFileUpload',

  components: {
    GButton,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    btnText: {
      type: String,
      default: 'Update file',
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      UPDATE_DRAFT,
    }),

    uploadFile(event) {
      const modelFile = event?.target?.files?.[0];
      if (!modelFile) return;
      const properties = { ...this.part.configuration };
      properties['file-type'] = this.part?.configuration?.['file-type'];
      if (properties['file-type'] === 9) {
        properties.project_hash = this.part.project_hash;
        properties.name = modelFile.name;
        this[UPDATE_DRAFT]({
          draft: this.part,
          properties,
          files: {
            modelFile,
            supportingFiles: this.part?.uploads,
          },
          immediate: true,
        });
      } else if (properties['file-type']) {
        properties.project_hash = this.part.project_hash;
        properties.name = modelFile.name;
        this[UPDATE_DRAFT]({
          draft: this.part,
          properties,
          files: {
            modelFile,
          },
          immediate: true,
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
