<template>
  <div class="tw-flex tw-flex-col tw-text-left tw-text-white tw-p-4">
    <div
      v-if="clientNotes"
      class="tw-w-full tw-mb-2"
    >
      <p class="tw-text-sm tw-mb-2">
        You have a Geomiq note about this quote. You need to approve it before you can add your part
        to cart:
      </p>
      <p class="tw-font-bold tw-text-sm">Geomiq Note:</p>
      <p class="tw-italic tw-text-sm">
        <i v-html="clientNotes" />
      </p>
    </div>
    <!--      <div-->
    <!--        v-if="deliveryDelay"-->
    <!--        class="is-width-100"-->
    <!--      >-->
    <!--        <p class="is-size-7 mb-2">-->
    <!--          Have you reviewed the new expected delivery date for this job?-->
    <!--        </p>-->
    <!--        <div class="mb-1 has-text-left">-->
    <!--          <p class="is-size-7">-->
    <!--          <span class="has-text-weight-bold">-->
    <!--            Delivery Delay:-->
    <!--          </span>-->
    <!--            +{{ deliveryDelay }} day{{-->
    <!--              deliveryDelay === 1-->
    <!--                ? ''-->
    <!--                : 's'-->
    <!--            }}-->
    <!--          </p>-->
    <!--        </div>-->
    <!--      </div>-->
    <g-button
      isFullWidth
      :label="confirmText"
      color="tertiary"
      size="sm"
      @click="confirm"
    />
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'QuoteConfirmPrompt',

  components: {
    GButton,
  },

  props: {
    quoteId: {
      type: [String, Number, Array],
    },
    hash: {
      type: String,
      required: true,
    },
    clientNotes: {
      type: String,
      default: '',
    },
    deliveryDelay: {
      type: [String, Number],
      default: null,
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
  },

  methods: {
    confirm() {
      localStorage.setItem(`notes_${this.hash}_quote_${this.quoteId}`, 'true');
      this.$emit('confirm');
    },
  },
};
</script>
