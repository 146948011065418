var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-rounded-xs tw-p-3 tw-inline-flex tw-items-start",class:[_vm.messageColor, _vm.messageSize, _vm.leftBorder, { 'tw-w-full' : _vm.isFullWidth}]},[(_vm.iconLeft)?_c('font-awesome-icon',{staticClass:"tw-pointer-events-none",class:[
        _vm.iconSize,
        _vm.size === 'sm' ? 'tw-mr-1' : 'tw-mr-2',
        _vm.messageIconColor || _vm.messageColor,
      ],attrs:{"spin":_vm.hasIconSpinning,"icon":['fas', `${_vm.iconLeft}`]}}):_vm._e(),_vm._t("default"),(_vm.iconRight)?_c('font-awesome-icon',{staticClass:"tw-pointer-events-none tw-ml-auto",class:[
        _vm.iconSize,
        _vm.size === 'sm' ? 'tw-mr-1' : 'tw-mr-2',
        _vm.messageIconColor || _vm.messageColor,
      ],attrs:{"spin":_vm.hasIconSpinning,"icon":['fas', `${_vm.iconRight}`]}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }