<template>
  <div
    class="tw-flex tw-flex-col tw-px-4 tw-pb-32 tw-pt-0 lg:tw-pt-1 lg:tw-pb-2 lg:tw-px-3 xl:tw-py-0 xl:tw-px-3 xl:tw-h-full"
  >
    <PartTable
      v-if="hasDrafts && !loadingProject"
      ref="partTable"
      @add-to-cart="$emit('add-to-cart', $event)"
      @remove-from-cart="$emit('remove-from-cart', $event)"
      @request-quotes="$emit('request-quotes', $event)"
    />
    <PartUpload :compact="!!hasDrafts" />
    <ProjectContentLoader v-show="LOGGED_IN && (!INITIAL_LOAD || loadingProject)" />
    <BModal
      :active="!!PROJECT_SUPPORTING_FILE_MODAL"
      :full-screen="false"
      class="project-pdf-modal"
      width="98%"
      :can-cancel="false"
    >
      <PDFView
        v-if="!!PROJECT_SUPPORTING_FILE_MODAL"
        :pdf="PROJECT_SUPPORTING_FILE_MODAL"
        :full-screen="true"
        :is-project-pdf="true"
        class="project-pdf"
        @close="handlePdfModalClose"
      />
    </BModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import PartUpload from '@/app-buyer/components/project/PartUpload.vue';
import PDFView from '@/app-buyer/components/project/PDFView.vue';
import { DRAFT_RFQS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import ProjectContentLoader from '@/app-buyer/components/project/ProjectContentLoader.vue';
import { INITIAL_LOAD } from '@/app-buyer/store/modules/types';
import { AUTH_MODULE, LOGGED_IN } from '@/app-buyer/store/modules/auth/types';
import PartTable from '@/app-buyer/components/project/PartTable';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import {
  ACTIVE_PROJECT,
  PROJECT_MODULE,
  PROJECT_SUPPORTING_FILE_MODAL,
  SET_PROJECT_SUPPORTING_FILE_MODAL,
} from '@/app-buyer/store/modules/projects/types';

export default {
  name: 'ProjectContent',
  components: {
    ProjectContentLoader,
    PartUpload,
    PDFView,
    PartTable,
  },
  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    ...mapState(PROJECT_MODULE, {
      INITIAL_LOAD,
      PROJECT_SUPPORTING_FILE_MODAL,
    }),

    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),

    hasDrafts() {
      return this[DRAFT_RFQS]?.length;
    },

    loadingProject() {
      return this[ACTIVE_PROJECT]
        && this[ACTIVE_PROJECT]._loading
        && !this[ACTIVE_PROJECT]._detailed;
    },
  },
  destroyed() {
    this[SET_PROJECT_SUPPORTING_FILE_MODAL](null);
  },
  methods: {
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_SUPPORTING_FILE_MODAL,
    }),

    handlePdfModalClose() {
      this[SET_PROJECT_SUPPORTING_FILE_MODAL](null);
    },

    forceClearNoteNotifications() {
      this.$refs.partTable.forceClearNoteNotifications();
    }
  },
};
</script>

<style
  lang="scss"
  scoped
>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.project-pdf-modal {
  position: absolute;
}
</style>
