import {
  ACTIVE_PROJECT_HASH,
  ADD_DRAFT,
  ARCHIVED_LOADED,
  ARCHIVED_PROJECTS,
  CURRENT_PROJECT,
  GET_MRFQ_PROJECTS_M,
  MRFQS_PAGINATION,
  MRFQS_PAGINATION_PAGES,
  ON_INITIAL_LOAD,
  PAGINATION_PAGES,
  PROJECT_INVITE_MODAL,
  PROJECT_SUPPORTING_FILE_MODAL,
  PROJECTS,
  PROJECTS_MRFQ,
  REMOVE_FROM_PROJECT,
  RESET_PROJECTS_MRFQ,
  SET_ACTIVE_PROJECT_M,
  SET_ARCHIVED_LOADED,
  SET_CURRENT_PROJECT_M,
  SET_MRFQS_PAGINATION,
  SET_MRFQS_PAGINATION_PAGES,
  SET_PAGINATION_PAGES,
  SET_PROJECT_INVITE_MODAL,
  SET_PROJECT_SUPPORTING_FILE_MODAL,
} from './types';
import getState from './state';
import {
  DELETE,
  INITIAL_LOAD,
  PAGINATION,
  RESET_STATE,
  SET,
  SET_PAGINATION,
  UPDATE,
} from '../types';

export default {
  /**
   * Sets the projects list
   *
   * @param state
   * @param {Object} payload
   * @param {Object} payload.data      The data to be stored in the state
   * @param {boolean} payload.clear     Clears the projects already stored in the state
   * */
  [SET](state, {
    data, clear, toFront, isArchived,
  }) {
    if (!isArchived) {
      let projects = null;

      if (clear) state[PROJECTS].splice(0, state[PROJECTS].length);
      if (toFront) projects = [...data, ...state[PROJECTS]];
      else {
        const projectsInSidebarHashes = new Set();
        projects = [...state[PROJECTS], ...data].filter((project) => {
          if (projectsInSidebarHashes.has(project.hash)) {
            return false;
          }
          projectsInSidebarHashes.add(project.hash);
          return true;
        });
      }
      state[PROJECTS] = projects;
    } else {
      if (clear) state[ARCHIVED_PROJECTS].splice(0, state[ARCHIVED_PROJECTS].length);
      state[ARCHIVED_LOADED] = true;
      data.forEach((projectData) => {
        state[ARCHIVED_PROJECTS].push(projectData);
      });
    }

    if (!state[INITIAL_LOAD]) {
      const fns = state[ON_INITIAL_LOAD]?.splice(0, state[ON_INITIAL_LOAD]?.length);
      if (fns) {
        fns.forEach((fn) => {
          if (fn && typeof fn === 'function') {
            fn();
          }
        });
      }
      setTimeout(() => {
        state[INITIAL_LOAD] = true;
      }, 500);
    }
  },

  /**
   * Updates a project with the new data
   *
   * @param state
   * @param {Object} payload
   * @param {number} payload.hash    The id of the project to be updated
   * @param {Object} payload.data  The data to be merged into the project
   * */
  [UPDATE](state, { hash, data, isUnarchived = false }) {
    if (state[CURRENT_PROJECT]?.[0]?.hash === hash) {
      state[CURRENT_PROJECT][0] = data;
    }

    const index = state[!isUnarchived ? PROJECTS : ARCHIVED_PROJECTS].findIndex((e) => e.hash === hash);
    if (isUnarchived) {
      state[ARCHIVED_PROJECTS].splice(index, 1);
      state[PROJECTS] = [...state[PROJECTS], data];
    } else if (index > -1 && data.archived_at) {
      state[PROJECTS].splice(index, 1);
      state[ARCHIVED_PROJECTS] = [...state[ARCHIVED_PROJECTS], data];
    } else if (index > -1) {
      state[PROJECTS].splice(index, 1, data);
    } else {
      state[PROJECTS].unshift(data);
    }
  },

  /**
   * Removes a project from the projects list, if it's set as the active one
   * the first project in the projects list will be set as active
   *
   * @param state
   * @param {Object} project
   * @param {number} project.hash    The id of the project to be removed
   * */
  [DELETE](state, { hash }) {
    const index = state[PROJECTS].findIndex((e) => e.hash === hash);
    if (index > -1) {
      if (state[ACTIVE_PROJECT_HASH] === hash) {
        state[ACTIVE_PROJECT_HASH] = null;
      }
      state[PROJECTS].splice(index, 1);
    }
  },
  [REMOVE_FROM_PROJECT](state, model) {
    state[PROJECTS].some((p) => {
      const draftIndex = p.draft_rfqs?.findIndex((d) => d.hash === model.hash);
      const rfqIndex = p.rfqs?.findIndex((r) => r.hash === model.hash);
      if (draftIndex > -1) p.draft_rfqs.splice(draftIndex, 1);
      if (rfqIndex > -1) p.rfqs.splice(rfqIndex, 1);
      return draftIndex > -1 || rfqIndex > -1;
    });
  },
  [ADD_DRAFT](state, draft) {
    const project = state[PROJECTS].find((p) => p.hash === draft.project_hash);
    project.draft_rfqs.splice(0, 0, draft)
  },
  [SET_PAGINATION](state, data) {
    state[PAGINATION] = data;
  },
  [SET_PAGINATION_PAGES](state, data) {
    if (!data || data.clear) state[PAGINATION_PAGES] = [];
    if (!data || !data.current_page) return;
    const pagesLoaded = [...state[PAGINATION_PAGES], data.current_page];

    state[PAGINATION_PAGES] = [...new Set(pagesLoaded)];
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
  [SET_ACTIVE_PROJECT_M](state, { hash }) {
    state[ACTIVE_PROJECT_HASH] = hash;
  },

  [SET_CURRENT_PROJECT_M](state, currentProject) {
    // eslint-disable-next-line prefer-destructuring
    state[CURRENT_PROJECT] = currentProject;
  },

  [SET_PROJECT_INVITE_MODAL](state, visible = false) {
    state[PROJECT_INVITE_MODAL] = visible;
  },
  [SET_ARCHIVED_LOADED](state, value) {
    state[ARCHIVED_LOADED] = value;
  },
  [SET_PROJECT_SUPPORTING_FILE_MODAL](state, value) {
    state[PROJECT_SUPPORTING_FILE_MODAL] = value;
  },
  [GET_MRFQ_PROJECTS_M](state, data) {
    let projects = null;
    projects = [...state[PROJECTS_MRFQ], ...data];
    state[PROJECTS_MRFQ] = projects;
  },

  [RESET_PROJECTS_MRFQ](state) {
    state[PROJECTS_MRFQ] = [];
  },

  [SET_MRFQS_PAGINATION](state, data) {
    state[MRFQS_PAGINATION] = data;
  },
  [SET_MRFQS_PAGINATION_PAGES](state, data) {
    state[MRFQS_PAGINATION_PAGES] = [];

    const pagesLoaded = [...state[MRFQS_PAGINATION_PAGES], data.current_page];

    state[MRFQS_PAGINATION_PAGES] = [...new Set(pagesLoaded)];
  },
};
