<template>
  <div
    v-click-outside="handleClose"
    class="g-select tw-inline-block tw-relative tw-cursor-pointer "
    :class="[selectSize, {'tw-w-full': isFullWidth, 'admin-style' : isAdminStyle }]"
  >
    <div class="selected">
      <g-button
        isInverted
        :label="hasFixedLabel ? label : selectedLabel"
        :iconLeft="iconLeft"
        :iconRight="iconRight"
        :size="size"
        :color="color"
        :type="selectType"
        :isFullWidth="isFullWidth"
        :fontWeight="fontWeight"
        justifyContent="tw-justify-between"
        :class="{'admin-style' : isAdminStyle }"
        @click="handleOpenSelect"
      />
    </div>
    <template v-if="isOpen">
      <div
        class="options tw-bg-white tw-absolute tw-border tw-z-50 tw-rounded-xs tw-overflow-y-auto"
        :class="[{ 'tw-right-0' : alignRight }, dropDownDistance, dropDownColor, dropDownHeight]"
      >
        <div
          v-if="isMultiSelect"
          class="tw-flex tw-items-center tw-justify-between tw-p-2"
        >
          <p class="tw-mb-0 tw-text-grey-darkest tw-text-sm">{{ selected?.length }} selected</p>
          <g-button
            isInverted
            label="Clear all"
            color="grey-dark"
            size="sm"
            @click="handleClearAll"
          />
        </div>

        <div class="g-select-search-wrapper tw-relative">
          <input
            ref="countrySearchInput"
            v-model="searchInput"
            type="text"
            class="tw-w-full tw-px-2 tw-py-2 tw-rounded-xs focus:tw-outline-none focus:tw-border-tertiary"
            placeholder="Search..."
          >
          <font-awesome-icon
            icon="search"
            class="tw-absolute tw-right-2 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-flex tw-items-center tw-justify-center tw-w-3 tw-h-3 tw-text-grey-darkest"
          />
        </div>

        <div
          v-for="option in selectOptions"
          :key="option.slug"
          class="option tw-transition tw-p-2 tw-flex tw-items-center tw-justify-between first:tw-rounded-t-xs last:tw-rounded-b-xs hover:tw-bg-grey-lightest tw-w-auto"
          :class="{'tw-bg-grey-lightest' : selected?.some((s) => s?.value === option?.value) }"
          @click="handleClickOption(option)"
        >
          <p class="tw-m-0">{{ option.string }}</p>
          <font-awesome-icon
            v-if="selected?.some((s) => s?.value === option?.value)"
            icon="check"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GSelect',

  components: {
    GButton,
    FontAwesomeIcon,
  },

  props: {
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hasFixedLabel: {
      type: Boolean,
      default: false,
    },
    activeOption: {
      type: [Array, null],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    dropDownHeight: {
      type: String,
      default: 'tw-max-h-64',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isAdminStyle: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'pill',
        'underline',
      ].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => [
        'xs',
        'sm',
        'md',
        'lg',
      ].includes(value),
    },
    color: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'secondary',
        'tertiary',
        'error',
        'success',
        'grey-dark',
        'grey-light',
        'white',
        'transparent',
      ].includes(value),
    },
    fontWeight: {
      type: String,
      default: 'bold',
      validator: (value) => [
        'light',
        'normal',
        'medium',
        'bold',
      ].includes(value),
    },
  },

  data() {
    return {
      isOpen: false,
      selected: [],
      searchInput: null,
    }
  },

  computed: {
    selectOptions() {
      if (this.searchInput) {
        return this.options.filter((o) => o.string?.toLowerCase()?.includes(this.searchInput?.toLowerCase()));
      }
      return this.options;
    },

    selectedLabel() {
      if (!this.selected?.length) return this.label;
      if (this.selected?.length === 1) return this.selected?.[0]?.string;
      return `Multiple selected (${this.selected?.length})`;
    },

    selectSize() {
      if (this.size === 'xs') return 'tw-text-xs';
      if (this.size === 'sm') return 'tw-text-sm';
      if (this.size === 'lg') return 'tw-text-lg ';
      // md is default
      return 'tw-text-md';
    },

    dropDownDistance() {
      if (this.size === 'xs') return 'tw-top-6';
      if (this.size === 'sm') return 'tw-top-8';
      if (this.size === 'md' && this.type === 'pill') return 'tw-top-10'
      return 'tw-top-12';
    },

    dropDownColor() {
      if (this.color === 'primary') return `tw-border-primary tw-text-primary`;
      if (this.color === 'tertiary') return `tw-border-tertiary tw-text-tertiary`;
      if (this.color === 'error') return `tw-border-error tw-text-error`;
      if (this.color === 'success') return `tw-border-success tw-text-success`;
      if (this.color === 'grey-dark') return `tw-border-grey-dark tw-text-grey-dark`;
      if (this.color === 'grey-light') return `tw-border-grey-light tw-text-grey-light`;
      if (this.color === 'white') return `tw-border-white tw-text-white`;
      if (this.color === 'transparent') return `tw-border-transparent tw-text-transparent`;
      // secondary is default
      return `tw-border-secondary tw-text-secondary`;
    },

    selectType() {
      if (this.type === 'pill') return 'pill-icon';
      if (this.type === 'underline') return 'underline';

      // primary-icon is default
      return 'primary-icon';
    },
  },

  watch: {
    activeOption: {
      handler() {
        this.selected = this.activeOption ?? [];
      },
      immediate: true,
    },
  },

  methods: {
    handleOpenSelect() {
      this.isOpen = !this.isOpen;

      this.$nextTick(() => {
        if (this.isOpen) this.$refs.countrySearchInput.focus();
      })
    },
    handleClickOption(option) {
      if (this.isMultiSelect && this.selected?.some((s) => s?.value === option?.value)) {
        this.selected = this.selected.filter((s) => s.value !== option.value);
      } else if (this.isMultiSelect) this.selected = [...this.selected, option];
      else this.selected = [option];

      this.$emit('selected', this.selected);

      if (!this.isMultiSelect) this.isOpen = false;
    },
    handleClearAll() {
      this.selected = [];
      this.$emit('selected', this.selected);
    },
    handleClose() {
      this.isOpen = false;
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.options {
  .option {
    min-width: 13rem;
  }
}

::v-deep {
  button {
    &.admin-style {
      border-color: theme('colors.grey-light') !important;
      background-color: theme('colors.white');
    }

    &.admin-style:hover {
      border-color: theme('colors.grey-dark') !important;
      background-color: theme('colors.white') !important;
    }

    &.admin-style {
      span {
        color: theme('colors.primary');
      }
    }
  }
}
.admin-style {
  .options {
    border-color: theme('colors.grey-light') !important;

    .option {
      color: theme('colors.primary');
    }
  }
}
</style>
