<template>
  <section class="reg-wrapper is-flex">
    <div class="registration-wrapper reg-side is-flex is-align-items-center is-hidden-touch">
      <div class="container">
        <RegistrationAside />
      </div>
    </div>
    <div class="reg-side is-flex">
      <div class="container is-flex is-flex-direction-column">
        <div
          class="is-flex is-align-items-center is-hidden-desktop"
          style="padding-bottom: 30px;"
        >
          <icon-font
            icon="geomiq-up"
            class="mr-4"
            style="font-size: 20px; color: white; font-weight: 100;"
          />
          <icon-font
            icon="geomiq-text"
            style="font-size: 16px; color: white; font-weight: 100; margin-top: 3px;"
          />
        </div>
        <div class="registration-wrapper is-flex is-align-items-center is-flex-grow-1">
          <transition
            mode="out-in"
            name="slide-left"
          >
            <component
              :is="component"
              class="has-text-white is-flex-grow-1"
              @progress="progress"
            />
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import RegistrationAside from '@/app-buyer/components/registration/RegistrationAside.vue';
import RegistrationDetails from '@/app-buyer/components/registration/RegistrationDetails.vue';
import RegistrationForm from '@/app-buyer/components/registration/RegistrationForm.vue';
import RegistrationSuccess from '@/app-buyer/components/registration/RegistrationSuccess.vue';
import { PROJECT_MODULE, PROJECTS } from '@/app-buyer/store/modules/projects/types';
import { AUTH_MODULE, FORCE_AUTH } from '@/app-buyer/store/modules/auth/types';
import IconFont from '@/shared/components/icon-font/icon-font.vue';
import getEnvironmentVariable, { config } from '../../../shared/misc/env-variable';
import { USER_DATA, USER_MODULE } from '@/app-buyer/store/modules/user/types';

export default {
  name: 'RegistrationPage',
  components: {
    IconFont,
    RegistrationAside,
    RegistrationDetails,
    RegistrationForm,
    RegistrationSuccess,
  },
  props: {
    state: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      innerState: null,
    };
  },
  computed: {
    ...mapState(PROJECT_MODULE, {
      PROJECTS,
    }),
    ...mapState(USER_MODULE, {
      USER_DATA,
    }),

    redirect() {
      const hash = this[PROJECTS]?.[0]?.hash;
      return hash ? `/quotes/${hash}` : '/quotes';
    },

    partnerForm() {
      return `${getEnvironmentVariable('VUE_APP_API_URL')}/partners/register`;
    },
    component() {
      switch (this.innerState) {
        case 'success':
          return RegistrationSuccess;
        default:
          return RegistrationForm;
      }
    },
  },
  watch: {
    state: {
      handler(value) {
        this.innerState = value;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),
    progress(state) {
      if (state === 'final') {
        const features = this[USER_DATA]?.user?.enabled_features;
        const nextSites = ['alpha', 'beta'];
        const nextSiteOn = features?.some((f) => nextSites.includes(f));

        // TODO remove this when we main becomes IQ
        if (nextSiteOn) return window.location.href = `${config('NEXT_URL')}`;
        else return window.location.href = `${config('OG_BUYER_URL')}`;
      }
      if (this.$route.name === 'register') {
        this.$router.push({ ...this.$route, params: { state } });
      } else {
        this.innerState = state;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section.reg-wrapper {
  min-height: 100vh;
  background-color: variables.$v2-primary;

  .reg-side {
    flex: 1 1 0;
    padding: 5px 0;

    .container {
      max-width: 640px;
    }

    @media all and (max-width: 1050px) {
        padding: 15px;
    }

    @media all and (max-width: 1440px) {
      .container {
        max-width: 480px;
      }
    }

    &:first-child {
      background-image: linear-gradient(-90deg, rgba(#1c2856, .9) 0%, rgba(#0a163f, .9) 100% ),
      url("~@/assets/images/bg_2560.webp");
      background-size: cover;
      background-position: center;
    }
  }

  @media all and (min-width: variables.$breakpoint-xl) {
      .registration-wrapper {
        align-items: center;
      }
    }
}
</style>
