import accessDragData from '@/app-buyer/mixins/drag-drop-access';

const draggableElement = {
  mixins: [accessDragData],
  data() {
    return {
      isDragged: false,
      draggedElement: null,
    };
  },
  methods: {
    onDragStart(data, type, $event) {
      const parent = this.$refs[`part-row-${data.hash}`];
      const preview = parent.cloneNode(true);
      preview.classList.add('preview');
      preview.style.width = '50vw';
      preview.style.height = '10vh';
      preview.style.opacity = '0.1';
      preview.style.filter = 'opacity(10%)';
      preview.style.position = 'relative';
      preview.id = 'buyer-app';

      document.body.appendChild(preview);

      $event.dataTransfer.setDragImage(preview, 0, 0);
      $event.dataTransfer.setData('text/plain', ''); // Necessary for Firefox to start dragging

      this.draggedElement = preview;

      this.isDragged = true;
      this._setDragData(data, type);
    },
    onDragEnd() {
      if (this.draggedElement) {
        document.body.removeChild(this.draggedElement);
        this.draggedElement = null;
      }

      this.isDragged = false;
      this._removeDragData();
    },
  },
};

export default draggableElement;
