import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { config } from './env-variable';
import Api from '@/app-buyer/api/api';

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  cluster: 'eu',
  authEndpoint: `${config('API_URL')}/broadcasting/auth`,
  key: config('PUSHER_APP_KEY') || 'app-key',
  wsHost: config('PUSHER_HOST') || config('API_URL'),
  wsPort: config('PUSHER_PORT'),
  wssPort: config('PUSHER_PORT'),
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        Api
          .post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name
          })
          .then(response => {
            callback(false, response.data);
          })
          .catch(error => {
            callback(true, error);
          });
      }
    };
  },
});

// eslint-disable-next-line no-undef
window.Echo
  .channel('PublicNotificationTestChannel')
  .listen('WebsocketTestEvent', (event) => {
    // eslint-disable-next-line no-alert
    alert(event.message);
  });
