<template>
  <div
    id="buyer-app"
    class="modal-card"
    style="overflow: visible;"
  >
    <div class="modal-card-body tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs">
      <h1 class="tw-font-bold tw-text-xl tw-mb-3">
        Confirm your configurations
      </h1>
      <p class="tw-text-md tw-mb-4">
        By requesting {{ partCount === 1 ? 'a manual quote' : 'these manual quotes' }}, you
        confirm that you have clarified any discrepancies between the selected configurations and
        any attached engineering drawings for each part.
      </p>
      <div
        v-if="hasSelectedPartsParsingCount || hasSelectedPartsWithErrors"
        class="issues-wrapper"
      >
        <b-message
          v-if="hasSelectedPartsParsingCount"
          class="tw-text-md"
          type="is-danger"
        >
          <span class="message-title">{{ selectedPartsParsingCount }}/{{ partCount }}
            selected parts are still being analysed</span>
          Very complex files can take up to 5 mins
        </b-message>
        <b-message
          v-if="hasSelectedPartsWithErrors"
          class="tw-text-md"
          type="is-danger"
        >
          <span class="message-title">{{ selectedPartsWithErrorsCount }}/{{ partCount }}
            parts have warnings</span>
          Please resolve the warnings to request the selected quotes
        </b-message>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-mt-6">
        <g-button
          isInverted
          label="Cancel"
          color="tertiary"
          @click="$emit('close')"
        />
        <g-button
          :isDisabled="hasSelectedPartsWithErrors || hasSelectedPartsParsingCount"
          :label="`Get ${partCount} manual ${partCount >= 2 ? 'quotes' : 'quote' } now`"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
} from '@/app-buyer/store/modules/rfq/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';

export default {
  name: 'ConfirmConfigurationForm',

  components: {
    GButton,
  },

  props: {
    selected: {
      type: Boolean,
    },
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),

    partCount() {
      return this.selected ? this.selectedManualQuoteCount : this.allManualQuoteCount;
    },

    submittableDrafts() {
      return this[DRAFT_RFQS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];
        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    submittableSelectedDrafts() {
      return this[GET_ALL_SELECTED_PARTS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];

        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    allManualQuotes() {
      return this.submittableDrafts?.filter((s) => {
        const modelFileType = s.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model')?.extension?.toLowerCase();
        if ((MANUAL_QUOTE_EXTENSIONS.includes(modelFileType) && !s.status) || s.status === 'manual') return s;
      });
    },

    selectedManualQuotes() {
      return this.submittableSelectedDrafts?.filter((s) => {
        const modelFileType = s.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model')?.extension?.toLowerCase();
        if ((MANUAL_QUOTE_EXTENSIONS.includes(modelFileType) && !s.status) || s.status === 'manual') return s;
      });
    },

    allManualQuoteCount() {
      return this.allManualQuotes?.length ?? 0;
    },

    selectedManualQuoteCount() {
      return this.selectedManualQuotes?.length ?? 0;
    },

    selectedPartsWithErrorsCount() {
      return this.partsWithIssues('error');
    },

    selectedPartsParsingCount() {
      return this.partsWithIssues('parsing');
    },

    hasSelectedPartsWithErrors() {
      return this.selectedPartsWithErrorsCount > 0;
    },

    hasSelectedPartsParsingCount() {
      return this.selectedPartsParsingCount > 0;
    },
  },

  methods: {
    submit() {
      this.$emit('submit');
      this.$emit('close');
    },

    partsWithIssues(errorType) {
      if (!this.selected) {
        return this.allManualQuotes.filter((d) => {
          const issues = this[DRAFT_RFQ_ISSUES][d.hash];
          if (issues?.length) {
            let errors = false;
            issues.forEach((issue) => {
              if (typeof issue === 'object' && issue.type === errorType) {
                errors = true;
              }
            });
            return errors;
          }
          return issues;
        }).length;
      }

      return this.selectedManualQuotes.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES][d.hash];
        if (issues?.length) {
          let errors = false;
          issues.forEach((issue) => {
            if (typeof issue === 'object' && issue.type === errorType) {
              errors = true;
            }
          });
          return errors;
        }
        return issues;
      }).length;
    },
  },
};
</script>
<style
  lang="scss"
  scoped
>
.modal-card-body {
  border-radius: 4px !important;

  .issues-wrapper {
    margin-top: 3.5rem;
  }
}

::v-deep {
  .message-title {
    font-weight: 700;
    margin-right: 0.5rem;
  }

  .message-body {
    padding: 0.7rem;
  }

  .message:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>
