<template>
  <div class="confirm-notes-modal-wrapper modal-card">
    <div class="modal-card-head has-background-white is-flex is-flex-direction-column is-align-items-start">
      <div class="tw-flex tw-items-center tw-justify-between tw-pb-3 tw-w-full">
        <p class="tw-font-bold tw-text-lg">There are notes to review and approve</p>
        <button
          class="tw-text-black tw-text-lg"
          @click="$emit('close')"
        >
          <font-awesome-icon icon="times" />
        </button>
      </div>
      <p class="tw-text-md tw-mb-3">You have Geomiq notes related to your quotes. You need to approve these before you can add your parts to cart:</p>
      <QuoteRowSimple
        v-for="quote in quotes"
        :key="quote.reference"
        :quote="quote"
      />
    </div>
    <div class="modal-card-foot tw-flex tw-items-center tw-justify-between">
        <g-button
          isInverted
          label="Cancel"
          color="tertiary"
          @click="$emit('close')"
        />
        <g-button
          label="Approve all notes"
          @click="handleAcceptAllNotes"
        />
    </div>
  </div>
</template>

<script>
import QuoteRowSimple from '@/app-buyer/components/grouped-quotes/QuoteRowSimple.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  components: {
    QuoteRowSimple,
    GButton,
  },
  props: {
    quotes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleAcceptAllNotes() {
      this.quotes.forEach((q) => {
        localStorage.setItem(`notes_${q.hash}_quote_${q.quote_id}`, 'true');
      })
      this.$emit('close');
      this.$emit('add-to-cart',{ addAllQuoted: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-notes-modal-wrapper {
  .modal-card-head {
    height: 22rem;
    overflow-y: auto;
  }
}
</style>
