<template>
  <tippy
    :key="user.id"
    :content="hoverText"
    placement="right"
    arrow
    boundary="window"
  >
    <template #trigger>
      <g-initials-tag
        :name="user.name || user.email"
        :color="memberColor"
        size="sm"
        class="tw-border tw-border-grey-lighter"
      />
    </template>
  </tippy>
</template>

<script>
import GInitialsTag from '@common/components/storied/atoms/GInitialsTag.vue';

export default {
  name: 'MemberAvatar',

  components: {
    GInitialsTag,
  },

  props: {
    user: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      colors: ['purple-muted', 'success-muted', 'tertiary-muted', 'primary-light-pressed', 'error-muted', 'pink-muted', 'red-muted'],
      pendingColor: 'grey-light'
    }
  },

  computed: {
    hoverText() {
      return this.user.pending ? `${this.user.email} (Pending)` : this.user.name;
    },

    memberColor() {
      if (!this.user?.email) return this.colors[0];
      if (this.user.pending) return this.pendingColor;

      const name = this.user?.email?.split('@')[0];
      return this.colors[name.charCodeAt(0) % this.colors.length];
    }
  },
};
</script>

<style lang="scss" scoped>
.initials {
  font-size: 10px;
  font-weight: 500;
}

.pending {
  z-index: 0;
}

.user-avatar {
  z-index: 1;
}
</style>
