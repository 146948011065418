import {
  ACTIVE_PROJECT_HASH,
  ACTIVE_PROJECT_TAB,
  ARCHIVED_LOADED,
  ON_INITIAL_LOAD,
  PROJECT_INVITE_MODAL,
  PROJECTS,
  ARCHIVED_PROJECTS,
  PAGINATION_PAGES,
  CURRENT_PROJECT,
  PROJECT_SUPPORTING_FILE_MODAL,
  PROJECTS_MRFQ,
  MRFQS_PAGINATION,
  MRFQS_PAGINATION_PAGES,
} from '@/app-buyer/store/modules/projects/types';
import { INITIAL_LOAD, PAGINATION } from '@/app-buyer/store/modules/types';

export default function getState() {
  return {
    [PROJECTS]: [],
    [ARCHIVED_PROJECTS]: [],
    [INITIAL_LOAD]: false,
    [ON_INITIAL_LOAD]: [],
    [ACTIVE_PROJECT_HASH]: null,
    [PROJECT_INVITE_MODAL]: false,
    [ARCHIVED_LOADED]: false,
    [PAGINATION]: {},
    [PAGINATION_PAGES]: [],
    [CURRENT_PROJECT]: [],
    [PROJECT_SUPPORTING_FILE_MODAL]: null,
    [PROJECTS_MRFQ]: [],
    [MRFQS_PAGINATION]: {},
    [MRFQS_PAGINATION_PAGES]: [],
  };
}
