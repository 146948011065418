<template>
  <section data-testid="login-form">
    <validation-observer v-slot="{ passes }">
      <form
        @submit.prevent.stop
      >
        <transition-group
          class="input-wrapper tw-my-4 last:tw-mb-0"
          mode="out-in"
          name="slide-left"
          tag="ul"
        >
          <li
            key="1"
            class="input-wrapper tw-py-4"
          >
            <b-input-with-validator
              v-model="email"
              :field-type="fieldType"
              label="Email"
              name="email"
              placeholder="name@company.com"
              rules="required|email"
              trim
              vid="email"
              custom-class=""
            />
          </li>
          <li
            key="2"
            class="input-wrapper"
          >
            <b-field :message="message" :type="fieldType">
              <div>
                <div class="tw-flex tw-items-center tw-justify-between">
                  <label class="label">Password <span class="tw-font-normal tw-opacity-75">(optional)</span></label>
                  <div class="tw-mb-2">
                    <g-button
                      tabindex="-1"
                      label="Forgot password"
                      type="underline"
                      color="tertiary"
                      fontWeight="normal"
                      class="tw-inline-flex tw-border-none"
                      size="lg"
                      @click="showPasswordReset = !showPasswordReset"
                      data-testid="reset-password-link"
                    />
                  </div>
                </div>
                <div class="control">
                  <input
                    v-model="password"
                    type="password"
                    autocomplete="on"
                    label="Password"
                    name="password"
                    placeholder="Your password"
                    class="input"
                  >
                </div>
              </div>
            </b-field>
          </li>
          <li
            key="4"
            class="input-wrapper tw-mt-2"
          >
            <b-field v-if="password">
              <b-checkbox
                v-model="remember"
              >
                Keep me logged in
              </b-checkbox>
            </b-field>
            <b-field
              key="9"
              class="tw-mt-4 tw-mb-3"
            >
              <g-button
                :isLoading="submitted"
                :isDisabled="sendingLink"
                isFullWidth
                label="Login"
                size="lg"
                native-type="submit"
                data-testid="sign-in-button"
                @click="passes(attemptPasswordLogin)"
              />
            </b-field>
            <div class="tw-flex tw-items-center">
              <hr class="mt-2 mb-2" style="border-top: 1px solid #ddd;clear: both;flex-grow: 1;">
              <div class="ml-3 mr-3">OR</div>
              <hr class="mt-2 mb-2" style="border-top: 1px solid #ddd;clear: both;flex-grow: 1;">
            </div>
            <b-field
              key="10"
              class="tw-my-3"
            >
              <g-button
                :isLoading="sendingLink"
                :isDisabled="submitted"
                isFullWidth
                isInverted
                label="Login without Password"
                color="grey-dark"
                size="lg"
                native-type="submit"
                data-testid="sign-in-button"
                @click="passes(emailLoginLink)"
              />
            </b-field>
            <div class="login-link-prompt tw-flex tw-items-center tw-p-4 tw-text-grey-darkest tw-bg-grey-lighter tw-rounded-xs">
              <div class="tw-pr-4">
                <font-awesome-icon :icon="['fas', 'magic']"/>
              </div>
              <span>We'll email you a magic login-link for a password-free sign in. Or you can login as normal with your password.</span>
            </div>
          </li>
        </transition-group>
      </form>
    </validation-observer>
    <b-modal
      :active.sync="showPasswordReset"
      has-modal-card
    >
      <EmailPasswordResetForm />
    </b-modal>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import {
  AUTH_MODULE,
  CREATE_LOGIN_LINK,
  FORCE_AUTH,
  LOGIN,
} from '@/app-buyer/store/modules/auth/types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import notificationInjection
  from '../../../shared/components/notification/notification-injection-mixin';
import EmailPasswordResetForm from '../password-reset/EmailPasswordResetForm.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'LoginForm',
  components: {
    EmailPasswordResetForm,
    FontAwesomeIcon,
    ValidationObserver,
    GButton,
  },
  mixins: [notificationInjection],
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      showPasswordReset: false,
      submitted: false,
      sendingLink: false,
      fieldType: null,
      message: null,
    };
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      login: LOGIN,
      createLoginLink: CREATE_LOGIN_LINK,
    }),
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),
    async attemptPasswordLogin() {
      this.submitted = true;
      const { status } = await this.login({
        email: this.email,
        password: this.password,
        remember: this.remember,
      });
      if (status < 300) {
        this[FORCE_AUTH](null);
        this._addNotification({
          type: 'is-success',
          message: 'Successfully logged in!',
        });
      }
      if (status === 419) {
        this._addNotification({
          type: 'is-danger',
          message: 'Page has Expired! Please try again after refresh.',
        });
        setTimeout(() => window.location.reload(), 1000);
      } else if (status >= 300) {
        this.fieldType = 'is-danger';
        this.message = 'Invalid email or password!';
      }
      this.submitted = false;
    },
    async emailLoginLink() {
      this.sendingLink = true;
      const status = await this.createLoginLink({email: this.email});
      if (status < 300) {
        this._addNotification({
          type: 'is-success',
          message: `We've emailed a login link to ${this.email}`,
        });
      }
      if (status === 419) {
        this._addNotification({
          type: 'is-danger',
          message: 'Page has Expired! Please try again after refresh.',
        });
        setTimeout(() => window.location.reload(), 1000);
      } else if (status >= 300) {
        this.fieldType = 'is-danger';
        this.message = 'An account with that email address could not be found.';
      }
      this.sendingLink = false;
    },
  },

};
</script>

<style
  lang="scss"
  scoped
>
.login-link-prompt {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #ededed;
  color: #626262;
  border-radius: 5px;
}
</style>
