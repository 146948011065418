<template>
  <section :class="[
    compact ? 'xl:tw-pb-12' : 'tw-h-full tw-mt-3',
    { 'logged-in' : LOGGED_IN, 'tw-pointer-events-none' : _dragType === 'draft-rfq-supporting-file' },
    ]">
    <file-upload
      ref="partUpload"
      multiple
      drag-drop
      :accept="acceptedFiles"
      :class="{'tw-h-full' : !compact}"
      :label-style="{
        height: '100%'
      }"
      data-testid="main-upload"
      @upload="handleAttemptCreateQuotes($event)"
    >
      <div
        class="drop-area__inner tw-flex tw-flex-col tw-justify-center tw-rounded-sm tw-transition tw-items-center tw-bg-grey-lighter tw-h-full tw-cursor-pointer hover:tw-bg-grey-lightest"
      >
        <template v-if="compact">
          <div class="tw-flex tw-flex-col justify-center tw-items-center tw-py-5 tw-text-grey-darkest">
            <div class="tw-text-center">
              <h2 class="tw-text-lg tw-font-bold tw-mb-1">
                {{ IS_DESKTOP ? 'Drag and drop or click here' : 'Browse' }} to upload your files
              </h2>
              <p class="tw-text-sm tw-mx-auto tw-w-9/12 xl:tw-w-full">
                Maximum file size: 100MB , Accepted file
                types: STEP .STP .IGES .IGS .PDF .DXF .DWG .STL
              </p>
            </div>
            <div class="tw-flex tw-text-sm tw-items-center tw-mt-4">
              <font-awesome-icon
                icon="badge-check"
                class="tw-mr-1"
              />
              <p class="tw-text-sm tw-font-bold">ISO9001:2015 & ISO13485:2016 Accredited</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-grey-darkest"
          >
            <figure class="image">
              <img
                :src="require('../../../assets/images/uploader-icons.svg')"
                alt="uploader-icons"
              >
            </figure>
            <h2 class="tw-text-xxl tw-text-center tw-font-bold">
              Drag and drop or <span class="tw-text-tertiary">click here</span> to upload your files
            </h2>
            <div class="tw-flex tw-text-sm tw-items-center tw-mt-2">
              <font-awesome-icon
                icon="badge-check"
                class="tw-mr-1"
              />
              <p class="tw-text-sm tw-font-bold">ISO9001:2015 & ISO13485:2016 Accredited</p>
            </div>
            <p class="tw-text-md tw-block tw-text-center tw-mt-2">Maximum file size: 100MB</p>
          </div>

          <div class="upload-grid tw-grid tw-text-md tw-text-center tw-mt-12 tw-gap-x-1 tw-mx-auto">
            <div class="upload-grid-header tw-grid tw-border-grey-light tw-border-b">
              <div class="tw-font-bold tw-text-left tw-pb-2">Accepted file types</div>
              <div class="tw-pb-2 xl:tw-whitespace-nowrap">Injection Moulding</div>
              <div class="tw-pb-2">CNC</div>
              <div class="tw-pb-2">Sheet Metal</div>
              <div class="tw-pb-2">3D Printing</div>
            </div>


            <div
              v-for="row in data"
              class="upload-grid-row tw-grid tw-py-1 tw-border-grey-light tw-border-b"
            >
              <div class="tw-text-left xl:tw-whitespace-nowrap">{{ row.type }} <span
                v-if="row.recommended"
                class="tw-text-tertiary tw-bg-tertiary-light tw-rounded-xs tw-px-1 tw-py-05 tw-text-sm tw-whitespace-nowrap"
              ><font-awesome-icon icon="star" class="tw-mr-1" />Best for auto quotes</span></div>
              <div class="tw-flex tw-items-center tw-justify-center">
                <font-awesome-icon
                  :icon="row.im ? 'check' : 'times'"
                  :class="row.im ? 'tw-text-grey-dark' : 'tw-text-grey-light'"
                />
              </div>
              <div class="tw-flex tw-items-center tw-justify-center">
                <font-awesome-icon
                  :icon="row.cnc ? 'check' : 'times'"
                  :class="row.cnc ? 'tw-text-grey-dark' : 'tw-text-grey-light'"
                />
              </div>
              <div class="tw-flex tw-items-center tw-justify-center">
                <font-awesome-icon
                  :icon="row.sheet ? 'check' : 'times'"
                  :class="row.sheet ? 'tw-text-grey-dark' : 'tw-text-grey-light'"
                />
              </div>
              <div class="tw-flex tw-items-center tw-justify-center">
                <font-awesome-icon
                  :icon="row.threed ? 'check' : 'times'"
                  :class="row.threed ? 'tw-text-grey-dark' : 'tw-text-grey-light'"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </file-upload>

    <g-modal
      :is-active.sync="isPdfModalOpen"
      :canCancel="false"
      width="1000"
      modalWidth="tw-w-full"
      modalHeight="tw-h-full"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs">
        <div class="tw-flex tw-items-center">
          <font-awesome-icon icon="exclamation-circle" class="tw-text-pending tw-mr-1 tw-text-lg" />
          <p class="tw-font-bold tw-text-lg">Upload {{ hasMultiplePdfs ? 'CAD files' : 'a CAD file' }} instead of {{ hasMultiplePdfs ? 'PDFs' : 'a PDF' }} for a quicker, more competitive quote</p>
        </div>
        <div class="tw-flex tw-items-center tw-justify-end tw-mt-6">
          <g-button
            isInverted
            :label="`Continue with PDF${hasMultiplePdfs ? 's' : ''}`"
            color="tertiary"
            class="tw-mr-3"
            @click="handleCreateQuotes(files)"
          />
          <g-button
            :label="`Upload ${hasMultiplePdfs ? 'CAD files' : 'a CAD file'}`"
            @click="handleUploadCAD"
          />
        </div>
      </div>
    </g-modal>
  </section>
</template>

<script>
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import GModal from '@/app-buyer/components/GModal';
import GButton from '@common/components/storied/atoms/GButton.vue';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import checkPdfUpload from '@/app-buyer/mixins/check-pdf-upload';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapState } from 'vuex';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';

export default {
  name: 'PartUpload',

  components: {
    FileUpload,
    GModal,
    GButton,
    FontAwesomeIcon,
  },

  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    accessDragData,
    checkPdfUpload,
  ],

  data() {
    return {
      data: [
        {
          type: '.STEP . STP', recommended: true, im: true, cnc: true, sheet: true, threed: true,
        },
        {
          type: '.IGES, .IGS', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.PDF', im: true, cnc: true, sheet: true, threed: false,
        },
        {
          type: '.DXF, .DWG', im: false, cnc: false, sheet: true, threed: false,
        },
        {
          type: '.STL', im: false, cnc: false, sheet: false, threed: true,
        },
      ],
    };
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),
  },
};
</script>

<style
  lang="scss"
  scoped
>
.logged-in {
  .upload-grid {
    width: 95%;

    @screen xl {
      width: 69%;
    }
  }
}

.upload-grid {
  width: 45%;

  .upload-grid-header,
  .upload-grid-row {
    grid-template-columns: 40% 15% 15% 15% 15%;
  }
}
</style>
