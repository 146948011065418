<template>
  <div
    v-if="isReceived"
    class="tw-flex tw-items-center tw-justify-center"
  >
    <InvoiceSummaryLayout>
      Your rating
      <template #subtitle>
        <div class="tw-flex">
          <div
            v-for="n in 5"
            :key="n"
          >
            <BIcon
              :type="rating >= n ? 'is-v2-supporting-1' : 'is-light'"
              icon="star"
              size="is-small"
              style="font-size: 1rem;"
            />
          </div>
        </div>
      </template>
    </InvoiceSummaryLayout>
    <OrderFeedbackButton
      v-if="!rating"
      :invoice="invoice"
    >
      <template #default="{ showFeedbackModal }">
        <g-button
          slot="trigger"
          isFullWidth
          color="tertiary"
          label="Rate part(s)"
          class="tw-ml-4"
          @click="showFeedbackModal"
        />
      </template>
    </OrderFeedbackButton>
  </div>
  <div v-else />
</template>
<script>
import OrderFeedbackButton
  from '@/app-buyer/components/order-details-collapsable-card/OrderFeedbackButton.vue';
import InvoiceSummaryLayout from '@/app-buyer/views/orders/InvoiceSummaryLayout.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import STATUS_SEQ_NUM from '@/shared/consts/status-sequence-numbers';

export default {
  name: 'OrderFeedbackCell',
  components: {
    InvoiceSummaryLayout,
    OrderFeedbackButton,
    GButton,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rating() {
      return this.invoice.feedback?.rating;
    },
    isReceived() {
      let received = false;

      this.invoice.last_5_rfqs.forEach((rfq) => rfq.order?.product_lifecycle?.statuses
        .forEach((status) => {
          if (status.sequence_number >= STATUS_SEQ_NUM.CUSTOMER_RECEIVED) received = true;
        }));

      return received;
    },
  },
};
</script>
