<template>
  <g-message
    :color="issue.type"
    isFullWidth
    :hasLeftBorder="issue.hasLeftBorder"
    :borderColor="issue.borderColor || ''"
    :iconLeft="issue.iconLeft || ''"
    :iconColor="issue.iconColor || ''"
  >
    <div class="tw-flex tw-flex-col tw-justify-between tw-text-sm tw-w-full xl:tw-flex-row xl:tw-items-center">
      <div class="error-message-text tw-flex tw-flex-col tw-mb-2 xl:tw-mb-0 xl:tw-mr-2">
        <span
          v-if="issue.messageTitle"
          class="message-title tw-font-medium"
        >{{ issue.messageTitle }}</span>
        <p>
          {{ issue.message }}
          <span v-if="issue.inlineComponent">
          <Component
            :is="issue.inlineComponent"
            :part="part"
            :btn-text="issue.btnText"
            :btn-type="issue.btnType"
          />
        </span>
        </p>
      </div>
      <Component
        :is="issue.component"
        :part="part"
        :btn-text="issue.btnText"
        :btn-type="issue.btnType"
        :show-editor="issue.showEditor"
        :service="issue.service"
        :from-error="true"
      />
    </div>
  </g-message>
</template>

<script>
import GMessage from '@common/components/storied/molecules/GMessage.vue';

export default {
  name: 'PartErrorMessage',

  components: {
    GMessage,
  },

  props: {
    issue: {
      type: Object,
      required: true,
    },
    part: {
      type: Object,
      required: true,
    },
    hasLeftBorder: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    textColor(color) {
      return `tw-text-${color}`;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
</style>
