const leadTimeCalculator = {
  data() {
    return {
      // These are the current delivery days on
      // top of the base expedited values.
      'cnc': {
        expedited: 0,
        standard: 5,
        economy: 15,
      },
      '3d-printing': {
        expedited: 0,
        standard: 2,
        economy: 5,
      },
      'sheet-metal': {
        expedited: 0,
        standard: 3,
        economy: 9,
      },
      'injection-moulding': {
        expedited: 0,
        standard: 9,
        economy: 19,
      },

      quantityDelays: [
        { min: 1, max: 2, value: 0 },
        { min: 3, max: 9, value: 1 },
        { min: 10, max: 24, value: 2 },
        { min: 25, max: 79, value: 3 },
        { min: 80, max: 149, value: 4 },
        { min: 150, max: 249, value: 5 },
        { min: 250, max: 499, value: 6 },
        { min: 500, max: 999, value: 7 },
        { min: 1000, max: 1999, value: 8 },
        { min: 2000, max: 99999999, value: 10 },
      ],

      toleranceDelays: [
        { key: '0127mm', value: 0 },
        { key: '01mm', value: 0 },
        { key:'005mm', value: 1 },
        { key:'0025mm', value: 2 },
        { key:'001mm', value: 3 },
        { key: '0005mm', value: 4 },
        { key: '0001mm', value: 4 },
      ],

      // Until the other services have dynamic lead times,
      // these are the hardcoded base values.
      hardcodedBaseCnc: 8,
      hardcodedBase3D: 5,
      hardcodedBaseSheet: 5,
      hardcodedBaseIM: 11,
    };
  },

  methods: {
    leadTimeDate({ date }) {
      const formattedDate = new Date(date);
      const options = { weekday: 'short', day: 'numeric', month: 'short' };

      return formattedDate.toLocaleDateString('en-UK', options);
    },

    calcDelayRR({ service, lead_time, quantity_initial, tolerance, pre_quantity, pre_tolerance, pre_service, lead_time_speed}) {
      if (service !== 'cnc') return lead_time;

      let baseLeadTime = lead_time < this.hardcodedBaseCnc ? this.hardcodedBaseCnc : lead_time;

      let quantityPlus = 0;
      let quantityInitial = 0;
      let tolerancePlus = 0;
      let toleranceInitial = 0;

      this.quantityDelays.forEach((qD) => {
        if (quantity_initial >= qD.min && quantity_initial <= qD.max) {
          quantityPlus = qD.value;
        }

        if (pre_quantity >= qD.min && pre_quantity <= qD.max) {
          quantityInitial = qD.value;
        }
      });

      this.toleranceDelays.forEach((tD) => {
        if (tolerance === tD.key) {
          tolerancePlus = tD.value;
        }

        if (pre_tolerance === tD.key) {
          toleranceInitial = tD.value;
        }
      });

      if (pre_service !== 'cnc') {
        baseLeadTime += this['cnc'][lead_time_speed];

        return baseLeadTime + (quantityPlus + tolerancePlus);
      }

      return baseLeadTime + ((quantityPlus + tolerancePlus) - (quantityInitial + toleranceInitial));
    },
  },
};

export default leadTimeCalculator;
