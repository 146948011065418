export const orderColumns = [
  {
    name: 'Ref',
    customStyle: 'width:86px;',
  },
  {
    name: '',
    customStyle: 'width:62px;',
  },
  {
    name: 'Part name',
    customStyle: '',
  },
  {
    name: 'Service',
    customStyle: 'width:110px;',
  },
  {
    name: 'Qty.',
    customStyle: 'width:50px;',
  },
  {
    name: 'Material',
    customStyle: 'width:120px;',
  },
  {
    name: 'Delivery by',
    customStyle: 'width:200px;',
  },
  {
    name: 'Status',
    customStyle: 'width:150px;',
  },
  {
    name: 'Unit Price',
    customStyle: 'text-align:right;width:70px;',
  },
  {
    name: 'Subtotal',
    customStyle: 'text-align:right;width:70px;',
  },
  {
    name: '',
    customStyle: 'width:4rem;',
  },
  {
    name: '',
    customStyle: 'width:56px;',
  },
];

export const quoteColumns = [
  {
    name: 'Ref',
    customStyle: 'width:86px;',
  },
  {
    name: '',
    customStyle: 'width:62px;',
  },
  {
    name: 'Part name',
    customStyle: '',
  },
  {
    name: 'Service',
    customStyle: 'width:110px;',
  },
  {
    name: 'Qty.',
    customStyle: 'width:50px;',
  },
  {
    name: 'Material',
    customStyle: 'width:120px;',
  },
  {
    name: 'Shipping in',
    customStyle: 'width:150px;',
  },
  {
    name: 'Delivery by',
    customStyle: 'width:150px;',
  },
  {
    name: 'Status',
    customStyle: 'width:140px;',
  },
  {
    name: 'Unit Price',
    customStyle: 'text-align:right;width:70px;',
  },
  {
    name: 'Subtotal',
    customStyle: 'text-align:right;width:70px;',
  },
  {
    name: '',
    customStyle: 'width:4rem;',
  },
  {
    name: '',
    customStyle: 'width:56px;',
  },
];
