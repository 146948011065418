<template>
  <div
    tabindex="1"
    class="faux-wrapper is-flex-grow-1 is-flex is-flex-wrap-wrap"
  >
    <BTag
      v-for="user in value"
      :key="user.email"
      class="m-1"
      size="is-medium"
      closable
      @close="$emit('input', value.filter((e) => e !== user))"
    >
      <div class="is-flex is-align-items-center">
        <MemberAvatar
          v-if="user.name"
          :user="user"
        />
        <p class="ml-2">
          {{ user.name || user.email }}
        </p>
      </div>
    </BTag>
    <div class="is-flex-grow-1 is-flex">
      <BAutocomplete
        v-model="searchTerm"
        :loading="loading"
        :data="suggestions"
        :disabled="disabled"
        expanded
        class="is-flex-grow-1"
        data-testid="add-team-member-input"
        @input="handleInput"
        @select="$emit('input', [...value, $event])"
      >
        <template #default="{ option }">
          <div class="is-flex is-align-items-center">
            <MemberAvatar :user="option" />
            <p
              class="ml-2"
              data-testid="add-team-member-autofill-add-email"
            >
              {{ option.name }}
            </p>
          </div>
        </template>
        <template #empty>
          <div
            class="flex is-justify-space-between has-text-black"
          >
            <div>
              <p class="has-text-black">
                {{ searchTerm }}
              </p>
              <template v-if="messages && messages.email">
                <small
                  v-for="m in messages.email"
                  :key="m"
                  class="has-text-danger"
                >
                  {{ m }}
                </small>
              </template>
            </div>
            <BButton
              :disabled="messages && messages.email"
              class="has-text-black"
              outlined
              icon-right="plus"
              :loading="emailValidating"
              data-testid="add-team-member-manual-add-email"
              @click="addEmail"
            />
          </div>
        </template>
      </BAutocomplete>
    </div>
  </div>
</template>

<script>
import validateEmailMixin from '@/app-buyer/mixins/validate-email';
import Api from '@/app-buyer/api/api';
import MemberAvatar from '@/app-buyer/components/teams/MemberAvatar.vue';

export default {
  name: 'ProjectMemberInviteInput',
  components: { MemberAvatar },
  mixins: [validateEmailMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    members: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      searchTerm: null,
      prevUsers: [],
      loading: false,
    };
  },
  computed: {
    suggestions() {
      const valueLower = this.searchTerm?.toLowerCase();
      return this.prevUsers.filter((user) => (
        this.value.findIndex((u) => u.email === user.email) === -1
        && this.members.findIndex((m) => m.user.email === user.email) === -1
        && valueLower !== ''
        && (user.name?.toLowerCase().indexOf(valueLower) >= 0
          || user.email.indexOf(valueLower) >= 0)
      ));
    },
  },
  created() {
    this.getPrevUsers();
  },
  methods: {
    async getPrevUsers() {
      this.loading = true;
      try {
        const { data } = await Api.get('v2/projects/previous-members');
        this.prevUsers = data.data;
      } finally {
        this.loading = false;
      }
    },
    handleInput(value) {
      this.$emit('update:errors', null);
      this.emailValidating = true;
      this.email = value;
      this.validateEmail(true);
    },
    addEmail() {
      this.$emit('input', [...this.value, { email: this.email }]);
      this.searchTerm = null;
      this.email = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.faux-wrapper {
  border: 1px solid #dbdbdb;
  border-radius: 5px;

  ::v-deep {
    input.input {
      border: none !important;
    }

    .dropdown-item.is-disabled {
      opacity: 1;
      cursor: unset;
    }
  }
}
</style>
