var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.$attrs.name || _vm.$attrs.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('BField',_vm._b({attrs:{"type":_vm.fieldType
      || ((errors[0]
        || (_vm.customErrors && _vm.customErrors[0]))
        && _vm.showError
        && 'is-danger')
      || (valid
        &&
        _vm.showValid
        && 'is-success')
      || '',"label-position":_vm.labelPosition,"custom-class":_vm.customClass,"message":[
      _vm.message,
      ...(_vm.showError
        ? [...errors, ...(_vm.customErrors || [])]
        : [])
    ]},scopedSlots:_vm._u([(_vm.$slots.label)?{key:"label",fn:function(){return [_vm._t("label")]},proxy:true}:null],null,true)},'BField',_vm.$attrs,false),[(_vm.inputType === 'checkbox')?_c('BCheckbox',_vm._g(_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'BCheckbox',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2):(_vm.inputType === 'radio')?_c('BRadio',_vm._g(_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'BRadio',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2):(_vm.inputType === 'dropdown')?_c('BDropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_vm._t("trigger",null,{"active":active})]}}],null,true),model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_vm._t("default")],2):(_vm.trim)?_c('BInput',_vm._g(_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"innerValue"}},'BInput',_vm.$attrs,false),_vm.$listeners)):_c('BInput',_vm._g(_vm._b({model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'BInput',_vm.$attrs,false),_vm.$listeners))],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }