import {
  AUTH_MODULE,
  FORCE_AUTH, LOGGED_IN,
} from '@/app-buyer/store/modules/auth/types';
import { mapMutations, mapState } from 'vuex';
import baseUpload from '@/app-buyer/mixins/base-upload';

const checkPdfUpload = {

  mixins: [
    baseUpload,
  ],

  data() {
    return {
      isPdfModalOpen: false,
      files: null,
      noPdfs: false,
    }
  },

  computed: {
    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
    }),

    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
    }),

    acceptedFiles() {
      return this.noPdfs ? '.iges,.igs,.dwg,.dxf,.stp,.step,.stl' : '.iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl';
    },

    hasMultiplePdfs() {
      if (!this.files) return false;
      return this.files.filter((f) => f.type === 'application/pdf')?.length > 1;
    },
  },

  methods: {
    handleCreateQuotes(files) {
      this.isPdfModalOpen = false;

      this.createQuotes(files);

      if (!this.LOGGED_IN) this[FORCE_AUTH]('register');
    },

    handleAttemptCreateQuotes(files) {
      if (this.noPdfs) return this.handleUploadNoPdfs({ newFiles: files, oldFiles: this.files });

      if (files.every((f) => f.type === 'application/pdf')) {
        this.files = files;
        this.isPdfModalOpen = true;
        return;
      }

      this.handleCreateQuotes(files);
    },

    handleUploadNoPdfs({ newFiles, oldFiles }) {
      const oldFilesNoPdfs = oldFiles.filter((oF) => oF.type !== 'application/pdf');
      const cleanFiles = this.removeDuplicates([...newFiles, ...oldFilesNoPdfs], 'name');

      this.noPdfs = false;
      this.handleCreateQuotes(cleanFiles);
    },

    handleUploadCAD() {
      this.noPdfs = true;

      this.$nextTick(() => {
        this.$refs.partUpload.manualClick();
      });
    },

    removeDuplicates(array, key) {
      const map = new Map();

      array.forEach(item => {
        if (!map.has(item[key])) {
          map.set(item[key], item);
        }
      });

      return Array.from(map.values());
    },
  },
}

export default checkPdfUpload
