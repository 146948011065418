<template>
  <div
    class="tw-relative"
  >
    <div
      id="part-table"
      class="part-table"
    >
      <span v-if="FILTERED_PARTS && !FILTERED_PARTS.length">
        <p class="tw-text-center tw-mt-3">
          No parts found.
        </p>
      </span>
      <part-table-head
        v-bind:showQuoted.sync="showQuoted"
        @add-to-cart="$emit('add-to-cart', $event)"
        @remove-from-cart="$emit('remove-from-cart', $event)"
      />
      <template>
        <div
          v-for="(part, i) in parts"
          :key="part.hash"
          class="part-table-group-wrapper tw-mb-3"
        >
          <PartTableGroupRow
            ref="partTableGroupRow"
            :is-first="i === 0"
            :part="part"
            @shift-select="handleShiftSelect"
            @openConfigurator="handleOpenConfigurator"
            @request-quotes="$emit('request-quotes', $event)"
          />
        </div>
        <PartTableGroupRowForm
          v-if="showMobileConfiguration"
          :part="part"
          @closeMobileConfigurator="handleCloseConfigurator"
          @remove-from-cart="$emit('remove-from-cart', $event)"
        />
        <PartTableUploadingParts />
      </template>
    </div>
    <PartTableDropArea />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  DRAFT_RFQS,
  FILTERED_PARTS,
  RFQ_MODULE,
  RFQS,
  SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import PartTableGroup from '@/app-buyer/components/project/PartTableGroup.vue';
import PartTableHead from '@/app-buyer/components/project/PartTableHead.vue';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartTableUploadingParts from '@/app-buyer/components/project/PartTableUploadingParts.vue';
import PartTableDropArea from '@/app-buyer/components/project/PartTableDropArea.vue';
import PartTableGroupRow from '@/app-buyer/components/project/PartTableGroupRow.vue';
import PartTableGroupRowForm from '@/app-buyer/components/project/PartTableGroupRowForm.vue';

export default {
  name: 'PartTable',
  components: {
    PartTableDropArea,
    PartTableUploadingParts,
    PartTableHead,
    PartTableGroup,
    PartTableGroupRow,
    PartTableGroupRowForm,
  },
  mixins: [partSelectMixin],

  data() {
    return {
      showMobileConfiguration: false,
      showQuoted: false,
    }
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
      RFQS,
      FILTERED_PARTS,
      DRAFT_RFQS,
    }),

    parts() {
      if (this[FILTERED_PARTS]) return this[FILTERED_PARTS];
      else if (this.showQuoted) return this[DRAFT_RFQS].filter((draft) => draft.quote_id);
      else return this[DRAFT_RFQS];
    },

  },
  mounted() {
    if (this[DRAFT_RFQS].length && !this[SELECTED_PART_HASHES]?.length) {
      this.selectParts(this[DRAFT_RFQS][0]?.hash);
    }
  },
  methods: {
    handleShiftSelect(part) {
      const flattenedParts = this.parts;
      const indexStart = flattenedParts.findIndex((e) => this[SELECTED_PART_HASHES].includes(e.hash));
      const indexEnd = flattenedParts.findIndex((e) => e.hash === part.hash);
      let newHashes = [];
      if (indexStart < indexEnd) {
        newHashes = flattenedParts.slice(indexStart, indexEnd + 1).map((p) => p.hash);
      } else {
        newHashes = flattenedParts.slice(indexEnd, indexStart + 1).map((p) => p.hash);
      }
      this.selectParts(...newHashes);
    },

    handleOpenConfigurator(part) {
      this.showMobileConfiguration = true;
      this.part = part;
    },
    handleCloseConfigurator() {
      this.showMobileConfiguration = false;
      this.part = null;
    },

    forceClearNoteNotifications() {
      this.$refs.partTableGroupRow?.forEach((row) => {
        row.forceClearNoteNotifications();
      })
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-table {
  width: 100%;
  user-select: none;
  table-layout: fixed;

  ::v-deep {
    .header-cell,
    td {
      vertical-align: middle;
      font-size: 13px;
      padding: 10px;

      @media all and (min-width: 1230px) and (max-width: 1500px) {
        padding: 5px;
      }

      @media all and (min-width: 1150px) and (max-width: 1229px) {
        padding: 1px;
      }
    }

    .header-cell {
      white-space: nowrap;
      padding-top: 0;
      padding-bottom: 0;
    }

    tbody .row:first-child td {
      padding-top: .5rem !important;
    }
  }
}

</style>
