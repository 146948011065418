<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ pdf.client_original_name }}
      </p>
      <button
        v-if="isProjectPdf"
        aria-label="close"
        class="delete"
        @click="$emit('close')"
      />
    </header>
    <div class="modal-card-content">
      <embed
        :src="pdf.url"
        type="application/pdf"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PDFView',
  props: {
    pdf: {
      type: Object,
      required: true,
    },
    isProjectPdf: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 90vw;
  .modal-card-head {
    display: flex;
    justify-content: center;
    p {
      max-width: 100%;
    }
  }
  &.project-pdf {
    width: 100%;
    max-height: initial;
    height: 100%;
  }
  .modal-card-content {
    height: 90vh;
    embed {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
