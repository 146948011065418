import { mapActions, mapMutations, mapState } from 'vuex';
import { UPDATE } from '@/app-buyer/store/modules/types';
import {
  SET_UPDATE_ADDRESS_IS_LOADING,
  USER_DATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';

const updateAddressMixin = {
  computed: {
    ...mapState(USER_MODULE, {
      userData: USER_DATA,
    }),
  },
  methods: {
    ...mapActions(USER_MODULE, {
      updateUserData: UPDATE,
    }),
    ...mapMutations(USER_MODULE, {
      SET_UPDATE_ADDRESS_IS_LOADING,
    }),
    async updateAddressEvent(type, { data, resolve, reject }) {
      const newData = await this.updateAddress(type, data)
        .catch((error) => reject(error));
      resolve(newData);
    },
    async updateAddress(type, data) {
      this[SET_UPDATE_ADDRESS_IS_LOADING](true);
      const key = `${type}_contact`;
      const copy = JSON.parse(JSON.stringify(this.userData));
      copy[key] = { ...copy[key], ...data };
      if (type === 'delivery' && this.sameAddress) {
        copy.billing_contact = { ...copy[key], ...data };
      }

      this.$emit('updateError', { errorKey: 'hasNoDeliveryAddress', value: false });

      return this.updateUserData(copy);
    },
  },
};

export default updateAddressMixin;
