var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('BTable',{attrs:{"data":_vm.screws,"detailed":"","show-detail-icon":false,"opened-detailed":_vm.openedDetailed,"detail-key":"id"},scopedSlots:_vm._u([{key:"detail",fn:function({ row }){return [_c('div',[_c('p',{staticClass:"title is-7"},[_vm._v("\n          What's included\n        ")]),_c('div',{staticClass:"markdown-html",domProps:{"innerHTML":_vm._s(_vm.$options.markdownConverter.makeHtml(row.metadata.description))}})])]}}])},[_c('BTableColumn',{attrs:{"width":"2rem"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('GmButton',{directives:[{name:"show",rawName:"v-show",value:(row.metadata.description),expression:"row.metadata.description"}],staticClass:"p-1",attrs:{"type":"g-light-1","size":"small"},on:{"click":function($event){_vm.$emit(
          'update-detailed',
          {
            id: row.id,
            isOpen: !_vm.openedDetailed.includes(row.id)
          }
        )}}},[_c('BIcon',{attrs:{"icon":_vm.openedDetailed.includes(row.id) ? 'chevron-down' : 'chevron-right'}})],1)]}}])}),_c('BTableColumn',{scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{staticClass:"is-size-6 has-text-weight-bold"},[_vm._v("\n        "+_vm._s(row.string_value)+"\n      ")])]}}])}),_c('BTableColumn',{attrs:{"width":"10rem"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.quote_addon_label_price)?[_vm._v("\n        + "+_vm._s(_vm._f("formatWithCurrency")(row.quote_addon_label_price))+"\n      ")]:_vm._e()]}}])}),_c('BTableColumn',{attrs:{"numeric":"","width":"10rem"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('GmButton',{attrs:{"type":"info","outlined":_vm.selected.includes(row.id),"icon-left":_vm.selected.includes(row.id) ? 'check' : null,"icon-pack":"fas"},on:{"click":function($event){return _vm.$emit('add-or-remove', row.id)}}},[_vm._v("\n        "+_vm._s(_vm.selected.includes(row.id) ? 'Added' : 'Add')+"\n      ")])]}}])})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("Screws")]),_c('small',[_c('i',[_vm._v(" (you can select multiple)")])])])
}]

export { render, staticRenderFns }