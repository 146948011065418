var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"animated-geomiq-logo",class:{'loading' : _vm.loading},style:(`
       width: ${36.73 * _vm.scale}px;
       height: ${32.01 * _vm.scale}px;
       --scale: ${_vm.scale};
       --stroke: ${_vm.stroke};
       --stroke-width: ${_vm.strokeWidth};
       --fill: ${_vm.fill};
       --duration: ${_vm.duration}s`)},[_c('title',[_vm._v("geomiq")]),_c('path',{attrs:{"stroke":_vm.stroke,"d":_vm.path}})])
}
var staticRenderFns = []

export { render, staticRenderFns }