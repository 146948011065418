<template>
  <transition-group
    id="notification-column"
    tag="ul"
    name="float-in-right"
    :class="{'on-rfq-form' : $route.name === 'quote-page' && _viewedModels && _viewedModels.length}"
    appear
  >
    <li
      v-for="notification in notifications"
      :key="notification.id"
    >
      <notification-component :notification="notification" />
    </li>
  </transition-group>
</template>

<script>
import NotificationComponent from './notification-component.vue';
import accessAllViewed from '../../../app-buyer/mixins/access-all-viewed';

export default {
  name: 'NotificationColumn',
  components: { NotificationComponent },
  mixins: [accessAllViewed],
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>

    #notification-column {
      position: fixed;
      top: calc( var(--navbar-height) + 1rem );
      transition: transform 0.5s;
      right: 1rem;
      z-index: 100;

      @media all and (max-width: variables.$desktop) {
        top: var(--navbar-height);
        transform: translateX(0);
      }
    }
</style>
