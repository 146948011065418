import {
  CART,
  CART_DATA,
  CONFIRMED_CART_DATA,
  CREDIT,
  DISCOUNT_CODE,
  DISCOUNT_CODE_NAME,
  DISCOUNT_RATE,
  DISCOUNT_VALUE,
  ITEMS_TOTAL,
  ORDER_BILLING_EMAIL_ADDRESS,
  ORDER_REFERENCE_FILE,
  ORDER_REFERENCE_NUMBER,
  PAGE_BEFORE_CART,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_PROGRESS,
  PAYMENT_PROGRESS_PAID,
  SAVE_CARD,
  SHIPPING,
  STRIPE,
  SUBTOTAL,
  TAX_AMOUNT,
  TOTAL_AMOUNT,
} from './types';

export default function getState() {
  return {
    [CART]: [],
    [PAYMENT_PROGRESS]: null,
    [TOTAL_AMOUNT]: null,
    [TAX_AMOUNT]: null,
    [SUBTOTAL]: null,
    [SHIPPING]: {},
    [ITEMS_TOTAL]: null,
    [CREDIT]: null,
    [SAVE_CARD]: false,
    [STRIPE]: null,
    [PAYMENT_METHOD]: null,
    [ORDER_REFERENCE_NUMBER]: null,
    [ORDER_BILLING_EMAIL_ADDRESS]: null,
    [ORDER_REFERENCE_FILE]: null,
    [PAYMENT_METHODS]: [],
    [PAYMENT_PROGRESS]: null,
    [PAYMENT_PROGRESS_PAID]: null,
    [CART_DATA]: null,
    [DISCOUNT_VALUE]: null,
    [DISCOUNT_RATE]: null,
    [DISCOUNT_CODE]: null,
    [DISCOUNT_CODE_NAME]: null,
    [CONFIRMED_CART_DATA]: {},
    [PAGE_BEFORE_CART]: null,
  };
}
