import {
  METADATA,
  SET_METADATA,
  SET_USER_COUNTRY,
  USER_COUNTRY,
  USER_DATA,
  SET_UPDATE_ADDRESS_IS_LOADING,
  UPDATE_ADDRESS_IS_LOADING,
  SET_COOKIE_PREFERENCES,
  COOKIE_PREFERENCES,
} from './types';
import { RESET_STATE, SET } from '../types';
import getState from './state';

export default {
  [SET](state, data) {
    state[USER_DATA] = data;
  },
  [SET_METADATA](state, data) {
    state[METADATA] = data;
  },
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    // eslint-disable-next-line no-param-reassign
    state = Object.assign(state, getState());
  },
  [SET_USER_COUNTRY](state, data) {
    state[USER_COUNTRY] = data;
  },
  [SET_UPDATE_ADDRESS_IS_LOADING](state, isLoading) {
    state[UPDATE_ADDRESS_IS_LOADING] = isLoading;
  },
  [SET_COOKIE_PREFERENCES](state, data) {
    state[COOKIE_PREFERENCES] = data;
  }
};
