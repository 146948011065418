<template>
  <div
    class="part-form-footer tw-px-4 tw-py-2 tw-bg-white tw-bottom-0 tw-right-0 tw-left-0 tw-sticky tw-col-span-2 tw-z-10 tw-flex tw-flex-wrap tw-items-center tw-justify-between"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-mb-2 xl:mb-0 xl:tw-w-auto">
      <p class="tw-text-md tw-w-full xl:tw-pt-2 xl:tw-w-auto xl:tw-pt-0 xl:tw-mr-4">
        Selected <span class="tw-font-bold">{{ GET_ALL_SELECTED_PARTS?.length }}/{{
          DRAFT_RFQS?.length
        }}</span>
        parts
      </p>
      <div
        v-if="!IS_DESKTOP && (status || !canSubmitSomeSelected && GET_ALL_SELECTED_PARTS.length)"
        class="tw-flex tw-items-center tw-text-md tw-mr-2 tw-whitespace-nowrap"
      >
        <p class="tw-mr-1">Subtotal price:</p>
        <font-awesome-icon
          v-if="isCalculating || shouldShowSpinner"
          spin
          icon="spinner"
          class="tw-mr-1 tw-text-grey-dark"
        />
        <p
          v-if="status && typeof status === 'number'"
          class="tw-font-bold tw-text-xl"
        >{{ status | formatWithCurrency }}</p>
        <p
          v-else-if="status"
          class="tw-font-bold tw-text-grey-dark"
        >{{ status }}</p>
      </div>
    </div>

    <div class="tw-flex tw-flex-col-reverse tw-items-center tw-w-full xl:tw-w-auto xl:tw-flex-row">
      <div
        v-if="IS_DESKTOP && (status || !canSubmitSomeSelected && GET_ALL_SELECTED_PARTS.length)"
        class="tw-flex tw-items-center tw-text-md tw-mr-2"
      >
        <p class="tw-mr-1">Subtotal price:</p>
        <font-awesome-icon
          v-if="isCalculating || shouldShowSpinner"
          spin
          icon="spinner"
          class="tw-mr-1 tw-text-grey-darkest"
        />
        <p
          v-if="status && typeof status === 'number'"
          class="tw-font-bold"
        >{{ status | formatWithCurrency }}</p>
        <p
          v-else-if="status"
          class="tw-font-bold tw-text-grey-darkest"
        >{{ status }}</p>
      </div>
      <div
        v-if="shouldShowRequestAllManualQuotesBtn"
        class="tw-flex tw-w-full xl:tw-w-auto"
      >
        <g-button
          isFullWidth
          :isLoading="projectContext.submitting"
          :isDisabled="isCalculating"
          label="Get all manual quotes"
          isInverted
          type="primary"
          color="tertiary"
          size="lg"
          class="xl:tw-mr-1"
          @click="$emit('request-quotes', false)"
          data-testid="submit-rfq-btn-quote-all"
        />
      </div>
      <div
        v-else-if="shouldShowAddAllToCartBtn"
        class="tw-flex tw-flex-col tw-w-full xl:tw-w-auto xl:tw-flex-row"
      >
        <g-button
          isFullWidth
          :isLoading="projectContext.submitting"
          :isDisabled="isCalculating"
          label="Add all to cart"
          isInverted
          type="primary"
          color="tertiary"
          size="lg"
          class="xl:tw-mr-1"
          @click="$emit('add-to-cart', { addAllQuoted: true })"
          data-testid="submit-rfq-btn-quote-all"
        />
      </div>
      <div
        v-if="canSubmitSomeSelected"
        class="tw-w-full xl:tw-w-auto"
      >
        <g-button
          isFullWidth
          :isLoading="projectContext.submitting"
          :isDisabled="isCalculating"
          :label="submitBtnStyle.label"
          :color="submitBtnStyle.color"
          :isInverted="submitBtnStyle.isInverted"
          size="lg"
          class="tw-mb-2 tw-whitespace-nowrap xl:tw-mb-0 xl:tw-ml-1"
          @click="handleSubmit"
          data-testid="submit-rfq-btn-quote-selected"
        />
      </div>
      <div
        v-if="GET_ALL_SELECTED_PARTS?.length && isAllInCart"
        class="tw-w-full xl:tw-w-auto"
      >
        <router-link to="/cart">
          <g-button
            isFullWidth
            label="Proceed to cart"
            size="lg"
            class="tw-mb-2 tw-whitespace-nowrap xl:tw-mb-0 xl:tw-ml-1"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  GET_ALL_SELECTED_PARTS,
  RFQ_MODULE,
  RFQ_UPDATE_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import ConfiguratorDraftsSummary
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-drafts-summary.vue';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { CART, CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';
import { hasBeenXMins } from '@/app-buyer/store/modules/rfq/draftIssues';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';

export default {
  name: 'PartFormFooter',

  components: {
    ConfiguratorDraftsSummary,
    GButton,
  },

  inject: {
    projectContext: {
      default: {},
    },
  },

  data() {
    return {
      showSummary: false,
      showNotes: false,
    };
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      RFQ_UPDATE_HASHES,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
      GET_ALL_SELECTED_PARTS,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapState(CART_MODULE, {
      CART,
    }),

    submittableDrafts() {
      return this[DRAFT_RFQS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];
        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    submittableSelectedDrafts() {
      return this[GET_ALL_SELECTED_PARTS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];

        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    isCalculating() {
      return !!this[RFQ_UPDATE_HASHES].length;
    },

    shouldShowSpinner() {
      return !!(
        this[GET_ALL_SELECTED_PARTS]?.some((s) => s.status === 'uploading') ||
        this[GET_ALL_SELECTED_PARTS]?.some((s) => s.status === 'quoting') ||
        this[GET_ALL_SELECTED_PARTS]?.some((s) => s.status === 'parsing' && !hasBeenXMins(this.modelFile(s)?.created_at, 3))
      )
    },

    status() {
      if (!this[GET_ALL_SELECTED_PARTS]?.length) return null;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'uploading')) return `Uploading file${this[GET_ALL_SELECTED_PARTS].length > 1 ? 's' : ''}`;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'parsing' && !hasBeenXMins(this.modelFile(s)?.created_at, 3))) return `Analysing file${this[GET_ALL_SELECTED_PARTS].length > 1 ? 's' : ''}`;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'quoting')) return `Pricing part${this[GET_ALL_SELECTED_PARTS].length > 1 ? 's' : ''}`;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'awaiting')) return `Awaiting quote${this[GET_ALL_SELECTED_PARTS].length > 1 ? 's' : ''}`;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'unable') || (this[GET_ALL_SELECTED_PARTS]?.length && !this.submittableSelectedDrafts?.length)) return 'Unable to quote';
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'manual') && (this[GET_ALL_SELECTED_PARTS]?.length === this.submittableSelectedDrafts?.length)) return null;
      if (this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'quoted')) return this[GET_ALL_SELECTED_PARTS].reduce((acc, s) => acc + s.quote_price, 0);

      return 'Mixed';
    },

    selectedManualQuoteCount() {
      return this.submittableSelectedDrafts?.filter((s) => {
        const modelFileType = s.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model')?.extension?.toLowerCase();
        if ((MANUAL_QUOTE_EXTENSIONS.includes(modelFileType) && !s.status) || s.status === 'manual') return s;
      })?.length ?? 0;
    },

    selectedInstantQuoteCount() {
      return this.submittableSelectedDrafts?.filter((s) => s.quote_id && !s.cart_item_id)?.length ?? 0;
    },

    isSomeSelectedQuoted() {
      return !!(this.submittableSelectedDrafts?.length && this.submittableSelectedDrafts?.some((s) => s.quote_id));
    },

    isAllInCart() {
      const hasMatchingQuoteIds = this[GET_ALL_SELECTED_PARTS]?.every((s) =>
        this[CART]?.some((c) => s.cart_item_id === c.id),
      );

      return hasMatchingQuoteIds || (this[GET_ALL_SELECTED_PARTS]?.length && this[GET_ALL_SELECTED_PARTS]?.every((s) => s.cart_item_id));
    },

    isAllAwaitingQuote() {
      return this[GET_ALL_SELECTED_PARTS]?.every((s) => s.status === 'awaiting');
    },

    isNeedsQuoteAllSelected() {
      const notSelected = this[DRAFT_RFQS]?.filter((d) => !this[GET_ALL_SELECTED_PARTS]?.find((s) => s.hash === d.hash));

      const noIssuesNotSelected = notSelected?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];
        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });

      return noIssuesNotSelected?.every((n) => n.quote_id || n.status !== 'manual');
    },

    isAddToCartAllSelected() {
      const notSelected = this[DRAFT_RFQS]?.filter((d) => !this[GET_ALL_SELECTED_PARTS]?.find((s) => s.hash === d.hash));

      return notSelected?.every((n) => !n.quote_id || n.status !== 'quoted');
    },

    canSubmitAll() {
      return !!(!this.projectContext.submitting && !!this.submittableDrafts.length)
    },

    canSubmitSomeSelected() {
      if (this.isSomeSelectedQuoted && !this.isAllInCart && this.selectedInstantQuoteCount) return true;

      return !!(!this.projectContext.submitting
        && this.selectedManualQuoteCount
        && this[GET_ALL_SELECTED_PARTS].length
        && !this.isAllAwaitingQuote
        && !this.isAllInCart);
    },

    submitBtnStyle() {
      if (this.isAllInCart) return { color: 'error', isInverted: true, label: 'Remove from cart' };
      if (this.isSomeSelectedQuoted && !this.selectedManualQuoteCount) return {
        color: 'tertiary',
        isInverted: false,
        label: `Add ${this.selectedInstantQuoteCount} part${this.selectedInstantQuoteCount === 1 ? '' : 's'} to cart`,
      };
      if (this.selectedManualQuoteCount) {
        return {
          color: 'secondary',
          isInverted: false,
          label: `Get ${this.selectedManualQuoteCount} manual quote${this.selectedManualQuoteCount === 1 ? '' : 's'}`,
        };
      }
    },

    shouldShowRequestAllManualQuotesBtn() {
      return (
        !this.isNeedsQuoteAllSelected &&
        this.canSubmitSomeSelected &&
        this.submittableDrafts.filter((d) => d.status === 'manual')?.length > 1 &&
        this.submittableSelectedDrafts?.some((d) => d.status === 'manual') &&
        this.canSubmitAll
      );
    },

    shouldShowAddAllToCartBtn() {
      return (
        !this.isAddToCartAllSelected &&
        this[GET_ALL_SELECTED_PARTS]?.length &&
        this.canSubmitSomeSelected &&
        this.submittableDrafts.filter((d) => d.quote_id && !d.cart_item_id)?.length > 1 &&
        this.submittableSelectedDrafts?.some((d) => d.quote_id && !d.cart_item_id) &&
        this.submittableSelectedDrafts.every((d) => d.status !== 'manual') &&
        this.canSubmitAll
      );
    },
  },

  methods: {
    handleSubmit() {
      if (this.isAllInCart) return this.$emit('remove-from-cart', { removeAllInCart: true });
      if (this.selectedManualQuoteCount) return this.$emit('request-quotes', true);
      if (this.isSomeSelectedQuoted) return this.$emit('add-to-cart', { addAllQuoted: false });
    },

    modelFile(part) {
      return findDraftModelFile(part);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-form-footer {
  box-shadow: 7px -1px 8px 0px #00000026;

  @media (min-width: 1280px) {
    height: 54px
  }
}
</style>
