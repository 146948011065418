import {
  DRAG_DATA, DRAG_DROP_MODULE,
  DRAG_TYPE, DRAG_TYPES,
  DRAGGING, REMOVE_DRAG_DATA,
  SET_DRAG_DATA,
} from '../store/modules/drag-drop-helper/types';

/**
 * Access the dragData stored in the state, and the ability to set or remove said data
 */
const accessDragData = {
  data() {
    return {
      DRAG_TYPES,
    };
  },
  computed: {
    _dragging() {
      return this.$store.state[DRAG_DROP_MODULE] && this.$store.state[DRAG_DROP_MODULE][DRAGGING];
    },
    _dragData() {
      return this.$store.state[DRAG_DROP_MODULE] && this.$store.state[DRAG_DROP_MODULE][DRAG_DATA];
    },
    _dragType() {
      return this.$store.state[DRAG_DROP_MODULE] && this.$store.state[DRAG_DROP_MODULE][DRAG_TYPE];
    },
  },
  methods: {
    _setDragData(data, type) {
      if (data.name === 'GEOMIQ_CYPRESS_TEST_STEP.STEP') return;
      this.$store.commit(`${DRAG_DROP_MODULE}/${SET_DRAG_DATA}`, { data, type });
    },
    _removeDragData() {
      this.$store.commit(`${DRAG_DROP_MODULE}/${REMOVE_DRAG_DATA}`);
    },
  },
};

export default accessDragData;
