<template>
  <button
    :class="{ 'can-view': canViewPart, 'is-hovered': showHoverState }"
    :disabled="!canViewPart"
    class="button-no-style"
    @click.stop="view"
  >
    <figure
      :style="imageStyle"
      class="image part-image is-flex is-align-items-center
      is-justify-content-center tw-rounded-sm"
    >
      <BIcon
        v-if="thumbnailStatus === 'exclamation'"
        icon="exclamation-circle"
      />
      <img
        v-else-if="thumbnailStatus === 'thumbnail'"
        :alt="`Image of ${part.name}`"
        :src="imagePath"
      >
      <div
        v-else-if="thumbnailStatus === 'custom-thumbnail'"
        class="custom-thumbnail"
      >
        <div class="border tw-rounded-sm">
          <p class="text">
            {{ modelFile.extension.toUpperCase() }}
          </p>
        </div>
      </div>
      <tippy
        v-else
        arrow
        boundry="viewport"
        content="Analysing file."
        offset="0, 15"
        placement="right"
      >
        <template #trigger>
          <font-awesome-icon
            spin
            icon="sync"
            class="tw-text-grey-dark tw-w-5 tw-h-5"
          />
        </template>
      </tippy>
      <div class="view-icon-wrapper">
        <BIcon
          v-show="canViewPart"
          icon="eye"
        />
      </div>
    </figure>
  </button>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { DRAFT_RFQ_ISSUES, RFQ_MODULE, SET_VIEWED } from '@/app-buyer/store/modules/rfq/types';
import { DRAFT_RFQ_MODEL_PREVIEW, PDF, RFQ_MODEL_PREVIEW } from '@/shared/consts/slugs';
import { viewPDF } from '@/app-buyer/components/project/mixins';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import { hasBeen15Mins } from '@/app-buyer/store/modules/rfq/draftIssues';
import { PENDING_REVISIONS, QUOTES_MODULE } from '@/app-buyer/store/modules/quotes/types';

export default {
  name: 'PartThumbnail',

  mixins: [viewPDF],

  props: {
    part: {
      type: Object,
      required: true,
    },

    size: {
      type: [String, Number],
      default: 53,
    },

    canView: {
      type: Boolean,
      default: true,
    },

    showHoverState: Boolean,
  },

  computed: {
    ...mapState(QUOTES_MODULE, {
      PENDING_REVISIONS,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
    }),

    thumbnailStatus() {
      if (this.modelFile?.extension.toLowerCase() === 'pdf' && !this.partThumbnail) {
        this.$emit('isAnalysing', true);
        return 'analysing';
      }

      if ((this.imagePath && !this.isBeingRequested && this.modelFile?.extension.toLowerCase() !== 'pdf') || (this.modelFile?.extension.toLowerCase() === 'pdf' && this.partThumbnail)) {
        this.$emit('isAnalysing', false);
        return 'thumbnail';
      }

      if (
        this.hasCompletedAt && !this.isBeingRequested ||
        (this.modelFile?.extension?.toLowerCase() === 'pdf' && !this.isBeingRequested && !this.partThumbnail)
      ) {
        this.$emit('isAnalysing', false);
        return 'custom-thumbnail';
      }

      if (
        this.checkFailingAfter15Mins && !this.isBeingRequested ||
        (this.hasFailedAt && !this.isBeingRequested) ||
        (this.hasError && !this.isBeingRequested) ||
        !this.modelFile && !this.part?.uploadPercent && !this.part?.updatingFile && !this.part?.awaitingDispatch && !this.part?.uploading
      ) {
        this.$emit('isAnalysing', false);
        return 'exclamation';
      }

      this.$emit('isAnalysing', true);
      return 'analysing';
    },

    modelFile() {
      return findModelFile(this.part);
    },

    partThumbnail() {
      return this.part?.uploads?.find((u) => u?.type?.slug === 'draft-rfq-model-preview');
    },

    imagePath() {
      return this.part?.uploads?.find((u) => [DRAFT_RFQ_MODEL_PREVIEW, RFQ_MODEL_PREVIEW].includes(u?.type?.slug))?.url;
    },

    imageStyle() {
      const size = typeof this.size === 'string' ? this.size : `${this.size}px`;
      return {
        width: size,
        height: size,
      };
    },
    canViewPart() {
      return ((this.imagePath && this.hasCompletedAt)
          || this.modelFile?.extension.toLowerCase() === 'pdf')
        && this.canView;
    },
    checkFailingAfter15Mins() {
      return this.modelFile?.extension?.toLowerCase() !== PDF
        && hasBeen15Mins(this.modelFile?.created_at)
        && !this.hasFailedAt
        && !this.hasCompletedAt;
    },
    hasCompletedAt() {
      return !!this.modelFile?.parser_metadata?.completed_at;
    },
    hasFailedAt() {
      return !!this.modelFile?.parser_metadata?.failed_at;
    },
    isBeingRequested() {
      return !!this[PENDING_REVISIONS]?.includes(this.part.hash);
    },
    hasError() {
      return (DRAFT_RFQ_ISSUES?.[this.part?.hash] && DRAFT_RFQ_ISSUES[this.part?.hash]?.[0]?.type === 'error');
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_VIEWED,
    }),

    updateIsSelected(isSelected) {
      this.$emit('update:is-selected', isSelected);
    },

    view() {
      if (!this.modelFile) return;
      if (this.modelFile.extension.toLowerCase() === PDF) {
        this.viewPDF(this.modelFile);
      } else {
        this[SET_VIEWED](this.part);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.part-image {
  overflow: hidden;
  background-color: rgba(224,224,224, 1);
}

.custom-thumbnail {
  font-size: 0.55rem;
  font-weight: 600;

  .border {
    align-items: center;
    border: solid black 1px;
    display: flex;
    height: 35px;
    justify-content: center;
    width: 35px;
  }
}

.can-view {
  .view-icon-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    display: flex;
    inset: 0;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: opacity .2s;
  }

  &.is-hovered .view-icon-wrapper,
  &:hover .view-icon-wrapper {
    opacity: 1;
  }
}
</style>
