<template>
  <div class="part-supporting-files-wrapper tw-mt-1">
    <div class="btn-wrapper tw-flex tw-flex-col">
      <div
        v-for="(file, index) in dynamicSupportingFiles"
        :key="file.id"
        ref="supporting-file"
        class="button-group tw-items-center tw-pr-1 tw-w-full tw-mb-1 tw-pb-1 xl:tw-mb-0 xl:tw-w-40"
      >
        <GmButton
          v-if="isDraggable && IS_DESKTOP && !part.cart_item_id"
          draggable
          size="xsmall"
          type="light"
          outlined
          class=" tw-pl-1 tw-pr-05 tw-flex tw-items-center tw-border-r-0"
          @dragstart.stop="startDrag($event, index)"
          @dragend="handleDragEnd"
          @dragexit="handleDragEnd"
        >
          <BIcon
            icon="grip-vertical"
            size="is-small"
            class="tw-text-grey-dark"
          />
        </GmButton>
        <GmButton
          :title="file.client_original_name || file.name"
          size="xsmall"
          type="light"
          outlined
          class="upload-button hidden-ghost tw-flex tw-items-center tw-justify-center tw-border-r-0"
          @click="handlePDFClick(file)"
        >
        <span
          data-testid="supporting-file-name"
          class="tw-text-primary tw-whitespace-nowrap tw-text-sm"
        >
          {{ truncatedString(file.client_original_name || file.name) }}
        </span>
          <span
            v-if="showHover"
            class="is-absolute is-flex is-align-items-center is-justify-content-center
              is-inset-0 is-block"
          >
          <BIcon
            icon="eye"
            class="tw-text-white"
          />
        </span>
        </GmButton>
        <GmButton
          v-if="!part.cart_item_id"
          size="xsmall"
          outlined
          type="light"
          class="tw-px-05 tw-flex tw-items-center"
          @click="handleRemoveSupportingFile({ file })"
        >
          <BIcon
            icon="times"
            size="is-small"
            class="tw-text-grey-dark"
          />
        </GmButton>
      </div>
      <div
        class="tw-flex tw-items-center tw-pb-1"
      >
        <g-button
          v-if="hasMoreThanSupportingFiles"
          isInverted
          htmlType="a"
          :label="showMoreBtnText"
          :iconLeft="showAllSupportingFiles ? 'minus' : 'plus'"
          type="primary-icon"
          color="grey-dark"
          fontWeight="normal"
          size="sm"
          class="admin-style tw-whitespace-nowrap tw-mr-1"
          @click="showAllSupportingFiles = !showAllSupportingFiles"
        />
        <button
          class="tw-text-tertiary tw-mt-1 tw-text-sm tw-font-medium"
          @click="$emit('add-more-files')"
          :disabled="part.cart_item_id"
        >
          <font-awesome-icon
            icon="plus"
            class="tw-mr-1"
          />
          <span class="tw-text-tertiary">Add PDF file</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  REMOVE_SUPPORTING_FILE,
  REVISE_REQUOTE_CONFIG,
  RFQ_MODULE,
  SET_REVISE_REQUOTE_CONFIG,
} from '@/app-buyer/store/modules/rfq/types';
import {
  PROJECT_MODULE,
  SET_PROJECT_SUPPORTING_FILE_MODAL,
} from '@/app-buyer/store/modules/projects/types';
import { viewPDF } from '@/app-buyer/components/project/mixins';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import GButton from '@common/components/storied/atoms/GButton.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { truncateStringInMiddle } from '@common/helpers/index';

export default {
  name: 'PartSupportingFiles',

  components: {
    GButton,
  },

  mixins: [viewPDF, accessDragData],

  props: {
    part: {
      type: Object,
      required: true,
    },

    supportingFiles: {
      type: Array,
      default: () => [],
    },

    isDraggable: {
      type: Boolean,
      default: true,
    },
    showHover: {
      type: Boolean,
      default: true,
    },
    isProjectPage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showAllSupportingFiles: false,
      numberOfSupportingFilesToShow: 3,
    }
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      REVISE_REQUOTE_CONFIG,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    hasMoreThanSupportingFiles() {
      return this.supportingFiles?.length > this.numberOfSupportingFilesToShow;
    },

    showMoreBtnText() {
      if (!this.supportingFiles?.length) return '';

      if (this.showAllSupportingFiles) return 'Show less';

      return `${this.supportingFiles?.length - this.numberOfSupportingFilesToShow} file${this.numberOfSupportingFilesToShow > 1 ? 's' : ''}`;
    },

    dynamicSupportingFiles() {
      if (this.showAllSupportingFiles || !this.hasMoreThanSupportingFiles || !this[IS_DESKTOP]) return this.supportingFiles;

      return this.supportingFiles.slice(0, this.numberOfSupportingFilesToShow);
    },
  },

  methods: {
    ...mapActions(RFQ_MODULE, {
      REMOVE_SUPPORTING_FILE,
    }),
    ...mapMutations(RFQ_MODULE, {
      SET_REVISE_REQUOTE_CONFIG,
    }),
    ...mapMutations(PROJECT_MODULE, {
      SET_PROJECT_SUPPORTING_FILE_MODAL,
    }),

    handleRemoveSupportingFile({ file }) {
      if (this.isProjectPage) this[REMOVE_SUPPORTING_FILE]({ part: this.part, file });
      else {
        const config = this[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === this.part.hash);
        config[0].files.supportingFiles = config[0]?.files?.supportingFiles?.filter((sF) => sF.name !== file.name);

        if (!config[0]?.files?.supportingFiles?.length) {
          delete config[0].files.supportingFiles;
        }

        this[SET_REVISE_REQUOTE_CONFIG](config[0]);
      }
    },

    startDrag(event, index) {
      const file = this.supportingFiles[index];
      if (!file) return;
      const el = this.$refs['supporting-file'][index];
      const ghost = el.cloneNode(true);
      ghost.style.position = 'fixed';
      document.body.appendChild(ghost);
      event.dataTransfer.setDragImage(ghost, 0, 0);
      this.$nextTick(() => {
        this.handleDragStart(file);
      });
    },

    handleDragStart(file) {
      this._setDragData({
        file,
        ownerHash: this.part.hash,
      }, DRAFT_RFQ_SUPPORTING_FILE);
    },

    handleDragEnd() {
      this._removeDragData();
      this.dragoverFile = null;
    },

    handlePDFClick(file) {
      this[SET_PROJECT_SUPPORTING_FILE_MODAL](file);
      this.$emit('shift-select');
    },

    truncatedString(str) {
      const maxStringLength = this[IS_DESKTOP] ? 19 : 24;
      return truncateStringInMiddle({ str, maxLength: maxStringLength });
    },
  },
};
</script>
<style
  scoped
  lang="scss"
>
.part-supporting-files-wrapper {
  grid-template-columns: 25% 75%;

  @media (min-width: 425px) {
    grid-template-columns: 20% 80%;
  }

  ::v-deep {
    a {
      &.admin-style {
        border-color: theme('colors.grey-light') !important;
        background-color: theme('colors.white');
      }

      &.admin-style {
        span {
          color: theme('colors.primary');
        }
      }
    }
  }
}
</style>
