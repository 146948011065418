import ConfirmConfigurationForm
  from '@/app-buyer/components/project/ConfirmConfigurationForm.vue';

const confirmConfigurationPrompt = {
  methods: {
    confirmConfiguration(selected = false) {
      return new Promise((resolve, reject) => {
        this.$buefy.modal.open({
          parent: this,
          component: ConfirmConfigurationForm,
          props: {
            selected,
          },
          events: {
            submit: () => {
              resolve({ selected });
            },
          },
          onCancel: () => {
            reject();
          },
          width: '600px',
          hasModalCard: true,
          trapFocus: true,
        });
      });
    },
  },
};

export default confirmConfigurationPrompt;
