<template>
  <b-collapse
    :open="isOpen"
    animation="slide"
  >
    <template v-slot:trigger="{ open: openLower }">
      <button
        ref="trigger-button"
        :style="{paddingLeft: `${level * 0.5}rem`}"
        class="button is-small tw-w-full is-result is-dropdown-trigger"
      >
        <p
          class="title tw-text-sm tw-ml-2"
        >
          {{ title }}
          <font-awesome-icon :icon="openLower ? 'chevron-up' : 'chevron-down'" />
        </p>
      </button>
    </template>
    <template v-for="(lowerData, key) in data">
      <multi-layer-collapsable-input
        v-if="key !== 'options'"
        :key="key"
        :data="lowerData"
        :values="values"
        :open="open"
        :title="key"
        :level="level + 1"
        @set-note="$emit('set-note', $event)"
        @has-value="containsValue = true"
        @input="$emit('input', $event)"
      />
    </template>
    <template v-if="data.options && data.options.length">
      <template v-for="option in data.options">
        <template v-if="option.metadata && option.metadata.requires_notes">
          <b-field
            :key="option.id"
            class="mx-2 mb-2"
            custom-class="tw-text-sm"
            :label="option.string_value"
          >
            <b-input
              v-model="customNote"
              expanded
              size="is-small"
              placeholder="Please specify the material"
            />
            <b-button
              :disabled="!customNote"
              size="is-small"
              @click="update(option, true)"
            >
              Save
            </b-button>
          </b-field>
        </template>
        <button
          v-else
          :key="option.id"
          ref="options"
          class="button is-small is-fullwidth is-result"
          style="border-radius: 0 !important;
        border-left: none; border-right: none; border-bottom: none;"
          :style="level && {paddingLeft: `${level}rem`}"
          :class="{'is-info': values && values.includes(option.id)}"
          @click="update(option)"
        >
          <p class="tw-text-sm">
            {{ option.string_value }}
          </p>
        </button>
      </template>
    </template>
  </b-collapse>
</template>

<script>
import customNotes from '@/app-buyer/mixins/custom_notes';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'MultiLayerCollapsableInput',
  components: {
    FontAwesomeIcon,
  },
  mixins: [customNotes],
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: () => 0,
    },
    values: {
      type: Array,
      default: () => [],
    },
    open: Boolean,
    openTopLevel: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      containsValue: false,
      hasValue: false,
    };
  },
  computed: {
    isOpen() {
      return this.open || (this.openTopLevel && this.level === 0) || this.containsValue;
    },
  },
  watch: {
    hasValue: {
      handler(val) {
        if (val && this._singleViewedModel && this._singleViewedModel.hash) {
          this.getNote('material');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const idx = this.data?.options?.findIndex((e) => this.values.includes(e.id));
    const containsValue = idx > -1;
    if (containsValue) {
      this.containsValue = true;
      this.hasValue = true;
      this.$emit('has-value');
      this.$nextTick(() => {
        if (this.$refs.options[idx]) {
          this.$refs.options[idx].scrollIntoView();
        }
      });
    }
  },
  methods: {
    update(material, withNotes) {
      this.$emit('set-note', (withNotes ? `${material.string_value}: ${this.customNote}` : null));
      this.$nextTick(() => {
        this.$emit('input', material.id);
      });
    },
  },
};
</script>

<style scoped>

</style>
