import { REFERENCE_DATA, THICKNESS_COMPONENT_REF } from './types';
import { LOADING } from '@/app-buyer/store/modules/rfq/types';

export default function getState() {
  return {
    [REFERENCE_DATA]: null,
    [LOADING]: false,
    [THICKNESS_COMPONENT_REF]: null,
  };
}
