<template>
  <div class="part-row-info-wrapper tw-relative tw-grid tw-mb-3 tw-pr-4 tw-pl-2">
    <slot />
    <div class="grid-layout tw-grid tw-items-start xl:tw-flex">
      <part-handler
        :is-selected="isSelected"
        :part="part"
        @isAnalysing="handleIsAnalysing"
        @update:is-selected="$emit('update:is-selected', $event)"
      />
      <div class="tw-ml-3 tw-w-full">
        <div
          v-if="shouldShowQNumber || isInstantQuoted || isAnalysing || isUploading || hasFailed || hasErrors"
          class="tw-flex tw-items-center tw-pb-1"
        >
          <template v-if="shouldShowQNumber">
            <div class="tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-py-05 tw-px-1 tw-bg-grey-lighter tw-text-grey-darkest tw-whitespace-nowrap tw-mr-1 xl:tw-text-sm">
              <p>{{ part.quote_rfq_ref }}</p>
            </div>
          </template>
          <template v-if="hasFailed">
            <div class="tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-px-1 tw-py-05 tw-bg-error-light tw-text-error tw-whitespace-nowrap">
              <font-awesome-icon
                icon="exclamation-circle"
                class="tw-mr-1"
              />
              <p class="tw-leading-13">Error</p>
            </div>
          </template>
          <template v-else-if="isInstantQuoted && !hasErrors">
            <div
              class="tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-py-05 tw-px-1 tw-bg-success-light tw-text-success tw-whitespace-nowrap"
              :class="{ 'tw-mr-1' : isAnalysing || isUploading }"
            >
              <p>Auto quoted</p>
            </div>
          </template>
          <template v-if="isAnalysing || isUploading">
            <div class="tw-inline-flex tw-items-center tw-text-sm tw-font-bold tw-rounded-xs tw-py-05 tw-px-1 tw-bg-grey-lighter tw-text-grey-dark tw-whitespace-nowrap xl:tw-text-sm">
              <font-awesome-icon
                icon="sync"
                spin
                class="tw-mr-1"
              />
              <p>{{ isUploading ? 'Uploading' : 'Analysing' }} file</p>
            </div>
          </template>
        </div>
        <tippy
          :enabled="isPartNameTruncated"
          arrow
        >
          <template #trigger>
            <p
              class="part-name text-field tw-font-bold tw-break-all tw-mr-3 tw-text-md xl:tw-text-sm xl:tw-overflow-ellipsis xl:tw-overflow-hidden xl:tw-whitespace-nowrap"
              :class="{ 'reduced-width' : isAnalysing || isUploading || hasFailed || isInstantQuoted }"
              :data-testid="partName"
            >{{ cleanPartName }}</p>
          </template>
          <div id="buyer-app">
            <div class="tw-text-left tw-p-1 tw-text-sm">
              <p>{{ partName }}</p>
            </div>
          </div>
        </tippy>
        <div class="tw-flex tw-text-sm">
          <p class="tw-mr-1">Service:</p>
          <p>{{ service || '&nbsp' }}</p>
          <span
            v-if="technology"
            class="tw-mx-1"
          >|</span>
          <p v-if="technology">{{ technology }}</p>
        </div>
        <div class="tw-flex tw-text-sm">
          <p class="tw-mr-1">Material:</p>
          <p>{{ material || '&nbsp' }}
            <span v-if="partErrors?.some((e) => e.errorType === 'material-error')">
              <font-awesome-icon
                icon="exclamation-circle"
                class="tw-ml-05 tw-text-pending"
              />
            </span>
          </p>
        </div>
        <div class="tw-flex tw-text-sm">
          <p class="tw-mr-1">Shipping in:</p>
          <p>
            {{ leadTime }}
            <template v-if="hasQuoteDelay">
              <tippy
                arrow
                interactive
                class="tw-inline-flex"
              >
                <template #trigger>
                  <button>
                    <font-awesome-icon
                      icon="info-circle"
                      class="tw-text-tertiary"
                    />
                  </button>
                </template>
                <div id="buyer-app">
                  <div class="tw-text-left tw-p-1 tw-text-sm">
                    <p class="">Fastest lead time at this price. Need a faster lead time?</p>
                    <g-button
                      :hasBorder="false"
                      label="Speak to advisor"
                      type="underline"
                      color="white"
                      size="sm"
                      fontWeight="normal"
                      @click="handleOpenIntercomLeadTime"
                    />
                  </div>
                </div>
              </tippy>
            </template>
          </p>
        </div>

        <div
          v-if="!IS_DESKTOP"
          class="tw-mr-4 tw-text-sm tw-mt-2"
        >
          <part-table-group-row-quantity
            :part="part"
            :hide-another-qty="true"
            :is-read-only="isReadOnly"
          />
          <part-supporting
            :part="part"
            ref="partSupporting"
            class="tw-mt-3"
          />

          <div
            v-if="isCalculatingNewPrice && isModelFileTypeInstantQuotable"
            class="tw-mt-3"
          >
            <font-awesome-icon
              spin
              icon="spinner"
              class="tw-text-grey-dark tw-text-md"
            />
          </div>
          <div
            v-else-if="part.quote_price"
            class="tw-flex tw-flex-col tw-mt-3"
          >
            <p class="tw-text-md">Price per unit:</p>
            <p class="tw-font-bold tw-text-lg">{{ part?.quote_unit_price | formatWithCurrency }}</p>
            <template v-if="part.quote_tool_cost">
              <p class="tw-text-sm tw-mt-2 xl:tw-text-right">Tool cost:</p>
              <p class="tw-font-bold tw-text-sm xl:tw-text-right">{{
                  part?.quote_tool_cost | formatWithCurrency
                }}</p>
            </template>
            <p class="tw-text-sm tw-mt-2">Subtotal:</p>
            <p class="tw-font-bold tw-text-sm">{{
                part?.quote_price | formatWithCurrency
              }}</p>
          </div>
          <div
            v-else-if="modelFile?.extension?.toLowerCase() === 'pdf' && !part.quote_status"
            class="tw-flex tw-flex-col tw-mt-3 tw-text-md"
          >
            <p class="tw-text-sm">Subtotal</p>
            <p class="quote-status-field tw-font-bold tw-text-md">Manual quote needed</p>
          </div>
          <div
            v-else-if="!isAnalysing && (!part.quote_price && part?.quote_status)"
            class="tw-flex tw-flex-col tw-mt-3 tw-text-md"
          >
            <p class="tw-text-sm">Subtotal</p>
            <p class="quote-status-field tw-font-bold tw-text-md">{{ part.quote_status }}</p>
          </div>
          <div
            v-else-if="!part?.quote_status"
            class="tw-flex tw-mt-3 tw-text-sm"
          >
            <font-awesome-icon
              spin
              icon="spinner"
              class="tw-text-grey-dark tw-text-md"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-if="IS_DESKTOP">

      <!-- Supporting files -->
      <part-supporting
        :part="part"
        ref="partSupporting"
        @duplicate-with-file="handleDuplicateWithFile"
      />

      <part-table-group-row-quantity
        :key="part.quantity_initial"
        :part="part"
        :hide-another-qty="true"
        :is-read-only="isReadOnly"
      />

      <div
        v-if="isPricing || isAnalysing || isUploading || isLongAnalysis"
        class="tw-flex tw-justify-end"
      >
        <span class="quote-status-field tw-text-sm tw-text-right tw-text-grey-darker tw-mr-2">
          {{ partLoadingString }}
        </span>
        <font-awesome-icon
          spin
          icon="spinner"
          class="tw-text-grey-darkest tw-text-md"
        />
      </div>
      <div
        v-else-if="hasErrors"
        class="tw-flex tw-justify-end tw-text-md"
      >
        <p class="quote-status-field tw-text-sm tw-text-right">Unable to quote</p>
      </div>
      <div
        v-else-if="part.quote_price"
        class="tw-flex tw-flex-col"
      >
        <p class="tw-text-right tw-text-sm">Price per unit:</p>
        <p class="tw-text-right tw-font-bold tw-text-lg">
          {{ part?.quote_unit_price | formatWithCurrency }}</p>
        <template v-if="part.quote_tool_cost">
          <p class="tw-text-right tw-text-sm tw-mt-2">Tool cost:</p>
          <p class="tw-text-right tw-font-bold tw-text-sm">{{
              part?.quote_tool_cost | formatWithCurrency
            }}</p>
        </template>
        <p class="tw-text-right tw-text-sm tw-mt-2">Subtotal:</p>
        <p class="tw-text-right tw-font-bold tw-text-sm">{{
            part?.quote_price | formatWithCurrency
          }}</p>
      </div>
      <div
        v-else-if="part.status === 'manual'"
        class="tw-flex tw-justify-end tw-text-md"
      >
        <p class="quote-status-field tw-text-sm tw-text-right">Manual quote needed</p>
      </div>
      <div
        v-else-if="!part.quote_price && part?.quote_status"
        class="tw-flex tw-justify-end tw-text-md"
      >
        <p class="quote-status-field tw-text-sm tw-text-right">{{ part.quote_status }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import PartHandler from '@/app-buyer/components/project/PartHandler.vue';
import DfmModal from '@/app-buyer/components/project/DfmModal.vue';
import PartTableGroupRowQuantity
  from '@/app-buyer/components/project/PartTableGroupRowQuantity.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import { RFQ_MODULE, SELECTED_PART_HASHES } from '@/app-buyer/store/modules/rfq/types';
import { findDraftModelFile } from '@/app-buyer/components/project/helpers';
import leadTimeCalculator from '../../mixins/lead-time-calculator';
import { DRAFT_RFQ_SUPPORTING_FILE, RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import GButton from '@common/components/storied/atoms/GButton.vue';
import PartSupporting from '@/app-buyer/components/project/PartSupporting.vue';
import { truncateStringInMiddle } from '@common/helpers/index';
import GroupedQuotesCartButtons
  from '@/app-buyer/components/grouped-quotes/GroupedQuotesCartButtons.vue';
import { MANUAL_QUOTE_EXTENSIONS } from '@/app-buyer/consts/common';

export default {
  name: 'PartTableGroupRowInfo',

  components: {
    GroupedQuotesCartButtons,
    PartHandler,
    DfmModal,
    PartTableGroupRowQuantity,
    PartSupporting,
    FontAwesomeIcon,
    GButton,
  },

  props: {
    part: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
    hasParsed: {
      type: Boolean,
      default: false,
    },
    hasFailed: {
      type: Boolean,
      default: false,
    },
    isAnalysing: {
      type: Boolean,
      default: false,
    },
    isLongAnalysis: {
      type: Boolean,
      default: false,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      default: false,
    },
    partErrors: {
      type: Array,
      default: () => [],
    },
    issueMessages: {
      type: Array,
      default: () => [],
    },
    isModelFileTypeInstantQuotable: {
      type: Boolean,
      default: false,
    },
    isPricing: {
      type: Boolean,
      default: false,
    },
    isCalculatingNewPrice: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    partSelectMixin,
    leadTimeCalculator,
  ],

  data() {
    return {
      analysing: false,
      manualQuoteFileExtensions: MANUAL_QUOTE_EXTENSIONS,
    }
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    ...mapState(RFQ_MODULE, {
      SELECTED_PART_HASHES,
    }),

    shouldShowQNumber() {
      return !!(this.part.quote_rfq_ref && this.part.status !== 'manual' && !this.hasErrors);
    },

    modelFile() {
      return findDraftModelFile(this.part);
    },

    quantity() {
      return this.part.quantity_initial;
    },

    partLoadingString() {
      if (this.isUploading) return 'Uploading file';
      if (this.isAnalysing) return 'Analysing file';

      return 'Pricing part';
    },

    partName() {
      return this.part?.name;
    },

    isInstantQuoted() {
      return this.part.quote_rfq_type === 'instant' && this.part?.quote_id;
    },

    service() {
      return this.part?.configuration_object?.service?.string_value;
    },

    technology() {
      return this.part?.configuration_object?.technology?.string_value;
    },

    material() {
      return this.part?.configuration_object?.material?.string_value;
    },

    leadTime() {
      if (!this.part?.lead_time_days?.[this.part.lead_time_speed]) return '';

      const leadTimeNumber = this.part?.lead_time_days?.[this.part.lead_time_speed];

      return `${leadTimeNumber + (this.part.quote_delay || 0)} business days`;
    },

    hasQuoteDelay() {
      return !!this.part.quote_delay;
    },

    supportingFiles() {
      return this.part?.uploads?.filter((u) => [DRAFT_RFQ_SUPPORTING_FILE, RFQ_SUPPORTING_FILE].includes(u?.type?.slug));
    },

    isReadOnly() {
      return !!this.part.cart_item_id;
    },

    partNameMaxWidth() {
      if ((this.isAnalysing || this.isUploading || this.hasFailed || this.isInstantQuoted) && window.innerWidth >= 1280) return 20;

      return 32;
    },

    cleanPartName() {
      return this.truncatedPartName({ maxLength: this.partNameMaxWidth });
    },

    isPartNameTruncated() {
      return this.partName.length > this.partNameMaxWidth;
    },
  },

  methods: {
    handleIsAnalysing(e) {
      this.analysing = e;
    },

    truncatedPartName({ maxLength }) {
      return truncateStringInMiddle({ str: this.partName, maxLength });
    },

    handleOpenIntercomLeadTime() {
      Intercom('trackEvent', 'QuoteDelay-FasterLeadTime');
    },

    handleDuplicateWithFile(event) {
      this.$emit('duplicate-with-file', event)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.part-row-info-wrapper {

  @media (min-width: 1280px) {
    grid-template-columns: 48% 23% 15% 14%;
  }
}

.grid-layout {
  grid-template-columns: 25% 75%;

  @media (min-width: 425px) {
    grid-template-columns: 20% 80%;
  }
}

.quote-status-field {
  @media (min-width: 1280px) {
    width: theme('spacing.20');
  }
}

@screen xl {
  .part-name {
    max-width: 18rem;

    &.reduced-width {
      max-width: theme('spacing.40');
    }
  }
}

</style>
