<template>
  <div class="tw-flex tw-h-10 tw-bg-grey-lighter tw-sticky tw-z-10 tw-top-0 xl:tw-justify-between xl:tw-pl-2">
    <div class="tw-flex tw-items-center">
      <div
        class="tw-flex tw-items-center xl:tw-mr-3"
        @mouseenter="handleHover({ isHovered: true, refName: 'checkboxSelectAll' })"
        @mouseleave="handleHover({ isHovered: false, refName: 'checkboxSelectAll' })"
      >
        <BCheckbox
          ref="checkboxSelectAll"
          v-model="areAllSelected"
          class="tw-flex tw-items-center"
          size="is-small"
          type="is-info"
        >
          <span class="tw-text-sm">Select all files</span>
        </BCheckbox>
      </div>

      <span v-if="!IS_DESKTOP" class="tw-mr-2 tw-text-grey-light">|</span>

      <part-actions />

      <div
        v-if="IS_DESKTOP"
        class="tw-flex tw-items-center tw-ml-2"
      >
        <file-upload
          :accept="acceptedFiles"
          multiple
          @upload="handleAttemptCreateQuotes($event)"
        >
          <g-button
            isInverted
            htmlType="a"
            label="Upload new part"
            iconLeft="cloud-upload-alt"
            nativeType="button"
            type="primary-icon"
            color="grey-dark"
            fontWeight="normal"
            size="sm"
            class="admin-style tw-whitespace-nowrap"
          />
        </file-upload>
      </div>
    </div>

    <div class="tw-flex tw-ml-2 tw-items-center xl:tw-ml-0">
      <div
        v-if="IS_DESKTOP"
        class="tw-flex tw-items-center"
        @mouseenter="handleHover({ isHovered: true, refName: 'checkboxShowQuoted' })"
        @mouseleave="handleHover({ isHovered: false, refName: 'checkboxShowQuoted' })"
      >
        <BCheckbox
          ref="checkboxShowQuoted"
          v-model="showQuoted"
          class="tw-mr-3"
          size="is-small"
          type="is-info"
        >
          <span class="tw-text-sm">Show quoted only</span>
        </BCheckbox>
      </div>

      <g-button
        v-if="!isAllInCart && submittableDrafts?.length"
        :isDisabled="!hasQuotes || !submittableDrafts?.length"
        label="Add all to cart"
        iconLeft="shopping-cart"
        type="primary-icon"
        color="tertiary"
        size="sm"
        @click="$emit('add-to-cart', { addAllQuoted: true })"
      />

      <g-button
        v-else-if="isAllInCart && submittableDrafts?.length && hasQuotes"
        isInverted
        label="Remove all from cart"
        iconLeft="trash"
        type="primary-icon"
        color="error"
        size="sm"
        @click="$emit('remove-from-cart', { removeAllInCart: true })"
      />

      <g-dropdown
        v-if="!IS_DESKTOP"
        isInverted
        hasBorder
        alignRight
        iconRight="ellipsis-v"
        color="grey-darkest"
        type="icon"
        size="sm"
        class="admin-dropdown tw-ml-2"
      >
        <div class="dropdown-content tw-bg-white tw-p-4 tw-rounded-xs">
          <p class="tw-font-bold tw-text-sm">More options</p>
          <BCheckbox
            ref="checkboxShowQuoted"
            v-model="showQuoted"
            class="tw-mt-2"
            size="is-small"
            type="is-info"
          >
            <span class="tw-text-sm">Show quoted only</span>
          </BCheckbox>
        </div>
      </g-dropdown>
    </div>

    <g-modal
      :is-active.sync="isPdfModalOpen"
      :canCancel="false"
      width="1000"
      modalWidth="tw-w-full"
      modalHeight="tw-h-full"
    >
      <div class="tw-px-6 tw-py-5 tw-bg-white tw-rounded-xs">
        <div class="tw-flex tw-items-center">
          <font-awesome-icon icon="exclamation-circle" class="tw-text-pending tw-mr-1 tw-text-lg" />
          <p class="tw-font-bold tw-text-lg">Upload {{ hasMultiplePdfs ? 'CAD files' : 'a CAD file' }} instead of {{ hasMultiplePdfs ? 'PDFs' : 'a PDF' }} for a quicker, more competitive quote</p>
        </div>
        <div class="tw-flex tw-items-center tw-justify-end tw-mt-6">
          <g-button
            isInverted
            :label="`Continue with PDF${hasMultiplePdfs ? 's' : ''}`"
            color="tertiary"
            class="tw-mr-3"
            @click="handleCreateQuotes(files)"
          />
          <g-button
            :label="`Upload ${hasMultiplePdfs ? 'CAD files' : 'a CAD file'}`"
            @click="handleUploadCAD"
          />
        </div>
      </div>
    </g-modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  DRAFT_RFQ_ISSUES,
  DRAFT_RFQS,
  RFQ_MODULE,
  SELECTED_PART_HASHES,
} from '@/app-buyer/store/modules/rfq/types';
import PartActions from '@/app-buyer/components/project/PartActions.vue';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';
import GButton from '@common/components/storied/atoms/GButton.vue';
import GDropdown from '@common/components/storied/molecules/GDropdown.vue';
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import { partSelectMixin } from '@/app-buyer/components/project/mixins';
import checkPdfUpload from '@/app-buyer/mixins/check-pdf-upload';
import GModal from '@/app-buyer/components/GModal.vue';

export default {
  name: 'PartTableHead',

  components: {
    GModal,
    PartActions,
    GButton,
    GDropdown,
    FileUpload,
  },

  mixins: [
    partSelectMixin,
    checkPdfUpload,
  ],

  data() {
    return {
      showQuoted: false,
    }
  },

  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      SELECTED_PART_HASHES,
    }),

    ...mapGetters(RFQ_MODULE, {
      DRAFT_RFQ_ISSUES,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    submittableDrafts() {
      return this[DRAFT_RFQS]?.filter((d) => {
        const issues = this[DRAFT_RFQ_ISSUES]?.[d.hash];

        if (!issues || (issues && !Object.keys(issues)?.length)) return d;
      });
    },

    hasQuotes() {
      return this[DRAFT_RFQS].some((rfq) => rfq.quote_id);
    },

    isAllInCart() {
      const partsWithQuotes = this.submittableDrafts?.filter((d) => d.quote_id);
      return partsWithQuotes?.every((d) => d.cart_item_id);
    },

    allPartsSelected() {
      return this.selectedParts?.length === this[DRAFT_RFQS]?.length;
    },

    selectPartsBtnText() {
      return this.allPartsSelected ? `Deselect all parts (${this.selectedParts?.length})` : `Select all parts (${this[DRAFT_RFQS]?.length})`;
    },

    selectedParts() {
      return this[DRAFT_RFQS].filter((part) => this[SELECTED_PART_HASHES].includes(part.hash));
    },

    areAllSelected: {
      get() {
        return this[DRAFT_RFQS].every((part) => this[SELECTED_PART_HASHES].includes(part.hash));
      },
      set(value) {
        if (value) this.selectParts(...this[DRAFT_RFQS].map((part) => part.hash));
        else this.selectParts(...[]);
      },
    },
  },

  watch: {
    showQuoted(val) {
      this.$emit('update:showQuoted', val);
    },
  },

  methods: {
    handleSelectParts() {
      if (this.allPartsSelected) {
        this.selectParts(...[]);
      } else {
        this.selectParts(...this[DRAFT_RFQS].map((part) => part.hash));
      }
    },

    handleHover({ isHovered, refName }) {
      let check = this.$refs[refName].$el.getElementsByClassName('check');
      check = [...check];

      check[0].style.borderColor = isHovered ? '#338dbc' : '';
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  a {
    &.admin-style {
      border-color: theme('colors.grey-light') !important;
      background-color: theme('colors.white');
    }

    &.admin-style {
      span {
        color: theme('colors.primary');
      }
    }
  }
}

.admin-dropdown {
  ::v-deep {
    button {
      border-color: theme('colors.grey-light') !important;
      background-color: theme('colors.white') !important;
    }

    span {
      color: theme('colors.primary');
    }
  }

  .dropdown-content {
    width: 50vw;
    box-shadow: 0px 4px 4px 0px #00000026;
  }
}
</style>
