<template>
  <div class="tw-inline-flex">
    <g-button
      :label="btnText"
      type="underline"
      color="tertiary"
      fontWeight="normal"
      size="sm"
      @click="handleOpenOtherThicknessModal"
    />
    <g-modal
      :is-active.sync="isOpen"
      :can-cancel="['escape', 'outside', 'button']"
      :modal-width="modalWidth"
      modal-height="80vh"
    >
      <div class="tw-py-5 tw-bg-white tw-overflow-y-auto tw-rounded-xs tw-px-4 xl:tw-px-6 xl:tw-w-9/12 xl:tw-w-full tw-h-full">
        <div class="tw-flex tw-items-center tw-justify-between tw-pb-3">
          <p class="tw-font-bold tw-text-xl">Available thicknesses and materials</p>
          <button
            class="tw-text-black tw-text-lg"
            @click="isOpen = false"
          >
            <font-awesome-icon icon="times" />
          </button>
        </div>
        <thickness-material-content />
      </div>
    </g-modal>
  </div>
</template>

<script>
import GModal from '@/app-buyer/components/GModal';
import GButton from '@common/components/storied/atoms/GButton.vue';
import ThicknessMaterialContent from '@/app-buyer/components/project/ThicknessMaterialContent.vue';
import { mapState } from 'vuex';
import { IS_DESKTOP, NAVIGATION_MODULE } from '@/app-buyer/store/modules/navigation/types';

export default {
  name: 'ThicknessMaterialModal',

  components: {
    GModal,
    GButton,
    ThicknessMaterialContent,
  },

  props: {
    btnText: {
      type: String,
      default: 'View instant quotable thicknesses and materials',
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
    }),

    modalWidth() {
      if (!this[IS_DESKTOP]) return '90vw';
      return '60vw';
    },
  },

  methods: {
    handleOpenOtherThicknessModal() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>


<style
  scoped
  lang="scss"
>
</style>
