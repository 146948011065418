/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';

import { partOrder, PDF } from '@/shared/consts/slugs';
import getState from './state';
import { DELETE, PAGINATION, RESET_STATE, SET, SET_ALL } from '../types';
import { mixedSort, numericSort } from '../../../mixins/sorting';
import { findModelFile } from '@/app-buyer/components/project/helpers';
import {
  ADD_TO_DRAFTS,
  ADD_UPLOAD_PROGRESS,
  ALL_RFQS,
  DRAFT_COUNT,
  DRAFT_RFQS,
  FILTERED_PARTS,
  FORM_ERRORS,
  INSTANT_QUOTE_RFQ_IDS,
  LOADING,
  PRESETS,
  PRODUCTION_REQUIREMENTS_MODAL,
  PROGRESSES,
  REVISE_REQUOTE_CONFIG,
  REVISE_REQUOTE_MODAL_RFQS,
  RFQ_UPDATE_HASHES,
  RFQS,
  SELECTED_PART_HASHES,
  SET_DRAFT_COUNT,
  SET_FILTERED,
  SET_FORM_ERRORS,
  SET_INSTANT_QUOTE,
  SET_INSTANT_QUOTE_RFQ_IDS,
  SET_LOADING,
  SET_PRESETS,
  SET_PRODUCTION_REQUIREMENTS_MODAL,
  SET_PROGRESS,
  SET_PROPERTY,
  SET_REVISE_REQUOTE_CONFIG,
  SET_REVISE_REQUOTE_MODAL_RFQS,
  SET_RFQ_UPDATE_HASHES,
  SET_SELECTED_PART_HASHES,
  SET_SORT_BY,
  SET_UPLOADED_DRAFTS,
  SET_VIEWED,
  SORT_BY,
  SORT_PARTS,
  SWAP_UPLOADING,
  UPDATE_DRAFT,
  UPDATE_RFQ,
  UPDATE_UPLOAD_PROGRESS,
  UPLOAD_PROGRESSES,
  UPLOADED_DRAFTS,
  VIEWED,
  SET_UPDATING_PROPERTIES,
  UPDATING_PROPERTIES,
  SET_PREVIOUS_QUOTES,
  PREVIOUS_QUOTES,
} from '@/app-buyer/store/modules/rfq/types';

const findPreset = (draft, presets) => presets.forEach((preset) => {
  let arr = [];
  const service = preset.configuration_properties.find((e) => e.entity_slug === 'service');
  const technology = preset.configuration_properties.find((e) => e.entity_slug === 'technology');
  if (service.id === draft.configuration.service) {
    if (draft.configuration.technology
      && technology
      && draft.configuration.technology === technology.id) {
      arr = [...arr, preset];
    }
    arr = [...arr, preset];
  }
  return arr;
});

const safeProperties = ['quantity_initial', 'quantity_production', 'file-type', 'material', 'hrc'];
const carryOver = ['hrc'];

const cleanConfiguration = (draft, presets) => {
  const presetProperties = Array.from(presets.reduce((res, curr) => {
    curr.configuration_properties.forEach((prop) => res.add(prop.entity_slug));
    return res;
  }, new Set()));
  const found = findPreset(draft, presets);
  if (!found) return draft;

  const keep = [...safeProperties, ...found.configuration_properties.map((e) => e.entity_slug)];
  const toClear = presetProperties.filter((p) => !keep.includes(p));

  // eslint-disable-next-line camelcase
  const {
    configuration,
    configuration_object,
  } = Object.entries(draft.configuration_object).reduce((res, [key, value]) => {
    if (!toClear.includes(key)) {
      return {
        configuration: {
          ...res.configuration,
          [key]: carryOver ? draft.configuration[key] : value.id,
        },
        configuration_object: {
          ...res.configuration_object,
          [key]: value,
        },
      };
    }
    return res;
  }, { configuration: {}, configuration_object: {} });

  return {
    ...draft,
    configuration,
    configuration_object,
  };
};

export default {
  /**
   * Sets the rfqs and draft-rfqs visible in the rfq-form
   * @param state
   * @param {Object} payload
   * @param {Array} payload.rfqs      Array of rfqs to be set
   * @param {Array} payload.draftRfqs Array of draft-rfqs to be set
   * @param {boolean} payload.clear     Clears the stored rfqs and draft-rfqs from the state
   * @param {boolean} payload.merge     Merges existing rfqs, leaves out rest
   * */
  [SET](state, {
    rfqs = [], draftRfqs = [], clear,
  }) {
    if (draftRfqs.length > 1) {
      draftRfqs.sort((a, b) => partOrder.indexOf(a.configuration_object?.['file-type']?.slug) - partOrder.indexOf(b.configuration_object?.['file-type']?.slug));
    }
    const attachmentRequiredDrafts = state[DRAFT_RFQS].reduce((res, cur) => ({
      ...res,
      [cur.hash]: cur.__attachment_needed,
    }), {});
    if (clear) {
      state[RFQS].splice(0, state[RFQS].length);
      state[DRAFT_RFQS].splice(0, state[DRAFT_RFQS].length);
    }
    if (!state[RFQS].some((e) => e.configuring) && !state[DRAFT_RFQS].some((e) => e.configuring)) {
      if (draftRfqs.length) {
        draftRfqs[0].configuring = true;
      } else if (rfqs.length) {
        rfqs[0].configuring = true;
      }
    }
    draftRfqs.forEach((draft) => {
      const cleanDraft = cleanConfiguration(draft, state[PRESETS]);
      const alreadyInArrayIndex = state[DRAFT_RFQS]?.findIndex((d) => d.hash === cleanDraft.hash);
      if (alreadyInArrayIndex > -1) {
        Vue.set(state[DRAFT_RFQS], alreadyInArrayIndex, {
          ...cleanDraft,
          ...{
            __draft: true,
            _open: false,
            _openMobile: false,
            __attachment_needed: !!attachmentRequiredDrafts[cleanDraft.hash],
          },
        });
      } else {
        state[DRAFT_RFQS].push({
          ...cleanDraft,
          ...{
            __draft: true,
            _open: false,
            _openMobile: false,
            __attachment_needed: !!attachmentRequiredDrafts[cleanDraft.hash],
          },
        });
      }
    });
    const addRfq = (e, index) => {
      const alreadyInArrayIndex = state[RFQS]?.findIndex((r) => r.hash === e.hash);
      const rfq = {
        ...e,
        ...{
          __draft: false,
          _open: false,
          _openMobile: index === 0,
        },
      };
      if (alreadyInArrayIndex === -1) {
        state[RFQS].push(rfq);
      } else {
        state[RFQS].splice(alreadyInArrayIndex, 1, rfq);
      }
    };
    if (Array.isArray(rfqs)) {
      rfqs.forEach(addRfq);
    } else {
      rfqs.data.forEach(addRfq);
    }
  },

  /**
   * Sets the rfqs for the user (used in rfq list)
   *
   * @param state
   * @param {Object} payload
   * @param {Object} payload.data      The response data from which the rfq
   * list is extracted, if this is an object the method tries to extract the pagination data
   * @param {boolean} payload.clear    If this is true the stored rfqs are
   * cleared from the state before the new ones are appended
   * @param {boolean} payload.toFront  Inserts quotes to the front of the array
   * */
  [SET_ALL](state, { data = [], clear, toFront }) {
    if (clear) {
      state[ALL_RFQS].splice(0, state[ALL_RFQS].length);
    }
    if (Array.isArray(data)) {
      data.forEach((e) => {
        const idx = state[ALL_RFQS].find((r) => r.hash === e.hash);
        if (idx > -1) {
          state[ALL_RFQS].splice(idx, 1, e);
        } else {
          state[ALL_RFQS][toFront ? 'unshift' : 'push'](e);
        }
      });
    } else {
      state[PAGINATION].currentPage = data.current_page;
      state[PAGINATION].nextPage = data.next_page_url ? state[PAGINATION].currentPage + 1 : null;
      state[PAGINATION].total = data.total;
      state[PAGINATION].per_page = data.per_page;
      data.data.forEach((e) => state[ALL_RFQS].push(e));
    }
  },

  /**
   * Adds model to the draft-rfqs array, append the properties the FE needs to handle them
   *
   * @param state
   * @param {Object} model     The model to be added
   * */
  [ADD_TO_DRAFTS](state, model) {
    const addedProperties = {
      __draft: true,
      configuring: false,
      selected: false,
      uploading: false,
      uploadPercent: false,
      configuration: null,
      configuration_object: null,
    };
    state[DRAFT_RFQS].push({ ...addedProperties, ...model });
  },

  /**
   * Sets a property on a selected rfq/draft-rfq
   *
   * @param state
   * @param {Object} payload
   * @param {Object} payload.model     The model that the property is set on
   * @param {string} payload.property  The property to be set
   * @param {*} payload.value     The value the property is set to
   * */
  [SET_PROPERTY](state, { model, property, value }) {
    const _model = [...state[DRAFT_RFQS], ...state[RFQS]].find((e) => e.hash === model.hash);
    if (_model) {
      Vue.set(_model, property, value);
    }
  },

  /**
   * Swap the uploading dummy model with the actual file
   * @param state
   * @param uploading
   * @param model
   */
  [SWAP_UPLOADING](state, { uploading, model }) {
    state[DRAFT_RFQS].some((toSwap) => {
      if (toSwap.hash === uploading.hash) {
        Object.keys(model).forEach((key) => {
          Vue.set(toSwap, key, model[key]);
        });
        Vue.set(toSwap, 'uploading', false);
        return true;
      }
      return false;
    });
  },

  /**
   * Update draft properties with the model
   * @param state
   * @param model
   */
  [UPDATE_DRAFT](state, model) {
    const oldModel = state[DRAFT_RFQS].find((e) => e.hash === model.hash);
    if (oldModel) {
      Object.keys(model).forEach((key) => {
        Vue.set(oldModel, key, model[key]);
      });
    }
  },

  /**
   * Update rfq properties with the model
   * @param state
   * @param model
   */
  [UPDATE_RFQ](state, model) {
    const oldModel = state[RFQS].find((e) => e.hash === model.hash);
    if (oldModel) {
      Object.keys(model).forEach((key) => {
        Vue.set(oldModel, key, model[key]);
      });
    }
  },

  /**
   * Removes a model from the rfq/draft-rfq list
   *
   * @param state
   * @param {Object} model     The model to be removed
   * */
  [DELETE](state, model) {
    const configurableIndex = state[DRAFT_RFQS].findIndex((e) => e.hash === model.hash);
    const historicIndex = state[RFQS].findIndex((e) => e.hash === model.hash);
    if (configurableIndex > -1) {
      state[DRAFT_RFQS].splice(configurableIndex, 1);
    }
    if (historicIndex > -1) {
      state[RFQS].splice(historicIndex, 1);
    }
  },

  /**
   *
   * @param state
   * @param errors
   */
  [SET_FORM_ERRORS](state, errors) {
    state[FORM_ERRORS] = errors;
  },

  /**
   * Set the progress for a draft rfq
   * @param state
   * @param {Object} payload
   * @param {number} payload.hash
   * @param {Object} payload.progress
   */
  [SET_PROGRESS](state, { hash, progress }) {
    state[PROGRESSES] = { ...state[PROGRESSES], ...{ [hash]: progress } };
  },

  /**
   * Set presets in the state
   * @param state
   * @param presets
   */
  [SET_PRESETS](state, presets) {
    state[PRESETS] = presets;
  },

  /**
   * Set the filtered parts in the state
   * @param state
   * @param array
   */
  [SET_FILTERED](state, array) {
    state[FILTERED_PARTS] = array;
  },

  [ADD_UPLOAD_PROGRESS](state, progress) {
    state[UPLOAD_PROGRESSES].push(progress);
  },

  [UPDATE_UPLOAD_PROGRESS](state, progress) {
    const index = state[UPLOAD_PROGRESSES].findIndex((e) => e.name === progress.name);
    if (index > -1) {
      state[UPLOAD_PROGRESSES].splice(index, 1, progress);
    }
    if (state[UPLOAD_PROGRESSES].every((e) => e.status === 1)) {
      state[UPLOAD_PROGRESSES] = [];
    }
  },

  [SORT_PARTS](state, sortOptions) {
    // eslint-disable-next-line max-len
    const isInConfigurationObject = state[DRAFT_RFQS][0]?.configuration_object?.[sortOptions.key]?.name;
    const keys = isInConfigurationObject ? ['configuration_object', sortOptions.key, 'name'] : [sortOptions.key];
    const resDrafts = sortOptions.key === 'quantity_initial' ? numericSort(state[DRAFT_RFQS], ...keys) : mixedSort(state[DRAFT_RFQS].slice(), ...keys);
    const resRfqs = sortOptions.key === 'quantity_initial' ? numericSort(state[RFQS], ...keys) : mixedSort(state[RFQS].slice(), ...keys);
    state[DRAFT_RFQS] = sortOptions.direction === 'desc' ? resDrafts.reverse() : resDrafts;
    state[RFQS] = sortOptions.direction === 'desc' ? resRfqs.reverse() : resRfqs;
  },

  [SET_LOADING](state, { type, isLoading }) {
    Vue.set(state[LOADING], type, isLoading);
  },

  [SET_SELECTED_PART_HASHES](state, hashes) {
    state[SELECTED_PART_HASHES] = hashes;
  },

  [SET_VIEWED](state, part) {
    const partInState = [
      ...state[DRAFT_RFQS],
      ...state[RFQS],
      ...state[ALL_RFQS],
    ].find((p) => p.hash === part?.hash) || part;
    const modelFile = findModelFile(part);
    const isPdf = modelFile?.extension.toLowerCase() === PDF;
    const parserData = modelFile?.parser_metadata;

    if (isPdf || parserData || partInState == null) {
      state[VIEWED] = partInState;
    } else {
      Toast.open('The viewer is not available while the file is being parsed.');
    }
  },

  [SET_SORT_BY](state, payload) {
    if (!payload) {
      state[SORT_BY] = getState()[SORT_BY];
    } else {
      const { field, type, direction } = payload;
      state[SORT_BY] = { field, type, direction };
    }
  },

  /**
   * Resets the initial state
   *
   * @param state
   * */
  // eslint-disable-next-line no-unused-vars
  [RESET_STATE](state) {
    state = Object.assign(state, getState());
  },

  [SET_DRAFT_COUNT](state, payload) {
    state[DRAFT_COUNT] = payload;
  },

  [SET_UPLOADED_DRAFTS](state, payload) {
    state[UPLOADED_DRAFTS] = payload;
  },
  [SET_PRODUCTION_REQUIREMENTS_MODAL](state, payload) {
    state[PRODUCTION_REQUIREMENTS_MODAL] = payload;
  },

  [SET_RFQ_UPDATE_HASHES](state, payload) {
    state[RFQ_UPDATE_HASHES] = payload;
  },

  [SET_REVISE_REQUOTE_MODAL_RFQS](state, payload) {
    state[REVISE_REQUOTE_MODAL_RFQS] = payload;
  },

  [SET_INSTANT_QUOTE_RFQ_IDS](state, payload) {
    state[INSTANT_QUOTE_RFQ_IDS] = payload;
  },

  [SET_INSTANT_QUOTE](state, { instantQuote, hash, add = true }) {
    const index = state[DRAFT_RFQS].findIndex((draft) => draft.hash === hash);

    if (index !== -1) {

      add ? Vue.set(state[DRAFT_RFQS][index], 'instant_quote', instantQuote) : Vue.delete(state[DRAFT_RFQS][index], 'instant_quote');
    }
  },

  [SET_REVISE_REQUOTE_CONFIG](state, payload) {
    if (payload.reset) {
      state[REVISE_REQUOTE_CONFIG] = [];
    } else {
      if (payload.configObject) {
        const stateRfq = state[REVISE_REQUOTE_MODAL_RFQS].rfqs?.filter((rfq) => rfq.hash === payload.hash);
        stateRfq[0].configuration_object = payload.configObject;
        stateRfq[0].configuration = payload.configuration;

        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty('quantity_initial')) {
          stateRfq[0] = {
            ...stateRfq[0],
            quantity_initial: payload.quantity_initial,
          };
        }
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty('quantity_production')) {
          stateRfq[0] = {
            ...stateRfq[0],
            quantity_production: payload.quantity_production,
          };
        }
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty('production_requirements')) {
          stateRfq[0] = {
            ...stateRfq[0],
            production_requirements: payload.production_requirements,
          };
        }
        // eslint-disable-next-line no-prototype-builtins
        if (payload.hasOwnProperty('notes')) {
          stateRfq[0] = {
            ...stateRfq[0],
            notes: payload.notes,
          };
        }

        state[REVISE_REQUOTE_MODAL_RFQS].rfqs = [...state[REVISE_REQUOTE_MODAL_RFQS].rfqs.filter((rfq) => rfq.hash !== payload.hash), ...stateRfq];
      }

      if (!state[REVISE_REQUOTE_CONFIG].filter((c) => c.hash === payload.hash)) {
        state[REVISE_REQUOTE_CONFIG] = [...state[REVISE_REQUOTE_CONFIG], payload];
      } else {
        state[REVISE_REQUOTE_CONFIG] = [...state[REVISE_REQUOTE_CONFIG].filter((c) => c.hash !== payload.hash), payload];
      }
    }
  },

  [SET_UPDATING_PROPERTIES](state, payload) {
    state[UPDATING_PROPERTIES] = payload;
  },

  [SET_PREVIOUS_QUOTES](state, payload) {
    state[PREVIOUS_QUOTES] = payload;
  }
};
