import { METADATA, USER_COUNTRY, USER_DATA, UPDATE_ADDRESS_IS_LOADING, COOKIE_PREFERENCES } from './types';

export default function getState() {
  return {
    [USER_DATA]: null,
    [METADATA]: null,
    [USER_COUNTRY]: null,
    [UPDATE_ADDRESS_IS_LOADING]: false,
    [COOKIE_PREFERENCES]: null,
  };
}
