<template>
  <div>
    <template>
      <p
        class="material-title is-size-7"
      >
        {{ title }}
      </p>
    </template>
    <template v-if="cheapestOptions.length">
      <template v-for="option in cheapestOptions">
        <button
          :key="option.id"
          ref="options"
          :class="{'is-info': values && values.includes(option.id)}"
          class="option button is-small is-fullwidth is-result"
          @click="update(option)"
        >
          <p class="tw-text-sm tw-font-bold">
            {{ option.string_value }}
          </p>
        </button>
      </template>
    </template>
    <template v-for="option in options">
      <button
        :key="option.id"
        ref="options"
        :class="{'is-info': values && values.includes(option.id)}"
        class="option button is-small is-fullwidth is-result"
        @click="update(option)"
      >
        <p class="tw-text-sm">
          {{ option.string_value }}
        </p>
      </button>
    </template>
    <template v-if="optionsWithNotes.length">
      <template v-for="option in optionsWithNotes">
        <b-field
          :key="option.id"
          :class="{'other-option-selected': values && values.includes(option.id)}"
          :label="option.string_value"
          class="option other-option space-x py-2"
          custom-class="is-size-7 has-text-weight-normal"
        >
          <b-input
            v-model="customNote"
            expanded
            placeholder="Please specify the material"
            size="is-small"
          />
          <b-button
            :disabled="!customNote"
            class="ml-1"
            size="is-small"
            @click="update(option, true)"
          >
            Save
          </b-button>
        </b-field>
      </template>
    </template>
  </div>
</template>

<script>
import customNotes from '@/app-buyer/mixins/custom_notes';

export default {
  name: 'MaterialCollapse',
  mixins: [customNotes],
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: () => 0,
    },
    values: {
      type: Array,
      default: () => [],
    },
    open: Boolean,
    openTopLevel: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      containsValue: false,
      hasValue: false,
      optionsWithNotes: [],
      options: [],
      cheapestOptions: [],
    };
  },

  watch: {
    hasValue: {
      handler(val) {
        if (val && this._singleViewedModel && this._singleViewedModel.hash) {
          this.getNote('material');
        }
      },
      immediate: true,
    },

    data: {
      handler(value) {
        this.cleanOptions(value);
      },
      deep: true,
    },
  },

  mounted() {
    this.cleanOptions(this.data);
    const idx = this.data?.options?.findIndex((e) => this.values.includes(e.id));
    const containsValue = idx > -1;
    if (containsValue) {
      this.containsValue = true;
      this.hasValue = true;
      this.$emit('has-value');
      this.$nextTick(() => {
        if (this.$refs.options[idx]) {
          this.$refs.options[idx].scrollIntoView();
        }
      });
    }
  },

  methods: {
    update(material, withNotes) {
      this.$emit('set-note', (withNotes ? `${material.string_value}: ${this.customNote}` : null));
      this.$nextTick(() => {
        this.$emit('input', material.id);
      });
    },

    cleanOptions(value) {
      let needsNotes = [];
      let doesNotNeedNotes = [];
      let cheapestOptions = [];

      const { options } = value;

      for (const property in options) {
        if (options[property]?.metadata?.requires_notes) {
          needsNotes = [...needsNotes, options[property]];
        } else if (options[property]?.slug.includes('cheapest')) {
          cheapestOptions = [...cheapestOptions, options[property]];
        } else {
          doesNotNeedNotes = [...doesNotNeedNotes, options[property]];
        }
      }

      doesNotNeedNotes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      this.cheapestOptions = cheapestOptions;
      this.optionsWithNotes = needsNotes;
      this.options = doesNotNeedNotes;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.material-title {
  background-color: #f1f1f1;
  font-weight: 700;
  padding: 0.2rem 0 0.2rem 0.8rem;
  text-transform: uppercase;
}

.space-x {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.option {
  border: solid 1px transparent;

  &:hover {
    border-color: black;
  }

  &:hover:not(.is-info):not(.other-option-selected) {
    background-color: #f1f1f1;
  }
}

.other-option {
  border-radius: 5px;
}

.other-option-selected {
  background-color: #3085b2;

  ::v-deep .label {
    color: white;
  }
}
</style>
