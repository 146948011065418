<template>
  <div id="autodesk-viewer" />
</template>

<script>
/* eslint-disable no-undef */

import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import {
  ACCESS_TOKEN,
  AUTODESK_MODULE,
  GET_ACCESS_TOKEN,
} from '../../../store/modules/autodesk/types';

export default {
  name: 'AutodeskViewer',
  props: {
    urn: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewer: null,
      options: {
        env: 'AutodeskProduction',
        accessToken: '',
        api: 'derivativeV2',
      },
      documentId: '',
      toolbar: null,
      changing: false,
      interval: null,
    };
  },
  computed: {
    ...mapState(AUTODESK_MODULE, {
      accessToken: ACCESS_TOKEN,
    }),
  },
  watch: {
    urn: {
      handler(newVal) {
        if (newVal) {
          this.changing = true;
          setTimeout(() => this.setup(), 500);
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    if (this.viewer) {
      this.viewer.tearDown();
      this.viewer.finish();
      this.viewer = null;
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    ...mapActions(AUTODESK_MODULE, {
      getAccessToken: GET_ACCESS_TOKEN,
    }),
    async setup() {
      if (this.urn) {
        this.$emit('loading', true);
        this.documentId = `urn:${this.urn}`;
        if (!this.accessToken) {
          await this.getAccessToken();
        }
        this.options.accessToken = this.accessToken;
        this.interval = setInterval(this.onDocumentReady, 3000);
        this.onDocumentReady();
      }
    },
    async onDocumentReady() {
      const response = await axios.get(`https://developer.api.autodesk.com/modelderivative/v2/designdata/${this.urn}/manifest`, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
      if (response && response.data.status === 'success') {
        clearInterval(this.interval);
        Autodesk.Viewing.Initializer(this.options, () => {
          if (this.viewer) this.viewer.tearDown();
          Autodesk.Viewing.Document
            .load(this.documentId, this.onDocumentLoadSuccess, this.onDocumentLoadFailure);
        });
      }
    },

    handleError(viewerErrorCode) {
      // TODO HANDLE ERROR
      console.log(viewerErrorCode);
    },

    onDocumentLoadSuccess(doc) {
      // A document contains references to 3D and 2D geometries.
      const geometries = doc.getRoot()
        .search({ type: 'geometry' });
      if (geometries.length === 0) {
        console.error('Document contains no geometries.');
        return;
      }
      // Choose any of the avialable geometries
      const initGeom = geometries[0];

      const svfUrl = doc.getViewablePath(initGeom);
      const modelOptions = {
        sharedPropertyDbPath: doc.getPropertyDbPath(),
      };

      if (!this.viewer) {
        const viewerDiv = document.getElementById('autodesk-viewer');

        const config = {
          extensions: initGeom.extensions() || [],
        };
        this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv, config);

        this.viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated);

        const onExtensionLoaded = () => {
          const navTools = this.viewer.toolbar.getControl('navTools');
          // const modelTools = this.viewer.toolbar.getControl("modelTools");
          navTools.removeControl('toolbar-bimWalkTool');
          navTools.removeControl('toolbar-cameraSubmenuTool');
        };

        this.viewer.addEventListener(
          Autodesk.Viewing.EXTENSION_LOADED_EVENT,
          onExtensionLoaded,
        );

        this.viewer.start(svfUrl, modelOptions, this.onLoadModelSuccess, this.onLoadModelError);

        this.$el.addEventListener('mousemove', this.showControls);
        this.$el.addEventListener('mouseleave', this.autoHideControls);
      } else {
        this.viewer.load(svfUrl, modelOptions, this.onLoadModelSuccess, this.onLoadModelError);
      }
      setTimeout(() => this.$emit('loading', false), 500);
    },

    /**
             * Autodesk.Viewing.Document.load() failure callback.
             */
    onDocumentLoadFailure(viewerErrorCode) {
      this.handleError(viewerErrorCode);
      this.$emit('loading', false);
    },

    /**
             * viewer.loadModel() success callback.
             * Invoked after the model's SVF has been initially loaded.
             * It may trigger before any geometry has been downloaded and displayed on-screen.
             */
    onLoadModelSuccess() {
      this.viewer.setBackgroundColor(243, 243, 243, 243, 243, 243);
    },

    /**
             * viewer.loadModel() failure callback.
             * Invoked when there's an error fetching the SVF file.
             */
    onLoadModelError(viewerErrorCode) {
      this.handleError(viewerErrorCode);
    },

    onToolbarCreated() {
      const settingsTools = this.viewer.toolbar.getControl('settingsTools');
      settingsTools.removeControl('toolbar-modelStructureTool');
      settingsTools.removeControl('toolbar-propertiesTool');
      settingsTools.removeControl('toolbar-settingsTool');
      settingsTools.removeControl('toolbar-fullscreenTool');

      this.toolbar = this.viewer.toolbar.container;
      this.toolbar.style.display = 'none';
      this.toolbar.style.opacity = '0';
      this.toolbar.style.transform = 'translateY(2rem)';
      this.toolbar.style.transition = 'all .2s';
    },

    showControls() {
      if (this.toolbar) {
        this.toolbar.style.opacity = 1;
        this.toolbar.style.transform = 'translateY(0)';
      }
    },

    autoHideControls() {
      if (this.toolbar) {
        setTimeout(() => {
          this.toolbar.style.opacity = 0;
          this.toolbar.style.transform = 'translateY(2rem)';
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
  #autodesk-viewer {
    height: 80%;
    width: 100%;
  }
</style>
