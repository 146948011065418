const ENDPOINTS = {
  AUTH: {
    CSRF: '/sanctum/csrf-cookie',
    LOGIN: '/login',
    LOGIN_LINK: '/api/login-links',
    REGISTER: '/api/register',
    LOGOUT: '/logout',
    EMAIL_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/api/password/change',
    RESEND_VERIFICATION_EMAIL: '/email/verification-notification',
    EMAIL_VERIFY: 'email/verify',
    REGISTER_BASIC_RESEND: '/api/register-basic-resend',
    REGISTER_BASIC_COMPLETE: '/api/register-basic-complete',
  },
  USER: {
    INDEX: '/api/getuserprofile',
    UPDATE: '/api/updateuserprofile',
    METADATA: '/api/user/metadata',
    PAYMENT_METHODS: '/api/user/payment-methods',
    AGREE_TO_TERMS: '/api/user/policies/agree',
    UNSUBSCRIBE: '/api/notification-subscriptions',
  },
  PROFILE: {
    SUGGEST: '/api/profile/address-suggest',
    DETAIL: '/api/profile/address-detailed-info',
  },
  PROJECTS: {
    INDEX: '/api/v2/projects',
    SHOW: '/api/v2/projects/{hash}',
  },
  RFQS: {
    INDEX: '/api/v2/rfqs',
    CONFIGURATION: '/api/v2/rfqs/{id}/configuration',
    UPDATE: '/api/v2/rfqs/{hash}',
    REVISE: '/api/v2/rfqs/revise',
    DETAIL: '/api/v2/rfqs/{hash}',
    CANCEL: '/api/v2/rfqs/{hash}/cancel',
  },
  DRAFT_RFQS: {
    INDEX: '/api/v2/draft-rfqs',
    DETAIL: '/api/v2/draft-rfqs/{hash}',
    DELETE_UPLOADED: '/api/v2/draft-rfqs/{hash}/uploads/{id}',
    MANAGE_UPLOADED: '/api/v2/draft-rfqs/{hash}/uploads',
    SIGNED_URL_MODEL: '/api/v2/draft-rfqs/{hash}/direct-upload',
    SIGNED_URL_SUPPORTING: '/api/v2/draft-rfqs/{hash}/direct-upload/supporting-file',
    PARSING: '/draft-rfqs/{hash}/model-file-parse-attempt',
    CLONE: '/api/v2/draft-rfqs/{hash}/clone',
    SET_QUOTE: '/api/v2/draft-rfqs/{hash}/replicate-rfq/{rfq_id}',
  },
  QUOTE_GROUPS: {
    INDEX: '/api/v2/quote-groups',
    DETAIL: '/api/v2/quote-groups/{id}',
  },
  PRESETS: {
    INDEX: '/api/v2/presets',
  },
  REFERENCE: {
    PROPERTIES: '/api/v2/reference/properties',
  },
  AUTODESK: {
    TOKEN: '/api/autodesk/token',
  },
  MESSAGES: {
    INDEX: '/api/buyer/messages',
    DETAIL: '/api/v2/messages',
    SEND: '/api/messages/{id}',
    MESSAGE_COUNT: '/api/messages/{type}/{id}/count',
  },
  INVOICES: {
    INDEX: '/api/v2/invoices',
    SHOW: '/api/v2/invoices/{id}',
  },
  CART: {
    SHOW: '/api/v2/cart',
    UPDATE: '/api/v2/cart',
    PAY: '/api/v2/cart/pay',
    PAY_VIA_CREDIT: '/api/v2/cart/confirm-payment/credit',
    CONFIRM_PAYMENT: '/api/v2/cart/confirm-payment',
    GENERATE_PROFORMA: '/api/v2/cart/invoice',
    GENERATE_FORMAL_QUOTE: '/api/v2/cart/formal-quote',
    ITEMS: {
      STORE: '/api/v2/cart-items',
      REMOVE: '/api/v2/cart-items',
    },
    APPLY_DISCOUNT_CODE: '/api/discount-code/apply',
    CANCEL_DISCOUNT_CODE: '/api/discount-code/cancel',
  },
  CART_TRANSFER: {
    INDEX: '/api/cart-transfers',
    STORE: '/api/cart-transfers',
    DO_ACTION: '/api/cart-transfers/{id}/{action}',
  },
  ORDERS: {
    INDEX: '/api/v2/orders',
    SHOW: '/api/v2/orders/{id}',
    FEEDBACK: '/api/order-feedback',
    REORDER: '/api/v2/client/orders/{id}/reorder',
    BULK_REORDER: '/api/v2/client/orders/bulk-reorder',
    REORDER_COST: '/api/v2/client/orders/reorder-cost',
  },
  G_SERVE: {
    SPECIFICATION: '/api/v2/model-parse/{urn}/specification',
    DATA: '/api/v2/model-parse/{urn}/features',
    PRICE: '/api/v2/model-parse/{uuid}/price',
  },
  UPLOADS: {
    DETAIL: '/api/v2/uploads/{id}',
  },
  VALIDATIONS: {
    EMAIL: '/api/validations/email',
    INVITEEEMAIL: '/api/validations/email/invitee',
  },
};

export default ENDPOINTS;
