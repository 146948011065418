<template>
  <router-link
    to="/cart"
    class="tw-text-md tw-text-white tw-relative tw-flex tw-items-center tw-justify-center tw-group"
  >
    <div class="tw-flex tw-items-center tw-mr-1">
      <img
        src="@/assets/svgs/custom-cart.svg"
        alt="cart-icon"
        class="cart-icon tw-inline-flex tw-h-8 tw-w-8 tw-transition-all xl:tw-filter xl:group-hover:tw-brightness-85"
      />
      <gm-tag
        rounded
        class="count tw-absolute tw-font-bold tw-rounded-xxl tw-text-md tw-transition-colors xl:tw-top-3"
        :class="cartCount ? 'tw-bg-pending xl:group-hover:tw-bg-pending-hover' : 'tw-bg-grey-darkest xl:group-hover:tw-bg-grey-primary'"
      >
        {{ ` ${cartCount}` }}
      </gm-tag>
    </div>
    <div class="tw-flex tw-flex-col tw-items-center tw-ml-05 tw-transition-colors xl:group-hover:tw-text-grey-light">
      <p class="tw-text-md tw-font-bold tw-">{{ cartTotal | formatWithCurrency }}</p>
      <p class="tw-text-xs leading-none">Subtotal</p>
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
import { CART_DATA, CART_MODULE } from '@/app-buyer/store/modules/cart/types';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'NavbarCart',

  components: {
    FontAwesomeIcon,
  },

  props: {
    metadataCartCount: {
      type: Number,
      default: 0,
    },
    metadataCartTotal: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState(CART_MODULE, {
      CART_DATA,
    }),

    cartCount() {
      return this[CART_DATA]?.items?.length ?? this.metadataCartCount;
    },

    cartTotal() {
      return this[CART_DATA]?.items_subtotal ?? this.metadataCartTotal;
    },
  },

}
</script>

<style
  scoped
  lang="scss"
>
.count {
  left: 10px;
  top: -0.125rem;
  height: 18px;
  width: 18px;
}
</style>
