import {
  FORCE,
  FORM_MODE,
  LOGIN_MODAL_MODE,
  LOGIN_MODAL_VISIBLE,
  PROFILE_MODAL_VISIBLE,
  RFQ_DETAIL_MODE,
  RFQ_ENGINEER_VIEW,
  SHOW_FILE_SERVICE_TOOLTIP,
  ON_LOGIN_REDIRECT,
  IS_DESKTOP,
  IS_MOBILE_SIDEBAR_OPEN,
} from './types';
import { FORM_MODES } from '../../../consts/common';

export default function getState() {
  return {
    [LOGIN_MODAL_VISIBLE]: false,
    [PROFILE_MODAL_VISIBLE]: false,
    [LOGIN_MODAL_MODE]: 'login',
    [FORM_MODE]: FORM_MODES.CONFIGURATOR,
    [RFQ_DETAIL_MODE]: false,
    [RFQ_ENGINEER_VIEW]: false,
    [FORCE]: false,
    [SHOW_FILE_SERVICE_TOOLTIP]: false,
    [ON_LOGIN_REDIRECT]: null,
    [IS_DESKTOP]: true,
    [IS_MOBILE_SIDEBAR_OPEN]: false,
  };
}
