<template>
  <div class="">
    <FileUpload
      accept=".iges,.igs,.dwg,.dxf,.stp,.step,.pdf,.stl"
      multiple
      @upload="createQuotes"
    >
      <g-button
        isInverted
        htmlType="a"
        label="Upload new part"
        iconLeft="cloud-upload-alt"
        nativeType="button"
        type="primary-icon"
        color="grey-dark"
        fontWeight="normal"
        size="sm"
        class="admin-style tw-whitespace-nowrap"
      />
    </FileUpload>
  </div>
</template>
<script>
import FileUpload from '@/app-buyer/components/file-upload/file-upload.vue';
import baseUpload from '@/app-buyer/mixins/base-upload';
import GButton from '@common/components/storied/atoms/GButton.vue';

export default {
  name: 'PartTableHeaderUpload',
  components: {
    FileUpload,
    GButton,
  },
  mixins: [baseUpload],
};
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  a {
    &.admin-style {
      border-color: theme('colors.grey-light') !important;
      background-color: theme('colors.white');
    }

    &.admin-style {
      span {
        color: theme('colors.primary');

      }
    }
  }
}
</style>
