<template>
  <div class="">
    <material-search-select
      v-bind="$attrs"
      :options="properties"
      :values="values"
      :configurations="configurations"
      :lead-configuration="leadConfiguration"
      @input="$emit('input', $event)"
      @set-note="$emit('set-note', $event)"
    />
    <button
      class="tw-text-tertiary tw-mt-1 tw-font-bold tw-text-sm xl:tw-absolute"
      @click="showIntercom"
    >
      Help me choose a material
    </button>
  </div>
</template>
<script>
import { formInput } from '@/app-buyer/mixins/form-input';
import MaterialSearchSelect
  from '@/app-buyer/components/configurator/configurator-body/configurator-drafts/configurator-element/material-search-select.vue';

export default {
  name: 'ConfiguratorElementMaterial',
  props: {
    configurations: {
      type: Object,
      default: () => ({}),
    },
    leadConfiguration: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MaterialSearchSelect,
  },
  mixins: [formInput],
  methods: {
    showIntercom() {
      Intercom('showNewMessage');
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.input-squisher {
  overflow: hidden;
  width: 100%;

  &.is-squished {
    margin-right: 1rem;
    width: 0;
  }
}
</style>
