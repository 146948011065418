<template>
  <NotificationProvider v-slot="{ notifications }">
    <main
      id="buyer-app"
      :class="{
        'with-request': LOGGED_IN
          && ($route.name === 'quote-page' || $route.name === 'rfq-form-no-selected'),
        'dragover-active-parent': dragover
      }"
    >
      <nav
        v-if="!noNavigationRoute"
        id="nav"
      >
        <Navbar v-if="IS_DESKTOP" />
        <NavbarMobile v-else />
      </nav>
      <section id="inner-app">
        <aside
          v-if="showSideNav"
          id="main-aside"
        >
          <SideNavbar />
        </aside>
        <div id="router-container-with-sidebar">
          <section id="router-view-container">
            <Transition
              enter-active-class="slide-right-enter-active"
              enter-class="slide-right-enter"
              leave-active-class="slide-left-leave-active"
              leave-class="slide-left-enter"
              mode="out-in"
            >
              <KeepAlive include="quote-page">
                <RouterView />
              </KeepAlive>
            </Transition>
            <UploadIndicator />
          </section>
        </div>
      </section>
      <BModal
        :active="FORCED === 'register'"
        :can-cancel="[]"
        full-screen
        width="80%"
      >
        <RegisterPage />
      </BModal>
      <BModal
        :active="FORCED === 'login'"
        :can-cancel="[]"
        has-modal-card
      >
        <div class="modal-card">
          <div class="modal-card-body">
            <div class="is-flex is-justify-center is-flex-align-centered p-md">
              <icon-font
                icon="geomiq-text"
                style="font-size: 1.2rem;"
              />
            </div>
            <h1 class="tw-text-lg tw-font-bold tw-w-full tw-text-center tw-mb-4">
              Login
            </h1>
            <LoginForm />
            <BButton
              class="mt-4"
              expanded
              outlined
              size="is-small"
              type="is-info"
              @click="FORCE_AUTH('register')"
            >
              Sign up
            </BButton>
          </div>
        </div>
      </BModal>
      <NotificationColumn :notifications="notifications" />
      <FeedbackModal />
      <ReviseRequoteModal />
      <RegistrationDetailsModal v-if="!FORCED" />
      <cookie-popup />
      <div style="z-index: 100;">
        <PortalTarget
          multiple
          name="modal"
        />
      </div>
      <PartViewer />
    </main>
  </NotificationProvider>
</template>

<script>
/* eslint-disable camelcase */

import { PortalTarget } from 'portal-vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from 'lodash';
import { REFERENCE_MODULE } from '@/app-buyer/store/modules/reference-data/types';
import FeedbackModal from '@/app-buyer/components/feedback-modal/FeedbackModal.vue';
import {
  COOKIE_PREFERENCES,
  SET_USER_COUNTRY,
  USER_DATA,
  USER_MODULE,
} from '@/app-buyer/store/modules/user/types';
import UploadIndicator from '@/app-buyer/UploadIndicator.vue';
import RegisterPage from '@/app-buyer/views/registration-page/registration-page.vue';
import {
  AUTH_MODULE,
  FORCE_AUTH,
  FORCED,
  LOGGED_IN,
  LOGIN_SUCCESS,
  MASQUERADING,
  SET_MASQUERADING,
} from '@/app-buyer/store/modules/auth/types';
import LoginForm from '@/app-buyer/components/login-registration/login-form.vue';
import Navbar from '@/app-buyer/components/navbar/Navbar.vue';
import NavbarMobile from '@/app-buyer/components/navbar/NavbarMobile.vue';
import NotificationProvider from '../shared/components/notification/notification-provider.vue';
import NotificationColumn from '../shared/components/notification/notification-column.vue';
import SideNavbar from '@/app-buyer/components/side-navbar/SideNavbar.vue';
import { GET_PRESETS, RFQ_MODULE } from '@/app-buyer/store/modules/rfq/types';
import { GET } from '@/app-buyer/store/modules/types';
import RegistrationDetailsModal
  from '@/app-buyer/components/registration/RegistrationDetailsModal.vue';
import PartViewer from '@/app-buyer/components/project/PartViewer.vue';
import accessDragData from '@/app-buyer/mixins/drag-drop-access';
import { DRAFT_RFQ_SUPPORTING_FILE } from '@/shared/consts/slugs';
import getEnvironmentVariable from '@/shared/misc/env-variable';
import ReviseRequoteModal from '@/app-buyer/components/revise-requote/ReviseRequoteModal.vue';
import {
  IS_DESKTOP,
  IS_MOBILE_SIDEBAR_OPEN,
  NAVIGATION_MODULE,
  SET_IS_DESKTOP,
} from '@/app-buyer/store/modules/navigation/types';
import CookiePopup from '@/app-buyer/components/cookies/CookiePopup.vue';

export default {
  name: 'App',

  components: {
    CookiePopup,
    ReviseRequoteModal,
    PartViewer,
    RegistrationDetailsModal,
    LoginForm,
    RegisterPage,
    UploadIndicator,
    FeedbackModal,
    SideNavbar,
    NotificationColumn,
    NotificationProvider,
    Navbar,
    NavbarMobile,
    PortalTarget,
  },

  mixins: [accessDragData],

  data() {
    return {
      dragevents: {
        dragleave: this.setDragoverFalse,
        dragenter: this.setDragoverTrue,
        dragover: this.setDragoverTrue,
        dragexit: this.setDragoverFalse,
        dragend: this.setDragoverFalse,
        drop: this.setDragoverFalse,
      },
      dragover: false,
    };
  },

  computed: {
    noNavigationRoute() {
      const noNavRouteNames = [
        'login',
        'register',
        'email-verification',
        'finish-register',
        'reset-password',
        'registration-details',
        'registration-success',
        'unsubscribe',
      ];
      return noNavRouteNames.includes(this.$route.name);
    },

    ...mapState(AUTH_MODULE, {
      LOGGED_IN,
      MASQUERADING,
      FORCED,
    }),

    ...mapState(USER_MODULE, {
      USER_DATA,
      COOKIE_PREFERENCES,
    }),

    ...mapState(NAVIGATION_MODULE, {
      IS_DESKTOP,
      IS_MOBILE_SIDEBAR_OPEN,
    }),

    showSideNav() {
      return this[LOGGED_IN] && this.$route.path.includes('quotes') && ((!this.IS_DESKTOP && this.IS_MOBILE_SIDEBAR_OPEN) || this.IS_DESKTOP);
    },
  },

  watch: {
    [USER_DATA]: {
      handler(val) {
        if (val?.user?.partial) {
          this.$router.push({ name: 'finish-register' });
        }
      },
      immediate: true,
      deep: true,
    },
    'USER_DATA.user': {
      handler(val) {
        // SEGMENT TRACKING
        if (val && getEnvironmentVariable('VUE_APP_SEGMENT_ENABLED')) {
          window.analytics.identify(`${val.id}`, {
            name: `${val.name}`,
            email: `${val.email}`,
            isMasquerading: !!this[MASQUERADING],
          });
        }

        if (this.LOGGED_IN && getEnvironmentVariable('VUE_APP_ENV') === 'production') {
          const APP_ID = 'cdbwv4do';
          window.intercomSettings = {
            app_id: APP_ID,
            alignment: 'left',
          };

          // only add user info to Intercom if they have accepted the cookies
          if (this[COOKIE_PREFERENCES]?.functional || this[COOKIE_PREFERENCES]?.analytics || this[COOKIE_PREFERENCES]?.advertising) {
            window.intercomSettings = {
              ...window.intercomSettings,
              user_hash: this.USER_DATA.user.intercom_user_hash,
              name: this.USER_DATA.user.name,
              email: this.USER_DATA.user.email,
              created_at: this.USER_DATA.user.created_at,
            }
          }

          (function () {
            const w = window;
            const ic = w.Intercom;
            if (typeof ic === 'function') {
              ic('reattach_activator');
              ic('update', w.intercomSettings);
            } else {
              const d = document;
              var i = function () {
                i.c(arguments);
              };
              i.q = [];
              i.c = function (args) {
                i.q.push(args);
              };
              w.Intercom = i;
              const l = function () {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${APP_ID}`;
                const x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
              };
              if (document.readyState === 'complete') {
                l();
              } else if (w.attachEvent) {
                w.attachEvent('onload', l);
              } else {
                w.addEventListener('load', l, false);
              }
            }
          }());
        }
      },

    },

    _dragging(newVal) {
      if (!newVal) {
        this.dragover = false;
      }
    },
  },

  created() {
    if (this.$route.query.gm_src) sessionStorage.setItem('gm_src', this.$route.query.gm_src);

    if (this.$cookies.get('_origin')) this[SET_MASQUERADING](true);

    this.$store.dispatch(`${RFQ_MODULE}/${GET_PRESETS}`, {}, { root: true });
    this.$store.dispatch(`${REFERENCE_MODULE}/${GET}`, {}, { root: true });

    // eslint-disable-next-line no-restricted-syntax
    for (const event in this.dragevents) {
      if (Object.prototype.hasOwnProperty.call(this.dragevents, event)) {
        window.addEventListener(event, this.dragevents[event]);
      }
    }
  },

  mounted() {
    const storedCountry = localStorage.getItem('userCountrySelect');
    if (storedCountry) this[SET_USER_COUNTRY](storedCountry);
    else {
      this[SET_USER_COUNTRY]('United Kingdom');
      localStorage.setItem('userCountrySelect', 'United Kingdom');
    }

    if (document.location.search.includes('verified=1')) {
      this.$buefy.toast.open({
        message: 'Your email was successfully verified',
        type: 'is-success',
      });

      this.$router.replace({ 'query': null });
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    // eslint-disable-next-line no-restricted-syntax
    for (const event in this.dragevents) {
      if (Object.prototype.hasOwnProperty.call(this.dragevents, event)) {
        window.removeEventListener(event, this.dragevents[event]);
      }
    }
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      LOGIN_SUCCESS,
    }),
    ...mapActions(USER_MODULE, {
      GET,
    }),
    ...mapMutations(USER_MODULE, {
      SET_USER_COUNTRY,
    }),
    ...mapMutations(AUTH_MODULE, {
      FORCE_AUTH,
      SET_MASQUERADING,
    }),
    ...mapMutations(NAVIGATION_MODULE, {
      SET_IS_DESKTOP,
    }),

    handleResize: debounce(function () {
      if (window.innerWidth <= 1279) this[SET_IS_DESKTOP](false);
      else this[SET_IS_DESKTOP](true);
    }, 500),

    setDragoverTrue() {
      this.$nextTick(() => {
        if (!this._dragging || this._dragType === DRAFT_RFQ_SUPPORTING_FILE) {
          this.dragover = true;
        }
      });
    },

    setDragoverFalse: debounce(function debounceDragoverFalse() {
      this.dragover = false;
    }, 500),
  },
};
</script>

<style lang="scss">

#buyer-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media all and (max-width: variables.$tablet) {

    padding-bottom: 0;
  }

  #nav {
    height: var(--navbar-height);
    width: 100%;
  }

  #inner-app {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    min-height: 0;

    @media all and (max-width: variables.$tablet) {
      overflow-y: auto;
    }

    #main-aside {
      flex: none;
      //width: var(--main-aside-width);

      @media all and (max-width: variables.$tablet) {
        bottom: 0;
        position: fixed;
        width: 100vw;
        z-index: 15;
      }
    }

    #router-container-with-sidebar {
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      min-height: 0;
      overflow-y: hidden;
      width: 100%;

      #aside {
        box-shadow: var(--border-shadow-right);
        flex-basis: 0;
        flex-grow: 0;
        height: 100%;
        max-width: var(--project-aside-width);

        @media all and (max-width: variables.$tablet) {
          max-width: 100vw;
          position: absolute;
          z-index: 12;
        }
      }

      #router-view-container {
        flex-basis: 0;
        flex-grow: 1;
        height: 100%;
        min-height: 0;
        overflow-x: hidden;
        overflow-y: auto;
        //@media all and (max-width: variables.$tablet) {
        //  padding-bottom: 66px;
        //}
      }
    }
  }
}
</style>
