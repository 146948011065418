<template>
  <div class="modal-card">
    <header class="modal-card-head has-background-white p-1" />
    <div class="modal-card-body px-6">
      <RfqUnsuccessfulMessage
        v-if="errors && Object.keys(errors).length"
        :error="errors"
        initial-open-state
      />
    </div>
    <footer class="modal-card-foot is-justify-content-space-between">
      <BButton
        class="has-text-weight-bold"
        size="is-small"
        @click="$emit('close')"
      >
        Back to project
      </BButton>
      <BButton
        class="has-text-weight-bold"
        size="is-small"
        type="is-g-primary"
        @click="goToQuotes"
      >
        View my quotes
      </BButton>
    </footer>
  </div>
</template>

<script>
import RfqUnsuccessfulMessage from '@/app-buyer/components/project/RfqUnsuccessfulMessage.vue';

export default {
  name: 'RfqUnsuccessModalContent',
  components: { RfqUnsuccessfulMessage },
  props: {
    errors: {
      type: Object,
      default: () => {
      },
    },
  },

  methods: {
    goToQuotes() {
      this.$router.push({ name: 'quote-page' });
      this.$emit('close');
    },
  },
};
</script>
