<template>
  <g-button
    :color="isOpen ? 'tertiary' : 'grey-light'"
    :iconLeft="isOpen ? 'chevron-down' : 'chevron-right'"
    :isInverted="isOpen"
    :label="label"
    :isDisabled="false"
    :isLoading="false"
    type="primary-icon"
    fontWeight="normal"
  />
</template>

<script>
import GButton from '@common/components/storied/atoms/GButton.vue'

export default {
  name: 'GroupedQuotesCellRef',
  components: {
    GButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    }
  },
};
</script>
