<template>
  <div>
    <BInput
      v-model="searchTerm"
      icon="search"
      placeholder="Search for part"
      :custom-class="isDesktop ? 'tw-text-md' : 'tw-text-sm'"
    />
  </div>
</template>
<script>
import * as Fuse from 'fuse.js';
import { debounce } from 'lodash/function';
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  DRAFT_RFQS,
  FILTERED_PARTS,
  RFQ_MODULE,
  SET_FILTERED,
} from '@/app-buyer/store/modules/rfq/types';
import { ACTIVE_PROJECT, PROJECT_MODULE } from '@/app-buyer/store/modules/projects/types';

let fuse;

export default {
  name: 'PartTableSearch',

  props: {
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerSearchTerm: null,
    };
  },
  computed: {
    ...mapState(RFQ_MODULE, {
      DRAFT_RFQS,
      FILTERED_PARTS,
    }),
    ...mapGetters(PROJECT_MODULE, {
      ACTIVE_PROJECT,
    }),
    searchTerm: {
      get() {
        return this.innerSearchTerm;
      },
      set(value) {
        this.search();
        this.innerSearchTerm = value;
      },
    },
  },
  watch: {
    DRAFT_RFQS: {
      handler(newVal) {
        const searchOptions = {
          shouldSort: false,
          location: 0,
          threshold: 0.5,
          distance: 50,
          maxPatternLength: 50,
          minMatchCharLength: 1,
          keys: ['name'],
        };
        fuse = new Fuse(newVal, searchOptions);
      },
      immediate: true,
    },
    ACTIVE_PROJECT(newVal, oldVal) {
      if (newVal?.hash !== oldVal?.hash) {
        this.searchTerm = null;
      }
    },
  },
  methods: {
    ...mapMutations(RFQ_MODULE, {
      SET_FILTERED,
    }),
    // eslint-disable-next-line prefer-arrow-callback
    search: debounce(function debounceSearch() {
      if (!this.innerSearchTerm) {
        this[SET_FILTERED](null);
      } else {
        this[SET_FILTERED](fuse.search(this.innerSearchTerm));
      }
    }, 200),
  },
};
</script>

<style
  scoped
  lang="scss"
>
::v-deep {
  .control {
    height: 26px;

    input, span {
      height: 26px !important;

      svg {
        width: 0.8em;
      }
    }

   @screen xl {
     height: 36px;

     input, span {
       height: 36px !important;
     }
   }
  }
}
</style>
